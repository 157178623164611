import React from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { roundListSchema } from '../../schemas/round';

// local components
import FiltersForm from './components/FiltersForm';
import ResultsTable from './components/ResultsTable';
import ModalAdvancedOptions from './components/ModalAdvancedOptions';

function Results({
  round,
  currentChapter,
}) {
  Results.propTypes = {
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    round:          PropTypes.instanceOf(Map).isRequired,
  };

  const getRounds = () => {
    const result = (currentChapter.get('data') || Map()).get('rounds') || List();
    const roundItems = (round.get('data') || Map()).get('items') || List();

    const entities = {
      round: roundItems.toJS(),
    };

    const items = denormalize(result.toJS(), roundListSchema, entities);
    return Immutable.fromJS(items);
  };

  const rounds = getRounds() || List();

  return (
    <div>
      <FiltersForm rounds={ rounds } currentChapter={ currentChapter } />
      <ModalAdvancedOptions rounds={ rounds } currentChapter={ currentChapter } />
      <ResultsTable rounds={ rounds } />
    </div>
  );
}

export default Results;
