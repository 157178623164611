import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// mui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';

// helpers
import getUserRole from '../../../../lib/selectors/getUserRole';

class SettingsTabBar extends Component {
  static propTypes = {
    classes:               PropTypes.instanceOf(Object).isRequired,
    clearanceLevel:        PropTypes.number.isRequired,
    navigateToSettingsTab: PropTypes.func.isRequired,
    router:                PropTypes.instanceOf(Object).isRequired,
    teamLevel:             PropTypes.number.isRequired,
    currentChapter:        PropTypes.instanceOf(Map).isRequired,
    currentUser:           PropTypes.instanceOf(Map).isRequired,
    organization:          PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        currentRoute: '/settings/account',
      }),
    };
  }

  componentWillMount() {
    const { router: { location: { pathname } } } = this.props;
    const pathnameValue = this.tabValueExists(pathname) && pathname;

    this.setState({ data: this.state.data.set('currentRoute', pathnameValue) });
  }

  componentWillReceiveProps(nextProps) {
    const currentPath = this.props.router.location.pathname;
    const nextPath = nextProps.router.location.pathname;

    const routeExists = this.tabValueExists(nextPath);
    const tabActive = this.tabActive(nextPath);

    if (currentPath !== nextPath && routeExists) {
      this.setState({
        data: this.state.data.set('currentRoute', nextPath),
      });
    } else if (!routeExists && !tabActive) {
      this.setState({
        data: this.state.data.set('currentRoute', false),
      });
    }
  }

  getTabs = () => {
    const {
      currentChapter,
      currentUser,
      clearanceLevel,
      organization,
      teamLevel,
      router: { location: { pathname } },
    } = this.props;

    let tabs;
    const showAdvancedMatching = currentChapter.getIn(['data', 'showAdvancedMatching'], false);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const isConcernChairUser = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';
    const membershipConcernsEnabled = organization.get('exportingConcernsFeatureEnabled', false);
    const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);

    const votingMemberTabs = Immutable.fromJS([
      {
        label: 'Account',
        route: '/settings/account',
        match: (/\/settings\/.*/),
      },
    ]);

    let concernChairTabs = Immutable.fromJS([
      {
        label: 'Account',
        route: '/settings/account',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Tags',
        route: '/settings/tags',
        match: (/\/settings\/.*/),
      },
    ]);

    if (membershipConcernsEnabled) {
      concernChairTabs = concernChairTabs.push(Map({
        label: 'Export Membership Concerns',
        route: '/settings/export',
        match: (/\/settings\/.*/),
      }));
    }

    const hqDashboardTabs = Immutable.fromJS([
      {
        label: 'Account',
        route: '/dashboard/settings/account',
        icon:  'account_box',
        match: (/\/dashboard\/settings\/.*/),
      },
      {
        label: 'Organization',
        route: '/dashboard/settings/organization',
        icon:  'group',
        match: (/\/dashboard\/settings\/.*/),
      },
      {
        label: 'Concern Categories',
        route: '/dashboard/settings/concern-categories',
        match: (/\/dashboard\/settings\/.*/),
      },
    ]);

    const adminTabs = Immutable.fromJS([
      {
        label: 'Account',
        route: '/settings/account',
        icon:  'account_box',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Chapter',
        route: '/settings/chapter',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Rounds',
        route: '/settings/rounds',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Categories',
        route: '/settings/categories',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Fields',
        route: '/settings/fields',
        match: (/\/settings\/.*/),
      },
      ...(isCdSite ? [{
        label: 'Files',
        route: '/settings/files',
        match: (/\/settings\/.*/),
      }] : []),
      {
        label: 'Hometowns',
        route: '/settings/hometowns',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Users',
        route: '/settings/users',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Tags',
        route: '/settings/tags',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Export',
        route: '/settings/export',
        match: (/\/settings\/.*/),
      },
    ]);

    if (teamLevel === 0) {
      if (isConcernChairUser) {
        tabs = concernChairTabs;
      } else {
        tabs = votingMemberTabs;
      }
    } else if (teamLevel === 1 || teamLevel === 2) {
      tabs = adminTabs;
    } else {
      tabs = votingMemberTabs;
    }

    if (clearanceLevel > 0) {
      tabs = adminTabs;
    }

    if (isAdmin && showAdvancedMatching) {
      tabs = tabs.push(
        Map({
          label: 'Bump Groups',
          route: '/settings/bumpGroups',
          match: (/\/settings\/.*/),
        }),
      );
    }

    if (!currentChapter.get('data')) {
      tabs = votingMemberTabs;
    }

    if (pathname.includes('dashboard')) {
      tabs = hqDashboardTabs;
    }

    return tabs;
  }

  handleChange = (event, value) => {
    const { navigateToSettingsTab } = this.props;

    navigateToSettingsTab(value);
  }

  tabValueExists(route) {
    const tabs = this.getTabs();
    const routeExists = tabs.filter(t => t.get('route') === route).size === 1;

    return routeExists;
  }

  tabActive(route) {
    const tabs = this.getTabs();
    const routeExists = tabs.filter(t => t.get('match').test(route)).size === 1;

    return routeExists;
  }

  renderTabs(tabs) {
    const { classes } = this.props;

    const elements = [];

    tabs.forEach((tab) => {
      elements.push(
        <Tab classes={
            {
              wrapper: classes.wrapper,
              root:    classes.root,
            }
          }
          key={ tab.get('route') }
          label={ tab.get('label') }
          value={ tab.get('route') } />
      );
    });

    return elements;
  }

  render() {
    const { classes, currentChapter } = this.props;
    const currentRoute = this.state.data.get('currentRoute');
    const tabs = this.getTabs();

    let tabsClass = classes.tabs;

    if (!currentChapter.get('data')) {
      tabsClass = classes.tabsFullWidth;
    }

    return (
      <Grid>
        <Tabs value={ currentRoute }
          className={ tabsClass }
          onChange={ this.handleChange }
          indicatorColor='primary'
          textColor='primary'
          scrollButtons='on'
          variant='scrollable'>

          { this.renderTabs(tabs) }

        </Tabs>
      </Grid>
    );
  }
}

const styles = theme => ({
  tabsFullWidth: {
    position:             'fixed',
    zIndex:               10,
    borderTopLeftRadius:  10,
    borderTopRightRadius: 10,
    backgroundColor:      grey[200],
    boxShadow:            '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',

    [theme.breakpoints.up('lg')]: {
      right: 30,
      left:  30,
    },

    [theme.breakpoints.between('sm', 'md')]: {
      right: 30,
      left:  30,
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  tabs: {
    position:             'fixed',
    zIndex:               10,
    borderTopLeftRadius:  10,
    borderTopRightRadius: 10,
    backgroundColor:      grey[200],
    boxShadow:            '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',

    [theme.breakpoints.up('lg')]: {
      right: 30,
      left:  355,
    },

    [theme.breakpoints.between('sm', 'md')]: {
      right: 30,
      left:  30,
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  wrapper: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row', // displays tabs icons in row on larger screens
    },
  },

  root: {
    maxWidth: 'auto',
    minWidth: 'auto',
  },
});

export default withStyles(styles)(SettingsTabBar);
