import React from 'react';

import SpecialtiesTable from '../SpecialtiesTable';

function BulkSpecialtiesTab() {
  return (
    <div>
      <SpecialtiesTable />
    </div>
  );
}

export default BulkSpecialtiesTab;
