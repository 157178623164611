import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { get } from '../../sources/api/client';
import analyticsService from '../../sources/api/analyticsService';

// constants
import { ANALYTICS_FETCH_OVERVIEW_DATA, ANALYTICS_FETCH_OVERVIEW_DATA_INIT } from '../../constants/analyticsTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* analyticsOverviewData(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { user, orgId, overview } = action.payload || {};

  const options = {
    query: {
      user,
      orgId,
      overview,
    },
  };

  // Ensures that services methods are reached.
  //  An empty array id will prevent chapters list from returning
  const FETCH_ID = user;

  try {
    yield* httpSaga(ANALYTICS_FETCH_OVERVIEW_DATA, call(get, analyticsService, FETCH_ID, options));
  } catch (err) {
    yield put(error(ANALYTICS_FETCH_OVERVIEW_DATA, err));
  } finally {
    yield put(doneIndicator(ANALYTICS_FETCH_OVERVIEW_DATA));
  }
}

export function* watchAnalyticsOverviewData() {
  yield takeEvery(ANALYTICS_FETCH_OVERVIEW_DATA_INIT, analyticsOverviewData);
}

export default function* root() {
  yield all([
    fork(watchAnalyticsOverviewData),
  ]);
}
