import React from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';

// components
import Button from '../../../Button';
import AddPnmModal from '../AddPnmModal';
import VoteModal from '../../../Pnm/components/VoteModal';

// mui components
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const menuOptions = Immutable.fromJS([
  { key: 'pnms', label: 'Import PNMs', icon: 'group_add' },
  { key: 'votes', label: 'Import Votes', icon: 'thumbs_up_down' },
  { key: 'hometowns', label: 'Import Hometowns', icon: 'home' },
  { key: 'tags', label: 'Import Tags', icon: 'local_offer' },
  { key: 'events', label: 'Import Events', icon: 'event' },
  { key: 'users', label: 'Import Users', icon: 'group_add' },
]);

class AddButton extends React.Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    navigate:       PropTypes.func.isRequired,
    pnm:            PropTypes.instanceOf(Map).isRequired,
    router:         PropTypes.instanceOf(Object).isRequired,
    vote:           PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        anchorEl:            null,
        clearanceLevel:      '',
        displayAddPnmModal:  false,
        displayAddAnonModal: false,
        teamLevel:           '',
      }),
    };
  }

  componentWillMount() {
    const { currentChapter, currentUser } = this.props;

    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level');
    const teamLevel = (currentChapter.get('data') || Map()).get('team_level');

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('clearanceLevel', clearanceLevel);
        map.set('teamLevel', teamLevel);
      }),
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentChapter, currentUser, router: { location: { pathname } }, pnm, vote,
    } = nextProps;

    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level');
    const teamLevel = (currentChapter.get('data') || Map()).get('team_level');

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('clearanceLevel', clearanceLevel);
        map.set('teamLevel', teamLevel);
        if (!pnm.get('loading') && this.props.pnm.get('loading') && pnm.get('status') === 'success') {
          map.set('displayAddPnmModal', false);
        }
        if (!vote.get('loading') && this.props.vote.get('loading') && vote.get('status') === 'success') {
          map.set('displayAddAnonModalPnmModal', false);
        }
        if (this.props.router.location.pathname !== pathname) map.set('anchorEl', null);
      }),
    });
  }

  handleCloseMenu = () => {
    this.setState({ data: this.state.data.set('anchorEl', null) });
  }

  handleOpenMenu = (e) => {
    this.setState({ data: this.state.data.set('anchorEl', e.currentTarget) });
  }

  handleNavigate = (key) => {
    const { navigate } = this.props;
    navigate(`/import/${key}`);
    this.handleCloseMenu();
  }

  handleOpenAddPnmModal = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('displayAddPnmModal', true);
        map.set('anchorEl', null);
      }),
    });
  }

  handleCloseAddPnmModal = () => {
    this.setState({ data: this.state.data.set('displayAddPnmModal', false) });
  }

  handleOpenAddAnonModal = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('displayAddAnonModal', true);
        map.set('anchorEl', null);
      }),
    });
  }

  handleCloseAddAnonModal = () => {
    this.setState({ data: this.state.data.set('displayAddAnonModal', false) });
  }

  renderMenuOption = (option) => {
    const { currentChapter } = this.props;
    const demoMode = currentChapter.getIn(['data', 'demoMode']) || false;
    const cobMode = currentChapter.getIn(['data', 'cobMode']) || false;
    const isCdSite = currentChapter.getIn(['data', 'isCdSite']) || false;

    let element;

    if ((option.get('key') === 'pnms' && (demoMode || (isCdSite && !cobMode)))
      || (option.get('key') === 'events' && (isCdSite && !cobMode))) {
      return element;
    }

    element = (
      <MenuItem key={ option.get('key') }
        onClick={ () => this.handleNavigate(option.get('key')) }>
        <ListItemIcon>
          <Icon>{ option.get('icon')}</Icon>
        </ListItemIcon>

        { option.get('label')}
      </MenuItem>
    );

    return element;
  }

  render() {
    const {
      classes, currentChapter, currentUser,
    } = this.props;
    const anchorEl = this.state.data.get('anchorEl');
    const displayAddPnmModal = this.state.data.get('displayAddPnmModal');
    const displayAddAnonModal = this.state.data.get('displayAddAnonModal');

    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level');
    const teamLevel = (currentChapter.get('data') || Map()).get('team_level');
    const isVotingOpen = (currentChapter.get('data') || Map()).get('isVotingOpen');
    const demoMode = currentChapter.getIn(['data', 'demoMode']) || false;
    const cobMode = currentChapter.getIn(['data', 'cobMode']) || false;
    const isCdSite = currentChapter.getIn(['data', 'isCdSite']) || false;

    return (
      <div>
        <Button variant='fab'
          color='primary'
          className={ classes.addButton }
          aria-owns={ anchorEl ? 'addMenu' : null }
          aria-haspopup='true'
          onClick={ this.handleOpenMenu }>
          <Icon className={ classes.buttonIcon }>add</Icon>
        </Button>

        <Menu anchorEl={ anchorEl }
          anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
          getContentAnchorEl={ null }
          id='addMenu'
          open={ Boolean(anchorEl) }
          onClose={ this.handleCloseMenu }>

          { (!isCdSite || cobMode) && !demoMode
            && (
            <MenuItem onClick={ this.handleOpenAddPnmModal }>
              <ListItemIcon><Icon>person_add</Icon></ListItemIcon>

              Add PNM
            </MenuItem>
            )}

          { isVotingOpen && (
            <MenuItem onClick={ this.handleOpenAddAnonModal }>
              <ListItemIcon><Icon>help</Icon></ListItemIcon>

              Add Anonymous Vote
            </MenuItem>
          )}

          { (clearanceLevel > 0 || teamLevel > 1) && (
            <div>
              { menuOptions.map(option => this.renderMenuOption(option))}
            </div>
          )}

        </Menu>

        <AddPnmModal open={ displayAddPnmModal }
          onClose={ this.handleCloseAddPnmModal } />

        <VoteModal clearanceLevel={ 0 }
          dialogOpen={ displayAddAnonModal }
          handleDialogClose={ this.handleCloseAddAnonModal }
          teamLevel={ 0 } />
      </div>
    );
  }
}

const styles = theme => ({
  addButton: {
    position:     'absolute',
    zIndex:       3,
    borderRadius: 16,
    transform:    'rotate(45deg)',
    bottom:       25,
    right:        25,

    [theme.breakpoints.down('sm')]: {
      bottom: 15,
      right:  15,
    },
  },

  buttonIcon: {
    transform: 'rotate(45deg)',
  },
});

export default withStyles(styles)(AddButton);
