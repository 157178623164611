import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// components
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// styles
import checkboxInputStyles from './checkboxInput.style';

class CheckboxInput extends Component {
  static propTypes = {
    classes:            PropTypes.instanceOf(Object).isRequired,
    containerClassName: PropTypes.string,
    errorClassName:     PropTypes.string,
    input:              PropTypes.instanceOf(Object).isRequired,
    meta:               PropTypes.instanceOf(Object).isRequired,
    id:                 PropTypes.string,
    label:              PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    size: PropTypes.oneOf(['default', 'small']),
  }

  static defaultProps = {
    containerClassName: '',
    errorClassName:     '',
    id:                 '',
    label:              '',
    size:               'default',
  }

  shouldDisplayError = () => {
    const {
      meta: {
        active,
        dirty,
        error,
        touched,
      },
    } = this.props;

    return error && !active && (dirty || touched);
  }

  renderError = () => {
    const { errorClassName, meta: { error } } = this.props;

    let element;

    if (this.shouldDisplayError()) {
      element = (
        <FormHelperText className={ errorClassName } error>
          { error[0] }
        </FormHelperText>
      );
    }

    return element;
  }

  render() {
    const {
      classes,
      size,
      label,
      id,
      containerClassName,
      errorClassName,
      input,
      ...rest
    } = this.props;

    return (
      <FormControl className={ classNames(containerClassName) }>

        <FormControlLabel className={ classNames(classes.formControlLabel, size) }
          control={ (
            <Checkbox checked={ input.value }
              className={ classNames(classes.formControlCheckbox, size) }
              onChange={ input.onChange(input.value) }
              id={ id }
              color='primary'
              error={ this.shouldDisplayError() }
              inputProps={ { id, ...input } }
              { ...rest } />
          ) }
          label={ label } />

        { this.renderError() }
      </FormControl>
    );
  }
}

export default withStyles(checkboxInputStyles)(CheckboxInput);
