import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';
import pluralize from 'pluralize';

// components
import Filter from '../Filter';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';

class Toolbar extends Component {
  static propTypes = {
    bumpGroupId:       PropTypes.string.isRequired,
    classes:           PropTypes.instanceOf(Object).isRequired,
    currentUser:       PropTypes.instanceOf(Map).isRequired,
    filter:            PropTypes.instanceOf(Object).isRequired,
    itemsToDisplay:    PropTypes.string.isRequired,
    loading:           PropTypes.bool,
    navigateToListTab: PropTypes.func.isRequired,
    numChecked:        PropTypes.number.isRequired,
    onFilterChange:    PropTypes.func.isRequired,
    onSearchChange:    PropTypes.func.isRequired,
    onSortChange:      PropTypes.func.isRequired,
    pathname:          PropTypes.string.isRequired,
    pnm:               PropTypes.instanceOf(Map).isRequired,
  }

  static defaultProps = {
    loading: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        timerId:      null,
        currentRoute: '/match/user/0',
      }),
    };
  }

  componentWillMount() {
    const { pathname = '' } = this.props;

    this.setState({ data: this.state.data.set('currentRoute', pathname) });
  }

  componentWillReceiveProps(nextProps) {
    const currentPath = this.props.pathname;
    const nextPath = nextProps.pathname || '';

    if (currentPath !== nextPath) {
      this.setState({
        data: this.state.data.set('currentRoute', nextPath),
      });
    }
  }

  handleSearchChange = ({ target: { value } }) => {
    const { onSearchChange } = this.props;

    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(onSearchChange, 500, value)),
    });
  }

  handleTabChange = (event, value) => {
    const { navigateToListTab } = this.props;

    navigateToListTab(value);
  }

  renderLoadingIndicator = () => {
    const { classes, loading } = this.props;

    let element;

    if (loading) {
      element = <CircularProgress className={ classes.loadingIndicator } color='inherit' size={ 20 } />;
    }

    return element;
  }

  render() {
    const {
      bumpGroupId,
      classes,
      filter,
      itemsToDisplay,
      onFilterChange,
      onSortChange,
      pnm,
    } = this.props;

    const searchInputClasses = {
      root:      classes.searchRoot,
      underline: classes.searchUnderline,
      input:     classes.searchInput,
    };

    const totalInList = pnm.getIn(['data', 'total'], 0);
    let itemsToSearch = 'PNM';

    if (itemsToDisplay === 'users') {
      itemsToSearch = 'User';
    } else if (itemsToDisplay === 'bumpGroups') {
      itemsToSearch = 'Bump Group';
    }

    const placeholder = `Search ${pluralize(itemsToSearch, totalInList, true)}`;
    const currentRoute = this.state.data.get('currentRoute', '');

    const lastIndex = currentRoute.lastIndexOf('/') + 1;
    const routeId = currentRoute.substring(lastIndex, currentRoute.length);

    // Checks if ID is a number, or a Mongo ID (NaN)
    const userId = isNaN(routeId) // eslint-disable-line
      ? 0
      : routeId;

    return (
      <div className={ classes.toolbar } component='form'>
        <Grid container alignItems='center' justify='flex-end'>
          <Grid item xs={ 10 } className={ classes.searchContainer }>
            <TextField placeholder={ placeholder }
              onChange={ this.handleSearchChange }
              InputProps={ {
                classes:        searchInputClasses,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  this.renderLoadingIndicator()
                ),
              } }
              fullWidth />
          </Grid>

          <Grid item xs={ 2 }>
            <Filter { ...{
              onFilterChange, onSortChange, filter, itemsToDisplay,
            } } />
          </Grid>

          { itemsToDisplay !== 'pnms'
            && (
            <Grid item xs={ 12 }>
              <Tabs value={ currentRoute }
                onChange={ this.handleTabChange }>
                <Tab key='/match/user'
                  label='Users'
                  value={ `/match/user/${userId}` } />
                <Tab key='/match/bumpgroup'
                  label='Bump Groups'
                  value={ `/match/bumpgroup/${bumpGroupId}` } />
              </Tabs>
            </Grid>
            )}
        </Grid>
      </div>
    );
  }
}

Toolbar.propTypes = {
  classes:        PropTypes.instanceOf(Object).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSortChange:   PropTypes.func.isRequired,
};

const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    borderBottom:    `1px solid ${theme.palette.grey['500']}`,
    color:           theme.palette.common.white,
    paddingTop:      5,
  },

  loadingIndicator: {
    position: 'absolute',
    right:    0,
  },

  searchContainer: {
    paddingLeft: 20,
  },

  searchRoot: {
    color: theme.palette.common.white,
  },

  searchUnderline: {
    '&:before': {
      borderColor: theme.palette.common.white,
    },

    '&:hover': {
      '&:before': {
        borderColor: 'rgba(255, 255, 255, 0.87) !important',
      },
    },
  },
});

export default withStyles(styles)(Toolbar);
