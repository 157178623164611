import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import reportingService from '../../sources/api/reportingService';

// constants
import { REPORTING_FETCH_PNMS_TO_CONFIRM, REPORTING_FETCH_PNMS_TO_CONFIRM_INIT } from '../../constants/reportingTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* reportingFetch(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(REPORTING_FETCH_PNMS_TO_CONFIRM, call(find, reportingService, options));
  } catch (err) {
    yield put(error(REPORTING_FETCH_PNMS_TO_CONFIRM, err));
  } finally {
    yield put(doneIndicator(REPORTING_FETCH_PNMS_TO_CONFIRM));
  }
}

export function* watchReportingFetch() {
  yield takeEvery(REPORTING_FETCH_PNMS_TO_CONFIRM_INIT, reportingFetch);
}

export default function* root() {
  yield all([
    fork(watchReportingFetch),
  ]);
}
