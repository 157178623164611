import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { matchListSchema } from '../../schemas/match';

export default function getMatches(state) {
  const chapter = state.get('chapter');
  const match = state.get('match');
  const user = state.get('user');

  const matchData = match.get('data', Map());
  const result = matchData.get('result', List());
  let items = [];

  if (result.size > 0) {
    const users = user.getIn(['data', 'items'], Map());
    const chapters = chapter.getIn(['data', 'items'], Map());
    const matchesData = matchData.get('items', Map());

    const entities = {
      chapter: chapters.toJS(),
      match:   matchesData.toJS(),
      user:    users.toJS(),
    };

    items = denormalize(result.toJS(), matchListSchema, entities);
  }

  return Immutable.fromJS(items);
}
