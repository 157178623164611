import { ROUND_CREATE_INIT, ROUND_REMOVE_INIT, ROUND_BULK_UPDATE_INIT } from '../constants/roundTypes';

export function roundCreate(params) {
  return {
    type:    ROUND_CREATE_INIT,
    payload: params,
  };
}

export function roundRemove(id, chapter, index) {
  return {
    type:    ROUND_REMOVE_INIT,
    payload: { id, chapter, index },
  };
}

export function roundBulkUpdate({ items, chapter, formName }) {
  return {
    type:    ROUND_BULK_UPDATE_INIT,
    payload: {
      items,
      chapter,
      formName,
    },
  };
}
