import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Field } from 'redux-form/immutable';

// mui components
import Grid from '@material-ui/core/Grid';

// local components
import CheckboxInput from '../../../CheckboxInput';

class RoundsFields extends React.PureComponent {
  static propTypes = {
    rounds: PropTypes.instanceOf(Immutable.Iterable).isRequired,
  }

  render() {
    const {
      rounds,
    } = this.props;

    return rounds.map((round) => {
      const name = round.get('name', '');
      const id = round.get('_id', '');
      return (
        <Grid item
          key={ `${name} ${id}` }
          xs={ 12 }>
          <Field name={ `displayRoundScoresList.${id}` }
            label={ name }
            size='small'
            component={ CheckboxInput } />
        </Grid>
      );
    });
  }
}

export default RoundsFields;
