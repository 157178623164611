import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import moment from 'moment';

// components
import ActionModal from '../../../ActionModal';
import MemberInfoForm from './components/MemberInfoForm';
import MemberDateForm from './components/MemberDateForm';

import { withStyles } from '@material-ui/core/styles';
import Button from '../../../Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const NewMemberCard = function ({
  reporting,
  reportingLoading,
  reportingStatus,
  reportingMessage,
  classes,
  confirmPnms,
  currentChapter,
  isEduEmailAllowed,
  reportingPlatformDisplayName,
  memberInfoForm,
  memberDateForm,
  pnms,
  pnmLoading,
}) {
  const [sendModalOpen, setSendModalOpen] = useState(false);

  const pnmsToConfirm = memberInfoForm.getIn(['values', 'pnms'], Immutable.List());
  const checkedPnms = pnmsToConfirm.filter(p => p.get('checked') === true);
  const sendEmailDisabled = Boolean(checkedPnms.size === 0 || memberInfoForm.get('syncErrors') || memberDateForm.get('syncErrors'));

  const loading = pnmLoading || reportingLoading;

  const handleSend = () => {
    const date = memberDateForm.getIn(['values', 'date'], moment().format('YYYY-MM-DD'));

    // Send selected PNMS only to reporting platform
    const pnmsReportingData = checkedPnms.map(item => (
      {
        id:    item.get('id'),
        email: item.get('email'),
        phone: item.get('phone'),
      }
    )).toJS();

    confirmPnms({
      chapter: currentChapter.getIn(['data', 'id'], 0),
      date,
      pnmsReportingData,
    });
  };

  const handleClose = () => {
    if (!loading) {
      setSendModalOpen(false);
    }
  };

  const renderActionModal = () => {
    const additionalProps = {
      onAccept: () => {},
      onReject: () => {},
    };

    let acceptLabel = 'Report';
    let title = 'Report New Members?';
    let description = [
      'New members should not be reported until all PNMs ',
      'have accepted their bids and Bid Day has ended. ',
      `Are you sure you want to report all recruited new members to ${reportingPlatformDisplayName} `,
      'and add them to your chapter roster?',
    ].join('');

    if (reportingStatus === 'reporting') {
      acceptLabel = 'Okay';
      title = 'Reporting Members';
      description = reportingMessage;
      additionalProps.onAccept = () => handleClose();
      additionalProps.onReject = () => handleClose();
    } else {
      additionalProps.onAccept = () => handleSend();
      additionalProps.onReject = () => handleClose();
      additionalProps.rejectLabel = 'Cancel';
    }

    return (
      <ActionModal description={ description }
        acceptLabel={ acceptLabel }
        appState={ reporting }
        key='sendModal'
        loading={ loading }
        open={ sendModalOpen }
        title={ title }
        { ...additionalProps } />
    );
  };

  return (
    <Card>
      <CardContent>
        { pnmLoading ? (
          <Grid container justify='center'>
            <Grid item xs={ 12 } align='center'>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div>
            <Fade in={ !pnmLoading }>
              <Grid container spacing={ 8 }>
                <Grid item xs={ 12 }>
                  <Typography color='primary' variant='h6'>Congratulations!</Typography>
                </Grid>
                <Grid item xs={ 12 }>
                  <Typography>
                    There are
                    {' '}
                    { pnms.size }
                    {' '}
                    new members ready to call your chapter home.
                  </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                  <Typography color='textSecondary'>
                    Once every member has an email on file,
                    you will be able to report them as new
                    members to
                    {' '}
                    {reportingPlatformDisplayName}
                    .
                  </Typography>
                </Grid>

                <MemberInfoForm pnms={ pnms } isEduEmailAllowed={ isEduEmailAllowed } />

                <MemberDateForm />

                <Grid item xs={ 12 }>
                  <Grid container spacing={ 8 }>
                    <Grid item xs={ 12 } sm={ 6 } align='left'>
                      <Button variant='contained'
                        color='primary'
                        disabled={ sendEmailDisabled }
                        onClick={ () => setSendModalOpen(true) }>

                        <Icon className={ classes.leftIcon }>send</Icon>
                        Report as New Members
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>

          </div>
        )}
      </CardContent>

      { renderActionModal() }

    </Card>
  );
};

NewMemberCard.propTypes = {
  classes:                      PropTypes.instanceOf(Object).isRequired,
  confirmPnms:                  PropTypes.func.isRequired,
  currentChapter:               PropTypes.instanceOf(Map).isRequired,
  isEduEmailAllowed:            PropTypes.bool.isRequired,
  memberDateForm:               PropTypes.instanceOf(Map).isRequired,
  memberInfoForm:               PropTypes.instanceOf(Map).isRequired,
  reportingLoading:             PropTypes.bool.isRequired,
  reportingStatus:              PropTypes.string.isRequired,
  reportingMessage:             PropTypes.string,
  pnmLoading:                   PropTypes.bool.isRequired,
  pnms:                         PropTypes.instanceOf(List).isRequired,
  reporting:                    PropTypes.instanceOf(Map).isRequired,
  reportingPlatformDisplayName: PropTypes.string.isRequired,
};

NewMemberCard.defaultProps = {
  reportingMessage: '',
};

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(NewMemberCard);
