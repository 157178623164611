import { Map } from 'immutable';
import { connect } from 'react-redux';

import getTags from '../../../../../../lib/selectors/getTags';

// components
import SingleBallotNoteForm from './SingleBallotNoteForm';

const mapStateToProps = state => ({
  currentUser:  state.get('currentUser'),
  noteForm:     state.getIn(['form', 'noteTag'], Map()),
  organization: state.get('organization'),
  tags:         getTags(state),
});

export default connect(mapStateToProps)(SingleBallotNoteForm);
