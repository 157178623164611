import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';

import { getVoteScore } from '../../../../lib/formulas/voteScoreFormulas';

// components
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '../../../Button';
import VoteForm from '../VoteForm';

class VoteModal extends Component {
  static propTypes = {
    categories:        PropTypes.instanceOf(List).isRequired,
    category:          PropTypes.instanceOf(Map).isRequired,
    classes:           PropTypes.instanceOf(Object).isRequired,
    clearanceLevel:    PropTypes.number.isRequired,
    createVote:        PropTypes.func.isRequired,
    currentChapter:    PropTypes.instanceOf(Map).isRequired,
    currentUser:       PropTypes.instanceOf(Map).isRequired,
    currentVote:       PropTypes.instanceOf(Map),
    dialogOpen:        PropTypes.bool.isRequired,
    fullScreen:        PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    organization:      PropTypes.instanceOf(Map).isRequired,
    pnm:               PropTypes.instanceOf(Object),
    pnmId:             PropTypes.number,
    rounds:            PropTypes.instanceOf(List).isRequired,
    roundCategory:     PropTypes.instanceOf(Map).isRequired,
    teamLevel:         PropTypes.number.isRequired,
    updateVote:        PropTypes.func.isRequired,
    voteForm:          PropTypes.instanceOf(Map).isRequired,
  };

  static defaultProps = {
    pnmId:       0,
    pnm:         Map(),
    currentVote: Map(),
  }

  componentWillReceiveProps(nextProps) {
    const { handleDialogClose } = nextProps;

    if (this.props.voteForm.get('submitSucceeded')) {
      handleDialogClose();
    }
  }

  handleSubmit = () => {
    const {
      categories,
      createVote,
      currentUser,
      currentChapter,
      currentVote,
      organization,
      pnmId,
      rounds,
      updateVote,
      voteForm,
    } = this.props;

    const {
      user, round, comment, ...restOfValues
    } = voteForm.get('values').toJS();

    const currentRound = (currentChapter.get('data') || Map()).get('currentRound');
    const currentEvent = (currentChapter.get('data') || Map()).get('currentEvent');
    const chapter = currentChapter.getIn(['data', 'id'], 0);
    const userForVote = (user || {}).value;
    const currentVoteId = currentVote.get('_id') || 0;

    const voteValues = (restOfValues.vote === undefined)
      ? Object.keys(restOfValues).map(key => (
        { category: key, value: parseFloat(restOfValues[key]) }))
      : restOfValues.vote;

    const roundForVote = round || currentRound || 0;

    const value = getVoteScore(
      organization, Immutable.fromJS(voteValues), categories, currentChapter, roundForVote, rounds
    );

    let submittedByAdmin = 0;
    if (userForVote && currentUser.getIn(['data', 'id']) !== userForVote) {
      submittedByAdmin = currentUser.getIn(['data', 'id']);
    }

    const standardParams = {
      pnm:    pnmId,
      author: userForVote || currentUser.getIn(['data', 'id']),
      round:  roundForVote,
      chapter,
      value,
      comment,
      event:  currentEvent,
    };

    if (submittedByAdmin > 0) {
      standardParams.submittedByAdmin = submittedByAdmin;
    }

    const voteParams = { ...standardParams };

    if (Array.isArray(voteValues)) {
      voteParams.categories = voteValues;
    }

    if (!voteForm.get('syncErrors')) {
      if (currentVoteId) {
        updateVote({
          voteId:   currentVoteId,
          chapter,
          formName: 'voteForm',
          ...voteParams,
        });
      } else {
        createVote(voteParams);
      }
    }
  }

  renderErrorMessage = () => {
    const { voteForm } = this.props;
    let element;

    if (voteForm.get('submitFailed')) {
      element = <FormHelperText error>Vote failed, try again.</FormHelperText>;
    }
    return element;
  };

  render() {
    const {
      categories,
      classes,
      clearanceLevel,
      currentChapter,
      currentUser,
      currentVote,
      dialogOpen,
      fullScreen,
      handleDialogClose,
      organization,
      pnm,
      rounds,
      teamLevel,
      voteForm,
    } = this.props;

    let disableButton = false;

    const currentRound = (currentChapter.get('data') || Map()).get('currentRound');
    const currentChapterId = currentChapter.getIn(['data', 'id'], 0);
    const categoryVotes = currentUser.getIn(['data', 'categoryVotes'], Map());

    if (voteForm.get('syncErrors')) { disableButton = true; }

    let displayPnmInfoOnVote = organization.get('displayPnmInfoOnVote');

    if (displayPnmInfoOnVote === undefined) {
      displayPnmInfoOnVote = (currentChapter.get('data') || Map()).get('displayPnmInfoOnVote');
    }

    return (
      <Dialog open={ dialogOpen }
        onClose={ handleDialogClose }
        fullScreen={ fullScreen }
        maxWidth={ false }>

        <DialogContent classes={ { root: classes.dialogContentRoot } }>
          <VoteForm { ...{
            categories,
            categoryVotes,
            clearanceLevel,
            currentChapterId,
            currentRound,
            currentVote,
            displayPnmInfoOnVote,
            handleDialogClose,
            organization,
            pnm,
            rounds,
            teamLevel,
          } } />

          <DialogActions className={ classes.dialogActionsRoot }>
            { this.renderErrorMessage(voteForm) }

            <Button onClick={ handleDialogClose } disabled={ voteForm.get('submitting') } color='primary'>
              Cancel
            </Button>

            <Button variant='contained'
              onClick={ this.handleSubmit }
              loading={ voteForm.get('submitting') }
              disabled={ disableButton }
              color='primary'>

              <Icon className={ classes.leftIcon } color='inherit'>thumbs_up_down</Icon>
              Submit

            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContentRoot: {
    minHeight:       '60vh',
    minWidth:        '50vw',
    overflowX:       'hidden',
    overflowY:       'auto',
    padding:         0,
    '&:first-child': {
      paddingTop: 0,
    },

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(
  withStyles(styles)(VoteModal)
);
