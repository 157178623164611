import { Map } from 'immutable';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';

// action creators
import { fetchUsersForChapter } from '../../actions/userActions';
import { createMatch } from '../../actions/matchActions';

// components
import MatchModal from './MatchModal';

const mapStateToProps = state => ({
  matchForm: state.get('form').get('matchForm') || Map(),
  chapter:   state.get('chapter'),
  user:      state.get('user'),
});

const mapDispatchToProps = dispatch => ({
  createMatch(params) {
    dispatch(createMatch(params));
  },
  fetchUsers: (params) => {
    dispatch(fetchUsersForChapter(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS());
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:     'matchForm',
  validate: validateForm,
  onChange: handleChange,
})(MatchModal));
