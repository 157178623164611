import Immutable, { Map, List } from 'immutable';

// constants
import {
  DISABLE_DISPLAY_ACTIVE_LIST,
  ENABLE_DISPLAY_ACTIVE_LIST,
  PNM_BULK_UPDATE,
  PNM_UPDATE,
  PNM_CREATE,
  PNM_CLEAR,
  PNM_DELETE,
  PNM_FETCH_ALL,
  PNM_FETCH_ALL_FOR_ANONYMOUS,
  PNM_FETCH,
  PNM_DELETE_ALL_FOR_CHAPTER,
  PNM_UPDATE_ALL_FOR_CHAPTER,
} from '../constants/pnmTypes';

import { VOTE_CREATE, VOTE_DELETE, VOTE_UPDATE } from '../constants/voteTypes';
import { NOTE_CREATE, NOTE_DELETE } from '../constants/noteTypes';
import { MEMBERSHIP_CONCERN_CREATE } from '../constants/membershipConcernTypes';

import { remove } from '../lib/normalizedReducerHelpers';

// lib
import httpReducer from '../lib/httpReducer';

function updateVoteStats(state, action, method) {
  const { payload = {}, meta } = action;
  const { data = {} } = payload;

  const oldData = state.get('data') || Map();
  const oldItems = oldData.get('items') || Map();

  let newData = oldData;

  if (!meta.loading && !Array.isArray(data)) {
    const oldPnm = oldItems.get(String(data.pnm)) || Map();
    let newPnm = oldPnm;

    if (method === 'create') {
      newPnm = oldPnm.withMutations((map) => {
        map.set('voted', true);
        map.set('votedThisRound', true);
        map.set('voteCount', oldPnm.get('voteCount') + 1);
      });
    } else if (method === 'delete') {
      newPnm = oldPnm.withMutations((map) => {
        map.set('voted', false);
        map.set('votedThisRound', false);
        map.set('voteCount', oldPnm.get('voteCount') - 1);
      });
    }

    const newItems = oldItems.set(String(data.pnm), newPnm);
    newData = oldData.set('items', newItems);
  }

  return state.set('data', newData);
}

function updateVotes(state, action) {
  const { payload, meta } = action;
  const { data } = payload || {};

  const oldData = state.get('data') || Map();
  const oldItems = oldData.get('items') || Map();

  let newData = oldData;

  if (!meta.loading && !Array.isArray(data) && data) {
    const oldPnm = oldItems.get(String(data.pnm)) || Map();
    let newPnm = oldPnm;

    let newVotes = List();
    newVotes = newVotes.push(Immutable.fromJS(data));

    newPnm = oldPnm.withMutations((map) => {
      map.set('votes', newVotes);
    });

    const newItems = oldItems.set(String(data.pnm), newPnm);
    newData = oldData.set('items', newItems);
  }

  return state.set('data', newData);
}

function updateTags(state, action, method) {
  const { payload, meta } = action;
  const { data } = payload || {};
  const tags = Immutable.fromJS((data || {}).tags || []);

  const oldData = state.get('data') || Map();
  const oldItems = oldData.get('items') || Map();

  let newData = oldData;

  if (!meta.loading) {
    const oldPnm = oldItems.get(String(data.pnm)) || Map();

    const newPnm = oldPnm.withMutations((map) => {
      if (method === 'create') { // NOTE: tags are populated on NOTE_CREATE
        // loop through the new tag(s) on the payload
        tags.forEach((tag) => {
          const pnmTags = map.get('tags') || List(); // the existing tags on the pnm

          const pnmTagIds = pnmTags.map(t => t.get('_id')) || List();
          const currentTagId = (tag.get('_id') || '').toString();

          if (!pnmTagIds.includes(currentTagId)) {
            // add new tags

            map.set('tags', pnmTags.push(Immutable.fromJS(tag)));
          }
          // update tag counts
          const tagIndex = pnmTags.findIndex(pnmTag => (pnmTag.get('_id') || '').toString() === currentTagId);
          const count = map.getIn(['tags', tagIndex, 'count']) || 0;
          map.setIn(['tags', tagIndex, 'count'], count + 1);
        });
      } else if (method === 'delete') { // NOTE: tags are NOT populated on NOTE_DELETE
        tags.forEach((tag) => {
          const pnmTags = map.get('tags') || List(); // the existing tags on the pnm
          const tagIndex = pnmTags.findIndex(pnmTag => pnmTag.get('_id').toString() === tag.toString());

          // update tag counts
          const count = map.getIn(['tags', tagIndex, 'count']) || 0;
          map.setIn(['tags', tagIndex, 'count'], count - 1);

          if (!count || count === 1) {
            // removes tag
            map.set('tags', pnmTags.splice(tagIndex, 1));
          }
        });
      }
    });

    const newItems = oldItems.set(String(data.pnm), newPnm);
    newData = oldData.set('items', newItems);
  }

  return state.set('data', newData);
}

function pushIdOntoListAttribute(key) {
  return (map, action) => {
    const payloadData = action.payload.data;
    const id = payloadData._id || payloadData.id;
    const pnmId = payloadData.pnm;

    const oldData = map.getIn(['data', 'items', pnmId.toString()]) || Map();
    const oldList = oldData.get(key) || List();

    const newList = oldList.push(id);
    const newData = oldData.set(key, newList);

    return map.setIn(['data', 'items', pnmId.toString()], newData);
  };
}

export default (state = Map({ displayList: true }), action) => {
  switch (action.type) {
    case PNM_FETCH_ALL: return httpReducer(state, action, { entity: 'pnm' });
    case PNM_FETCH_ALL_FOR_ANONYMOUS: return httpReducer(state, action, { entity: 'pnm', result: 'anonymousResult' });
    case PNM_FETCH: return httpReducer(state, action, { entity: 'pnm', updateResult: false });
    case PNM_BULK_UPDATE:
    case PNM_UPDATE_ALL_FOR_CHAPTER:
      return httpReducer(state, action, { entity: 'pnm', updateResult: false });
    case PNM_UPDATE: return httpReducer(state, action, { entity: 'pnm', updateResult: false });
    case PNM_CLEAR: return Map();
    case PNM_DELETE: return httpReducer(state, action, { entity: 'pnm', cb: remove() });
    case PNM_DELETE_ALL_FOR_CHAPTER: return Map();
    case PNM_CREATE: return httpReducer(state, action, { entity: 'pnm', updateResult: false });
    case ENABLE_DISPLAY_ACTIVE_LIST: return state.set('displayList', true);
    case DISABLE_DISPLAY_ACTIVE_LIST: return state.set('displayList', false);
    case VOTE_CREATE: return updateVoteStats(state, action, 'create');
    case VOTE_DELETE: return updateVoteStats(state, action, 'delete');
    case VOTE_UPDATE: return updateVotes(state, action);
    case NOTE_CREATE: return updateTags(state, action, 'create');
    case NOTE_DELETE: return updateTags(state, action, 'delete');
    case MEMBERSHIP_CONCERN_CREATE: return httpReducer(state, action, { cb: pushIdOntoListAttribute('membershipConcerns') });
    default: return state;
  }
};
