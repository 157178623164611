import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

// schemas
import { pnmSchema } from '../../schemas/pnm';

// components
import PnmCard from './components/PnmCard';
import TagsCard from './components/TagsCard';
import TabMenu from './components/TabMenu';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// selectors
import getUserRole from '../../lib/selectors/getUserRole';

class Pnm extends Component {
  static propTypes = {
    clearNotes:         PropTypes.func.isRequired,
    clearVotes:         PropTypes.func.isRequired,
    currentChapter:     PropTypes.instanceOf(Map).isRequired,
    currentUser:        PropTypes.instanceOf(Map).isRequired,
    disableList:        PropTypes.func.isRequired,
    enableList:         PropTypes.func.isRequired,
    fetchNotesForPnm: PropTypes.func.isRequired, // eslint-disable-line
    fetchPnm:         PropTypes.func.isRequired, // eslint-disable-line
    fetchVotesForPnm: PropTypes.func.isRequired, // eslint-disable-line
    match:              PropTypes.instanceOf(Object).isRequired,
    note:               PropTypes.instanceOf(Map).isRequired,
    notes:              PropTypes.instanceOf(List).isRequired,
    organization:       PropTypes.instanceOf(Map).isRequired,
    pnm:                PropTypes.instanceOf(Map).isRequired,
    round:              PropTypes.instanceOf(Map).isRequired,
    tag:                PropTypes.instanceOf(Map).isRequired,
    votes:              PropTypes.instanceOf(List).isRequired,
    membershipConcerns: PropTypes.instanceOf(List).isRequired,
  };

  componentWillMount() {
    const { currentChapter, clearNotes } = this.props;
    if (currentChapter.get('data')) {
      this.initPNM(this.props);
      clearNotes();
      this.initNotes(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentChapter, match: { params: { id } }, clearNotes,
    } = this.props;

    const nextId = nextProps.match.params.id;
    const nextCurrentChapter = nextProps.currentChapter;

    // Let's re-init if a new PNM is selected
    if (id !== nextId || (!currentChapter.get('data') && nextCurrentChapter.get('data'))) {
      this.initPNM(nextProps);
      clearNotes();
      this.initNotes(nextProps);
    }
  }

  getPnm = (props) => {
    const { pnm, match: { params: { id } }, tag } = props;

    const result = pnm.getIn(['data', 'items', id], Map());
    let item = {};

    if (result.size > 0) {
      const tags = tag.getIn(['data', 'items'], List());

      const entities = {
        tag: tags.toJS(),
      };

      item = denormalize(result.toJS(), pnmSchema, entities);
    }

    return Immutable.fromJS(item);
  }

  initPNM = (props) => {
    const { currentChapter, fetchPnm, match: { params: { id } } } = props;

    fetchPnm({
      pnm:     id,
      chapter: currentChapter.getIn(['data', 'id'], 0),
    });
  }

  initNotes = (props) => {
    const { currentChapter, fetchNotesForPnm, match: { params: { id } } } = props;

    fetchNotesForPnm(id, currentChapter.getIn(['data', 'id'], 0));
  }

  render() {
    const {
      clearVotes,
      currentChapter,
      currentUser,
      disableList,
      enableList,
      fetchVotesForPnm,
      match: { params: { id } },
      note,
      notes,
      organization,
      pnm,
      round,
      tag,
      votes,
      membershipConcerns,
    } = this.props;

    const pnmData = this.getPnm(this.props) || Map();
    const isLegacy = pnmData.get('isLegacy') || false;
    const hasLetter = pnmData.get('hasLetter') || false;

    const loading = (pnm.get('loading') && !(pnm.get('data') || Map()).get('result'));
    const currentChapterData = currentChapter.get('data') || Map();
    const currentRound = currentChapterData.get('currentRound', '');

    const showBestMatchedWith = currentChapterData.get('showBestMatchedWith') || false;
    const teamLevel = currentChapterData.get('team_level') || 0;
    const isCdSite = currentChapterData.get('isCdSite') || false;
    const currentRoundExists = Map.isMap(round.getIn(['data', 'items'], List())
      .find(r => r.get('_id') === currentRound));

    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);
    const currentUserId = currentUser.getIn(['data', 'id'], 0);

    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';

    const currentRoundName = round.getIn(['data', 'items', currentRound.toString(), 'name'], '');
    const currentRoundType = round.getIn(['data', 'items', currentRound.toString(), 'roundType'], '').toString();
    const votingLimit = round.getIn(['data', 'items', currentRound.toString(), 'votingLimit'], 0);

    const roundCategories = organization.get('roundCategories', List());
    const currentRoundCategory = roundCategories.find(rc => rc.get('roundType', '').toString() === currentRoundType, {}, Map());

    const hideVotesFromVotingMembers = (organization || Map()).get('hideVotesFromVotingMembers') || false;

    let displayPnmInfoOnVote;
    let oneVotePerPnm = false;
    let disableNotesFeature;
    let allowVotingMembersToTag;
    let allowMemberToVote;
    let displaySingleBallot;
    let oneMemberVotePerPnm;
    let membershipConcernsFeatureEnabled;

    if (organization) {
      oneVotePerPnm = currentRoundCategory.get('oneVotePerPnm') || organization.get('oneVotePerPnm');
      oneMemberVotePerPnm = currentRoundCategory.get('oneMemberVotePerPnm');
      displayPnmInfoOnVote = organization.get('displayPnmInfoOnVote');
      disableNotesFeature = organization.get('disableNotesFeature');
      allowVotingMembersToTag = organization.get('allowVotingMembersToTag');
      allowMemberToVote = teamLevel >= currentRoundCategory.get('teamLevel', 0) || clearanceLevel > 0;
      displaySingleBallot = organization.get('displaySingleBallot');
      membershipConcernsFeatureEnabled = organization.get('membershipConcernsFeatureEnabled');
    }

    if (disableNotesFeature === undefined) {
      disableNotesFeature = currentChapterData.get('disableNotesFeature', false);
    }

    if (!displayPnmInfoOnVote) {
      displayPnmInfoOnVote = currentChapterData.get('displayPnmInfoOnVote', false);
    }
    if (!allowVotingMembersToTag) {
      allowVotingMembersToTag = currentChapterData.get('allowVotingMembersToTag', false);
    }
    if (!displaySingleBallot) {
      displaySingleBallot = currentChapterData.get('displaySingleBallot', false);
    }

    if (allowMemberToVote === undefined) {
      allowMemberToVote = true;
    }

    const tagsLoading = note.get('loading', false) && Boolean(!note.getIn(['data', 'result'], false));

    return (loading
      ? (
        <Grid container justify='center'>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )
      : (
        <Grid container spacing={ 24 }>
          <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
            <Grid container direction='column' spacing={ 24 }>
              <Grid item>
                <PnmCard allowMemberToVote={ allowMemberToVote }
                  allowVotingMembersToTag={ allowVotingMembersToTag }
                  clearanceLevel={ clearanceLevel }
                  clearVotes={ clearVotes }
                  currentChapter={ currentChapter }
                  currentUser={ currentUser }
                  currentRoundExists={ currentRoundExists }
                  currentRoundName={ currentRoundName }
                  currentUserId={ currentUserId }
                  disableList={ disableList }
                  disableNotesFeature={ disableNotesFeature }
                  displayPnmInfoOnVote={ displayPnmInfoOnVote }
                  displaySingleBallot={ displaySingleBallot }
                  enableList={ enableList }
                  membershipConcernsFeatureEnabled={ membershipConcernsFeatureEnabled }
                  fetchVotesForPnm={ fetchVotesForPnm }
                  hideVotesFromVotingMembers={ hideVotesFromVotingMembers }
                  notes={ notes }
                  oneVotePerPnm={ oneVotePerPnm }
                  oneMemberVotePerPnm={ oneMemberVotePerPnm }
                  pnm={ pnm }
                  pnmData={ pnmData }
                  pnmId={ parseInt(id, 10) }
                  pnmLoading={ pnm.get('loading') }
                  votes={ votes }
                  votingLimit={ votingLimit } />
              </Grid>

              <Grid item>
                <TagsCard clearanceLevel={ clearanceLevel }
                  initialTags={ pnmData.get('tags') || List() }
                  isCdSite={ isCdSite }
                  isLegacy={ Boolean(isLegacy) }
                  isConcernChair={ isConcernChair }
                  loading={ tagsLoading }
                  tag={ tag }
                  teamLevel={ teamLevel }
                  hasLetter={ hasLetter } />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 } sm={ 12 } md={ 8 }>
            <TabMenu disableNotesFeature={ disableNotesFeature }
              membershipConcernsFeatureEnabled={ membershipConcernsFeatureEnabled }
              hideVotesFromVotingMembers={ hideVotesFromVotingMembers }
              isAdmin={ isAdmin }
              isCdSite={ isCdSite }
              notes={ notes }
              membershipConcerns={ membershipConcerns }
              pnm={ pnmData }
              chapterId={ currentChapter.getIn(['data', 'id'], 0) }
              showBestMatchedWith={ showBestMatchedWith } />
          </Grid>
        </Grid>
      )
    );
  }
}

export default Pnm;
