import PropTypes from 'prop-types';
import { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

import red from '@material-ui/core/colors/red';

// local components
import Button from '../../../../../Button';
import TextInput from '../../../../../TextInput';

class RemoveUsersForm extends Component {
  static propTypes = {
    classes:                  PropTypes.instanceOf(Object).isRequired,
    formState:                PropTypes.instanceOf(Map).isRequired,
    currentChapter:           PropTypes.instanceOf(Map).isRequired,
    removeAllUsersForChapter: PropTypes.func.isRequired,
  };

  handleRemoveAllUsers = () => {
    const { removeAllUsersForChapter, currentChapter } = this.props;

    const chapter = currentChapter.getIn(['data', 'id'], false);

    if (chapter) {
      removeAllUsersForChapter(chapter);
    }
  }

  renderErrorMessage = (form) => {
    let element;

    if (form.get('submitFailed')) {
      element = <FormHelperText error>Save failed.</FormHelperText>;
    }
    return element;
  };

  render() {
    const { classes, formState } = this.props;

    const removeUsersForm = formState.get('removeUsersForm') || Map();
    let disableButton = false;
    if (removeUsersForm.get('syncErrors')) { disableButton = true; }

    return (
      <Grid container alignItems='center' spacing={ 8 }>
        <Grid item xs={ 12 } sm={ 4 }>
          <Field name='removeUsers'
            label='Type REMOVE'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        { this.renderErrorMessage(removeUsersForm) }

        <Grid item xs={ 12 } sm={ 4 }>
          <Button variant='contained'
            className={ classes.removeAllButton }
            disabled={ disableButton }
            onClick={ this.handleRemoveAllUsers }
            loading={ removeUsersForm.get('submitting') }>

            Remove All Users
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  removeAllButton: {
    backgroundColor: red[500],
    color:           theme.palette.common.white,

    '&:hover': {
      backgroundColor: red[800],
    },
  },
});

export default withStyles(styles)(RemoveUsersForm);
