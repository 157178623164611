import { connect } from 'react-redux';
import { reduxForm, isDirty } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map, List } from 'immutable';

// action creators
import { organizationUpdate } from '../../../../actions/organizationActions';

// components
import Organization from './Organization';

// selectors
import getOrganization from '../../../../lib/selectors/getOrganization';

const createInitialValues = (state) => {
  const organization = getOrganization(state);
  const currentChapter = state.get('currentChapter', Map());
  const round = state.get('round', Map());

  const roundCategories = organization.get('roundCategories', List());
  const currentChapterData = currentChapter.get('data') || Map();
  const currentRound = currentChapterData.get('currentRound', '');
  const currentRoundType = round.getIn(['data', 'items', currentRound.toString(), 'roundType'], '').toString();
  const currentRoundCategory = roundCategories.find(rc => rc.get('roundType', '').toString() === currentRoundType, {}, Map());

  const oneVotePerPnm = currentRoundCategory.get('oneVotePerPnm') || organization.get('oneVotePerPnm');
  const singlePreScoreVote = organization.get('singlePreScoreVote');
  const displayOverallCob = organization.get('displayOverallCob');
  const hideSupportNumber = organization.get('hideSupportNumber');

  const displayPnmInfoOnVote = organization.get('displayPnmInfoOnVote');
  const displayPreScore = organization.get('displayPreScore');
  const disableNotesFeature = organization.get('disableNotesFeature') || false;
  const membershipConcernsFeatureEnabled = organization.get('membershipConcernsFeatureEnabled', false);
  const exportingConcernsFeatureEnabled = organization.get('exportingConcernsFeatureEnabled', false);
  const allowVotingMembersToTag = organization.get('allowVotingMembersToTag');
  const hideNotesFromVotingMembers = organization.get('hideNotesFromVotingMembers');
  const hideNoteTagsFromVotingMembers = organization.get('hideNoteTagsFromVotingMembers');
  const hideMatchesFromVotingMembers = organization.get('hideMatchesFromVotingMembers');

  const values = {
    oneVotePerPnm,
    singlePreScoreVote,
    displayOverallCob,
    hideSupportNumber,
    allowVotingMembersToTag,
    disableNotesFeature,
    membershipConcernsFeatureEnabled,
    exportingConcernsFeatureEnabled,
    displayPnmInfoOnVote,
    displayPreScore,
    hideNotesFromVotingMembers,
    hideNoteTagsFromVotingMembers,
    hideMatchesFromVotingMembers,
  };

  return values;
};

const mapStateToProps = state => ({
  organizationSettingsForm: (state.get('form')).get('organizationSettingsForm') || Map(),
  organization:             state.get('organization'),
  currentUser:              state.get('currentUser'),
  dirty:                    isDirty('organizationSettingsForm'),
  initialValues:            createInitialValues(state),
});

const mapDispatchToProps = dispatch => ({
  updateOrganization: (params) => {
    dispatch(organizationUpdate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS());
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedOrganizationSettingsForm = reduxForm({
  form:               'organizationSettingsForm',
  enableReinitialize: true,
  validate:           validateForm,
  onChange:           handleChange,
})(Organization);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedOrganizationSettingsForm);
