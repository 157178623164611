import PropTypes from 'prop-types';
import { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import green from '@material-ui/core/colors/green';

// local components
import Button from '../../../../../Button';
import TextInput from '../../../../../TextInput';

class PasswordForm extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    updatePassword: PropTypes.func.isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    handleSubmit:   PropTypes.func.isRequired,
    passwordForm:   PropTypes.instanceOf(Map).isRequired,
    resetForm:      PropTypes.func.isRequired,
  };

  handlePasswordChange = () => {
    const {
      updatePassword, currentUser, passwordForm, resetForm,
    } = this.props;

    // Update password from backend
    const updateParams = {
      userId: currentUser.get('id'),
      ...passwordForm.get('values').toJS(),
    };
    updatePassword(updateParams);

    // delay and then reset form
    setTimeout(() => resetForm('passwordForm'), 3000);
  }

  renderSubmitMessage = (form) => {
    const { classes } = this.props;
    let element;

    if (!form.get('syncErrors') && form.get('submitSucceeded')) {
      element = (
        <FormHelperText className={ classNames(classes.helperText, classes.success) }>
          Password changed successfully.
        </FormHelperText>
      );
    } else if (form.get('submitFailed')) {
      element = (
        <FormHelperText className={ classes.helperText } error>
          Password changed failed.
        </FormHelperText>
      );
    }

    return element;
  }

  render() {
    const { handleSubmit, passwordForm } = this.props;

    let disableButton = false;
    if (passwordForm.get('syncErrors')) { disableButton = true; }

    return (
      <Grid container spacing={ 16 } justify='center' component='form'>
        <Grid item xs={ 12 }>
          <Field name='password'
            label='Old password'
            type='password'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>
        <Grid item xs={ 12 }>
          <Field name='newPassword'
            label='New password'
            type='password'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 }>
          <Field name='newPasswordConfirm'
            label='Confirm new password'
            type='password'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } align='center'>
          <Button variant='contained'
            color='primary'
            disabled={ disableButton }
            onClick={ handleSubmit(this.handlePasswordChange) }
            loading={ passwordForm.get('submitting') }>

            Change Password
          </Button>
        </Grid>

        <Grid item xs={ 12 } align='center'>
          { this.renderSubmitMessage(passwordForm) }
        </Grid>

      </Grid>
    );
  }
}

const styles = () => ({
  helperText: {
    textAlign: 'center',
  },

  success: {
    color: green.A700,
  },
});

export default withStyles(styles)(PasswordForm);
