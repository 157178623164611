import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field } from 'redux-form/immutable';
import { Map, List } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// local components
import TextInput from '../../../TextInput';
import TagButtons from '../TagButtons';

// selectors
import getUserRole from '../../../../lib/selectors/getUserRole';

const NoteTagForm = function ({
  classes,
  clearanceLevel,
  currentUser,
  currentChapter,
  allowVotingMembersToTag,
  disableNotesFeature,
  handleDialogClose,
  notes,
  organization,
  pnm,
  singleBallot,
  tags,
  teamLevel,
}) {
  const isAdmin = teamLevel > 0 || clearanceLevel > 0;
  const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';
  const enableNotes = !disableNotesFeature;
  const enableTags = isAdmin || isConcernChair || allowVotingMembersToTag;
  const orgId = currentChapter.getIn(['data', 'organization'], 0).toString();

  let displayPnmInfoOnNote = organization.getIn(['data', 'items', orgId, 'displayPnmInfoOnNote']);

  if (displayPnmInfoOnNote === undefined) {
    displayPnmInfoOnNote = currentChapter.getIn(['data', 'displayPnmInfoOnNote'], false);
  }

  const name = displayPnmInfoOnNote
    ? `${pnm.get('firstname')} ${pnm.get('lastname')}`
    : 'PNM';

  const getTitle = useCallback(() => {
    if (enableNotes && enableTags) return `Write A Note Or Tag ${name}`;
    if (enableNotes && !enableTags) return `Write A Note For ${name}`;
    if (!enableNotes && enableTags) return `Tag ${name}`;
    return 'Write Note';
  }, [name, enableTags, enableNotes]);

  return (
    <div>
      <Grid container
        spacing={ 24 }
        className={ singleBallot ? classes.noteTagHeader : classes.noteTagHeaderFilled }>
        <Grid item sm={ 12 } xs={ 11 }>
          {singleBallot
            ? (
              <Typography variant='subtitle1' color='primary' className={ classes.paddedContainer }>
                {getTitle()}
              </Typography>
            ) : (
              <Typography variant='h6' align='center' gutterBottom className={ !singleBallot && classes.headerText }>
                {getTitle()}
              </Typography>
            )}
        </Grid>

        <Hidden smUp>
          <IconButton className={ classes.closeIcon } onClick={ handleDialogClose }>
            <Icon>close</Icon>
          </IconButton>
        </Hidden>
      </Grid>

      { enableNotes && (
        <Grid container
          justify={ singleBallot ? 'flex-start' : 'center' }
          className={ singleBallot
            ? classes.noteTagTextContainer
            : classes.noteTagTextContainerFilled }>
          <Grid item md={ 10 } xs={ 12 }>
            { singleBallot ? (
              <Field name='comment'
                key='multiline-flexible'
                label={ `Write a note about ${pnm.get('firstname')}` }
                multiline
                type='text'
                variant='outlined'
                component={ TextInput }
                fullWidth
                maxLength={ 1000 } />
            ) : (
              <Paper className={ classes.paperRoot }>
                <Field name='comment'
                  key='multiline-flexible'
                  label={ `Write a note about ${pnm.get('firstname')}` }
                  multiline
                  type='text'
                  component={ TextInput }
                  fullWidth
                  maxLength={ 1000 } />
              </Paper>
            )}
          </Grid>
        </Grid>
      )}

      { enableTags && (
        <Grid container
          justify={ singleBallot ? 'flex-start' : 'center' }
          className={ singleBallot && classes.paddedContainer }
          alignItems='center'>
          <Grid item md={ 8 } xs={ 11 }>
            <Field name='tags'
              component={ TagButtons }
              currentChapter={ currentChapter }
              currentUser={ currentUser }
              notes={ notes }
              organization={ organization }
              tags={ tags } />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

NoteTagForm.propTypes = {
  allowVotingMembersToTag: PropTypes.bool.isRequired,
  classes:                 PropTypes.instanceOf(Object).isRequired,
  clearanceLevel:          PropTypes.number.isRequired,
  currentChapter:          PropTypes.instanceOf(Map).isRequired,
  currentUser:             PropTypes.instanceOf(Map).isRequired,
  disableNotesFeature:     PropTypes.bool.isRequired,
  handleDialogClose:       PropTypes.func.isRequired,
  notes:                   PropTypes.instanceOf(List).isRequired,
  organization:            PropTypes.instanceOf(Map).isRequired,
  pnm:                     PropTypes.instanceOf(Object).isRequired,
  singleBallot:            PropTypes.bool,
  tags:                    PropTypes.instanceOf(List).isRequired,
  teamLevel:               PropTypes.number.isRequired,
};

NoteTagForm.defaultProps = {
  singleBallot: false,
};

const styles = theme => ({
  noteTagHeader: {
    paddingTop:   15,
    marginBottom: 15,
  },
  noteTagHeaderFilled: {
    backgroundColor: theme.palette.primary.main,
    paddingTop:      15,
    marginBottom:    15,
  },

  headerText: {
    color: theme.palette.common.white,
  },

  paddedContainer: {
    paddingLeft:  40,
    paddingRight: 40,
  },

  closeIcon: {
    position: 'absolute',
    top:      10,
    right:    0,
    color:    theme.palette.common.white,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  noteTagTextContainer: {
    display:      'flex',
    padding:      25,
    paddingLeft:  40,
    marginBottom: 20,
    marginTop:    -15,
  },
  noteTagTextContainerFilled: {
    display:           'flex',
    backgroundColor:   theme.palette.primary.main,
    padding:           25,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.dark,
    marginBottom:      20,
    marginTop:         -15,
  },

  paperRoot: theme.mixins.gutters({
    paddingTop:    16,
    paddingBottom: 16,
  }),

});

export default withStyles(styles)(NoteTagForm);
