import { SLIDESHOW_FETCH_PNMS_INIT } from '../constants/slideshowTypes';

export function fetchSlideshowPnms({
  chapter, pnmIds = [], sortBy, order, filter = {},
}) {
  return {
    type:    SLIDESHOW_FETCH_PNMS_INIT,
    payload: {
      chapter,
      pnmIds,
      sortBy,
      order,
      filter,
    },
  };
}
