import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import React, { useState } from 'react';
import { Field } from 'react-final-form';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import red from '@material-ui/core/colors/red';

// local components
import Button from '../../../../../Button';
import TextInput from '../../../../../TextInput';
import SelectInput from '../../../../../SelectInput';
import ActionModal from '../../../../../ActionModal';

// image assets
import logo from '../../../../../../assets/images/cd_logo.png';

const FieldItem = function ({
  chapterFields,
  classes,
  currentChapter,
  field,
  handlePopoverOpen,
  index,
  loading,
  pageEnd,
  pageStart,
  removeField,
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const fieldItem = chapterFields.get(index, Map());
  const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);
  const cobMode = currentChapter.getIn(['data', 'cobMode'], false);

  const standardField = chapterFields.getIn([index, 'standardField']);
  const isDefault = standardField.get('default') === 1;
  const isShared = standardField.get('isShared');
  const isDisabled = (isCdSite && !cobMode) || isShared || isDefault;

  const getVisibilityOptions = () => {
    const items = [
      { value: 0, label: 'All' },
      { value: 1, label: 'Admins' },
      { value: 2, label: 'None' },
    ];

    return items;
  };

  const handleDelete = () => {
    const fieldId = chapterFields.getIn([index, '_id']);
    const chapterId = currentChapter.getIn(['data', 'id']);

    removeField(fieldId, chapterId);
    setDeleteModalOpen(false);
  };

  const renderLogo = () => {
    let element;

    if (fieldItem.get('fromCD') === 1) {
      element = (
        <img src={ logo } className={ classes.logo } alt='Field From CampusDirector' />
      );
    }
    return element;
  };

  // Handles pagination filtering, without leaving items out of final-form
  //  Filtering before the Form component will prevent items from reordering
  if (index < pageStart || index > pageEnd - 1) {
    return null;
  }

  return (
    <Paper className={ classes.itemContainer }>
      <Grid container
        key={ fieldItem.get('_id') || field }
        spacing={ 24 }
        className={ classes.itemWrapper }
        justify='space-between'>

        <Grid item xs={ 3 } sm={ 1 }>
          <Tooltip id='field-move-tip'
            placement='bottom'
            title='Click or drag to reorder'>

            <Fab color='primary' onClick={ event => handlePopoverOpen(event, index) }>
              <Icon>
                list
              </Icon>
            </Fab>
          </Tooltip>
        </Grid>

        <Grid item xs={ 9 } sm={ 5 } align='left'>
          <Field name={ `${field}.standardField.text` }
            label='Field Name'
            type='text'
            variant='outlined'
            margin='dense'
            disabled={ isDisabled }
            component={ TextInput }
            fullWidth
            helpertext={ renderLogo() }
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 3 }>
          <Field name={ `${field}.teamLevel` }
            label='Visible To'
            component={ SelectInput }
            variant='outlined'
            margin='dense'
            options={ getVisibilityOptions() }
            fullWidth
            required />
        </Grid>

        { !isDisabled ? [
          <Grid item xs={ 12 } sm={ 3 } key='button' className={ classes.redButton }>
            <Button variant='text' color='inherit' onClick={ () => setDeleteModalOpen(true) }>
              <Icon className={ classes.leftIcon }>delete</Icon>
              Delete
            </Button>
          </Grid>,

          <ActionModal open={ deleteModalOpen }
            key='modal'
            description='This field will no longer appear for any user in PNM’s profiles.'
            onAccept={ () => handleDelete(index) }
            onReject={ () => setDeleteModalOpen(false) }
            acceptLabel='Delete'
            rejectLabel='Cancel'
            loading={ loading }
            title='Are you sure you want to delete this field?' />,
        ] : <Grid item xs={ 12 } sm={ 3 } /> }

      </Grid>
    </Paper>
  );
};
const styles = theme => ({
  itemContainer: {
    padding:      15,
    borderRadius: 10,
  },

  itemWrapper: {
    marginTop:    -4,
    marginBottom: -24,
  },

  fieldIcon: {
    height:          50,
    width:           50,
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  logo: {
    width: 105,
  },

  redButton: {
    color: red[500],
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

FieldItem.propTypes = {
  chapterFields:     PropTypes.instanceOf(List).isRequired,
  classes:           PropTypes.instanceOf(Object).isRequired,
  currentChapter:    PropTypes.instanceOf(Map).isRequired,
  field:             PropTypes.string.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  index:             PropTypes.number.isRequired,
  loading:           PropTypes.bool.isRequired,
  pageEnd:           PropTypes.number.isRequired,
  pageStart:         PropTypes.number.isRequired,
  removeField:       PropTypes.func.isRequired,
};

export default withStyles(styles)(FieldItem);
