import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// action creators
import { sessionDestroy } from '../../../../actions/sessionActions';

// components
import ProfileMenu from './ProfileMenu';

const mapStateToProps = state => ({
  currentUser:    state.get('currentUser'),
  currentChapter: state.get('currentChapter'),
  router:         state.get('router'),
});

const mapDispatchToProps = dispatch => ({
  destroySession: () => {
    dispatch(sessionDestroy());
  },

  navigate: (route) => {
    dispatch(push(route));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
