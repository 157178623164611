import { Map } from 'immutable';
import { connect } from 'react-redux';

import { voteCreate, voteUpdate } from '../../../../actions/voteActions';

import getOrganization from '../../../../lib/selectors/getOrganization';
import getRounds from '../../../../lib/selectors/getRounds';
import getCategories from '../../../../lib/selectors/getCategories';

// components
import VoteModal from './VoteModal';

const mapStateToProps = state => ({
  category:       state.get('category'),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  rounds:         getRounds(state),
  categories:     getCategories(state),
  organization:   getOrganization(state),
  roundCategory:  state.get('roundCategory'),
  voteForm:       state.get('form').get('voteForm') || Map(),
});

const mapDispatchToProps = dispatch => ({
  createVote: (params) => {
    dispatch(voteCreate(params));
  },
  updateVote: (params) => {
    dispatch(voteUpdate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoteModal);
