import { connect } from 'react-redux';
import { Map } from 'immutable';

// components
import RoundItem from './RoundItem';

// selectors
import getOrganization from '../../../../../../lib/selectors/getOrganization';
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';
import getStatusFromState from '../../../../../../lib/selectors/getStatusFromState';

// action creators
import { voteDelete } from '../../../../../../actions/voteActions';
import { currentChapterFetchVotes } from '../../../../../../actions/chapterActions';

const getRoundVotes = (state) => {
  const rounds = state.getIn(['round', 'data', 'items'], Map()).toList();
  const roundsArr = rounds.map((round) => {
    let roundsObj = {};
    roundsObj = { order: round.get('order'), votes: round.get('roundHasVotes') };
    return roundsObj;
  });

  // rounds->items : if order of rounds is changed before a hard-refresh then
  // the items array will appear out of order - so sort based on order first
  roundsArr.sort((orderA, orderB) => orderA.order - orderB.order);
  const orderedRoundVotes = roundsArr.map(obj => obj.votes);
  return Array.from(orderedRoundVotes);
};

const mapStateToProps = state => ({
  form:          state.get('form'),
  organization:  getOrganization(state),
  roundLoading:  getLoadingFromState('round')(state),
  roundStatus:   getStatusFromState('round')(state),
  roundCategory: state.get('roundCategory'),
  roundVotes:    getRoundVotes(state),
});

const mapDispatchToProps = dispatch => ({
  deleteVotes:     (params) => { dispatch(voteDelete(params)); },
  fetchRoundVotes: () => { dispatch(currentChapterFetchVotes()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoundItem);
