import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  startSubmit, setSubmitFailed, setSubmitSucceeded, destroy,
} from 'redux-form/immutable';

// api
import { patch } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import { PNM_UPDATE_ALL_FOR_CHAPTER, PNM_UPDATE_ALL_FOR_CHAPTER_INIT } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmListSchema } from '../../schemas/pnm';

export function* pnmUpdateAllForChapterSaga(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter, formName, filter, newData,
  } = action.payload;

  const FORM = formName || ' ';
  yield put(startSubmit(FORM));

  const options = {
    query: {
      chapter,
      bulkUpdate: true,
      updateAll:  newData,
    },
  };

  if (filter) {
    options.query.filter = filter;
  }

  try {
    yield* httpSaga(PNM_UPDATE_ALL_FOR_CHAPTER, call(patch, pnmService, null, {}, options), {
      schema: pnmListSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    if (FORM !== 'memberInfoForm' || FORM !== 'managePnmForm') {
      yield put(destroy(FORM));
    }
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(PNM_UPDATE_ALL_FOR_CHAPTER, err));
  } finally {
    yield put(doneIndicator(PNM_UPDATE_ALL_FOR_CHAPTER));
  }
}

export function* watchPnmUpdateAllForChapter() {
  yield takeEvery(PNM_UPDATE_ALL_FOR_CHAPTER_INIT, pnmUpdateAllForChapterSaga);
}

export default function* root() {
  yield all([
    fork(watchPnmUpdateAllForChapter),
  ]);
}
