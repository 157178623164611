import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { get, patch } from '../../sources/api/client';
import organizationService from '../../sources/api/organizationService';

// constants
import { ORGANIZATION_UPDATE, ORGANIZATION_UPDATE_INIT, ORGANIZATION_FETCH } from '../../constants/organizationTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { organizationSchema } from '../../schemas/organization';

export function* organizationUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { organizationId, ...values } = action.payload;
  const FORM = action.payload.formName || ' ';
  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(ORGANIZATION_UPDATE,
      call(patch, organizationService, organizationId, { ...values }),
      {
        schema: organizationSchema,
      });

    yield* httpSaga(ORGANIZATION_FETCH, call(get, organizationService, organizationId), {
      schema: organizationSchema,
    });

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(ORGANIZATION_UPDATE, err));
  } finally {
    yield put(doneIndicator(ORGANIZATION_UPDATE));
  }
}

export function* watchOrganizationUpdate() {
  yield takeEvery(ORGANIZATION_UPDATE_INIT, organizationUpdate);
}

export default function* root() {
  yield all([
    fork(watchOrganizationUpdate),
  ]);
}
