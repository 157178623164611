import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';
import { Field } from 'redux-form/immutable';

// components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import VoteButtons from '../VoteButtons';
import VoteSlider from '../VoteSlider';
import TextInput from '../../../TextInput';

const VoteInput = function ({
  categoryVotes,
  classes,
  currentVote,
  isAdmin,
  item,
  voteForm,
}) {
  VoteInput.propTypes = {
    categoryVotes: PropTypes.instanceOf(Map),
    classes:       PropTypes.instanceOf(Object).isRequired,
    currentVote:   PropTypes.instanceOf(Map).isRequired,
    isAdmin:       PropTypes.bool.isRequired,
    item:          PropTypes.instanceOf(Map).isRequired,
    voteForm:      PropTypes.instanceOf(Map).isRequired,
  };

  VoteInput.defaultProps = {
    categoryVotes: Map(),
  };

  const name = item.get('name');
  const description = item.get('description');
  const minValue = parseFloat(item.get('minOptionValue'));
  const maxValue = parseFloat(item.get('maxOptionValue'));
  const optionIncrement = (Math.round(parseFloat(item.get('optionIncrement')) * 1000) / 1000) || 1;

  let sliderValue;

  const itemId = item.get('_id', '').toString();

  let currentCategoryVote = Map();
  if (categoryVotes) {
    currentCategoryVote = categoryVotes.get(itemId, Map());
  }

  if (item.get('type') === 'range') {
    sliderValue = voteForm.getIn(['values', item.get('_id')], 'N/A');
  }

  const checkRange = (value) => {
    if (minValue !== undefined && value && value < minValue) {
      return minValue;
    } if (maxValue !== undefined && value && value > maxValue) {
      return maxValue;
    }
    return value;
  };

  // Checks range of options and forces user to enter number that is a valid vote weight
  const checkOptions = (v) => {
    const value = parseFloat(v || 0);
    const options = item.get('options') || List();
    let minOptionValue;
    let maxOptionValue;

    const optionValues = [];

    options.forEach((option) => {
      if (minOptionValue === undefined || option.get('value') < minOptionValue) {
        minOptionValue = option.get('value');
      }
      if (maxOptionValue === undefined || option.get('value') > maxOptionValue) {
        maxOptionValue = option.get('value');
      }

      optionValues.push(option.get('value'));
    });

    if (minOptionValue !== undefined && value && value < minOptionValue) {
      return minValue;
    } if (maxOptionValue !== undefined && value && value > maxOptionValue) {
      return maxOptionValue;
    } if (!optionValues.includes(value)) {
      return '';
    }
    return value;
  };

  const renderVoteInputComponent = () => {
    let element;

    switch (item.get('type')) {
      case 'button':
      case 'icon':
        element = (item.get('options') || List()).size < 15 ? (
          <Field name={ item.get('_id') }
            options={ item.get('options') }
            component={ VoteButtons }
            categoryVote={ currentCategoryVote }
            currentVote={ currentVote }
            isAdmin={ isAdmin }
            type={ item.get('type') }
            required />
        ) : (
          <Paper className={ classes.paperRoot }>
            <Field name={ item.get('_id') }
              options={ item.get('options') }
              component={ TextInput }
              normalize={ checkOptions }
              placeholder='Enter valid value'
              type='number'
              required />
          </Paper>
        );
        break;

      case 'gpa':
        element = (
          <Paper className={ classes.paperRoot }>
            <Field name={ item.get('_id') }
              component={ TextInput }
              type='number'
              placeholder='3.50'
              label='GPA'
              fullWidth
              normalize={ checkRange }
              required />
          </Paper>
        );
        break;

      case 'number':
        element = (
          <Paper className={ classes.paperRoot }>
            <Field name={ item.get('_id') }
              component={ TextInput }
              type='number'
              placeholder='Enter valid value'
              label='Score'
              fullWidth
              normalize={ checkRange }
              required />
          </Paper>
        );
        break;

      case 'range':
        element = [
          <Grid item
            xs={ 12 }
            key={ `${item.get('_id')}_label` }
            align='center'>
            <Grid container justify='center'>
              <Grid item xs={ 2 }>
                <Typography variant='h6'
                  className={ classes.voteValueTitle }
                  align='center'
                  gutterBottom>

                  Score:
                  {' '}
                  { sliderValue }
                </Typography>
              </Grid>
            </Grid>
          </Grid>,
          <Grid item xs={ 10 }>
            <Field name={ item.get('_id') }
              key={ `${item.get('_id')}_field` }
              id={ item.get('id') }
              defaultValue={ maxValue - optionIncrement }
              optionIncrement={ optionIncrement }
              minValue={ minValue }
              maxValue={ maxValue }
              component={ VoteSlider }
              required />
          </Grid>,
        ];
        break;

      default:
        break;
    }

    return element;
  };

  return (
    <Grid container
      spacing={ 8 }
      justify='center'
      align='center'
      className={ classes.voteInputContainer }>
      <Grid item xs={ 12 }>
        <Typography variant='h6'
          align='center'
          className={ classes.voteCategoryTitle }
          gutterBottom>
          { name }
        </Typography>

        {Boolean(description)
          && description.split('\n').map(desc =>
            (
              <Typography align='center'
                className={ classes.voteCategoryTitle }
                gutterBottom>
                { desc }
              </Typography>
            ))}

      </Grid>

      { renderVoteInputComponent() }
    </Grid>
  );
};

const styles = theme => ({
  voteInputContainer: {
    display:           'flex',
    backgroundColor:   theme.palette.primary.main,
    padding:           25,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.dark,
  },

  voteCategoryTitle: {
    color: theme.palette.common.white,
  },

  voteValueTitle: {
    color:         theme.palette.common.white,
    border:        '1px solid white',
    paddingTop:    10,
    paddingBottom: 10,
    borderRadius:  100,
    marginBottom:  10,
  },

  voteTextFieldContainer: {
    display:           'flex',
    backgroundColor:   theme.palette.primary.main,
    padding:           25,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.dark,
  },

  paperRoot: theme.mixins.gutters({
    paddingTop:    16,
    paddingBottom: 16,
    marginTop:     theme.spacing.unit * 3,
  }),

  range: {
    marginLeft:  '10%',
    marginRight: '10%',
  },
});

export default withStyles(styles)(VoteInput);
