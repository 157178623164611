import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create } from '../../sources/api/client';
import bumpGroupService from '../../sources/api/bumpGroupService';

// constants
import { BUMP_GROUP_GENERATE, BUMP_GROUP_GENERATE_INIT } from '../../constants/bumpGroupTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* bumpGroupGenerate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter, membersPerGroup,
  } = action.payload || {};

  try {
    const options = {
      query: {
        chapter,
        membersPerGroup,
        shouldGenerateBumpGroups: true,
      },
    };

    const item = {
      chapter,
      author: 0,
    };

    yield* httpSaga(BUMP_GROUP_GENERATE, call(create, bumpGroupService, item, options));
  } catch (err) {
    yield put(error(BUMP_GROUP_GENERATE, err));
  } finally {
    yield put(doneIndicator(BUMP_GROUP_GENERATE));
  }
}

export function* watchResultFetchAllForChapter() {
  yield takeLatest(BUMP_GROUP_GENERATE_INIT, bumpGroupGenerate);
}

export default function* root() {
  yield all([
    fork(watchResultFetchAllForChapter),
  ]);
}
