import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import classNames from 'classnames';

// components
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CardContent from '@material-ui/core/CardContent';
import CircularStatProgress from '../CircularStatProgress';

// selectors
import getUserRole from '../../../../lib/selectors/getUserRole';

// styles and images
import Logo from '../../../../assets/images/cd_logo.png';

class MainCardBody extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    clearanceLevel: PropTypes.number.isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    organization:   PropTypes.instanceOf(Map).isRequired,
    pnm:            PropTypes.instanceOf(Map).isRequired,
    teamLevel:      PropTypes.number.isRequired,
    user:           PropTypes.instanceOf(Map).isRequired,
    vote:           PropTypes.instanceOf(Map).isRequired,
    votes:          PropTypes.instanceOf(List).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data:        Map(),
      initialized: false, // eslint-disable-line
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentChapter, pnm, vote, user,
    } = nextProps;

    const chapterId = currentChapter.getIn(['data', 'id'], 0);
    const initialized = this.state.data.get('initialized') || false;

    if (!initialized && !user.get('loading') && !pnm.get('loading') && !vote.get('loading') && chapterId) {
      this.setState({ data: this.state.data.set('initialized', true) });
    }
  }

  render() {
    const {
      classes, clearanceLevel, currentChapter, currentUser,
      organization, pnm, teamLevel, vote, votes,
    } = this.props;

    let usersVotedList = List();

    votes.forEach((v) => {
      if (!usersVotedList.includes(v.get('author'))) {
        usersVotedList = usersVotedList.push(v.get('author'));
      }
    });

    const usersVoted = currentChapter.getIn(['data', 'usersVoted']) || 0;
    const isCdSite = currentChapter.getIn(['data', 'isCdSite']) || false;
    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen']) || false;
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';
    const showBestMatchedWith = currentChapter.getIn(['data', 'showBestMatchedWith'], false);
    const matchRecs = currentUser.getIn(['data', 'matchRecs'], 0);
    const organizationId = currentChapter.getIn(['data', 'organization'], 0);
    const hideSupportNumber = organization.getIn(['data', 'items', organizationId.toString(), 'hideSupportNumber'], false);

    const totalActive = pnm.getIn(['data', 'totalActive'], 0);
    const totalPnms = pnm.getIn(['data', 'total'], 0);

    const totalUsers = currentChapter.getIn(['data', 'totalUsers'], 0);
    const totalVotes = vote.getIn(['data', 'items'], List()).size
      ? vote.getIn(['data', 'total'], 0)
      : 0;

    const initialized = this.state.data.get('initialized') || false;

    return (
      <CardContent>
        { !initialized ? (
          <Grid container justify='center'>
            <Grid item xs={ 12 } align='center'>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Fade in={ initialized }>
            <Grid container spacing={ 8 }>
              <Grid item xs={ 12 } md={ 5 }>
                <Grid container
                  className={ classNames(classes.outlinedContainer, classes.paddedContainer) }>
                  <Grid item xs={ 2 }>
                    <Icon className={ classes.icon }>people</Icon>
                  </Grid>
                  <Grid item xs={ 10 }>
                    <Typography variant='subtitle1' color='textSecondary'>Active PNMs</Typography>
                  </Grid>

                  <Grid item xs={ 12 }>
                    <Typography variant='h2' color='inherit'>
                      { isAdmin || isConcernChair
                        ? `${totalActive} / ${totalPnms}`
                        : totalActive}
                    </Typography>
                  </Grid>
                </Grid>

                { isAdmin
                && (
                <Grid container
                  className={ classNames(classes.outlinedContainer, classes.paddedContainer) }>
                  <Grid item xs={ 2 }>
                    <Icon className={ classes.icon }>speaker_phone</Icon>
                  </Grid>
                  <Grid item xs={ 10 }>
                    <Typography variant='subtitle1' color='textSecondary'>Chapter Members</Typography>
                  </Grid>

                  <Grid item xs={ 12 }>
                    <Typography variant='h2' color='inherit'>
                      { totalUsers }
                    </Typography>
                  </Grid>
                </Grid>
                )}

                { showBestMatchedWith
                  && (
                  <Grid container
                    className={ classNames(classes.outlinedContainer, classes.paddedContainer) }>
                    <Grid item xs={ 2 }>
                      <Icon className={ classes.icon }>extension</Icon>
                    </Grid>
                    <Grid item xs={ 10 }>
                      <Typography variant='subtitle1' color='textSecondary'>Matches</Typography>
                    </Grid>

                    <Grid item xs={ 12 }>
                      <Typography variant='h2' color='inherit' gutterBottom>
                        { matchRecs }
                      </Typography>
                    </Grid>

                    <Grid item xs={ 12 }>
                      <Typography color='textSecondary'>
                        Filter list to view PNMs matched to you
                      </Typography>
                    </Grid>
                  </Grid>
                  )}
              </Grid>

              <Grid item xs={ 12 } md={ 7 }>
                { isAdmin && isVotingOpen && (
                  <div className={ classes.progressContainer }>
                    <Grid item
                      xs={ 6 }
                      align='center'
                      className={ classNames(
                        classes.progressItem,
                        classes.outlinedContainer,
                        classes.standardPadding,
                      ) }>
                      <Typography color='textSecondary' variant='subtitle1'>Votes Cast</Typography>
                      <Typography color='textSecondary'>Current Round</Typography>
                      <CircularStatProgress max={ totalActive } value={ totalVotes } />
                    </Grid>

                    <Grid item
                      xs={ 6 }
                      align='center'
                      className={ classNames(
                        classes.outlinedContainer,
                        classes.standardPadding,
                      ) }>
                      <Typography color='textSecondary' variant='subtitle1'>Members Voted</Typography>
                      <Typography color='textSecondary'>Current Round</Typography>
                      <CircularStatProgress max={ totalUsers } value={ usersVoted } />
                    </Grid>
                  </div>
                )}

                { isCdSite
                  && (
                  <Grid container
                    className={ classNames(
                      classes.logoContainer, classes.paddedContainer, classes.outlinedContainer
                    ) }>
                    <Grid item>
                      <Icon className={ classes.logoIcon }>sync</Icon>
                      <Tooltip title='Synced with CampusDirector'>
                        <img src={ Logo } alt='CampusDirector Sync' className={ classes.logoImage } />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  )}

                { isAdmin && !hideSupportNumber && (
                <Grid container className={ classes.outlinedContainer }>
                  <Grid item xs={ 12 } className={ classes.standardPadding }>
                    <Typography>
                      Having trouble with MyVote?
                      Our support team is on call 24/7 during primary recruitment dates.
                    </Typography>
                    <Typography>
                      Note, by calling our number you are agreeing to receive a text message
                      response that includes notifications about wait times and an optional survey.
                    </Typography>
                  </Grid>

                  <Grid item xs={ 12 } className={ classes.outlinedItem }>
                    <Grid container>
                      <Grid item xs={ 1 }>
                        <Icon color='primary'>phone</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography color='primary'>
                          Call us at 888.280.0676
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                )}
              </Grid>
            </Grid>
          </Fade>
        )}
      </CardContent>
    );
  }
}

const styles = () => ({
  outlinedContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    marginBottom: 10,
  },

  paddedContainer: {
    padding: '15px 15px 15px 25px',
  },

  logoIcon: {
    color:       'rgba(0, 0, 0, 0.54)',
    marginRight: 5,
  },

  logoImage: {
    width: 125,
  },

  outlinedItem: {
    borderTop: '1px solid #bfbfbf',
    padding:   15,
  },

  progressContainer: {
    display: 'flex',
  },

  progressItem: {
    marginRight: 10,
  },

  standardPadding: {
    padding: 15,
  },

  icon: {
    fontSize: 25,
    color:    'rgba(0, 0, 0, 0.54)',
  },

  cursorContainer: {
    cursor: 'pointer',
  },
});

export default withStyles(styles)(MainCardBody);
