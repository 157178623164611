import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';

// local components
import VoteForm from '../../../VoteForm';

const SingleBallotVoteForm = function ({
  categories,
  clearanceLevel,
  currentChapter,
  currentUser,
  currentVote,
  handleDialogClose,
  organization,
  pnm,
  rounds,
  teamLevel,
}) {
  const currentRound = currentChapter.getIn(['data', 'currentRound'], Map());
  const currentChapterId = currentChapter.getIn(['data', 'id'], 0);
  const categoryVotes = currentUser.getIn(['data', 'categoryVotes'], Map());

  let displayPnmInfoOnVote = organization.get('displayPnmInfoOnVote');

  if (displayPnmInfoOnVote === undefined) {
    displayPnmInfoOnVote = (currentChapter.get('data') || Map()).get('displayPnmInfoOnVote');
  }

  return (
    <VoteForm { ...{
      categories,
      categoryVotes,
      clearanceLevel,
      currentChapterId,
      currentRound,
      currentVote,
      displayPnmInfoOnVote,
      handleDialogClose,
      organization,
      pnm,
      singleBallot: true,
      rounds,
      teamLevel,
    } } />
  );
};

SingleBallotVoteForm.propTypes = {
  categories:        PropTypes.instanceOf(List).isRequired,
  classes:           PropTypes.instanceOf(Object).isRequired,
  clearanceLevel:    PropTypes.number.isRequired,
  currentChapter:    PropTypes.instanceOf(Map).isRequired,
  currentUser:       PropTypes.instanceOf(Map).isRequired,
  currentVote:       PropTypes.instanceOf(Map),
  dialogOpen:        PropTypes.bool.isRequired,
  fullScreen:        PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  organization:      PropTypes.instanceOf(Map).isRequired,
  pnm:               PropTypes.instanceOf(Object),
  pnmId:             PropTypes.number,
  rounds:            PropTypes.instanceOf(List).isRequired,
  teamLevel:         PropTypes.number.isRequired,
  updateVote:        PropTypes.func.isRequired,
};

SingleBallotVoteForm.defaultProps = {
  pnmId:       0,
  pnm:         Map(),
  currentVote: Map(),
};

export default SingleBallotVoteForm;
