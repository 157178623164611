import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { patch } from '../../sources/api/client';
import reportingService from '../../sources/api/reportingService';

// constants
import { REPORTING_CONFIRM_PNMS, REPORTING_CONFIRM_PNMS_INIT } from '../../constants/reportingTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* reportingConfirmPnms(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter, date, pnmsReportingData } = action.payload || {};
  const options = { query: { chapter } };

  try {
    yield* httpSaga(REPORTING_CONFIRM_PNMS, call(patch, reportingService, null, { pnmsReportingData, date }, options));
  } catch (err) {
    yield put(error(REPORTING_CONFIRM_PNMS, err));
  } finally {
    yield put(doneIndicator(REPORTING_CONFIRM_PNMS));
  }
}

export function* watchReportingConfirmPnms() {
  yield takeEvery(REPORTING_CONFIRM_PNMS_INIT, reportingConfirmPnms);
}

export default function* root() {
  yield all([
    fork(watchReportingConfirmPnms),
  ]);
}
