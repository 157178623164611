import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { pnmListSchema } from '../../schemas/pnm';

export default function getPnms(state) {
  const pnm = state.get('pnm');
  const slideshow = state.get('slideshow');
  let elements = [];

  const pnmData = pnm.get('data') || Map();
  const slideshowPnmIds = slideshow.getIn(['data', 'pnms'], List()).map(p => p.get('id'));

  if (slideshowPnmIds) {
    const result = slideshowPnmIds.toJS();
    const entities = { pnm: (pnmData.get('items', Map())).toJS() };

    elements = denormalize(result, pnmListSchema, entities) || [];
  }

  return Immutable.fromJS(elements);
}
