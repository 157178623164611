import Immutable, { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Tag from '../../../../../Tag';
import SpecialtiesModal from './components/SpecialtiesModal';

class Specialties extends Component {
  static propTypes = {
    classes:             PropTypes.instanceOf(Object).isRequired,
    selectedItem:        PropTypes.instanceOf(Map).isRequired,
    userSpecialties:     PropTypes.instanceOf(List).isRequired,
    removeUserSpecialty: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        specialtiesModalOpen: false,
      }),
    };
  }

  handleOpenSpecialtiesModal = () => {
    this.setState({
      data: this.state.data.set('specialtiesModalOpen', true),
    });
  }

  handleCloseSpecialtiesModal = () => {
    this.setState({
      data: this.state.data.set('specialtiesModalOpen', false),
    });
  }

  handleDeleteUserSpecialty = (id) => {
    const { removeUserSpecialty, selectedItem } = this.props;

    const user = selectedItem.get('id');
    removeUserSpecialty({ id, user });
  }

  render() {
    const {
      classes, selectedItem, userSpecialties,
    } = this.props;

    const specialtiesModalOpen = this.state.data.get('specialtiesModalOpen');
    let usedSpecialtyIds = List();
    let uniqueUserSpecialties = List();

    // get only unique specialties
    userSpecialties.forEach((us, index) => {
      const specialtyId = us.getIn(['specialty', '_id']);

      if (specialtyId && !usedSpecialtyIds.includes(specialtyId)) {
        uniqueUserSpecialties = uniqueUserSpecialties.push(us);
        usedSpecialtyIds = usedSpecialtyIds.push(specialtyId);
      } else if (specialtyId) {
        uniqueUserSpecialties = uniqueUserSpecialties.delete(index);
      }
    });

    return (
      <Paper className={ classes.card }>
        <Grid container justify='center' alignItems='center'>
          <Grid item xs={ 11 } className={ classes.paddedContainer }>
            <Typography variant='subtitle1'>Specialties</Typography>
          </Grid>
          <Grid item xs={ 1 }>
            <IconButton onClick={ this.handleOpenSpecialtiesModal }>
              <Icon color='primary'>add_circle</Icon>
            </IconButton>
          </Grid>

          <Grid item xs={ 12 } className={ classes.bottomBorderContainer } />

          <Grid item xs={ 12 } className={ classes.paddedContainer }>
            <Grid container spacing={ 16 } alignItems='center'>
              { uniqueUserSpecialties.size
                ? uniqueUserSpecialties.map(userSpecialty => [
                  <Grid item xs={ 3 } key={ `${userSpecialty.get('_id')}_tag` }>
                    <Tag title={ userSpecialty.getIn(['specialty', 'title']) }
                      color={ userSpecialty.getIn(['specialty', 'color']) } />
                  </Grid>,

                  <Grid item xs={ 8 } key={ `${userSpecialty.get('_id')}_desc` }>
                    <Typography color='textSecondary'>
                      { userSpecialty.getIn(['specialty', 'description']) }
                    </Typography>
                  </Grid>,

                  <Grid item xs={ 1 } key={ `${userSpecialty.get('_id')}_delete` }>
                    <IconButton onClick={ () => this.handleDeleteUserSpecialty(userSpecialty.get('_id')) }>
                      <Icon className={ classes.lightgrayIcon }>delete</Icon>
                    </IconButton>
                  </Grid>,
                ]) : (
                  <Grid item xs={ 12 }>
                    <Typography color='secondary'>No Specialties Assigned</Typography>
                  </Grid>
                )}
            </Grid>

          </Grid>
        </Grid>

        <SpecialtiesModal dialogOpen={ specialtiesModalOpen }
          selectedItem={ selectedItem }
          handleDialogClose={ this.handleCloseSpecialtiesModal } />

      </Paper>
    );
  }
}

const styles = theme => ({
  card: {
    borderRadius: 10,
    marginTop:    20,
    minHeight:    450,
  },

  paddedContainer: {
    padding: 16,
  },

  profileStats: {
    marginTop: 15,
  },

  profileIcon: {
    marginLeft: 15,
  },
  lightgrayIcon: {
    color: '#bfbfbf',
  },

  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatarIcon: {
    fontSize: 50,
  },

  bottomBorderContainer: {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
});

export default withStyles(styles)(Specialties);
