import { RESULT_FETCH_ALL_FOR_CHAPTER_INIT, RESULT_CLEAR } from '../constants/resultTypes';

export function resultFetchAllForChapter(params) {
  return {
    type:    RESULT_FETCH_ALL_FOR_CHAPTER_INIT,
    payload: params,
  };
}

export function resultClear() {
  return {
    type: RESULT_CLEAR,
  };
}
