import { Map } from 'immutable';

// constants
import {
  INVITE_CREATE,
  INVITE_DELETE,
  INVITE_FETCH,
  INVITE_FETCH_ALL_FOR_CHAPTER,
  INVITE_BULK_CREATE,
} from '../constants/inviteTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { add, remove } from '../lib/normalizedReducerHelpers';

function inviteCreate() {
  return (map, action) => {
    const { payload: { data: { user, message } } } = action;
    if (!user) {
      return add()(map, action);
    }

    return map.set('message', message);
  };
}

export default (state = Map(), action) => {
  switch (action.type) {
    case INVITE_FETCH: return httpReducer(state, action, { entity: 'invite' });
    case INVITE_FETCH_ALL_FOR_CHAPTER: return httpReducer(state, action, { entity: 'invite' });
    case INVITE_CREATE: return httpReducer(state, action, { cb: inviteCreate() });
    case INVITE_BULK_CREATE: return httpReducer(state, action, { entity: 'invite' });
    case INVITE_DELETE: return httpReducer(state, action, { cb: remove() });
    default: return state;
  }
};
