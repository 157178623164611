import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
// import Slider from '@material-ui/core/Slider';

// styles
import '../../assets/stylesheets/Slider.scss';

function RangeInput(props) {
  RangeInput.propTypes = {
    initialValue: PropTypes.number,
    input:        PropTypes.instanceOf(Object).isRequired,
    maxValue:     PropTypes.number.isRequired,
    minValue:     PropTypes.number.isRequired,
  };

  RangeInput.defaultProps = {
    initialValue: 0,
  };

  const {
    initialValue,
    input,
    maxValue,
    minValue,
  } = props;

  const [sliderValue, setSliderValue] = useState(initialValue);

  return (
    <InputRange value={ sliderValue }
      minValue={ minValue }
      maxValue={ maxValue }
      onChange={ (value) => {
        setSliderValue(value);
        input.onChange(value);
      } } />

  );
}

export default RangeInput;
