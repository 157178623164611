import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { noteListSchema } from '../../schemas/note';

export default function getNotes(state) {
  const note = state.get('note');
  const user = state.get('user');
  const chapter = state.get('chapter');
  const round = state.get('round');

  const result = note.getIn(['data', 'result'], List());

  let items = [];

  if (result.size > 0) {
    const users = user.getIn(['data', 'items'], Map());
    const rounds = round.getIn(['data', 'items'], Map());
    const chapters = chapter.getIn(['data', 'items'], Map());
    const notes = note.getIn(['data', 'items'], Map());

    const entities = {
      note:    notes.toJS(),
      user:    users.toJS(),
      chapter: chapters.toJS(),
      round:   rounds.toJS(),
    };

    items = denormalize(result.toJS(), noteListSchema, entities);
  }

  return Immutable.fromJS(items);
}
