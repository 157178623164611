import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// components
import SlideCard from './SlideCard';

// selectors
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';

const mapStateToProps = state => ({
  pnmLoading:   getLoadingFromState('pnm')(state),
  noteLoading:  getLoadingFromState('note')(state),
  matchLoading: getLoadingFromState('match')(state),
});

export default withRouter(connect(mapStateToProps)(SlideCard));
