import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map, List } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local components
import Button from '../../../Button';
import TagsForm from './components/TagsForm';

class Tags extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    createTag:      PropTypes.func.isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    tags:           PropTypes.instanceOf(List).isRequired,
    tagsForm:       PropTypes.instanceOf(Map).isRequired,
    parentclasses:  PropTypes.instanceOf(Object).isRequired,
    removeTag:      PropTypes.func.isRequired,
    tagLoading:     PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        snackbarOpen:    false,
        snackbarMessage: '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { tagsForm } = this.props;
    const nextTag = nextProps.tags;
    const nextTagsForm = nextProps.tagsForm;

    const oldSize = ((tagsForm.get('values') || Map()).get('tags') || List()).size;
    const nextSize = ((nextTagsForm.get('values') || Map()).get('tags') || List()).size;

    if (nextTag.get('status') === 'success' && tagsForm.get('values') && nextSize > oldSize) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarMessage', 'Added Successfully');
          map.set('snackbarOpen', true);
        }),
      });
    } else if (nextTag.get('status') === 'error') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarMessage', 'Failed To Add');
          map.set('snackbarOpen', true);
        }),
      });
    }
  }

  handleAddTag = () => {
    const {
      createTag, currentChapter, tags,
    } = this.props;

    let newTagTitle = 'New Tag';
    const finder = tag => tag.get('title') === newTagTitle;
    for (let i = 1; tags.find(finder); i += 1) {
      newTagTitle = `New Tag (${i})`;
    }

    const values = {
      title:     newTagTitle,
      color:     'deepPurple',
      usableBy:  0,
      visibleTo: 0,
      removed:   0,
      order:     0, // We can get rid of this, tags don't reorder
      type:      'chapter',
      chapter:   currentChapter.getIn(['data', 'id'], 0),
      group:     1,
    };

    createTag({
      chapter: currentChapter.getIn(['data', 'id'], 0),
      values,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  }

  renderLoadingIndicator = () => {
    const { currentUser } = this.props;
    const loading = currentUser.get('loading') || false;

    let element;

    if (loading) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <CircularProgress />
        </Grid>
      );
    }

    return element;
  }

  render() {
    const {
      classes,
      createTag,
      currentChapter,
      parentclasses: {
        tabsCard, titleContainer, titleIcon, topButton,
      },
      removeTag,
      tags,
      tagsForm,
      tagLoading,
    } = this.props;

    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');
    const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);
    const loading = tagLoading || currentChapter.get('loading');

    return (
      <div>
        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 24 }>

              <Grid item xs={ 8 } sm={ 8 } className={ titleContainer }>
                <Icon className={ titleIcon } color='primary'>local_offer</Icon>
                <Typography variant='h5' color='primary'>Manage Tags</Typography>
              </Grid>

              <Hidden xsDown>
                <Grid item sm={ 4 } className={ topButton } align='right'>
                  <Button variant='contained'
                    color='primary'
                    disabled={ loading }
                    onClick={ this.handleAddTag }>
                    <Icon className={ classes.leftIcon }>add</Icon>
                    Add Tag
                  </Button>
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid item sm={ 4 } className={ topButton } align='right'>
                  <Button variant='fab'
                    color='primary'
                    loading={ loading }
                    onClick={ this.handleAddTag }>
                    <Icon>add</Icon>
                  </Button>
                </Grid>
              </Hidden>

              <Grid item xs={ 12 }>
                <Typography variant='subtitle1' color='textSecondary' gutterBottom>
                  Create tags to categorize PNMs, and then manage them here.
                </Typography>

                { isCdSite && (
                <div>
                  <Typography variant='body2' color='primary'>
                    What are CampusDirector tags?
                  </Typography>

                  <Typography variant='body2' color='textSecondary' gutterBottom>
                    They are default tags that bring in data synced from your campus
                  </Typography>
                </div>
                ) }
              </Grid>

              { this.renderLoadingIndicator() }

              <Grid item xs={ 12 } className={ classes.tagsFormContainer }>
                <TagsForm tags={ tags }
                  createTag={ createTag }
                  currentChapter={ currentChapter }
                  removeTag={ removeTag }
                  tagLoading={ tagLoading }
                  tagsForm={ tagsForm }
                  updateTags={ this.updateTags } />
              </Grid>

            </Grid>
          </CardContent>
        </Card>

        <Snackbar key='add'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ this.handleSnackbarClose }
          message={ snackbarMessage } />
      </div>
    );
  }
}

const styles = theme => ({
  tagsFormContainer: {
    marginTop: 15,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(Tags);
