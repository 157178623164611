import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove, get } from '../../sources/api/client';
import bumpGroupService from '../../sources/api/bumpGroupService';
import chapterService from '../../sources/api/chapterService';

// constants
import { BUMP_GROUP_REMOVE, BUMP_GROUP_REMOVE_INIT } from '../../constants/bumpGroupTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { bumpGroupSchema } from '../../schemas/bumpGroup';
import { chapterSchema } from '../../schemas/chapter';

export function* bumpGroupRemove(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(BUMP_GROUP_REMOVE, call(remove, bumpGroupService, id, options), {
      schema: bumpGroupSchema,
    });

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapter), {
      schema: chapterSchema,
    });
  } catch (err) {
    yield put(error(BUMP_GROUP_REMOVE, err));
  } finally {
    yield put(doneIndicator(BUMP_GROUP_REMOVE));
  }
}

export function* watchBumpGroupRemove() {
  yield takeEvery(BUMP_GROUP_REMOVE_INIT, bumpGroupRemove);
}

export default function* root() {
  yield all([
    fork(watchBumpGroupRemove),
  ]);
}
