import PropTypes from 'prop-types';
import React from 'react';
import pluralize from 'pluralize';

// components
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import {
  red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, deepOrange, orange, brown, grey, blueGrey,
} from '@material-ui/core/colors';

const Tag = function (props) {
  Tag.propTypes = {
    classes:              PropTypes.instanceOf(Object).isRequired,
    color:                PropTypes.string.isRequired,
    count:                PropTypes.number,
    disableRipple:        PropTypes.bool,
    disabled:             PropTypes.bool,
    disabledTooltipTitle: PropTypes.string,
    displayCount:         PropTypes.bool,
    selected:             PropTypes.bool,
    title:                PropTypes.string.isRequired,
    tooltipTitle:         PropTypes.string,
    type:                 PropTypes.string,
    variant:              PropTypes.string,
  };

  Tag.defaultProps = {
    count:                0,
    disableRipple:        true,
    disabled:             false,
    disabledTooltipTitle: 'Cannot select',
    displayCount:         false,
    selected:             false,
    variant:              'default',
    tooltipTitle:         '',
    type:                 '',
  };

  const {
    classes,
    color,
    count,
    disableRipple,
    disabled,
    disabledTooltipTitle,
    displayCount,
    selected,
    title,
    tooltipTitle,
    type,
    variant,
  } = props;

  let tagColor = '';
  const white = '#fff';
  const black = '#000';
  const gray = '#bdbdbd';
  let textColor = white;

  // / Pastel tag colors
  switch (color) {
    case 'primary':
      tagColor = '#4285f4';
      break;
    case 'red':
      tagColor = red['400'];
      break;
    case 'pink':
      tagColor = pink['300'];
      break;
    case 'purple':
      tagColor = purple['300'];
      break;
    case 'deepPurple':
      tagColor = deepPurple['400'];
      break;
    case 'indigo':
      tagColor = indigo['300'];
      break;
    case 'blue':
      tagColor = blue['300'];
      break;
    case 'lightBlue':
      tagColor = lightBlue['300'];
      break;
    case 'cyan':
      tagColor = cyan['300'];
      break;
    case 'teal':
      tagColor = teal['300'];
      break;
    case 'darkGreen':
      tagColor = '#00600f';
      break;
    case 'green':
      tagColor = green['400'];
      break;
    case 'lightGreen':
      tagColor = lightGreen['300'];
      break;
    case 'lime':
      tagColor = lime['300'];
      textColor = black;
      break;
    case 'yellow':
      tagColor = yellow['300'];
      textColor = black;
      break;
    case 'amber':
      tagColor = amber['300'];
      textColor = black;
      break;
    case 'orange':
      tagColor = deepOrange['300'];
      break;
    case 'lightOrange':
      tagColor = orange['300'];
      break;
    case 'brown':
      tagColor = brown['300'];
      break;
    case 'grey':
      tagColor = grey['400'];
      textColor = black;
      break;
    case 'blueGrey':
      tagColor = blueGrey['300'];
      break;

    default:
      tagColor = deepPurple['300'];
      break;
  }

  const getTextColor = () => {
    if (disabled) {
      return '#fff';
    } if (selected) {
      return '#000';
    }
    return textColor;
  };

  const renderTag = () => (
    <ButtonBase disableRipple={ disableRipple }
      className={ selected ? classes.selectedTag : classes.tag }
      style={ {
        backgroundColor: disabled ? gray : !selected && tagColor,
      } }>
      <Typography align='center'
        style={ { color: getTextColor() } }
        noWrap>
        { title }
      </Typography>
    </ButtonBase>
  );

  const getTooltipTitle = () => {
    if (disabled) {
      return disabledTooltipTitle;
    } if (type === 'campusDirector') {
      return `${title} | From CampusDirector`;
    } if (displayCount && count > 0) {
      return `${title} | Tagged ${pluralize('time', count, true)}`;
    } if (tooltipTitle) {
      return tooltipTitle;
    }
    return title;
  };

  return (
    <div className={ classes.tagWrapper }>
      { variant === 'default' ? (
        <div className={ classes.tagContainer }>
          <Tooltip id={ `tag-tooltip-${title}` }
            title={ getTooltipTitle() }>
            { displayCount && count > 0
              ? (
                <Badge badgeContent={ count } color='secondary'>
                  { renderTag() }
                </Badge>
              )
              : renderTag() }
          </Tooltip>
        </div>
      ) : (
        <div className={ classes.miniTagContainer }>
          <Tooltip id={ `tag-tooltip-${title}` } title={ getTooltipTitle() }>
            <div className={ classes.miniTag } style={ { backgroundColor: tagColor } } />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const styles = theme => ({
  tagWrapper: {
    maxWidth: '100%',
  },

  tagContainer: {
    margin:   5,
    maxWidth: '100%',
  },

  tag: {
    alignItems:     'center',
    borderRadius:   25,
    display:        'flex',
    justifyContent: 'center',
    padding:        '5px 10px',
    border:         '2px solid #fff',
    maxWidth:       '100%',
  },

  selectedTag: {
    alignItems:      'center',
    borderRadius:    25,
    display:         'flex',
    justifyContent:  'center',
    padding:         '5px 10px',
    color:           theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border:          `2px solid ${theme.palette.primary.main}`,
    userSelect:      'none',
    boxShadow:       '0 9px 16px 0 rgba(66,133,244,0.5)',
    maxWidth:        '100%',
  },

  miniTagContainer: {
    width: 'inherit',
  },

  tagCountContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius:    100,
  },

  tagButtonBase: {
    width:        'inherit',
    borderRadius: 25,
    margin:       0,
    padding:      0,
  },

  miniTag: {
    width:        15,
    height:       15,
    borderRadius: '100%',
    display:      'flex',
    border:       '1.5px solid #fff',
  },
});

export default withStyles(styles)(Tag);
