import PropTypes from 'prop-types';
import React from 'react';

// components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import { Field } from 'redux-form/immutable';
import TextInput from '../../../TextInput';

// styles
import './LoginForm.scss';

const handleKeyDown = function (e, cb) {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault();
    cb();
  }
};

const LoginForm = function ({ onSubmit }) {
  return (
    <Grid item component='form' onKeyDown={ (e) => { handleKeyDown(e, onSubmit); } }>
      <Grid container spacing={ 24 }>
        <Grid item xs={ 12 }>
          <div className='login-form__input-container'>
            <Icon className='login-form__icon' color='primary'>mail_outline</Icon>
            <Field errorClassName='login-form__error'
              InputProps={ { disableUnderline: true } }
              name='email'
              type='email'
              component={ TextInput }
              placeholder='Enter Email'
              required
              fullWidth />
          </div>
        </Grid>

        <Grid item xs={ 12 }>
          <div className='login-form__input-container'>
            <Icon className='login-form__icon' color='primary'>lock_outline</Icon>
            <Field errorClassName='login-form__error'
              InputProps={ { disableUnderline: true } }
              name='password'
              placeholder='Enter Password'
              type='password'
              component={ TextInput }
              required
              fullWidth />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
