import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

// components
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

class SwitchInput extends Component {
  static propTypes = {
    classes:            PropTypes.instanceOf(Object).isRequired,
    containerClassName: PropTypes.string,
    switchClassname:    PropTypes.string,
    errorClassName:     PropTypes.string,
    id:                 PropTypes.string,
    input:              PropTypes.instanceOf(Object).isRequired,
    label:              PropTypes.string,
    meta:               PropTypes.instanceOf(Object).isRequired,
    subText:            PropTypes.string,
    disabled:           PropTypes.bool,
  }

  static defaultProps = {
    containerClassName: '',
    switchClassname:    '',
    errorClassName:     '',
    id:                 '',
    label:              '',
    subText:            '',
    disabled:           false,
  }

  shouldDisplayError = () => {
    const {
      meta: {
        active,
        dirty,
        error,
        touched,
      },
    } = this.props;

    return error && !active && (dirty || touched);
  }

  renderError = () => {
    const { errorClassName, meta: { error } } = this.props;

    let element;

    if (this.shouldDisplayError()) {
      element = (
        <FormHelperText className={ errorClassName } error>
          { error[0] }
        </FormHelperText>
      );
    }

    return element;
  }

  render() {
    const {
      classes,
      containerClassName,
      switchClassname,
      errorClassName,
      id,
      input,
      label,
      subText,
      disabled,
      ...rest
    } = this.props;

    return (
      <FormControl disabled={ disabled }
        className={ classNames(containerClassName, classes.formControl) }>

        <FormControlLabel control={ (
          <Switch checked={ Boolean(input.value) }
            id={ id }
            color='primary'
            className={ switchClassname }
            value={ id }
            error={ this.shouldDisplayError() }
            inputProps={ { id, ...input } }
            { ...rest } />
        ) }
          label={ label } />

        <Typography color='textSecondary'>
          { subText }
        </Typography>

        { this.renderError() }
      </FormControl>
    );
  }
}

const styles = theme => ({
  formControl: {
    margin:   theme.spacing.unit,
    minWidth: 140,
  },
});

export default withStyles(styles)(SwitchInput);
