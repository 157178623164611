import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import slideshowService from '../../sources/api/slideshowService';

// constants
import { SLIDESHOW_FETCH_PNMS, SLIDESHOW_FETCH_PNMS_INIT } from '../../constants/slideshowTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* fetchSlideshowPnms(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter,
    pnmIds = [],
    sortBy,
    order,
    filter = {},
  } = action.payload || {};

  try {
    const options = {
      query: {
        chapter,
        pnmIds,
        sortBy,
        order,
      },
    };

    if (filter) {
      options.query.filter = filter;
    }

    yield* httpSaga(SLIDESHOW_FETCH_PNMS, call(find, slideshowService, options));
  } catch (err) {
    yield put(error(SLIDESHOW_FETCH_PNMS, err));
  } finally {
    yield put(doneIndicator(SLIDESHOW_FETCH_PNMS));
  }
}

export function* watchPnmFetchAll() {
  yield takeLatest(SLIDESHOW_FETCH_PNMS_INIT, fetchSlideshowPnms);
}

export default function* root() {
  yield all([
    fork(watchPnmFetchAll),
  ]);
}
