import { List, Map } from 'immutable';
import EditPnmModal from './EditPnmModal';
import {
  reduxForm, isSubmitting, hasSubmitFailed, hasSubmitSucceeded,
} from 'redux-form/immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';

// action creators
import { pnmCreate, pnmUpdate } from '../../../../actions/pnmActions';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getFields from '../../../../lib/selectors/getFields';

const createInitialValues = (props) => {
  const { pnmData = Map() } = props;

  const values = {
    firstname:       pnmData.get('firstname'),
    lastname:        pnmData.get('lastname'),
    leg_tech_pnm_id: pnmData.get('leg_tech_pnm_id'),
  };

  const standardFields = pnmData.get('standardFields', List());
  standardFields.forEach((f) => {
    values[f.getIn(['standardField', 'id'], '0')] = f.get('text', '0');
  });

  return values;
};

const schema = {
  firstname: {
    presence: true,
  },

  lastname: {
    presence: true,
  },

  leg_tech_pnm_id: {
    presence: true,
  },
};

const mapStateToProps = (state, props) => ({
  currentChapter:  state.get('currentChapter'),
  currentUser:     state.get('currentUser'),
  editPnmForm:     state.getIn(['form', 'editPnmForm'], Map()),
  fields:          getFields(state),
  initialValues:   createInitialValues(props),
  isPnmLoading:    getLoadingFromState('pnm')(state),
  submitFailed:    hasSubmitFailed('addPnmForm')(state),
  submitSucceeded: hasSubmitSucceeded('addPnmForm')(state),
  submitting:      isSubmitting('addPnmForm')(state),
  user:            state.get('user'),
});

const mapDispatchToProps = dispatch => ({
  createPnm: (params) => {
    dispatch(pnmCreate(params));
  },
  updatePnm: (params) => {
    dispatch(pnmUpdate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:               'editPnmForm',
  validate:           validateForm,
  onChange:           handleChange,
  enableReinitialize: true,
})(EditPnmModal));
