import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import forgotPasswordService from '../../sources/api/forgotPasswordService';

// constants
import { FORGOT_PASSWORD_CREATE_INIT, FORGOT_PASSWORD_CREATE } from '../../constants/forgotPasswordTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* forgotPasswordCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'forgotPasswordEmail';

  yield put(startSubmit(FORM));

  const { payload } = action;

  try {
    yield* httpSaga(FORGOT_PASSWORD_CREATE, call(create, forgotPasswordService, payload));

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(FORGOT_PASSWORD_CREATE, err));
  } finally {
    yield put(doneIndicator(FORGOT_PASSWORD_CREATE));
  }
}

export function* watchForgotPasswordCreate() {
  yield takeEvery(FORGOT_PASSWORD_CREATE_INIT, forgotPasswordCreate);
}

export default function* root() {
  yield all([
    fork(watchForgotPasswordCreate),
  ]);
}
