import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { arrayRemove } from 'redux-form/immutable';

// api
import { remove } from '../../sources/api/client';
import roundService from '../../sources/api/roundService';

// constants
import { ROUND_REMOVE, ROUND_REMOVE_INIT } from '../../constants/roundTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { roundSchema } from '../../schemas/round';

export function* roundRemove(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id, chapter, index } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield put(arrayRemove('roundsForm', 'rounds', index));

    yield* httpSaga(ROUND_REMOVE, call(remove, roundService, id, options), {
      schema: roundSchema,
    });
  } catch (err) {
    yield put(error(ROUND_REMOVE, err));
  } finally {
    yield put(doneIndicator(ROUND_REMOVE));
  }
}

export function* watchFieldRemove() {
  yield takeEvery(ROUND_REMOVE_INIT, roundRemove);
}

export default function* root() {
  yield all([
    fork(watchFieldRemove),
  ]);
}
