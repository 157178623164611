import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import validate from 'validate.js';
import Immutable, { Map } from 'immutable';

// action creators
import { fetchAllPnms } from '../../../../../../actions/pnmActions';
import { voteFetchForPnm } from '../../../../../../actions/voteActions';
import {
  deleteMatch, fetchMatchesForUser, clearMatches, createMatch, bulkCreateMatches,
} from '../../../../../../actions/matchActions';
import { fetchBumpGroupsForChapter } from '../../../../../../actions/bumpGroupActions';

// helpers
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';
import getPnmSuggestions from '../../../../../../lib/selectors/getPnmSuggestions';
import getRounds from '../../../../../../lib/selectors/getRounds';
import getChapterAttribute from '../../../../../../lib/selectors/getChapterAttribute';

// components
import MatchList from './MatchList';

const createInitialValues = (state) => {
  const currentRoundId = state.getIn(['currentChapter', 'data', 'currentRound']);

  return Immutable.fromJS({
    round: currentRoundId,
  });
};

const mapStateToProps = state => ({
  currentChapterId: getChapterAttribute('id')(state),
  currentUser:      state.get('currentUser'),
  formValues:       getFormValues('matchListForm')(state) || Map(),
  initialValues:    createInitialValues(state),
  organization:     state.get('organization'),
  pnm:              state.get('pnm'),
  pnmLoading:       getLoadingFromState('pnm')(state),
  pnmSuggestions:   getPnmSuggestions(state),
  rounds:           getRounds(state),
  vote:             state.get('vote'),
});

const mapDispatchToProps = dispatch => ({
  fetchPnms: (params) => {
    dispatch(fetchAllPnms(params));
  },
  fetchVotesForPnm: (params) => {
    dispatch(voteFetchForPnm(params));
  },
  deleteMatch: ({
    chapter, id, ids, userIds,
  }) => {
    dispatch(deleteMatch({
      chapter, id, ids, userIds,
    }));
  },
  fetchMatchesForUser: ({ userId, round, chapterId }) => {
    dispatch(fetchMatchesForUser({ userId, round, chapterId }));
  },
  clearMatches: () => {
    dispatch(clearMatches());
  },
  createMatch(params) {
    dispatch(createMatch(params));
  },
  bulkCreateMatches(params) {
    dispatch(bulkCreateMatches(params));
  },
  fetchBumpGroupsForChapter: ({ chapter, round, shouldPopulateUser }) => {
    dispatch(fetchBumpGroupsForChapter({ chapter, round, shouldPopulateUser }));
  },
});

const schema = {
  pnm: {
    presence: true,
  },
  round: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:     'matchListForm',
  validate: validateForm,
  onChange: handleChange,
})(MatchList)));
