import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create, get } from '../../sources/api/client';
import tagService from '../../sources/api/tagService';
import chapterService from '../../sources/api/chapterService';

// constants
import { TAG_CREATE, TAG_CREATE_INIT } from '../../constants/tagTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { tagSchema } from '../../schemas/tag';
import { chapterSchema } from '../../schemas/chapter';

export function* tagCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter, values = {} } = action.payload;

  try {
    yield* httpSaga(TAG_CREATE, call(create, tagService, values), {
      schema: tagSchema,
    });

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapter), {
      schema: chapterSchema,
    });
  } catch (err) {
    yield put(error(TAG_CREATE, err));
  } finally {
    yield put(doneIndicator(TAG_CREATE));
  }
}

export function* watchInviteCreate() {
  yield takeEvery(TAG_CREATE_INIT, tagCreate);
}

export default function* root() {
  yield all([
    fork(watchInviteCreate),
  ]);
}
