import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';

// MUI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

// components
import SlideCard from './components/SlideCard';
import Button from '../Button';
import AutoSuggestDeluxe from '../AutoSuggestDeluxe';

const LEFT_KEY = 37;
const RIGHT_KEY = 39;

const Slideshow = function ({
  classes,
  currentChapterId,
  fetchMatchesForPnm,
  history,
  matches,
  notes,
  noteFetchForPnm,
  pnmFetch,
  pnms,
  slideshowLoading,
  slideshowPnms,
}) {
  const formatPnmsForSearch = () => slideshowPnms.map(pnm => Map({
    label: `${pnm.get('firstname')} ${pnm.get('lastname')}`,
    value: pnm.get('id'),
  }));

  const [displayStatus, setDisplayStatus] = useState(false);
  const [displayHometownGroup, setDisplayHometownGroup] = useState(false);
  const [displayMatches, setDisplayMatches] = useState(false);
  const [displayTags, setDisplayTags] = useState(false);
  const [displayNotes, setDisplayNotes] = useState(false);
  const [fieldsToDisplay, setFieldsToDisplay] = useState(List());

  const formattedPnms = formatPnmsForSearch();
  const [suggestions, setSuggestions] = useState(formattedPnms);

  const { location: { state, search } } = history;
  const urlParams = new URLSearchParams(search);
  const currentSlide = parseInt(urlParams.get('slide'), 10);
  const totalSlides = slideshowPnms.size ? slideshowPnms.size : 1;
  const navLabel = `${currentSlide} / ${totalSlides}`;
  const currentPnmId = slideshowPnms.getIn([currentSlide - 1, 'id']); // the current pnm

  const disableNavigateNext = currentSlide === slideshowPnms.size || !slideshowPnms.size;
  const disableNavigateBack = currentSlide === 1;

  const getPnmData = (pnmId) => {
    let newPnmData = pnms.find((p = Map()) => p.get('id') === pnmId) || Map();

    // Use the pnm record from the initial pnm find while the pnm get loads
    if (!newPnmData.size) {
      newPnmData = slideshowPnms.get(currentSlide - 1, Map());
    }

    const pnmMatches = matches.filter(m => m.get('pnm'));
    const pnmNotes = notes.filter(n => n.get('pnm'));
    const status = slideshowPnms.getIn([currentSlide - 1, 'status']);

    // Add new attributes to the pnm object
    if (displayStatus) {
      newPnmData = newPnmData.set('status', status);
    }
    if (displayNotes) {
      newPnmData = newPnmData.set('notes', pnmNotes);
    }
    if (displayMatches) {
      newPnmData = newPnmData.set('matches', pnmMatches);
    }

    return newPnmData;
  };

  const setCurrentSlide = (newSlide) => {
    history.replace({
      pathname: '/slideshow',
      search:   `?slide=${newSlide}`,
    });
  };

  const fetchSlideData = (pnmId) => {
    const shouldFetchPnm = displayHometownGroup
    || displayTags
    || fieldsToDisplay.size;

    if (pnmId !== currentPnmId) {
      // Update the URL if a new pnm is selected
      const newSlide = slideshowPnms.findIndex(pnm => pnm.get('id') === pnmId);
      setCurrentSlide(newSlide + 1);
    }

    // When slide query param changes, load next pnm's data
    if (currentSlide > 0
      && currentSlide <= slideshowPnms.size
      && currentChapterId
      && slideshowPnms.size) {
      const fieldIds = fieldsToDisplay.map(field => field.get('value'));

      // We only need to fetch pnms if displaying hometown, field, or tags are selected
      if (shouldFetchPnm) {
        pnmFetch({
          chapter:       currentChapterId,
          pnm:           pnmId,
          includeFields: fieldIds.toJS(),
        });
      }

      if (displayNotes) {
        noteFetchForPnm({ chapterId: currentChapterId, pnmId });
      }
      if (displayMatches) {
        fetchMatchesForPnm({ chapterId: currentChapterId, pnmId });
      }
    }
  };

  useEffect(() => {
    if (state) {
      setDisplayStatus(state.displayStatus);
      setDisplayHometownGroup(state.displayHometownGroup);
      setDisplayMatches(state.displayMatches);
      setDisplayTags(state.displayTags);
      setDisplayNotes(state.displayNotes);
      setFieldsToDisplay(state.fieldsToDisplay);
    }
  }, [state]);

  useEffect(() => {
    fetchSlideData(currentPnmId);
  }, [slideshowPnms.size, currentSlide, currentPnmId, currentChapterId]);

  const handlePnmsSearchChange = (value) => {
    const newSuggestions = formattedPnms.filter((pnm) => {
      const pnmName = pnm.get('label', '').toLowerCase();

      if (value) {
        return pnmName.includes(value.toLowerCase());
      }
      return true;
    });

    setSuggestions(newSuggestions);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === LEFT_KEY && !disableNavigateBack) {
      setCurrentSlide(currentSlide - 1);
    } else if (e.keyCode === RIGHT_KEY && !disableNavigateNext) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const renderLoadingIndicator = () => (
    <Grid container justify='center' alignItems='center' className={ classes.loadingContainer }>
      <Grid item xs={ 12 }>
        <CircularProgress />
      </Grid>
    </Grid>
  );

  const renderNavigation = () => (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={ 4 } className={ classes.navButtonContainer }>
        <IconButton color='primary'
          onClick={ () => setCurrentSlide(currentSlide - 1) }
          disabled={ disableNavigateBack }>
          <Icon>arrow_back</Icon>
        </IconButton>
      </Grid>

      <Grid item xs={ 4 }>
        <Typography variant='body2' align='center'>{navLabel}</Typography>
      </Grid>

      <Grid item xs={ 4 } className={ classes.navButtonContainer }>
        <IconButton color='primary'
          onClick={ () => setCurrentSlide(currentSlide + 1) }
          disabled={ disableNavigateNext }>
          <Icon>arrow_forward</Icon>
        </IconButton>
      </Grid>
    </Grid>
  );

  const onSelect = (selectedSuggestion) => {
    fetchSlideData(selectedSuggestion.value);
  };

  return (
    <Grid container
      onKeyDown={ e => handleKeyDown(e) }
      tabIndex='0'
      justify='center'
      alignItems='center'>

      <Grid item xs={ 12 }>
        <Grid container alignItems='center' className={ classes.toolbarContainer }>

          <Grid item xs={ 3 }>
            <Button color='primary' variant='text' onClick={ () => history.push('') }>
              <Icon color='primary' className={ classes.leftIcon }>arrow_back</Icon>
              Back To Home
            </Button>
          </Grid>

          <Grid item xs={ 6 }>
            <AutoSuggestDeluxe label='Search PNMs'
              onSelectCallback={ onSelect }
              getAllSuggestions={ () => Immutable.fromJS(slideshowPnms) }
              formValues={ Immutable.fromJS({}) }
              suggestions={ suggestions.toJS() }
              onSearchChange={ handlePnmsSearchChange } />
          </Grid>

          <Grid item xs={ 3 } className={ classes.navContainer }>
            { renderNavigation() }
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <Card className={ classes.slideCard }>
          <CardContent className={ classes.slideCardContent }>
            { slideshowLoading
              ? renderLoadingIndicator()
              : (
                <Fade in={ !slideshowLoading }>
                  <SlideCard displayHometownGroup={ displayHometownGroup }
                    displayMatches={ displayMatches }
                    displayNotes={ displayNotes }
                    displayStatus={ displayStatus }
                    displayTags={ displayTags }
                    fieldsToDisplay={ Immutable.fromJS(fieldsToDisplay) }
                    pnm={ getPnmData(currentPnmId) } />
                </Fade>
              )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Slideshow.propTypes = {
  classes:            PropTypes.instanceOf(Object).isRequired,
  currentChapterId:   PropTypes.number.isRequired,
  fetchMatchesForPnm: PropTypes.func.isRequired,
  history:            PropTypes.instanceOf(Object).isRequired,
  matches:            PropTypes.instanceOf(List).isRequired,
  noteFetchForPnm:    PropTypes.func.isRequired,
  notes:              PropTypes.instanceOf(List).isRequired,
  pnmFetch:           PropTypes.func.isRequired,
  pnms:               PropTypes.instanceOf(List).isRequired,
  slideshowLoading:   PropTypes.bool.isRequired,
  slideshowPnms:      PropTypes.instanceOf(List),
};

Slideshow.defaultProps = {
  slideshowPnms: List(),
};

const styles = theme => ({
  slideCard: {
    minHeight: '100vh',
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  backButton: {
    position: 'absolute',
    left:     20,
    top:      20,
  },

  slideCardContent: {
    padding:      35,
    paddingRight: 10,
    paddingTop:   15,
  },

  loadingContainer: {
    textAlign: 'center',
  },

  navButtonContainer: {
    textAlign: 'center',
    color:     theme.palette.common.white,
  },

  navContainer: {
    paddingLeft: '10%',
  },

  toolbarContainer: {
    backgroundColor: theme.palette.common.white,
    borderBottom:    '1px solid #dfdfdf',
    paddingBottom:   5,
  },

  bold: {
    fontWeight: 800,
  },
});

export default withStyles(styles)(Slideshow);
