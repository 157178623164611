import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';

// components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local components
import Tag from '../../../Tag';

class TagButtons extends Component {
  static propTypes = {
    input:          PropTypes.instanceOf(Object).isRequired,
    classes:        PropTypes.instanceOf(Object).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    organization:   PropTypes.instanceOf(Map).isRequired,
    notes:          PropTypes.instanceOf(List).isRequired,
    tags:           PropTypes.instanceOf(List).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        selectedTags: [],
      }),
    };
  }

  handleTagSelect = (tagIndex, disabled = false) => {
    const selectedTags = this.state.data.get('selectedTags');
    if (disabled) {
      return selectedTags;
    }

    let newSelectedTags = selectedTags;
    let removed = false;

    selectedTags.forEach((tag, index) => {
      if (tag === tagIndex) {
        newSelectedTags = selectedTags.splice(index, 1); // remove tag from list
        this.setState({ data: this.state.data.set('selectedTags', newSelectedTags) });
        removed = true;
      }
    });

    if (!removed) {
      newSelectedTags = selectedTags.push(tagIndex);
      this.setState({ data: this.state.data.set('selectedTags', newSelectedTags) });
      return newSelectedTags;
    }
    return newSelectedTags;
  }

  containsRoundTag = (tagIds) => {
    const { currentChapter, organization } = this.props;

    const orgId = currentChapter.getIn(['data', 'organization'], 1);
    const roundTags = organization.getIn(['data', 'items', orgId.toString(), 'roundTags']) || List();

    return (tagIds.find(tag => roundTags.includes(tag.toString())) || '').length > 0;
  }

  render() {
    const {
      classes,
      currentChapter,
      currentUser,
      input,
      organization,
      notes,
      tags,
    } = this.props;

    const selectedTags = this.state.data.get('selectedTags');

    const currentRound = currentChapter.getIn(['data', 'currentRound'], 0);
    const orgId = currentChapter.getIn(['data', 'organization'], 1).toString();

    // Disable tag button if:
    //  1) The tag is a one-per-round tag
    //              - and -
    //  2) The current user has used the one-per-round tag this round
    //              - or -
    //  3) A one-per-round tag is currently selected
    const roundTags = organization.getIn(['data', 'items', orgId, 'roundTags'], List());

    // Get all notes that the user has created
    const notesByCurrentUser = notes.filter((n) => {
      const authorId = n.getIn(['author', 'id'], 0);
      return authorId === currentUser.getIn(['data', 'id'], 1);
    }) || List();

    // Check to see if the user's notes from the current round include a one-per-round tag
    const hasUsedRoundTag = (notesByCurrentUser.find((n) => {
      const tagIdsForNote = (n.get('tags') || List()).map(t => t.get('_id')) || List();

      return (this.containsRoundTag(tagIdsForNote) && n.get('round') === currentRound);
    }) || Map()).size > 0;

    // See if the user has selected a one-per-round tag on the form currently
    const roundTagSelected = this.containsRoundTag(selectedTags);

    return (
      <Grid container spacing={ 8 }>

        <Grid item xs={ 12 }>
          <Typography variant='subtitle2' color='primary'>
            { selectedTags.size }
            {' '}
            tags selected
          </Typography>
        </Grid>

        { tags.map(item => (
          <Grid key={ item.get('_id') }
            className={ classes.tagContainer }
            onClick={
              (!roundTags.includes(item.get('_id')) || (!hasUsedRoundTag && !roundTagSelected) || selectedTags.includes(item.get('_id')))
                ? () => input.onChange(this.handleTagSelect(item.get('_id')))
                : null
            }>
            <Tag key={ item.get('_id') }
              selected={ selectedTags.includes(item.get('_id').toString()) }
              title={ item.get('title') }
              type={ item.get('type', '') }
              disabled={ roundTags.includes(item.get('_id').toString()) && (hasUsedRoundTag || roundTagSelected) }
              disableRipple={ roundTags.includes(item.get('_id')) && (hasUsedRoundTag || roundTagSelected) }
              color={ item.get('color') } />
          </Grid>
        ))}
      </Grid>
    );
  }
}

const styles = theme => ({
  helperText: {
    color:     theme.palette.common.white,
    marginTop: 15,
    textAlign: 'center',
  },

  tagButtonBase: {
    borderRadius: 25,
  },

  tagContainer: {
    cursor: 'pointer',
  },
});

export default withStyles(styles)(TagButtons);
