import { connect } from 'react-redux';

// action creators
import { voteFetchAll } from '../../../../actions/voteActions';
import { noteFetchAll } from '../../../../actions/noteActions';
import { exportFetchForPdf } from '../../../../actions/exportActions';

// components
import Export from './Export';

const mapStateToProps = state => ({
  form:           state.get('form'),
  currentChapter: state.get('currentChapter'),
  vote:           state.get('vote'),
  note:           state.get('note'),
  pnm:            state.get('pnm'),
  tag:            state.get('tag'),
  chapter:        state.get('chapter'),
  round:          state.get('round'),
  field:          state.get('field'),
  hometownGroup:  state.get('hometownGroup'),
  organization:   state.get('organization'),
  category:       state.get('category'),
  roundCategory:  state.get('roundCategory'),
});

const mapDispatchToProps = dispatch => ({
  fetchVotes: (params) => {
    dispatch(voteFetchAll(params));
  },

  fetchNotes: (params) => {
    dispatch(noteFetchAll(params));
  },

  fetchExportsForPdf: (params) => {
    dispatch(exportFetchForPdf(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Export);
