import getOrganization from './getOrganization';
import getRounds from './getRounds';
import { Map, List } from 'immutable';

export default function getCategories(state) {
  const voteFormRoundId = state.getIn(['form', 'voteForm', 'values', 'round'], '');

  const rounds = getRounds(state);
  const organization = getOrganization(state);
  const currentChapter = state.get('currentChapter', Map());
  const roundCategories = organization.get('roundCategories', List());
  const customCategories = currentChapter.getIn(['data', 'customCategories'], List());
  const currentRoundId = voteFormRoundId || currentChapter.getIn(['data', 'currentRound'], '');

  const currentRoundType = rounds.find(
    round => currentRoundId && currentRoundId.toString() === round.get('_id'), // callback
    {}, // context
    Map() // not set value
  ).get('roundType');

  if (currentRoundType === null) {
    return List();
  }

  let currentRoundCategory = roundCategories.filter((r = Map()) => r.get('roundType') === currentRoundType, {}, List());
  currentRoundCategory = currentRoundCategory.get(0, Map());
  const oldCategories = currentRoundCategory.get('categories', List());
  let newCategories = List();

  oldCategories.forEach((category) => {
    let newCategory = category;

    // Look to see if the chapter has made any changes to the category
    const customCategory = (customCategories.filter(c => c.get('_id') === category.get('_id')) || List()).get(0);

    // Use the custom category if one exists
    if (customCategory) {
      newCategory = customCategory;
    }

    // Don't let the custom category override the HQ category's required setting
    newCategory = newCategory.set('required', category.get('required'));

    // If the category does not have custom options, use the orgs default options
    if (newCategory.get('options').size === 0) {
      newCategory = newCategory.set('options', organization.get('defaultOptions'));
    }

    if (newCategory.get('required') || newCategory.get('enabled')) {
      newCategory = newCategory.set('enabled', true);
      newCategories = newCategories.push(newCategory);
    }
  });

  return newCategories;
}
