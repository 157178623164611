import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';

// action creators
import { userRemoveAllForChapter } from '../../../../../../actions/userActions';

// components
import RemoveUsersForm from './RemoveUsersForm';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  formState:      state.get('form'),
});

const mapDispatchToProps = dispatch => ({
  removeAllUsersForChapter: (params) => {
    dispatch(userRemoveAllForChapter(params));
  },
});

const schema = ({
  removeUsers: {
    presence:  true,
    inclusion: {
      within:  { REMOVE: 'removeValue' },
      message: 'You entered %{value}, REMOVE must be entered',
    },
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedRemoveUsersForm = reduxForm({
  form:     'removeUsersForm',
  validate: validateForm,
  onChange: handleChange,
})(RemoveUsersForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedRemoveUsersForm);
