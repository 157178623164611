import { Map } from 'immutable';

// constants
import {
  FORM_DESTROY,
  FORM_SET_SUBMIT_FAILED,
  FORM_SET_SUBMIT_SUCCEEDED,
  FORM_START_SUBMIT,
  FORM_STOP_SUBMIT,
} from '../constants/formTypes';

export default (state = Map(), action = {}) => {
  const { formName, error: { data: { msg } } = { data: {} } } = action.payload || {}; // eslint-disable-line

  switch (action.type) {
    case FORM_DESTROY:
      return state.delete(formName);
    case FORM_START_SUBMIT:
      return state.withMutations((map) => {
        map.setIn([formName, 'submitting'], true);
        map.deleteIn([formName, 'submitFailed']);
        map.deleteIn([formName, 'submitSucceeded']);
        map.deleteIn([formName, 'error']);

        return map;
      });
    case FORM_STOP_SUBMIT:
      return state.setIn([formName, 'submitting'], false);
    case FORM_SET_SUBMIT_FAILED:

      return state.withMutations((map) => {
        map.setIn([formName, 'submitting'], false);
        map.setIn([formName, 'submitFailed'], true);
        map.setIn([formName, 'submitSucceeded'], false);
        map.setIn(
          [formName, 'error'],
          msg || 'Something went wrong, please try again'
        );

        return map;
      });
    case FORM_SET_SUBMIT_SUCCEEDED:
      return state.withMutations((map) => {
        map.setIn([formName, 'submitting'], false);
        map.setIn([formName, 'submitFailed'], false);
        map.setIn([formName, 'submitSucceeded'], true);
        map.deleteIn([formName, 'error']);

        return map;
      });
    default:
      return state;
  }
};
