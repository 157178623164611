import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map, List } from 'immutable';

// components
import Button from '../Button';
import NoteTagForm from './components/NoteTagForm';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// selectors
import getUserRole from '../../lib/selectors/getUserRole';

class NoteTagModal extends Component {
  static propTypes = {
    allowVotingMembersToTag: PropTypes.bool.isRequired,
    classes:                 PropTypes.instanceOf(Object).isRequired,
    clearanceLevel:          PropTypes.number.isRequired,
    createNote:              PropTypes.func.isRequired,
    currentChapter:          PropTypes.instanceOf(Map).isRequired,
    currentUser:             PropTypes.instanceOf(Map).isRequired,
    dialogOpen:              PropTypes.bool.isRequired,
    disableNotesFeature:     PropTypes.bool.isRequired,
    fullScreen:              PropTypes.bool.isRequired,
    handleDialogClose:       PropTypes.func.isRequired,
    noteTagForm:             PropTypes.instanceOf(Map).isRequired,
    notes:                   PropTypes.instanceOf(List).isRequired,
    organization:            PropTypes.instanceOf(Map).isRequired,
    pnm:                     PropTypes.instanceOf(Map).isRequired,
    tags:                    PropTypes.instanceOf(List).isRequired,
    teamLevel:               PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = Immutable.fromJS({
      snackbarOpen:    false,
      snackbarMessage: '',
    });
  }

  componentWillReceiveProps(nextProps) {
    const { noteTagForm, handleDialogClose } = nextProps;

    if (noteTagForm.get('submitSucceeded')) {
      handleDialogClose();
    }
  }

  setSnackbarOpen(isOpen) {
    this.setState({ snackbarOpen: isOpen });
  }

  setSnackbarMessage(message) {
    this.setState({ snackbarMessage: message });
  }

  handleShowSnackbar(message) {
    const { handleDialogClose } = this.props;
    handleDialogClose();
    this.setSnackbarMessage(message);
    this.setSnackbarOpen(true);
  }

  handleHideSnackbar() {
    this.setSnackbarOpen(false);
    this.setSnackbarMessage('');
  }

  handleSubmit = () => {
    const {
      createNote, noteTagForm, pnm, currentChapter, organization,
    } = this.props;

    const chapter = currentChapter.getIn(['data', 'id'], 0);
    const currentRound = currentChapter.getIn(['data', 'currentRound'], 0);
    const orgId = currentChapter.getIn(['data', 'organization'], 0);

    const roundTags = organization.getIn(['data', 'items', orgId.toString(), 'roundTags']) || List();

    let values = noteTagForm.get('values') || Map();

    const tags = values.get('tags') || List();
    tags.forEach((tag) => {
      if (roundTags.includes(tag)) {
        values = values.set('round', currentRound);
      }
    });

    if (!noteTagForm.get('syncErrors')) {
      createNote({
        pnm: pnm.get('id'),
        chapter,
        ...values.toJS(),
      });

      this.handleShowSnackbar('Submitted successfully!');
    } else {
      this.handleShowSnackbar('Failed to submit!');
    }
  }

  renderErrorMessage = (noteTagForm) => {
    let element;

    if (noteTagForm.get('submitFailed')) {
      element = <FormHelperText error>Failed to create note, try again.</FormHelperText>;
    }

    return element;
  };

  render() {
    const {
      allowVotingMembersToTag,
      classes,
      clearanceLevel,
      currentChapter,
      currentUser,
      dialogOpen,
      disableNotesFeature,
      fullScreen,
      handleDialogClose,
      notes,
      noteTagForm,
      organization,
      pnm,
      tags,
      teamLevel,
    } = this.props;

    const { snackbarOpen, snackbarMessage } = this.state;

    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';

    const filteredTags = tags.filter(t => ['chapter', 'organization'].includes(t.get('type')) && (isConcernChair || clearanceLevel > 0 || teamLevel >= t.get('usableBy')));

    let disableButton = false;

    if (noteTagForm.get('syncErrors')) { disableButton = true; }

    return (
      <div>
        <Dialog open={ dialogOpen }
          onClose={ handleDialogClose }
          fullScreen={ fullScreen }
          maxWidth={ false }>

          <DialogContent classes={ { root: classes.dialogContentRoot } }>
            <NoteTagForm { ...{
              allowVotingMembersToTag,
              clearanceLevel,
              currentChapter,
              currentUser,
              disableNotesFeature,
              handleDialogClose,
              notes,
              organization,
              pnm,
              tags: filteredTags,
              teamLevel,
            } } />

            <DialogActions className={ classes.dialogActionsRoot }>
              { this.renderErrorMessage(noteTagForm) }

              <Button onClick={ handleDialogClose } color='primary'>
                Cancel
              </Button>

              <Button variant='contained'
                onClick={ this.handleSubmit }
                loading={ noteTagForm.get('submitting') }
                disabled={ disableButton }
                color='primary'>

                <Icon className={ classes.leftIcon } color='inherit'>speaker_notes</Icon>
                Submit

              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ () => this.handleHideSnackbar() }
          message={ snackbarMessage } />
      </div>
    );
  }
}

const styles = theme => ({
  dialogContentRoot: {
    minWidth:        '50vw',
    overflowX:       'hidden',
    overflowY:       'auto',
    padding:         0,
    '&:first-child': {
      paddingTop: 0,
    },

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(
  withStyles(styles)(NoteTagModal)
);
