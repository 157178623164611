import { all } from 'redux-saga/effects';

// sagas
import sessionVerifyRoot from './session/sessionVerify';

const context = require.context('./', true, /.*/);

const sagas = [];

context.keys().forEach((key) => {
  if (
    !(/\.js/).test(key)
    && !(/index/).test(key)
    && !(/__test__/).test(key)
    && !(/sessionVerify/).test(key)
    && key !== '.'
    && key !== './'
  ) {
    const object = context(key);

    sagas.push(object.default.apply());
  }
});

export default function* root() {
  yield* sessionVerifyRoot();
  yield all(sagas);
}
