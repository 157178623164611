import client from './client';

export default (function ForgotPasswordService() {
  let service;

  if (!service) {
    service = client.service('forgot-password');
  }

  return service;
}());
