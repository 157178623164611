import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import inviteService from '../../sources/api/inviteService';

// constants
import { INVITE_FETCH_ALL_FOR_CHAPTER, INVITE_FETCH_ALL_FOR_CHAPTER_INIT } from '../../constants/inviteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { inviteListSchema } from '../../schemas/invite';

export function* inviteFetchAllForChapter(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(INVITE_FETCH_ALL_FOR_CHAPTER, call(find, inviteService, options), {
      schema: inviteListSchema,
    });
  } catch (err) {
    yield put(error(INVITE_FETCH_ALL_FOR_CHAPTER, err));
  } finally {
    yield put(doneIndicator(INVITE_FETCH_ALL_FOR_CHAPTER));
  }
}

export function* watchInviteFetchAllForChapter() {
  yield takeEvery(INVITE_FETCH_ALL_FOR_CHAPTER_INIT, inviteFetchAllForChapter);
}

export default function* root() {
  yield all([
    fork(watchInviteFetchAllForChapter),
  ]);
}
