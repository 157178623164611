import { Map } from 'immutable';

// constants
import {
  VOTE_CLEAR,
  VOTE_CREATE,
  VOTE_DELETE,
  VOTE_FETCH_ALL,
  VOTE_FETCH_FOR_PNM,
  VOTE_FETCH_FOR_USER,
  VOTE_UPDATE,
} from '../constants/voteTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { addToFront, remove } from '../lib/normalizedReducerHelpers';

export default (state = Map(), action) => {
  switch (action.type) {
    case VOTE_CLEAR: return Map();
    case VOTE_CREATE: return httpReducer(state, action, { cb: addToFront() });
    case VOTE_DELETE: return httpReducer(state, action, { cb: remove() });
    case VOTE_FETCH_ALL: return httpReducer(state, action, { entity: 'vote' });
    case VOTE_FETCH_FOR_PNM: return httpReducer(state, action, { entity: 'vote' });
    case VOTE_FETCH_FOR_USER: return httpReducer(state, action, { entity: 'vote' });
    case VOTE_UPDATE: return httpReducer(state, action, { entity: 'vote', updateResult: false });
    default: return state;
  }
};
