import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form/immutable';
import validate from 'validate.js';
import Immutable, { Map } from 'immutable';
import moment from 'moment';

// components
import Graphs from './Graphs';

const createInitialValues = () => {
  const values = Immutable.fromJS({
    date: {
      from: moment().subtract(90, 'days').toDate().setHours(0, 0, 0, 0),
      to:   moment().toDate().setHours(0, 0, 0, 0),
    },
  });

  return values;
};

const mapStateToProps = state => ({
  analytics:     state.get('analytics'),
  analyticsForm: state.getIn(['form', 'analyticsForm'], Map()),
  chapter:       state.get('chapter'),
  currentUser:   state.get('currentUser'),
  initialValues: createInitialValues(state),
  router:        state.get('router'),
});

const mapDispatchToProps = dispatch => ({
  clearSelectedChapter: () => {
    dispatch(change('analyticsForm', 'selectedChapter', {}));
  },
});

// schemas
const schema = {
  chapters: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedGraphsForm = reduxForm({
  form:               'analyticsForm',
  validate:           validateForm,
  enableReinitialize: true,
  onChange:           handleChange,
})(Graphs);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedGraphsForm);
