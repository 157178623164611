import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import Button from '../../../../../Button';
import TextInput from '../../../../../TextInput';
import SelectInput from '../../../../../SelectInput';

class InviteForm extends Component {
  static propTypes = {
    inviteForm:     PropTypes.instanceOf(Map).isRequired,
    createInvite:   PropTypes.func.isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    invite:         PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        snackbarOpen:    false,
        snackbarMessage: '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { invite } = nextProps;

    if (nextProps.inviteForm.get('submitSucceeded')) {
      let message = 'Invite Sent Successfully';

      if (invite.get('message')) {
        message = invite.get('message');
      }

      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', message);
        }),
      });
    } else if (nextProps.inviteForm.get('submitFailed')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Invite failed to send, please try again');
        }),
      });
    }
  }

  getUserTypeOptions = () => {
    const items = [
      { value: 0, label: 'Voting Member' },
      // It's a workaround, CONCERN_CHAIR actually has
      // team_level = 0, handled in backend
      { value: 3, label: 'Concern Chair' },
      { value: 1, label: 'Recruitment Team' },
      { value: 2, label: 'Technology Team' },
    ];

    return items;
  }

  handleSubmit = () => {
    const { inviteForm, createInvite, currentChapter } = this.props;

    if (!inviteForm.get('syncErrors')) {
      const values = (inviteForm.get('values') || Map()).toJS();

      createInvite({
        chapter: currentChapter.getIn(['data', 'id'], 0),
        ...values,
      });
    }
  };

  handleClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  };

  render() {
    const { inviteForm } = this.props;

    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');

    let disableButton = false;
    if (inviteForm.get('syncErrors')) { disableButton = true; }

    return (
      <Grid container spacing={ 8 }>
        <Grid item xs={ 12 } sm={ 4 }>
          <Field name='email'
            label='Email Address'
            type='email'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 5 }>
          <Field name='teamLevel'
            label='What best describes this user?'
            variant='outlined'
            component={ SelectInput }
            options={ this.getUserTypeOptions() }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 3 } align='left'>
          <Button variant='contained'
            color='primary'
            style={ { marginBottom: -25 } }
            disabled={ inviteForm.get('pristine') || disableButton }
            onClick={ () => this.handleSubmit() }
            loading={ inviteForm.get('submitting') }>

            Invite User
          </Button>
        </Grid>

        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ snackbarOpen }
          autoHideDuration={ 5000 }
          onClose={ this.handleClose }
          ContentProps={ {
            'aria-describedby': 'save-message-id',
          } }
          message={ <span id='save-message-id'>{ snackbarMessage }</span> } />
      </Grid>
    );
  }
}

export default InviteForm;
