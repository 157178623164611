import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { denormalize } from 'normalizr';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import Button from '../../../Button';
import { Field } from 'redux-form/immutable';

// local components
import Autosuggest from '../../../Autosuggest';

// schemas
import { pnmListSchema } from '../../../../schemas/pnm';

class AssignVoteModal extends Component {
  static propTypes = {
    assignVoteForm: PropTypes.instanceOf(Map).isRequired,
    classes:        PropTypes.instanceOf(Object).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    fetchPnms:      PropTypes.func.isRequired,
    item:           PropTypes.instanceOf(Map).isRequired,
    onClose:        PropTypes.func.isRequired,
    open:           PropTypes.bool.isRequired,
    pnm:            PropTypes.instanceOf(Map).isRequired,
    updateVote:     PropTypes.func.isRequired,
    voteId:         PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        showSnackbar:    false,
        snackbarMessage: 'Unable to create pnm, please try again',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { assignVoteForm, open, onClose } = nextProps;
    const showSnackbar = this.state.data.get('showSnackbar');

    if (assignVoteForm.get('submitFailed') && !showSnackbar) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', true);
          map.set('snackbarMessage', 'Unable to create pnm, please try again');
        }),
      });
    } else if (showSnackbar && assignVoteForm.get('submitting')) {
      this.setState({ data: this.state.data.set('showSnackbar', false) });
    } else if (assignVoteForm.get('submitSucceeded')) {
      onClose();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', true);
          map.set('snackbarMessage', 'PNM Created Successfully');
        }),
      });
    } else if (!open && !assignVoteForm.get('submitSucceeded')) {
      onClose();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', false);
        }),
      });
    }
  }

  getSuggestions = () => {
    const { pnm } = this.props;

    const suggestions = [];

    const pnmData = pnm.get('data') || Map();

    if (pnmData.get('anonymousResult')) {
      const result = (pnmData.get('anonymousResult') || Immutable.List()).toJS();
      const entities = { pnm: (pnmData.get('items') || Immutable.List()).toJS() };

      const elements = denormalize(result, pnmListSchema, entities);

      elements.forEach((item) => {
        suggestions.push({
          label: `${item.firstname} ${item.lastname}`,
          value: item.id,
        });
      });
    }

    return suggestions;
  }

  handleUpdateSuggestions = ({ value }) => {
    const { currentChapter, fetchPnms } = this.props;

    const fetchParams = {
      search:  value,
      sort:    {},
      chapter: currentChapter.getIn(['data', 'id'], 0),
    };

    fetchPnms(fetchParams);
  }

  handleClearSuggestions = () => { }

  handleSubmit = () => {
    const {
      assignVoteForm, updateVote, item, currentChapter, voteId,
    } = this.props;

    const { pnm } = assignVoteForm.get('values').toJS();
    const chapter = currentChapter.getIn(['data', 'id'], 0);

    const author = (item.get('author') || Map()).get('id');
    const values = { ...item.toJS(), pnm: pnm.value, author };

    if (!assignVoteForm.get('syncErrors')) {
      updateVote({
        voteId,
        chapter,
        formName: 'assignVoteForm',
        ...values,
      });
    }
  }

  render() {
    const {
      classes, onClose, open, assignVoteForm,
    } = this.props;

    let disableButton = false;

    if (assignVoteForm.get('syncErrors')) { disableButton = true; }
    const snackbarMessage = this.state.data.get('snackbarMessage');

    return (
      <Dialog open={ open }
        onClose={ onClose }>
        <div className={ classes.header }>
          <Typography variant='h3'>Match Vote To PNM</Typography>
        </div>

        <DialogContent>
          <Grid container justify='center' className={ classes.bodyContainer }>
            <Grid item xs={ 10 } sm={ 8 } className={ classes.inputContainer }>
              <Typography>
                Help this vote finds its home! Search and select a PNM to match it
              </Typography>
              <Field name='pnm'
                label='Search for pnms'
                component={ Autosuggest }
                suggestions={ this.getSuggestions() }
                onSuggestionsFetchRequested={ this.handleUpdateSuggestions }
                onSuggestionsClearRequested={ this.handleClearSuggestions } />
            </Grid>
          </Grid>

          <DialogActions className={ classes.actionContainer }>
            <Button onClick={ onClose }
              disabled={ assignVoteForm.get('submitting') }
              color='primary'>

              Cancel
            </Button>

            <Button variant='contained'
              onClick={ this.handleSubmit }
              disabled={ disableButton }
              loading={ assignVoteForm.get('submitting') }
              color='primary'>

              Submit
            </Button>
          </DialogActions>
        </DialogContent>

        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ this.state.data.get('showSnackbar') }
          message={ snackbarMessage } />
      </Dialog>
    );
  }
}

const styles = theme => ({
  actionContainer: {
    marginTop: 50,
  },

  header: {
    backgroundColor: theme.palette.primary.main,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    padding:         10,
    marginBottom:    10,
  },

  bodyContainer: {
    height: 250,
  },

  inputContainer: {
    marginBottom: 10,
  },
});

export default withMobileDialog()(withStyles(styles)(AssignVoteModal));
