import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { userListSchema } from '../../schemas/user';

export default function getPnms(state, format = 'default') {
  const chapter = state.get('chapter');
  const user = state.get('user');
  let elements = [];

  const userData = user.get('data') || Map();
  const chapterData = chapter.get('data') || Map();

  if (userData.get('result') && typeof userData.get('result').toJS === 'function') {
    const result = (userData.get('result', List())).toJS();
    const entities = {
      user:    (userData.get('items', Map())).toJS(),
      chapter: (chapterData.get('items', Map())).toJS(),
    };

    elements = denormalize(result, userListSchema, entities) || [];
  }

  if (format === 'suggestions') {
    const suggestions = [];
    elements.forEach((item = {}) => {
      const suggestion = {
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      };

      suggestions.push(suggestion);
    });

    return suggestions;
  }

  return Immutable.fromJS(elements);
}
