import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';
import { membershipConcernListSchema } from '../../schemas/membershipConcern';

export default function getMembershipConcerns(pnmId) {
  return (state) => {
    const membershipConcern = state.get('membershipConcern', Map());
    const user = state.get('user');
    const concernCategory = state.get('concernCategory');

    const currentPnm = state.getIn(['pnm', 'data', 'items', pnmId.toString()], Map());

    const result = currentPnm.get('membershipConcerns', List());

    let items = [];
    if (result.size > 0) {
      const membershipConcerns = membershipConcern.getIn(['data', 'items'], Map());
      const users = user.getIn(['data', 'items'], Map());
      const concernCategories = concernCategory.getIn(['data', 'items'], Map());

      const entities = {
        membershipConcern: membershipConcerns.toJS(),
        user:              users.toJS(),
        concernCategory:   concernCategories.toJS(),
      };

      items = denormalize(result.toJS(), membershipConcernListSchema, entities);
    }

    return Immutable.fromJS(items);
  };
}
