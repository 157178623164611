import Immutable, { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import { denormalize } from 'normalizr';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../../../Button';
import SelectInput from '../../../../../SelectInput';

// schemas
import { hometownGroupListSchema } from '../../../../../../schemas/hometownGroup';

class HometownGroupsForm extends Component {
  static propTypes = {
    classes:                PropTypes.instanceOf(Object).isRequired,
    currentChapter:         PropTypes.instanceOf(Map).isRequired,
    hometownGroup:          PropTypes.instanceOf(Map).isRequired,
    hometownGroupPnmForm:   PropTypes.instanceOf(Map).isRequired,
    pnms:                   PropTypes.instanceOf(List).isRequired,
    createHometownGroupPnm: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        successMessage: null,
        errorMessage:   null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { hometownGroupPnmForm } = nextProps;

    if (hometownGroupPnmForm.get('submitSucceeded')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', null);
          map.set('successMessage', 'Hometowns Assigned Successfully');
        }),
      });
    } else if (hometownGroupPnmForm.get('submitFailed')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', 'Failed to Assign Hometowns');
          map.set('successMessage', null);
        }),
      });
    }
  }

  getHometownOptions = () => {
    const { currentChapter, hometownGroup } = this.props;

    let elements = [];

    const hometownGroupData = hometownGroup.get('data') || Map();
    const currentChapterData = currentChapter.get('data') || Map();

    const result = currentChapterData.get('hometownGroups') || List();

    if (result.size > 0) {
      const entities = { hometownGroup: (hometownGroupData.get('items') || List()).toJS() };

      elements = denormalize(result.toJS(), hometownGroupListSchema, entities)
        .map(hg => ({
          value: hg.id,
          label: hg.name,
        }));
    }

    return elements;
  }

  handleSave = () => {
    const {
      createHometownGroupPnm,
      currentChapter,
      hometownGroupPnmForm,
      pnms,
    } = this.props;

    if (!hometownGroupPnmForm.get('syncErrors')) {
      const hometownGroup = hometownGroupPnmForm.getIn(['values', 'hometownGroup'], 0);
      const chapterId = currentChapter.getIn(['data', 'id'], 0);

      const items = pnms.map(pnm => ({
        pnm:            pnm.get('id'),
        hometown_group: hometownGroup,
        chapter:        chapterId,
      })).toJS();

      createHometownGroupPnm({
        bulkCreate: true,
        chapter:    chapterId,
        items,
      });
    }
  }

  renderMessage = () => {
    const { classes } = this.props;
    const successMessage = this.state.data.get('successMessage');
    const errorMessage = this.state.data.get('errorMessage');

    let element;

    if (successMessage) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <Typography className={ classes.successLabel }>{ successMessage }</Typography>
        </Grid>
      );
    } else if (errorMessage) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <Typography color='error'>{ errorMessage }</Typography>
        </Grid>
      );
    }

    return element;
  }

  renderField = () => {
    const options = this.getHometownOptions();

    let element;

    if (options.length > 0) {
      element = (
        <Field name='hometownGroup'
          options={ options }
          component={ SelectInput }
          label='Select Hometown'
          fullWidth
          required />
      );
    } else {
      element = <Typography>You have no hometowns set up</Typography>;
    }

    return element;
  }

  render() {
    const { hometownGroupPnmForm } = this.props;

    let disabled = false;
    if (hometownGroupPnmForm.get('syncErrors')) { disabled = true; }

    return (
      <Grid item xs={ 12 }>
        <Grid container spacing={ 8 }>
          <Grid item xs={ 12 } align='center'>
            { this.renderField() }
          </Grid>

          <Grid item xs={ 12 } align='center'>
            <Button variant='contained'
              color='primary'
              loading={ hometownGroupPnmForm.get('submitting') }
              disabled={ disabled }
              onClick={ this.handleSave }>

              Save
            </Button>
          </Grid>

          { this.renderMessage() }
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  successLabel: {
    color: '#00E676',
  },
});

export default withStyles(styles)(HometownGroupsForm);
