import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { voteDelete, voteClear, voteFetchForPnm } from '../../../../actions/voteActions';
import getVotes from '../../../../lib/selectors/getVotes';

// components
import VotesTab from './VotesTab';

const mapStateToProps = state => ({
  vote:           state.get('vote'),
  votes:          getVotes(state),
  currentChapter: state.get('currentChapter'),
});

const mapDispatchToProps = dispatch => ({
  deleteVote: (params) => {
    dispatch(voteDelete(params));
  },
  fetchVotesForPnm: (params) => {
    dispatch(voteFetchForPnm(params));
  },
  clearVotes: () => {
    dispatch(voteClear());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VotesTab));
