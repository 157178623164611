import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import bumpGroupService from '../../sources/api/bumpGroupService';

// constants
import { BUMP_GROUP_FETCH_FOR_CHAPTER, BUMP_GROUP_FETCH_FOR_CHAPTER_INIT } from '../../constants/bumpGroupTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { bumpGroupListSchema } from '../../schemas/bumpGroup';

export function* bumpGroupFetchForChapter(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter, round, shouldPopulateUser = false } = action.payload || {};

  const options = {
    query: {
      chapter,
      round,
      $limit: 1000,
      $sort:  {
        createdAt: -1,
      },
    },
  };

  if (shouldPopulateUser) {
    options.query.shouldPopulateUser = true;
  }

  try {
    yield* httpSaga(BUMP_GROUP_FETCH_FOR_CHAPTER, call(find, bumpGroupService, options), {
      schema: bumpGroupListSchema,
    });
  } catch (err) {
    yield put(error(BUMP_GROUP_FETCH_FOR_CHAPTER, err));
  } finally {
    yield put(doneIndicator(BUMP_GROUP_FETCH_FOR_CHAPTER));
  }
}

export function* watchBumpGroupFetchForUser() {
  yield takeEvery(BUMP_GROUP_FETCH_FOR_CHAPTER_INIT, bumpGroupFetchForChapter);
}

export default function* root() {
  yield all([
    fork(watchBumpGroupFetchForUser),
  ]);
}
