import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';

// mui components
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

class TableToolbar extends Component {
  static propTypes = {
    classes:  PropTypes.instanceOf(Object).isRequired,
    icon:     PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title:    PropTypes.string.isRequired,
  };

  formatData = (chapters) => {
    // Creates an array of arrays without keys, only properties for exporting
    const data = [];

    chapters.forEach((chapter) => {
      data.push([
        chapter.location,
        chapter.date_created,
        chapter.date_updated,
        chapter.location,
        chapter.name,
        chapter.school,
        chapter.url,
        chapter.isCdSite,
      ]);
    });

    return data;
  };

  exportFile = (cols, unformattedData, sheetName, fileName) => {
    const data = this.formatData(unformattedData);
    // make array of column names
    const colNames = [];
    cols.forEach(col =>
      colNames.push(col.name));

    // create array with cols and data combined
    data.unshift(colNames);

    // autofit table cell width
    const colWidths = [];
    colNames.forEach((item) => {
      // Sets the cell width in characters, multiplied for more spacing
      colWidths.push({ wch: item.length * 2.5 });
    });

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(data);

    ws['!cols'] = colWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  render() {
    const {
      classes,
      icon, subtitle, title,
    } = this.props;

    return (
      <Toolbar className={ classes.root }>
        <Grid container className={ classes.tableToolbar }>
          <Grid item xs={ 6 } className={ classes.titleContainer }>
            <Icon className={ classes.titleIcon } color='primary'>{ icon }</Icon>
            <Typography variant='h5' color='primary'>{ title }</Typography>
          </Grid>

          <Grid item xs={ 12 } sm={ 12 } align='left' className={ classes.subtitleContainer }>
            <Typography variant='subtitle1' color='textSecondary'>{ subtitle }</Typography>
          </Grid>
        </Grid>

      </Toolbar>
    );
  }
}

const styles = theme => ({
  highlight:
    theme.palette.type === 'light'
      ? {
        color:           theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color:           theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },

  actions: {
    color: theme.palette.text.secondary,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  tableToolbar: {
    padding: 24,
  },

  title: {
    flex: '0 0 auto',
  },

  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      30,
  },

  titleContainer: {
    display:       'flex',
    flexDirection: 'row',
    marginBottom:  20,
  },

  subtitleContainer: {
    marginTop: -20,
  },

  logo: {
    width: 105,
  },
});

export default withStyles(styles)(TableToolbar);
