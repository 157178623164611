import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';

// components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

// local components
import Button from '../../../Button';

class MainCardHeader extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    clearanceLevel: PropTypes.number.isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    rounds:         PropTypes.instanceOf(List).isRequired,
    teamLevel:      PropTypes.number.isRequired,
    updateChapter:  PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Map({
        roundAnchorEl: null,
        eventAnchorEl: null,
        currentRound:  null,
        currentEvent:  0,
        isVotingOpen:  false,
      }),
    };
  }

  componentWillMount() {
    const { currentChapter } = this.props;

    const chapterData = (currentChapter.get('data') || Map());

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('currentRound', chapterData.get('currentRound'));
        map.set('currentEvent', chapterData.get('currentEvent'));
        map.set('isVotingOpen', chapterData.get('isVotingOpen'));
      }),
    });
  }

  componentWillReceiveProps(nextProps) {
    const { currentChapter } = nextProps;

    const chapterData = (currentChapter.get('data') || Map());
    const previousChapterData = this.props.currentChapter.get('data');

    if (currentChapter.get('data') && !previousChapterData) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('currentRound', chapterData.get('currentRound'));
          map.set('currentEvent', chapterData.get('currentEvent'));
          map.set('isVotingOpen', chapterData.get('isVotingOpen'));
        }),
      });
    }
  }

  handleCloseMenu = (id) => {
    if (id === 'rounds') {
      this.setState({ data: this.state.data.set('roundAnchorEl', null) });
    } else {
      this.setState({ data: this.state.data.set('eventAnchorEl', null) });
    }
  }

  handleOpenRoundMenu = (e) => {
    this.setState({ data: this.state.data.set('roundAnchorEl', e.currentTarget) });
  }

  handleOpenEventMenu = (e) => {
    this.setState({ data: this.state.data.set('eventAnchorEl', e.currentTarget) });
  }

  handleMenuChange = () => {
    const { currentChapter, updateChapter } = this.props;
    const currentRound = this.state.data.get('currentRound');
    const currentEvent = this.state.data.get('currentEvent');
    const isVotingOpen = this.state.data.get('isVotingOpen');

    const updateParams = {
      chapterId: currentChapter.getIn(['data', 'id'], 0),
      currentRound,
      currentEvent,
      isVotingOpen,
    };
    updateChapter(updateParams);
  }

  handleSelect = (type, id) => {
    if (type === 'rounds') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('roundAnchorEl', null);

          if (id !== 0) {
            map.set('isVotingOpen', true);
            map.set('currentRound', id);
          } else {
            map.set('isVotingOpen', false);
            map.set('currentEvent', 0);
          }
        }),
      });
    } else {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('currentEvent', id);
          map.set('eventAnchorEl', null);
        }),
      });
    }
  }

  renderRoundsMenu = (anchorEl, items) => (
    <Menu anchorEl={ anchorEl }
      anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
      getContentAnchorEl={ null }
      open={ Boolean(anchorEl) }
      onExited={ this.handleMenuChange }
      onClose={ () => this.handleCloseMenu('rounds') }>

      { items.map(item => (
        <MenuItem key={ item.get('_id') } onClick={ () => this.handleSelect('rounds', item.get('_id')) }>
          { item.get('name') }
        </MenuItem>
      ))}
    </Menu>
  );

  renderEventsMenu = (anchorEl, items) => (
    <Menu anchorEl={ anchorEl }
      anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
      getContentAnchorEl={ null }
      open={ Boolean(anchorEl) }
      onExited={ this.handleMenuChange }
      onClose={ () => this.handleCloseMenu('events') }>

      { items.map(item => (
        <MenuItem key={ item.get('key') } onClick={ () => this.handleSelect('events', item.get('key')) }>
          { item.get('label') }
        </MenuItem>
      ))}
    </Menu>
  );

  render() {
    const {
      classes, clearanceLevel, currentChapter, teamLevel, rounds,
    } = this.props;

    let formattedRounds = rounds;

    // Sorts rounds to make sure prescore precedes all other rounds
    rounds.forEach((round, index) => {
      if (round.get('roundType') === 6) {
        formattedRounds = formattedRounds.splice(index, 1);
        formattedRounds = formattedRounds.unshift(round);
      }
    });

    formattedRounds = formattedRounds.unshift(Immutable.fromJS({ _id: 0, name: 'Voting Closed' }));

    const isVotingOpen = this.state.data.get('isVotingOpen');

    const currentRound = this.state.data.get('currentRound');
    const currentRoundName = rounds.find((round => round.get('_id') === currentRound), null, Map()).get('name');

    const currentEvent = this.state.data.get('currentEvent');
    const eventCount = rounds.find(round => round.get('_id') === currentRound, null, Map()).get('events');

    const events = List().setSize(eventCount + 1).map((event, index) => {
      if (index === 0) {
        return Immutable.fromJS({ key: index, label: 'Open Voting' });
      }
      return Immutable.fromJS({ key: index, label: `Event ${index}` });
    });

    const roundLabel = isVotingOpen
      ? currentRoundName
      : 'Voting Closed';
    const eventLabel = (events.get(currentEvent) || List()).get('label');

    const roundAnchorEl = this.state.data.get('roundAnchorEl');
    const eventAnchorEl = this.state.data.get('eventAnchorEl');

    const demoMode = currentChapter.getIn(['data', 'demoMode']);
    const cobMode = currentChapter.getIn(['data', 'cobMode']);

    return (
      <CardContent className={ classes.mainCardHeader }>
        <Grid container spacing={ 8 }>

          { demoMode && (
            <Grid item xs={ 12 } sm={ 8 } className={ classes.modeContainer }>
              <Icon className={ classes.votingIcon }>
                new_releases
              </Icon>
              <Typography variant='subtitle1' className={ classes.modeLabel }>You Are In Training Mode</Typography>
            </Grid>
          )}

          { cobMode && !demoMode && (
            <Grid item xs={ 12 } sm={ 8 } className={ classes.modeContainer }>
              <Icon className={ classes.votingIcon }>
                new_releases
              </Icon>
              <Typography variant='subtitle1' className={ classes.modeLabel }>
                You Are In Continuous Open Bidding (COB) Mode
              </Typography>
            </Grid>
          )}

          { teamLevel > 0 || clearanceLevel > 0 ? (
            // Only admin can change round and event. Change to 1 later.
            <Grid item xs={ 12 }>
              <Grid container spacing={ 0 }>
                <Grid item xs={ 12 }>
                  <Button aria-owns={ roundAnchorEl ? 'roundSelector' : null }
                    aria-haspopup='true'
                    id='roundsMenu'
                    onClick={ this.handleOpenRoundMenu }>

                    <Typography variant='h3'>{ roundLabel }</Typography>
                    <Icon className={ classes.editIcon }>edit</Icon>
                  </Button>
                </Grid>

                { isVotingOpen
                  && (
                  <Grid item xs={ 12 }>
                    <Button aria-owns={ eventAnchorEl ? 'eventSelector' : null }
                      aria-haspopup='true'
                      id='eventsMenu'
                      onClick={ this.handleOpenEventMenu }>

                      <Typography variant='h4'>{ eventLabel }</Typography>
                      <Icon className={ classes.editIcon }>edit</Icon>
                    </Button>
                  </Grid>
                  )}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={ 12 }>
              <Typography variant='h3'>{ roundLabel }</Typography>
              { isVotingOpen && <Typography variant='h4'>{ eventLabel }</Typography> }
            </Grid>
          )}
        </Grid>

        { this.renderRoundsMenu(roundAnchorEl, formattedRounds) }
        { this.renderEventsMenu(eventAnchorEl, events) }

      </CardContent>
    );
  }
}

const styles = theme => ({
  mainCardHeader: {
    backgroundColor: theme.palette.primary.main,
    boxShadow:       '0 2px 10px 0 rgba(157, 157, 157, .5)',
  },

  selectContainer: {
    padding: '10px 10px 0 10px',
  },

  modeContainer: {
    alignItems: 'center',
    display:    'flex',
    marginLeft: 20,
  },

  votingIcon: {
    color: '#fff',
  },

  modeLabel: {
    color:      theme.palette.common.white,
    display:    'inline',
    marginLeft: 20,
  },

  editIcon: {
    marginLeft: 10,
    color:      theme.palette.common.white,
  },

  whiteText: {
    color: theme.palette.common.white,
  },
});

export default withStyles(styles)(MainCardHeader);
