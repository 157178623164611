export const MATCH_FETCH_FOR_PNM = 'MATCH_FETCH_FOR_PNM';
export const MATCH_FETCH_FOR_PNM_INIT = 'MATCH_FETCH_FOR_PNM_INIT';
export const MATCH_FETCH_FOR_PNM_DONE = 'MATCH_FETCH_FOR_PNM_DONE';

export const MATCH_FETCH_ALL = 'MATCH_FETCH_ALL';
export const MATCH_FETCH_ALL_INIT = 'MATCH_FETCH_ALL_INIT';
export const MATCH_FETCH_ALL_DONE = 'MATCH_FETCH_ALL_DONE';

export const MATCH_FETCH_FOR_USER = 'MATCH_FETCH_FOR_USER';
export const MATCH_FETCH_FOR_USER_INIT = 'MATCH_FETCH_FOR_USER_INIT';
export const MATCH_FETCH_FOR_USER_DONE = 'MATCH_FETCH_FOR_USER_DONE';

export const MATCH_DELETE = 'MATCH_DELETE';
export const MATCH_DELETE_INIT = 'MATCH_DELETE_INIT';
export const MATCH_DELETE_DONE = 'MATCH_DELETE_DONE';

export const MATCH_CREATE = 'MATCH_CREATE';
export const MATCH_CREATE_INIT = 'MATCH_CREATE_INIT';
export const MATCH_CREATE_DONE = 'MATCH_CREATE_DONE';

export const MATCH_AUTO = 'MATCH_AUTO';
export const MATCH_AUTO_INIT = 'MATCH_AUTO_INIT';
export const MATCH_AUTO_DONE = 'MATCH_AUTO_DONE';

export const MATCH_BULK_CREATE = 'MATCH_BULK_CREATE';
export const MATCH_BULK_CREATE_INIT = 'MATCH_BULK_CREATE_INIT';
export const MATCH_BULK_CREATE_DONE = 'MATCH_BULK_CREATE_DONE';

export const MATCH_CLEAR = 'MATCH_CLEAR';
