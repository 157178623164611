import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field } from 'redux-form/immutable';
import { Map, List } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// local components
import TextInput from '../../../TextInput';
import SelectInput from '../../../SelectInput';

const MembershipConcernForm = function ({
  classes,
  handleDialogClose,
  organization,
  pnm,
}) {
  const name = `${pnm.get('firstname')} ${pnm.get('lastname')}`;

  const getTitle = useCallback(() => `Write a membership concern for ${name}`, [name]);

  const getCategoryOptions = () => {
    const items = [];

    organization.get('concernCategories', List()).forEach((category) => {
      items.push({
        value: category.get('_id'),
        label: category.get('label'),
      });
    });

    return items;
  };

  return (
    <div>
      <Grid container
        spacing={ 24 }
        className={ classes.membershipConcernHeaderFilled }>
        <Grid item sm={ 12 } xs={ 11 }>
          <Typography variant='h6' align='center' gutterBottom className={ classes.headerText }>
            {getTitle()}
          </Typography>

        </Grid>
        <Hidden smUp>
          <IconButton className={ classes.closeIcon } onClick={ handleDialogClose }>
            <Icon>close</Icon>
          </IconButton>
        </Hidden>
      </Grid>

      <Grid container
        justify='center'
        className={ classes.paddedContainer }
        alignItems='center'>
        <Grid item md={ 8 } xs={ 11 }>
          <Field name='category'
            label='Concern Category'
            variant='outlined'
            helpertext='Choose Category'
            component={ SelectInput }
            options={ getCategoryOptions() }
            required />
        </Grid>

      </Grid>

      <Grid container
        justify='center'
        className={
            classes.membershipConcernTextContainer
}>
        <Grid item md={ 10 } xs={ 12 }>
          <Paper className={ classes.paperRoot }>
            <Field name='comment'
              key='multiline-flexible'
              label={ `Write a comment about ${pnm.get('firstname')}` }
              multiline
              minRows={ 3 }
              type='text'
              component={ TextInput }
              fullWidth
              maxLength={ 1000 } />
          </Paper>

        </Grid>
      </Grid>
    </div>
  );
};

MembershipConcernForm.propTypes = {
  classes:           PropTypes.instanceOf(Object).isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  organization:      PropTypes.instanceOf(Map).isRequired,
  pnm:               PropTypes.instanceOf(Object).isRequired,
};

const styles = theme => ({
  membershipConcernHeader: {
    paddingTop:   15,
    marginBottom: 15,
  },
  membershipConcernHeaderFilled: {
    backgroundColor: theme.palette.primary.main,
    paddingTop:      15,
    marginBottom:    15,
  },

  headerText: {
    color: theme.palette.common.white,
  },

  paddedContainer: {
    paddingLeft:  40,
    paddingRight: 40,
    marginBottom: 15,
    marginTop:    10,
  },

  closeIcon: {
    position: 'absolute',
    top:      10,
    right:    0,
    color:    theme.palette.common.white,
  },

  membershipConcernTextContainer: {
    display:      'flex',
    padding:      25,
    paddingLeft:  40,
    marginBottom: 20,
    marginTop:    -5,
  },
  paperRoot: theme.mixins.gutters({
    paddingTop:    16,
    paddingBottom: 16,
  }),

});

export default withStyles(styles)(MembershipConcernForm);
