import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import moment from 'moment-timezone';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../../../Button';
import SelectInput from '../../../../../SelectInput';
import CheckboxInput from '../../../../../CheckboxInput';
import SwitchInput from '../../../../../SwitchInput';

// helpers
import getUserRole from '../../../../../../lib/selectors/getUserRole';

class ExportReportsForm extends Component {
  static propTypes = {
    classes:           PropTypes.instanceOf(Object).isRequired,
    clearExports:      PropTypes.func.isRequired, // eslint-disable-line
    clearanceLevel:    PropTypes.number.isRequired,
    createExport:      PropTypes.func.isRequired, // eslint-disable-line
    currentChapter:    PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    currentRoundId:    PropTypes.string.isRequired,
    exportReportsForm: PropTypes.instanceOf(Map).isRequired,
    exportState:       PropTypes.instanceOf(Map).isRequired,
    isCdSite:          PropTypes.bool.isRequired,
    rounds:            PropTypes.instanceOf(List).isRequired,
    teamLevel:         PropTypes.number.isRequired,
    organization:      PropTypes.instanceOf(Map).isRequired,
    currentUser:       PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        exported:        false,
        snackbarOpen:    false,
        snackbarMessage: '',
        selectedReport:  '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { exportReportsForm } = this.props;
    const nextExportReportsForm = nextProps.exportReportsForm;

    const values = exportReportsForm.get('values') || Map();
    const nextValues = nextExportReportsForm.get('values') || Map();

    if (values !== nextValues) {
      this.handleReportChange(nextProps);
    }
  }

  getReportOptions = () => {
    const {
      clearanceLevel,
      teamLevel,
      organization,
      currentUser,
      currentChapter,
    } = this.props;

    const options = [
      { value: 'legacies', label: 'Legacies' },
      { value: 'matches', label: 'PNM Match Recommendations' },
      { value: 'tags', label: 'PNM Tags' },
    ];

    // CONCERN_CHAIR user should be able to see only membership concerns export
    const isConcernChairUser = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';

    if (isConcernChairUser) {
      if (organization.get('exportingConcernsFeatureEnabled', false)) {
        return [{ value: 'membershipConcerns', label: 'Membership Concerns' }];
      }
      return [];
    }

    if (clearanceLevel > 0 && organization.get('exportingConcernsFeatureEnabled', false)) {
      options.push({ value: 'membershipConcerns', label: 'Membership Concerns' });
    }

    if (teamLevel > 1 || clearanceLevel > 0) {
      options.push({ value: 'notes', label: 'Notes' });
      options.push({ value: 'votes', label: 'Votes For Current Round' });
      options.push({ value: 'votingMembers', label: 'Voting Members Report' });
      options.push({ value: 'roundReport', label: 'Round Report' });
    }
    return options;
  }

  getRoundOptions = () => {
    const { rounds, isCdSite, exportReportsForm } = this.props;
    const options = [];
    const report = Map.isMap(exportReportsForm) ? exportReportsForm.getIn(['values', 'report'], '') : '';

    rounds.forEach((round) => {
      const roundNotClosed = !round.get('roundClosed') > 0 && isCdSite;
      const roundHasNoScheduledPnms = !round.get('hasScheduledPnms') && !isCdSite;

      const label = roundNotClosed || roundHasNoScheduledPnms
        ? `${round.get('name')} (No Events Scheduled)`
        : round.get('name');

      options.push({
        label,
        value: round.get('_id'),
      });
    });
    if (report !== 'roundReport') {
      options.unshift({
        label: 'All Rounds',
        value: 0,
      });
    }

    return options;
  };

  getEventOptions = (type) => {
    const { rounds, currentRoundId, exportReportsForm } = this.props;

    const options = [];
    const eventFrom = exportReportsForm.getIn(['values', 'eventFrom']);
    const eventTo = exportReportsForm.getIn(['values', 'eventTo']);

    const currentRound = rounds.find(round => round.get('_id', '').toString() === currentRoundId) || Map();
    const events = currentRound.get('events', 0);

    // Prevents user from selecting a greater event for from, or lesser event for to
    const size = type === 'from' ? eventTo : events;
    const startIndex = type === 'from' ? 0 : eventFrom - 1;

    for (let i = startIndex; i < size; i += 1) {
      options.push({
        value: i + 1,
        label: `Event ${i + 1}`,
      });
    }

    return options;
  }

  isTrainingMode = () => {
    const {
      currentChapter,
    } = this.props;

    return Map.isMap(currentChapter) ? !!currentChapter.getIn(['data', 'demoMode']) : false;
  }

  handleReportChange = async (props) => {
    const {
      createExport, clearExports, currentChapter, exportReportsForm,
    } = props;

    const demoMode = this.isTrainingMode();

    const values = exportReportsForm.get('values', Map()).toJS();
    delete values.report;

    const report = exportReportsForm.getIn(['values', 'report'], '');

    const exportParams = {
      chapter:  currentChapter.getIn(['data', 'id'], 0),
      timezone: moment.tz.guess(),
    };

    switch (report) {
      case 'legacies':
        exportParams.legacies = { includeFields: 'gpa', ...values };
        break;
      case 'votes':
        exportParams.votes = { includeComments: true, ...values };
        break;
      case 'votingMembers':
        exportParams.votingMembers = true;
        break;
      case 'tags':
        exportParams.tags = Object.keys(values).length ? values : true;
        break;
      case 'notes':
        exportParams.notes = Object.keys(values).length ? values : true;
        break;
      case 'roundReport':
        exportParams.round = Object.keys(values).length ? {
          selectedRound: values.selectedRoundReport,
        } : true;
        break;
      case 'matches':
        exportParams.matches = true;
        break;
      case 'membershipConcerns':
        exportParams.membershipConcerns = true;
        break;
      default:
        break;
    }

    await clearExports();
    if (report && !(demoMode && report === 'roundReport')) {
      createExport(exportParams);
    }

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('selectedReport', report);
        map.set('exported', false);
      }),
    });
  }

  handleClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  };

  handleExport = () => {
    this.setState({ data: this.state.data.set('exported', true) });
  }

  renderErrorMessage = (form) => {
    let element;

    if (form.get('submitFailed')) {
      element = <FormHelperText error>Export failed.</FormHelperText>;

      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Save Failed.');
        }),
      });
    }
    return element;
  };

  renderExportButton = () => {
    const {
      classes,
      exportState,
    } = this.props;

    const selectedReport = this.state.data.get('selectedReport');
    const exported = this.state.data.get('exported');
    const loading = Boolean(exportState.get('loading'));
    const fileId = exportState.getIn(['data', 'id'], false);

    const demoMode = this.isTrainingMode();

    const element = (
      <Button variant='contained'
        href={ `${process.env.REACT_APP_API_URL}/exports/${fileId}` }
        color='primary'
        loading={ loading }
        disabled={ !!(!fileId || exported || (demoMode && selectedReport === 'roundReport')) }
        onClick={ this.handleExport }
        download>

        <Icon className={ classes.leftIcon }>file_download</Icon>
        {exported ? 'Exported' : 'Export'}
      </Button>
    );

    return element;
  }

  renderExportOptions = () => {
    const {
      classes, rounds, currentRoundId, isCdSite,
    } = this.props;

    let element;
    const selectedReport = this.state.data.get('selectedReport');
    const currentRound = rounds.find(round => round.get('_id', '').toString() === currentRoundId) || Map();
    const events = currentRound.get('events', 0);
    const demoMode = this.isTrainingMode();

    switch (selectedReport) {
      case 'tags':
        element = (
          <Grid item xs={ 12 }>
            <div className={ classes.exportOptionsContainer }>
              <Typography variant='subtitle1'>Export Options</Typography>
              <Field name='vmsOnly'
                label='Export Voting Members Only'
                component={ CheckboxInput } />
            </div>
          </Grid>
        );
        break;
      case 'votes':
        if (isCdSite) {
          element = (
            <Grid item xs={ 12 }>
              <div className={ classes.exportOptionsContainer }>
                <Grid container spacing={ 8 }>
                  <Grid item xs={ 12 }>
                    <Typography variant='subtitle1'>Export Options</Typography>
                    <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                      Select the same event for both to export by a single event
                    </Typography>
                  </Grid>

                  {events > 0 ? [
                    <Grid item xs={ 2 } key='from'>
                      <Field name='eventFrom'
                        label='From Event'
                        variant='outlined'
                        options={ this.getEventOptions('from') }
                        component={ SelectInput } />
                    </Grid>,
                    <Grid item xs={ 2 } key='to'>
                      <Field name='eventTo'
                        label='To Event'
                        variant='outlined'
                        options={ this.getEventOptions('to') }
                        component={ SelectInput } />
                    </Grid>,
                    <Grid item xs key='includeOpenVotes'>
                      <Field name='includeOpenVotes'
                        label='Include Votes Cast During Open Voting'
                        component={ SwitchInput } />
                    </Grid>,
                  ] : (
                    <Grid item xs={ 12 }>
                      <Typography color='textSecondary'>Round has no events to filter by</Typography>
                    </Grid>
                  )}

                </Grid>

              </div>
            </Grid>
          );
        }

        break;
      case 'notes':
        element = (
          <Grid item xs={ 12 }>
            <div className={ classes.exportOptionsContainer }>
              <Typography variant='subtitle1'>Export Options</Typography>
              <Typography variant='subtitle2' color='secondary'>
                Note: This will add the event that each PNM attended for the round to your export.
              </Typography>
              <Typography variant='subtitle2' color='secondary' gutterBottom>
                It will not filter notes by which round they were written in.
              </Typography>
              <Field name='selectedRound'
                label='Select Round'
                variant='outlined'
                options={ this.getRoundOptions() }
                component={ SelectInput } />
            </div>
          </Grid>
        );
        break;
      case 'roundReport':
        element = (
          <Grid item xs={ 12 }>
            <div className={ classes.exportOptionsContainer }>
              <Typography variant='subtitle1'>Export Options</Typography>
              {demoMode && (
                <Typography variant='subtitle2' color='secondary'>
                  This report is unavailable in training mode.
                </Typography>
              )}
              <Field name='selectedRoundReport'
                label='Select Round'
                variant='outlined'
                disabled={ demoMode }
                options={ this.getRoundOptions() }
                component={ SelectInput } />
            </div>
          </Grid>
        );
        break;
      default:
        break;
    }

    return element;
  }

  render() {
    const { exportReportsForm } = this.props;

    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');
    const selectedReport = this.state.data.get('selectedReport');

    return (
      <Grid container alignItems='center' alignContent='center' spacing={ 32 }>

        <Grid item xs={ 12 } sm={ 4 }>
          <Field name='report'
            label='Select A Report'
            component={ SelectInput }
            variant='outlined'
            options={ this.getReportOptions() }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 4 } align='left'>

          {this.renderErrorMessage(exportReportsForm)}

          {this.renderExportButton(selectedReport)}
        </Grid>

        {this.renderExportOptions()}

        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ snackbarOpen }
          autoHideDuration={ 5000 }
          onClose={ this.handleClose }
          ContentProps={ {
            'aria-describedby': 'save-message-id',
          } }
          message={ <span id='save-message-id'>{snackbarMessage}</span> } />
      </Grid>
    );
  }
}

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  exportOptionsContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    padding:      20,
  },
});

export default withStyles(styles)(ExportReportsForm);
