import {
  BUMP_GROUP_GENERATE_INIT,
  BUMP_GROUP_CREATE_INIT,
  BUMP_GROUP_REMOVE_INIT,
  BUMP_GROUP_FETCH_FOR_CHAPTER_INIT,
  BUMP_GROUP_BULK_UPDATE_INIT,
  BUMP_GROUP_UPDATE_INIT,
} from '../constants/bumpGroupTypes';

export function generateBumpGroups({ chapter, membersPerGroup }) {
  return {
    type:    BUMP_GROUP_GENERATE_INIT,
    payload: { chapter, membersPerGroup },
  };
}

export function createBumpGroup({ item }) {
  return {
    type:    BUMP_GROUP_CREATE_INIT,
    payload: { item },
  };
}

export function removeBumpGroup({ id, chapter }) {
  return {
    type:    BUMP_GROUP_REMOVE_INIT,
    payload: { id, chapter },
  };
}

export function fetchBumpGroupsForChapter({ chapter, round, shouldPopulateUser }) {
  return {
    type:    BUMP_GROUP_FETCH_FOR_CHAPTER_INIT,
    payload: { chapter, round, shouldPopulateUser },
  };
}

export function updateBumpGroup({
  id, item, chapter, shouldPopulateUser,
}) {
  return {
    type:    BUMP_GROUP_UPDATE_INIT,
    payload: {
      id,
      item,
      chapter,
      shouldPopulateUser,
    },
  };
}

export function bulkUpdateBumpGroups({ items, chapter, shouldPopulateUser }) {
  return {
    type:    BUMP_GROUP_BULK_UPDATE_INIT,
    payload: {
      items,
      chapter,
      shouldPopulateUser,
    },
  };
}
