import { connect } from 'react-redux';
import { Map } from 'immutable';

// action creators
import { concernCategoryBulkUpdate } from '../../../../../../actions/concernCategoryActions';

// selectors
import { isSubmitting, hasSubmitFailed, hasSubmitSucceeded } from '../../../../../../lib/selectors/formSelectors';
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';
import getStatusFromState from '../../../../../../lib/selectors/getStatusFromState';

// components
import ConcernCategoriesForm from './ConcernCategoriesForm';

const createInitialValues = (props) => {
  // concernCategories are the array of concernCategory data coming from the API/app state
  const {
    organizationConcernCategories,
  } = props;

  return organizationConcernCategories.sortBy((f = Map()) => f.get('order'));
};

const mapStateToProps = (state, props) => ({
  concernCategoryLoading:               getLoadingFromState('concernCategory')(state),
  concernCategoryStatus:                getStatusFromState('concernCategory')(state),
  concernCategoriesFormSubmitFailed:    hasSubmitFailed('concernCategoriesForm')(state),
  concernCategoriesFormSubmitSucceeded: hasSubmitSucceeded('concernCategoriesForm')(
    state
  ),
  concernCategoriesFormSubmitting: isSubmitting('concernCategoriesForm')(state),
  initialValues:                   Map({ concernCategories: createInitialValues(props) }),
});

const mapDispatchToProps = dispatch => ({
  bulkUpdateConcernCategories: (items, chapter) => {
    dispatch(concernCategoryBulkUpdate(items, chapter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConcernCategoriesForm);
