import { Map } from 'immutable';

// constants
import { EXPORT_FETCH_FOR_PDF } from '../constants/exportTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case EXPORT_FETCH_FOR_PDF: return httpReducer(state, action);
    default: return state;
  }
};
