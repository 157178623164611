import { Map } from 'immutable';
import { connect } from 'react-redux';

import { voteCreate, voteUpdate } from '../../../../../../actions/voteActions';

import getOrganization from '../../../../../../lib/selectors/getOrganization';
import getRounds from '../../../../../../lib/selectors/getRounds';
import getCategories from '../../../../../../lib/selectors/getCategories';

// components
import SingleBallotVoteForm from './SingleBallotVoteForm';

const mapStateToProps = state => ({
  categories:     getCategories(state),
  currentChapter: state.get('currentChapter', Map()),
  currentUser:    state.get('currentUser', Map()),
  rounds:         getRounds(state),
  organization:   getOrganization(state),
});

const mapDispatchToProps = dispatch => ({
  createVote: (params) => {
    dispatch(voteCreate(params));
  },
  updateVote: (params) => {
    dispatch(voteUpdate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleBallotVoteForm);
