import client from './client';

export default (function SpecialtiesService() {
  let service;

  if (!service) {
    service = client.service('specialties');
  }

  return service;
}());
