import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';

// components
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

class HeaderMenu extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    navigate:       PropTypes.func.isRequired,
    router:         PropTypes.instanceOf(Object).isRequired,
    width:          PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        value: '/',
      }),
    };
  }

  componentWillMount() {
    const { router: { location: { pathname } } } = this.props;
    const value = this.tabValueExists(pathname) && pathname;

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('value', value);
      }),
    });
  }

  componentWillReceiveProps(nextProps) {
    const currentPath = this.props.router.location.pathname;
    const nextPath = nextProps.router.location.pathname;

    const valueExists = this.tabValueExists(nextPath);
    const tabActive = this.tabActive(nextPath);

    if (currentPath !== nextPath && valueExists) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('value', nextPath);
        }),
      });
    } else if (!valueExists && !tabActive) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('value', false);
        }),
      });
    }
  }

  getLabel = (tab) => {
    const { width } = this.props;
    const label = width !== 'xs' ? tab.get('label') : '';
    return label;
  }

  getTabs = (pathname) => {
    const { currentChapter, currentUser } = this.props;
    const teamLevel = (currentChapter.get('data') || Map()).get('team_level');
    const showAdvancedMatching = (currentChapter.get('data') || Map()).get('showAdvancedMatching');
    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level');

    let adminTabs = Immutable.fromJS([
      {
        label: 'Home',
        icon:  'account_balance',
        value: '/',
        match: (/^\/$/),
      },
      {
        label: 'Results',
        icon:  'thumbs_up_down',
        value: '/results',
        match: (/\/results\/.*/),
      },
      {
        label: 'Export',
        icon:  'file_download',
        value: '/settings/export',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Settings',
        icon:  'settings',
        value: '/settings/account',
        match: (/\/settings\/.*/),
      },
    ]);

    const recruitmentTeamTabs = Immutable.fromJS([
      {
        label: 'Home',
        icon:  'account_balance',
        value: '/',
        match: (/^\/$/),
      },
      {
        label: 'Export',
        icon:  'file_download',
        value: '/settings/export',
        match: (/\/settings\/.*/),
      },
      {
        label: 'Settings',
        icon:  'settings',
        value: '/settings/account',
        match: (/\/settings\/.*/),
      },
    ]);

    const votingMemberTabs = Immutable.fromJS([
      {
        label: 'Home',
        icon:  'account_balance',
        value: '/',
        match: (/^\/$/),
      },
      {
        label: 'Settings',
        icon:  'settings',
        value: '/settings/account',
        match: (/\/settings\/.*/),
      },
    ]);

    const dashboardTabs = Immutable.fromJS([
      {
        label: 'Chapters',
        icon:  'people',
        value: '/dashboard',
        match: (/\/dashboard\/.*/),
      },
      {
        label: 'Analytics',
        icon:  'insert_chart',
        value: '/dashboard/analytics',
        match: (/\/dashboard\/.*/),
      },
      {
        label: 'Settings',
        icon:  'settings',
        value: '/dashboard/settings/account',
        match: (/\/dashboard\/settings\/.*/),
      },
    ]);

    if ((pathname.includes('dashboard') || !currentChapter.get('data')) && clearanceLevel > 0) {
      return dashboardTabs;
    }
    if (teamLevel === 1) {
      return recruitmentTeamTabs;
    }
    if (teamLevel > 0 || clearanceLevel > 0) {
      if (showAdvancedMatching) {
        adminTabs = adminTabs.splice(2, 0, Immutable.fromJS({
          label: 'Match',
          icon:  'extension',
          value: '/match/user/0',
          match: (/\/match\/.*/),
        }));

        // Gets rid of Export tab to make room
        adminTabs = adminTabs.splice(3, 1);
      }
      return adminTabs;
    }
    return votingMemberTabs;
  }

  handleChange = (event, value) => {
    const { navigate } = this.props;

    navigate(value);
  }

  tabValueExists(value) {
    const tabs = this.getTabs(value);
    const valueExists = tabs.filter(t => t.get('value') === value).size === 1;

    return valueExists;
  }

  tabActive(value) {
    const tabs = this.getTabs(value);
    const valueExists = tabs.filter(t => t.get('match').test(value)).size === 1;

    return valueExists;
  }

  renderIcon = (tab) => {
    const { classes } = this.props;

    return <Icon className={ classes.menuIcon }>{ tab.get('icon') }</Icon>;
  }

  renderTabs(tabs) {
    const { classes } = this.props;
    const elements = [];

    tabs.forEach((tab) => {
      elements.push(
        <Tab classes={ { root: classes.tab, wrapper: classes.wrapper } }
          key={ tab.get('value') }
          label={ this.getLabel(tab) }
          icon={ this.renderIcon(tab) }
          value={ tab.get('value') } />
      );
    });

    return elements;
  }

  render() {
    const { classes, router: { location: { pathname } } } = this.props;

    const value = this.state.data.get('value');
    const tabs = this.getTabs(pathname);

    return (
      <Tabs className={ classes.tabs }
        indicatorColor='primary'
        onChange={ this.handleChange }
        textColor='primary'
        value={ value }
        variant='fullWidth'
        centered>

        { this.renderTabs(tabs) }
      </Tabs>
    );
  }
}

const styles = () => ({
  tabs: {
    flex:      1,
    minHeight: 65,
  },

  wrapper: {
    display: 'block',
  },

  menuIcon: {
    verticalAlign: 'bottom',
  },

  tab: {
    minHeight: 65,
  },
});

export default compose(withStyles(styles), withWidth())(HeaderMenu);
