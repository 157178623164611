import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

// components
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

class SelectInput extends Component {
  static propTypes = {
    classes:            PropTypes.instanceOf(Object).isRequired,
    containerClassName: PropTypes.string,
    menuItemClassName:  PropTypes.string,
    errorClassName:     PropTypes.string,
    input:              PropTypes.instanceOf(Object).isRequired,
    meta:               PropTypes.instanceOf(Object).isRequired,
    options:            PropTypes.instanceOf(Array),
    id:                 PropTypes.string,
    label:              PropTypes.string,
    helpertext:         PropTypes.string,
  }

  static defaultProps = {
    containerClassName: '',
    menuItemClassName:  '',
    errorClassName:     '',
    options:            [],
    id:                 '',
    label:              '',
    helpertext:         '',
  }

  shouldDisplayError = () => {
    const {
      meta: {
        active,
        dirty,
        error,
        touched,
      },
    } = this.props;

    return error && !active && (dirty || touched);
  }

  renderError = () => {
    const { helpertext, errorClassName, meta: { error } } = this.props;

    let element;

    if (this.shouldDisplayError()) {
      element = (
        <FormHelperText className={ errorClassName } error>
          { error[0] }
        </FormHelperText>
      );
    } else {
      element = (
        <FormHelperText>
          { helpertext }
        </FormHelperText>
      );
    }

    return element;
  }

  renderOptions = () => {
    const { options, menuItemClassName } = this.props;

    const elements = [];

    options.forEach((option) => {
      elements.push(
        <MenuItem className={ menuItemClassName } key={ option.value } value={ option.value }>
          <Typography noWrap>
            { option.label }
          </Typography>
        </MenuItem>
      );
    });

    return elements;
  }

  render() {
    const {
      classes,
      label,
      id,
      containerClassName,
      errorClassName,
      input,
      options,
      menuItemClassName,
      ...rest
    } = this.props;

    return (
      <FormControl className={ classNames(containerClassName, classes.formControl) }>

        <TextField id={ id }
          select
          error={ this.shouldDisplayError() }
          inputProps={ { id, ...input } }
          value={ input.value }
          label={ label }
          { ...rest }>

          { this.renderOptions() }
        </TextField>

        { this.renderError() }
      </FormControl>
    );
  }
}

const styles = () => ({
  formControl: {
    width: '100%',
  },
});

export default withStyles(styles)(SelectInput);
