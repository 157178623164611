import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Immutable from 'immutable';
import { Field } from 'react-final-form';

// local components
import SwitchInput from '../../../../SwitchInput';
import AutoSuggestDeluxe from '../../../../AutoSuggestDeluxe';
import SelectInput from '../../../../SelectInput';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const SlideshowSettings = function ({
  classes,
  values,
  fields,
}) {
  const [suggestions, setSuggestions] = useState(fields);

  const handleFieldsSearchChange = (value) => {
    const newSuggestions = fields.filter((field = {}) => {
      const { label = '' } = field;
      const fieldName = label.toLowerCase();

      if (value) {
        return fieldName.includes(value.toLowerCase());
      }
      return true;
    });

    setSuggestions(newSuggestions);
  };

  const getSortByOptions = () => [
    {
      value: 'firstName',
      label: 'First Name',
    },
    {
      value: 'lastName',
      label: 'Last Name',
    },
    {
      value: 'status',
      label: 'Status',
    },
  ];

  const getOrderOptions = () => [
    {
      value: 'asc',
      label: 'Ascending',
    },
    {
      value: 'desc',
      label: 'Descending',
    },
  ];

  return (
    <Grid container>
      <Grid item xs={ 8 }>
        <Grid container>
          <Grid item xs={ 12 }>
            <div className={ classes.settingsItemContainer }>
              <Field name='fieldsToDisplay'
                render={ props => (
                  <AutoSuggestDeluxe multiSelect
                    label='Select Fields'
                    getAllSuggestions={ () => Immutable.fromJS(fields) }
                    formValues={ Immutable.fromJS(values) }
                    suggestions={ suggestions }
                    onSearchChange={ handleFieldsSearchChange }
                    { ...props } />
                ) } />
            </div>
          </Grid>
          <Grid item xs={ 12 }>
            <div className={ classes.settingsItemContainer }>
              <Grid container spacing={ 16 }>
                <Grid item xs={ 6 }>
                  <Field name='sortBy'
                    render={ props => (
                      <SelectInput options={ getSortByOptions() }
                        label='Sort By'
                        variant='outlined'
                        { ...props } />
                    ) } />
                </Grid>

                <Grid item xs={ 6 }>
                  <Field name='order'
                    render={ props => (
                      <SelectInput options={ getOrderOptions() }
                        label='Order'
                        variant='outlined'
                        { ...props } />
                    ) } />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={ 4 }>
        <div className={ classes.settingsItemContainer }>
          <Grid container>
            <Grid item xs={ 10 }>
              <Typography color='textSecondary' variant='body1'>Include</Typography>
            </Grid>

            <Grid item xs={ 10 }>
              <Field name='displayStatus'
                render={ props =>
                  <SwitchInput label='Status' id='displayStatus' { ...props } /> } />
            </Grid>

            <Grid item xs={ 10 }>
              <Field name='displayTags'
                render={ props =>
                  <SwitchInput label='Tags' id='displayTags' { ...props } /> } />
            </Grid>

            <Grid item xs={ 10 }>
              <Field name='displayHometownGroup'
                render={ props =>
                  <SwitchInput label='Hometown Group' id='displayHometownGroup' { ...props } /> } />
            </Grid>

            <Grid item xs={ 10 }>
              <Field name='displayNotes'
                render={ props =>
                  <SwitchInput label='Notes' id='displayNotes' { ...props } /> } />
            </Grid>

            <Grid item xs={ 10 }>
              <Field name='displayMatches'
                render={ props =>
                  <SwitchInput label='Matches' id='displayMatches' { ...props } /> } />
            </Grid>

          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

SlideshowSettings.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  values:  PropTypes.instanceOf(Object).isRequired,
  fields:  PropTypes.instanceOf(Array).isRequired,
  invalid: PropTypes.bool.isRequired,
};

const styles = theme => ({
  settingsItemContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       10,
    padding:      '15px !important',
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(SlideshowSettings);
