import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create, get } from '../../sources/api/client';
import hometownGroupPnmService from '../../sources/api/hometownGroupPnmService';
import chapterService from '../../sources/api/chapterService';

// constants
import { HOMETOWN_GROUP_PNM_CREATE, HOMETOWN_GROUP_PNM_CREATE_INIT } from '../../constants/hometownGroupPnmTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schema
import { chapterSchema } from '../../schemas/chapter';

export function* hometownGroupPnmCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'hometownGroupPnmForm';

  yield put(startSubmit(FORM));

  const { payload } = action;

  let values;

  const options = {};

  if (payload.bulkCreate) {
    values = payload.items;
    options.query = { chapter: payload.chapter };
  } else if (payload.import) {
    values = payload.items;
    options.query = {
      chapter: payload.chapter,
      import:  payload.import,
    };
  } else {
    values = payload;
  }

  try {
    yield* httpSaga(HOMETOWN_GROUP_PNM_CREATE, call(
      create, hometownGroupPnmService, values, options
    ));

    if (payload.import || payload.bulkCreate) {
      yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, payload.chapter), {
        schema: chapterSchema,
      });
    }

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(HOMETOWN_GROUP_PNM_CREATE, err));
  } finally {
    yield put(doneIndicator(HOMETOWN_GROUP_PNM_CREATE));
  }
}

export function* watchHometownGroupPnmCreate() {
  yield takeEvery(HOMETOWN_GROUP_PNM_CREATE_INIT, hometownGroupPnmCreate);
}

export default function* root() {
  yield all([
    fork(watchHometownGroupPnmCreate),
  ]);
}
