import { Map } from 'immutable';
import PnmPhotoModal from './PnmPhotoModal';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';

// action creators
import { pnmUpdate } from '../../../../actions/pnmActions';

const createInitialValues = (props) => {
  const { pnmData = Map() } = props;

  const values = {
    image: pnmData.get('image'),
  };

  return values;
};

const schema = {
  image: {
    presence: true,
  },
};

const mapStateToProps = (state, props) => ({
  pnm:            state.get('pnm'),
  field:          state.get('field'),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  user:           state.get('user'),
  pnmPhotoForm:   state.getIn(['form', 'pnmPhotoForm'], Map()),
  initialValues:  createInitialValues(props),
});

const mapDispatchToProps = dispatch => ({
  bulkUpdatePnms: (params) => {
    dispatch(pnmUpdate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:               'pnmPhotoForm',
  validate:           validateForm,
  onChange:           handleChange,
  enableReinitialize: true,
})(PnmPhotoModal));
