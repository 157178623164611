import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';
import { Field } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../Button';
import TextInput from '../../../TextInput';
import SelectInput from '../../../SelectInput';
import MultiSelectInput from '../../../MultiSelectInput';
import CheckboxInput from '../../../CheckboxInput';
import Space from '../../../Space';
import SwitchInput from '../../../SwitchInput';

// helpers
import isOverallScoreVisible from '../../helpers/isOverallScoreVisible';
import { isEmpty, isObject } from '../../../../helpers/check';

// icons
import SettingsIcon from '../../../../assets/images/settings.svg';

class FiltersForm extends Component {
  static propTypes = {
    bulkUpdateRounds:            PropTypes.func.isRequired,
    classes:                     PropTypes.instanceOf(Object).isRequired,
    currentChapter:              PropTypes.instanceOf(Map).isRequired,
    dirty:                       PropTypes.bool.isRequired,
    fields:                      PropTypes.instanceOf(List).isRequired,
    filtersForm:                 PropTypes.instanceOf(Map).isRequired,
    organization:                PropTypes.instanceOf(Map).isRequired,
    reset:                       PropTypes.func.isRequired, // eslint-disable-line
    setOpenModalAdvancedOptions: PropTypes.func.isRequired,
    advancedDisplayOptionForm:   PropTypes.instanceOf(Map).isRequired,
    tags:                        PropTypes.instanceOf(List).isRequired,
    updateChapter:               PropTypes.func.isRequired,
    rounds:                      PropTypes.instanceOf(Immutable.Iterable).isRequired,
    change:                      PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        showFilters:         1,
        showAdvancedOptions: false,
        snackbarOpen:        false,
        snackbarMessage:     '',
        modalFields:         {},
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { filtersForm, reset } = nextProps;

    if (filtersForm.get('submitSucceeded')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Saved Successfully');
        }),
      });
    } else if (filtersForm.get('submitFailed')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Save Failed');
        }),
      });
    }
  }

  getPnmsToIncludeOptions = () => {
    const elements = [
      { value: 0, label: 'All PNMs' },
      { value: 1, label: 'Legacies' },
      { value: 2, label: 'Withdrawn PNMs' },
    ];

    return elements;
  }

  getPoolOptions = () => {
    const elements = [
      { value: 0, label: 'All Pools' },
      { value: 1, label: 'Primary' },
      { value: 2, label: 'Secondary' },
    ];

    return elements;
  }

  getTagOptions = () => {
    const { tags } = this.props;

    const elements = tags
      .map(t => ({
        value: t.get('_id'),
        label: t.get('type') === 'campusDirector'
          ? `${t.get('title')} (from CampusDirector)`
          : t.get('title'),
      })).toArray();

    return elements;
  }

  getEventOptions = () => {
    const { rounds } = this.props;
    const { currentChapter } = this.props;
    const currentRound = (currentChapter.get('data') || Map()).get('currentRound');

    const eventCount = (rounds.find(round => round.get('_id') === currentRound, Map())
     && rounds.find(round => round.get('_id') === currentRound, Map()).get('events'));

    const events = List().setSize(eventCount + 1).toJS().map((event, index) => {
      if (index === 0) {
        return { value: index, label: 'No Events' };
      }
      return { value: index, label: `Event ${index}` };
    });

    return events;
  }

  getGpaOptions = () => {
    const { fields } = this.props;

    const filteredFields = fields.filter((f = Map()) => {
      const fieldName = f.getIn(['standardField', 'text'], '').toLowerCase();
      return f.get('teamLevel') < 2
        && fieldName.includes('gpa');
    });
    return ((filteredFields.map(f =>
      ({
        value: f.getIn(['standardField', 'id'], 0),
        label: f.getIn(['standardField', 'text'], ''),
      }))) || List()).toJS();
  }

  getDisplayOverallScore = () => {
    const { currentChapter, organization } = this.props || {};

    return isOverallScoreVisible(currentChapter, organization);
  };

  getRoundStatus = () => {
    const { advancedDisplayOptionForm, rounds } = this.props;

    const displayRoundScoresList = advancedDisplayOptionForm.getIn(['values', 'displayRoundScoresList'], Map());
    const roundScores = Map.isMap(displayRoundScoresList) ? displayRoundScoresList : Map();

    return List.isList(rounds) ? rounds.find((round) => {
      const key = round.get('_id');
      return key && !roundScores.get(key);
    }) : false;
  }

  resetModalValues = ({
    displayRoundScoresList = {},
    displayCategoryScoresList = {},
  }) => {
    const resetSubList = (key, oldSubList, currentList) => {
      const isValueObject = isObject(oldSubList);
      Object.keys(currentList).forEach((currentKey) => {
        this.props.change(
          `${key}.${currentKey}`,
          isValueObject && !isEmpty(oldSubList[currentKey])
            ? oldSubList[currentKey] : false
        );
      });
    };
    const mapModalValues = this.state.data.get('modalFields');
    const modalValues = Map.isMap(mapModalValues) ? mapModalValues.toJS() : mapModalValues || {};

    Object.keys(modalValues).forEach((key) => {
      switch (key) {
        case 'displayRoundScoresList':
          resetSubList(key, modalValues[key], displayRoundScoresList);
          break;
        case 'displayCategoryScoresList':
          resetSubList(key, modalValues[key], displayCategoryScoresList);
          break;
        default:
          this.props.change(key, modalValues[key] || false);
          break;
      }
    });
  };

  handleShowFilters = () => {
    const showFilters = this.state.data.get('showFilters');

    this.setState({
      data: this.state.data.set('showFilters', !showFilters),
    });
  }

  handleClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  };

  handleOpenAdvancedOptions = () => {
    this.props.setOpenModalAdvancedOptions(true);
  };

  handleSubmit = () => {
    const {
      bulkUpdateRounds, currentChapter, filtersForm, updateChapter,
    } = this.props;

    const currentRound = currentChapter.getIn(['data', 'currentRound'], false);

    if (!filtersForm.get('syncErrors')) {
      const {
        // rounds values:
        flexMinus,
        flexPlus,
        invitations,
        // chapter values:
        selectedTags,
        gpaFieldOne,
        gpaFieldTwo,
        gpaRiskOne,
        gpaRiskTwo,
        moveLegaciesToTop,
        moveGradeRisksToBottom,
        moveByTagId,
        moveByTagToTop,
        resultsPnmsToInclude,
        resultsPoolType,
        includeGPA1,
        includeGPA2,
        includePnmImages,
        includePnmTags,
        selectedEvent,
        includePnmStatus,
        displayOverallScores,
        displayRoundScores,
        displayCategoryScores,
        displayRoundScoresList,
        selectedField,
        searchPnmFilter,
      } = filtersForm.get('values').toJS();

      const chapterParams = {
        chapterId:     currentChapter.getIn(['data', 'id'], 0),
        formName:      'filtersForm',
        selectedTags:  List.isList(selectedTags) ? selectedTags.toJS() : selectedTags || [],
        selectedEvent: List.isList(selectedEvent) ? selectedEvent.toJS() : selectedEvent || [],
        selectedField,
        gpaFieldOne,
        gpaRiskOne,
        gpaFieldTwo,
        gpaRiskTwo,
        resultsPoolType,
        resultsPnmsToInclude,
        moveLegaciesToTop,
        moveGradeRisksToBottom,
        moveByTagId,
        moveByTagToTop,
        includeGPA1,
        includeGPA2,
        includePnmImages,
        includePnmTags,
        includePnmStatus,
        displayOverallScores,
        displayRoundScores,
        displayCategoryScores,
        displayRoundScoresList,
        searchPnmFilter,
      };

      const roundsValues = [{
        _id: currentRound,
        flexMinus,
        flexPlus,
        invitations,
      }];

      updateChapter(chapterParams);

      if (currentRound) {
        bulkUpdateRounds({ items: roundsValues, chapter: currentChapter.getIn(['data', 'id'], 0), formName: 'filtersForm' });
      }
    }
  };

  handleClearSearchField = () => {
    this.props.change('searchPnmFilter', '');
  };

  renderErrorMessage = (form) => {
    const { classes, currentChapter } = this.props;

    const currentRound = currentChapter.getIn(['data', 'currentRound'], false);

    let element;

    if (form.get('submitFailed')) {
      element = (
        <FormHelperText className={ classes.errorMessage } error>
          Save failed.
        </FormHelperText>
      );
    }
    if (!currentRound) {
      element = (
        <div>
          <FormHelperText className={ classes.errorMessage }>
            Welcome to the Results page! It looks like you have yet to open a round.
          </FormHelperText>
          <FormHelperText className={ classes.errorMessage }>
            Create a round in Settings and set it on the Home page
            to save flex and invitation fields.
          </FormHelperText>
        </div>
      );
    }

    return element;
  };

  render() {
    const {
      classes,
      currentChapter,
      dirty,
      filtersForm,
      organization,
    } = this.props;

    const values = !filtersForm.get('syncErrors') ? filtersForm.get('values') : Map();

    const {
      searchPnmFilter,
    } = Map.isMap(values) ? values.toJS() : {};

    const showFilters = this.state.data.get('showFilters');
    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');

    const currentRound = currentChapter.getIn(['data', 'currentRound'], false);
    const disableGradeRiskFeature = organization.get('disableGradeRiskFeature');

    let disableButton = !dirty;
    if (filtersForm.get('syncErrors')) {
      disableButton = true;
    }

    const showRoundsWarning = this.getRoundStatus();

    return (
      <div>
        <Card className={ classes.filtersCard }>
          <CardContent>
            <Grid container>
              <Grid item xs={ 6 } className={ classes.titleContainer }>
                <Icon className={ classes.titleIcon } color='primary'>filter_list</Icon>
                <Typography variant='h5' color='primary'>Filters</Typography>
              </Grid>

              <Grid item xs={ 6 } align='right'>
                <Button variant='text'
                  color='primary'
                  onClick={ this.handleShowFilters }>
                  { showFilters ? 'Hide' : 'Show' }
                  <Icon className={ classes.rightIcon }>{ showFilters ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }</Icon>
                </Button>
              </Grid>

              { showFilters && (
                <Grid container spacing={ 24 }>
                  <Grid item xs={ 4 }>
                    <Grid container className={ classes.mainFiltersContainer } spacing={ 24 }>
                      <Grid item xs={ 12 } sm={ 4 } align='left'>
                        <Field name='flexMinus'
                          label='Flex -'
                          type='number'
                          variant='outlined'
                          margin='dense'
                          disabled={ !currentRound }
                          component={ TextInput }
                          fullWidth />
                      </Grid>
                      <Grid item xs={ 12 } sm={ 4 } align='left'>
                        <Field name='invitations'
                          label='Invitations'
                          type='number'
                          variant='outlined'
                          margin='dense'
                          disabled={ !currentRound }
                          component={ TextInput }
                          fullWidth />
                      </Grid>
                      <Grid item xs={ 12 } sm={ 4 } align='left'>
                        <Field name='flexPlus'
                          label='Flex +'
                          type='number'
                          variant='outlined'
                          margin='dense'
                          disabled={ !currentRound }
                          component={ TextInput }
                          fullWidth />
                      </Grid>

                      <Grid item xs={ 12 } sm={ 6 }>
                        <Field name='resultsPnmsToInclude'
                          label='PNMs To Include'
                          component={ SelectInput }
                          variant='outlined'
                          margin='dense'
                          options={ this.getPnmsToIncludeOptions() }
                          fullWidth
                          required />
                      </Grid>
                      <Grid item xs={ 12 } sm={ 6 }>
                        <Field name='resultsPoolType'
                          label='Pools'
                          variant='outlined'
                          margin='dense'
                          component={ SelectInput }
                          options={ this.getPoolOptions() }
                          fullWidth
                          required />
                      </Grid>
                      <Grid item xs={ 12 }>
                        <Field name='selectedTags'
                          label='Filter By Tags'
                          variant='outlined'
                          margin='dense'
                          containerClassName={ classes.multiSelectInput }
                          displaySelectAll={ false }
                          displayClear={ false }
                          withoutSorting
                          component={ MultiSelectInput }
                          options={ Immutable.fromJS(this.getTagOptions()) }
                          fullWidth
                          required />
                      </Grid>
                      <Grid item xs={ 12 }>
                        <Field name='selectedEvent'
                          label='Filter By Event'
                          variant='outlined'
                          margin='dense'
                          containerClassName={ classes.multiSelectInput }
                          displaySelectAll={ false }
                          displayClear={ false }
                          withoutSorting
                          component={ MultiSelectInput }
                          options={ Immutable.fromJS(this.getEventOptions()) }
                          fullWidth
                          required />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={ 4 }>
                    <Grid container className={ classes.fieldFiltersContainer } spacing={ 24 }>
                      <Grid item xs={ 12 } sm={ 8 }>
                        <Field name='gpaFieldOne'
                          label='GPA 1'
                          variant='outlined'
                          margin='dense'
                          component={ SelectInput }
                          options={ this.getGpaOptions() }
                          fullWidth />
                      </Grid>

                      { !disableGradeRiskFeature
                      && (
                      <Grid item xs={ 12 } sm={ 4 } align='left'>
                        <Field name='gpaRiskOne'
                          label='GPA Risk 1'
                          type='number'
                          variant='outlined'
                          margin='dense'
                          component={ TextInput }
                          fullWidth />
                      </Grid>
                      )}

                      <Grid item xs={ 12 } sm={ 8 } className={ classes.bottomInputs }>
                        <Field name='gpaFieldTwo'
                          label='GPA 2'
                          variant='outlined'
                          margin='dense'
                          component={ SelectInput }
                          options={ this.getGpaOptions() }
                          fullWidth />
                      </Grid>

                      {!disableGradeRiskFeature
                      && (
                      <Grid item xs={ 12 } sm={ 4 } align='left' className={ classes.bottomInputs }>
                        <Field name='gpaRiskTwo'
                          label='GPA Risk 2'
                          type='number'
                          variant='outlined'
                          margin='dense'
                          component={ TextInput }
                          fullWidth />
                      </Grid>
                      )}

                    </Grid>
                  </Grid>

                  <Grid item xs={ 4 }>
                    <Grid container className={ classes.additionalFiltersContainer } spacing={ 24 }>
                      <Grid item xs={ 12 }>
                        <Field name='moveLegaciesToTop'
                          label='Move legacies to top'
                          component={ CheckboxInput } />
                      </Grid>

                      { !disableGradeRiskFeature
                      && (
                      <Grid item xs={ 12 }>
                        <Field name='moveGradeRisksToBottom'
                          label='Move grade risks to bottom'
                          component={ CheckboxInput } />
                      </Grid>
                      )}

                      <Grid item xs={ 12 } alignContent='center'>
                        <Grid container spacing={ 24 } alignItems='center'>
                          <Grid item xs={ 7 } alignContent='center'>
                            <Field name='moveByTagId'
                              label='Move By Tag'
                              variant='outlined'
                              style={ { marginTop: 20 } }
                              component={ SelectInput }
                              options={ [{ value: '', label: 'No Tags Selected' }, ...this.getTagOptions()] }
                              fullWidth />
                          </Grid>
                          <Grid item xs={ 5 }>
                            <Field name='moveByTagToTop'
                              id='moveByTagToTop'
                              component={ SwitchInput }
                              label='Move to top or bottom?'
                              switchClassname={ classes.verticalSwitch } />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={ 12 }>
                        <Grid container alignItems='flex-end'>
                          <Grid item style={ { width: '35px' } }>
                            <Icon color='primary'>search</Icon>
                          </Grid>
                          <Grid item xs>
                            <Field name='searchPnmFilter'
                              label='Search PNMs'
                              margin='dense'
                              className={ classes.searchText }
                              InputProps={ {
                                endAdornment: (
                                  <IconButton className={ classes.searchClear }
                                    disabled={ !searchPnmFilter }
                                    onClick={ this.handleClearSearchField }>
                                    <Icon color={ searchPnmFilter ? 'primary' : 'disabled' }>close</Icon>
                                  </IconButton>
                                ),
                              } }
                              component={ TextInput }
                              fullWidth />
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item xs align='center'>
                    <Grid container className={ classes.buttonsContainer } spacing={ 16 }>
                      <Grid className='leftGrid' item xs={ 12 } sm={ 7 } align='left'>
                        <Button variant='contained'
                          onClick={ this.handleOpenAdvancedOptions }
                          color='contrast'>
                          <img src={ SettingsIcon } alt='Advanced Display Options' />
                          <Space type='long05' />
                          Advanced Display Options
                        </Button>
                        {showRoundsWarning
                          ? (
                            <Typography variant='subtitle2' color='secondary' className={ classes.roundsWarning }>
                              Not all rounds are being displayed
                            </Typography>
                          ) : null}
                      </Grid>
                      <Grid className='rightGrid' item xs={ 12 } sm={ 5 } align='right'>
                        { this.renderErrorMessage(filtersForm) }

                        <Button variant='contained'
                          color='primary'
                          disabled={ disableButton }
                          onClick={ this.handleSubmit }
                          loading={ filtersForm.get('submitting') }>
                          Save Filter Settings
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Snackbar anchorOrigin={ {
                vertical:   'bottom',
                horizontal: 'center',
              } }
                open={ snackbarOpen }
                autoHideDuration={ 3000 }
                onClose={ this.handleClose }
                ContentProps={ {
                  'aria-describedby': 'save-message-id',
                } }
                message={ <span id='save-message-id'>{ snackbarMessage }</span> } />

            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const styles = theme => ({
  filtersCard: {
    height:       'auto',
    borderRadius: 10,
  },

  bottomInputs: {
    marginTop: -20,
  },

  errorMessage: {
    textAlign:    'center',
    marginBottom: 15,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  mainFiltersContainer: {
    [theme.breakpoints.up('sm')]: {
    },
  },

  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      30,
  },

  titleContainer: {
    display:       'flex',
    flexDirection: 'row',
    marginBottom:  10,
  },

  roundsWarning: {
    display:    'inline-block',
    marginLeft: 10,
  },

  fieldFiltersContainer: {},

  additionalFiltersContainer: {},

  searchText: {
    '& > label': {
      whiteSpace:   'nowrap',
      overflow:     'hidden',
      maxWidth:     '100%',
      paddingRight: 22,
      boxSizing:    'border-box',
      textOverflow: 'ellipsis',
    },
  },

  searchClear: {
    padding:      1,
    marginBottom: 1,
  },

  buttonsContainer: {
    [theme.breakpoints.only('xs')]: {
      marginTop:                   24,
      '& .leftGrid, & .rightGrid': {
        textAlign: 'center',
      },
    },
  },

  multiSelectInput: {
    margin: 0,
  },

  verticalSwitch: {
    transform: 'rotate(-90deg)',
  },

});

export default withStyles(styles)(FiltersForm);
