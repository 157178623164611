import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import red from '@material-ui/core/colors/red';

// local components
import Button from '../../../Button';
import RoundsForm from './components/RoundsForm';
import RoundsExtraSettingsForm from './components/RoundsExtraSettingsForm';

// helpers
import capitalize from '../../../../lib/capitalize';

class Rounds extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    createRound:    PropTypes.func.isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    parentclasses:  PropTypes.instanceOf(Object).isRequired,
    organization:   PropTypes.instanceOf(Map).isRequired,
    removeRound:    PropTypes.func.isRequired,
    roundLoading:   PropTypes.bool.isRequired,
    roundStatus:    PropTypes.string.isRequired,
    rounds:         PropTypes.instanceOf(List).isRequired,
    roundsForm:     PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        snackbarOpen:    false,
        snackbarMessage: '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { roundsForm } = this.props;
    const nextRoundStatus = nextProps.roundStatus;
    const nextRoundsForm = nextProps.roundsForm;

    const oldSize = ((roundsForm.get('values') || Map()).get('rounds') || List()).size;
    const nextSize = ((nextRoundsForm.get('values') || Map()).get('rounds') || List()).size;

    if (nextRoundStatus === 'success' && roundsForm.get('values') && nextSize > oldSize) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarMessage', 'Added Successfully');
          map.set('snackbarOpen', true);
        }),
      });
    } else if (nextRoundStatus === 'error') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarMessage', 'Failed To Add');
          map.set('snackbarOpen', true);
        }),
      });
    }
  }

  handleSnackbarClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  }

  handleAddRound = () => {
    const { currentChapter, createRound, roundsForm } = this.props;

    const roundsFormValues = (roundsForm.get('values') || Map()).get('rounds');

    let nextOrder = 1;

    if (roundsFormValues.size > 0) {
      nextOrder = Math.max(...roundsFormValues.map(r => r.get('order'))) + 1;
    }

    const values = {
      order:     nextOrder,
      roundType: 0,
      name:      'New Round',
      events:    0,
      topScores: 0,
      cob:       currentChapter.getIn(['data', 'cobMode']) || false,
    };

    createRound({
      chapter: currentChapter.getIn(['data', 'id'], 0),
      ...values,
    });
  };

  render() {
    const {
      classes,
      currentChapter,
      organization,
      parentclasses: {
        tabsCard, titleContainer, titleIcon, topButton,
      },
      removeRound,
      roundLoading,
      rounds,
      roundsForm,
    } = this.props;

    const isCdSite = currentChapter.getIn(['data', 'isCdSite']) || false;
    const demoMode = currentChapter.getIn(['data', 'demoMode']) || false;
    const cobMode = currentChapter.getIn(['data', 'cobMode']) || false;
    const scoreLabel = organization.getIn(['labels', 'score'], 'Score');

    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');

    return (
      <div>
        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 24 }>

              <Grid item xs={ 8 } sm={ 8 } className={ titleContainer }>
                <Icon className={ titleIcon } color='primary'>group_work</Icon>
                <Typography variant='h5' color='primary'>Manage Rounds</Typography>
              </Grid>

              <Hidden xsDown>
                <Grid item sm={ 4 } className={ topButton } align='right'>
                  <Button variant='contained'
                    color='primary'
                    disabled={ roundLoading }
                    onClick={ this.handleAddRound }>

                    <Icon className={ classes.leftIcon }>add</Icon>
                    Add Round
                  </Button>
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid item sm={ 4 } className={ topButton } align='right'>
                  <Button variant='fab' color='primary'>
                    <Icon>add</Icon>
                  </Button>
                </Grid>
              </Hidden>

              <Grid item xs={ 12 }>
                <Typography variant='subtitle1' color='textSecondary'>
                  Manage your MyVote round order, types, names, and more
                </Typography>
                <Typography color='primary'>
                  Click and drag the icons to rearrange round order.
                  Pre-
                  {capitalize(scoreLabel)}
                  {' '}
                  rounds cannot be reordered.
                </Typography>
              </Grid>

              <Grid item xs={ 12 }>
                <RoundsForm rounds={ rounds }
                  roundsForm={ roundsForm }
                  removeRound={ removeRound } />
              </Grid>

            </Grid>
          </CardContent>
        </Card>

        { isCdSite && !demoMode && !cobMode
          && (
          <Card className={ tabsCard }>
            <CardContent>
              <Grid container spacing={ 8 }>

                <Grid item xs={ 12 } className={ titleContainer }>
                  <Icon className={ titleIcon } color='primary'>list</Icon>
                  <Typography variant='h5' color='primary'>Additional Round Settings</Typography>
                </Grid>

                <Grid item xs={ 12 }>
                  <Typography variant='subtitle1' color='textSecondary'>
                    Fine-tune your chapter round settings
                  </Typography>
                </Grid>

                <Grid item xs={ 12 }>
                  <RoundsExtraSettingsForm currentChapter={ currentChapter }
                    rounds={ rounds } />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
          )}

        <Snackbar key='add'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ this.handleSnackbarClose }
          message={ snackbarMessage } />
      </div>
    );
  }
}

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  deleteButton: {
    color: red[500],
  },
});

export default withStyles(styles)(Rounds);
