import { Map } from 'immutable';

// constants
import { CURRENT_CHAPTER_FETCH } from '../constants/chapterTypes';
import { ORGANIZATION_FETCH } from '../constants/organizationTypes';
import { CONCERN_CATEGORY_CREATE, CONCERN_CATEGORY_REMOVE, CONCERN_CATEGORY_BULK_UPDATE } from '../constants/concernCategoryTypes';
import { add, remove } from '../lib/normalizedReducerHelpers';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case CONCERN_CATEGORY_REMOVE: return httpReducer(state, action, { entity: 'concernCategory', cb: remove() });
    case CONCERN_CATEGORY_CREATE: return httpReducer(state, action, { entity: 'concernCategory', cb: add() });
    case CONCERN_CATEGORY_BULK_UPDATE:
    case CURRENT_CHAPTER_FETCH:
    case ORGANIZATION_FETCH: return httpReducer(state, action, { entity: 'concernCategory', updateResult: false });
    default: return state;
  }
};
