import { connect } from 'react-redux';

// components
import Filter from './Filter';

// selectors
import getRounds from '../../../../lib/selectors/getRounds';
import getHometowns from '../../../../lib/selectors/getHometowns';
import getTags from '../../../../lib/selectors/getTags';
import getOrganization from '../../../../lib/selectors/getOrganization';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  organization:   getOrganization(state),
  rounds:         getRounds(state),
  hometowns:      getHometowns(state),
  tags:           getTags(state),
});

export default connect(mapStateToProps)(Filter);
