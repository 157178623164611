import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import locationOrgService from '../../sources/api/locationOrgService';

// constants
import { LOCATION_ORG_FETCH_ALL_INIT, LOCATION_ORG_FETCH_ALL } from '../../constants/locationOrgTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { locationOrgListSchema } from '../../schemas/locationOrg';

export function* orgFetchAll(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { search } = action.payload || {};

  const options = {
    query: {
      $search: search,
      sex:     'women',
    },
  };

  try {
    yield* httpSaga(LOCATION_ORG_FETCH_ALL, call(find, locationOrgService, options), {
      schema: locationOrgListSchema,
    });
  } catch (err) {
    yield put(error(LOCATION_ORG_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(LOCATION_ORG_FETCH_ALL));
  }
}

export function* watchOrgFetchAll() {
  yield takeLatest(LOCATION_ORG_FETCH_ALL_INIT, orgFetchAll);
}

export default function* root() {
  yield all([
    fork(watchOrgFetchAll),
  ]);
}
