import { connect } from 'react-redux';

// action creators
import { fetchAllSpecialties } from '../../../../actions/specialtyActions';
import { autoMatch } from '../../../../actions/matchActions';
import { fetchUsersForChapter } from '../../../../actions/userActions';
import { chapterUpdate } from '../../../../actions/chapterActions';

// helpers
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getChapterAttribute from '../../../../lib/selectors/getChapterAttribute';
import getRounds from '../../../../lib/selectors/getRounds';
import getUserSuggestions from '../../../../lib/selectors/getUserSuggestions';
import getSpecialties from '../../../../lib/selectors/getSpecialties';
import getHometowns from '../../../../lib/selectors/getHometowns';
import getStatusFromState from '../../../../lib/selectors/getStatusFromState';

// components
import AutoMatchTab from './AutoMatchTab';

const mapStateToProps = state => ({
  currentChapterId:      getChapterAttribute('id')(state),
  cobMode:               getChapterAttribute('cobMode')(state),
  matchSettings:         getChapterAttribute('matchSettings')(state),
  currentChapterLoading: getLoadingFromState('currentChapter')(state),
  currentChapterStatus:  getStatusFromState('currentChapter')(state),
  currentRound:          getChapterAttribute('currentRound')(state),
  hometowns:             getHometowns(state),
  isCdSite:              getChapterAttribute('isCdSite')(state),
  matchLoading:          getLoadingFromState('match')(state),
  matchStatus:           getStatusFromState('match')(state) || 'success',
  rounds:                getRounds(state),
  specialties:           getSpecialties(state).sortBy(s => s.get('category')),
  suggestions:           getUserSuggestions(state),
  userLoading:           getLoadingFromState('user')(state),
});

const mapDispatchToProps = dispatch => ({
  fetchAllSpecialties: (params) => {
    dispatch(fetchAllSpecialties(params));
  },
  autoMatch: (params) => {
    dispatch(autoMatch(params));
  },
  fetchUsers: (params) => {
    dispatch(fetchUsersForChapter(params));
  },
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoMatchTab);
