import { connect } from 'react-redux';

// action creators
// import {  } from '../../actions/fieldActions';

import getFieldSuggestions from '../../../../../lib/selectors/getFieldSuggestions';

// components
import PdfSettings from './PdfSettings';

const mapStateToProps = state => ({
  fields: getFieldSuggestions(state),
});

// const mapDispatchToProps = dispatch => ({
// });

export default connect(mapStateToProps)(PdfSettings);
