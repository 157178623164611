import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form/immutable';
import validate from 'validate.js';
import { List, Map } from 'immutable';

// action creators
import { tagBulkUpdate } from '../../../../../../actions/tagActions';

import getChapterAttribute from '../../../../../../lib/selectors/getChapterAttribute';
import getStatusFromState from '../../../../../../lib/selectors/getStatusFromState';

// components
import TagsForm from './TagsForm';

const createInitialValues = (props) => {
  const { tags, tagsForm } = props;
  const formValues = (tagsForm.get('values') || Map()).get('tags') || List();
  const formValueIds = (formValues.map(item => item.get('_id')) || List()).toJS();

  let values = tags || List();

  values = tags.map((v) => {
    if (formValueIds.includes(v.get('_id'))) {
      return formValues.find(item => item.get('_id') === v.get('_id'));
    }
    return v;
  });

  return values;
};

// Manually checks if the form is dirty, because adding and deleting items reinitializes the form
const getManualDirty = (props) => {
  const { tags, tagsForm } = props;
  const formValues = (tagsForm.get('values') || Map()).get('tags') || List();
  let isFieldDirty = false;

  if (formValues.size) {
    tags.forEach((v) => {
      const formValue = formValues.find(item => item.get('_id') === v.get('_id')) || Map();

      if (formValue.get('title') !== v.get('title')
          || formValue.get('color') !== v.get('color')
          || formValue.get('usableBy') !== v.get('usableBy')
          || formValue.get('visibleTo') !== v.get('visibleTo')) {
        isFieldDirty = true;
      }
    });
  }
  return isFieldDirty;
};

const mapStateToProps = (state, props) => ({
  tagsForm:         state.getIn(['form', 'tagsForm'], Map()),
  manualDirty:      getManualDirty(props),
  currentChapterId: getChapterAttribute('id')(state),
  initialValues:    { tags: createInitialValues(props) },
  tagStatus:        getStatusFromState('tag')(state),
});

const mapDispatchToProps = dispatch => ({
  bulkUpdateTags: (items, chapter) => {
    dispatch(tagBulkUpdate(items, chapter));
  },
  initializeForm: (items) => {
    dispatch(initialize('tagsForm', items));
  },
});

const handleChange = function (tags, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const validateForm = function (form) {
  return validate(form.toJS());
};

const wrappedTagsForm = reduxForm({
  form:               'tagsForm',
  validate:           validateForm,
  enableReinitialize: false,
  onChange:           handleChange,
})(TagsForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedTagsForm);
