import { reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { forgotPasswordUpdate } from '../../../actions/forgotPasswordActions';

// components
import ForgotPasswordForm from './ForgotPasswordForm';

// schemas
const schema = {
  password: {
    presence: true,

    length: {
      maximum: 255,
    },
  },

  confirmPassword: {
    presence: true,

    equality: 'password',

    length: {
      maximum: 255,
    },
  },
};

const mapStateToProps = state => ({
  forgotPasswordForm: (state.get('form') || Map()).get('forgotPassword') || Map(),
});

const mapDispatchToProps = dispatch => ({
  updateForgotPassword: (params) => {
    dispatch(forgotPasswordUpdate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default reduxForm({
  form:     'forgotPassword',
  validate: validateForm,
  onChange: handleChange,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)));
