import {
  INVITE_CREATE_INIT,
  INVITE_BULK_CREATE_INIT,
  INVITE_FETCH_INIT,
  INVITE_FETCH_ALL_FOR_CHAPTER_INIT,
  INVITE_DELETE_INIT,
  INVITE_UPDATE_INIT,
} from '../constants/inviteTypes';

export function inviteCreate(params) {
  return {
    type:    INVITE_CREATE_INIT,
    payload: params,
  };
}

export function inviteBulkCreate(params) {
  return {
    type:    INVITE_BULK_CREATE_INIT,
    payload: params,
  };
}

export function inviteUpdate(params) {
  return {
    type:    INVITE_UPDATE_INIT,
    payload: params,
  };
}

export function inviteFetch(id) {
  return {
    type:    INVITE_FETCH_INIT,
    payload: { id },
  };
}

export function inviteFetchAllForChapter(id) {
  return {
    type:    INVITE_FETCH_ALL_FOR_CHAPTER_INIT,
    payload: { chapter: id },
  };
}

export function inviteDelete(id, chapter) {
  return {
    type:    INVITE_DELETE_INIT,
    payload: { id, chapter },
  };
}
