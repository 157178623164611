import { Map } from 'immutable';
import { connect } from 'react-redux';

// action creators
import { membershipConcernCreate, membershipConcernUpdate } from '../../../../actions/membershipConcernActions';

// components
import MembershipConcernModal from './MembershipConcernModal';

const mapStateToProps = state => ({
  membershipConcernForm: state.getIn(['form', 'membershipConcern'], Map()),
  organization:          state.get('organization'),
});

const mapDispatchToProps = dispatch => ({
  membershipConcernCreate(params) {
    dispatch(membershipConcernCreate(params));
  },
  membershipConcernUpdate(params) {
    dispatch(membershipConcernUpdate(params));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipConcernModal);
