import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import CategoryLabel from '../../../CategoryLabel';

class EnhancedTableHead extends React.Component {
  static propTypes = {
    classes:             PropTypes.instanceOf(Object).isRequired,
    currentRound:        PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    displayOverallScore: PropTypes.bool.isRequired,
    overallScoreLabel:   PropTypes.string.isRequired,
    onRequestSort:       PropTypes.func.isRequired,
    pnms:                PropTypes.instanceOf(List).isRequired,
    order:               PropTypes.instanceOf(Map).isRequired,
    orderBy:             PropTypes.instanceOf(Map).isRequired,
    rounds:              PropTypes.instanceOf(List).isRequired,
    categories:          PropTypes.instanceOf(List).isRequired,
    extraColumnType:     PropTypes.string.isRequired,
    displayOptions:      PropTypes.shape({
      includeGPA1:               PropTypes.bool,
      includeGPA2:               PropTypes.bool,
      includePnmTags:            PropTypes.bool,
      includePnmImages:          PropTypes.bool,
      includePnmStatus:          PropTypes.bool,
      displayCategoryScores:     PropTypes.bool,
      displayCategoryScoresList: PropTypes.arrayOf(PropTypes.string),
      displayRoundScores:        PropTypes.bool,
      displayRoundScoresList:    PropTypes.arrayOf(PropTypes.string),
      selectedField:             PropTypes.instanceOf(Array).isRequired,
    }),
  };

  static defaultProps = {
    displayOptions: {},
  };

  getCellPadding = (column, index) => {
    const { displayOverallScore, rounds, extraColumnType } = this.props;

    if (!rounds.size && !displayOverallScore) {
      return 'dense';
    }
    if (!rounds.size && column.id === 'overall' && !extraColumnType) {
      return 'dense';
    }
    if (!rounds.size && column.id === 'rawScore') {
      return 'dense';
    }
    if (index === this.getColumnData().length - 1) {
      return 'dense';
    }
    return column.padding;
  }

  getCategories = () => {
    const { categories } = this.props;

    return List.isList(categories) ? categories : List();
  };

  getCellClassName = (column) => {
    const { classes } = this.props;

    if (column.isCurrentRound) {
      return classNames(classes.head, classes.currentRoundColumn);
    } if (column.id === 'firstname') {
      return classNames(classes.head, classes.firstNameCell);
    } if (column.id === 'status') {
      return classNames(classes.head, classes.statusCell);
    }

    return classes.head;
  }

  getColumnData = () => {
    const {
      currentRound,
      displayOverallScore,
      rounds,
      extraColumnType,
      overallScoreLabel,
      displayOptions,
      classes,
    } = this.props;

    const {
      includeGPA1,
      includeGPA2,
      includePnmTags,
      includePnmImages,
      includePnmStatus,
      displayCategoryScores,
      displayRoundScores,
      displayCategoryScoresList = [],
      displayRoundScoresList = [],
      selectedField = [],
    } = displayOptions || {};

    const columnData = [
      {
        id:      'rank',
        align:   'right',
        label:   'Rank',
        padding: 'none',
      },
      ...(includePnmImages ? [{
        id:      'image',
        align:   'right',
        label:   'Image',
        padding: 'none',
      }] : []),
      ...(includePnmStatus ? [{
        id:      'status',
        align:   'center',
        label:   'Status',
        padding: 'none',
      }] : []),
      {
        id:      'firstname',
        align:   'left',
        label:   'First Name',
        padding: 'none',
      },
      {
        id:      'lastname',
        align:   'left',
        label:   'Last Name',
        padding: 'none',
      },
      {
        id:      'id',
        align:   'left',
        label:   'ID',
        padding: 'none',
      },
      ...(includePnmTags ? [
        {
          id:      'tags',
          align:   'left',
          label:   'Tags',
          padding: 'none',
        }] : []),
      ...(includeGPA1 ? [{
        id:      'gpa1',
        align:   'right',
        label:   'GPA 1',
        padding: 'none',
      }] : []),
      ...(includeGPA2 ? [
        {
          id:      'gpa2',
          align:   'right',
          label:   'GPA 2',
          padding: 'none',
        }] : []),
      {
        id:      'event',
        align:   'right',
        label:   'Event',
        padding: 'none',
      },
    ];

    if (selectedField.length) {
      selectedField.map(f => (
        columnData.push(
          {
            id:      `customFieldId${f.value || ''}`,
            align:   'left',
            label:   f.label,
            padding: 'dense',
          }
        )));
    }

    if (displayOverallScore) {
      columnData.push({
        id:    'overall',
        align: 'right',
        label: (
          <Tooltip title='The overall score will continue to include all rounds whether they are displayed or not.' placement='top'>
            <div className={ classes.overallInfo }>
              { overallScoreLabel }
              {' '}
              <Icon fontSize='small'>info_outline</Icon>
            </div>
          </Tooltip>
        ),
        padding: 'dense',
      });
    }

    if (extraColumnType) {
      switch (extraColumnType) {
        case 'raw':
          columnData.push({
            id:      'rawScore',
            align:   'right',
            label:   'Raw Score',
            padding: 'none',
          });
          break;
        default:
          break;
      }
    }

    rounds.forEach((r) => {
      const id = r.get('_id', '').toString();
      if (displayRoundScores && displayRoundScoresList.indexOf(id) !== -1) {
        columnData.push({
          id,
          align:          'right',
          isCurrentRound: currentRound === r.get('_id'),
          label:          r.get('name'),
          padding:        'none',
        });

        if (r.get('roundType') === 5 && extraColumnType === 'prePref') {
          columnData.push({
            id:      'prePrefScore',
            align:   'right',
            label:   'Pre-Pref',
            padding: 'none',
          });
        }
      }
    });

    if (displayCategoryScores) {
      this.getCategories().forEach((category) => {
        const id = category.get('_id', '').toString();
        const name = category.get('name', '');
        const roundType = category.get('roundType', '');
        const roundTypeName = category.get('roundTypeName', '');
        if (displayCategoryScoresList.indexOf(id) !== -1) {
          columnData.push({
            id:    `categoryId${id}`,
            align: 'right',
            label: (
              <CategoryLabel name={ name }
                roundType={ roundType }
                roundTypeName={ roundTypeName }
                nowrapInline />
            ),
            padding: 'none',
          });
        }
      });
    }

    return columnData;
  }

  createSortHandler = property => (event) => {
    const { pnms } = this.props;
    const unsortableCols = ['tags', 'rank'];

    if (!unsortableCols.includes(property)) {
      this.props.onRequestSort(event, property, pnms);
    }
  };

  render() {
    const {
      classes, order, orderBy,
    } = this.props;

    return (
      <TableHead>
        <TableRow className={ classes.tableRow }>
          <TableCell className={ classes.head }
            variant='head'
            padding='dense' />
          {this.getColumnData().map((column, index) => (
            <TableCell key={ column.id }
              variant='head'
              align={ column.align }
              className={ this.getCellClassName(column) }
              padding={ this.getCellPadding(column, index) }
              sortDirection={ orderBy.has(column.id) ? order.get(column.id) : false }>

              <Tooltip title={ column.isCurrentRound ? 'Current round' : 'Sort' }
                placement={ column.numeric ? 'bottom-end' : 'bottom-start' }
                enterDelay={ 300 }>

                <TableSortLabel active={ orderBy.has(column.id) }
                  direction={ order.get(column.id) }
                  onClick={ this.createSortHandler(column.id) }>
                  {column.label}
                </TableSortLabel>
              </Tooltip>

            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

const styles = theme => ({
  currentRoundColumn: {
    color: theme.palette.primary.main,
  },
  tableRow: {
    '& th:last-child': {
      paddingBottom: 0,
    },
  },
  head: {
    backgroundColor: '#fff',
    position:        'sticky',
    top:             -30,
    zIndex:          10,
    paddingTop:      10,
    borderCollapse:  'separate',
  },
  firstNameCell: {
    padding: '10px 0 0 24px',
  },
  statusCell: {
    padding: '10px 0 0 16px',
  },
  overallInfo: {
    display:    'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles)(EnhancedTableHead);
