import { connect } from 'react-redux';

// components
import Layout from './Layout';

const mapStateToProps = state => ({
  router:         state.get('router'),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
});

export default connect(mapStateToProps)(Layout);
