import { Map } from 'immutable';

// constants
import { MEMBERSHIP_CONCERN_CREATE, MEMBERSHIP_CONCERN_UPDATE } from '../constants/membershipConcernTypes';
import { PNM_FETCH } from '../constants/pnmTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { addToFront } from '../lib/normalizedReducerHelpers';

export default (state = Map(), action) => {
  switch (action.type) {
    case MEMBERSHIP_CONCERN_CREATE: return httpReducer(state, action, { cb: addToFront() });
    case MEMBERSHIP_CONCERN_UPDATE: return httpReducer(state, action, { entity: 'membershipConcern', updateResult: false });
    case PNM_FETCH: return httpReducer(state, action, { entity: 'membershipConcern', updateResult: false });
    default: return state;
  }
};
