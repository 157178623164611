const modalBoxStyles = theme => ({
  paperWidthSm: {
    width:                          '800px',
    maxWidth:                       '90%',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
      margin:   10,
    },
  },
  paperWidthXs: {
    maxWidth: '100%',
  },
  paperWidthMd: {
    width:    '800px',
    maxWidth: '90%',
  },
  paperWidthLg: {
    width:    '800px',
    maxWidth: '90%',
  },
  paperWidthXl: {
    width:    '800px',
    maxWidth: '90%',
  },
  title: {
    fontSize:   '20px',
    lineHeight: '30px',
    fontWeight: 700,
    color:      '#4285F4',
    margin:     0,
  },
  subtitle: {
    fontSize:   '14px',
    fontWeight: 700,
    color:      '#5A5A5AA5',
    marginTop:  5,
  },
  icon: {
    display:         'flex',
    width:           74,
    height:          74,
    backgroundColor: '#4285F4',
    color:           '#ffffff',
    borderRadius:    74,
    justifyContent:  'center',
    alignItems:      'center',
  },
  acceptButton: {},
  rejectButton: {},
});

export default modalBoxStyles;
