import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import green from '@material-ui/core/colors/green';

// local components
import SwitchInput from '../../../../../SwitchInput';
import Savebar from '../../../../../Savebar';

// helpers
import capitalize from '../../../../../../lib/capitalize';

class ChapterSettingsForm extends Component {
  static propTypes = {
    chapterSettingsForm: PropTypes.instanceOf(Map).isRequired,
    classes:             PropTypes.instanceOf(Object).isRequired,
    currentChapter:      PropTypes.instanceOf(Map).isRequired,
    dirty:               PropTypes.bool.isRequired, // eslint-disable-line
    isSystemAdmin:       PropTypes.bool.isRequired, // eslint-disable-line
    fetchPnms:           PropTypes.func.isRequired, // eslint-disable-line
    organization:        PropTypes.instanceOf(Map).isRequired,
    reset:               PropTypes.func.isRequired,
    updateChapter:       PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        savebarOpen:      false,
        savebarMessage:   'Save your changes?',
        hideButtons:      false,
        autoHideDuration: null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { currentChapter, fetchPnms } = nextProps;
    const oldCobMode = this.props.currentChapter.getIn(['data', 'cobMode'], false);
    const oldDemoMode = this.props.currentChapter.getIn(['data', 'demoMode'], false);
    const newCobMode = currentChapter.getIn(['data', 'cobMode'], false);
    const newDemoMode = currentChapter.getIn(['data', 'demoMode'], false);
    const chapterId = nextProps.currentChapter.getIn(['data', 'id'], 0);

    if (currentChapter.get('data') && (oldCobMode !== newCobMode || oldDemoMode !== newDemoMode)) {
      const fetchParams = {
        sort:    { lastname: 1 },
        filter:  null,
        order:   1,
        chapter: chapterId,
      };

      fetchPnms(fetchParams);
    }

    const { dirty, chapterSettingsForm, reset } = nextProps;
    const savebarOpen = this.state.data.get('savebarOpen');

    if (!savebarOpen) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', dirty);
          map.set('savebarMessage', 'Save your changes?');
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    } else if (chapterSettingsForm.get('submitSucceeded')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Saved Successfully');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });

      reset();
    } else if (chapterSettingsForm.get('submitFailed')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save Failed');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });

      reset();
    }
  }

  getSettingDisabled = (attribute) => {
    const { organization } = this.props;
    let disabled = false;

    if (organization.get(attribute) !== undefined) {
      disabled = true;
    }

    return disabled;
  }

  handleCloseSavebar = (event, reason) => {
    if (reason === 'timeout') {
      this.setState({ data: this.state.data.set('savebarOpen', false) });
    }
  }

  handleSave = () => {
    const {
      chapterSettingsForm, currentChapter, updateChapter,
    } = this.props;

    const chapterId = currentChapter.getIn(['data', 'id'], 0);

    if (!chapterSettingsForm.get('syncErrors')) {
      const updateParams = {
        chapterId,
        formName: 'chapterSettingsForm',
        ...chapterSettingsForm.get('values').toJS(),
      };
      updateChapter(updateParams);
    }
  }

  handleCancel = () => {
    const { reset } = this.props;

    reset();
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('savebarMessage', 'Changes reset.');
        map.set('autoHideDuration', 2000);
        map.set('hideButtons', true);
      }),
    });
  }

  renderSubmitMessage = (form) => {
    const { classes, currentChapter } = this.props;
    let element;

    if (!form.get('syncErrors') && form.get('submitSucceeded') && (currentChapter.get('status') || '') === 'success') {
      element = (
        <FormHelperText className={ classNames(classes.helperText, classes.success) }>
          Settings saved successfully.
        </FormHelperText>
      );
    } else if (form.get('submitFailed')) {
      element = (
        <FormHelperText className={ classes.helperText } error>
          Saving settings failed.
        </FormHelperText>
      );
    }

    return element;
  }

  renderOrganizationMessage = (disabledByOrg) => {
    let element;
    const { classes } = this.props;

    if (disabledByOrg) {
      element = (
        <Typography variant='body2' color='secondary' className={ classes.settingsItemOrganizationLabel }>
          Set by your organization
        </Typography>
      );
    }
    return element;
  }

  render() {
    const {
      chapterSettingsForm, classes, currentChapter, isSystemAdmin, organization,
    } = this.props;

    const savebarOpen = this.state.data.get('savebarOpen');
    const savebarMessage = this.state.data.get('savebarMessage');
    const hideButtons = this.state.data.get('hideButtons');
    const autoHideDuration = this.state.data.get('autoHideDuration');

    const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);
    const scoreLabel = organization.getIn(['labels', 'score'], 'Score');

    const demoModeEnabled = chapterSettingsForm.getIn(['values', 'demoMode'], false);
    const cobModeEnabled = chapterSettingsForm.getIn(['values', 'cobMode'], false);
    const displayPnmsOnVotingClosed = chapterSettingsForm.getIn(['values', 'displayPnmsOnVotingClosed'], false);

    return (
      <Grid container spacing={ 40 }>
        <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
          <Grid container spacing={ 16 } className={ classes.settingsItemHeaderContainer }>
            <Grid item xs={ 1 }>
              <Icon className={ classes.settingsIcon }>extension</Icon>
            </Grid>

            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Matching
              </Typography>
            </Grid>
          </Grid>

          <div className={ classes.settingsItemContainer }>
            <Field name='showBestMatchedWith'
              id='showBestMatchedWith'
              label='Best Matched With Feature'
              disabled={ this.getSettingDisabled('showBestMatchedWith') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('showBestMatchedWith')) }
          </div>

          { isSystemAdmin
            && (
            <div className={ classes.settingsItemContainer }>
              <Field name='showAdvancedMatching'
                id='showAdvancedMatching'
                label='Advanced Matching Feature'
                component={ SwitchInput } />
            </div>
            )}

          <div className={ classes.settingsItemContainer }>
            <Field name='hideMatchesFromVotingMembers'
              id='hideMatchesFromVotingMembers'
              label='Hide Matches From Voting Members'
              subText='Hides matches from voting members that are not the author or matched user'
              disabled={ this.getSettingDisabled('hideMatchesFromVotingMembers') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('hideMatchesFromVotingMembers')) }
          </div>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
          <Grid container spacing={ 16 } className={ classes.settingsItemHeaderContainer }>
            <Grid item xs={ 1 }>
              <Icon className={ classes.settingsIcon }>phonelink_setup</Icon>
            </Grid>

            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Modes
              </Typography>
            </Grid>
          </Grid>

          <div className={ classes.settingsItemContainer }>
            <Field name='demoMode'
              id='demoMode'
              label='Training Mode'
              subText='Allows your chapter to simulate recruitment on test PNMs. Come back here to turn it off before recruitment begins!'
              disabled={ this.getSettingDisabled('demoMode') || cobModeEnabled }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('demoMode')) }
          </div>

          <div className={ classes.settingsItemContainer }>
            <Field name='cobMode'
              id='cobMode'
              label='Continuous Open Bidding (COB) Mode'
              subText='Provides your chapter with a clean slate to run secondary recruitment. Turn off COB to view all data from primary recruitment.'
              disabled={ this.getSettingDisabled('cobMode') || demoModeEnabled }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('cobMode')) }
          </div>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
          <Grid container spacing={ 16 } className={ classes.settingsItemHeaderContainer }>
            <Grid item xs={ 1 }>
              <Icon className={ classes.settingsIcon }>comment</Icon>
            </Grid>

            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Notes
              </Typography>
            </Grid>
          </Grid>

          <div className={ classes.settingsItemContainer }>
            <Field name='disableNotesFeature'
              id='disableNotesFeature'
              label='Disable Notes Feature'
              subText='For all users, admin included'
              disabled={ this.getSettingDisabled('disableNotesFeature') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('disableNotesFeature')) }
          </div>

          <div className={ classes.settingsItemContainer }>
            <Field name='hideNotesFromVotingMembers'
              id='hideNotesFromVotingMembers'
              label='Hide Notes From Voting Members'
              subText='Hides the content of a note comment for notes that a voting member did not author'
              disabled={ this.getSettingDisabled('hideNotesFromVotingMembers') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('hideNotesFromVotingMembers')) }
          </div>

          <div className={ classes.settingsItemContainer }>
            <Field name='displayPnmInfoOnNote'
              id='displayPnmInfoOnNote'
              label='Display PNM Name On Note / Tag Dialog'
              disabled={ this.getSettingDisabled('displayPnmInfoOnNote') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('displayPnmInfoOnNote')) }
          </div>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
          <Grid container spacing={ 16 } className={ classes.settingsItemHeaderContainer }>
            <Grid item xs={ 1 }>
              <Icon className={ classes.settingsIcon }>group</Icon>
            </Grid>

            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='textSecondary'>
                PNMs
              </Typography>
            </Grid>
          </Grid>

          { isCdSite
            && (
            <div className={ classes.settingsItemContainer }>
              <Field name='usePreferredName'
                id='usePreferredName'
                label='Display Preferred Name For PNMs'
                subText='Replaces PNMs first name with their preferred name'
                disabled={ this.getSettingDisabled('usePreferredName') }
                component={ SwitchInput } />
              { this.renderOrganizationMessage(this.getSettingDisabled('usePreferredName')) }
            </div>
            )}

          <div className={ classes.settingsItemContainer }>
            <Field name='displayPnmsOnVotingClosed'
              id='displayPnmsOnVotingClosed'
              label='Display PNM List When Voting Closed'
              subText='Allows voting members to view the PNM list even when voting is closed.
                       Enable this setting to configure other voting closed settings below'
              disabled={ this.getSettingDisabled('displayPnmsOnVotingClosed') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('displayPnmsOnVotingClosed')) }
          </div>
          <div className={ classes.settingsItemContainer }>
            <Field name='showVotedOnVotingClosed'
              id='showVotedOnVotingClosed'
              label='Display Voted On PNMs When Voting Closed'
              subText='Allows voting members to view a PNM list containing only those that they voted on when voting is closed'
              disabled={ this.getSettingDisabled('showVotedOnVotingClosed') || !displayPnmsOnVotingClosed }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('showVotedOnVotingClosed')) }
          </div>
          <div className={ classes.settingsItemContainer }>
            <Field name='displayPnmProfileOnVotingClosed'
              id='displayPnmProfileOnVotingClosed'
              label='Display PNM Profile When Voting Closed'
              disabled={ this.getSettingDisabled('displayPnmProfileOnVotingClosed') || !displayPnmsOnVotingClosed }

              subText='Allows voting members to view PNM profiles even when voting is closed'
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('displayPnmProfileOnVotingClosed')) }
          </div>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
          <Grid container spacing={ 16 } className={ classes.settingsItemHeaderContainer }>
            <Grid item xs={ 1 }>
              <Icon className={ classes.settingsIcon }>group_work</Icon>
            </Grid>

            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Rounds & Events
              </Typography>
            </Grid>
          </Grid>

          <div className={ classes.settingsItemContainer }>
            <Field name='restrictPnmsToCurrentEvent'
              id='restrictPnmsToCurrentEvent'
              label='Restrict PNMs To Current Event'
              subText='Filters down PNM list for voting members'
              disabled={ this.getSettingDisabled('restrictPnmsToCurrentEvent') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('restrictPnmsToCurrentEvent')) }
          </div>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
          <Grid container spacing={ 16 } className={ classes.settingsItemHeaderContainer }>
            <Grid item xs={ 1 }>
              <Icon className={ classes.settingsIcon }>local_offer</Icon>
            </Grid>

            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Tags
              </Typography>
            </Grid>
          </Grid>

          <div className={ classes.settingsItemContainer }>
            <Field name='allowVotingMembersToTag'
              id='allowVotingMembersToTag'
              label='Allow Voting Members To Tag PNMs'
              disabled={ this.getSettingDisabled('allowVotingMembersToTag') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('allowVotingMembersToTag')) }
          </div>

          <div className={ classes.settingsItemContainer }>
            <Field name='hideNoteTagsFromVotingMembers'
              id='hideNoteTagsFromVotingMembers'
              label='Hide Note Tags From Voting Members'
              subText='Hides the tags of a note for notes that a voting member did not author'
              disabled={ this.getSettingDisabled('hideNoteTagsFromVotingMembers') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('hideNoteTagsFromVotingMembers')) }
          </div>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
          <Grid container spacing={ 16 } className={ classes.settingsItemHeaderContainer }>
            <Grid item xs={ 1 }>
              <Icon className={ classes.settingsIcon }>thumbs_up_down</Icon>
            </Grid>

            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Voting
              </Typography>
            </Grid>
          </Grid>

          <div className={ classes.settingsItemContainer }>
            <Field name='displayPnmInfoOnVote'
              id='displayPnmInfoOnVote'
              label='Display PNM Name On Vote Dialog'
              disabled={ this.getSettingDisabled('displayPnmInfoOnVote') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('displayPnmInfoOnVote')) }
          </div>

          <div className={ classes.settingsItemContainer }>
            <Field name='displaySingleBallot'
              id='displaySingleBallot'
              label='Display voting, noting, tagging, and matching on a single, combined ballot'
              disabled={ this.getSettingDisabled('displaySingleBallot') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('displaySingleBallot')) }
          </div>

          <div className={ classes.settingsItemContainer }>
            <Field name='displayPreScore'
              id='displayPreScore'
              label={ `Display Pre-${capitalize(scoreLabel)} Votes` }
              subText={ `Allows voting members to see pre-${scoreLabel} votes on a PNM profile` }
              disabled={ this.getSettingDisabled('displayPreScore') }
              component={ SwitchInput } />
            { this.renderOrganizationMessage(this.getSettingDisabled('displayPreScore')) }
          </div>
        </Grid>

        <Savebar open={ savebarOpen }
          autoHideDuration={ autoHideDuration }
          form={ chapterSettingsForm }
          handleAccept={ this.handleSave }
          handleReject={ this.handleCancel }
          handleClose={ this.handleCloseSavebar }
          hideButtons={ hideButtons }
          message={ savebarMessage } />

        <Grid item xs={ 12 } sm={ 6 }>
          { this.renderSubmitMessage(chapterSettingsForm) }
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  helperText: {
    textAlign: 'left',
  },

  settingsGroupContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       10,
    padding:      '0 !important',
  },

  settingsItemHeaderContainer: {
    padding: '15px 10px 0px 15px',
  },

  settingsItemContainer: {
    borderTop: '1px solid #bfbfbf',
    padding:   '5px 10px 10px 10px',
  },

  settingsItemOrganizationLabel: {
    margin: 5,
  },

  settingsIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  success: {
    color: green.A700,
  },
});

export default withStyles(styles)(ChapterSettingsForm);
