import {
  all, call, fork, put, takeEvery, select,
} from 'redux-saga/effects';
import Immutable, { Map } from 'immutable';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { updateCategoryVoteCounts } from '../../actions/voteActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { patch } from '../../sources/api/client';
import voteService from '../../sources/api/voteService';

// constants
import { VOTE_UPDATE, VOTE_UPDATE_INIT } from '../../constants/voteTypes';

// lib
import httpSaga from '../../lib/httpSaga';
import { getPnm, getCurrentUser } from '../../lib/selectors';

// schemas
import { voteSchema } from '../../schemas/vote';

export function* voteUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    voteId, chapter, formName, ...restOfPayload
  } = action.payload;

  const FORM = formName;

  const options = {};

  options.query = { chapter };

  yield put(startSubmit(FORM));

  // Get pnm and currentUser from state before we make the request to update
  const pnm = yield select(getPnm);
  const currentUser = yield select(getCurrentUser);

  try {
    yield* httpSaga(VOTE_UPDATE, call(patch, voteService, voteId, restOfPayload, options), {
      schema: voteSchema,
    });

    if ((restOfPayload.categories || []).length > 0) {
      // Get the current category votes from the current vote on this PNM
      const currentPnmId = ((restOfPayload || {}).pnm || '').toString();
      const currentCategoryVotes = pnm.getIn(['data', 'items', currentPnmId, 'votes', 0, 'categories']);

      // Get the new category votes from the incoming payload
      const newCategoryVotes = Immutable.fromJS((restOfPayload || {}).categories || []);

      // Get the current category vote counts from the current user
      const currentCategoryVoteCounts = currentUser.getIn(['data', 'categoryVotes'], Map());

      let newCategoryVoteCounts = Map();

      currentCategoryVoteCounts.forEach((category, key) => {
        // Get the current category vote value for this category
        let currentVoteValue;
        currentCategoryVotes.forEach((o) => {
          if (o.get('category') === key) {
            currentVoteValue = o.get('value');
          }
        });

        // Get the new category vote value for this category
        let newVoteValue;
        newCategoryVotes.forEach((o) => {
          if (o.get('category') === key) {
            newVoteValue = o.get('value');
          }
        });

        let newOptions = Map();
        category.get('options').forEach((option, optionId) => {
          let newOption = option;

          if (option.get('value') === currentVoteValue) {
            newOption = newOption.set('count', newOption.get('count') - 1);
          }

          if (option.get('value') === newVoteValue) {
            newOption = newOption.set('count', newOption.get('count') + 1);
          }

          if (newOption.get('count') >= newOption.get('limit')) {
            newOption = newOption.set('limitReached', true);
          } else {
            newOption = newOption.set('limitReached', false);
          }

          newOptions = newOptions.set(optionId, newOption);
        });

        newCategoryVoteCounts = newCategoryVoteCounts.set(key, category.set('options', newOptions));
      });

      yield put(updateCategoryVoteCounts({ newCategoryVotes: newCategoryVoteCounts.toJS() }));
    }

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(VOTE_UPDATE, err));
  } finally {
    yield put(doneIndicator(VOTE_UPDATE));
  }
}

export function* watchVoteUpdate() {
  yield takeEvery(VOTE_UPDATE_INIT, voteUpdate);
}

export default function* root() {
  yield all([
    fork(watchVoteUpdate),
  ]);
}
