import { combineReducers } from 'redux-immutable';
import * as storage from 'redux-storage';
import immutableMerger from 'redux-storage-merger-immutablejs';

const context = require.context('./', true, /.*/);

const reducers = {};

let rootReducer;

context.keys().forEach((key) => {
  if (!(/\.js/).test(key) && !(/index/).test(key) && !(/__test__/).test(key) && key !== '.' && key !== './') {
    const reducer = context(key).default;

    if ((/^\.\/root/).test(key)) {
      rootReducer = reducer;
    } else {
      const name = key.substring(2, key.length);

      reducers[name] = reducer;
    }
  }
});

if (!rootReducer) {
  rootReducer = r => r;
}

const combinedReducers = combineReducers({ ...reducers });

export default storage.reducer(rootReducer(combinedReducers), immutableMerger);
