import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

// MUI components
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const getMode = (type, chapter) => {
  let icon;
  let title;

  if (chapter.cobMode) {
    icon = 'mobile_screen_share';
    title = 'COB Mode';
  } else if (chapter.demoMode) {
    icon = 'device_unknown';
    title = 'Training Mode';
  } else {
    icon = 'smartphone';
    title = 'Standard Mode';
  }

  return type === 'icon' ? icon : title;
};

const formatDate = date => moment(new Date(date)).format('MMMM Do YYYY');

class EnhancedTableRow extends Component {
  static propTypes = {
    chapter:              PropTypes.instanceOf(Object).isRequired,
    classes:              PropTypes.instanceOf(Object).isRequired,
    handleRowOptionsOpen: PropTypes.func.isRequired,
    onClick:              PropTypes.func.isRequired,
    numCols:              PropTypes.number.isRequired,
    clearanceLevel:       PropTypes.number.isRequired,
  }

  handleClick = () => {
    const { chapter, onClick } = this.props;

    onClick(chapter.id);
  }

  render() {
    const {
      classes, chapter, handleRowOptionsOpen, numCols, clearanceLevel,
    } = this.props;

    const cdSite = chapter.isCdSite ? 'Yes' : 'No';
    const {
      totalUsers, totalVotingMembers, totalRecruitmentTeam, totalTechTeam,
    } = chapter.userCount || {};

    const userBreakdown = `Voting: ${totalVotingMembers}
      | Rec Team: ${totalRecruitmentTeam}
      | Tech Team: ${totalTechTeam}`;
    const isTpAdmin = clearanceLevel === 2;
    const isAdmin = clearanceLevel > 0;
    const organizationName = (chapter.organization || {}).name || 'None Found';

    return (
      <TableRow colSpan={ numCols }
        className={ classes.row }
        hover>

        { isAdmin && [
          <TableCell align='right'
            key={ `mode-${chapter.id}` }
            padding='checkbox'>
            <Tooltip title={ getMode('title', chapter) }>
              <IconButton onClick={ this.handleClick }>
                <Icon color='primary' className={ classes.modeIcon }>
                  { getMode('icon', chapter) }
                </Icon>
              </IconButton>
            </Tooltip>
          </TableCell>,

          isTpAdmin && [
            <TableCell padding='none' onClick={ this.handleClick } key={ `id-${chapter.id}` }>
              <Typography>{ chapter.id }</Typography>
            </TableCell>,

            <TableCell padding='dense' onClick={ this.handleClick } key={ `org-${chapter.id}` }>
              <Typography>{ organizationName }</Typography>
            </TableCell>,
          ],
        ]}

        <TableCell padding='dense' onClick={ this.handleClick }>
          <Typography>{ chapter.name }</Typography>
        </TableCell>

        <TableCell padding='dense' onClick={ this.handleClick }>
          <Typography>{ chapter.school }</Typography>
        </TableCell>

        { isAdmin ? [
          <TableCell padding='none' onClick={ this.handleClick } key={ `cdSite-${chapter.id}` }>
            <Typography>{ cdSite }</Typography>
          </TableCell>,

          <TableCell padding='none' onClick={ this.handleClick } key={ `totalUsers-${chapter.id}` }>
            <Tooltip title={ userBreakdown }>
              <Typography>
                { totalUsers }
              </Typography>
            </Tooltip>
          </TableCell>,
        ] : null}

        <TableCell padding='dense' onClick={ this.handleClick }>
          <Typography>{ formatDate(chapter.date_created) }</Typography>
        </TableCell>

        { isAdmin && (
          <TableCell padding='dense' onClick={ this.handleClick } key={ `lastActive-${chapter.id}` }>
            <Typography>{ formatDate(chapter.updatedAt) }</Typography>
          </TableCell>
        )}

        { isTpAdmin && [
          <TableCell padding='dense' onClick={ this.handleClick } key={ `lastCleared-${chapter.id}` }>
            <Typography>{ chapter.lastCleared || 'N/A' }</Typography>
          </TableCell>,
          <TableCell padding='dense' onClick={ this.handleClick } key={ `lastCobCleared-${chapter.id}` }>
            <Typography>{ chapter.lastCobCleared || 'N/A' }</Typography>
          </TableCell>,

          <TableCell padding='checkbox' key={ `options-${chapter.id}` }>
            <IconButton className={ classes.optionsButton }
              onClick={ event => handleRowOptionsOpen(event, chapter.id, chapter.isCdSite) }>
              <Icon>more_vert</Icon>
            </IconButton>
          </TableCell>,
        ]}

      </TableRow>
    );
  }
}

const styles = theme => ({
  modeIcon: {
    fontSize: 35,
  },

  row: {
    cursor: 'pointer',
  },

  recTeam: {
    color: theme.palette.primary.main,
  },

  optionsButton: {
    zIndex: 2,
  },

  techTeam: {
    color: theme.palette.secondary.main,
  },
});

export default withStyles(styles)(EnhancedTableRow);
