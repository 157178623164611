import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import noteService from '../../sources/api/noteService';

// constants
import { NOTE_CREATE, NOTE_CREATE_INIT } from '../../constants/noteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { noteSchema } from '../../schemas/note';

export function* noteCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'noteForm';

  const values = action.payload;

  yield put(startSubmit(FORM));

  const options = {
    query: {
      $populate: 'tags',
    },
  };

  try {
    yield* httpSaga(NOTE_CREATE, call(create, noteService, values, options), {
      schema: noteSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(NOTE_CREATE, err));
  } finally {
    yield put(doneIndicator(NOTE_CREATE));
  }
}

export function* watchNoteCreate() {
  yield takeEvery(NOTE_CREATE_INIT, noteCreate);
}

export default function* root() {
  yield all([
    fork(watchNoteCreate),
  ]);
}
