import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import resultService from '../../sources/api/resultService';

// constants
import { RESULT_FETCH_ALL_FOR_CHAPTER, RESULT_FETCH_ALL_FOR_CHAPTER_INIT } from '../../constants/resultTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* resultFetchAllForChapter(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    active,
    chapter,
    includeFields,
  } = action.payload || {};

  try {
    const options = {
      query: {
        includeFields,
        active,
        chapter,
      },
    };

    yield* httpSaga(RESULT_FETCH_ALL_FOR_CHAPTER, call(find, resultService, options));
  } catch (err) {
    yield put(error(RESULT_FETCH_ALL_FOR_CHAPTER, err));
  } finally {
    yield put(doneIndicator(RESULT_FETCH_ALL_FOR_CHAPTER));
  }
}

export function* watchResultFetchAllForChapter() {
  yield takeLatest(RESULT_FETCH_ALL_FOR_CHAPTER_INIT, resultFetchAllForChapter);
}

export default function* root() {
  yield all([
    fork(watchResultFetchAllForChapter),
  ]);
}
