import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { find, remove } from '../../sources/api/client';
import userService from '../../sources/api/userService';

// constants
import { USER_REMOVE_ALL_FOR_CHAPTER_INIT, USER_REMOVE_ALL_FOR_CHAPTER, USER_FETCH_ALL_FOR_CHAPTER } from '../../constants/userTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userListSchema } from '../../schemas/user';

export function* userRemoveAllForChapter(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'removeUsersForm';

  yield put(startSubmit(FORM));

  const { chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
      removeAllUsers: true,
    },
  };

  try {
    yield* httpSaga(USER_REMOVE_ALL_FOR_CHAPTER, call(remove, userService, null, options));

    yield* httpSaga(USER_FETCH_ALL_FOR_CHAPTER, call(find, userService, { query: { chapter } }), {
      schema: userListSchema,
    });

    yield put(setSubmitSucceeded(FORM));

    yield put(destroy(FORM));
  } catch (err) {
    yield put(error(USER_REMOVE_ALL_FOR_CHAPTER, err));
    yield put(setSubmitFailed(FORM));
  } finally {
    yield put(doneIndicator(USER_REMOVE_ALL_FOR_CHAPTER));
  }
}

export function* watchUserRemoveAllForChapter() {
  yield takeEvery(USER_REMOVE_ALL_FOR_CHAPTER_INIT, userRemoveAllForChapter);
}

export default function* root() {
  yield all([
    fork(watchUserRemoveAllForChapter),
  ]);
}
