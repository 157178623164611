import client from './client';

export default (function RoundsService() {
  let service;

  if (!service) {
    service = client.service('rounds');
  }

  return service;
}());
