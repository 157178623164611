import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { pnmBulkUpdate, pnmUpdateAllForChapter } from '../../../../../../actions/pnmActions';

// components
import StatusForm from './StatusForm';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  statusForm:     (state.get('form') || Map()).get('statusForm') || Map(),
});

const mapDispatchToProps = dispatch => ({
  bulkUpdatePnms: (params) => {
    dispatch(pnmBulkUpdate(params));
  },
  updateAllPnmsForChapter: (params) => {
    dispatch(pnmUpdateAllForChapter(params));
  },
});

// schemas
const schema = {
  status: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedStatusForm = reduxForm({
  form:     'statusForm',
  validate: validateForm,
  onChange: handleChange,
})(StatusForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(wrappedStatusForm));
