import {
  ANALYTICS_FETCH_INIT,
  ANALYTICS_FETCH_FOR_CHAPTER_INIT,
  ANALYTICS_FETCH_OVERVIEW_DATA_INIT,
} from '../constants/analyticsTypes';

export function analyticsFetchAll() {
  return {
    type: ANALYTICS_FETCH_INIT,
  };
}

export function analyticsFetchOverviewData(params) {
  return {
    type:    ANALYTICS_FETCH_OVERVIEW_DATA_INIT,
    payload: params,
  };
}

export function analyticsFetchForChapters(params) {
  return {
    type:    ANALYTICS_FETCH_FOR_CHAPTER_INIT,
    payload: params,
  };
}
