import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { get, patch } from '../../sources/api/client';
import chapterService from '../../sources/api/chapterService';

// constants
import { CHAPTER_UPDATE, CHAPTER_UPDATE_INIT, CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { chapterSchema } from '../../schemas/chapter';

export function* chapterUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapterId, ...values } = action.payload;
  const FORM = action.payload.formName || ' ';
  yield put(startSubmit(FORM));

  if (values.hometownGroups) {
    const { hometownGroups } = values;

    hometownGroups.forEach((hometownGroup, index) => {
      values.hometownGroups[index].order = index;
    });
  }

  if (values.rounds) {
    const { rounds } = values;

    rounds.forEach((round, index) => {
      values.rounds[index].order = index;
    });
  }

  if (values.fields) {
    const { fields } = values;

    fields.forEach((field, index) => {
      values.fields[index].order = index;
    });
  }

  try {
    yield* httpSaga(CHAPTER_UPDATE, call(patch, chapterService, chapterId, { ...values }), {
      schema: chapterSchema,
    });

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapterId), {
      schema: chapterSchema,
    });

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(CHAPTER_UPDATE, err));
  } finally {
    yield put(doneIndicator(CHAPTER_UPDATE));
  }
}

export function* watchChapterUpdate() {
  yield takeEvery(CHAPTER_UPDATE_INIT, chapterUpdate);
}

export default function* root() {
  yield all([
    fork(watchChapterUpdate),
  ]);
}
