import {
  VOTE_FETCH_FOR_PNM_INIT,
  VOTE_DELETE_INIT,
  VOTE_CREATE_INIT,
  VOTE_UPDATE_INIT,
  VOTE_CLEAR,
  VOTE_BULK_CREATE_INIT,
  VOTE_FETCH_ALL_INIT,
  VOTE_FETCH_FOR_USER_INIT,
  VOTE_UPDATE_COUNTS,
} from '../constants/voteTypes';

export function voteFetchForPnm(params) {
  return {
    type:    VOTE_FETCH_FOR_PNM_INIT,
    payload: params,
  };
}

export function voteFetchAll({
  chapter,
  eventNumber,
  infiniteScroll = false,
  limit,
  shouldPopulatePnm = false,
  roundId,
  search,
  skip,
  sort,
} = {}) {
  return {
    type:    VOTE_FETCH_ALL_INIT,
    payload: {
      chapter,
      eventNumber,
      infiniteScroll,
      limit,
      shouldPopulatePnm,
      roundId,
      search,
      skip,
      sort,
    },
  };
}

export function voteFetchForUser({
  chapter,
  infiniteScroll = false,
  limit,
  roundId,
  search,
  shouldPopulatePnm = false,
  skip,
  sort,
  userId,
} = {}) {
  return {
    type:    VOTE_FETCH_FOR_USER_INIT,
    payload: {
      chapter,
      infiniteScroll,
      limit,
      roundId,
      search,
      shouldPopulatePnm,
      skip,
      sort,
      userId,
    },
  };
}

export function voteDelete(params) {
  return {
    type:    VOTE_DELETE_INIT,
    payload: params,
  };
}

export function voteCreate(params) {
  return {
    type:    VOTE_CREATE_INIT,
    payload: params,
  };
}

export function voteUpdate(params) {
  return {
    type:    VOTE_UPDATE_INIT,
    payload: params,
  };
}

export function updateCategoryVoteCounts({ newCategoryVotes }) {
  return {
    type:    VOTE_UPDATE_COUNTS,
    payload: {
      newCategoryVotes,
    },
  };
}

export function voteBulkCreate(params) {
  return {
    type:    VOTE_BULK_CREATE_INIT,
    payload: params,
  };
}

export function voteClear() {
  return { type: VOTE_CLEAR };
}
