export const BUMP_GROUP_GENERATE = 'BUMP_GROUP_GENERATE';
export const BUMP_GROUP_GENERATE_INIT = 'BUMP_GROUP_GENERATE_INIT';
export const BUMP_GROUP_GENERATE_DONE = 'BUMP_GROUP_GENERATE_DONE';

export const BUMP_GROUP_CREATE_INIT = 'BUMP_GROUP_CREATE_INIT';
export const BUMP_GROUP_CREATE = 'BUMP_GROUP_CREATE';

export const BUMP_GROUP_REMOVE_INIT = 'BUMP_GROUP_REMOVE_INIT';
export const BUMP_GROUP_REMOVE = 'BUMP_GROUP_REMOVE';

export const BUMP_GROUP_FETCH_FOR_CHAPTER_INIT = 'BUMP_GROUP_FETCH_FOR_CHAPTER_INIT';
export const BUMP_GROUP_FETCH_FOR_CHAPTER = 'BUMP_GROUP_FETCH_FOR_CHAPTER';

export const BUMP_GROUP_UPDATE = 'BUMP_GROUP_UPDATE';
export const BUMP_GROUP_UPDATE_INIT = 'BUMP_GROUP_UPDATE_INIT';
export const BUMP_GROUP_UPDATE_DONE = 'BUMP_GROUP_UPDATE_DONE';

export const BUMP_GROUP_BULK_UPDATE = 'BUMP_GROUP_BULK_UPDATE';
export const BUMP_GROUP_BULK_UPDATE_INIT = 'BUMP_GROUP_BULK_UPDATE_INIT';
export const BUMP_GROUP_BULK_UPDATE_DONE = 'BUMP_GROUP_BULK_UPDATE_DONE';
