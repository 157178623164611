import { connect } from 'react-redux';
import { Map } from 'immutable';
import { reduxForm, isDirty } from 'redux-form/immutable';

// action creators
import { chapterUpdate } from '../../../../../../actions/chapterActions';

// components
import FilesSettingsForm from './FilesSettingsForm';

const createInitialValues = (props) => {
  const { currentChapter } = props;

  const file1Label = currentChapter.getIn(['data', 'file1Label'], '');
  const file2Label = currentChapter.getIn(['data', 'file2Label'], '');
  const files = [
    file1Label.length ? {
      label:     file1Label,
      visibleTo: currentChapter.getIn(['data', 'file1VisibilityLevel']),
    } : null,
    file2Label.length ? {
      label:     file2Label,
      visibleTo: currentChapter.getIn(['data', 'file2VisibilityLevel']),
    } : null,
  ].filter(Boolean);

  return { files };
};

const mapStateToProps = (state, props) => ({
  filesSettingsForm: state.getIn(['form', 'filesSettingsForm'], Map()),
  dirty:             isDirty('filesSettingsForm'),
  initialValues:     createInitialValues(props),
});

const mapDispatchToProps = dispatch => ({
  updateChapter: (params) => dispatch(chapterUpdate(params)),
});

const wrappedFilesSettingsForm = reduxForm({
  form:               'filesSettingsForm',
  enableReinitialize: true,
})(FilesSettingsForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedFilesSettingsForm);
