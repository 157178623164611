import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';
import moment from 'moment';

// MUI components
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popover from '@material-ui/core/Popover';

// local components
import ActionModal from '../../../../../../ActionModal';

class RowOptionsMenu extends Component {
  static propTypes = {
    chapter:         PropTypes.instanceOf(Map).isRequired,
    chapterId:       PropTypes.number.isRequired,
    deleteMatch:     PropTypes.func.isRequired,
    deleteNotes:     PropTypes.func.isRequired,
    deletePnms:      PropTypes.func.isRequired,
    deleteVotes:     PropTypes.func.isRequired,
    initChapters:    PropTypes.func.isRequired,
    isChapterCdSite: PropTypes.number.isRequired,
    item:            PropTypes.instanceOf(Map).isRequired,
    match:           PropTypes.instanceOf(Map).isRequired,
    note:            PropTypes.instanceOf(Map).isRequired,
    pnm:             PropTypes.instanceOf(Map).isRequired,
    updateChapter:   PropTypes.func.isRequired,
    vote:            PropTypes.instanceOf(Map).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        menuOpen:              false,
        chapterIndex:          0,
        chapterToClear:        null,
        confirmClearModalOpen: false,
        dataToClear:           null,
        modeToClear:           '',
        rowOptionsOpen:        false,
        anchorEl:              null,
      }),
    };

    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {
      match,
      note,
      pnm,
      vote,
    } = nextProps;

    const success = !pnm.get('loading') && !vote.get('loading') && !note.get('loading') && !match.get('loading');

    if (success) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('confirmClearModalOpen', false);
        }),
      });
    }
  }

  getModalInfo = () => {
    const { chapter, isChapterCdSite } = this.props;
    const dataToClear = this.state.data.get('dataToClear');
    const rawModeToClear = this.state.data.get('modeToClear', 'Site');
    const modeToClear = `${rawModeToClear.charAt(0).toUpperCase()}${rawModeToClear.substr(1)}`;

    const chapterData = chapter.get('data') || Map();
    const chapterToClear = this.state.data.get('chapterToClear') || 0;
    const chapterToClearData = chapterData.getIn(['items', chapterToClear.toString()], Map());
    const chapterName = chapterToClearData.get('name') || 'N/A';
    const chapterSchool = chapterToClearData.get('school') || 'N/A';

    let description;
    let title;

    switch (dataToClear) {
      case 'all':
        description = isChapterCdSite
          ? `All votes and notes for ${chapterName} (${chapterSchool}) on this mode will be lost.
            Are you sure you wasnt to delete these precious lil' gems?`
          : `All PNMs, votes, and notes for ${chapterName} (${chapterSchool}) on this mode will be lost.
            Are you sure you want to delete these precious lil' gems?`;

        title = `Clear ${modeToClear} Data?`;
        break;
      case 'pnms':
        description = `All PNMs for ${chapterName} (${chapterSchool}) on this mode will be lost.
          Are you sure you want to delete these precious lil' gems?`;
        title = `Clear ${modeToClear} PNMs?`;
        break;
      case 'votes':
        description = `All votes for ${chapterName} (${chapterSchool}) on this mode will be lost.
          Are you sure you want to delete these forever?`;
        title = `Clear ${modeToClear} Votes?`;
        break;
      case 'notes':
        description = `All notes for ${chapterName} (${chapterSchool}) on this mode will be lost.
          Are you sure you want to delete these forever?`;
        title = `Clear ${modeToClear} Notes?`;
        break;
      case 'matches':
        description = `All matches for ${chapterName} (${chapterSchool}) on this mode will be lost.
          Are you sure you want to delete these forever?`;
        title = `Clear ${modeToClear} Matches?`;
        break;
      default:
        description = 'Are you sure you want to clear this data?';
        title = 'Clear Site?';
        break;
    }

    return Immutable.fromJS({ title, description });
  }

  handleClearSite = () => {
    const {
      deleteMatch,
      deleteNotes,
      deletePnms,
      deleteVotes,
      initChapters,
      updateChapter,
      isChapterCdSite,
    } = this.props;

    const chapter = this.state.data.get('chapterToClear');
    const dataToClear = this.state.data.get('dataToClear');
    const modeToClear = this.state.data.get('modeToClear', '');

    switch (dataToClear) {
      case 'all':
        if (!isChapterCdSite || modeToClear === 'cob') {
          deletePnms({ pnmIds: [], chapter, modeToClear });
        }
        deleteVotes({ id: null, chapter, modeToClear });
        deleteNotes({ id: null, chapter, modeToClear });
        deleteMatch({ id: null, chapter, modeToClear });
        break;
      case 'pnms':
        deletePnms({ pnmIds: [], chapter, modeToClear });
        break;
      case 'votes':
        deleteVotes({ id: null, chapter, modeToClear });
        break;
      case 'notes':
        deleteNotes({ id: null, chapter, modeToClear });
        break;
      case 'matches':
        deleteMatch({ id: null, chapter, modeToClear });
        break;
      default:
        break;
    }

    if (dataToClear === 'all') {
      const query = {
        chapterId: chapter,
      };
      if (modeToClear === 'all') {
        query.lastCleared = moment(new Date(), 'MM-DD-YYYY').format('MMMM Do, YYYY');
        query.lastCobCleared = moment(new Date(), 'MM-DD-YYYY').format('MMMM Do, YYYY');
      }
      if (modeToClear === 'standard') {
        query.lastCleared = moment(new Date(), 'MM-DD-YYYY').format('MMMM Do, YYYY');
      }
      if (modeToClear === 'cob') {
        query.lastCobCleared = moment(new Date(), 'MM-DD-YYYY').format('MMMM Do, YYYY');
      }

      updateChapter(query);
      initChapters();
    }
  }

  handleOpenConfirmModal = (chapter, dataToClear, modeToClear) => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('rowOptionsOpen', false);
        map.set('confirmClearModalOpen', true);
        map.set('anchorEl', null);
        map.set('menuOpen', false);
        map.set('chapterToClear', chapter);
        map.set('dataToClear', dataToClear);
        map.set('modeToClear', modeToClear);
      }),
    });
  }

  handleCloseConfirmModal = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('confirmClearModalOpen', false);
        map.set('anchorEl', null);
        map.set('chapterToClear', null);
        map.set('dataToClear', null);
        map.set('modeToClear', '');
      }),
    });
  }

  handleOpenMenu = (event) => {
    event.preventDefault();

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('menuOpen', true);
        map.set('anchorEl', event.currentTarget);
      }),
    });
  }

  handleCloseMenu = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('menuOpen', false);
        map.set('anchorEl', null);
      }),
    });
  }

  render() {
    const {
      chapterId,
      isChapterCdSite,
      item,
      match,
      note,
      pnm,
      vote,
    } = this.props;

    const confirmClearModalOpen = this.state.data.get('confirmClearModalOpen');

    const modalLoading = pnm.get('loading') || vote.get('loading') || note.get('loading') || match.get('loading') || false;
    const modalInfo = this.getModalInfo();

    const open = this.state.data.get('menuOpen');
    const anchorEl = this.state.data.get('anchorEl');

    return (
      <div>
        <ListItem button
          onClick={ event => this.handleOpenMenu(event) }>
          <ListItemIcon>
            <Icon>keyboard_arrow_left</Icon>
          </ListItemIcon>
          <ListItemText primary={ item.get('title') } />
        </ListItem>

        <Popover open={ open }
          anchorEl={ anchorEl }
          anchorOrigin={ {
            vertical:   'center',
            horizontal: 'left',
          } }
          transformOrigin={ {
            vertical:   'top',
            horizontal: 'right',
          } }
          onClose={ this.handleCloseMenu }>
          <List subheader={
            <ListSubheader component='div'>{ item.get('title') }</ListSubheader>
          }>
            <ListItem button onClick={ () => this.handleOpenConfirmModal(chapterId, 'all', item.get('mode')) }>
              <ListItemIcon>
                <Icon>delete</Icon>
              </ListItemIcon>
              <ListItemText primary='Clear All' />
            </ListItem>

            { (!isChapterCdSite || item.get('mode') === 'cob') && (
            <ListItem button onClick={ () => this.handleOpenConfirmModal(chapterId, 'pnms', item.get('mode')) }>
              <ListItemIcon>
                <Icon>group</Icon>
              </ListItemIcon>
              <ListItemText primary='Clear PNMs' />
            </ListItem>
            )}

            <ListItem button onClick={ () => this.handleOpenConfirmModal(chapterId, 'votes', item.get('mode')) }>
              <ListItemIcon>
                <Icon>thumbs_up_down</Icon>
              </ListItemIcon>
              <ListItemText primary='Clear Votes' />
            </ListItem>

            <ListItem button onClick={ () => this.handleOpenConfirmModal(chapterId, 'notes', item.get('mode')) }>
              <ListItemIcon>
                <Icon>comment</Icon>
              </ListItemIcon>
              <ListItemText primary='Clear Notes' />
            </ListItem>

            <ListItem button onClick={ () => this.handleOpenConfirmModal(chapterId, 'matches', item.get('mode')) }>
              <ListItemIcon>
                <Icon>extension</Icon>
              </ListItemIcon>
              <ListItemText primary='Clear Matches' />
            </ListItem>
          </List>
        </Popover>

        <ActionModal description={ modalInfo.get('description') }
          acceptLabel='R.I.P.'
          appState={ pnm }
          key='confirmClearModal'
          loading={ modalLoading }
          onAccept={ () => this.handleClearSite() }
          onReject={ this.handleCloseConfirmModal }
          open={ confirmClearModalOpen }
          rejectLabel='Go back!'
          title={ modalInfo.get('title') } />
      </div>
    );
  }
}

export default RowOptionsMenu;
