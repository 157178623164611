import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { patch } from '../../sources/api/client';
import roundService from '../../sources/api/roundService';

// constants
import { ROUND_BULK_UPDATE, ROUND_BULK_UPDATE_INIT } from '../../constants/roundTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { roundListSchema } from '../../schemas/round';

export function* roundBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { items, chapter, formName } = action.payload || {};

  const FORM = formName;

  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(ROUND_BULK_UPDATE, call(patch, roundService, null, { items }, {
      query: {
        bulkUpdate: true,
        chapter,
      },
    }), { schema: roundListSchema });

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(ROUND_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(ROUND_BULK_UPDATE));
  }
}

export function* watchRoundBulkUpdate() {
  yield takeEvery(ROUND_BULK_UPDATE_INIT, roundBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchRoundBulkUpdate),
  ]);
}
