import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { patch } from '../../sources/api/client';
import userService from '../../sources/api/userService';

// constants
import { USER_BULK_UPDATE, USER_BULK_UPDATE_INIT } from '../../constants/userTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userListSchema } from '../../schemas/user';

export function* userBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'usersForm';

  yield put(startSubmit(FORM));

  const { items, chapter } = action.payload || {};

  try {
    yield* httpSaga(USER_BULK_UPDATE, call(patch, userService, null, { items }, {
      query: {
        bulkUpdate: true,
        chapter,
      },
    }), { schema: userListSchema });

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(USER_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(USER_BULK_UPDATE));
  }
}

export function* watchUserBulkUpdate() {
  yield takeEvery(USER_BULK_UPDATE_INIT, userBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchUserBulkUpdate),
  ]);
}
