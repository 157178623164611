export const CURRENT_USER_FETCH_INIT = 'CURRENT_USER_FETCH_INIT';
export const CURRENT_USER_FETCH = 'CURRENT_USER_FETCH';

export const USER_FETCH_ALL = 'USER_FETCH_ALL';
export const USER_FETCH_ALL_INIT = 'USER_FETCH_ALL_INIT';
export const USER_FETCH_ALL_DONE = 'USER_FETCH_ALL_DONE';

export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_INIT = 'USER_CREATE_INIT';
export const USER_CREATE_DONE = 'USER_CREATE_DONE';

export const USER_UPDATE_INIT = 'USER_UPDATE_INIT';
export const USER_UPDATE = 'USER_UPDATE';

export const USER_FETCH_ALL_FOR_CHAPTER = 'USER_FETCH_ALL_FOR_CHAPTER';
export const USER_FETCH_ALL_FOR_CHAPTER_INIT = 'USER_FETCH_ALL_FOR_CHAPTER_INIT';
export const USER_FETCH_ALL_FOR_CHAPTER_DONE = 'USER_FETCH_ALL_FOR_CHAPTER_DONE';

export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_INIT = 'USER_DELETE_INIT';
export const USER_DELETE_DONE = 'USER_DELETE_DONE';

export const USER_BULK_UPDATE = 'USER_BULK_UPDATE';
export const USER_BULK_UPDATE_INIT = 'USER_BULK_UPDATE_INIT';
export const USER_BULK_UPDATE_DONE = 'USER_BULK_UPDATE_DONE';

export const USER_REMOVE_ALL_FOR_CHAPTER = 'USER_REMOVE_ALL_FOR_CHAPTER';
export const USER_REMOVE_ALL_FOR_CHAPTER_INIT = 'USER_REMOVE_ALL_FOR_CHAPTER_INIT';
export const USER_REMOVE_ALL_FOR_CHAPTER_DONE = 'USER_REMOVE_ALL_FOR_CHAPTER_DONE';
