import { SESSION_CREATE, SESSION_DESTROY, SESSION_VERIFY } from '../constants/sessionTypes';

export function sessionCreate({ email, password }) {
  return {
    type: SESSION_CREATE,

    payload: {
      email,
      password,
    },
  };
}

export function sessionDestroy() {
  return {
    type: SESSION_DESTROY,
  };
}

export function sessionVerify() {
  return {
    type: SESSION_VERIFY,
  };
}
