import { connect } from 'react-redux';
import { reduxForm, isDirty } from 'redux-form/immutable';
import validate from 'validate.js';
import Immutable, { Map } from 'immutable';

// components
import ExistingUsersForm from './ExistingUsersForm';

// schemas
const schema = {
  role: {
    presence: true,
  },
};

const getInitialValues = function (users) {
  return {
    users: users.map((u = Map()) => Immutable.fromJS({
      id:   u.get('id') || 0,
      role: u.get('role') || '',
    })),
  };
};

const mapStateToProps = (state, { users }) => ({
  usersForm:     (state.get('form')).get('usersForm') || Map(),
  dirty:         isDirty('usersForm'),
  initialValues: getInitialValues(users),
});

const validateForm = function (form) {
  const userErrors = [];

  let error = false;
  let validations;

  form.toJS().users.forEach((user) => {
    if (user.role) {
      const userError = validate(user, schema);

      userErrors.push(userError);

      if (userError) {
        error = true;
      }
    }
  });

  if (error) {
    validations = {
      users: userErrors,
    };
  }

  return validations;
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedExistingUsersForm = reduxForm({
  form:               'usersForm',
  validate:           validateForm,
  onChange:           handleChange,
  enableReinitialize: true,
})(ExistingUsersForm);

export default connect(mapStateToProps)(wrappedExistingUsersForm);
