import { connect } from 'react-redux';

import { fieldCreate, fieldRemove } from '../../../../actions/fieldActions';

// components
import Fields from './Fields';

// selectors
import { isSubmitting } from '../../../../lib/selectors/formSelectors';
import getFields from '../../../../lib/selectors/getFields';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getStatusFromState from '../../../../lib/selectors/getStatusFromState';

const mapStateToProps = state => ({
  currentChapter:       state.get('currentChapter'),
  chapterFields:        getFields(state),
  fieldLoading:         getLoadingFromState('field')(state),
  fieldStatus:          getStatusFromState('field')(state),
  fieldsFormSubmitting: isSubmitting('fieldsForm')(state),
});

const mapDispatchToProps = dispatch => ({
  createField: (params) => {
    dispatch(fieldCreate(params));
  },
  removeField: (id, chapter) => {
    dispatch(fieldRemove(id, chapter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
