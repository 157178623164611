import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroyForm,
  startFormSubmit,
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from '../../actions/formActions';

// api
import { patch } from '../../sources/api/client';
import hometownGroupService from '../../sources/api/hometownGroupService';

// constants
import { HOMETOWN_GROUP_BULK_UPDATE_INIT, HOMETOWN_GROUP_BULK_UPDATE } from '../../constants/hometownGroupTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { hometownGroupListSchema } from '../../schemas/hometownGroup';

export function* hometownGroupBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'hometownGroupsForm';

  yield put(startFormSubmit(FORM));

  const { items, chapter } = action.payload || {};

  items.forEach((item, index) => {
    items[index].order = index;
  });

  try {
    yield* httpSaga(HOMETOWN_GROUP_BULK_UPDATE, call(patch, hometownGroupService, null, { items }, {
      query: {
        bulkUpdate: true,
        chapter,
      },
    }), { schema: hometownGroupListSchema });

    yield put(setFormSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setFormSubmitFailed(FORM));
    yield put(error(HOMETOWN_GROUP_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(HOMETOWN_GROUP_BULK_UPDATE));
    yield put(destroyForm(FORM));
  }
}

export function* watchHometownGroupBulkUpdate() {
  yield takeEvery(HOMETOWN_GROUP_BULK_UPDATE_INIT, hometownGroupBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchHometownGroupBulkUpdate),
  ]);
}
