import { connect } from 'react-redux';

// action creators
import { drawerClose } from '../../../../actions/drawerActions';

// components
import Drawer from './Drawer';

const mapStateToProps = state => ({
  drawer: state.get('drawer'),
});

const mapDispatchToProps = dispatch => ({
  closeDrawer: () => {
    dispatch(drawerClose());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
