import { connect } from 'react-redux';

// action creators
import { hometownGroupCreate, hometownGroupRemove } from '../../../../actions/hometownGroupActions';

// selectors
import { isSubmitting } from '../../../../lib/selectors/formSelectors';
import getHometowns from '../../../../lib/selectors/getHometowns';
import getStatusFromState from '../../../../lib/selectors/getStatusFromState';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';

// components
import HometownGroups from './HometownGroups';

const mapStateToProps = state => ({
  currentChapter:               state.get('currentChapter'),
  hometownGroups:               getHometowns(state),
  hometownGroupStatus:          getStatusFromState('hometownGroup')(state) || '',
  hometownGroupLoading:         getLoadingFromState('hometownGroup')(state),
  hometownGroupsFormSubmitting: isSubmitting('hometownGroupsForm')(state),
});

const mapDispatchToProps = dispatch => ({
  createHometownGroup: (params) => {
    dispatch(hometownGroupCreate(params));
  },
  removeHometownGroup: (id, chapter) => {
    dispatch(hometownGroupRemove(id, chapter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HometownGroups);
