import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';

// components
import Button from '../../../Button';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

class VoteButtons extends Component {
  static propTypes = {
    categoryVote: PropTypes.instanceOf(Map),
    classes:      PropTypes.instanceOf(Object).isRequired,
    currentVote:  PropTypes.instanceOf(Map),
    input:        PropTypes.instanceOf(Object).isRequired,
    isAdmin:      PropTypes.bool.isRequired,
    options:      PropTypes.instanceOf(List).isRequired,
    type:         PropTypes.string.isRequired,
  };

  static defaultProps = {
    categoryVote: List(),
    currentVote:  Map(),
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        selectedVoteIndex: '',
      }),
    };
  }

  getDisabled = (item) => {
    const { categoryVote, currentVote, isAdmin } = this.props;

    if (isAdmin) {
      return false;
    }

    const itemId = item.get('_id', '').toString();
    const categoryCount = categoryVote.getIn(['options', itemId, 'count'], 0);
    const categoryLimit = categoryVote.getIn(['options', itemId, 'limit'], 0);
    const currentVoteCategoryValue = currentVote.getIn(['categories', 0, 'value']);
    const limit = categoryVote.getIn(['options', itemId, 'limit'], 1);
    const count = categoryVote.getIn(['options', itemId, 'count'], 0);

    // enable option if this is a resubmit and the current count is only one vote over the limit
    if (currentVoteCategoryValue === item.get('value')) {
      return categoryCount - 1 > categoryLimit;
    }

    // check if limit reached
    return limit <= count;
  }

  getCategoryVotesCast = (item) => {
    const { categoryVote, currentVote } = this.props;
    const itemId = item.get('_id', '').toString();
    const currentVoteCategoryValue = currentVote.getIn(['categories', 0, 'value']);
    let count = categoryVote.getIn(['options', itemId, 'count'], 0);

    // do not count current vote on resubmit
    if (currentVoteCategoryValue === item.get('value')) {
      count -= 1;
    }

    return count > 0 ? count : '';
  }

  getCategoryVotesRemainingLabel = (item) => {
    const { categoryVote, currentVote } = this.props;
    const itemId = item.get('_id', '').toString();

    // check if limit reached
    const currentVoteCategoryValue = currentVote.getIn(['categories', 0, 'value']);
    const voteValue = categoryVote.getIn(['options', itemId, 'value'], false);
    const limit = categoryVote.getIn(['options', itemId, 'limit'], false);
    let count = categoryVote.getIn(['options', itemId, 'count'], 0);

    // do not count current vote on resubmit
    if (currentVoteCategoryValue === voteValue) {
      count -= 1;
    }
    const votesRemaining = limit - count > 0 ? limit - count : 0;

    const label = votesRemaining
      ? `You may cast ${votesRemaining} more ${voteValue}'s`
      : 'Vote limit reached';

    return limit || count
      ? label
      : '';
  }

  getButtonVariant = (options) => {
    let variant = 'fab';
    const { type } = this.props;

    options.forEach((option) => {
      if (option.get('label').length > 2 && type !== 'icon') {
        variant = 'extendedFab';
      }
    });
    return variant;
  }

  handleVoteChange = (newVoteIndex) => {
    this.setState({ data: this.state.data.set('selectedVoteIndex', newVoteIndex) });
  }

  render() {
    const {
      classes,
      options,
      input,
      type,
    } = this.props;

    const selectedVoteIndex = this.state.data.get('selectedVoteIndex');
    const buttonVariant = this.getButtonVariant(options);

    return options.map((item, index) => (
      <Grid key={ `${item.get('_id')}` } item xs>
        <Tooltip title={ this.getCategoryVotesRemainingLabel(item) }>

          <Badge badgeContent={ this.getCategoryVotesCast(item) } color='secondary'>
            <Button className={ classes.button }
              variant={ buttonVariant }
              color={ selectedVoteIndex === index ? 'secondary' : 'contrast' }
              disabled={ this.getDisabled(item) }
              onClick={ () => {
                this.handleVoteChange(index);
                input.onChange(item.get('value'));
              } }>

              { type === 'button'
                ? (
                  <Typography variant={ item.get('typographyVariant', 'h3') }
                    color={ selectedVoteIndex === index ? 'inherit' : 'primary' }>
                    { item.get('label') }
                  </Typography>
                )
                : <Icon className={ classes.iconButton }>{item.get('label')}</Icon>}

            </Button>
          </Badge>
        </Tooltip>
      </Grid>
    ));
  }
}

const styles = theme => ({
  button: {
    height: 'auto',
  },

  iconButton: {
    margin: theme.spacing.unit,
  },

  helperText: {
    color:     theme.palette.common.white,
    marginTop: 15,
    textAlign: 'center',
  },
});

export default withStyles(styles)(VoteButtons);
