import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import pluralize from 'pluralize';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// local components
import Tag from '../../../../../Tag';

function Profile(props) {
  Profile.propTypes = {
    classes:         PropTypes.instanceOf(Object).isRequired,
    matchLoading:    PropTypes.bool.isRequired,
    pathname:        PropTypes.string.isRequired,
    selectedItem:    PropTypes.instanceOf(Map).isRequired,
    userSpecialties: PropTypes.instanceOf(List).isRequired,
  };

  const {
    classes, selectedItem, matchLoading, pathname, userSpecialties,
  } = props;

  const getRoleLabel = () => {
    const teamLevel = selectedItem.get('team_level');
    if (teamLevel === 0) {
      if (selectedItem.get('role') === 'CONCERN_CHAIR') {
        return 'Concern Chair';
      }
      return 'Voting Member';
    } if (teamLevel === 1) {
      return 'Recruitment Team';
    } if (teamLevel === 2) {
      return 'Technology Team';
    }
    return 'Voting Member';
  };

  const profileType = pathname.includes('bumpgroup')
    ? 'bumpGroup'
    : 'user';

  const getCount = (attribute) => {
    const initialValue = 0;

    const sumCount = selectedItem.get('users', List())
      .reduce((acc, cur) => acc + cur.get(attribute.toString()), initialValue);

    switch (profileType) {
      case 'user':
        return selectedItem.get(attribute.toString()) || 0;
      case 'bumpGroup':
        return sumCount || 0;
      default:
        return 0;
    }
  };

  const officialMatchCount = getCount('officialMatches');
  const matchRecCount = getCount('matchRecs');

  const matchCount = officialMatchCount === 0 ? 'No' : officialMatchCount;
  const officialMatchLabel = officialMatchCount > 1
    ? `${matchCount} Official Matches This Round`
    : `${matchCount} Official Match This Round`;

  const renderAvatar = () => {
    const firstName = selectedItem.get('firstname', '');
    const lastName = selectedItem.get('lastname', '');

    const avatarText = profileType === 'bumpGroup'
      ? `${selectedItem.get('order')}`
      : `${firstName.charAt(0)}${lastName.charAt(0)}`;

    let avatar = (
      <Avatar className={ classes.initialsAvatar }>
        { avatarText }
      </Avatar>
    );

    if (selectedItem.get('image')) {
      avatar = (
        <Avatar className={ classes.avatar } src={ selectedItem.get('image') } />
      );
    }

    return avatar;
  };

  const renderMatchCount = () => [
    <Grid item xs={ 2 } key='icon'>
      <Icon color='primary' className={ classes.profileIcon }>check_circle</Icon>
    </Grid>,
    <Grid item xs={ 10 } key='label'>
      <Typography color='primary'
        variant='subtitle2'
        align='left'
        className={ classes.profileLabel }
        gutterBottom>
        { officialMatchLabel }
      </Typography>
    </Grid>,
  ];

  const renderRecommendationCount = () => [
    <Grid item xs={ 2 } key='icon'>
      <Icon className={ classNames(classes.profileIcon, classes.grayIcon) }>extension</Icon>
    </Grid>,
    <Grid item xs={ 10 } className={ classes.profileLabel } key='label'>
      <Typography variant='subtitle2'
        align='left'
        className={ classes.profileLabel }
        gutterBottom>

        { pluralize('Match Recommendation', matchRecCount, true) }
      </Typography>
    </Grid>,
  ];

  const renderProfileContent = () => {
    let element;

    if (profileType === 'user') {
      element = [
        <Grid container justify='center' alignItems='center' key='top'>
          <Grid item xs={ 12 } className={ classes.paddedContainer }>
            { renderAvatar() }
          </Grid>
          <Grid item xs={ 12 }>
            <Typography variant='subtitle1' align='center'>
              { `${selectedItem.get('firstname')} ${selectedItem.get('lastname')}` }
            </Typography>
          </Grid>
          <Grid item xs={ 12 } className={ classes.bottomBorderContainer }>
            <Typography color='textSecondary' align='center' gutterBottom>{ getRoleLabel() }</Typography>
          </Grid>
        </Grid>,

        <Grid container
          justify='space-around'
          alignItems='center'
          alignContent='center'
          spacing={ 16 }
          key='bottom'
          className={ classes.profileStats }>

          { renderMatchCount() }
          { renderRecommendationCount() }

          <Grid item xs={ 2 }>
            <Icon className={ classNames(classes.profileIcon, classes.grayIcon) }>email</Icon>
          </Grid>
          <Grid item xs={ 10 } className={ classes.profileLabel }>
            <Typography variant='subtitle2'
              align='left'
              className={ classes.profileLabel }
              gutterBottom>
              { selectedItem.get('email') }
            </Typography>
          </Grid>
        </Grid>,
      ];
    } else if (profileType === 'bumpGroup') {
      let groupSpecialties = List();

      userSpecialties.forEach((userSpecialty) => {
        const groupSpecialtyIds = groupSpecialties.map(gs => gs.get('_id'));
        const specialtyId = userSpecialty.getIn(['specialty', '_id'], '').toString();

        // Filter out duplicate specialties
        if (!groupSpecialtyIds.includes(specialtyId)) {
          const newSpecialty = userSpecialty.get('specialty', Map());
          groupSpecialties = groupSpecialties.push(newSpecialty);
        } else {
          // Add counts to specialties if dupes
          const count = (groupSpecialties.find(gs =>
            gs.get('_id', '').toString() === specialtyId) || Map()).get('count', 1);

          groupSpecialties = groupSpecialties.setIn([
            groupSpecialties.indexOf(userSpecialty.get('specialty')),
            'count',
          ],
          count + 1);
        }
      });

      element = [
        <Grid container
          alignItems='center'
          className={ classNames(classes.paddedContainer, classes.bottomSpacing) }
          key='top'>

          <Grid item xs={ 4 }>
            <Avatar className={ classes.bumpGroupAvatar }>
              { selectedItem.get('order') }
            </Avatar>
          </Grid>

          <Grid item xs={ 8 }>
            <Grid container>
              <Grid item xs={ 12 }>
                <Typography variant='subtitle1'>
                  Bump Group
                  {' '}
                  { selectedItem.get('order') }
                </Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='body2'
                  color='textSecondary'>
                  { selectedItem.get('users', List()).size }
                  {' '}
                  Members
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>,

        <div key='topBorder' className={ classes.bottomBorderContainer } />,

        <Grid container
          justify='space-around'
          alignItems='center'
          alignContent='center'
          spacing={ 16 }
          key='stats'
          className={ classes.profileStats }>

          { renderMatchCount() }
          { renderRecommendationCount() }

        </Grid>,

        <div key='bottomBorder' className={ classes.bottomBorderContainer } />,

        <Grid container
          key='specialties'
          className={ classes.paddedContainer }
          alignItems='center'>
          <Grid item xs={ 12 }>
            <Typography variant='subtitle2' color='textSecondary'>
              Group Specialties
            </Typography>
          </Grid>

          { groupSpecialties.map(gs => (
            <Grid item xs={ 6 } key={ gs.get('_id') }>
              <Tag color={ gs.get('color', 'orange') }
                displayCount
                count={ gs.get('count', 0) }
                title={ gs.get('title', 'Specialty') } />
            </Grid>
          ))}

        </Grid>,
      ];
    }

    return element;
  };

  return (
    <Paper className={ classNames(classes.card, classes.profileCard) }>
      { matchLoading
        ? (
          <Grid container justify='center' alignItems='center'>
            <Grid item xs={ 12 } className={ classes.progressIndicator }>
              <CircularProgress />
            </Grid>
          </Grid>
        )
        : renderProfileContent()}
    </Paper>
  );
}

const styles = theme => ({
  card: {
    borderRadius: 10,
    marginTop:    20,
    minHeight:    450,
  },

  paddedContainer: {
    paddingTop:   25,
    paddingLeft:  16,
    paddingRight: 16,
  },

  progressIndicator: {
    textAlign: 'center',
    padding:   35,
  },

  bumpGroupAvatar: {
    width:           60,
    height:          60,
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.common.white,
  },

  bottomSpacing: {
    marginBottom: 15,
  },

  profileStats: {
    marginTop:    10,
    marginBottom: 5,
  },

  profileLabel: {
    paddingLeft: 12,
  },

  profileIcon: {
    marginLeft: 25,
  },
  grayIcon: {
    color: 'rgba(0,0,0,0.5)',
  },

  initialsAvatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatar: {
    width:  125,
    height: 125,
    margin: 'auto auto 15px auto',
  },

  avatarIcon: {
    fontSize: 50,
  },

  bottomBorderContainer: {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
});

export default withStyles(styles)(Profile);
