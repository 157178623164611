import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';
import { FieldArray } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import DraggableList from '../../../../../DraggableList';
import Savebar from '../../../../../Savebar';
import RoundItem from '../RoundItem';

const PRESCORE_ROUND_TYPES = [6, 7];

class RoundsForm extends Component {
  static propTypes = {
    bulkUpdateRounds: PropTypes.func.isRequired,
    currentChapter:   PropTypes.instanceOf(Map).isRequired,
    initialValues:    PropTypes.instanceOf(Object).isRequired,
    initializeForm:   PropTypes.func.isRequired,
    manualDirty:      PropTypes.bool.isRequired,
    removeRound:      PropTypes.func.isRequired,
    reorderForm:      PropTypes.func.isRequired,
    reset:            PropTypes.func.isRequired,
    roundsForm:       PropTypes.instanceOf(Map).isRequired,
    updateForm:       PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        autoHideDuration: null,
        hideButtons:      false,
        savebarOpen:      false,
        savebarMessage:   '',
        snackbarOpen:     false,
        snackbarMessage:  '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      manualDirty, roundsForm, reset, initializeForm, initialValues,
    } = nextProps;

    const savebarOpen = this.state.data.get('savebarOpen');

    if (!savebarOpen) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', manualDirty);
          map.set('savebarMessage', 'Save your changes?');
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    } else if (roundsForm.get('submitSucceeded')) {
      initializeForm(initialValues);
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Saved Successfully');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    } else if (roundsForm.get('submitFailed')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save Failed');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    }
  }

  handleOrderChange = (roundsParam = 0) => {
    const { updateForm, roundsForm } = this.props;
    const rounds = roundsParam || (roundsForm.get('values') || Map()).get('rounds') || List();

    const fixedRounds = rounds.filter(round => (
      round.get('draggable') === 0
      && PRESCORE_ROUND_TYPES.includes(round.get('roundType'))
    )) || List();

    const draggableRounds = rounds.filter(round => (round.get('draggable') === 1)) || List();

    let newRounds = fixedRounds;

    // reorders draggable rounds
    for (let index = 0; index < draggableRounds.size; index += 1) {
      const draggableRound = (draggableRounds.get(index) || Map()).set('order', index + 1);
      newRounds = newRounds.push(draggableRound);
    }

    // updates redux form state so that fields are mapped to correct order
    newRounds.forEach((round, index) => {
      updateForm(index, round);
    });
  }

  handleRoundChange = (event = { target: { value: 0 } }, roundIndex = -1) => {
    const { roundsForm } = this.props;
    event.preventDefault();

    const rounds = (roundsForm.get('values') || Map()).get('rounds') || List();

    const oldRoundType = rounds.getIn([roundIndex, 'roundType']);
    const newRoundType = event.target.value;

    let updatedRounds = rounds || List();

    // handles roundType changes by updating additional attrs
    //  that aren't connected to a Field component
    if (rounds.size > 0) {
      if (!PRESCORE_ROUND_TYPES.includes(oldRoundType)
          && PRESCORE_ROUND_TYPES.includes(newRoundType)) {
        const newRound = updatedRounds.get(roundIndex).withMutations((map) => {
          map.set('draggable', 0);
          map.set('order', 0);
          map.set('roundType', newRoundType);
          map.set('events', 0);
        });

        updatedRounds = updatedRounds.update(roundIndex, () => newRound);
      } else if (!PRESCORE_ROUND_TYPES.includes(newRoundType)) {
        const newRound = updatedRounds.get(roundIndex).withMutations((map) => {
          map.set('draggable', 1);
          map.set('roundType', newRoundType);
        });
        updatedRounds = updatedRounds.update(roundIndex, () => newRound);
      }
    }
    this.handleOrderChange(updatedRounds);
  }

  handleSnackbarOpen = (status) => {
    if (status === 'success') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Removed Successfully');
        }),
      });
    } else {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Remove Failed');
        }),
      });
    }
  }

handleSnackbarClose = () => {
  this.setState({ data: this.state.data.set('snackbarOpen', false) });
}

  handleCloseSavebar = (event, reason) => {
    if (reason === 'timeout') {
      this.setState({ data: this.state.data.set('savebarOpen', false) });
    }
  }

  handleSave = () => {
    const { currentChapter, roundsForm, bulkUpdateRounds } = this.props;

    if (!roundsForm.get('syncErrors')) {
      bulkUpdateRounds({
        items:    roundsForm.getIn(['values', 'rounds']).toJS(),
        chapter:  currentChapter.getIn(['data', 'id']),
        formName: 'roundsForm',
      });
    }
  }

  handleCancel = () => {
    const { reset } = this.props;

    reset();
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('savebarMessage', 'Changes reset.');
        map.set('autoHideDuration', 2000);
        map.set('hideButtons', true);
      }),
    });
  }

  render() {
    const {
      currentChapter,
      reorderForm,
      roundsForm,
      removeRound,
    } = this.props;

    const rounds = (roundsForm.get('values') || Map()).get('rounds') || List();
    const savebarOpen = this.state.data.get('savebarOpen');
    const savebarMessage = this.state.data.get('savebarMessage');
    const hideButtons = this.state.data.get('hideButtons');
    const autoHideDuration = this.state.data.get('autoHideDuration');
    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');

    return (
      <Grid container spacing={ 40 }>
        <FieldArray name='rounds'
          items={ rounds }
          component={ DraggableList }
          reorderForm={ reorderForm }
          handleItemsChange={ this.handleOrderChange }
          listItem={ (
            <RoundItem currentChapter={ currentChapter }
              handleSnackbarOpen={ this.handleSnackbarOpen }
              rounds={ rounds }
              handleRoundChange={ this.handleRoundChange }
              removeRound={ removeRound } />
          ) } />

        <Savebar open={ savebarOpen }
          autoHideDuration={ autoHideDuration }
          form={ roundsForm }
          handleAccept={ this.handleSave }
          handleReject={ this.handleCancel }
          handleClose={ this.handleCloseSavebar }
          hideButtons={ hideButtons }
          message={ savebarMessage } />

        <Snackbar key='remove'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ () => this.handleSnackbarClose() }
          message={ snackbarMessage } />
      </Grid>
    );
  }
}

export default RoundsForm;
