import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove, get } from '../../sources/api/client';
import tagService from '../../sources/api/tagService';
import chapterService from '../../sources/api/chapterService';

// constants
import { TAG_REMOVE, TAG_REMOVE_INIT } from '../../constants/tagTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { tagSchema } from '../../schemas/tag';
import { chapterSchema } from '../../schemas/chapter';

export function* tagRemove(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(TAG_REMOVE, call(remove, tagService, id, options), {
      schema: tagSchema,
    });

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapter), {
      schema: chapterSchema,
    });
  } catch (err) {
    yield put(error(TAG_REMOVE, err));
  } finally {
    yield put(doneIndicator(TAG_REMOVE));
  }
}

export function* watchInviteRemove() {
  yield takeEvery(TAG_REMOVE_INIT, tagRemove);
}

export default function* root() {
  yield all([
    fork(watchInviteRemove),
  ]);
}
