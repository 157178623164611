import { connect } from 'react-redux';
import Immutable, { Map } from 'immutable';
import { reduxForm, isDirty } from 'redux-form/immutable';
import validate from 'validate.js';
import moment from 'moment';

// components
import MemberDateForm from './MemberDateForm';

// schemas
const schema = {
  date: {
    presence: { allowEmpty: false },
    datetime: {
      dateOnly: true,
      latest:   moment(),
      message:  'Date cannot be in the future',
    },
  },
};

const createInitialValues = () => {
  const values = {
    date: moment().format('YYYY-MM-DD'),
  };

  return values;
};

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  dirty:          isDirty('memberInfoForm'),
  initialValues:  Immutable.fromJS({ pnms: createInitialValues() }),
  pnm:            state.get('pnm'),
  memberDateForm: state.getIn(['form', 'memberInfoForm'], Map()),
});

const validateForm = function (form) {
  // Adds the parse and format functions to the validate function
  //  So that we can validate dates more powerfully: https://validatejs.org/#validators-date
  validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse(value) {
      return +moment.utc(value);
    },
    // Input is a unix timestamp
    format(value, options) {
      const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
      return moment.utc(value).format(format);
    },
  });

  const schemaValidation = validate(form.toJS(), schema);

  if (schemaValidation) {
    return schemaValidation;
  }

  return undefined;
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps)(reduxForm({
  form:               'memberDateForm',
  enableReinitialize: true,
  validate:           validateForm,
  onChange:           handleChange,
})(MemberDateForm));
