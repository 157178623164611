import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create, find } from '../../sources/api/client';
import roundPnmService from '../../sources/api/roundPnmService';
import pnmService from '../../sources/api/pnmService';

// constants
import { ROUND_PNM_CREATE, ROUND_PNM_CREATE_INIT } from '../../constants/roundPnmTypes';
import { PNM_FETCH_ALL } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { roundPnmSchema } from '../../schemas/roundPnm';
import { pnmListSchema } from '../../schemas/pnm';

export function* roundPnmCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const values = action.payload;
  const FORM = 'importForm';

  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(ROUND_PNM_CREATE, call(create, roundPnmService, values), {
      schema: roundPnmSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));

    yield* httpSaga(PNM_FETCH_ALL, call(find, pnmService, {
      query: {
        chapter: action.payload.chapter,
        $limit:  25,
      },
    }), {
      schema: pnmListSchema,
    });
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(ROUND_PNM_CREATE, err));
  } finally {
    yield put(doneIndicator(ROUND_PNM_CREATE));
  }
}

export function* watchRoundPnmCreate() {
  yield takeEvery(ROUND_PNM_CREATE_INIT, roundPnmCreate);
}

export default function* root() {
  yield all([
    fork(watchRoundPnmCreate),
  ]);
}
