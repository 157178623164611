import { connect } from 'react-redux';

// components
import AddButton from './AddButton';

// action creators
import { push } from 'react-router-redux';

const mapStateToProps = state => ({
  router: state.get('router'),
  pnm:    state.get('pnm'),
  vote:   state.get('vote'),
});

const mapDispatchToProps = dispatch => ({
  navigate: (route) => {
    dispatch(push(`${route}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddButton);
