import { connect } from 'react-redux';
import { Map } from 'immutable';

// action creators
import { analyticsFetchForChapters, analyticsFetchOverviewData } from '../../../actions/analyticsActions';
import { chapterFetchAll } from '../../../actions/chapterActions';

// components;
import Analytics from './Analytics';

const mapStateToProps = state => ({
  analytics:           state.get('analytics'),
  analyticsForm:       state.getIn(['form', 'analyticsForm'], Map()),
  chapter:             state.get('chapter'),
  compareChaptersForm: state.getIn(['form', 'compareChaptersForm'], Map()),
  currentUser:         state.get('currentUser'),
  field:               state.get('field'),
});

const mapDispatchToProps = dispatch => ({
  fetchChapters: (params) => {
    dispatch(chapterFetchAll(params));
  },
  fetchAnalyticsForChapters: (params) => {
    dispatch(analyticsFetchForChapters(params));
  },
  fetchOverviewData: (params) => {
    dispatch(analyticsFetchOverviewData(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
