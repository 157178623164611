import { schema } from 'normalizr';

// schemas
import { chapterListSchema } from './chapter';

export const userSchema = new schema.Entity('user', {
  chapters: chapterListSchema,
});

export const userSchemaNoChapters = new schema.Entity('user', {
});

export const userListSchema = [userSchema];
export const userListNoChaptersSchema = [userSchemaNoChapters];
