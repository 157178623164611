import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { storeClear } from '../../actions/storeActions';
import { push } from 'react-router-redux';

// api
import client, { remove } from '../../sources/api/client';
import authService from '../../sources/api/authenticationService';

// constants
import { SESSION_DESTROY } from '../../constants/sessionTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* sessionDestroy(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  try {
    const token = window.localStorage.getItem('feathers-jwt');

    yield window.localStorage.removeItem('chapter');

    yield put(push('/login'));
    yield put(storeClear());

    yield* httpSaga(SESSION_DESTROY, call(remove, authService, token));
  } catch (err) {
    yield put(error(SESSION_DESTROY, err));
  } finally {
    yield client.logout();
    yield put(doneIndicator(SESSION_DESTROY));
  }
}

export function* watchSessionDestroy() {
  yield takeEvery(SESSION_DESTROY, sessionDestroy);
}

export default function* root() {
  yield all([
    fork(watchSessionDestroy),
  ]);
}
