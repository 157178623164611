import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';

// local components
import TextInput from '../../../../../TextInput';
import Savebar from '../../../../../Savebar';

class AccountForm extends Component {
  static propTypes = {
    accountForm: PropTypes.instanceOf(Map).isRequired,
    currentUser: PropTypes.instanceOf(Map).isRequired,
    dirty:       PropTypes.bool.isRequired,
    reset:       PropTypes.func.isRequired,
    updateUser:  PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        savebarOpen:      false,
        savebarMessage:   'Save your changes?',
        hideButtons:      false,
        autoHideDuration: null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { dirty, accountForm, reset } = nextProps;
    const savebarOpen = this.state.data.get('savebarOpen');

    if (!savebarOpen) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', dirty);
          map.set('savebarMessage', 'Save your changes?');
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    } else if (accountForm.get('submitSucceeded')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Saved Successfully');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
      reset();
    } else if (accountForm.get('submitFailed')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save Failed');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
      reset();
    }
  }

  handleCloseSavebar = (event, reason) => {
    if (reason === 'timeout') {
      this.setState({ data: this.state.data.set('savebarOpen', false) });
    }
  }

  handleSave = () => {
    const { accountForm, currentUser, updateUser } = this.props;

    if (!accountForm.get('syncErrors')) {
      const updateParams = {
        userId:   currentUser.get('id'),
        formName: 'accountForm',
        ...accountForm.get('values').toJS(),
      };

      updateUser(updateParams);
    }
  }

  handleCancel = () => {
    const { reset } = this.props;

    reset();
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('savebarMessage', 'Changes reset.');
        map.set('autoHideDuration', 2000);
        map.set('hideButtons', true);
      }),
    });
  }

  render() {
    const { accountForm } = this.props;
    const savebarOpen = this.state.data.get('savebarOpen');
    const savebarMessage = this.state.data.get('savebarMessage');
    const hideButtons = this.state.data.get('hideButtons');
    const autoHideDuration = this.state.data.get('autoHideDuration');

    return (
      <Grid container component='form' spacing={ 24 }>
        <Grid item xs={ 12 } sm={ 6 }>
          <Field name='firstname'
            label='First Name'
            type='text'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Field name='lastname'
            label='Last Name'
            type='text'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Field name='email'
            label='Email'
            type='email'
            variant='outlined'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Field name='phone'
            label='Phone Number'
            type='tel'
            variant='outlined'
            component={ TextInput }
            fullWidth />
        </Grid>

        <Savebar open={ savebarOpen }
          autoHideDuration={ autoHideDuration }
          form={ accountForm }
          handleAccept={ this.handleSave }
          handleReject={ this.handleCancel }
          handleClose={ this.handleCloseSavebar }
          hideButtons={ hideButtons }
          message={ savebarMessage } />
      </Grid>
    );
  }
}

export default AccountForm;
