import { schema } from 'normalizr';

// schemas
import { userSchema } from './user';

export const voteSchema = new schema.Entity('vote', {
  author:           userSchema,
  submittedByAdmin: userSchema,
}, {
  idAttribute: '_id',
});

export const voteListSchema = [voteSchema];
