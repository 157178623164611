import {
  USER_CREATE_INIT,
  USER_FETCH_ALL_INIT,
  USER_UPDATE_INIT,
  USER_FETCH_ALL_FOR_CHAPTER_INIT,
  USER_DELETE_INIT,
  USER_BULK_UPDATE_INIT,
  USER_REMOVE_ALL_FOR_CHAPTER_INIT,
} from '../constants/userTypes';

export function userFetchAll(params) {
  return {
    type:    USER_FETCH_ALL_INIT,
    payload: params,
  };
}

export function userCreate(params) {
  return {
    type:    USER_CREATE_INIT,
    payload: params,
  };
}

export function userUpdate({ isCurrentUser = true, destroyForm = false, ...params }) {
  return {
    type:    USER_UPDATE_INIT,
    payload: {
      isCurrentUser,
      destroyForm,
      ...params,
    },
  };
}

export function userBulkUpdate(items, chapter) {
  return {
    type:    USER_BULK_UPDATE_INIT,
    payload: {
      items,
      chapter,
    },
  };
}

export function fetchUsersForChapter({
  chapter, search = null, shouldAddMatchCounts = false, round = null,
}) {
  return {
    type:    USER_FETCH_ALL_FOR_CHAPTER_INIT,
    payload: {
      chapter,
      search,
      shouldAddMatchCounts,
      round,
    },
  };
}

export function userDelete(id, chapter) {
  return {
    type:    USER_DELETE_INIT,
    payload: {
      user: id,
      chapter,
    },
  };
}

export function userRemoveAllForChapter(chapter) {
  return {
    type: USER_REMOVE_ALL_FOR_CHAPTER_INIT,

    payload: {
      chapter,
    },
  };
}
