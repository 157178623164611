import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { fetchUsersForChapter } from '../../../../actions/userActions';
import { fetchAllSpecialties } from '../../../../actions/specialtyActions';
import {
  bulkFetchUserSpecialties,
  createUserSpecialty,
  removeUserSpecialty,
  clearUserSpecialties,
} from '../../../../actions/userSpecialtyActions';

// helpers
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getUsers from '../../../../lib/selectors/getUsers';
import getUserSpecialties from '../../../../lib/selectors/getUserSpecialties';
import getChapterAttribute from '../../../../lib/selectors/getChapterAttribute';
import getUserAttribute from '../../../../lib/selectors/getUserAttribute';
import getSpecialties from '../../../../lib/selectors/getSpecialties';

// components
import SpecialtiesTable from './SpecialtiesTable';

const mapStateToProps = state => ({
  currentRoundId:   getChapterAttribute('currentRound')(state),
  currentChapterId: getChapterAttribute('id')(state),
  currentUserId:    getUserAttribute('id')(state),
  specialties:      getSpecialties(state),
  user:             state.get('user'),
  users:            getUsers(state),
  userSpecialties:  getUserSpecialties(state),
  userLoading:      getLoadingFromState('user')(state),
});

const mapDispatchToProps = {
  createUserSpecialty,
  clearUserSpecialties,
  removeUserSpecialty,
  fetchUsersForChapter,
  fetchAllSpecialties,
  bulkFetchUserSpecialties,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecialtiesTable));
