import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';
import XLSX from 'xlsx';
import { denormalize } from 'normalizr';
import { getVoteScore } from '../../lib/formulas/voteScoreFormulas';
import { isString } from 'validate.js';

// components
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

// local components
import ImportInput from './components/ImportInput';
import ImportTable from './components/ImportTable';
import ImportForm from './components/ImportForm';
import ColumnMatcher from './components/ColumnMatcher';

import { fieldListSchema } from '../../schemas/field';
import { roundListSchema } from '../../schemas/round';
import { organizationSchema } from '../../schemas/organization';

// gets rid of all null and empty rows
const cleanData = function (data) {
  return data.filter((row = [], index) => index > 0 && row.length !== 0 && Boolean(row[0]));
};

class Import extends Component {
  static propTypes = {
    bulkCreateHometownGroupPnms: PropTypes.func.isRequired,
    bulkCreateInvites:           PropTypes.func.isRequired,
    bulkCreateNote:              PropTypes.func.isRequired,
    category:                    PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    classes:                     PropTypes.instanceOf(Object).isRequired,
    createPnm:                   PropTypes.func.isRequired,
    createRoundPnm:              PropTypes.func.isRequired,
    createVote:                  PropTypes.func.isRequired,
    currentChapter:              PropTypes.instanceOf(Map).isRequired,
    currentUser:                 PropTypes.instanceOf(Map).isRequired,
    field:                       PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    hometownGroupPnm:            PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    importForm:                  PropTypes.instanceOf(Map).isRequired,
    invite:                      PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    note:                        PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    organization:                PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    pnm:                         PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    round:                       PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    roundCategory:               PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    roundPnm:                    PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    router:                      PropTypes.instanceOf(Object).isRequired, // eslint-disable-line
    vote:                        PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        cols:                 [], /* Array of column objects e.g. { name: "C", K: 2 } */
        data:                 [], /* Array of Arrays e.g. [["a","b"],[1,2]] */
        dataForColumnMatcher: [],
        importState:          {},
        displayColumnMatcher: false,
      }),
    };

    this.handleFile = this.handleFile.bind(this);
  }

  componentWillMount() {
    this.initImport(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initImport(nextProps);
  }

  getFields = (props) => {
    const {
      field, currentChapter,
    } = props;

    const result = (currentChapter.get('data') || Map()).get('fields') || List();
    const fields = (field.get('data') || Map()).get('items') || List();

    const entities = {
      field: fields.toJS(),
    };

    const items = denormalize(result.toJS(), fieldListSchema, entities);

    return Immutable.fromJS(items);
  }

  getRounds = () => {
    const {
      round, currentChapter,
    } = this.props;

    const result = (currentChapter.get('data') || Map()).get('rounds') || List();
    const rounds = (round.get('data') || Map()).get('items') || List();

    const entities = {
      round: rounds.toJS(),
    };

    const items = denormalize(result.toJS(), roundListSchema, entities);
    return Immutable.fromJS(items);
  }

  getOrganization = () => {
    const {
      currentChapter, organization, roundCategory, category,
    } = this.props;

    const result = (currentChapter.get('data') || Map()).get('organization');

    let element;

    if (result) {
      const entities = {
        organization:  ((organization.get('data') || Map()).get('items') || Map()).toJS(),
        roundCategory: ((roundCategory.get('data') || Map()).get('items') || Map()).toJS(),
        category:      ((category.get('data') || Map()).get('items') || Map()).toJS(),
      };

      element = Immutable.fromJS(denormalize(result, organizationSchema, entities));
    }
    return element;
  }

  getCategories = (organization) => {
    const {
      currentChapter, round,
    } = this.props;

    const roundCategories = organization.get('roundCategories') || List();
    const customCategories = currentChapter.getIn(['data', 'customCategories'], List());
    const currentRoundId = currentChapter.getIn(['data', 'currentRound'], 0);
    const currentRoundType = round.getIn(['data', 'items', currentRoundId, 'roundType'], 0);
    const currentRoundCategory = (roundCategories.filter(r => r.get('roundType') === currentRoundType) || List()).get(0) || Map();

    const oldCategories = currentRoundCategory.get('categories') || List();
    let newCategories = List();

    oldCategories.forEach((category) => {
      let newCategory = category;

      // Look to see if the chapter has made any changes to the category
      const customCategory = (customCategories.filter(c => c.get('_id') === category.get('_id')) || List()).get(0);

      // Use the custom category if one exists
      if (customCategory) {
        newCategory = customCategory;
      }

      // If the category does not have custom options, use the orgs default options
      if (newCategory.get('options').size === 0) {
        newCategory = newCategory.set('options', organization.get('defaultOptions'));
      }

      if (newCategory.get('required') || newCategory.get('enabled')) {
        newCategories = newCategories.push(newCategory);
      }
    });

    return newCategories;
  }

  getImportTitle = (importType) => {
    let title = 'Import';

    switch (importType) {
      case 'pnms':
        title = 'Import PNMs';
        break;
      case 'votes':
        title = 'Import Votes';
        break;
      case 'hometowns':
        title = 'Import Hometown Groups';
        break;
      case 'tags':
        title = 'Import Tags';
        break;
      case 'events':
        title = 'Import Events';
        break;
      case 'users':
        title = 'Import Users';
        break;
      default:
        break;
    }
    return title;
  }

  initImport = (props) => {
    const {
      hometownGroupPnm,
      invite,
      note,
      pnm,
      roundPnm,
      router: { location: { pathname } },
      vote,
    } = props;

    const previousPathname = this.props.router.location.pathname;

    let cols = List();
    let importState = Map();

    const importType = pathname.substring(8);
    const fields = this.getFields(props) || List();
    const organization = this.getOrganization() || Map();
    const categories = this.getCategories(organization) || List();

    switch (importType) {
      case 'pnms':
        importState = pnm;
        cols = Immutable.fromJS([
          { name: 'PNM ID (from council)', key: 'leg_tech_pnm_id', editable: true, width: 175, resizable: true }, // eslint-disable-line
          { name: 'First Name', key: 'firstname', editable: true, width: 150, resizable: true }, // eslint-disable-line
          { name: 'Last Name', key: 'lastname', editable: true, width: 150, resizable: true }, // eslint-disable-line
          { name: 'Email Address', key: 'email', editable: true, width: 150, resizable: true }, // eslint-disable-line
          { name: 'Phone Number', key: 'phone', editable: true, width: 150, resizable: true }, // eslint-disable-line
          { name: 'Status (Abbreviation)', key: 'status', editable: true, width: 150, resizable: true }, // eslint-disable-line
          { name: 'Referral Source', key: 'referral_source', editable: true, width: 150, resizable: true }, // eslint-disable-line
          { name: 'Image Link', key: 'image', editable: true, width: 150, resizable: true }, // eslint-disable-line
          { name: 'Pool (0 or 1)', key: 'cd_pool_id', editable: true, width: 150, resizable: true }, // eslint-disable-line
        ]);

        fields.forEach((field) => {
          cols = cols.push(
            Immutable.fromJS({
              name:            field.getIn(['standardField', 'text']),
              key:             field.getIn(['standardField', 'id']),
              isStandardField: true,
              editable:        true,
              width:           150,
              resizable:       true,
            })
          );
        });
        break;
      case 'votes':
        importState = vote;
        cols = Immutable.fromJS([
          { name: 'PNM ID (from council)', key: 'pnm', editable: true },
        ]);

        // Get all categories for the currentRound
        categories.forEach((c) => {
          cols = cols.push(
            Immutable.fromJS({
              name:      c.get('name'),
              key:       c.get('_id'),
              editable:  true,
              width:     150,
              resizable: true,
            })
          );
        });
        if (!categories.size) {
          cols = cols.push(
            Immutable.fromJS({
              name:      'Score',
              key:       'noCategoryScore',
              editable:  true,
              resizable: true,
            })
          );
        }
        break;
      case 'hometowns':
        importState = hometownGroupPnm;
        cols = Immutable.fromJS([
          { name: 'PNM ID (from council)', key: 'pnm', editable: true },
          { name: 'Hometown Group Name', key: 'hometownGroup', editable: true },
        ]);
        break;
      case 'tags':
        importState = note;
        cols = Immutable.fromJS([
          { name: 'PNM ID (from council)', key: 'pnm', editable: true },
          { name: 'Tag Name', key: 'tag', editable: true },
        ]);
        break;
      case 'events':
        importState = roundPnm;
        cols = Immutable.fromJS([
          { name: 'PNM ID (from council)', key: 'pnm', editable: true },
          { name: 'Round Number', key: 'round', editable: true },
          { name: 'Event Number', key: 'event', editable: true },
        ]);
        break;
      case 'users':
        importState = invite;
        cols = Immutable.fromJS([
          { name: 'First Name', key: 'firstname', editable: true },
          { name: 'Last Name', key: 'lastname', editable: true },
          { name: 'Email Address', key: 'email', editable: true },
        ]);
        break;
      default:
        break;
    }

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('cols', Immutable.fromJS(cols));
        map.set('importState', importState);

        if (previousPathname !== pathname) {
          map.set('data', List());
        }
      }),
    });
  }

  handleColumnMatch = (data) => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('displayColumnMatcher', false);
        map.set('data', Immutable.fromJS(cleanData(data)));
      }),
    });
  }

  handleFile(file) {
    const { router: { location: { pathname } } } = this.props;
    const importType = pathname.substring(8);

    /* Boilerplate to set up FileReader */
    const cols = this.state.data.get('cols');
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      // header decides which row to start with when creating data
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 }).map(
        row => row.map(item => (isString(item) ? item.trim() : item))
      );

      /* Update state */
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('cols', Immutable.fromJS(cols));

          if (importType === 'pnms') {
            // If we're importing pnms, use the column matcher
            map.set('displayColumnMatcher', true);
            map.set('dataForColumnMatcher', Immutable.fromJS(data));
          } else {
            map.set('data', Immutable.fromJS(cleanData(data)));
          }
        }),
      });
    };

    if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
  }

  handleUpload = (rows) => {
    const {
      createPnm,
      createRoundPnm,
      bulkCreateInvites,
      createVote,
      currentChapter,
      bulkCreateHometownGroupPnms,
      bulkCreateNote,
      currentUser,
      importForm,
      router: { location: { pathname } },
      field,
    } = this.props;

    const importType = pathname.substring(8);

    const releasePnms = importForm.getIn(['values', 'releasePnms'], false);
    const currentRound = (currentChapter.get('data') || Map()).get('currentRound');
    const currentEvent = (currentChapter.get('data') || Map()).get('currentEvent');
    const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);
    const cobMode = currentChapter.getIn(['data', 'cobMode'], false);
    const organization = this.getOrganization() || Map();
    const categories = organization.get('_id') ? this.getCategories(organization) || List() : List();
    const rounds = this.getRounds() || List();
    const councilId = isCdSite && !cobMode
      ? 'group_pnm_id'
      : 'leg_tech_pnm_id';

    const chapter = currentChapter.getIn(['data', 'id'], 0);
    const cobModeValue = currentChapter.getIn(['data', 'cobMode']) ? 1 : 0;
    const fields = field.getIn(['data', 'items'], List());
    let allStandardFields = List();

    fields.forEach((f) => { // eslint-disable-line
      allStandardFields = allStandardFields.push(f.get('standardField') || Map());
    }) || List();

    let items;
    let rowsWithFields = List();

    // Status Options for Status Upload validation and a default one
    const statusOptions = ['R', 'A', 'NS', 'WD', 'RG', 'RL', 'NA', 'IN'];

    switch (importType) {
      case 'pnms':
      // Create array of inputted standard fields using all possible fields
        rowsWithFields = rows.map((row) => {
          let inputtedStandardFields = List();

        allStandardFields.forEach((standardField) => { // eslint-disable-line
            const id = standardField.get('id', '0').toString();

            if (row.get(id)) {
              const type = standardField.get('type');

              inputtedStandardFields = inputtedStandardFields.push(
                Immutable.fromJS({
                  id,
                  value: row.get(id),
                  type,
                })
              );
            }
          }) || List();
          return row.set('standardFields', inputtedStandardFields);
        }).toJS();

        items = rowsWithFields.map(row => (
          {
            ...row,
            chapter,
            cob: cobModeValue,
            ...(statusOptions.includes(row.status && row.status.toLowerCase())
              ? { status: row.status } : {}),
          }));

        createPnm({
          bulkCreate: true,
          chapter,
          items,
        });
        break;

      case 'votes':
        items = rows.map((row) => {
          let categoriesArray = List(); // eslint-disable-line
          row.keySeq().forEach((key) => {
            if (key !== 'pnm') {
              categoriesArray = categoriesArray.push(
                Immutable.fromJS({ category: key, value: parseFloat(row.get(key)) })
              );
            }
          });

          const standardValues = {
            [councilId]: row.get('pnm'), // we convert this to the real PNM id on the backend
            author:      currentUser.getIn(['data', 'id']),
            chapter,
            round:       currentRound,
            event:       currentEvent,
          };

          const hasCategories = !(categoriesArray.getIn([0, 'category'], '') === 'noCategoryScore');
          const voteValues = !hasCategories ? categoriesArray.getIn([0, 'value'], 0) : categoriesArray;

          const value = getVoteScore(
            organization, voteValues, categories, currentChapter, currentRound, rounds
          );

          if (hasCategories) {
            standardValues.categories = voteValues;
          }

          return { ...standardValues, value };
        });

        createVote({
          bulkCreate: true,
          chapter,
          items,
        });
        break;

      case 'hometowns':
        items = rows.map(row => ({ ...row.toJS(), chapter })).toJS();

        bulkCreateHometownGroupPnms({
          import: true,
          chapter,
          items,
        });

        break;

      case 'tags':
        items = rows.map(row => ({
          pnm:    row.get('pnm'),
          chapter,
          author: currentUser.getIn(['data', 'id'], 0),
          tag:    row.get('tag'),
        })).toJS();

        bulkCreateNote({
          import: true,
          chapter,
          items,
        });

        break;

      case 'events':
        items = rows.map(row => ({
          [councilId]: row.get('pnm'),
          event:       row.get('event'),
          round:       row.get('round'),
          chapter,
        })).toJS();

        createRoundPnm({
          releasePnms,
          chapter,
          items,
        });
        break;

      case 'users':
        items = rows.map(row => ({ ...row.toJS(), teamLevel: 0, chapter })).toJS();

        bulkCreateInvites({
          items,
          chapter,
        });
        break;

      default:
        break;
    }
  }

  renderMessage = (importType) => {
    const { currentChapter, round, roundPnm } = this.props;
    const currentRound = (currentChapter.get('data') || Map()).get('currentRound');
    const isVotingOpen = (currentChapter.get('data') || Map()).get('isVotingOpen');

    let currentRoundName;
    const currentRoundExists = Map.isMap(round.getIn(['data', 'items'], List())
      .find(r => r.get('_id') === currentRound));

    let message;
    const idsNotFound = roundPnm.getIn(['data', 'idsNotFound'], List());

    switch (importType) {
      case 'pnms':
        break;
      case 'votes':
        currentRoundName = round.getIn(['data', 'items', currentRound.toString(), 'name'], '');

        message = (
          <Grid item xs>
            <Typography color='textSecondary' variant='subtitle1' gutterBottom>
              Round:
              { currentRoundName }
            </Typography>

            { currentRoundName.toLowerCase().includes('yardstick')
              && (
              <Typography color='secondary'>
                Yardstick must be completed for ALL PNMs
                including grade risks, non-legacies, un-referred PNMs, etc.
              </Typography>
              )}

            <Typography color='textSecondary' gutterBottom>
              Having trouble uploading? Make sure every cell in your sheet contains data!
            </Typography>

            <Typography color='secondary'>
              Note: Cells may not be empty.
            </Typography>

          </Grid>
        );

        if (!currentRound || !currentRoundExists || !isVotingOpen) {
          message = (
            <Typography color='error'>
              Please set a round from the home page in order to import.
            </Typography>
          );
        }
        break;
      case 'hometowns':
      case 'tags':
        break;
      case 'events':
        if (idsNotFound.size && roundPnm.get('status') === 'success' && !roundPnm.get('loading')) {
          message = (
            <Typography color='error' align='center'>
              Heads up! PNM IDs
              {' '}
              { idsNotFound.join(', ') }
              {' '}
              were not found.
              Please add them to MyVote in order to import their events.
            </Typography>
          );
        }
        break;
      case 'users':
        break;
      default:
        break;
    }
    return message;
  }

  render() {
    const {
      classes, router: { location: { pathname } }, currentChapter, round,
    } = this.props;
    const data = this.state.data.get('data');
    const cols = this.state.data.get('cols');
    const importState = this.state.data.get('importState');

    const importType = pathname.substring(8);

    return (
      <Card className={ classes.importCard }>
        <Grid container spacing={ 8 }>
          <Grid item xs={ 12 } align='center' className={ classes.titleContainer }>
            <Icon className={ classes.titleIcon } color='primary'>cloud_upload</Icon>
            <Typography variant='h5' color='primary'>
              { this.getImportTitle(importType) }
            </Typography>
          </Grid>

          <Grid item xs={ 12 } align='center'>
            <div className={ classes.outlinedContainer }>
              <Typography variant='subtitle1' color='primary'>
                1. Format spreadsheet in Excel for the columns below
              </Typography>
              <Typography>* The first row of the uploaded spreadsheet will be ommited</Typography>
              <Typography>
                * The columns of the uploaded spreadsheet should match the columns below
              </Typography>
              { importType === 'tags' && [
                <Typography>
                  * To add multiple tags to a single PNM, put
                  each tag on a new row using the same ID
                </Typography>,
                <Typography>
                  * The capitalization of uploaded tags must
                  mach existing tags, otherwise new tags will be created
                </Typography>,
              ]}
            </div>
          </Grid>

          <Grid item xs={ 12 } align='center'>
            <div className={ classes.outlinedContainer }>
              <ImportInput handleFile={ this.handleFile } />
            </div>
          </Grid>

          { importType === 'events'
            && (
            <Grid item xs={ 12 } align='center'>
              <div className={ classes.outlinedContainer }>
                <ImportForm />
              </div>
            </Grid>
            )}

          <Grid item xs={ 12 } align='center'>
            { this.renderMessage(importType) }
          </Grid>

          <Grid item xs={ 12 } align='center'>
            <ImportTable rows={ data }
              cols={ cols }
              pathname={ pathname }
              round={ round }
              onUpload={ this.handleUpload }
              currentChapter={ currentChapter }
              importState={ importState } />
          </Grid>
        </Grid>

        <ColumnMatcher open={ this.state.data.get('displayColumnMatcher') }
          columns={ this.state.data.get('cols') }
          data={ this.state.data.get('dataForColumnMatcher') }
          onSave={ this.handleColumnMatch }
          onCancel={ () => { this.setState({ data: this.state.data.set('displayColumnMatcher', false) }); } } />
      </Card>
    );
  }
}
const styles = () => ({
  importCard: {
    borderRadius: 10,
  },

  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      35,
  },

  titleContainer: {
    marginTop: 10,
  },

  outlinedContainer: {
    padding:      15,
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    width:        '60%',
    marginBottom: 5,
  },
});

export default withStyles(styles)(Import);
