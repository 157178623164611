import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// local components
import SettingsTabBar from './components/SettingsTabBar';

import Account from './components/Account';
import Organization from './components/Organization';
import Chapter from './components/Chapter';
import Rounds from './components/Rounds';
import Categories from './components/Categories';
import Fields from './components/Fields';
import Files from './components/Files';
import ConcernCategories from './components/ConcernCategories';
import HometownGroups from './components/HometownGroups';
import Users from './components/Users';
import Tags from './components/Tags';
import BumpGroups from './components/BumpGroups';
import Export from './components/Export';

// helpers
import getUserRole from '../../lib/selectors/getUserRole';

class Settings extends Component {
  static propTypes = {
    classes:           PropTypes.instanceOf(Object).isRequired,
    currentChapter:    PropTypes.instanceOf(Map).isRequired,
    currentUser:       PropTypes.instanceOf(Map).isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    router:            PropTypes.instanceOf(Object).isRequired,
  }

  componentWillMount() {
    const {
      currentUser, fetchOrganization,
    } = this.props;

    const organizationId = currentUser.getIn(['data', 'organization'], 0);

    if (organizationId) {
      fetchOrganization({ organizationId });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentUser, fetchOrganization,
    } = nextProps;

    const organizationId = currentUser.getIn(['data', 'organization'], 0);
    const oldOrgId = this.props.currentUser.getIn(['data', 'organization'], 0);

    if (!oldOrgId && organizationId) {
      fetchOrganization({ organizationId });
    }
  }

  renderAdminComponent = (RouteComponent) => {
    const {
      classes, currentChapter, currentUser,
    } = this.props;

    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);
    const isConcernChairUser = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';

    const componentProps = {
      parentclasses: classes,
      teamLevel,
      clearanceLevel,
    };

    return function handleRedirect() {
      let element = <Redirect to='/settings/account' />;

      if (
        clearanceLevel > 0
        || teamLevel > 0
        || isConcernChairUser
      ) {
        element = <RouteComponent { ...componentProps } />;
      }

      return element;
    };
  }

  render() {
    const {
      classes,
      currentChapter,
      currentUser,
      router: { location: { pathname } },
    } = this.props;

    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);
    const showAdvancedMatching = currentChapter.getIn(['data', 'showAdvancedMatching'], false);
    const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);

    let backgroundHiderClass = classes.backgroundHider;

    if (!currentChapter.get('data')) {
      backgroundHiderClass = classes.backgroundHiderFullWith;
    }

    return (
      <div style={ { position: 'relative' } }>
        <div className={ backgroundHiderClass } />

        <Grid container spacing={ 16 } justify='center'>
          <Grid item xs={ 12 }>
            <SettingsTabBar { ...{ clearanceLevel, teamLevel } } />
          </Grid>
        </Grid>

        <Route path='/settings/account' render={ () => <Account parentclasses={ classes } /> } key='account' />

        {pathname.includes('dashboard') && [
          <Route path='/dashboard/settings/account' render={ this.renderAdminComponent(Account) } key='account' />,
          <Route path='/dashboard/settings/organization' render={ this.renderAdminComponent(Organization) } key='organization' />,
          <Route path='/dashboard/settings/concern-categories' render={ this.renderAdminComponent(ConcernCategories) } key='concern-categories' />,
        ]}

        <Route path='/settings/chapter' render={ this.renderAdminComponent(Chapter) } key='chapter' />
        <Route path='/settings/rounds' render={ this.renderAdminComponent(Rounds) } key='rounds' />
        <Route path='/settings/categories' render={ this.renderAdminComponent(Categories) } key='categories' />
        <Route path='/settings/fields' render={ this.renderAdminComponent(Fields) } key='fields' />
        {isCdSite && <Route path='/settings/files' render={ this.renderAdminComponent(Files) } key='files' />}
        <Route path='/settings/hometowns' render={ this.renderAdminComponent(HometownGroups) } key='hometowns' />
        <Route path='/settings/users' render={ this.renderAdminComponent(Users) } key='users' />
        <Route path='/settings/tags' render={ this.renderAdminComponent(Tags) } key='tags' />

        {
          showAdvancedMatching && (
          <Route path='/settings/bumpGroups'
            render={ this.renderAdminComponent(BumpGroups) }
            key='bumpGroups' />
          )
        }
        <Route path='/settings/export' render={ this.renderAdminComponent(Export) } key='export' />
      </div>
    );
  }
}

const styles = theme => ({
  backgroundHiderFullWith: {
    position:        'fixed',
    zIndex:          2,
    backgroundColor: '#eaeaea',
    height:          40,
    top:             60,
    marginLeft:      -5,
    marginRight:     -5,

    [theme.breakpoints.up('lg')]: {
      right: 30,
      left:  30,
    },

    [theme.breakpoints.between('sm', 'md')]: {
      right: 30,
      left:  30,
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  backgroundHider: {
    position:        'fixed',
    zIndex:          2,
    backgroundColor: '#eaeaea',
    height:          50,
    top:             60,
    marginLeft:      -5,
    marginRight:     -5,

    [theme.breakpoints.up('lg')]: {
      right: 30,
      left:  355,
    },

    [theme.breakpoints.between('sm', 'md')]: {
      right: 30,
      left:  30,
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  // Styles to pass to tabs:
  tabsCard: {
    height:       'auto',
    marginTop:    20,
    paddingTop:   20,
    borderRadius: 10,

    [theme.breakpoints.down('sm')]: {
      paddingLeft:  10,
      paddingRight: 10,
      marginBottom: 20,
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft:  25,
      paddingRight: 30,
      marginBottom: 25,
    },
  },

  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      35,
  },

  titleContainer: {
    marginTop:     20,
    display:       'flex',
    flexDirection: 'row',
  },

  topButton: {
    marginTop: 10,

    [theme.breakpoints.down('xs')]: {
      marginLeft: 40,
    },
  },
});

export default withStyles(styles)(Settings);
