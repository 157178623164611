import React from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';

// mui components
import Grid from '@material-ui/core/Grid';

// local components
import ModalBox from '../../../ModalBox';
import SvgIcon from '../../../SvgIcon';
import CheckboxInput from '../../../CheckboxInput';
import SwitchInput from '../../../SwitchInput';
import RoundsFields from '../RoundsFields';
import CategoriesFields from '../CategoriesFields';
import SearchCustomFields from '../SearchCustomFields';

// icons
import SettingsModalIcon from '../../../../assets/images/settings-modal.svg';

// helpers
import getAllCategoriesFromListAddData from '../../helpers/getAllCategoriesFromListAddData';
import isOverallScoreVisible from '../../helpers/isOverallScoreVisible';
import { isEmpty } from '../../../../helpers/check';

// styles
import modalAdvancedOptionsStyles from './modalAdvancedOptions.style';

class ModalAdvancedOptions extends React.Component {
  static propTypes = {
    classes:                     PropTypes.instanceOf(Object).isRequired,
    currentChapter:              PropTypes.instanceOf(Map).isRequired,
    formName:                    PropTypes.string.isRequired,
    dirty:                       PropTypes.bool.isRequired,
    advancedDisplayOptionForm:   PropTypes.instanceOf(Map).isRequired,
    setOpenModalAdvancedOptions: PropTypes.func.isRequired,
    rounds:                      PropTypes.instanceOf(Immutable.Iterable).isRequired,
    organization:                PropTypes.instanceOf(Map).isRequired,
    updateUser:                  PropTypes.func.isRequired,
    currentUserId:               PropTypes.number.isRequired,
    reset:                       PropTypes.func.isRequired,
    fields:                      PropTypes.instanceOf(List).isRequired,
    change:                      PropTypes.func.isRequired,
    clearSelectedFields:         PropTypes.func.isRequired,
    open:                        PropTypes.bool,
  }

  static defaultProps = {
    open: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        errorMessage: '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      advancedDisplayOptionForm,
      reset,
      setOpenModalAdvancedOptions,
    } = nextProps;

    if (advancedDisplayOptionForm.get('submitSucceeded')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', '');
        }),
      });
      setOpenModalAdvancedOptions(false);
    } else if (advancedDisplayOptionForm.get('submitFailed')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', 'Save Failed');
        }),
      });
    }
  }

  getValues = () => {
    const { advancedDisplayOptionForm } = this.props;

    return !advancedDisplayOptionForm.get('syncErrors') ? advancedDisplayOptionForm.get('values', Map()) : Map();
  };

  getDisplayOverallScore = () => {
    const { currentChapter, organization } = this.props;

    return isOverallScoreVisible(currentChapter, organization);
  };

  handleSubmitAdvancedOptions = () => {
    const {
      updateUser,
      currentUserId,
      formName,
      advancedDisplayOptionForm,
    } = this.props;
    const {
      includeGPA1 = false,
      includeGPA2 = false,
      includePnmTags = false,
      includePnmImages = false,
      includePnmStatus = false,
      displayOverallScores = false,
      displayRoundScores = false,
      displayCategoryScores = false,
      displayRoundScoresList = {},
      displayCategoryScoresList = {},
      selectedField = [],
    } = this.getValues().toJS() || {};

    if (!advancedDisplayOptionForm.get('syncErrors')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', '');
        }),
      });
      updateUser({
        userId:                currentUserId,
        formName,
        advancedDisplayOption: {
          includeGPA1,
          includeGPA2,
          includePnmTags,
          includePnmImages,
          includePnmStatus,
          displayOverallScores,
          displayRoundScores,
          displayCategoryScores,
          displayRoundScoresList,
          displayCategoryScoresList,
          selectedField,
          wasAdvancedDisplayOptionChanged: true,
        },
      });
    }
  }

  handleCloseModal = () => {
    const {
      setOpenModalAdvancedOptions,
      reset,
    } = this.props;
    setOpenModalAdvancedOptions(false);
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('errorMessage', '');
      }),
    });
    reset();
  };

  renderRoundsBox = () => {
    const {
      rounds,
    } = this.props;
    if (rounds.size > 4) {
      const midlSize = Math.round(rounds.size / 2);
      return (
        <>
          <Grid item xs={ 12 } md={ 6 }>
            <Grid container>
              <RoundsFields rounds={ rounds.slice(0, midlSize) } />
            </Grid>
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <Grid container>
              <RoundsFields rounds={ rounds.slice(midlSize) } />
            </Grid>
          </Grid>
        </>
      );
    }

    return <RoundsFields rounds={ rounds } />;
  }

  renderCategoriesBox = () => {
    const { organization } = this.props;
    const categories = getAllCategoriesFromListAddData(organization);
    if (categories.size > 4) {
      const midlSize = Math.round(categories.size / 2);
      return (
        <>
          <Grid item xs={ 12 } md={ 6 }>
            <Grid container>
              <CategoriesFields categories={ categories.slice(0, midlSize) } />
            </Grid>
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <Grid container>
              <CategoriesFields categories={ categories.slice(midlSize) } />
            </Grid>
          </Grid>
        </>
      );
    }

    return <CategoriesFields categories={ categories } />;
  }

  render() {
    const {
      classes,
      open,
      dirty,
      advancedDisplayOptionForm,
      fields,
      change,
      clearSelectedFields,
    } = this.props;

    const {
      displayRoundScores,
      displayCategoryScores,
    } = this.getValues().toJS() || {};

    const isDisplayOverallScoreDisabled = !this.getDisplayOverallScore();
    const isSubmitDisabled = !dirty || advancedDisplayOptionForm.get('syncErrors');
    const loading = !!advancedDisplayOptionForm.get('submitting');
    const errorMessage = this.state.data.get('errorMessage', '');

    return (
      <ModalBox open={ !!open }
        title='Advanced Display Options'
        subtitle='Select the data you want to display in your results table'
        icon={ (<SvgIcon viewBox='0 0 39 30' src={ SettingsModalIcon } />) }
        onAccept={ this.handleSubmitAdvancedOptions }
        onReject={ this.handleCloseModal }
        headerAlignItems='center'
        acceptLabel='Save'
        rejectLabel='Cancel'
        acceptButtonProps={ {
          disabled: !!isSubmitDisabled,
        } }
        loading={ loading }>
        <Grid container className={ classes.root }>
          <Grid item xs={ 12 } sm={ 7 }>
            <div className={ classes.gridHeader }>
              Score Display Options
            </div>
            <Grid container>
              <Grid item xs={ 12 }>
                <Field name='displayOverallScores'
                  type='checkbox'
                  label='Display Overall Scores'
                  disabled={ isDisplayOverallScoreDisabled }
                  containerClassName={ classes.switchInput }
                  component={ SwitchInput } />
              </Grid>
              <Grid item xs={ 12 }>
                <Field name='displayRoundScores'
                  type='checkbox'
                  label='Display Round Scores'
                  containerClassName={ classes.switchInput }
                  component={ SwitchInput } />
                <div className={ `${classes.subContent} ${displayRoundScores ? '' : 'disabled'}` }>
                  <Grid container>
                    {this.renderRoundsBox()}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={ 12 }>
                <Field name='displayCategoryScores'
                  type='checkbox'
                  label='Display Category Scores'
                  containerClassName={ classes.switchInput }
                  component={ SwitchInput } />
                <div className={ `${classes.subContent} ${displayCategoryScores ? '' : 'disabled'}` }>
                  <Grid container>
                    {this.renderCategoriesBox()}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 } sm={ 5 }>
            <div className={ classes.gridHeader }>
              PNM Display Options
            </div>
            <Grid container>
              <Grid item xs={ 12 }>
                <Field name='includeGPA1'
                  label='Include GPA 1'
                  component={ CheckboxInput } />
              </Grid>
              <Grid item xs={ 12 }>
                <Field name='includeGPA2'
                  label='Include GPA 2'
                  component={ CheckboxInput } />
              </Grid>
              <Grid item xs={ 12 }>
                <Field name='includePnmImages'
                  label='Include PNM Images'
                  component={ CheckboxInput } />
              </Grid>
              <Grid item xs={ 12 }>
                <Field name='includePnmTags'
                  label='Include PNM Tags'
                  component={ CheckboxInput } />
              </Grid>
              <Grid item xs={ 12 }>
                <Field name='includePnmStatus'
                  label='Include PNM Status'
                  component={ CheckboxInput } />
              </Grid>
              <SearchCustomFields fields={ fields }
                advancedDisplayOptionForm={ advancedDisplayOptionForm }
                clearSelectedFields={ clearSelectedFields }
                change={ change } />
            </Grid>
          </Grid>
          {!isEmpty(errorMessage) && (
            <Grid item xs={ 12 } className={ classes.errorMessage }>
              {errorMessage}
            </Grid>
          )}
        </Grid>
      </ModalBox>
    );
  }
}

export default withStyles(modalAdvancedOptionsStyles)(ModalAdvancedOptions);
