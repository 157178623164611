import client from './client';

export default (function OrganizationService() {
  let service;

  if (!service) {
    service = client.service('organizations');
  }

  return service;
}());
