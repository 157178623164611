import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create } from '../../sources/api/client';
import concernCategoryService from '../../sources/api/concernCategoryService';

// constants
import { CONCERN_CATEGORY_CREATE, CONCERN_CATEGORY_CREATE_INIT } from '../../constants/concernCategoryTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { concernCategorySchema } from '../../schemas/concernCategory';

export function* concernCategoryCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const values = action.payload || {};

  try {
    yield* httpSaga(CONCERN_CATEGORY_CREATE, call(create, concernCategoryService, values), {
      schema: concernCategorySchema,
    });
  } catch (err) {
    yield put(error(CONCERN_CATEGORY_CREATE, err));
  } finally {
    yield put(doneIndicator(CONCERN_CATEGORY_CREATE));
  }
}

export function* watchConcernCategoryCreate() {
  yield takeEvery(CONCERN_CATEGORY_CREATE_INIT, concernCategoryCreate);
}

export default function* root() {
  yield all([
    fork(watchConcernCategoryCreate),
  ]);
}
