import { Map } from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import { Field } from 'redux-form/immutable';
import TextInput from '../../TextInput';
import Button from '../../Button';
import { Link } from 'react-router-dom';

// styles
import '../ForgotPassword.scss';

const handleKeyDown = function (e, cb) {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault();
    cb();
  }
};

class ForgotPasswordEmailForm extends Component {
  static propTypes = {
    forgotPasswordEmailForm: PropTypes.instanceOf(Map).isRequired,
    createForgotPassword:    PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Map({
        displaySuccessMessage: false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { forgotPasswordEmailForm } = nextProps;

    if (forgotPasswordEmailForm.get('submitSucceeded')) {
      this.setState({ data: this.state.data.set('displaySuccessMessage', true) });
    }
  }

  handleSubmit = () => {
    const { forgotPasswordEmailForm, createForgotPassword } = this.props;

    if (!forgotPasswordEmailForm.get('syncErrors')) {
      createForgotPassword(forgotPasswordEmailForm.get('values').toJS());
    }
  }

  renderErrorMessage = () => {
    const { forgotPasswordEmailForm } = this.props;

    let element;

    if (forgotPasswordEmailForm.get('submitFailed')) {
      element = (
        <div className='forgot-password__error-container'>
          <FormHelperText error>Failed to send email, please try again</FormHelperText>
        </div>
      );
    }

    return element;
  }

  renderSuccessMessage = () => {
    let element;

    if (this.state.data.get('displaySuccessMessage')) {
      element = (
        <Grid item xs={ 12 } key='success-message'>
          <Typography>Reset password email sent</Typography>
        </Grid>
      );
    }

    return element;
  }

  render() {
    const { forgotPasswordEmailForm } = this.props;

    let disableButton = false;

    if (forgotPasswordEmailForm.get('syncErrors')) { disableButton = true; }

    return [
      <Grid item xs={ 12 } key='message'>
        <Typography>Enter your email and we will send you a link to reset your password</Typography>
      </Grid>,

      <Grid item xs={ 12 } component='form' key='form' onKeyDown={ (e) => { handleKeyDown(e, this.handleSubmit); } }>
        <Grid container spacing={ 24 }>
          <Grid item xs={ 12 }>
            <div className='forgot-password-form__input-container'>
              <Icon className='forgot-password-form__icon' color='primary'>mail_outline</Icon>
              <Field errorClassName='forgot-password-form__error'
                InputProps={ { disableUnderline: true } }
                name='email'
                type='email'
                component={ TextInput }
                placeholder='Enter Email'
                required
                fullWidth />
            </div>
          </Grid>
        </Grid>
      </Grid>,

      this.renderSuccessMessage(),

      <Grid xs={ 12 } item key='button'>
        <div className='forgot-password__button-container'>
          { this.renderErrorMessage() }

          <Button className='forgot-password__button'
            color='primary'
            onClick={ this.handleSubmit }
            disabled={ disableButton }
            loading={ forgotPasswordEmailForm.get('submitting') }
            variant='contained'>
            Send Reset Password Email
          </Button>
        </div>
      </Grid>,

      <Grid item key='link'>
        <Link className='forgot-password__link' to='/login' href='/login'>
          <Typography variant='button' color='primary'>Login</Typography>
        </Link>
      </Grid>,
    ];
  }
}

export default ForgotPasswordEmailForm;
