import { schema } from 'normalizr';

// schemas
import { userSchema } from './user';

export const noteSchema = new schema.Entity('note', {
  author: userSchema,
}, {
  idAttribute: '_id',
});

export const noteListSchema = [noteSchema];
