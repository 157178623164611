import React from 'react';
import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';

// mui components
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

class EnhancedTableHead extends React.Component {
  static propTypes = {
    numCols:      PropTypes.number.isRequired,
    onSortChange: PropTypes.func.isRequired,
    order:        PropTypes.string.isRequired,
    sortBy:       PropTypes.string.isRequired,
    round:        PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        columns: [],
      }),
    };
  }

  componentWillMount() {
    const { round } = this.props;

    const events = round.get('events', 0);

    let newColumns = Immutable.fromJS([
      {
        id:        'member',
        align:     'right',
        label:     'Member',
        allowSort: true,
      },
      {
        id:        'matchRecs',
        align:     'right',
        label:     'Match Recs',
        allowSort: true,
      },
      {
        id:        'matches',
        align:     'right',
        label:     'Matches For Round',
        allowSort: true,
      },
    ]);

    for (let index = 0; index < events; index += 1) {
      newColumns = newColumns.push(Immutable.fromJS({
        id:        `event_${index + 1}`,
        align:     'right',
        label:     `Event ${index + 1}`,
        allowSort: false,
      }));
    }

    this.setState({ data: this.state.data.set('columns', newColumns) });
  }

  componentWillReceiveProps(nextProps) {
    const { round } = this.props;
    const nextRound = nextProps.round;

    const events = round.get('events', 5);

    if (nextRound && round !== nextRound) {
      let newColumns = Immutable.fromJS([
        {
          id:        'firstName',
          align:     'left',
          label:     'First Name',
          allowSort: true,
        },
        {
          id:        'lastName',
          align:     'left',
          label:     'Last Name',
          allowSort: true,
        },
        {
          id:        'matchRecs',
          align:     'left',
          label:     'Match Recs',
          allowSort: true,
        },
        {
          id:        'matches',
          align:     'left',
          label:     'Matches',
          allowSort: true,
        },
      ]);

      for (let index = 0; index < events; index += 1) {
        newColumns = newColumns.push(Immutable.fromJS({
          id:        `event_${index + 1}`,
          align:     'left',
          label:     `Event ${index + 1}`,
          allowSort: false,
        }));
      }

      this.setState({ data: this.state.data.set('columns', newColumns) });
    }
  }

  getPadding = (column) => {
    let padding;
    padding = 'dense';

    if (column.get('id') === 'matchRecs' || column.get('id') === 'matches') {
      padding = 'none';
    }
    return padding;
  }

  handleRequestSort = (event, property) => {
    this.props.onSortChange(property);
  };

  renderColumnHeader = (column) => {
    const { sortBy, order } = this.props;

    let element = column.get('label');

    if (column.get('allowSort')) {
      element = (
        <Tooltip title='Sort'
          placement={ column.get('numeric') ? 'bottom-end' : 'bottom-start' }
          enterDelay={ 300 }>
          <TableSortLabel active={ sortBy === column.get('id') }
            direction={ order }
            onClick={ e => this.handleRequestSort(e, column.get('id')) }>
            {column.get('label')}
          </TableSortLabel>
        </Tooltip>
      );
    }

    return element;
  }

  render() {
    const {
      numCols, sortBy, order,
    } = this.props;

    const columns = this.state.data.get('columns');

    return (
      <TableHead>
        <TableRow colSpan={ numCols }>
          {columns.map(column => (
            <TableCell key={ column.get('id') }
              align={ column.get('align') }
              padding={ this.getPadding(column) }
              sortDirection={ sortBy === column.get('id') ? order : false }>

              { this.renderColumnHeader(column) }
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
