import { Map } from 'immutable';
import AddChapterModal from './AddChapterModal';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';

// action creators
import { chapterCreate } from '../../../../../actions/chapterActions';
import { locationOrgFetchAll, locationOrgClear } from '../../../../../actions/locationOrgActions';
import { locationSchoolFetchAll, locationSchoolClear } from '../../../../../actions/locationSchoolActions';

const schema = {
  org: {
    presence: true,
  },

  school: {
    presence: true,
  },
};

const mapStateToProps = state => ({
  locationOrg:    state.get('locationOrg'),
  locationSchool: state.get('locationSchool'),
  chapter:        state.get('chapter'),
  addChapterForm: state.get('form').get('addChapterForm') || Map(),
});

const mapDispatchToProps = dispatch => ({
  fetchLocationOrgs: (params) => {
    dispatch(locationOrgFetchAll(params));
  },

  fetchLocationSchools: (params) => {
    dispatch(locationSchoolFetchAll(params));
  },

  clearLocationOrgs: () => {
    dispatch(locationOrgClear());
  },

  clearLocationSchools: () => {
    dispatch(locationSchoolClear());
  },

  createChapter: (params) => {
    dispatch(chapterCreate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:     'addChapterForm',
  validate: validateForm,
  onChange: handleChange,
})(AddChapterModal));
