import { connect } from 'react-redux';

// action creators
import { drawerClose } from '../../../../actions/drawerActions';
import { push } from 'react-router-redux';

// components
import ListItem from './ListItem';

const mapDispatchToProps = dispatch => ({
  navigateToPNM: (id) => {
    dispatch(drawerClose());
    dispatch(push(`/pnm/${id}`));
  },
  navigateToUser: (id) => {
    dispatch(drawerClose());
    dispatch(push(`/match/user/${id}`));
  },
  navigateToBumpGroup: (id) => {
    dispatch(drawerClose());
    dispatch(push(`/match/bumpgroup/${id}`));
  },
});

export default connect(null, mapDispatchToProps)(ListItem);
