import Immutable, { Map } from 'immutable';
import { normalize } from 'normalizr';

// constants
import { CURRENT_CHAPTER_FETCH } from '../constants/chapterTypes';
import {
  FIELD_CREATE, FIELD_REMOVE, FIELD_BULK_UPDATE, FIELD_BULK_CREATE,
} from '../constants/fieldTypes';
import { add, remove } from '../lib/normalizedReducerHelpers';

// lib
import httpReducer from '../lib/httpReducer';

// schema
import { fieldListSchema } from '../schemas/field';

// Fields are indexed with integers when a CD site hasn't saved their fields yet,
//  but every app state has items indexed with Mongo IDs, so once the fields are created
//  we replace the state to get a clean slate
function replaceState() {
  return (map, action) => {
    const data = ((action || {}).payload || {}).data || [];

    const normalized = normalize(data, fieldListSchema);
    const items = ((normalized || {}).entities || {}).field;
    const result = (normalized || {}).result || [];

    const newData = (map.get('data') || Map()).withMutations((m) => {
      m.set('items', Immutable.fromJS(items));
      m.set('result', Immutable.fromJS(result));
    });

    return map.set('data', newData);
  };
}

export default (state = Map(), action) => {
  switch (action.type) {
    case FIELD_BULK_UPDATE: return httpReducer(state, action, { entity: 'field', updateResult: false });
    case FIELD_BULK_CREATE: return httpReducer(state, action, { entity: 'field', cb: replaceState() });
    case FIELD_REMOVE: return httpReducer(state, action, { entity: 'field', cb: remove() });
    case FIELD_CREATE: return httpReducer(state, action, { entity: 'field', cb: add() });
    case CURRENT_CHAPTER_FETCH: return httpReducer(state, action, { entity: 'field', updateResult: false });
    default: return state;
  }
};
