import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form/immutable';

// components
import FieldItem from './FieldItem';

const mapStateToProps = state => ({
  form:       state.get('form'),
  submitting: isSubmitting('fieldsForm')(state),
});

export default connect(mapStateToProps)(FieldItem);
