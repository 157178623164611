import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import { denormalize } from 'normalizr';

// MUI components
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// local components
import TableActions from './components/TableActions';
import EnhancedTableHead from './components/EnhancedTableHead';
import TableToolbar from './components/TableToolbar';
import EnhancedTableRow from './components/EnhancedTableRow';
import RowOptionsMenu from './components/RowOptionsMenu';

// schemas
import { chapterListSchema } from '../../../../../schemas/chapter';

const clearSiteOptions = Immutable.fromJS([
  {
    title: 'Entire Site Options',
    mode:  'all',
  },
  {
    title: 'Standard Site Options',
    mode:  'standard',
  },
  {
    title: 'COB Site Options',
    mode:  'cob',
  },
]);

class ChaptersTable extends Component {
  static propTypes = {
    chapter:                     PropTypes.instanceOf(Map).isRequired,
    classes:                     PropTypes.instanceOf(Object).isRequired,
    currentUser:                 PropTypes.instanceOf(Map).isRequired,
    deleteMatch:                 PropTypes.func.isRequired,
    deleteNotes:                 PropTypes.func.isRequired,
    deletePnms:                  PropTypes.func.isRequired,
    deleteVotes:                 PropTypes.func.isRequired,
    fetchChapters:               PropTypes.func.isRequired,
    fetchCurrentChapterForAdmin: PropTypes.func.isRequired,
    match:                       PropTypes.instanceOf(Map).isRequired,
    note:                        PropTypes.instanceOf(Map).isRequired,
    onPaginationChange:          PropTypes.func.isRequired,
    onSortChange:                PropTypes.func.isRequired,
    order:                       PropTypes.number.isRequired,
    pnm:                         PropTypes.instanceOf(Map).isRequired,
    search:                      PropTypes.string,
    sortBy:                      PropTypes.string.isRequired,
    updateChapter:               PropTypes.func.isRequired,
    vote:                        PropTypes.instanceOf(Map).isRequired,
  }

  static defaultProps = {
    search: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        anchorEl:        null,
        chapterIndex:    0,
        chapterToClear:  null,
        isChapterCdSite: 1,
        order:           'asc',
        orderBy:         'org',
        rowOptionsOpen:  false,
      }),
    };
  }

  getFetchParams = () => {
    const { sortBy, order, search } = this.props;
    const chapter = this.props.chapter.get('data') || Map();

    const limit = chapter.get('limit') || 10;
    const skip = chapter.get('skip') || 0;

    const params = {
      sort: {},
      limit,
      skip,
    };

    if (search) {
      params.search = search;
    }

    params.sort[sortBy] = order;

    return params;
  }

  getNumberOfColumns = () => {
    const { currentUser } = this.props;

    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;

    if (clearanceLevel === 2) { // TP Admin column count
      return 12;
    } if (clearanceLevel === 1) { // HQ Admin column count
      return 7;
    }

    return 3; // Chapter user column count
  }

  getPaginationParams = (newParams) => {
    const chapter = this.props.chapter.get('data') || Map();

    const paginationParams = {
      skip:  chapter.get('skip'),
      limit: chapter.get('limit'),
      ...newParams,
    };

    if (newParams.limit) {
      paginationParams.skip = 0;
    }

    return paginationParams;
  }

  getChapters = () => {
    const { chapter, currentUser } = this.props;

    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;
    const chapterData = chapter.get('data') || Map();

    let elements = [];
    let result;

    if (clearanceLevel > 0) {
      result = chapterData.get('result');
    } else {
      result = currentUser.getIn(['data', 'chapters'], Immutable.List());
    }

    if (result) {
      const entities = { chapter: (chapterData.get('items') || Immutable.List()).toJS() };

      elements = denormalize(result.toJS(), chapterListSchema, entities);
    }

    return elements;
  }

  initChapters = () => {
    const { fetchChapters } = this.props;

    fetchChapters(this.getFetchParams());
  }

  handleChangeRowsPerPage = (event) => {
    const { onPaginationChange } = this.props;

    onPaginationChange(this.getPaginationParams({
      limit: event.target.value,
    }));
  };

  handleRowOptionsOpen = (event, chapterId, isChapterCdSite) => {
    event.preventDefault();

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('rowOptionsOpen', true);
        map.set('anchorEl', event.currentTarget);
        map.set('chapterIndex', chapterId);
        map.set('isChapterCdSite', isChapterCdSite);
      }),
    });
  }

  handleRowOptionsClose = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('rowOptionsOpen', false);
        map.set('anchorEl', null);
      }),
    });
  }

  handleChangePage = (event, page) => {
    const { onPaginationChange, chapter } = this.props;

    const limit = (chapter.get('data') || Map()).get('limit');
    const skip = page * limit;

    onPaginationChange(this.getPaginationParams({ skip }));
  };

  handleRowClick = (chapterId) => {
    const { currentUser, fetchCurrentChapterForAdmin } = this.props;
    const userId = (currentUser.get('data') || Map()).get('id');

    fetchCurrentChapterForAdmin({ chapterId, userId });
  }

  handleOpenMenu = (event, mode) => {
    event.preventDefault();

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set(`${mode}MenuOpen`, true);
        map.set('subAnchorEl', event.currentTarget);
      }),
    });
  }

  handleCloseMenu = (mode) => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set(`${mode}MenuOpen`, false);
        map.set('subAnchorEl', null);
      }),
    });
  }

  renderRowOptions = () => {
    const {
      pnm, vote, match, note, chapter,
      deletePnms, deleteNotes, deleteMatch, deleteVotes, updateChapter,
    } = this.props;
    const chapterId = this.state.data.get('chapterIndex');
    const isChapterCdSite = this.state.data.get('isChapterCdSite');
    const rowOptionsOpen = this.state.data.get('rowOptionsOpen');
    const anchorEl = this.state.data.get('anchorEl');

    return (
      <Popover open={ rowOptionsOpen }
        key={ `${chapterId}_popover` }
        onClose={ this.handleRowOptionsClose }
        anchorEl={ anchorEl }
        anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
        transformOrigin={ {
          vertical:   'top',
          horizontal: 'center',
        } }>

        <List>
          { clearSiteOptions.map(item =>
            (
              <RowOptionsMenu key={ item.get('title') }
                chapter={ chapter }
                chapterId={ chapterId }
                deleteMatch={ deleteMatch }
                deleteNotes={ deleteNotes }
                deletePnms={ deletePnms }
                deleteVotes={ deleteVotes }
                initChapters={ this.initChapters }
                isChapterCdSite={ isChapterCdSite }
                item={ item }
                match={ match }
                note={ note }
                pnm={ pnm }
                updateChapter={ updateChapter }
                vote={ vote } />
            ))}
        </List>
      </Popover>
    );
  }

  renderLoadingIndicator = () => {
    const { classes, chapter } = this.props;

    const loading = (chapter.get('loading') && !chapter.get('data')) || false;
    let element;

    if (loading) {
      element = (
        <TableRow>
          <TableCell colSpan={ this.getNumberOfColumns() } className={ classes.spinner }>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    return element;
  }

  render() {
    const {
      chapter,
      classes,
      currentUser,
      onSortChange,
      order,
      sortBy,
    } = this.props;

    const chapters = this.getChapters();
    const chapterData = chapter.get('data') || Map();
    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;

    return (
      <div>
        <Paper className={ classes.root }>
          { this.renderRowOptions() }

          <TableToolbar icon='account_balance'
            chapters={ chapters }
            subtitle={ clearanceLevel > 0 ? 'Manage accounts and visit chapters' : 'Select chapter to view' }
            title='Chapters' />

          <div className={ classes.tableWrapper }>

            <Table className={ classes.table }>
              <EnhancedTableHead numCols={ this.getNumberOfColumns() }
                clearanceLevel={ clearanceLevel }
                order={ order }
                sortBy={ sortBy }
                onSortChange={ onSortChange }
                rowCount={ chapters.length || 0 } />

              <TableBody>

                { this.renderLoadingIndicator() }

                { (chapters.length === 0 && !chapter.get('loading')) ? (
                  <TableRow>
                    <TableCell colSpan={ 1 } padding='dense'>
                      <Icon>error_outline</Icon>
                    </TableCell>
                    <TableCell colSpan={ this.getNumberOfColumns() - 1 } padding='none'>
                      <Typography variant='subtitle1'>No Chapters Found</Typography>
                    </TableCell>
                  </TableRow>
                ) : chapters.map(c => (
                  <EnhancedTableRow key={ c.id }
                    onClick={ () => this.handleRowClick(c.id) }
                    numCols={ this.getNumberOfColumns() }
                    handleRowOptionsOpen={ this.handleRowOptionsOpen }
                    clearanceLevel={ clearanceLevel }
                    chapter={ c } />
                ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination count={ chapterData.get('total') || 0 }
                    rowsPerPage={ chapterData.get('limit') || 10 }
                    page={ chapterData.get('skip') / chapterData.get('limit') || 0 }
                    onChangePage={ this.handleChangePage }
                    onChangeRowsPerPage={ this.handleChangeRowsPerPage }
                    ActionsComponent={ TableActions } />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width:        '100%',
    marginTop:    theme.spacing.unit,
    height:       'auto',
    overflowY:    'scroll',
    borderRadius: 10,
  },

  table: {
    minWidth: 500,
  },

  tableWrapper: {
    overflowX: 'visible',
  },

  spinner: {
    textAlign: 'center',
  },
});

export default withStyles(styles)(ChaptersTable);
