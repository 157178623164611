import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// action creators
import { sessionVerify } from '../actions/sessionActions';

const authenticated = function (WrappedComponent) {
  const mapDispatchToProps = dispatch => ({
    verifySession: () => {
      dispatch(sessionVerify());
    },
  });

  return connect(null, mapDispatchToProps)(class extends Component {
    static propTypes = {
      verifySession: PropTypes.func.isRequired,
    };

    componentWillMount() {
      const { verifySession } = this.props;

      verifySession();
    }

    render() {
      return (
        <WrappedComponent { ...this.props } />
      );
    }
  });
};

export default authenticated;
