import {
  isEmpty,
  isString,
  isNumber,
  isArray,
} from './check';

export const toFloat = (value) => {
  if (isEmpty(value)) {
    return 0;
  }
  if (isString(value)) {
    return parseFloat(value ? value.replace(/,/, '.').replace(/[^\d-.]/g, '') : '0');
  }

  return isNumber(value) ? parseFloat(value) : 0;
};

export const toInt10 = (value) => {
  if (isEmpty(value)) {
    return 0;
  }

  return isNumber(parseInt(value, 10)) ? parseInt(value, 10) : 0;
};

export const toBool = value => (isEmpty(value) ? false : !!value);

export const toString = value => (isEmpty(value) ? '' : value.toString());

// eslint-disable-next-line no-nested-ternary
export const toArray = value => (isEmpty(value) ? [] : isArray(value) ? value : [value]);

// eslint-disable-next-line no-nested-ternary,max-len
export const toObject = value => (isEmpty(value) ? {} : isString(value) ? JSON.parse(value) : value);

export const toLowerCase = value => (isEmpty(value) ? '' : value.toLowerCase());

export const arrayToString = (list, separator = ', ') => toArray(list).join(separator);

export const arrayToStringWithoutEmpty = (list, separator = ', ') =>
  toArray(list).filter(item => !isEmpty(item)).join(separator);

export const stringToNull = value => (value === '' ? null : value);

export const stringToArrayWithoutEmpty = (value, separator = ',') =>
  toArray(toString(value).split(separator)).filter(item => item !== '');
