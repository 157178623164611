import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import 'rc-slider/assets/index.css';
import Slider, { createSliderWithTooltip } from 'rc-slider';

const SliderWithTooltip = createSliderWithTooltip(Slider);

// components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

class VoteSlider extends Component {
  static propTypes = {
    defaultValue:    PropTypes.number.isRequired,
    id:              PropTypes.string.isRequired,
    optionIncrement: PropTypes.number.isRequired,
    input:           PropTypes.instanceOf(Object).isRequired,
    maxValue:        PropTypes.number.isRequired,
    minValue:        PropTypes.number.isRequired,
    theme:           PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        sliderValue: 0,
      }),
    };
  }

  componentWillMount() {
    const { defaultValue } = this.props;

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('sliderValue', defaultValue);
      }),
    });
  }

  handleSliderChange = (value) => {
    const { input } = this.props;
    input.onChange(parseFloat(value));
    this.setState({ data: this.state.data.set('sliderValue', value) });
  }

  render() {
    const {
      optionIncrement,
      id,
      minValue,
      maxValue,
      theme,
    } = this.props;

    return (
      <Grid id={ id } item xs>
        <SliderWithTooltip defaultValue={ maxValue - optionIncrement }
          min={ minValue }
          max={ maxValue }
          step={ optionIncrement }
          dots
          trackStyle={ { backgroundColor: theme.palette.primary.light, height: 10 } }
          handleStyle={ {
            borderColor:     theme.palette.primary.light,
            height:          28,
            width:           28,
            marginLeft:      -14,
            marginTop:       -9,
            backgroundColor: '#fff',
          } }
          railStyle={ { backgroundColor: theme.palette.primary.dark, height: 10 } }
          dotStyle={ {
            height: 10,
            width:  10,
            bottom: -6,
          } }
          onChange={ value => this.handleSliderChange(value) } />
      </Grid>
    );
  }
}

const styles = theme => ({

  handle: {
    borderColor:     'blue',
    height:          28,
    width:           28,
    marginLeft:      -14,
    marginTop:       -9,
    backgroundColor: 'black',
  },
  inputRange: {
    background: theme.palette.secondary.main,
  },

  slider: { // From react-input-range's styles. Use classNames with ids to override fully
    background: theme.palette.secondary.main,
    border:     `1px solid ${theme.palette.secondary.main}`,
  },
  activeTrack: {
    background: theme.palette.secondary.main,
  },

});

export default withStyles(styles, { withTheme: true })(VoteSlider);
