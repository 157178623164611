import { schema } from 'normalizr';

// schemas
import { userSchema } from './user';

export const matchSchema = new schema.Entity('match', {
  author: userSchema,
  user:   userSchema,
}, {
  idAttribute: '_id',
});

export const matchListSchema = [matchSchema];
