import Immutable, { List, Map } from 'immutable';
import React, { useState, useEffect, useCallback } from 'react';
import validate from 'validate.js';
import deepEqual from 'fast-deep-equal';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';

// MUI components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// components
import Button from '../../../Button';
import SelectInput from '../../../SelectInput';
import Savebar from '../../../Savebar';
import TextInput from '../../../TextInput';
import CheckboxInput from '../../../CheckboxInput';
import AutoSuggestFinalForm from '../../../AutoSuggestFinalForm';
import WeightItem from './components/WeightItem';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

const schema = {
  selectedPnms: {
    presence: true,
  },
  selectedRound: {
    presence: true,
  },
  selectedItems: {
    presence: true,
  },
  selectedEvent: {
    presence: true,
  },
  matchesPerMemberEvent: {
    numericality: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
};

function AutoMatchTab({
  autoMatch,
  classes,
  cobMode,
  currentChapterId,
  currentChapterLoading,
  currentChapterStatus,
  currentRound,
  fetchUsers,
  hometowns,
  isCdSite,
  matchLoading,
  matchSettings,
  matchStatus,
  rounds,
  specialties,
  suggestions,
  updateChapter,
  userLoading,
}) {
  const [autoHideDuration, setAutoHideDuration] = useState(null);
  const [autoMatchCompleted, setAutoMatchCompleted] = useState(false);
  const [currentChapterWasLoading, setCurrentChapterWasLoading] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [matchWasLoading, setMatchWasLoading] = useState(false);
  const [savebarMessage, setSavebarMessage] = useState('Save match settings?');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (currentChapterWasLoading && !currentChapterLoading && currentChapterStatus === 'success') {
      setSnackbarOpen(true);
      setSnackbarMessage('Saved successfully');
    } else if (currentChapterWasLoading && !currentChapterLoading && currentChapterStatus === 'error') {
      setSnackbarOpen(true);
      setSnackbarMessage('Save failed');
    } else {
      if (currentChapterLoading) {
        setCurrentChapterWasLoading(true);
      }
      setSavebarMessage('Save match settings?');
      setHideButtons(false);
      setAutoHideDuration(null);
    }
  }, [currentChapterLoading, currentChapterStatus]);

  useEffect(() => {
    if (matchWasLoading && !matchLoading && matchStatus === 'success') {
      setAutoMatchCompleted(true);
    } else if (matchLoading) {
      setMatchWasLoading(true);
    }
  }, [matchLoading]);

  const getInitialValues = useCallback(() => {
    const defaultWeights = [{
      key:     'matchRecs',
      enabled: true,
      weight:  10,
    }];

    specialties.forEach((s) => {
      defaultWeights.push({
        key:     s.get('_id'),
        enabled: false,
        weight:  5,
      });
    });

    const defaultValues = {
      selectedRound:         currentRound,
      selectedPnms:          'active',
      matchesPerMemberEvent: 1,
      selectedEvent:         0,
      selectedHometown:      0,
      selectedItems:         'users',
      checkPreviousMatches:  true,
      weights:               defaultWeights,
      excludedUsers:         [],
    };

    const values = matchSettings.size ? matchSettings.toJS() : defaultValues;

    return values;
  }, [matchSettings]);

  const handleUpdateSuggestions = (value) => {
    fetchUsers({ search: value, chapter: currentChapterId });
  };

  const handleCancel = (reset) => {
    setSnackbarOpen(true);
    setSnackbarMessage('Changes reset.');
    setAutoHideDuration(2000);

    reset();
  };

  const updateMatchSettings = (values) => {
    updateChapter({ chapterId: currentChapterId, matchSettings: values });
  };

  const getRoundOptions = () => {
    const options = [];

    rounds.forEach((round) => {
      const roundNotClosed =  !round.get('roundClosed') > 0 && isCdSite && !cobMode;
      const roundHasNoScheduledPnms = !round.get('hasScheduledPnms')
        && (!isCdSite || (isCdSite && cobMode));

      const label = roundNotClosed || roundHasNoScheduledPnms
        ? `${round.get('name')} (No Events Scheduled)`
        : round.get('name');

      options.push({
        label,
        value: round.get('_id'),
      });
    });

    return options;
  };

  const getHometownOptions = () => {
    const options = [];

    hometowns.forEach((round) => {
      options.push({
        label: round.get('name'),
        value: round.get('id'),
      });
    });

    return options;
  };

  const getEventOptions = (numEvents) => {
    const options = [];

    options.push({
      label: 'All',
      value: 0,
    });

    for (let index = 0; index < numEvents; index += 1) {
      options.push({
        label: `${index + 1}`,
        value: index + 1,
      });
    }

    return options;
  };

  const getPnmOptions = () => [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Legacies',
      value: 'legacies',
    },
  ];

  const getUserOptions = () => [
    {
      label: 'Individual Voting Members',
      value: 'users',
    },
    {
      label: 'Bump Groups',
      value: 'bumpGroups',
    },
  ];

  const handleAutoMatch = (values) => {
    autoMatch({
      chapter: currentChapterId,
      ...values,
    });
  };

  const renderFilters = (values, pristine, numEvents) => {
    const hometownHeroSpecialtyId = (specialties.find(s => s.get('key') === 'hometownHero') || Map()).get('_id');
    const roadWarriorSpecialtyId = (specialties.find(s => s.get('key') === 'roadWarrior') || Map()).get('_id');
    const matchTo = values.get('selectedItems') === 'users'
      ? 'Member'
      : 'Bump Group';

    const displayHometownSelector = values.get('weights', List())
      .filter(w =>
        (w.get('key') === hometownHeroSpecialtyId && w.get('enabled'))
        || (w.get('key') === roadWarriorSpecialtyId && w.get('enabled'))).size > 0;

    return (
      <Grid container spacing={ 24 }>
        <Grid item xs={ 12 }>
          <Typography color='textSecondary' variant='body2' gutterBottom>Options</Typography>
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='selectedItems'
            options={ getUserOptions() }
            component={ SelectInput }
            label='Match To'
            variant='outlined'
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='selectedPnms'
            options={ getPnmOptions() }
            component={ SelectInput }
            label='PNMs'
            variant='outlined'
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='selectedRound'
            options={ getRoundOptions() }
            component={ SelectInput }
            label='Round'
            variant='outlined'
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='selectedEvent'
            options={ getEventOptions(numEvents) }
            component={ SelectInput }
            label='Event'
            variant='outlined'
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='matchesPerMemberEvent'
            component={ TextInput }
            type='number'
            label={ `Max Matches Per ${matchTo} Per Event` }
            variant='outlined'
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='checkPreviousMatches'
            component={ CheckboxInput }
            label='Do not match members to PNMs that they were matched to in another round'
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='selectedHometown'
            options={ getHometownOptions() }
            component={ SelectInput }
            disabled={ !displayHometownSelector }
            label='Hometown of Campus'
            helpertext={ displayHometownSelector
              ? 'Influences Road Warrior & Hometown Hero matching'
              : 'Enable Road Warrior or Hometown Hero matching to set' }
            variant='outlined'
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='checkVotedOn'
            component={ CheckboxInput }
            label='Do not match members to PNMs that they have voted on'
            required />
        </Grid>

        <Grid item xs={ 6 }>
          <Field name='excludedUsers'
            render={ props =>
              (
                <AutoSuggestFinalForm displayAllSuggestionsOnFocus
                  formValues={ values }
                  label='Search Members To Exclude From Matching'
                  helperText='Exclusions can include floaters,
                executive board members who do not actively recruit,
                users who are absent for the round,
                or any user who does not pick a PNM up at the door'
                  loading={ userLoading }
                  onSearchChange={ handleUpdateSuggestions }
                  required
                  multiSelect
                  defaultSelectedItems={ values.get('excludedUsers', List()) || List() }
                  pristine={ pristine }
                  suggestions={ suggestions }
                  variant='outlined'
                  circularProgressProps={ { disableShrink: true } }
                  { ...props } />
              ) } />

        </Grid>
      </Grid>
    );
  };

  const renderWeightItems = (fields, values) => fields.map((field, index) => {
    const item = values.getIn(['weights', index], Map());
    const itemSpecialty = specialties.find(s => s.get('_id') === item.get('key')) || Map();

    const prevItem = index !== 0
      ? values.getIn(['weights', index - 1], Map())
      : item;
    const prevItemSpecialty = specialties.find(s => s.get('_id') === prevItem.get('key')) || Map();

    return [
      itemSpecialty.get('category') !== prevItemSpecialty.get('category') && index !== 1 && [
        <Grid item xs={ 12 } key='skillTitle'>
          <Typography variant='subtitle2'
            className={ classes.weightsTitle }>
            Skills
          </Typography>
        </Grid>,
        <Grid item xs={ 12 } key='skillSubtitle'>
          <Typography color='textSecondary'
            variant='body2'
            className={ classes.weightsSubtitle }>
            Leverage your power recruiters by enabling and weighing these qualities
          </Typography>
        </Grid>,
      ],
      index === 1 && [
        <Grid item xs={ 12 } key='expTitle'>
          <Typography variant='subtitle2'
            className={ classes.weightsTitle }>
            Shared Experiences
          </Typography>
        </Grid>,
        <Grid item xs={ 12 } key='expSubtitle'>
          <Typography color='textSecondary'
            variant='body2'
            className={ classes.weightsSubtitle }>
            Emphasize personal connections via info about each PNM
            by enabling and weighing these qualities
          </Typography>
        </Grid>,
      ],
      <Grid item xs={ 12 } sm={ 6 } key={ item.get('key', index) }>
        <WeightItem field={ field }
          item={ item }
          parentClasses={ {
            outlinedContainer: classes.outlinedContainer,
          } }
          specialties={ specialties } />
      </Grid>,
    ];
  });

  return (
    <Form validate={ values => validate(values, schema) }
      mutators={ { ...arrayMutators } }
      initialValues={ getInitialValues() }
      initialValuesEqual={ deepEqual }
      onSubmit={ handleAutoMatch }
      render={ ({
        handleSubmit,
        invalid,
        pristine,
        values,
        form: {
          reset,
        },
      }) => {
        const valuesMap = Immutable.fromJS(values);
        const selectedRoundId = valuesMap.get('selectedRound', '');
        const selectedRound = rounds.find(r => r.get('_id', '') === selectedRoundId) || Map();

        const numEvents = selectedRound.get('events', 0);
        const selectedRoundNotClosed = !selectedRound.get('roundClosed') > 0 && isCdSite && !cobMode;

        const selectedRoundHasNoScheduledPnms = !selectedRound.get('hasScheduledPnms')
        && (!isCdSite || (isCdSite && cobMode));

        const noWeightsEnabled = !valuesMap.get('weights', List())
          .filter(w => w.get('enabled')).size;
        const disabled = noWeightsEnabled
        || (selectedRoundNotClosed || selectedRoundHasNoScheduledPnms);

        return (
          <Card className={ classes.card }>
            <CardContent>
              <Grid container>
                <Grid item xs={ 12 } className={ classes.titleContainer }>
                  <Icon className={ classes.titleIcon } color='primary'>autorenew</Icon>
                  <Typography variant='h5' color='primary'>
                    Auto Matching
                  </Typography>
                </Grid>

                <Grid item xs={ 12 } align='left' className={ classes.subtitleContainer }>
                  <Typography variant='subtitle1' color='textSecondary'>
                    Generate intentional matches
                    for your voting members by adjusting weights and options
                  </Typography>
                </Grid>

                <Grid item xs={ 12 }>
                  <div className={ classes.outlinedContainer }>
                    { renderFilters(valuesMap, pristine, numEvents) }
                  </div>
                </Grid>

                <Grid item xs={ 12 }>
                  <Typography variant='h6'
                    className={ classes.weightsTitle }>
                    Weights
                  </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                  <Typography color='textSecondary'
                    variant='body2'
                    className={ classes.weightsSubtitle }>
                    Check items to enable them,
                    and adjust weights to decide their likelihood of generating a match
                  </Typography>
                </Grid>

                <FieldArray name='weights'>
                  {({ fields }) => renderWeightItems(fields, valuesMap)}
                </FieldArray>

                <Grid item xs={ 12 } className={ classes.buttonContainer }>
                  <Button variant='contained'
                    color='primary'
                    onClick={ handleSubmit }
                    loading={ matchLoading }
                    disabled={ disabled || invalid }>
                    Generate Matches
                  </Button>

                  { autoMatchCompleted && (
                  <Typography className={ classes.greenText }>
                    Generated successfully! Check out your matches under Match Results
                  </Typography>
                  )}

                  { matchStatus === 'error' && (
                  <div>
                    <Typography className={ classes.redText }>
                      Generating matches failed.
                    </Typography>
                    <Typography className={ classes.redText }>
                      Either the selected options were too strict or matching took too long.
                      Check your options and try again.
                    </Typography>
                  </div>

                  )}

                  { (selectedRoundNotClosed || selectedRoundHasNoScheduledPnms) && (
                  <Typography className={ classes.redText }>
                    The selected round has no scheduled events
                  </Typography>
                  )}

                  { noWeightsEnabled && (
                  <Typography className={ classes.redText }>
                    Please enable at least one item in order to generate matches
                  </Typography>
                  )}
                </Grid>

              </Grid>
            </CardContent>

            <Savebar open={ !pristine }
              autoHideDuration={ autoHideDuration }
              form={ Map({ syncErrors: invalid, submitting: currentChapterLoading }) }
              handleAccept={ () => updateMatchSettings(values) }
              handleReject={ () => handleCancel(reset) }
              hideButtons={ hideButtons }
              message={ savebarMessage } />

            <Snackbar key='remove'
              anchorOrigin={ {
                vertical:   'bottom',
                horizontal: 'right',
              } }
              open={ snackbarOpen }
              autoHideDuration={ 3000 }
              onClose={ () => setSnackbarOpen(false) }
              message={ snackbarMessage } />
          </Card>
        );
      } } />
  );
}

AutoMatchTab.propTypes = {
  autoMatch:             PropTypes.func.isRequired,
  classes:               PropTypes.instanceOf(Object).isRequired,
  matchSettings:         PropTypes.instanceOf(Map).isRequired,
  cobMode:               PropTypes.bool.isRequired,
  currentChapterId:      PropTypes.number.isRequired,
  currentChapterLoading: PropTypes.bool.isRequired,
  currentChapterStatus:  PropTypes.string.isRequired,
  currentRound:          PropTypes.string.isRequired,
  fetchUsers:            PropTypes.func.isRequired,
  updateChapter:         PropTypes.func.isRequired,
  hometowns:             PropTypes.instanceOf(List).isRequired,
  isCdSite:              PropTypes.bool.isRequired,
  matchLoading:          PropTypes.bool.isRequired,
  matchStatus:           PropTypes.string.isRequired,
  rounds:                PropTypes.instanceOf(List).isRequired,
  specialties:           PropTypes.instanceOf(List).isRequired,
  suggestions:           PropTypes.instanceOf(Array).isRequired,
  userLoading:           PropTypes.bool.isRequired,
};

const styles = theme => ({
  card: {
    height:       'auto',
    marginTop:    20,
    borderRadius: 10,

    [theme.breakpoints.down('sm')]: {
      paddingLeft:  10,
      paddingRight: 10,
      marginBottom: 20,
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft:  25,
      paddingRight: 30,
      marginBottom: 25,
    },
  },

  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      35,
  },

  titleContainer: {
    marginTop:     20,
    display:       'flex',
    flexDirection: 'row',
  },

  subtitleContainer: {
    marginTop:    10,
    marginBottom: 5,
  },

  weightsTitle: {
    paddingLeft: 10,
    paddingTop:  20,
  },
  weightsSubtitle: {
    paddingLeft: 10,
  },

  outlinedContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       10,
    padding:      20,
  },

  redText: {
    color:     'red',
    marginTop: 20,
  },

  greenText: {
    color:     'lightseagreen',
    marginTop: 20,
  },

  buttonContainer: {
    textAlign: 'center',
    margin:    20,
  },
});

export default withStyles(styles)(AutoMatchTab);
