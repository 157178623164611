// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/chevron.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup{align-items:center;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-color:#4285f4;display:flex;flex-grow:1}.signup__container{padding:40px}.signup__logo{max-height:60px}.signup__button-container{align-items:center;display:flex;flex-direction:column;justify-content:flex-end;min-height:75px}.signup__button{margin-top:15px}.signup__link{text-decoration:none;text-transform:uppercase}.signup-form{align-items:center;display:flex;flex-direction:column;width:100%}.signup-form__input-container{border-radius:30px;box-shadow:0 6px 20px 0 rgba(157,157,157,.7);margin:0 auto;max-width:300px;padding:5px 20px 5px 40px;position:relative}.signup-form__icon{left:9px;position:absolute;top:9px}.signup-form__error{bottom:-17px;position:absolute;white-space:nowrap}.signup-form__password-container{margin-top:25px}.signup-form__input-spinner{position:absolute;right:10px;top:10px}.signup-form__error-container{margin-bottom:10px}input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active{transition:background-color 5000s ease-in-out 0s}`, "",{"version":3,"sources":["webpack://./src/components/Signup/Signup.scss","webpack://./src/assets/stylesheets/variables.scss"],"names":[],"mappings":"AAIA,QACE,kBAAA,CACA,kDAAA,CACA,wBCLiB,CDMjB,YAAA,CACA,WAAA,CAGF,mBACE,YAAA,CAGF,cACE,eAAA,CAGF,0BACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,eAAA,CAGF,gBACE,eAAA,CAGF,cACE,oBAAA,CACA,wBAAA,CAGF,aACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CAGF,8BACE,kBAAA,CACA,4CA5CwB,CA6CxB,aAAA,CACA,eAAA,CACA,yBAAA,CACA,iBAAA,CAGF,mBACE,QAAA,CACA,iBAAA,CACA,OAAA,CAGF,oBACE,YAAA,CACA,iBAAA,CACA,kBAAA,CAGF,iCACE,eAAA,CAGF,4BACE,iBAAA,CACA,UAAA,CACA,QAAA,CAGF,8BACE,kBAAA,CAIF,+GAII,gDAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
