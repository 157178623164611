import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { denormalize } from 'normalizr';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '../../../../Button';
import Autosuggest from '../../../../Autosuggest';
import { Field } from 'redux-form/immutable';

// schemas
import { locationOrgListSchema } from '../../../../../schemas/locationOrg';
import { locationSchoolListSchema } from '../../../../../schemas/locationSchool';

class AddChapterModal extends Component {
  static propTypes = {
    classes:              PropTypes.instanceOf(Object).isRequired,
    onClose:              PropTypes.func.isRequired,
    open:                 PropTypes.bool.isRequired,
    fetchLocationSchools: PropTypes.func.isRequired,
    fetchLocationOrgs:    PropTypes.func.isRequired,
    clearLocationSchools: PropTypes.func.isRequired,
    clearLocationOrgs:    PropTypes.func.isRequired,
    createChapter:        PropTypes.func.isRequired,
    locationOrg:          PropTypes.instanceOf(Map).isRequired,
    locationSchool:       PropTypes.instanceOf(Map).isRequired,
    addChapterForm:       PropTypes.instanceOf(Map).isRequired,
    chapter:              PropTypes.instanceOf(Map).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        showSnackbar: false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { addChapterForm } = nextProps;
    const showSnackbar = this.state.data.get('showSnackbar');

    if (addChapterForm.get('submitFailed') && !showSnackbar) {
      this.setState({ data: this.state.data.set('showSnackbar', true) });
    } else if (showSnackbar && addChapterForm.get('submitting')) {
      this.setState({ data: this.state.data.set('showSnackbar', false) });
    }
  }

  getLocationOrgSuggestions = () => {
    const { locationOrg } = this.props;

    let elements = [];

    const locationOrgData = locationOrg.get('data') || Map();

    if (locationOrgData.get('result')) {
      const result = (locationOrgData.get('result') || Immutable.List()).toJS();
      const entities = { locationOrg: (locationOrgData.get('items') || Immutable.List()).toJS() };

      elements = denormalize(result, locationOrgListSchema, entities).map(o => ({
        label: o.org,
        value: o.id,
      }));
    }

    return elements;
  }

  getLocationSchoolSuggestions = () => {
    const { locationSchool } = this.props;

    let elements = [];

    const locationSchoolData = locationSchool.get('data') || Map();

    if (locationSchoolData.get('result')) {
      const result = (locationSchoolData.get('result') || Immutable.List()).toJS();
      const entities = { locationSchool: (locationSchoolData.get('items') || Immutable.List()).toJS() };

      elements = denormalize(result, locationSchoolListSchema, entities).map(s => ({
        label: s.school,
        value: s.id,
      }));
    }

    return elements;
  }

  handleUpdateLocationOrgSuggestions = ({ value }) => {
    const { fetchLocationOrgs } = this.props;

    if (value) {
      fetchLocationOrgs({ search: value });
    }
  }

  handleUpdateLocationSchoolSuggestions = ({ value }) => {
    const { fetchLocationSchools } = this.props;

    if (value) {
      fetchLocationSchools({ search: value });
    }
  }

  handleClearLocationOrgSuggestions = () => {
    const { clearLocationOrgs } = this.props;

    clearLocationOrgs();
  }

  handleClearLocationSchoolSuggestions = () => {
    const { clearLocationSchools } = this.props;

    clearLocationSchools();
  }

  handleSubmit = () => {
    const { addChapterForm, createChapter } = this.props;

    const values = (addChapterForm.get('values') || Map());
    const locationOrg = (values.get('org') || {}).value;
    const locationSchool = (values.get('school') || {}).value;

    if (!addChapterForm.get('syncErrors')) {
      createChapter({ locationOrg, locationSchool });
    }
  }

  render() {
    const {
      classes, onClose, open, addChapterForm, locationOrg, locationSchool, chapter,
    } = this.props;

    let disableButton = false;

    if (addChapterForm.get('syncErrors')) { disableButton = true; }

    return (
      <Dialog open={ open }
        onClose={ onClose }>
        <div className={ classes.header }>
          <Typography variant='h3'>Create New Chapter</Typography>
        </div>

        <DialogContent>
          <Grid container spacing={ 8 }>
            <Grid item xs={ 12 } className={ classes.inputContainer }>
              <Field name='org'
                loading={ locationOrg.get('loading') }
                label='Organization'
                helperText=' Search for organizations'
                component={ Autosuggest }
                suggestions={ this.getLocationOrgSuggestions() }
                onSuggestionsFetchRequested={ this.handleUpdateLocationOrgSuggestions }
                onSuggestionsClearRequested={ this.handleClearLocationOrgSuggestions } />
            </Grid>

            <Grid item xs={ 12 } className={ classes.inputContainer }>
              <Field name='school'
                loading={ locationSchool.get('loading') }
                label='School'
                helperText='Search for schools'
                component={ Autosuggest }
                suggestions={ this.getLocationSchoolSuggestions() }
                onSuggestionsFetchRequested={ this.handleUpdateLocationSchoolSuggestions }
                onSuggestionsClearRequested={ this.handleClearLocationSchoolSuggestions } />
            </Grid>
          </Grid>
          <DialogActions className={ classes.actionContainer }>
            <Button onClick={ onClose } color='primary'>
              Cancel
            </Button>

            <Button variant='contained'
              onClick={ this.handleSubmit }
              disabled={ disableButton }
              loading={ chapter.get('loading') || false }
              color='primary'>
              Submit
            </Button>
          </DialogActions>
        </DialogContent>

        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ this.state.data.get('showSnackbar') }
          message='Unable to create chapter, please try again' />
      </Dialog>
    );
  }
}

const styles = theme => ({
  actionContainer: {
    marginTop: 50,
  },

  header: {
    backgroundColor: theme.palette.primary.main,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    padding:         15,
    marginBottom:    30,
  },

  inputContainer: {
    marginBottom: 20,
  },
});

export default withMobileDialog()(withStyles(styles)(AddChapterModal));
