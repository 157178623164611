import { Map, List } from 'immutable';
import { normalize } from 'normalizr';

// constants
import { CURRENT_CHAPTER_FETCH, CURRENT_CHAPTER_CLEAR } from '../constants/chapterTypes';
import { ROUND_REMOVE, ROUND_CREATE, ROUND_BULK_UPDATE } from '../constants/roundTypes';
import {
  FIELD_CREATE, FIELD_REMOVE, FIELD_BULK_UPDATE, FIELD_BULK_CREATE,
} from '../constants/fieldTypes';
import { HOMETOWN_GROUP_REMOVE, HOMETOWN_GROUP_CREATE, HOMETOWN_GROUP_BULK_UPDATE } from '../constants/hometownGroupTypes';

// lib
import httpReducer from '../lib/httpReducer';
import {
  mapSingleEntityToData,
  pushIdOntoListAttribute,
  removeIdFromListAttribute,
  replaceListWithResult,
} from '../lib/normalizedReducerHelpers';

// schema
import { fieldListSchema } from '../schemas/field';

// I don't know why we have to do this but I'm done messing with it
function handleFieldBulkCreate() {
  return (map, action) => {
    const data = ((action || {}).payload || {}).data || [];
    const normalized = normalize(data, fieldListSchema);

    const result = (normalized || {}).result || [];

    const oldData = map.get('data') || Map();

    const newList = List(result);
    const newData = oldData.set('fields', newList);

    return map.set('data', newData);
  };
}

export default (state = Map(), action) => {
  switch (action.type) {
    case HOMETOWN_GROUP_BULK_UPDATE: return httpReducer(state, action, { cb: replaceListWithResult('hometownGroups'), updateResult: false });
    case HOMETOWN_GROUP_REMOVE: return httpReducer(state, action, { cb: removeIdFromListAttribute('hometownGroups'), updateResult: false });
    case HOMETOWN_GROUP_CREATE: return httpReducer(state, action, { cb: pushIdOntoListAttribute('hometownGroups'), updateResult: false });

    case FIELD_BULK_UPDATE: return httpReducer(state, action, { cb: replaceListWithResult('fields'), updateResult: false });
    case FIELD_BULK_CREATE: return httpReducer(state, action, { cb: handleFieldBulkCreate() });
    case FIELD_REMOVE: return httpReducer(state, action, { cb: removeIdFromListAttribute('fields'), updateResult: false });
    case FIELD_CREATE: return httpReducer(state, action, { cb: pushIdOntoListAttribute('fields'), updateResult: false });

    case ROUND_BULK_UPDATE: return httpReducer(state, action, { cb: replaceListWithResult('rounds'), updateResult: false });
    case ROUND_REMOVE: return httpReducer(state, action, { attribute: 'round', cb: removeIdFromListAttribute('rounds'), updateResult: false });
    case ROUND_CREATE: return httpReducer(state, action, { attribute: 'round', cb: pushIdOntoListAttribute('rounds'), updateResult: false });

    case CURRENT_CHAPTER_FETCH: return httpReducer(state, action, { entity: 'chapter', cb: mapSingleEntityToData() });
    case CURRENT_CHAPTER_CLEAR: return Map();
    default: return state;
  }
};
