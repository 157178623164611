import { Map } from 'immutable';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';

// action creators
import { inviteCreate } from '../../../../../../actions/inviteActions';

// components
import InviteForm from './InviteForm';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  inviteForm:     state.get('form').get('invite') || Map(),
  invite:         state.get('invite'),
});

const mapDispatchToProps = dispatch => ({
  createInvite: (params) => {
    dispatch(inviteCreate(params));
  },
});

// schemas
const schema = {
  email: {
    presence: true,

    email: {
      message: 'not valid',
    },

    length: {
      maximum: 100,
    },
  },

  teamLevel: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedInviteForm = reduxForm({
  form:     'invite',
  validate: validateForm,
  onChange: handleChange,
})(InviteForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedInviteForm);
