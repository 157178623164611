import client from './client';

export default (function LocationSchoolService() {
  let service;

  if (!service) {
    service = client.service('locationSchools');
  }

  return service;
}());
