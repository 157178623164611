import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import userService from '../../sources/api/userService';

// constants
import { USER_FETCH_ALL_FOR_CHAPTER_INIT, USER_FETCH_ALL_FOR_CHAPTER } from '../../constants/userTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userListSchema } from '../../schemas/user';

export function* fetchUsersForChapter(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter, search, shouldAddMatchCounts, round,
  } = action.payload || {};

  try {
    const options = {
      query: {
        chapter,
        $search: search,
      },
    };

    if (shouldAddMatchCounts) {
      options.query.shouldAddMatchCounts = true;
    }
    if (round) {
      options.query.round = round;
    }

    yield* httpSaga(USER_FETCH_ALL_FOR_CHAPTER, call(find, userService, options), {
      schema: userListSchema,
    });
  } catch (err) {
    yield put(error(USER_FETCH_ALL_FOR_CHAPTER, err));
  } finally {
    yield put(doneIndicator(USER_FETCH_ALL_FOR_CHAPTER));
  }
}

export function* watchUserFetchAllForChapter() {
  yield takeEvery(USER_FETCH_ALL_FOR_CHAPTER_INIT, fetchUsersForChapter);
}

export default function* root() {
  yield all([
    fork(watchUserFetchAllForChapter),
  ]);
}
