import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create } from '../../sources/api/client';
import bumpGroupService from '../../sources/api/bumpGroupService';

// constants
import { BUMP_GROUP_CREATE, BUMP_GROUP_CREATE_INIT } from '../../constants/bumpGroupTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { bumpGroupSchema } from '../../schemas/bumpGroup';

export function* bumpGroupCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { item = {} } = action.payload;

  try {
    yield* httpSaga(BUMP_GROUP_CREATE, call(create, bumpGroupService, item), {
      schema: bumpGroupSchema,
    });
  } catch (err) {
    yield put(error(BUMP_GROUP_CREATE, err));
  } finally {
    yield put(doneIndicator(BUMP_GROUP_CREATE));
  }
}

export function* watchBumpGroupCreate() {
  yield takeEvery(BUMP_GROUP_CREATE_INIT, bumpGroupCreate);
}

export default function* root() {
  yield all([
    fork(watchBumpGroupCreate),
  ]);
}
