import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import Button from '../Button';
import LoginForm from './components/LoginForm';

import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MUILink from '@material-ui/core/Link';

import { Link } from 'react-router-dom';

// images
import logo from '../../assets/images/logo.png';

// styles
import './Login.scss';
import { Divider } from '@material-ui/core';

class Login extends Component {
  handleSubmit = () => {
    const { createSession, form } = this.props;

    const loginForm = form.get('login') || Map();
    const values = loginForm.get('values') || Map();

    if (!loginForm.get('syncErrors')) {
      createSession(values.toJS());
    }
  }

  renderErrorMessage = (loginForm) => {
    let element;

    if (loginForm.get('submitFailed')) {
      element = (
        <div className='login__error-container'>
          <FormHelperText error>Invalid login, please try again</FormHelperText>
        </div>
      );
    }

    return element;
  }

  render() {
    const { form } = this.props;

    const loginForm = form.get('login') || Map();

    let disableButton = false;

    if (loginForm.get('syncErrors')) { disableButton = true; }

    return (
      <div className='login'>
        <Grid container justify='center'>

          <Grid xs={ 12 } sm={ 12 } md={ 6 } lg={ 5 } xl={ 4 } item>
            <Paper className='login__container' style={ { borderRadius: 10 } } elevation={ 24 }>
              <Grid container alignItems='center' direction='column' spacing={ 24 }>

                <Grid item>
                  <img className='login__logo' src={ logo } alt='MyVote' />
                </Grid>

                <LoginForm onSubmit={ this.handleSubmit } />

                <Grid xs={ 12 } item>
                  <div className='login__button-container'>
                    { this.renderErrorMessage(loginForm) }

                    <Button className='login__button'
                      color='primary'
                      onClick={ this.handleSubmit }
                      disabled={ disableButton }
                      loading={ loginForm.get('submitting') }
                      variant='contained'>
                      Log In
                    </Button>
                  </div>
                </Grid>

                <Grid item>
                  <Link className='login__link' to='/forgot-password' href='/forgot-password'>
                    <Typography variant='button' color='primary'>Forgot Password?</Typography>
                  </Link>
                </Grid>
                <Grid item container alignItems='center' justify='space-around'>
                  <Grid xs={ 5 }>
                    <Divider />
                  </Grid>
                  <Grid item><Typography>OR</Typography></Grid>
                  <Grid xs={ 5 }><Divider /></Grid>
                </Grid>
                <Grid item xs>
                  <Button variant='outlined' color='primary' href={ `${process.env.REACT_APP_API_URL}/oauth/delta-zeta?redirect=/dashboard` }>
                    <div style={ { display: 'flex', alignItems: 'center', gap: 10 } }>
                      <img src='/delta_zeta_icon.png'
                        height={ 16 }
                        alt='delta-zeta-sso' />
                      <Typography>
                        Continue with Delta Zeta
                      </Typography>
                    </div>
                  </Button>

                </Grid>
                <Grid item>
                  <Typography>
                    By signing up with SSO, you agree to Phired Up's
                    <br />
                    <MUILink href='https://www.phiredup.com/privacy-policy'
                      rel='noopener'
                      target='_blank'
                      color='primary'>
                      Privacy Policy
                    </MUILink>
                    {' and '}
                    <MUILink href='https://www.phiredup.com/eula'
                      rel='noopener'
                      target='_blank'
                      color='primary'>
                      End User License Agreement
                    </MUILink>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  createSession: PropTypes.func.isRequired,
  form:          PropTypes.instanceOf(Map).isRequired,
};

export default Login;
