import { Map } from 'immutable';
import { reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import validate from 'validate.js';

// action creators
import { inviteFetch } from '../../actions/inviteActions';
import { userCreate } from '../../actions/userActions';

// components
import Signup from './Signup';

// schemas
const schema = {
  firstname: {
    presence: true,

    length: {
      maximum: 255,
    },
  },

  lastname: {
    presence: true,

    length: {
      maximum: 255,
    },
  },

  phone: {
    length: {
      minimum: 10,
      maximum: 10,
    },
  },

  password: {
    presence: true,

    length: {
      maximum: 255,
    },
  },

  confirmPassword: {
    presence: true,

    equality: 'password',

    length: {
      maximum: 255,
    },
  },
};

const mapStateToProps = state => ({
  invite:     state.get('invite'),
  signupForm: state.get('form').get('signup') || Map(),
});

const mapDispatchToProps = dispatch => ({
  fetchInvite: (id) => {
    dispatch(inviteFetch(id));
  },

  createUser: (params) => {
    dispatch(userCreate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form:     'signup',
    validate: validateForm,
    onChange: handleChange,
  })(Signup)
);
