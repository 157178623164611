import { schema } from 'normalizr';

// schemas
import { concernCategorySchema } from './concernCategory';

export const membershipConcernSchema = new schema.Entity('membershipConcern', {
  category: concernCategorySchema,
}, {
  idAttribute: '_id',
});

export const membershipConcernListSchema = [membershipConcernSchema];
