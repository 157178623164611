/*
  Simple HTML5 file input wrapper
  usage: <Import handleFile={callback} />
    handleFile(file:File):void;
*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

/* list of supported file types */
const SheetJSFT = [
  'xlsx', 'xlsb', 'xlsm', 'xls', 'xml', 'csv', 'txt', 'ods', 'fods', 'uos', 'sylk', 'dif', 'dbf', 'prn', 'qpw', '123', 'wb*', 'wq*', 'html', 'htm',
].map(x => `.${x}`).join(',');

class ImportInput extends Component {
  static propTypes = {
    handleFile: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        fileData: Date.now(),
      }),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { files } = e.target;
    const { handleFile } = this.props;

    this.setState({ data: this.state.data.set('fileDate', Date.now()) });

    if (files && files[0]) {
      handleFile(files[0]);
    }
  }

  render() {
    const fileDate = this.state.data.get('fileDate');
    return (
      <Grid item xs={ 12 }>
        <label htmlFor='file' style={ { marginRight: 15 } }>
          <Typography variant='subtitle1' color='primary' align='center'>2. Select spreadsheet</Typography>
          <input type='file' id='file' accept={ SheetJSFT } key={ fileDate } onChange={ this.handleChange } />
        </label>
      </Grid>
    );
  }
}

export default ImportInput;
