import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { hometownGroupPnmCreate } from '../../../../../../actions/hometownGroupPnmActions';

// components
import HometownGroupsForm from './HometownGroupsForm';

const mapStateToProps = state => ({
  currentChapter:       state.get('currentChapter'),
  hometownGroup:        state.get('hometownGroup'),
  hometownGroupPnmForm: state.getIn(['form', 'hometownGroupPnmForm'], Map()),
});

const mapDispatchToProps = dispatch => ({
  createHometownGroupPnm: (params) => {
    dispatch(hometownGroupPnmCreate(params));
  },
});

// schemas
const schema = {
  hometownGroup: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedHometownGroupsForm = reduxForm({
  form:     'hometownGroupPnmForm',
  validate: validateForm,
  onChange: handleChange,
})(HometownGroupsForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(wrappedHometownGroupsForm));
