import { connect } from 'react-redux';
import { Map } from 'immutable';

import { userUpdate } from '../../../../../../actions/userActions';

import PhotoForm from './PhotoForm';

const mapStateToProps = state => ({
  photoForm: (state.get('form')).get('photoForm') || Map(),
  user:      state.get('user'),
});

const mapDispatchToProps = dispatch => ({
  updateUser: (params) => {
    dispatch(userUpdate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoForm);
