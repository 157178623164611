import client from './client';

export default (function FieldsService() {
  let service;

  if (!service) {
    service = client.service('fields');
  }

  return service;
}());
