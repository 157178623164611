import { connect } from 'react-redux';

// action creators
import { drawerOpen } from '../../../../actions/drawerActions';

// components
import Header from './Header';

const mapDispatchToProps = dispatch => ({
  openDrawer: () => {
    dispatch(drawerOpen());
  },
});

export default connect(null, mapDispatchToProps)(Header);
