import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { patch, get } from '../../sources/api/client';
import hometownGroupService from '../../sources/api/hometownGroupService';
import chapterService from '../../sources/api/chapterService';

// constants
import { HOMETOWN_GROUP_UPDATE, HOMETOWN_GROUP_UPDATE_INIT } from '../../constants/hometownGroupTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { hometownGroupSchema } from '../../schemas/hometownGroup';
import { chapterSchema } from '../../schemas/chapter';

export function* hometownGroupUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapterId, type, index } = action.payload;
  const values = action.payload || {};

  const FORM = action.payload.formName || ' ';
  yield put(startSubmit(FORM));

  if (type === 'remove') {
    values.hometownGroups[index].removed = 1;
  }

  try {
    yield* httpSaga(HOMETOWN_GROUP_UPDATE, call(patch, hometownGroupService, { values }), {
      schema: hometownGroupSchema,
    });

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapterId), {
      schema: chapterSchema,
    });

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(HOMETOWN_GROUP_UPDATE, err));
  } finally {
    yield put(doneIndicator(HOMETOWN_GROUP_UPDATE));
  }
}

export function* watchHometownGroupsUpdate() {
  yield takeEvery(HOMETOWN_GROUP_UPDATE_INIT, hometownGroupUpdate);
}

export default function* root() {
  yield all([
    fork(watchHometownGroupsUpdate),
  ]);
}
