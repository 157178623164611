import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// styles
import './ProfileMenu.scss';

class ProfileMenu extends Component {
  static propTypes = {
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    destroySession: PropTypes.func.isRequired,
    navigate:       PropTypes.func.isRequired,
    router:         PropTypes.instanceOf(Object).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Map({
        anchorEl: null,
      }),
    };
  }

  handleCloseMenu = () => {
    this.setState({ data: this.state.data.set('anchorEl', null) });
  }

  handleLogOut = () => {
    const { destroySession } = this.props;

    destroySession();
    this.handleCloseMenu();
  }

  handleOpenMenu = (e) => {
    this.setState({ data: this.state.data.set('anchorEl', e.currentTarget) });
  }

  handleDashboard = () => {
    const { navigate } = this.props;

    navigate('/dashboard');
    this.handleCloseMenu();
  }

  renderSubheader = () => {
    const currentUser = (this.props.currentUser || Map()).get('data') || Map();
    const currentChapter = (this.props.currentChapter || Map()).get('data');

    let subHeader;

    if (currentChapter && currentUser.get('clearance_level') !== 2) {
      subHeader = currentChapter.get('name');
    } else if (currentChapter && currentUser.get('clearance_level') === 2) {
      subHeader = `Viewing ${currentChapter.get('name')}`;
    } else if (!currentChapter && currentUser.get('clearance_level') === 2) {
      subHeader = 'TechniPhi Admin';
    }

    return subHeader;
  }

  render() {
    const currentUser = (this.props.currentUser || Map()).get('data') || Map();
    const firstname = currentUser.get('firstname') || '';
    const lastname = currentUser.get('lastname') || '';
    const clearanceLevel = currentUser.get('clearance_level') || 0;

    const numberOfChapters = (currentUser.get('chapters') || List()).size || 0;
    const currentPath = this.props.router.location.pathname;
    const notDashboard = !currentPath.includes('dashboard');

    const anchorEl = this.state.data.get('anchorEl');

    let avatar = <Avatar className='profile-menu__avatar'>{ `${firstname[0]}${lastname[0]}` }</Avatar>;

    if (currentUser.get('image')) {
      avatar = <Avatar className='profile-menu___avatar' src={ currentUser.get('image') } />;
    }

    return (
      <div className='profile-menu-container'>
        <Button aria-owns={ anchorEl ? 'profileMenu' : null }
          aria-haspopup='true'
          onClick={ this.handleOpenMenu }>

          { avatar }

          <Hidden implementation='css' smDown>
            <div className='profile-menu__text'>
              <Typography noWrap>{ `${firstname} ${lastname}` }</Typography>
              <Typography color='textSecondary' noWrap>
                {' '}
                { this.renderSubheader() }
              </Typography>
            </div>
          </Hidden>
        </Button>

        <Menu anchorEl={ anchorEl }
          anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
          getContentAnchorEl={ null }
          id='profileMenu'
          open={ Boolean(anchorEl) }
          onClose={ this.handleCloseMenu }>

          { this.renderSubheader() ? (
            <MenuItem>
              <Typography>{ this.renderSubheader() }</Typography>
            </MenuItem>
          ) : null}

          { (clearanceLevel > 0 || numberOfChapters > 1) && notDashboard
            && (
            <MenuItem onClick={ this.handleDashboard }>
              <ListItemIcon><Icon>dashboard</Icon></ListItemIcon>
              Dashboard
            </MenuItem>
            )}

          <MenuItem onClick={ this.handleLogOut }>
            <ListItemIcon><Icon>power_settings_new</Icon></ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const styles = () => ({
  avatar: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
});

export default withStyles(styles)(ProfileMenu);
