import { connect } from 'react-redux';

// components
import FileSettingsItem from './FileSettingsItem';

const mapStateToProps = state => ({
  form: state.get('form'),
});

export default connect(mapStateToProps)(FileSettingsItem);
