import { connect } from 'react-redux';
import { Map } from 'immutable';

// action creators
import { hometownGroupBulkUpdate } from '../../../../../../actions/hometownGroupActions';

// selectors
import { hasSubmitFailed, hasSubmitSucceeded } from '../../../../../../lib/selectors/formSelectors';
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';

// components
import HometownGroupsForm from './HometownGroupsForm';

const createInitialValues = (props) => {
  const { hometownGroups } = props;
  return Map({ hometownGroups });
};

const mapStateToProps = (state, props) => ({
  hometownGroupsFormSubmitFailed:    hasSubmitFailed('hometownGroupsForm')(state),
  hometownGroupsFormSubmitSucceeded: hasSubmitSucceeded('hometownGroupsForm')(
    state
  ),
  hometownGroupLoading: getLoadingFromState('hometownGroup')(state),
  initialValues:        createInitialValues(props),
});

const mapDispatchToProps = dispatch => ({
  bulkUpdateHometownGroups: (items, chapter) => {
    dispatch(hometownGroupBulkUpdate(items, chapter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HometownGroupsForm);
