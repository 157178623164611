import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove } from '../../sources/api/client';
import hometownGroupService from '../../sources/api/hometownGroupService';

// constants
import { HOMETOWN_GROUP_REMOVE, HOMETOWN_GROUP_REMOVE_INIT } from '../../constants/hometownGroupTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { hometownGroupSchema } from '../../schemas/hometownGroup';

export function* hometownGroupRemove(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { hometownGroup, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(HOMETOWN_GROUP_REMOVE,
      call(remove, hometownGroupService, hometownGroup, options),
      {
        schema: hometownGroupSchema,
      });
  } catch (err) {
    yield put(error(HOMETOWN_GROUP_REMOVE, err));
  } finally {
    yield put(doneIndicator(HOMETOWN_GROUP_REMOVE));
  }
}

export function* watchInviteRemove() {
  yield takeEvery(HOMETOWN_GROUP_REMOVE_INIT, hometownGroupRemove);
}

export default function* root() {
  yield all([
    fork(watchInviteRemove),
  ]);
}
