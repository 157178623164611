import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import SelectInput from '../../../../../SelectInput';
import Button from '../../../../../Button';

class BumpGroupGenerator extends Component {
  static propTypes = {
    bumpGroup:              PropTypes.instanceOf(Map).isRequired,
    bumpGroups:             PropTypes.instanceOf(List).isRequired,
    bumpGroupGeneratorForm: PropTypes.instanceOf(Map).isRequired,
    currentChapterId:       PropTypes.number.isRequired,
    generateBumpGroups:     PropTypes.func.isRequired,
    user:                   PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        snackbarMessage:  '',
        snackbarOpen:     false,
        generatingGroups: false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { bumpGroups } = this.props;
    const generatingGroups = this.state.data.get('generatingGroups');

    if (generatingGroups && bumpGroups !== nextProps.bumpGroups) {
      this.setState({ data: this.state.data.set('generatingGroups', false) });
    }
  }

  getMembersPerGroupOptions = () => {
    const options = [];

    for (let i = 0; i < 9; i += 1) {
      options.push({
        value: i + 1,
        label: i + 1,
      });
    }

    return options;
  }

  handleSnackbarOpen = (status) => {
    if (status === 'success') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Generated Successfully');
        }),
      });
    } else {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Generating Failed');
        }),
      });
    }
  }

  handleSnackbarClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  }

  handleGenerateGroups = () => {
    const {
      currentChapterId,
      generateBumpGroups,
      bumpGroupGeneratorForm,
    } = this.props;

    const membersPerGroup = bumpGroupGeneratorForm.getIn(['values', 'membersPerGroup']);
    this.setState({ data: this.state.data.set('generatingGroups', true) });

    generateBumpGroups({ chapter: currentChapterId, membersPerGroup });
  }

  render() {
    const {
      bumpGroupGeneratorForm,
      bumpGroup,
      user,
    } = this.props;

    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');

    const disabled = Boolean(bumpGroupGeneratorForm.get('syncErrors'));
    const generatingGroups = this.state.data.get('generatingGroups');
    const loading = user.get('loading') || bumpGroup.get('loading') || generatingGroups;

    return (
      <Grid container component='form' spacing={ 24 }>
        <Grid item xs={ 4 }>
          <Field name='membersPerGroup'
            label='Minimum Members Per Group'
            component={ SelectInput }
            variant='outlined'
            options={ this.getMembersPerGroupOptions() }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 8 }>
          <Button color='primary'
            variant='contained'
            disabled={ disabled }
            loading={ loading }
            onClick={ this.handleGenerateGroups }>
            Generate Groups
          </Button>
        </Grid>

        <Snackbar key='remove'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ () => this.handleSnackbarClose() }
          message={ snackbarMessage } />
      </Grid>
    );
  }
}

export default BumpGroupGenerator;
