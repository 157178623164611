import { Map } from 'immutable';

// reducers
import reducer from '../drawer';

describe('drawer reducer', () => {
  describe('DRAWER_CLOSE', () => {
    const type = 'DRAWER_CLOSE';

    it('should set "open" to false', () => {
      const action = { type };
      const initialState = Map();

      expect(reducer(initialState, action)).toMatchSnapshot();
    });
  });

  describe('DRAWER_OPEN', () => {
    const type = 'DRAWER_OPEN';

    it('should set "open" to true', () => {
      const action = { type };
      const initialState = Map();

      expect(reducer(initialState, action)).toMatchSnapshot();
    });
  });

  describe('with no type', () => {
    it('should return the initial state', () => {
      const action = {};
      const initialState = Map({ initial: 'state' });

      expect(reducer(initialState, action)).toMatchSnapshot();
    });
  });
});
