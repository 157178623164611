import { connect } from 'react-redux';

// components
import Account from './Account';

const mapStateToProps = state => ({
  form:        state.get('form'),
  currentUser: state.get('currentUser'),
});

export default connect(mapStateToProps)(Account);
