import { PDF_GENERATE_INIT } from '../constants/pdfTypes';

export function generatePdf({
  chapter,
  pnmIds = [],
  filter = {},
  ...rest
}) {
  return {
    type:    PDF_GENERATE_INIT,
    payload: {
      chapter,
      pnmIds,
      filter,
      ...rest,
    },
  };
}
