import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import red from '@material-ui/core/colors/red';

// local components
import Button from '../Button';

function ActionModal(props) {
  ActionModal.propTypes = {
    classes:         PropTypes.instanceOf(Object).isRequired,
    description:     PropTypes.string.isRequired,
    fullScreen:      PropTypes.bool.isRequired,
    onAccept:        PropTypes.func.isRequired,
    onReject:        PropTypes.func,
    open:            PropTypes.bool.isRequired,
    acceptLabel:     PropTypes.string.isRequired,
    rejectLabel:     PropTypes.string,
    title:           PropTypes.string.isRequired,
    loading:         PropTypes.bool,
    confirmDeleting: PropTypes.bool,
  };

  ActionModal.defaultProps = {
    loading:         false,
    onReject:        () => {},
    rejectLabel:     '',
    confirmDeleting: false,
  };

  const {
    classes,
    description,
    fullScreen,
    onAccept,
    onReject,
    open,
    acceptLabel,
    rejectLabel,
    title,
    loading,
    confirmDeleting,
  } = props;

  const [acceptText, setAcceptText] = useState('');

  const handleChange = (event) => {
    event.preventDefault();
    setAcceptText(event.target.value);
  };

  return (
    <Dialog fullScreen={ fullScreen } open={ open } onClose={ onReject }>
      <DialogTitle>{ title }</DialogTitle>

      <DialogContent>
        <DialogContentText className={ classes.dialogContentText }>
          { description }
        </DialogContentText>
        { confirmDeleting
          && (
          <div>
            <Typography variant='h6'
              color='error'
              style={ { paddingTop: '20px' } }>
              Please type &apos;
              {acceptLabel.toUpperCase()}
              &apos; in the field to confirm
            </Typography>
            <TextField name='confirmDeleting'
              label={ `Type ${acceptLabel.toUpperCase()}` }
              variant='outlined'
              onChange={ handleChange }
              value={ acceptText }
              className={ classes.acceptDeleting } />
          </div>
          )}
      </DialogContent>

      <DialogActions>
        { rejectLabel && (
        <Button onClick={ onReject } color='primary' disabled={ loading }>
          { rejectLabel }
        </Button>
        )}

        <Button onClick={ onAccept }
          loading={ loading }
          className={ classes.deleteButton }
          disabled={ confirmDeleting && acceptText !== acceptLabel.toUpperCase() }>
          { acceptLabel }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = () => ({
  deleteButton: {
    color: red[500],
  },
  acceptDeleting: {
    marginTop: 20,
    width:     '60%',
  },
  dialogContentText: {
    whiteSpace: 'pre-line',
  },
});

export default withMobileDialog()(withStyles(styles)(ActionModal));
