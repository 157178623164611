import { Map } from 'immutable';

// constants
import {
  NOTE_FETCH_FOR_PNM, NOTE_FETCH_ALL,
  NOTE_DELETE, NOTE_CREATE, NOTE_CLEAR, NOTE_BULK_CREATE,
} from '../constants/noteTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { addToFront, remove } from '../lib/normalizedReducerHelpers';

export default (state = Map(), action) => {
  switch (action.type) {
    case NOTE_FETCH_ALL: return httpReducer(state, action, { entity: 'note' });
    case NOTE_FETCH_FOR_PNM: return httpReducer(state, action, { entity: 'note' });
    case NOTE_DELETE: return httpReducer(state, action, { cb: remove() });
    case NOTE_CREATE: return httpReducer(state, action, { cb: addToFront() });
    case NOTE_BULK_CREATE: return httpReducer(state, action, { cb: addToFront() });
    case NOTE_CLEAR: return Map();
    default: return state;
  }
};
