import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { get, patch } from '../../sources/api/client';
import userService from '../../sources/api/userService';

// constants
import { USER_UPDATE, USER_UPDATE_INIT, CURRENT_USER_FETCH } from '../../constants/userTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSchema } from '../../schemas/user';

export function* userUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    id, userId, isCurrentUser, destroyForm, formName, ...values
  } = action.payload;

  const FORM = formName || ' ';
  yield put(startSubmit(FORM));

  const { phone } = values;

  if (values.phone) {
    values.phone_area = phone.substring(0, 3);
    values.phone_number = phone.substring(3, 10);
    delete values.phone;
  }

  try {
    yield* httpSaga(USER_UPDATE, call(patch, userService, (id || userId), { ...values }), {
      schema: userSchema,
    });

    if (isCurrentUser) {
      yield* httpSaga(CURRENT_USER_FETCH, call(get, userService, (id || userId)), {
        schema: userSchema,
      });
    }

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(USER_UPDATE, err));
  } finally {
    yield put(doneIndicator(USER_UPDATE));
  }
}

export function* watchUserUpdate() {
  yield takeEvery(USER_UPDATE_INIT, userUpdate);
}

export default function* root() {
  yield all([
    fork(watchUserUpdate),
  ]);
}
