import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';

// MUI components
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// local components
import CellOptionsMenu from '../CellOptionsMenu';

function EnhancedTableRow(props) {
  EnhancedTableRow.propTypes = {
    classes:          PropTypes.instanceOf(Object).isRequired,
    currentChapterId: PropTypes.number.isRequired,
    deleteMatch:      PropTypes.func.isRequired,
    matches:          PropTypes.instanceOf(List).isRequired,
    numCols:          PropTypes.number.isRequired,
    round:            PropTypes.instanceOf(Map).isRequired,
    user:             PropTypes.instanceOf(Map).isRequired,
  };

  const {
    classes,
    currentChapterId,
    deleteMatch,
    matches,
    numCols,
    round,
    user,
  } = props;

  const [cellContent, setCellContent] = useState('');
  const [showAddButtons, setShowAddButtons] = useState(false);

  useEffect(() => {
    const element = (
      <IconButton className={ classes.iconPlaceholder }>
        <Icon>add</Icon>
      </IconButton>
    );

    setCellContent(element);
  }, []);

  const handleDelete = (itemToDelete) => {
    const params = {
      chapter: currentChapterId,
      id:      itemToDelete.get('_id'),
      ids:     [],
    };

    deleteMatch(params);
  };

  const getChipTooltipTitle = (match) => {
    let title = '';
    if (match.get('type') === 'bumpGroup') {
      title = 'Bump group match';
    }

    if (match.get('matchStrength') > 0) {
      const matchStrengthTitle = `Match strength of ${match.get('matchStrength')}`;

      if (title.length) {
        title = `${title} | ${matchStrengthTitle}`;
      } else {
        title = matchStrengthTitle;
      }
    }

    return title;
  };

  const getChipClassName = (match, index) => {
    const numEvents = round.get('events', 0);
    const needsSpacing = numEvents > 1 && index < numEvents;
    const giveAltColor = match.get('type') === 'bumpGroup';

    if (needsSpacing && giveAltColor) {
      return classNames(classes.blueChip, classes.marginChip);
    } if (needsSpacing) {
      return classes.marginChip;
    } if (giveAltColor) {
      return classes.blueChip;
    }

    return {};
  };

  const handleMouseOver = (eventNumber) => {
    const element = (
      <Fade in={ showAddButtons }>
        <CellOptionsMenu title='Select New Unmatched PNM'
          round={ round }
          user={ user }
          event={ eventNumber } />
      </Fade>
    );

    setCellContent(element);
    setShowAddButtons(true);
  };

  const handleMouseLeave = () => {
    const element = (
      <Fade in={ !showAddButtons }>
        <IconButton className={ classes.iconPlaceholder }>
          <Icon>add</Icon>
        </IconButton>
      </Fade>
    );

    setCellContent(element);
    setShowAddButtons(false);
  };

  const renderEventCells = () => {
    let elements = List();
    const numEvents = round.get('events', 0);

    for (let index = 0; index < numEvents; index += 1) {
      const eventNumber = index + 1;
      const matchesForEvent = matches.filter(m => m.getIn(['pnm', 'eventForRound'], 0) === eventNumber);

      const cellElement = (
        <TableCell padding='dense'
          key={ eventNumber }
          onMouseOver={ () => handleMouseOver(eventNumber) }
          onFocus={ () => handleMouseOver(eventNumber) }
          onMouseLeave={ handleMouseLeave }>
          { matchesForEvent.size
            ? matchesForEvent.map((m) => {
              let label = `${m.getIn(['pnm', 'firstname'], '')} ${m.getIn(['pnm', 'lastname'], '')}`;

              if (m.get('matchStrength') > 0) {
                label += ` (${m.get('matchStrength')})`;
              }

              return (
                <Fade in key={ m.get('_id') }>
                  <Tooltip title={ getChipTooltipTitle(m) }
                    disableHoverListener={ !getChipTooltipTitle(m).length }>
                    <Chip label={ label }
                      onDelete={ () => handleDelete(m) }
                      className={ getChipClassName(m, index) } />
                  </Tooltip>
                </Fade>
              );
            })
            : (
              <div>
                { cellContent }
              </div>
            )}

        </TableCell>
      );

      elements = elements.push(cellElement);
    }

    return elements;
  };

  return (
    <TableRow colSpan={ numCols }
      className={ classes.row }>

      <TableCell padding='dense'>
        <Typography>{ user.get('firstname') }</Typography>
      </TableCell>
      <TableCell padding='dense'>
        <Typography>{ user.get('lastname') }</Typography>
      </TableCell>
      <TableCell padding='none'>
        <Typography>{ user.get('matchRecs') }</Typography>
      </TableCell>
      <TableCell padding='none'>
        <Typography>{ user.get('officialMatches') }</Typography>
      </TableCell>

      { renderEventCells() }

    </TableRow>
  );
}

const styles = theme => ({
  row: {
    cursor: 'pointer',
  },
  marginChip: {
    marginBottom: 4,
  },
  blueChip: {
    color:      theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  iconPlaceholder: {
    opacity: 0,
  },
});

export default withStyles(styles)(EnhancedTableRow);
