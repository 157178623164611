import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// components
import ImportForm from './ImportForm';

const createInitialValues = () => {
  const values = {
    releasePnms: false,
  };

  return values;
};

const mapStateToProps = state => ({
  importForm:    (state.get('form')).get('importForm') || Map(),
  initialValues: createInitialValues(),
});

// schemas
const schema = {
  releasePnms: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedImportForm = reduxForm({
  form:               'importForm',
  enableReinitialize: true,
  validate:           validateForm,
  onChange:           handleChange,
})(ImportForm);

export default connect(mapStateToProps)(wrappedImportForm);
