import { Map } from 'immutable';
import AssignVoteModal from './AssignVoteModal';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';

// action creators
import { voteUpdate } from '../../../../actions/voteActions';
import { fetchAllPnmsForAnonymous } from '../../../../actions/pnmActions';

const schema = {
  pnm: {
    presence: true,
  },
};

const mapStateToProps = state => ({
  pnm:            state.get('pnm'),
  currentChapter: state.get('currentChapter'),
  assignVoteForm: state.getIn(['form', 'assignVoteForm'], Map()),
});

const mapDispatchToProps = dispatch => ({
  updateVote: (params) => {
    dispatch(voteUpdate(params));
  },
  fetchPnms: (params) => {
    dispatch(fetchAllPnmsForAnonymous(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:     'assignVoteForm',
  validate: validateForm,
  onChange: handleChange,
})(AssignVoteModal));
