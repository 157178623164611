import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

// components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import CommentList from '../../../CommentList';

function ConcernsTab({ classes, membershipConcerns, concernsLoading }) {
  return (
    <div>
      { membershipConcerns.size || concernsLoading
        ? (
          <CommentList type='concerns'
            items={ membershipConcerns }
            loading={ concernsLoading } />
        ) : (
          <div>
            <ListItem className={ classes.title }>
              <Typography color='secondary' align='center' variant='h6'>
                No Concerns Found
              </Typography>
            </ListItem>
            <ListItem>
              <Avatar className={ classes.avatar }>
                <Icon className={ classes.avatarIcon }>info</Icon>
              </Avatar>
            </ListItem>
            <ListItem className={ classes.title }>
              <Typography color='secondary' align='center'>
                Post membership concerns to keep track of your PNMs
              </Typography>
            </ListItem>
          </div>
        ) }
    </div>
  );
}

ConcernsTab.propTypes = {
  classes:            PropTypes.instanceOf(Object).isRequired,
  membershipConcerns: PropTypes.instanceOf(List).isRequired,
  concernsLoading:    PropTypes.bool.isRequired,
};

const styles = theme => ({
  title: {
    display: 'block',
    margin:  'auto',
  },

  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatarIcon: {
    fontSize: 50,
  },
});

export default withStyles(styles)(ConcernsTab);
