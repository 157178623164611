import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import red from '@material-ui/core/colors/red';

// local components
import TextInput from '../../../../../TextInput';
import SwitchInput from '../../../../../SwitchInput';
import SelectInput from '../../../../../SelectInput';

class CategoryItem extends Component {
  static propTypes = {
    category: PropTypes.instanceOf(Map).isRequired,
    classes:  PropTypes.instanceOf(Object).isRequired,
    field:    PropTypes.string.isRequired,
    errors:   PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    errors: null,
  }

  getSwitchLabel = (category) => {
    const element = category.get('enabled') ? 'On' : 'Off';

    return element;
  };

  getTypeOptions = () => {
    const elements = [
      { value: 'button', label: 'Button' },
      { value: 'gpa', label: 'GPA' },
      { value: 'number', label: 'Number' },
      { value: 'range', label: 'Range' },
      { value: 'icon', label: 'Icon' },
    ];

    return elements;
  }

  renderRangeFields = () => {
    const {
      category, classes, field,
    } = this.props;

    return [
      <Grid item xs={ 6 } sm={ 2 } key='min'>
        <Field className={ classes.labelInput }
          name={ `${field}.minOptionValue` }
          label='Minimum'
          type='number'
          variant='filled'
          placeholder={ `${category.get('minOptionValue')}` || '0' }
          component={ TextInput }
          fullWidth
          disabled={ !category.get('editable') }
          required />
      </Grid>,
      <Grid item xs={ 6 } sm={ 2 } key='max'>
        <Field className={ classes.labelInput }
          name={ `${field}.maxOptionValue` }
          label='Maximum'
          type='number'
          variant='filled'
          placeholder={ `${category.get('maxOptionValue')}` || '5' }
          component={ TextInput }
          fullWidth
          disabled={ !category.get('editable') }
          required />
      </Grid>,
      <Grid item xs={ 6 } sm={ 2 } key='optionIncrement'>
        <Field className={ classes.labelInput }
          name={ `${field}.optionIncrement` }
          label='Increment'
          type='number'
          variant='filled'
          placeholder={ `${category.get('optionIncrement')}` || '1' }
          component={ TextInput }
          fullWidth
          disabled={ !category.get('editable') }
          required />
      </Grid>,
    ];
  }

  renderOptions = ({ fields }) => {
    const { classes, category, errors } = this.props;

    return (
      <Grid container spacing={ 16 }>
        {
          fields.map((field, index) => {
            let labelError;
            let valueError;

            const error = (errors || {})[index];

            if (error) {
              labelError = error.label;
              valueError = error.value;
            }

            return (
              <Grid item xs={ 4 } sm={ 2 } align='center' key={ field } className={ classes.inputContainer }>
                <Field className={ classes.labelInput }
                  name={ `${field}.label` }
                  label='Label'
                  type='text'
                  margin='normal'
                  variant='filled'
                  placeholder='Label'
                  component={ TextInput }
                  fullWidth
                  disabled={ !category.get('editable') }
                  error={ labelError }
                  required />

                <Field className={ classes.valueInput }
                  name={ `${field}.value` }
                  label='Value'
                  parse={ value => (!value ? null : parseFloat(value)) }
                  type='number'
                  margin='normal'
                  variant='filled'
                  placeholder='Value'
                  component={ TextInput }
                  fullWidth
                  disabled={ !category.get('editable') }
                  error={ valueError }
                  required />

                { category.get('enableOptionVotingLimits')
                  && (
                  <Field className={ classes.valueInput }
                    name={ `${field}.limit` }
                    label='Vote Limit'
                    parse={ value => (!value ? null : parseFloat(value)) }
                    type='number'
                    margin='normal'
                    variant='filled'
                    placeholder='Enter number'
                    component={ TextInput }
                    fullWidth
                    error={ valueError }
                    required />
                  )}
              </Grid>
            );
          })
        }

        {
          category.get('editable')
            ? (
              <Grid item xs={ 2 }>
                <Grid container spacing={ 8 }>
                  <Grid item xs={ 6 }>
                    <IconButton className={ classes.redButton } onClick={ () => fields.pop() }>
                      <Icon>remove_circle_outline</Icon>
                    </IconButton>
                  </Grid>

                  <Grid item xs={ 6 }>
                    <IconButton color='primary'
                      onClick={ () => fields.push(Immutable.fromJS({ label: '0', value: 0 })) }>

                      <Icon>add_circle_outline</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )
            : null
        }
      </Grid>
    );
  }

  render() {
    const {
      category, classes, field,
    } = this.props;

    return (
      <Grid container
        className={ classes.categoryItem }
        spacing={ 16 }
        justify='flex-start'>

        <Grid item xs={ 12 } sm={ 2 } align='left'>
          <Typography color='textSecondary' variant='subtitle1'>Category:</Typography>
          <Typography color='primary' variant='subtitle1'>{ category.get('name') }</Typography>
        </Grid>

        <Grid item xs={ 12 } sm={ 2 } align='right'>
          <Field name={ `${field}.enabled` }
            id='status'
            label={ this.getSwitchLabel(category) }
            disabled={ category.get('required') }
            component={ SwitchInput } />
        </Grid>

        { (category.get('editable') || category.get('enableOptionVotingLimits')) && [ // hide settings and options fields for non-editable categories
          <Grid item xs={ 12 } sm={ 2 } key='type'>
            <Field name={ `${field}.type` }
              label='Type'
              variant='outlined'
              component={ SelectInput }
              options={ this.getTypeOptions() }
              disabled={ !category.get('editable') }
              fullWidth />
          </Grid>,

          category.get('type') === 'range'
          || category.get('type') === 'gpa'
          || category.get('type') === 'number'
            ? this.renderRangeFields()
            : (
              <Grid item xs={ 12 }>
                <FieldArray name={ `${field}.options` }
                  component={ this.renderOptions } />
              </Grid>
            ),
        ]}

        { category.get('description')
          && (
          <Grid item xs={ 12 }>
            <Typography color='textSecondary' variant='caption' className={ classes.descriptionTitle }>Description:</Typography>
            <Typography variant='caption'>{category.get('description')}</Typography>
          </Grid>
          )}

      </Grid>
    );
  }
}

const styles = () => ({
  categoryItem: {
    marginBottom: 25,
    padding:      25,
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
  },

  inputContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    marginRight:  20,
  },

  redButton: {
    color: red[500],
  },

  descriptionTitle: {
    fontWeight: 600,
  },

  labelInput: {
    marginBottom: 15,
  },

  valueInput: {
    marginBottom: 50,
  },
});

export default withStyles(styles)(CategoryItem);
