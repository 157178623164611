import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';

// MUI Components
import FormHelperText from '@material-ui/core/FormHelperText';
import green from '@material-ui/core/colors/green';

// local components
import Button from '../Button';

class FileInput extends Component {
  static propTypes = {
    buttonLabel:        PropTypes.string,
    containerClassName: PropTypes.string,
    profileData:        PropTypes.instanceOf(Map).isRequired,
    fileType:           PropTypes.string,
    update:             PropTypes.func.isRequired,

    // profileState prop is either the user or pnm app state
    profileState: PropTypes.instanceOf(Map).isRequired,
  }

  static defaultProps = {
    buttonLabel:        'Upload',
    containerClassName: '',
    fileType:           'file_extension',
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        file:      null,
        uploading: false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { profileState } = nextProps;
    const uploading = this.state.data.get('uploading');

    if (profileState.get('status') === 'success' && !profileState.get('loading') && uploading === true) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('uploading', 'done');
          map.set('file', null);
        }),
      });
    } else if (!profileState.get('loading') && uploading === true) {
      this.setState({ data: this.state.data.set('uploading', 'done') });
    }
  }

  getFileAcceptType = (fileType) => {
    let acceptedFile;

    switch (fileType) {
      case 'image':
        acceptedFile = 'image/*';
        break;
      case 'video':
        acceptedFile = 'video/*';
        break;
      case 'audio':
        acceptedFile = 'audio/*';
        break;
      case 'file':
        acceptedFile = 'file_extension';
        break;
      case 'media':
        acceptedFile = 'media_type';
        break;
      default:
        acceptedFile = 'file_extension';
        break;
    }
    return acceptedFile;
  }

  handleChange = (event) => {
    const file = event.target.files[0];
    this.setState({ data: this.state.data.set('file', file) });
  }

  handleSubmit = () => {
    const { profileData, fileType, update } = this.props;
    const file = this.state.data.get('file');
    const reader = new FileReader();

    if (file && fileType === 'image') {
      reader.onload = () => {
        // Update the profileState's image
        const { result } = reader;
        const updateParams = {
          id:    profileData.get('id'),
          image: result,
        };
        update(updateParams);
      };

      // Convert image to data url
      reader.readAsDataURL(file);

      this.setState({ data: this.state.data.set('uploading', true) });
    }
  }

  renderStatusMessage = () => {
    const { profileState } = this.props;

    let element;
    const uploading = this.state.data.get('uploading');

    if (profileState.get('status') === 'error') {
      const error = (profileState.get('error')) || Map();

      element = (
        <FormHelperText style={ { textAlign: 'center' } } error>
          Error:
          {' '}
          { error.message }
        </FormHelperText>
      );
    } else if (profileState.get('status') === 'success' && uploading === 'done') {
      // We set uploading to 'done' so that this success message only appears on upload.
      //  The default profileState.get('status') is success, so we needed something more to check
      element = (
        <FormHelperText style={ { textAlign: 'center', color: green.A700 } }>
          Uploaded successfully
        </FormHelperText>
      );
    }

    return element;
  }

  render() {
    const {
      buttonLabel,
      containerClassName,
      fileType,
      profileState,
    } = this.props;

    const acceptedFile = this.getFileAcceptType(fileType);
    const uploading = this.state.data.get('uploading');

    return (
      <div>
        <div className={ containerClassName }>
          <input type='file'
            accept={ acceptedFile }
            style={ { marginBottom: 20 } }
            onChange={ e => this.handleChange(e) }
            onBlur={ e => this.handleChange(e) } />
        </div>
        <Button variant='contained'
          color='primary'
          disabled={ !this.state.data.get('file') }
          loading={ profileState.get('loading') && uploading }
          onClick={ () => this.handleSubmit() }>
          { buttonLabel }
        </Button>

        { this.renderStatusMessage() }
      </div>
    );
  }
}

export default FileInput;
