import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';

// local components
import Button from '../../../../../Button';
import ActionModal from '../../../../../ActionModal';

class InviteItem extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    invite:         PropTypes.instanceOf(Object).isRequired,
    deleteInvite:   PropTypes.func.isRequired,
    updateInvite:   PropTypes.func.isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        deleteModalOpen: false,
      }),
    };
  }

  handleOpen = () => {
    this.setState({ data: this.state.data.set('deleteModalOpen', true) });
  };

  handleResendEmail = () => {
    const { currentChapter, updateInvite, invite } = this.props;

    updateInvite({
      $resendEmail: true,
      invite:       invite.get('_id'),
      chapter:      currentChapter.getIn(['data', 'id'], 0),
    });
  }

  handleClose = () => {
    this.setState({ data: this.state.data.set('deleteModalOpen', false) });
  };

  handleDelete = (inviteId) => {
    const { currentChapter, deleteInvite } = this.props;

    const chapterId = currentChapter.getIn(['data', 'id'], 0);

    deleteInvite(inviteId, chapterId);
  }

  handleCopyToClipboard = () => {
    const { invite } = this.props;
    const id = `${invite.get('_id')}link`;

    const input = document.getElementById(id);
    input.select();
    document.execCommand('copy');
  }

  render() {
    const { invite, classes } = this.props;

    const deleteModalOpen = this.state.data.get('deleteModalOpen');

    return (
      <Grid container alignItems='center'>
        <Grid item xs={ 12 } sm={ 5 }>
          <Typography variant='subtitle1'>{ invite.get('email') }</Typography>
        </Grid>

        <Grid item xs={ 12 } sm={ 2 } align='left'>
          <Button variant='text'
            onClick={ this.handleResendEmail }
            color='primary'>
            <Icon className={ classes.leftIcon }>email</Icon>

            Resend Email
          </Button>
        </Grid>

        { /*
        <Grid item xs={ 12 } sm={ 2 } align='left'>
          <Button variant='text'
            color='primary'>
            <Icon className={ classes.leftIcon }>send</Icon>

            Text Invite
          </Button>
        </Grid>
        */ }

        <Grid item xs={ 12 } sm={ 3 } align='left'>
          { invite.get('link') ? [
            <input type='text'
              style={ {
                height:   '1px',
                width:    '1px',
                position: 'absolute',
                zIndex:   -10,
              } }
              defaultValue={ invite.get('link') }
              id={ `${invite.get('_id')}link` }
              key={ `${invite.get('_id')}link` } />,

            <Button variant='text'
              onClick={ this.handleCopyToClipboard }
              key={ `${invite.get('_id')}copyButton` }
              color='primary'>
              <Icon className={ classes.leftIcon }>content_copy</Icon>

              Copy Invite Link
            </Button>,
          ] : null}
        </Grid>

        <Grid item xs={ 12 } sm={ 1 } align='left'>
          <IconButton className={ classes.redButton }
            onClick={ this.handleOpen }>
            <Icon className={ classes.leftIcon }>delete</Icon>
          </IconButton>
        </Grid>

        <ActionModal open={ deleteModalOpen }
          description='This user will no longer be invited to join your chapter’s account.'
          onAccept={ () => this.handleDelete(invite.get('_id')) }
          onReject={ this.handleClose }
          acceptLabel='Delete'
          rejectLabel='Cancel'
          title='Are you sure you want to delete this user’s invite?' />
      </Grid>
    );
  }
}

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  redButton: {
    color: red[500],
  },
});

export default withStyles(styles)(InviteItem);
