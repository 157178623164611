import Immutable, { Map } from 'immutable';

// constants
import { CURRENT_USER_FETCH } from '../constants/userTypes';
import { VOTE_CREATE, VOTE_DELETE, VOTE_UPDATE_COUNTS } from '../constants/voteTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { mapSingleEntityToData } from '../lib/normalizedReducerHelpers';
import { MATCH_CREATE, MATCH_DELETE } from '../constants/matchTypes';

function updateMatchCount(state, action, method) {
  const { payload, meta } = action;
  const { data } = payload || {};

  const oldData = state.get('data') || Map();

  let newData = oldData;

  if (!meta.loading && !Array.isArray(data)) {
    newData = oldData.withMutations((map) => {
      const matchRecs = map.get('matchRecs');
      if (method === 'create') {
        map.set('matchRecs', matchRecs + 1);
      } else if (method === 'delete') {
        map.set('matchRecs', matchRecs - 1);
      }
    });
  }
  return state.set('data', newData);
}

function getUpdatedCategoryVoteCounts(state, action, method) {
  const { payload, meta } = action;

  if (meta !== undefined && meta.loading) {
    return state;
  }

  const { data } = payload || {};

  const categories = (data || {}).categories || [];
  const oldCategoryVotes = state.getIn(['data', 'categoryVotes'], Map());

  let newCategoryVotes = oldCategoryVotes;

  if (method === 'update') {
    return Immutable.fromJS(payload.newCategoryVotes);
  }

  // for each category on the created vote
  categories.forEach((c) => {
    // Get the category's id from the vote
    const categoryId = c.category.toString();

    // Get the corresponding categoryVote from the state
    const categoryVote = oldCategoryVotes.get(categoryId, false);

    // If we track option vote counts for this category
    if (categoryVote) {
      // Get the options for the categoryVote
      const options = categoryVote.get('options', Map());

      // Iterate over the options, because we need to know the option value
      options.map((option, key) => {
        // If the option's value matches the created vote's value
        if (option.get('value') === c.value) {
          // Increment the count on this vote category and recalculate `limitReached`
          newCategoryVotes = newCategoryVotes.withMutations((map) => {
            const count = map.getIn([categoryId, 'options', key, 'count'], 0);

            const limit = map.getIn([categoryId, 'options', key, 'limit'], false);

            let newCount = count;

            // increment count and set limitReached to true if needed
            if (method === 'create') {
              newCount += 1;

              if (limit) {
                map.setIn([categoryId, 'options', key.toString(), 'count'], newCount);

                if (newCount >= limit) {
                  map.setIn([categoryId, 'options', key, 'limitReached'], true);
                }
              }
            } else if (method === 'delete' && count > 0) {
              // decrement count and set limitReached to false if needed
              newCount -= 1;

              const limitReached = map.getIn([categoryId, 'options', key, 'limitReached'], false);

              if (limitReached) {
                map.setIn([categoryId, 'options', key, 'count'], newCount);

                if (limitReached && newCount < limit) {
                  map.setIn([categoryId, 'options', key, 'limitReached'], false);
                }
              }
            }
          });
        }
        return option;
      });
    }
  });

  return newCategoryVotes;
}

function updateVoteCounts(state, action, method) {
  const { meta } = action;

  const oldData = state.get('data') || Map(); // the current user data
  let newData = oldData;
  if (meta === undefined || !meta.loading) {
    newData = oldData.withMutations((map) => {
      const roundVoteCount = map.get('roundVoteCount') || 0;
      const newCategoryVotes = getUpdatedCategoryVoteCounts(state, action, method);
      map.set('categoryVotes', newCategoryVotes);

      if (method === 'create') {
        // increment roundVoteCount
        map.set('roundVoteCount', roundVoteCount + 1);
      } else if (method === 'delete' && roundVoteCount > 0) {
        // decrement roundVoteCount
        map.set('roundVoteCount', roundVoteCount - 1);
      }
    });
  }

  return state.set('data', newData);
}

export default (state = Map(), action) => {
  switch (action.type) {
    case CURRENT_USER_FETCH: return httpReducer(state, action, { entity: 'user', cb: mapSingleEntityToData() });
    case MATCH_CREATE: return updateMatchCount(state, action, 'create');
    case MATCH_DELETE: return updateMatchCount(state, action, 'delete');
    case VOTE_CREATE: return updateVoteCounts(state, action, 'create');
    case VOTE_DELETE: return updateVoteCounts(state, action, 'delete');
    case VOTE_UPDATE_COUNTS: return updateVoteCounts(state, action, 'update');
    default: return state;
  }
};
