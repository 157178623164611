import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

// local components
import Tag from '../../../../../Tag';
import NoteTagModal from '../../../../../NoteTagModal';

// helpers
import { toFloat, toString } from '../../../../../../helpers/transform';

class ResultsTableRow extends Component {
  static propTypes = {
    classes:                 PropTypes.instanceOf(Object).isRequired,
    cobMode:                 PropTypes.bool.isRequired,
    displayOverallScore:     PropTypes.bool.isRequired,
    extraColumnType:         PropTypes.string.isRequired,
    form:                    PropTypes.instanceOf(Map).isRequired,
    handlePopoverOpen:       PropTypes.func.isRequired,
    navigateToPNM:           PropTypes.func.isRequired,
    isCdSite:                PropTypes.bool.isRequired,
    pnm:                     PropTypes.instanceOf(Object).isRequired,
    pnmIndex:                PropTypes.number.isRequired,
    provided:                PropTypes.instanceOf(Object).isRequired,
    rounds:                  PropTypes.instanceOf(List).isRequired,
    categories:              PropTypes.instanceOf(List).isRequired,
    currentChapter:          PropTypes.instanceOf(Map).isRequired,
    currentUser:             PropTypes.instanceOf(Map).isRequired,
    disableNotesFeature:     PropTypes.bool.isRequired,
    allowVotingMembersToTag: PropTypes.bool.isRequired,
    displayOptions:          PropTypes.shape({
      includeGPA1:               PropTypes.bool,
      includeGPA2:               PropTypes.bool,
      includePnmTags:            PropTypes.bool,
      includePnmImages:          PropTypes.bool,
      includePnmStatus:          PropTypes.bool,
      displayCategoryScores:     PropTypes.bool,
      displayCategoryScoresList: PropTypes.arrayOf(PropTypes.string),
      displayRoundScores:        PropTypes.bool,
      displayRoundScoresList:    PropTypes.arrayOf(PropTypes.string),
      selectedField:             PropTypes.instanceOf(Array),
    }),
  };

  static defaultProps = {
    displayOptions: {},
  };

  constructor(props) {
    super(props);

    this.state = Immutable.fromJS({
      noteTagModalOpen: false,
    });
  }

  getStyle = (pnm, element, tags = List()) => {
    // renders style depending on if PNM is a grade risk or not
    const hasLegacyTag = (tags.find((t) => t.get('title').toLowerCase() === 'legacy') || Map()).size > 0;

    // render blue on legacies
    if (pnm.get('isLegacy') || hasLegacyTag) {
      if (element === 'text' || element === 'voteCount') return 'primary';
      if (element === 'avatar') {
        return pnm.get('isGradeRisk')
          ? { backgroundColor: red[500] }
          : { backgroundColor: '#4285f4' };
      }
    }

    // render red on grade risks
    if (pnm.get('isGradeRisk')) {
      if (element === 'text' || element === 'voteCount') return 'error';
      if (element === 'avatar') return { backgroundColor: red[500] };
    }

    if (element === 'text') {
      return 'inherit';
    }
    if (element === 'voteCount') {
      return 'action';
    }
    return {};
  };

  getScoreValue = (score) => {
    let formattedScore = score;

    if (typeof score === 'number') {
      formattedScore = Math.round(score * 100000) / 100000;
    }

    return formattedScore;
  }

  getStandardFieldValue = (standardField) => {
    if (standardField.getIn(['standardField', 'type']) === 'select') {
      const optionsArray = standardField.getIn(['standardField', 'options'], '').split(',');
      return optionsArray[standardField.get('selector')];
    }
    const key = standardField.getIn(['standardField', 'type']) === 'number' ? 'number' : 'text';
    return standardField.get(key, '');
  }

  handleNavigateToPNM = (event) => {
    const { navigateToPNM, pnm } = this.props;
    event.preventDefault();

    navigateToPNM(pnm.get('id'));
  }

  handleNoteTagModalOpen = () => {
    this.setState({ noteTagModalOpen: true });
  }

  handleNoteTagModalClose = () => {
    this.setState({ noteTagModalOpen: false });
  }

  renderExtraCell = (colTypes) => {
    const {
      classes,
      extraColumnType,
      pnm,
      displayOptions,
    } = this.props;
    const {
      displayRoundScores,
      displayCategoryScores,
      displayRoundScoresList = [],
      displayCategoryScoresList = [],
    } = displayOptions || {};
    const tags = pnm.get('tags') || List();
    const paddingOptions = !(
      (displayRoundScores && displayRoundScoresList.length > 0)
      || (displayCategoryScores && displayCategoryScoresList.length > 0)
    ) ? 'dense' : 'none';
    let element;

    switch (extraColumnType) {
      case 'raw':
        element = (
          <TableCell align='right'
            key='raw'
            padding={ paddingOptions }>
            <Typography variant='subtitle1'
              color={ this.getStyle(pnm, 'text', tags) }>

              { Math.round(pnm.getIn(['overall', 'value', 'rawScore'], 0) * 100000) / 100000 }
            </Typography>

            <Grid container spacing={ 8 }>
              <Grid item xs={ 8 }>
                <Icon color={ this.getStyle(pnm, 'voteCount', tags) } className={ classes.voteCountIcon }>
                  thumbs_up_down
                </Icon>
              </Grid>
              <Grid item xs={ 4 }>
                <Typography color={ this.getStyle(pnm, 'text', tags) }>
                  { (pnm.get('overall') || Map()).get('extraColumnVoteCount') }
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        );
        break;
      case 'prePref':
        if (colTypes.includes('prePref')) {
          element = (
            <TableCell align='right'
              key='prePref'
              padding={ paddingOptions }>
              <Typography variant='subtitle1'
                color={ this.getStyle(pnm, 'text', tags) }>
                { Math.round(pnm.getIn(['overall', 'value', 'prePreferenceScore'], 0) * 100000) / 100000 }
              </Typography>

              <Grid container spacing={ 8 }>
                <Grid item xs={ 8 }>
                  <Icon color={ this.getStyle(pnm, 'voteCount', tags) } className={ classes.voteCountIcon }>
                    thumbs_up_down
                  </Icon>
                </Grid>
                <Grid item xs={ 4 }>
                  <Typography color={ this.getStyle(pnm, 'text', tags) }>
                    { (pnm.get('overall') || Map()).get('extraColumnVoteCount') }
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          );
        }
        break;
      default:
        break;
    }
    return element;
  }

  renderRoundCells = () => {
    const {
      classes,
      extraColumnType,
      pnm,
      rounds,
      displayOptions,
    } = this.props;

    const {
      displayRoundScores,
      displayCategoryScores,
      displayRoundScoresList = [],
      displayCategoryScoresList = [],
    } = displayOptions || {};

    const pnmRounds = pnm.get('rounds', Map);
    const tags = pnm.get('tags') || List();
    const maxRoundsLength = displayRoundScoresList.length - 1;
    const disablePaddingOptions = !(displayCategoryScores && displayCategoryScoresList.length > 0);

    const elements = rounds
      .filter((round) => displayRoundScores && displayRoundScoresList.indexOf(round.get('_id', '').toString()) !== -1)
      .map((r, index) => {
        const roundValue = pnmRounds.get(r.get('_id', '').toString(), Map()).get('value', 'N/A');

        const element = [
          <TableCell align='right'
            padding={ disablePaddingOptions && index === maxRoundsLength ? 'dense' : 'none' }
            key={ r.get('_id') }>

            <Typography color={ this.getStyle(pnm, 'text', tags) }>
              { this.getScoreValue(roundValue) }
            </Typography>

            <Grid container spacing={ 8 }>
              <Grid item xs={ 8 }>
                <Icon color={ this.getStyle(pnm, 'voteCount', tags) }
                  className={ classes.voteCountIcon }>
                  thumbs_up_down
                </Icon>
              </Grid>
              <Grid item xs={ 4 }>
                <Typography color={ this.getStyle(pnm, 'text', tags) }>
                  { pnmRounds.get(r.get('_id', '').toString(), Map()).get('voteCount', 0) }
                </Typography>
              </Grid>
            </Grid>

          </TableCell>,

          r.get('roundType') === 5 && extraColumnType === 'prePref'
           && this.renderExtraCell(['prePref']),
        ];

        return element;
      });

    return elements;
  }

  renderCategoryCells = () => {
    const {
      classes,
      pnm,
      categories,
      displayOptions,
    } = this.props;

    const {
      displayCategoryScores,
      displayCategoryScoresList = [],
    } = displayOptions || {};

    const categoriesList = List.isList(categories) ? categories : List();
    const roundCategoriesResult = pnm.get('roundCategoriesResult', Map());
    const tags = pnm.get('tags') || List();
    const maxRoundsLength = displayCategoryScoresList.length - 1;

    const elements = categoriesList
      .filter((category) => displayCategoryScores && displayCategoryScoresList.indexOf(category.get('_id', '').toString()) !== -1)
      .map((category, index) => {
        const id = category.get('_id');
        const value = roundCategoriesResult.getIn([id, 'value'], 0);
        const vote = roundCategoriesResult.getIn([id, 'voteCount'], 0);
        const newValue = value && vote ? toFloat((value / vote).toFixed(2)) : 'N/A';

        return (
          <TableCell align='right'
            padding={ index === maxRoundsLength ? 'dense' : 'none' }
            key={ category.get('_id') }>

            <Typography color={ this.getStyle(pnm, 'text', tags) }>
              { vote > 0 && value === 0 ? value : newValue }
            </Typography>

            <Grid container spacing={ 8 }>
              <Grid item xs={ 8 }>
                <Icon color={ this.getStyle(pnm, 'voteCount', tags) }
                  className={ classes.voteCountIcon }>
                  thumbs_up_down
                </Icon>
              </Grid>
              <Grid item xs={ 4 }>
                <Typography color={ this.getStyle(pnm, 'text', tags) }>
                  { vote }
                </Typography>
              </Grid>
            </Grid>

          </TableCell>
        );
      });

    return elements;
  }

  render() {
    const {
      classes,
      cobMode,
      allowVotingMembersToTag,
      currentChapter,
      currentUser,
      disableNotesFeature,
      displayOverallScore,
      handlePopoverOpen,
      isCdSite,
      pnm,
      pnmIndex,
      provided,
      rounds,
      extraColumnType,
      displayOptions,
      form,
    } = this.props;

    const { noteTagModalOpen } = this.state;

    const formValues = form.get('values');

    const initialValues = form.get('initial');

    const isEqualValues = JSON.stringify(formValues) === JSON.stringify(initialValues);

    const {
      includeGPA1,
      includeGPA2,
      includePnmTags,
      includePnmImages,
      includePnmStatus,
      selectedField,
    } = displayOptions || {};

    const tags = pnm.get('tags') || List();
    const rank = `${pnmIndex + 1}`;
    const rankVariant = rank.length < 4 ? 'h6' : 'subtitle1';
    const councilId = isCdSite && !cobMode
      ? pnm.get('group_pnm_id')
      : pnm.get('leg_tech_pnm_id');
    const customFieldResult = pnm.get('customFieldResult', Map());
    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;
    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);

    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const enableNotes = isAdmin || !disableNotesFeature;
    const enableTags = isAdmin || allowVotingMembersToTag;

    let noteTagTooltipTitle;

    if (enableNotes && enableTags) {
      noteTagTooltipTitle = 'Write Note & Tag';
    } else if (enableNotes && !enableTags) {
      noteTagTooltipTitle = 'Write Note';
    } else if (!enableNotes && enableTags) {
      noteTagTooltipTitle = 'Tag';
    }

    return [
      <tr className={ classes.tableRow }
        key={ pnm.get('id') }
        ref={ provided.innerRef }
        { ...provided.dragHandleProps }
        { ...provided.draggableProps }>

        <TableCell padding='none'>
          <IconButton className={ classes.reorderIcon }
            onClick={ (event) => handlePopoverOpen(event, pnm, pnmIndex) }>
            <Icon color='primary'>swap_vert</Icon>
          </IconButton>
        </TableCell>

        <TableCell align='right' padding='none'>
          <Avatar className={ classes.rankIcon } style={ this.getStyle(pnm, 'avatar') }>
            <Typography variant={ rankVariant } color='inherit' align='center'>{ isEqualValues ? rank : pnm.get('scoreRank') }</Typography>
          </Avatar>
        </TableCell>

        {includePnmImages && (
          <TableCell align='right' padding='none'>
            <Avatar className={ classes.imageIcon } src={ pnm.get('image') } />
          </TableCell>
        )}

        {includePnmStatus && (
          <TableCell align='center'>
            <Typography color={ this.getStyle(pnm, 'text', tags) }>{ pnm.get('status') }</Typography>
          </TableCell>
        )}

        <TableCell align='left' className={ classes.firstNameCell }>
          <Typography color={ this.getStyle(pnm, 'text', tags) }>{ pnm.get('firstname') }</Typography>
        </TableCell>
        <TableCell align='left' padding='none'>
          <Typography color={ this.getStyle(pnm, 'text', tags) }>{ pnm.get('lastname') }</Typography>
        </TableCell>

        <TableCell align='left' padding='none'>
          <Typography color={ this.getStyle(pnm, 'text', tags) }>{ councilId }</Typography>
        </TableCell>
        {includePnmTags && (
          <TableCell align='left' padding='none' className={ classes.tagsCell }>
            <Grid container spacing={ 24 }>
              { tags.map((t) => (
                <Grid item xs={ 1 } key={ t.get('_id') }>
                  <Tag variant='mini'
                    count={ t.get('count') }
                    displayCount
                    title={ t.get('title') }
                    color={ t.get('color') } />
                </Grid>
              ))}
              { pnm.get('isLegacy') ? (
                <Grid item xs={ 1 }>
                  <Tooltip title='Legacy | From CampusDirector'>
                    <Icon color={ this.getStyle(pnm, 'text', tags) } className={ classes.legacyIcon }>star</Icon>
                  </Tooltip>
                </Grid>
              ) : null }
              <Grid item xs={ 2 } key='addNoteTag'>
                <IconButton className={ classes.addNoteTagIconButton }
                  onClick={ this.handleNoteTagModalOpen }>
                  <Tooltip title={ noteTagTooltipTitle }>
                    <Icon>add</Icon>
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <NoteTagModal clearanceLevel={ clearanceLevel }
              currentChapter={ currentChapter }
              disableNotesFeature={ !enableNotes }
              allowVotingMembersToTag={ enableTags }
              dialogOpen={ noteTagModalOpen }
              handleDialogClose={ this.handleNoteTagModalClose }
              notes={ List() }
              pnm={ pnm }
              teamLevel={ teamLevel } />
          </TableCell>
        )}
        {includeGPA1 && (
          <TableCell align='right' padding='none'>
            <Typography color={ this.getStyle(pnm, 'text', tags) }>{ pnm.get('gpa1') }</Typography>
          </TableCell>
        )}
        {includeGPA2 && (
          <TableCell align='right' padding='none'>
            <Typography color={ this.getStyle(pnm, 'text', tags) }>{ pnm.get('gpa2') }</Typography>
          </TableCell>
        )}

        <TableCell align='right' padding={ !rounds.size || !displayOverallScore ? 'dense' : 'none' }>
          <Typography color={ this.getStyle(pnm, 'text', tags) }>{ pnm.get('event') }</Typography>
        </TableCell>

        {selectedField && (
          selectedField.map((s) => s.value
            && (
            <TableCell key={ toString(s.value) }
              align='left'
              className={ classes.statusCell }>
              <Typography color={ this.getStyle(pnm, 'text', tags) }>
                {this.getStandardFieldValue(customFieldResult.get(toString(s.value), Map()))}
              </Typography>
            </TableCell>
            ))
        )}

        { displayOverallScore
          && (
          <TableCell align='right'
            padding='dense'>
            <Typography variant='subtitle1'
              color={ this.getStyle(pnm, 'text', tags) }>

              { extraColumnType
                ? this.getScoreValue(pnm.getIn(['overall', 'value', 'overallScore'], 'N/A'))
                : this.getScoreValue(pnm.getIn(['overall', 'value'], 'N/A'))}
            </Typography>

            <Grid container spacing={ 8 }>
              <Grid item xs={ 8 }>
                <Icon color={ this.getStyle(pnm, 'voteCount', tags) } className={ classes.voteCountIcon }>
                  thumbs_up_down
                </Icon>
              </Grid>
              <Grid item xs={ 4 }>
                <Typography color={ this.getStyle(pnm, 'text', tags) }>
                  { (pnm.get('overall') || Map()).get('voteCount', 0) }
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
          )}
        { this.renderExtraCell(['raw']) }
        { this.renderRoundCells() }
        {this.renderCategoryCells()}
      </tr>,
    ];
  }
}

const styles = (theme) => ({
  rankIcon: {
    float:           'right',
    height:          45,
    width:           45,
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  imageIcon: {
    float:  'right',
    height: 45,
    width:  45,
  },

  tableRow: {
    color:           'inherit',
    height:          48,
    display:         'table-row',
    verticalAlign:   'middle',
    backgroundColor: theme.palette.common.white,

    '&:hover': {
      backgroundColor: grey[200],
    },
  },

  firstNameCell: {
    padding: '0 0 0 24px',
  },

  statusCell: {
    padding: '0 0 0 26px',
  },

  tagsCell: {
    padding: '0 24px 0 0',
  },

  reorderIcon: {
    float: 'right',
  },

  pnmCell: {
    paddingRight: 0,
    marginBottom: -10,
  },

  legacyIcon: {
    fontSize:  18,
    marginTop: 0,
  },

  addNoteTagIconButton: {
    width:      25,
    height:     25,
    marginTop:  -3,
    marginLeft: 10,
    display:    'flex',
  },

  pnmInfoContainer: {
    marginTop: -10,
  },

  voteCountIcon: {
    fontSize: 20,
  },
});

export default withStyles(styles)(ResultsTableRow);
