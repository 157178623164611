import { connect } from 'react-redux';
import { Map } from 'immutable';

// action creators
import { fieldBulkUpdate, fieldBulkCreate } from '../../../../../../actions/fieldActions';

// selectors
import { isSubmitting, hasSubmitFailed, hasSubmitSucceeded } from '../../../../../../lib/selectors/formSelectors';
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';
import getStatusFromState from '../../../../../../lib/selectors/getStatusFromState';

// components
import FieldsForm from './FieldsForm';

const createInitialValues = (props) => {
  // fields are the array of field data coming from the API/app state
  const {
    chapterFields,
  } = props;

  return chapterFields.sortBy((f = Map()) => f.get('order'));
};

const mapStateToProps = (state, props) => ({
  fieldLoading:              getLoadingFromState('field')(state),
  fieldStatus:               getStatusFromState('field')(state),
  fieldsFormSubmitFailed:    hasSubmitFailed('fieldsForm')(state),
  fieldsFormSubmitSucceeded: hasSubmitSucceeded('fieldsForm')(
    state
  ),
  fieldsFormSubmitting: isSubmitting('fieldsForm')(state),
  initialValues:        Map({ fields: createInitialValues(props) }),
});

const mapDispatchToProps = dispatch => ({
  bulkUpdateFields: (items, chapter) => {
    dispatch(fieldBulkUpdate(items, chapter));
  },

  bulkCreateFields: (params) => {
    dispatch(fieldBulkCreate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldsForm);
