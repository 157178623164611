import { Map } from 'immutable';

// constants
import { CHAPTER_UPDATE, CHAPTER_FETCH_ALL, CHAPTER_CREATE } from '../constants/chapterTypes';
import { CURRENT_USER_FETCH } from '../constants/userTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case CHAPTER_FETCH_ALL: return httpReducer(state, action, { entity: 'chapter' });
    case CHAPTER_UPDATE: return httpReducer(state, action, { entity: 'chapter' });
    case CHAPTER_CREATE: return httpReducer(state, action, { entity: 'chapter', updateResult: false });
    case CURRENT_USER_FETCH: return httpReducer(state, action, { entity: 'chapter', updateResult: false });
    default: return state;
  }
};
