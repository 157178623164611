import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { noteDelete } from '../../../../actions/noteActions';

// components
import NotesTab from './NotesTab';

const mapStateToProps = state => ({
  note: state.get('note'),
});

const mapDispatchToProps = dispatch => ({
  deleteNote: (params) => {
    dispatch(noteDelete(params));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotesTab));
