import { connect } from 'react-redux';

// components
import Categories from './Categories';
import getOrganization from '../../../../lib/selectors/getOrganization';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  organization:   getOrganization(state),
});

export default connect(mapStateToProps)(Categories);
