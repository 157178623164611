import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import noteService from '../../sources/api/noteService';

// constants
import { NOTE_FETCH_FOR_PNM, NOTE_FETCH_FOR_PNM_INIT } from '../../constants/noteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { noteListSchema } from '../../schemas/note';

export function* noteFetchForPnm(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { pnmId, chapterId } = action.payload || {};

  const options = {
    query: {
      pnm:       pnmId,
      chapter:   chapterId,
      $populate: 'tags',

      $sort: {
        createdAt: -1,
      },
    },
  };

  try {
    yield* httpSaga(NOTE_FETCH_FOR_PNM, call(find, noteService, options), {
      schema: noteListSchema,
    });
  } catch (err) {
    yield put(error(NOTE_FETCH_FOR_PNM, err));
  } finally {
    yield put(doneIndicator(NOTE_FETCH_FOR_PNM));
  }
}

export function* watchNoteFetchForPnm() {
  yield takeEvery(NOTE_FETCH_FOR_PNM_INIT, noteFetchForPnm);
}

export default function* root() {
  yield all([
    fork(watchNoteFetchForPnm),
  ]);
}
