import { connect } from 'react-redux';

// action creators
import { inviteDelete, inviteUpdate } from '../../../../../../actions/inviteActions';

// components
import InviteItem from './InviteItem';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
});

const mapDispatchToProps = dispatch => ({
  deleteInvite: (id, chapter) => {
    dispatch(inviteDelete(id, chapter));
  },

  updateInvite: (params) => {
    dispatch(inviteUpdate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteItem);
