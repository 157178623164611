import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import grey from '@material-ui/core/colors/grey';
import { isNumber } from '../../../../lib/isNumber';

// local components
import TableActions from './components/TableActions';
import EnhancedTableHead from './components/EnhancedTableHead';
import TableToolbar from './components/TableToolbar';
import SliderPopover from '../../../SliderPopover';
import Banner from '../../../Banner';
import FixedRow from './components/FixedRow';
import ResultsTableRow from './components/ResultsTableRow';

// helpers
import getAllCategoriesFromListAddData from '../../helpers/getAllCategoriesFromListAddData';
import { isSubStrInStr } from '../../../../helpers/check';
import { toString } from '../../../../helpers/transform';
import isOverallScoreVisible from '../../helpers/isOverallScoreVisible';
import checkboxObjectToArrayWithTrueValues from '../../helpers/checkboxObjectToArrayWithTrueValues';

// function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const listArray = Array.from(list);

  // grab value of item to move at index
  const [removed] = listArray.splice(parseInt(startIndex, 10), 1);

  // insert removed item at the specified index
  listArray.splice(parseInt(endIndex, 10), 0, removed);
  return Immutable.fromJS(listArray);
};

class ResultsTable extends Component {
  static propTypes = {
    category:                  PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    chapter:                   PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    chapterTags:               PropTypes.instanceOf(List).isRequired, // eslint-disable-line
    classes:                   PropTypes.instanceOf(Object).isRequired,
    clearResults:              PropTypes.func.isRequired,
    currentChapter:            PropTypes.instanceOf(Map).isRequired,
    currentUser:               PropTypes.instanceOf(Map).isRequired,
    fetchResultsForChapter:    PropTypes.func.isRequired,
    field:                     PropTypes.instanceOf(Map).isRequired,
    filtersForm:               PropTypes.instanceOf(Map).isRequired,
    advancedDisplayOptionForm: PropTypes.instanceOf(Map).isRequired,
    hometownGroups:            PropTypes.instanceOf(List).isRequired,
    navigateToPNM:             PropTypes.func.isRequired,
    navigate:                  PropTypes.func.isRequired,
    organization:              PropTypes.instanceOf(Map).isRequired,
    customFields:              PropTypes.instanceOf(List).isRequired,
    result:                    PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    round:                     PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    roundCategory:             PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    rounds:                    PropTypes.instanceOf(List).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        allStatesLoaded: false,
        anchorEl:        null,
        order:           { rank: 'asc' },
        orderBy:         { rank: ['rank'] },
        page:            0,
        pnmIndex:        0,
        pnms:            [],
        popoverOpen:     false,
        rowsPerPage:     25,
      }),
    };
  }

  componentWillMount() {
    const {
      currentChapter, fetchResultsForChapter, filtersForm,
    } = this.props;

    const formValues = filtersForm.get('values') || Map();
    const resultsPnmsToInclude = formValues.get('resultsPnmsToInclude');
    const isActive = resultsPnmsToInclude === 0;

    const fetchParams = {
      chapter:       currentChapter.getIn(['data', 'id'], 0),
      includeFields: this.getIncludeFields(this.props),
    };

    if (isActive) {
      fetchParams.active = true;
    }

    if (currentChapter.get('data')) {
      fetchResultsForChapter(fetchParams);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentChapter, filtersForm,
      organization, result,
      fetchResultsForChapter,
    } = nextProps;

    const oldFormValues = this.props.filtersForm.get('values') || Map();
    const newFormValues = filtersForm.get('values') || Map();
    const oldIncludeFields = this.getIncludeFields(this.props);
    const newIncludeFields = this.getIncludeFields(nextProps);

    const newResultsPnmsToInclude = newFormValues.get('resultsPnmsToInclude');
    const oldResultsPnmsToInclude = oldFormValues.get('resultsPnmsToInclude');

    const isActive = newResultsPnmsToInclude === 0;
    const fetchParams = {
      chapter:       currentChapter.getIn(['data', 'id'], 0),
      includeFields: newIncludeFields,
    };

    if (isActive) {
      fetchParams.active = true;
    }

    if (!this.props.currentChapter.get('data') && currentChapter.get('data')) {
      fetchResultsForChapter(fetchParams);
    }

    if (currentChapter.get('data')
      && ((oldResultsPnmsToInclude >= 0
      && newResultsPnmsToInclude !== oldResultsPnmsToInclude)
      || newIncludeFields.length > oldIncludeFields.length)) {
      fetchResultsForChapter(fetchParams);
    }

    const newMoveLegaciesToTop = newFormValues.get('moveLegaciesToTop');
    const newMoveGradeRisksToBottom = newFormValues.get('moveGradeRisksToBottom');
    const newMoveByTagId = newFormValues.get('moveByTagId');
    const newMoveByTagToTop = newFormValues.get('moveByTagToTop');

    if (currentChapter.get('data') && result.get('data') && organization) {
      let pnms = this.state.data.get('pnms');
      const newLoading = nextProps.result.get('loading', false);
      const oldLoading = this.props.result.get('loading', false);

      if (!this.state.data.get('pnms').size
      || newFormValues !== oldFormValues || (!newLoading && oldLoading)) {
        const pnmsData = result.get('data') || List();
        pnms = this.formatPnmData(pnmsData, organization, nextProps);

        pnms = this.getInitialSorting(pnms);

        // assigns rank attribute using initial order (based on scores)
        //  NOTE: we do not want this rank to change within the results table
        pnms = pnms.map((p, index) => p.set('scoreRank', index + 1));

        pnms = pnms.filter((p) => this.shouldPnmDisplay(p, nextProps)) || List();

        pnms = pnms.map((p) => {
          const isGradeRisk = this.isGradeRisk(p, p.get('gpa1'), p.get('gpa2'), nextProps);
          return p.set('isGradeRisk', isGradeRisk);
        });

        pnms = this.getSortedPnms(
          pnms,
          newMoveLegaciesToTop,
          newMoveGradeRisksToBottom,
          newMoveByTagId,
          newMoveByTagToTop
        );
      }

      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('pnms', pnms);
        }),
      });
    }
  }

  componentWillUnmount() {
    const { clearResults } = this.props;

    clearResults();
  }

  onDragEnd = (result, oldPnms) => {
    // Handle dropping outside the table
    if (!result.destination) return;

    const page = this.state.data.get('page');
    const rowsPerPage = this.state.data.get('rowsPerPage');

    // Calculate actual indices in full dataset
    const sourceIndex = result.source.index + (page * rowsPerPage);
    const destinationIndex = result.destination.index + (page * rowsPerPage);

    const newPnms = reorder(
      oldPnms,
      sourceIndex,
      destinationIndex,
    );

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('popoverOpen', false);
        map.set('anchorEl', null);
        map.set('moveLegaciesToTop', false);
        map.set('moveGradeRisksToBottom', false);
        map.set('moveByTagId', null);
        map.set('moveByTagToTop', false);
        map.set('pnms', newPnms);
      }),
    });
  }

  getDisplayOverallScore = () => {
    const { advancedDisplayOptionForm, currentChapter, organization } = this.props || {};
    const advancedFormValues = advancedDisplayOptionForm.get('values') || Map();
    const displayOverallScores = Map.isMap(advancedFormValues)
      ? !!advancedFormValues.get('displayOverallScores') : false;

    return displayOverallScores && isOverallScoreVisible(currentChapter, organization);
  };

  getIncludeFields = ({
    filtersForm, advancedDisplayOptionForm, customFields,
  }) => {
    const includeFields = customFields.filter(
      (item) => ['year in college', 'college student status'].includes(
        item.getIn(['standardField', 'text'], '').toLowerCase()
      )
    ).map((item) => item.getIn(['standardField', 'id'])).toJS();
    const gpaFieldOne = filtersForm.getIn(['values', 'gpaFieldOne']);
    if (gpaFieldOne) includeFields.push(gpaFieldOne);
    const gpaFieldTwo = filtersForm.getIn(['values', 'gpaFieldTwo']);
    if (gpaFieldTwo && gpaFieldOne !== gpaFieldTwo) includeFields.push(gpaFieldTwo);
    includeFields.push(...advancedDisplayOptionForm.getIn(['values', 'selectedField'], List()).map((item) => item.get('value')));
    return includeFields;
  }

  // Handles overall and round score sorting, as well as initial sort direction
  getInitialSorting = (pnms) => {
    const { organization, rounds } = this.props;

    const scoreOrder = organization.get('order') || 'desc';
    const displayOverallScore = this.getDisplayOverallScore();

    let extraColumnType = '';
    const shouldDisplayRawScore = organization.get('overallScore') === 'mean + bonus round';
    const shouldDisplayPrePrefScore = organization.get('overallScore') === 'pref with pre-pref';

    if (shouldDisplayRawScore) {
      extraColumnType = 'raw';
    } else if (shouldDisplayPrePrefScore) {
      extraColumnType = 'prePref';
    }

    // While the UI appears to sort by rank,
    //  below the surface we're actually sorting by overall and each round score
    let initialOrderBy = displayOverallScore
      ? List().push(this.getPathForSorting('overall', ''))
      : List();

    let initialOrder = displayOverallScore
      ? Immutable.fromJS({
        overall: scoreOrder,
      })
      : Map();

    // Handle funky overall value objects for orgs with extra columns to sort
    if (extraColumnType.length) {
      initialOrderBy = List().push(this.getPathForSorting('overall', extraColumnType));

      if (shouldDisplayRawScore) {
        initialOrderBy = initialOrderBy.push(this.getPathForSorting('rawScore', extraColumnType));
        initialOrder = initialOrder.set('rawScore', scoreOrder);
      }
      if (shouldDisplayPrePrefScore) {
        initialOrderBy = initialOrderBy.push(this.getPathForSorting('prePref', extraColumnType));
        initialOrder = initialOrder.set('prePreferenceScore', scoreOrder);
      }
    }

    const sortedRounds = rounds.sortBy((r) => r.get('order')).reverse();

    sortedRounds.forEach((round) => {
      if (round.get('roundType') === 8 && !displayOverallScore) {
        // Don't sort round for KD roundtype 8
        return;
      }

      initialOrderBy = initialOrderBy.push(
        Immutable.fromJS([round.get('_id'), 'sortByRoundScores'])
      );
      initialOrder = initialOrder.set(round.get('_id'), scoreOrder);
    });

    let sortedPnms = pnms.sort(this.sortByMultipleKeys(initialOrderBy, initialOrder));

    const scorelessPnms = sortedPnms.filter((p) => p.getIn(['overall', 'voteCount']) === 0);
    const scoredPnms = sortedPnms.filter((p) => p.getIn(['overall', 'voteCount']) > 0);

    sortedPnms = scoredPnms.concat(scorelessPnms);

    return sortedPnms;
  }

  // Handles sorting PNMs by Legacy, Grade Risk and custom tag options
  getSortedPnms = (
    pnms,
    moveLegaciesToTop,
    moveGradeRisksToBottom,
    moveByTagId,
    moveByTagToTop
  ) => {
    const orderedPnms = pnms.map((pnm) => {
      let legacyOrderValue = 0;
      let gradeRiskOrderValue = 0;
      let customTagOrderValue = 0;

      const tags = pnm.get('tags') || List();
      if (moveLegaciesToTop && (pnm.get('isLegacy', false) || tags.find((t) => (t.get('title') || '').toLowerCase() === 'legacy'))) {
        legacyOrderValue = 1;
      }

      if (moveGradeRisksToBottom && pnm.get('isGradeRisk', false)) {
        gradeRiskOrderValue = -1;
      }

      if (moveByTagId && tags.find((t) => (t.get('_id') || '') === moveByTagId)) {
        if (moveByTagToTop) {
          customTagOrderValue = 1;
        } else {
          customTagOrderValue = -1;
        }
      }

      return {
        pnm,
        legacyOrderValue,
        gradeRiskOrderValue,
        customTagOrderValue,
      };
    });

    return orderedPnms
      .sort((a, b) => (b.legacyOrderValue - a.legacyOrderValue)
    || (b.gradeRiskOrderValue - a.gradeRiskOrderValue)
    || (b.customTagOrderValue - a.customTagOrderValue))
      .map((entry) => entry.pnm);
  };

  getRowType = (index, flexMinus, invitations, flexPlus) => {
    if (invitations > 0) {
      if (index === (invitations - flexMinus) && flexMinus > 0) {
        return 'flexMinus';
      }
      if (index === invitations) {
        return 'invitations';
      }
      if (index === (invitations + flexPlus) && flexPlus > 0) {
        return 'flexPlus';
      }
    }
    return '';
  }

  getStandardFieldValue = (standardFields, standardFieldId) => {
    const item = (standardFields || List()).find((s) => (s.get('standardField') || Map()).get('id') === standardFieldId);

    const type = ((item || Map()).get('standardField') || Map()).get('type');
    let optionsArray;
    let gpaItem;

    switch (type) {
      case 'text':
        gpaItem = item.get('text');
        break;
      case 'number':
        gpaItem = item.get('number');
        break;
      case 'select':
        optionsArray = (item.get('standardField') || Map()).get('options').split(',');
        gpaItem = optionsArray[item.get('selector')];
        break;
      default:
        gpaItem = 0;
        break;
    }
    return gpaItem;
  }

  getPathForSorting = (
    property,
    extraColumnType,
    {
      isItCategoryProperty = false,
      isItCustomFieldProperty = false,
    } = {}
  ) => {
    switch (property) {
      case 'firstname':
      case 'lastname':
      case 'id':
      case 'gpa1':
      case 'gpa2':
      case 'event':
      case 'status':
      case 'image':
        return Immutable.fromJS([property]);

      case 'overall':
        return extraColumnType
          ? Immutable.fromJS(['overall', 'value', 'overallScore'])
          : Immutable.fromJS(['overall', 'value']);

      case 'rawScore':
        return Immutable.fromJS(['overall', 'value', 'rawScore']);

      case 'prePref':
        return Immutable.fromJS(['overall', 'value', 'prePreferenceScore']);

      default:
        if (isItCustomFieldProperty) {
          return Immutable.fromJS([
            'customFieldResult',
            property,
            'text',
          ]);
        }

        return Immutable.fromJS([
          isItCategoryProperty ? 'roundCategoriesResult' : 'rounds',
          property,
          'value',
        ]);
    }
  }

  formatPnmData = (pnms, organization, props) => {
    const { filtersForm } = props;
    const formValues = filtersForm.get('values') || Map();

    const gpaFieldOne = formValues.get('gpaFieldOne');
    const gpaFieldTwo = formValues.get('gpaFieldTwo');

    // Each loop creates a row for a pnm
    const tableData = pnms.map((pnm) => {
      const gpa1 = this.getStandardFieldValue(pnm.get('standardFields'), gpaFieldOne);
      const gpa2 = this.getStandardFieldValue(pnm.get('standardFields'), gpaFieldTwo);

      // The data for a table row is returned
      return Immutable.fromJS({
        ...pnm.toJS(), gpa1, gpa2,
      });
    });

    // The list of rows is returned
    return Immutable.fromJS(tableData);
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ data: this.state.data.set('rowsPerPage', event.target.value) });
  };

  handleChangePage = (event, page) => {
    this.setState({ data: this.state.data.set('page', page) });
  };

  handlePopoverOpen = (event, pnm, index) => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('popoverOpen', true);
        map.set('anchorEl', event.currentTarget);
        map.set('pnmIndex', index);
      }),
    });
  }

  handlePopoverClose = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('popoverOpen', false);
        map.set('anchorEl', null);
      }),
    });
  }

  handleChangeOrder = (oldRank, pnms, newRank) => {
    const newPnms = Immutable.fromJS(reorder(pnms, oldRank, newRank - 1));

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('popoverOpen', false);
        map.set('anchorEl', null);
        map.set('moveLegaciesToTop', false);
        map.set('moveGradeRisksToBottom', false);
        map.set('moveByTagId', null);
        map.set('moveByTagToTop', false);
        map.set('pnms', newPnms);
      }),
    });
  }

  sortByMultipleKeys = (keys, order) => (a, b) => {
    if (!keys.size) return 0; // force to equal if keys run out

    const currentKey = keys.first(); // take out the first key

    let valueA = a.getIn(currentKey.toJS());
    let valueB = b.getIn(currentKey.toJS());

    if (currentKey.last() === 'sortByRoundScores') {
      const roundId = currentKey.first().toString();
      valueA = a.getIn(['rounds', roundId, 'value'], 'N/A');
      if (valueA === null) {
        valueA = 'N/A';
      }
      valueB = b.getIn(['rounds', roundId, 'value'], 'N/A');
      if (valueB === null) {
        valueB = 'N/A';
      }
    } else if (typeof a === 'string') {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }
    // Sort the scores!
    // One is scoreless? Send em to hell
    if (valueA === 'N/A' && valueB !== 'N/A') {
      return 1; // sort b to lower index than a
    } if (valueB === 'N/A' && valueA !== 'N/A') {
      return -1; // sort a to lower index than b
    }

    // Sort differently based on direction
    if (order.get(currentKey.first()) === 'asc') {
      // Sort using both values
      if (valueA < valueB) {
        return -1;
      } if (valueA > valueB) {
        return 1;
      }
    } else if (order.get(currentKey.first()) === 'desc') {
      // Sort using both values
      if (valueB < valueA) {
        return -1;
      } if (valueB > valueA) {
        return 1;
      }
    }

    // valueA and valueB are equal? Move on to the next attribute to sort by
    return this.sortByMultipleKeys(keys.slice(1), order)(a, b);
  }

  handleRequestSort = (event, property, pnms) => {
    const { organization, rounds } = this.props;
    const isItCategoryProperty = toString(property).match(/^categoryId/gi);
    const isItCustomFieldProperty = toString(property).match(/^customFieldId/gi);
    const propertyWithoutSlag = isItCategoryProperty || isItCustomFieldProperty
      ? toString(property).replace(
        /^(categoryId|customFieldId)/gi,
        ''
      ) : toString(property);
    const order = this.state.data.get('order', Map());
    const roundIds = rounds.map((r) => r.get('_id', '').toString());

    let newPnms = pnms;
    let newOrderBy = Map();
    let newOrder = Map();
    let extraColumnType = '';
    const shouldDisplayRawScore = organization.get('overallScore') === 'mean + bonus round';
    const shouldDisplayPrePrefScore = organization.get('overallScore') === 'pref with pre-pref';
    const pathToProperty = this.getPathForSorting(
      propertyWithoutSlag,
      extraColumnType,
      {
        isItCategoryProperty,
        isItCustomFieldProperty,
      }
    );

    if (shouldDisplayRawScore) {
      extraColumnType = 'raw';
    } else if (shouldDisplayPrePrefScore) {
      extraColumnType = 'prePref';
    }

    /* Changing order happens in 2 states:
      1) First click: sorts column in descending order
      2) Second click: sorts column in ascending order
      --> then back to 1)
    */

    if (!order.get(property) || order.get(property) === 'asc') {
      newOrder = newOrder.set(property, 'desc');
    } else if (order.get(property) === 'desc') {
      newOrder = newOrder.set(property, 'asc');
    }

    newOrderBy = newOrderBy.set(property, pathToProperty);
    newPnms = pnms.sortBy((p) => p.getIn(pathToProperty.toJS(), List()));

    if (newOrder.get(property) === 'desc') {
      newPnms = newPnms.reverse();
    }

    if (roundIds.includes(property)) {
      // Moves score-less PNMs to the bottom of results
      //  when sorting by score-based columns
      const scoreLessPnms = newPnms.filter((p) => p.getIn(['rounds', property, 'voteCount'], 0) === 0);
      const scoredPnms = newPnms.filter((p) => p.getIn(['rounds', property, 'voteCount'], 0) > 0);

      newPnms = scoredPnms.concat(scoreLessPnms);
    }

    if (isItCategoryProperty) {
      // Moves score-less PNMs to the bottom of results
      //  when sorting by score-based columns
      const scoreLessPnms = newPnms.filter((p) => p.getIn(['roundCategoriesResult', propertyWithoutSlag, 'voteCount'], 0) === 0);
      const scoredPnms = newPnms.filter((p) => p.getIn(['roundCategoriesResult', propertyWithoutSlag, 'voteCount'], 0) > 0);

      newPnms = scoredPnms.concat(scoreLessPnms);
    }

    if (isItCustomFieldProperty) {
      // Moves score-less PNMs to the bottom of results
      //  when sorting by score-based columns
      const scoreLessPnms = newPnms.filter((p) => p.getIn(['customFieldResult', propertyWithoutSlag, 'text'], '') === '');
      const scoredPnms = newPnms.filter((p) => p.getIn(['customFieldResult', propertyWithoutSlag, 'text'], '') !== '');

      newPnms = scoredPnms.concat(scoreLessPnms);
    }

    if (property === 'overall') {
      // Moves score-less PNMs to the bottom of results
      //  when sorting by score-based columns
      const scoreLessPnms = newPnms.filter((p) => p.getIn(['overall', 'voteCount']) === 0);
      const scoredPnms = newPnms.filter((p) => p.getIn(['overall', 'voteCount']) > 0);

      newPnms = scoredPnms.concat(scoreLessPnms);
    }

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('pnms', newPnms);
        map.set('order', newOrder);
        map.set('orderBy', newOrderBy);
      }),
    });
  };

  isSearchValueFoundInPnm = (searchValue, pnm) => {
    if (!searchValue || !pnm || !Map.isMap(pnm)) {
      return true;
    }

    const firstname = pnm.get('firstname') || '';
    const lastname = pnm.get('lastname') || '';
    const legTechPnmId = pnm.get('leg_tech_pnm_id') || '';

    return (
      isSubStrInStr(searchValue, legTechPnmId)
      || isSubStrInStr(searchValue, `${firstname} ${lastname}`)
      || isSubStrInStr(searchValue, `${lastname} ${firstname}`)
    );
  };

  sortByEvent = (event, selectedEvent) => {
    let includePnmByEvent = true;

    const pnmHasSelectedEvent = !!(selectedEvent.includes(event));

    if (
      (Array.isArray(selectedEvent) && selectedEvent.length > 0)
      || (List.isList(selectedEvent) && selectedEvent.size > 0)
    ) {
      includePnmByEvent = pnmHasSelectedEvent;
    }

    if (selectedEvent === 0) {
      includePnmByEvent = pnmHasSelectedEvent;
    }
    return includePnmByEvent;
  };

  shouldPnmDisplay = (pnm, props) => {
    const { chapterTags, currentChapter, filtersForm } = props;
    const formValues = filtersForm.get('values') || Map();

    const isCdSite = currentChapter.getIn(['data', 'isCdSite']);
    const tags = pnm.get('tags') || List();
    const event = pnm.get('event');
    const pnmHasLegacyTag = Boolean(tags.find((t) => (t.get('title') || '').toLowerCase() === 'legacy'));
    const chapterLegacyTag = (isCdSite
      ? chapterTags.find((ct) => ct.get('title', '').toLowerCase() === 'legacy'
        && ct.get('type') === 'campusDirector')
      : chapterTags.find((ct) => ct.get('title', '').toLowerCase() === 'legacy')) || Map();

    // Pools: cd_pool_id of 0 is Primary, 1 is Secondary
    //  We subtract one from the form setting to account for the All Pools option
    const poolSetting = formValues.get('resultsPoolType') - 1;
    const inPool = poolSetting === pnm.get('cd_pool_id') || poolSetting === -1;
    const searchPnmFilter = formValues.get('searchPnmFilter');
    const selectedEvent = formValues.get('selectedEvent', 0) || List();
    const includeSetting = formValues.get('resultsPnmsToInclude');
    let includePnm;

    switch (includeSetting) {
      case 1: // Include only legacies
        includePnm = pnmHasLegacyTag || pnm.get('isLegacy');
        break;
      case 2: // Include only withdrawn PNMs
        includePnm = (pnm.get('cd_withdraw') !== 9999) || (pnm.get('status') === 'WD');
        break;
      default:
        includePnm = true;
        break;
    }

    // Include only pnms with selected tags
    const selectedTags = formValues.get('selectedTags', '') || List();
    const pnmHasSelectedTag = tags.some((tag) => selectedTags.includes(tag.get('_id', '').toString()));

    let includePnmByTag = true;
    if (((List.isList(selectedTags) && selectedTags.size > 0) || selectedTags.length)
    && !(selectedTags.includes(chapterLegacyTag.get('_id', '').toString())
      && (pnmHasLegacyTag || pnm.get('isLegacy')))) {
      includePnmByTag = pnmHasSelectedTag;
    }

    return !!(
      inPool
      && includePnmByTag
      && includePnm
      && this.sortByEvent(event, selectedEvent)
      && this.isSearchValueFoundInPnm(searchPnmFilter, pnm)
    );
  }

  isGradeRisk = (pnm, rawGpa1, rawGpa2, props) => {
    const { filtersForm, organization } = props;

    if (organization.get('disableGradeRiskFeature')) {
      return false;
    }

    const formValues = filtersForm.get('values') || Map();
    const gpa1 = parseFloat(rawGpa1);
    const gpa2 = parseFloat(rawGpa2);

    const tags = pnm.get('tags') || List();

    const risk1 = parseFloat(formValues.get('gpaRiskOne') || 0);
    const risk2 = parseFloat(formValues.get('gpaRiskTwo') || 0);

    let isFirstYear = false;
    let yearFieldsExist = false;

    const pnmStandardFieldsList = pnm.get('standardFields') || List();
    yearFieldsExist = (
      pnmStandardFieldsList.find(
        (sf) => ((sf.getIn(['standardField', 'text']) || '').toLowerCase() === 'year in college')
            || ((sf.getIn(['standardField', 'text']) || '').toLowerCase() === 'college student status')
            || ((sf.getIn(['standardField', 'text']) || '').toLowerCase() === 'classification')
      ) || Map()
    ).size > 0;

    // isFirstYear checks 2 conds using standard fields:
    //  1) The existence and values of a Year in College field
    //  2) The existence and values of a College Student Status or Classification field
    const standardFields = pnm.get('standardFields') || List();
    isFirstYear = (
      standardFields.find((sf) =>
        // Condition 1: Year in College - checks if value is First Year, or Freshman
        (
          (sf.getIn(['standardField', 'text']) || '').toLowerCase() === 'year in college'
          && (
            (
              this.getStandardFieldValue(
                pnm.get('standardFields') || Map(),
                sf.getIn(['standardField', 'id'])
              ) || ''
            ).toLowerCase() === 'first year'

            || (
              this.getStandardFieldValue(
                pnm.get('standardFields') || Map(),
                sf.getIn(['standardField', 'id'])
              ) || ''
            ).toLowerCase() === 'freshman'
          )
        )
        // Condition 2: College Student Status or Classification- checks if value is Freshman
        || (
          (
            (sf.getIn(['standardField', 'text']) || '').toLowerCase() === 'college student status'
            || (sf.getIn(['standardField', 'text']) || '').toLowerCase() === 'classification'
          )
          && (
            this.getStandardFieldValue(
              pnm.get('standardFields') || Map(),
              sf.getIn(['standardField', 'id'])
            ) || ''
          ).toLowerCase() === 'freshman'
        )
      ) || Map() // eslint-disable-line function-paren-newline
    ).size > 0;

    if (tags.find((t) => (t.get('title') || '').toLowerCase() === 'grade risk')) {
      return true;
    } if (yearFieldsExist) {
      /* check for gpa1 grade risks using year in college,
        marking high school grade risk for first years only
        and college gpa as a grade risk for upperclassmen only
       (assuming that the gpa1 field is High School GPA)
      */
      if (isFirstYear) {
        if (gpa1 && isNumber(gpa1) && gpa1 < risk1) {
          return true;
        }
      } else if (gpa2 && isNumber(gpa2) && gpa2 < risk2) {
        // sophomores and up don't get marked grade risk on GPA 1
        return true;
      }
    } else if ((gpa1 && (typeof gpa1 === 'number') && gpa1 < risk1) || (gpa2 && (typeof gpa2 === 'number') && gpa2 < risk2)) {
      return true;
    }
    return false;
  }

  shouldDisplayFixed = (index, flexMinus, invitation, flexPlus) => {
    if (invitation > 0) {
      if (index === (invitation - flexMinus) && flexMinus > 0) {
        return true;
      }
      if (index === invitation) {
        return true;
      }
      if (index === (invitation + flexPlus) && flexPlus > 0) {
        return true;
      }
    }
    return false;
  }

  renderPopover = (pnms) => {
    const popoverOpen = this.state.data.get('popoverOpen');
    const anchorEl = this.state.data.get('anchorEl');
    const index = parseInt(this.state.data.get('pnmIndex'), 10);
    const pnm = pnms.get(index, Map());

    return (
      <SliderPopover key={ index }
        popoverOpen={ popoverOpen }
        handleSubmit={ this.handleChangeOrder }
        handlePopoverClose={ this.handlePopoverClose }
        anchorEl={ anchorEl }
        defaultValue={ index + 1 }
        size={ pnms.size }
        item={ pnm }
        label={ `${pnm.get('firstname')} ${pnm.get('lastname')}` }
        items={ pnms } />
    );
  }

  renderLoadingIndicator = (colSpan) => {
    const {
      classes, result,
    } = this.props;

    const loading = result.get('loading');

    let element = null;

    if (loading) {
      element = (
        <TableRow>
          <TableCell colSpan={ colSpan } className={ classes.spinner }>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    return element;
  }

  render() {
    const {
      chapterTags,
      classes,
      currentChapter,
      currentUser,
      field,
      filtersForm,
      advancedDisplayOptionForm,
      hometownGroups,
      navigateToPNM,
      navigate,
      organization,
      result,
      rounds,
      customFields,
    } = this.props;

    const customFieldsList = List.isList(customFields) ? customFields : List();
    const categoriesList = getAllCategoriesFromListAddData(organization);
    const sortedRounds = rounds.sortBy((r) => r.get('order')).reverse();
    const shouldDisplayRawScore = organization.get('overallScore') === 'mean + bonus round';
    const shouldDisplayPrePrefScore = organization.get('overallScore') === 'pref with pre-pref';
    const overallScoreLabel = organization.get('overallScoreLabel');
    let extraColumnType = '';

    const fields = field.getIn(['data', 'items'], List());

    if (shouldDisplayRawScore) {
      extraColumnType = 'raw';
    } else if (shouldDisplayPrePrefScore) {
      extraColumnType = 'prePref';
    }

    const pnms = this.state.data.get('pnms') || List();
    const isCdSite = currentChapter.getIn(['data', 'isCdSite']) || false;
    const cobMode = currentChapter.getIn(['data', 'cobMode']) || false;
    const numberOfVotingMembers = currentChapter.getIn(['data', 'numberOfVotingMembers']);
    const teamLevel = (currentChapter.get('data') || Map()).get('team_level') || 0;
    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;

    const rowsPerPage = this.state.data.get('rowsPerPage');
    const rowsPerPageOptions = [5, 10, 25, 50, 100];
    const page = this.state.data.get('page');
    const order = this.state.data.get('order');
    const orderBy = this.state.data.get('orderBy');

    const formValues = filtersForm.get('values') || Map();
    const advancedFormValues = advancedDisplayOptionForm.get('values') || Map();
    const invitations = parseInt((formValues.get('invitations')), 10);
    const invitationIndex = invitations - 1;

    const flexPlus = parseInt((formValues.get('flexPlus')), 10);
    const flexMinus = parseInt((formValues.get('flexMinus')), 10);

    const displayOverallScore = this.getDisplayOverallScore();

    const currentRound = currentChapter.getIn(['data', 'currentRound']) || 0;
    const isCurrentRoundUsed = (organization.get('overallScore') || '').includes('current round');
    const requiredFields = organization.get('requiredFields', List());

    let disableNotesFeature;
    let allowVotingMembersToTag;

    if (organization) {
      disableNotesFeature = organization.get('disableNotesFeature');
      allowVotingMembersToTag = organization.get('allowVotingMembersToTag');
    }

    if (!disableNotesFeature) {
      disableNotesFeature = currentChapter.getIn(['data', 'disableNotesFeature'], false);
    }
    if (!allowVotingMembersToTag) {
      allowVotingMembersToTag = currentChapter.getIn(['data', 'allowVotingMembersToTag'], false);
    }

    const displayOptions = Map.isMap(advancedFormValues) ? {
      includeGPA1:               !!advancedFormValues.get('includeGPA1'),
      includeGPA2:               !!advancedFormValues.get('includeGPA2'),
      includePnmTags:            !!advancedFormValues.get('includePnmTags'),
      includePnmImages:          !!advancedFormValues.get('includePnmImages'),
      includePnmStatus:          !!advancedFormValues.get('includePnmStatus'),
      displayOverallScores:      !!advancedFormValues.get('displayOverallScores'),
      displayCategoryScores:     !!advancedFormValues.get('displayCategoryScores'),
      displayCategoryScoresList: checkboxObjectToArrayWithTrueValues(
        advancedFormValues.get('displayCategoryScoresList', Map()).toJS()
      ).filter((item) => categoriesList.find((category) => toString(category.get('_id')) === item)),
      displayRoundScores:     !!advancedFormValues.get('displayRoundScores'),
      displayRoundScoresList: checkboxObjectToArrayWithTrueValues(
        advancedFormValues.get('displayRoundScoresList', Map()).toJS()
      ).filter((item) => rounds.find((round) => toString(round.get('_id')) === item)),
      selectedField: advancedFormValues.get('selectedField', List()).toJS()
        .filter((item) => item && item.value && customFieldsList.find((customField) => customField.getIn(['standardField', 'id'], 0) === item.value)),
    } : {};
    const colSpanArr = [
      9, (displayOverallScore ? 1 : 0),
      (extraColumnType ? 1 : 0), rounds.size,
      displayOptions.selectedField.length,
    ];
    const colSpan = colSpanArr.reduce((partialSum, a) => partialSum + a, 0);

    return (
      <div>
        {
          (!numberOfVotingMembers && requiredFields.includes('numberOfVotingMembers') && isAdmin)
          && (
          <div style={ { marginTop: 24 } }>
            <Banner title='Set Voting Member Count'
              subtitle={
                'Your organization\'s results require you to input the number of active voting members in your chapter. Ensure accurate vote results by setting it!'
              }
              buttonIcon='group'
              buttonLabel='Set Voting Members'
              onButtonClick={ () => navigate('/settings/chapter') } />
          </div>
          )
        }

        <Paper className={ classes.root }>
          <TableToolbar chapterTags={ chapterTags }
            currentRound={ currentRound }
            cobMode={ cobMode }
            displayOverallScore={ displayOverallScore }
            extraColumnType={ extraColumnType }
            fields={ fields }
            flexMinus={ flexMinus }
            flexPlus={ flexPlus }
            hometownGroups={ hometownGroups }
            icon='thumbs_up_down'
            invitations={ invitations }
            isCdSite={ isCdSite }
            isCurrentRoundUsed={ isCurrentRoundUsed }
            pnms={ pnms }
            rounds={ sortedRounds }
            categories={ categoriesList }
            displayOptions={ displayOptions }
            subtitle='Click, drag, and filter to set your order, then export your list'
            title='Results' />

          <div className={ classes.tableWrapper }>
            <DragDropContext onDragEnd={ (res) => this.onDragEnd(res, pnms) }>

              <Droppable droppableId='droppable'>
                { (providedDrop) => (

                  <div ref={ providedDrop.innerRef }>

                    <Table className={ classes.table }>
                      <EnhancedTableHead order={ order }
                        numCols={ colSpan }
                        orderBy={ orderBy }
                        rounds={ sortedRounds }
                        currentRound={ currentRound }
                        displayOverallScore={ displayOverallScore }
                        overallScoreLabel={ overallScoreLabel }
                        extraColumnType={ extraColumnType }
                        pnms={ pnms }
                        categories={ categoriesList }
                        onRequestSort={ this.handleRequestSort }
                        displayOptions={ displayOptions }
                        rowCount={ pnms.size || 0 } />

                      { this.renderPopover(pnms) }

                      <TableBody className={ classes.placeholder }>

                        {[!pnms.size && !result.get('loading') && (
                        <TableRow className={ classes.tableRow }>
                          <TableCell colSpan={ 1 } padding='dense'>
                            <Icon>error_outline</Icon>
                          </TableCell>
                          <TableCell colSpan={ colSpan - 1 } padding='none'>
                            <Typography variant='subtitle1'>No PNMs Found.</Typography>
                          </TableCell>
                        </TableRow>
                        ),
                        result.get('loading')
                          ? this.renderLoadingIndicator(colSpan)
                          : pnms.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                            .map((p, index) => [
                              <Draggable key={ p.get('id') }
                                draggableId={ p.get('id') }
                                index={ index }>

                                { (providedDrag) => [
                                  <ResultsTableRow displayOverallScore={ displayOverallScore }
                                    extraColumnType={ extraColumnType }
                                    form={ filtersForm }
                                    handlePopoverOpen={ this.handlePopoverOpen }
                                    cobMode={ cobMode }
                                    isCdSite={ isCdSite }
                                    key={ p.get('id') }
                                    navigateToPNM={ navigateToPNM }
                                    pnm={ p }
                                    pnmIndex={ (page * rowsPerPage) + index }
                                    provided={ providedDrag }
                                    displayOptions={ displayOptions }
                                    categories={ categoriesList }
                                    currentChapter={ currentChapter }
                                    currentUser={ currentUser }
                                    disableNotesFeature={ disableNotesFeature }
                                    allowVotingMembersToTag={ allowVotingMembersToTag }
                                    rounds={ sortedRounds } />,

                                  providedDrag.placeholder ? (
                                    <TableRow className={ classes.placeholderRow } key={ `${p.get('id')}_placeholder` }>
                                      <TableCell padding='dense' colSpan={ colSpan }>
                                        {providedDrag.placeholder}
                                      </TableCell>
                                    </TableRow>
                                  ) : null,
                                ]}
                              </Draggable>,

                              this.shouldDisplayFixed(
                                (page * rowsPerPage) + index,
                                flexMinus,
                                invitationIndex,
                                flexPlus
                              )
                            && (
                            <FixedRow key={ `${p.get('id')}_fixed` }
                              order={ order }
                              colSpan={ colSpan }
                              type={
                                this.getRowType(
                                  (page * rowsPerPage) + index,
                                  flexMinus,
                                  invitationIndex,
                                  flexPlus
                                )
                              } />
                            ),
                            ]),
                        ]}
                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          <TablePagination classes={ { toolbar: classes.footer } }
                            colSpan={ colSpan }
                            count={ pnms.size }
                            rowsPerPage={ rowsPerPage }
                            rowsPerPageOptions={ rowsPerPageOptions }
                            page={ page }
                            onChangePage={ this.handleChangePage }
                            onChangeRowsPerPage={ this.handleChangeRowsPerPage }
                            ActionsComponent={ TableActions } />
                        </TableRow>
                      </TableFooter>
                    </Table>

                    {providedDrop.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Paper>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width:        '100%',
    marginTop:    theme.spacing.unit * 3,
    borderRadius: 10,
  },

  table: {
    minWidth:       500,
    borderCollapse: 'separate',
  },

  tableWrapper: {
    overflowX: 'scroll', // make this property auto/scroll to enable inner-table scrolling
  },

  tableRow: {
    color:           'inherit',
    height:          48,
    display:         'table-row',
    verticalAlign:   'middle',
    backgroundColor: theme.palette.common.white,
  },

  placeholderRow: {
    border: 'none',
  },

  placeholder: {
    backgroundColor: grey[300],
  },

  spinner: {
    textAlign: 'center',
  },

  footer: {
    float: 'left',
  },
});

export default withStyles(styles)(ResultsTable);
