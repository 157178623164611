export const CURRENT_CHAPTER_SET = 'CURRENT_CHAPTER_SET';

export const CURRENT_CHAPTER_CLEAR = 'CURRENT_CHAPTER_CLEAR';

export const CURRENT_CHAPTER_FETCH = 'CURRENT_CHAPTER_FETCH';

export const CURRENT_CHAPTER_FETCH_FOR_ADMIN_INIT = 'CURRENT_CHAPTER_FETCH_FOR_ADMIN_INIT';
export const CURRENT_CHAPTER_FETCH_FOR_ADMIN = 'CURRENT_CHAPTER_FETCH_FOR_ADMIN';
export const CURRENT_CHAPTER_FETCH_FOR_VOTES = 'CURRENT_CHAPTER_FETCH_FOR_VOTES';
export const CURRENT_CHAPTER_FETCH_FOR_VOTES_INIT = 'CURRENT_CHAPTER_FETCH_FOR_VOTES_INIT';

export const CHAPTER_UPDATE_INIT = 'CHAPTER_UPDATE_INIT';
export const CHAPTER_UPDATE = 'CHAPTER_UPDATE';

export const CHAPTER_FETCH_ALL = 'CHAPTER_FETCH_ALL';
export const CHAPTER_FETCH_ALL_INIT = 'CHAPTER_FETCH_ALL_INIT';
export const CHAPTER_FETCH_ALL_DONE = 'CHAPTER_FETCH_ALL_DONE';

export const CHAPTER_CREATE = 'CHAPTER_CREATE';
export const CHAPTER_CREATE_INIT = 'CHAPTER_CREATE_INIT';
export const CHAPTER_CREATE_DONE = 'CHAPTER_CREATE_DONE';
