import { HOMETOWN_GROUP_PNM_CREATE_INIT, HOMETOWN_GROUP_PNM_FETCH_INIT } from '../constants/hometownGroupPnmTypes';

export function hometownGroupPnmCreate(params) {
  return {
    type:    HOMETOWN_GROUP_PNM_CREATE_INIT,
    payload: params,
  };
}

export function hometownGroupPnmFetch(params) {
  return {
    type:    HOMETOWN_GROUP_PNM_FETCH_INIT,
    payload: params,
  };
}
