import { reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import validate from 'validate.js';

// components
import NoteTagForm from './NoteTagForm';

const mapStateToProps = state => ({
  organization: state.get('organization'),
});

// schemas
const schema = {
  comment: {
    length: {
      maximum: 1000,
    },

    presence: (value, attributes) => {
      const { tags } = attributes || {};

      let validation;

      if (!value && !tags) {
        validation = { message: 'or tags is required' };
      }

      return validation;
    },
  },

  tags: {
    presence: (value, attributes) => {
      const { comment } = attributes || {};

      let validation;

      if ((value || []).length === 0 && !comment) {
        validation = { message: 'or comment is required' };
      }

      return validation;
    },
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedNoteTagForm = reduxForm({
  form:     'noteTag',
  validate: validateForm,
  onChange: handleChange,
})(NoteTagForm);

export default connect(mapStateToProps)(wrappedNoteTagForm);
