import { connect } from 'react-redux';
import { Map } from 'immutable';
import { isDirty } from 'redux-form/immutable';

// action creators
import { fetchPnmsToConfirm, confirmPnms } from '../../../../actions/reportingActions';

import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getStatusFromState from '../../../../lib/selectors/getStatusFromState';
import getErrorsFromState from '../../../../lib/selectors/getErrorsFromState';
import getErrorFromState from '../../../../lib/selectors/getErrorFromState';

// components
import NewMemberCard from './NewMemberCard';

const mapStateToProps = state => ({
  currentChapter:   state.get('currentChapter'),
  dirty:            Boolean(isDirty('memberInfoForm')),
  memberDateForm:   state.getIn(['form', 'memberDateForm'], Map()),
  memberInfoForm:   state.getIn(['form', 'memberInfoForm'], Map()),
  reportingData:    state.getIn(['reporting', 'data'], Map()),
  reportingMessage: state.getIn(['reporting', 'message'], ''),
  pnmLoading:       getLoadingFromState('pnm')(state),
  reportingLoading: getLoadingFromState('reporting')(state),
  reportingStatus:  getStatusFromState('reporting')(state),
  reportingErrors:  getErrorsFromState('reporting')(state),
  reportingError:   getErrorFromState('reporting')(state),
});

const mapDispatchToProps = dispatch => ({
  fetchPnmsToConfirm: (params) => {
    dispatch(fetchPnmsToConfirm(params));
  },
  confirmPnms: (params) => {
    dispatch(
      confirmPnms(params)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMemberCard);
