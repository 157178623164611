import { connect } from 'react-redux';

// action creators
import { chapterUpdate } from '../../../../actions/chapterActions';
import getRounds from '../../../../lib/selectors/getRounds';

// components
import MainCardHeader from './MainCardHeader';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  rounds:         getRounds(state),
});

const mapDispatchToProps = dispatch => ({
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCardHeader);
