import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List, Map } from 'immutable';
import { Field } from 'redux-form/immutable';
import { denormalize } from 'normalizr';

// components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Autosuggest from '../../../Autosuggest';
import SelectInput from '../../../SelectInput';

// schemas
import { userListSchema } from '../../../../schemas/user';

class AdminVoteOptions extends Component {
  static propTypes = {
    classes:          PropTypes.instanceOf(Object).isRequired,
    chapter:          PropTypes.instanceOf(Map).isRequired,
    currentChapterId: PropTypes.number.isRequired,
    currentUser:      PropTypes.instanceOf(Map).isRequired,
    fetchUsers:       PropTypes.func.isRequired,
    rounds:           PropTypes.instanceOf(List).isRequired,
    user:             PropTypes.instanceOf(Map).isRequired,
  }

  getRoundOptions = () => {
    const { rounds } = this.props;
    const items = [];

    rounds.forEach((round) => {
      items.push({
        value: round.get('_id'),
        label: round.get('name'),
      });
    });

    return items;
  }

  getSuggestions = () => {
    const { currentUser, chapter, user } = this.props;

    const suggestions = [];
    const currentUserId = (currentUser.get('data') || Map()).get('id');

    const userData = user.get('data') || Map();
    const result = (userData.get('result') || List()).toJS();

    if (userData.get('result')) {
      const users = (user.get('data') || Map()).get('items') || List();
      const chapters = (chapter.get('data') || Map()).get('items') || List();

      const entities = {
        user:    users.toJS(),
        chapter: chapters.toJS(),
      };

      const items = denormalize(result, userListSchema, entities);

      items.forEach((item = {}) => {
        const suggestion = {
          label: `${item.firstname} ${item.lastname}`,
          value: item.id,
        };

        if (item.id === currentUserId) { // prioritizes current user in suggestions
          suggestions.unshift(suggestion);
        } else {
          suggestions.push(suggestion);
        }
      });
    }

    return suggestions;
  }

  handleUpdateSuggestions = ({ value }) => {
    const { currentChapterId, fetchUsers } = this.props;

    fetchUsers({ search: value, chapter: currentChapterId });
  }

  handleClearSuggestions = () => { }

  render() {
    const { classes, currentUser } = this.props;

    const currentUserId = (currentUser.get('data') || Map()).get('id');
    const currentUserName = `${(currentUser.get('data') || Map()).get('firstname')} ${(currentUser.get('data') || Map()).get('lastname')}`;

    return (
      <Grid container spacing={ 24 } justify='center'>
        <Grid item xs={ 12 } align='center'>
          <Typography className={ classes.inlineTitleText } color='primary' variant='button'>
            Admins:
          </Typography>
          <Typography className={ classes.inlineText }>
            You have the privilege to submit votes on behalf of other users for any round.
          </Typography>

          <br />

          <Typography className={ classes.inlineTitleText } color='error' variant='button'>
            Caution:
          </Typography>
          <Typography className={ classes.inlineText }>
            This will override any previous votes for that user/round without warning.
          </Typography>
          <br />
        </Grid>

        <Grid item xs={ 12 } align='center'>
          <Grid item xs={ 12 } sm={ 4 } align='left'>
            <Field name='round'
              label='Choose Round'
              variant='outlined'
              helpertext='Vote will submit for selected round'
              component={ SelectInput }
              options={ this.getRoundOptions() }
              required />
          </Grid>
        </Grid>

        <Grid item xs={ 12 } align='center'>
          <Grid item xs={ 12 } sm={ 4 }>
            <Field name='user'
              label='Search for users'
              helperText='To vote normally, select your name'
              defaultSuggestion={ { label: currentUserName, value: currentUserId } }
              component={ Autosuggest }
              suggestions={ this.getSuggestions() }
              onSuggestionsFetchRequested={ this.handleUpdateSuggestions }
              onSuggestionsClearRequested={ this.handleClearSuggestions } />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  voteOptionsContainer: {
    padding: 25,
  },

  inlineTitleText: {
    marginRight: 5,
    display:     'inline-block',
  },

  inlineText: {
    display: 'inline-block',
  },
});

export default withStyles(styles)(AdminVoteOptions);
