import PropTypes from 'prop-types';
import React from 'react';
import Immutable, { List, Map } from 'immutable';
import { Field } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// local components
import VoteInput from '../VoteInput';
import AdminVoteOptions from '../AdminVoteOptions';
import TextInput from '../../../TextInput';

const VoteForm = function ({
  categories,
  categoryVotes,
  classes,
  clearanceLevel,
  currentChapterId,
  currentVote,
  displayPnmInfoOnVote,
  handleDialogClose,
  organization,
  pnm,
  rounds,
  singleBallot,
  teamLevel,
  voteForm,
}) {
  const name = pnm.get('id') ? `${pnm.get('firstname')} ${pnm.get('lastname')}` : 'PNM';

  let sortedCategories = categories;
  const lastCategory = sortedCategories.find(c =>
    c.get('name') === 'Emotional Score') || Map();
  if (lastCategory.size > 0) {
    // remove category and put it at the end of the list
    sortedCategories = sortedCategories.splice(sortedCategories.indexOf(lastCategory), 1);
    sortedCategories = sortedCategories.push(lastCategory);
  }

  const defaultItem = Immutable.fromJS({
    _id:     'vote',
    name:    '',
    type:    'button',
    options: (organization.get('defaultOptions') || List()).size
      ? organization.get('defaultOptions')
      : [
        { _id: 1, label: '1', value: 1 },
        { _id: 2, label: '2', value: 2 },
        { _id: 3, label: '3', value: 3 },
        { _id: 4, label: '4', value: 4 },
        { _id: 5, label: '5', value: 5 },
      ],
  });

  const isAdmin = (teamLevel > 1 || clearanceLevel > 0);

  return (
    <div>
      <Grid container spacing={ 24 } className={ classes.voteHeader }>
        <Grid item sm={ 12 } xs={ 11 }>
          { displayPnmInfoOnVote
            && (
            <Typography variant='h6'
              align='center'
              gutterBottom
              className={ classes.headerText }>
              Submit Vote For
              {' '}
              { name }
            </Typography>
            )}
        </Grid>

        <Hidden smUp>
          <IconButton className={ classes.closeIcon } onClick={ handleDialogClose }>
            <Icon>close</Icon>
          </IconButton>
        </Hidden>
      </Grid>

      { sortedCategories.size ? sortedCategories.map(category =>
      // Renders vote inputs based on a number of various categories that an org may use
        (
          <VoteInput key={ category.get('_id') }
            categoryVotes={ categoryVotes }
            currentVote={ currentVote }
            isAdmin={ isAdmin }
            item={ category }
            voteForm={ voteForm } />
        ))
        : (
          <VoteInput key='default'
            item={ defaultItem }
            isAdmin={ isAdmin }
            voteForm={ voteForm } />
        )}

      { !singleBallot
      && (
      <Grid container
        spacing={ 8 }
        justify='center'
        align='center'
        className={ classes.voteCommentContainer }>
        <Grid item md={ 10 } xs={ 12 }>
          <Paper className={ classes.paperRoot }>
            <Field name='comment'
              id='multiline-flexible'
              label='Write A Comment About This Vote'
              multiline
              type='text'
              component={ TextInput }
              maxLength={ 1000 }
              fullWidth />
          </Paper>
        </Grid>
      </Grid>
      )}

      <Grid container
        spacing={ 24 }
        justify='center'
        className={
          !isAdmin && singleBallot
            ? classes.compactVoteOptionsContainer
            : classes.voteOptionsContainer
        }>

        { isAdmin && ( // displays more options for admins only
        <AdminVoteOptions rounds={ rounds }
          currentChapterId={ currentChapterId } />
        )}
      </Grid>
    </div>
  );
};

VoteForm.propTypes = {
  categories:           PropTypes.instanceOf(List).isRequired,
  categoryVotes:        PropTypes.instanceOf(Map).isRequired,
  classes:              PropTypes.instanceOf(Object).isRequired,
  clearanceLevel:       PropTypes.number.isRequired,
  currentChapterId:     PropTypes.number.isRequired,
  currentVote:          PropTypes.instanceOf(Map).isRequired,
  displayPnmInfoOnVote: PropTypes.bool.isRequired,
  handleDialogClose:    PropTypes.func.isRequired,
  organization:         PropTypes.instanceOf(Map).isRequired,
  pnm:                  PropTypes.instanceOf(Object),
  rounds:               PropTypes.instanceOf(List).isRequired,
  singleBallot:         PropTypes.bool,
  teamLevel:            PropTypes.number.isRequired,
  voteForm:             PropTypes.instanceOf(Map).isRequired,
};

VoteForm.defaultProps = {
  pnm:          Map(),
  singleBallot: false,
};

const styles = theme => ({
  voteHeader: {
    backgroundColor: theme.palette.primary.main,
    paddingTop:      15,
  },

  headerText: {
    color: theme.palette.common.white,
  },

  closeIcon: {
    position: 'absolute',
    top:      10,
    right:    0,
    color:    theme.palette.common.white,
  },

  voteOptionsContainer: {
    padding: 25,
  },

  compactVoteOptionsContainer: {
    padding: 10,
  },

  inlineTitleText: {
    marginRight: 5,
    display:     'inline-block',
  },

  inlineText: {
    display: 'inline-block',
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  voteCommentContainer: {
    display:           'flex',
    backgroundColor:   theme.palette.primary.main,
    padding:           25,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.dark,
  },

  paperRoot: theme.mixins.gutters({
    paddingTop:    16,
    paddingBottom: 16,
    marginTop:     theme.spacing.unit * 3,
  }),

});

export default withStyles(styles)(VoteForm);
