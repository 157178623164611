import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { generateBumpGroups } from '../../../../../../actions/bumpGroupActions';

// components
import BumpGroupGenerator from './BumpGroupGenerator';

const mapStateToProps = state => ({
  bumpGroupGeneratorForm: state.getIn(['form', 'bumpGroupGeneratorForm'], Map()),
  chapter:                state.get('chapter'),
  user:                   state.get('user'),
});

const mapDispatch = {
  generateBumpGroups,
};

const handleChange = function (bumpGroups, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const schema = {
  membersPerGroup: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const wrappedBumpGroupGenerator = reduxForm({
  form:               'bumpGroupGeneratorForm',
  validate:           validateForm,
  enableReinitialize: true,
  onChange:           handleChange,
})(BumpGroupGenerator);

export default connect(mapStateToProps, mapDispatch)(wrappedBumpGroupGenerator);
