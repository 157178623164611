import React from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import moment from 'moment';

// components
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

function ConfirmedMemberCard({
  reporting, reportingPlatformDisplayName, pnms,
}) {
  const loading = reporting.get('loading') && !reporting.get('data');
  const groupedPnms = pnms.sortBy(p => p.get('conf_new_member_date')).reverse();

  let currentDate;

  const renderConfirmedPnms = () => groupedPnms.map((p) => {
    const element = [
      p.get('conf_new_member_date') !== currentDate
      && (
      <div key={ p.get('conf_new_member_date') }>
        <Divider style={ { marginBottom: 25 } } />
        <Typography variant='subtitle1'>
          Reported on
          {' '}
          { moment(new Date(p.get('conf_new_member_date'))).format('MMMM Do YYYY, h:mm a') }
        </Typography>
        <Typography color='primary'>
          { `By ${p.getIn(['conf_new_member_by', 'firstname'])} ${p.getIn(['conf_new_member_by', 'lastname'])}` }
        </Typography>
      </div>
      ),

      <ListItem key={ p.get('id') }>
        <Avatar>
          <Icon>check_circle_outline</Icon>
        </Avatar>

        <ListItemText>
          <Typography>
            { `${p.get('firstname')} ${p.get('lastname')}  (${p.get('email')})` }
          </Typography>
        </ListItemText>
      </ListItem>,
    ];

    currentDate = p.get('conf_new_member_date');
    return element;
  });

  return (
    <Card>
      <CardContent>
        { loading ? (
          <Grid container justify='center'>
            <Grid item xs={ 12 } align='center'>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div>
            <Fade in={ !loading }>
              <Grid container spacing={ 8 }>
                <Grid item xs={ 12 }>
                  <Typography color='primary' variant='h6'>
                    New Members Reported!
                  </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                  <Typography>
                    {`You have reported ${pnms.size} new members to your organization.
                     These members will appear on your roster in ${reportingPlatformDisplayName}.
                     Please allow a few minutes for them to appear.
                     If you don't see the new members, please contact our support at support@techniphi.com.`}
                  </Typography>
                </Grid>

                <Divider />

                <Grid item xs={ 12 }>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={ <Icon>expand_more</Icon> }>
                      <Grid container spacing={ 8 }>
                        <Grid item xs={ 1 }>
                          <Icon color='primary'>check_circle</Icon>
                        </Grid>

                        <Grid item xs={ 11 }>
                          <Typography color='primary'>
                            View your reported new members:
                          </Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                      <Grid container spacing={ 8 }>
                        <Grid item xs={ 12 }>
                          <List>
                            { renderConfirmedPnms() }
                          </List>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                </Grid>
              </Grid>
            </Fade>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

ConfirmedMemberCard.propTypes = {
  pnms:                         PropTypes.instanceOf(Immutable.List).isRequired,
  reporting:                    PropTypes.instanceOf(Map).isRequired,
  reportingPlatformDisplayName: PropTypes.string.isRequired,
};

export default ConfirmedMemberCard;
