import { LOCATION_SCHOOL_FETCH_ALL_INIT, LOCATION_SCHOOL_CLEAR } from '../constants/locationSchoolTypes';

export function locationSchoolFetchAll(params) {
  return {
    type:    LOCATION_SCHOOL_FETCH_ALL_INIT,
    payload: params,
  };
}

export function locationSchoolClear() {
  return {
    type: LOCATION_SCHOOL_CLEAR,
  };
}
