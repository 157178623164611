import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordEmailForm from './ForgotPasswordEmailForm';

// images
import logo from '../../assets/images/logo.png';

// styles
import './ForgotPassword.scss';

class ForgotPassword extends Component {
  static propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
  }

  renderForgotPasswordForm = () => {
    const { match: { params: { token } } } = this.props;

    let element;

    if (token) {
      element = <ForgotPasswordForm />;
    } else {
      element = <ForgotPasswordEmailForm />;
    }

    return element;
  }

  render() {
    return (
      <div className='forgot-password'>
        <Grid container justify='center'>
          <Grid xs={ 12 } sm={ 12 } md={ 6 } lg={ 5 } xl={ 4 } item>
            <Paper className='forgot-password__container' style={ { borderRadius: 10 } } elevation={ 24 }>
              <Grid container alignItems='center' direction='column' spacing={ 24 }>
                <Grid item>
                  <img className='forgot-password__logo' src={ logo } alt='MyVote' />
                </Grid>

                { this.renderForgotPasswordForm() }
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ForgotPassword;
