import {
  MATCH_FETCH_FOR_PNM_INIT,
  MATCH_FETCH_FOR_USER_INIT,
  MATCH_FETCH_ALL_INIT,
  MATCH_DELETE_INIT,
  MATCH_CREATE_INIT,
  MATCH_AUTO_INIT,
  MATCH_CLEAR,
  MATCH_BULK_CREATE_INIT,
} from '../constants/matchTypes';

export function fetchMatchesForPnm({
  pnmId,
  chapterId,
  skipPnmPopulate = true,
}) {
  return {
    type:    MATCH_FETCH_FOR_PNM_INIT,
    payload: {
      pnmId, chapterId, skipPnmPopulate,
    },
  };
}

export function fetchMatchesForUser({ userId, round, chapterId }) {
  return {
    type:    MATCH_FETCH_FOR_USER_INIT,
    payload: {
      userId, round, chapterId,
    },
  };
}

export function fetchAllMatches({
  chapter,
  round,
  officialOnly = false,
  skipVoteData = false,
  skipFieldData = false,
  skipHometownData = false,
  skipTagData = false,
  skipPnmPopulate = false,
  skipPopulateUsers = false,
}) {
  return {
    type:    MATCH_FETCH_ALL_INIT,
    payload: {
      chapter,
      round,
      officialOnly,
      skipVoteData,
      skipFieldData,
      skipTagData,
      skipHometownData,
      skipPnmPopulate,
      skipPopulateUsers,
    },
  };
}

export function deleteMatch({
  chapter, id, ids = [], modeToClear,
}) {
  return {
    type:    MATCH_DELETE_INIT,
    payload: {
      chapter, id, ids, modeToClear,
    },
  };
}

export function createMatch(params) {
  return {
    type:    MATCH_CREATE_INIT,
    payload: params,
  };
}

export function autoMatch({
  chapter,
  checkPreviousMatches,
  checkVotedOn,
  excludedUsers,
  matchesPerMemberEvent,
  selectedEvent,
  selectedPnms,
  selectedRound,
  selectedHometown,
  selectedItems,
  weights,
}) {
  return {
    type:    MATCH_AUTO_INIT,
    payload: {
      chapter,
      checkPreviousMatches,
      checkVotedOn,
      excludedUsers,
      matchesPerMemberEvent,
      selectedEvent,
      selectedPnms,
      selectedRound,
      selectedHometown,
      selectedItems,
      weights,
    },
  };
}

export function bulkCreateMatches(params) {
  return {
    type:    MATCH_BULK_CREATE_INIT,
    payload: params,
  };
}

export function clearMatches() {
  return { type: MATCH_CLEAR };
}
