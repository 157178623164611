import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import analyticsService from '../../sources/api/analyticsService';

// constants
import { ANALYTICS_FETCH, ANALYTICS_FETCH_INIT } from '../../constants/analyticsTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* analyticsFetch(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(ANALYTICS_FETCH, call(find, analyticsService, options));
  } catch (err) {
    yield put(error(ANALYTICS_FETCH, err));
  } finally {
    yield put(doneIndicator(ANALYTICS_FETCH));
  }
}

export function* watchAnalyticsFetch() {
  yield takeEvery(ANALYTICS_FETCH_INIT, analyticsFetch);
}

export default function* root() {
  yield all([
    fork(watchAnalyticsFetch),
  ]);
}
