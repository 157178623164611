const modalAdvancedOptionsStyles = () => ({
  root:       {},
  gridHeader: {
    width:      '100%',
    padding:    '10px 0px',
    fontSize:   '14px',
    color:      '#5A5A5AA5',
    fontWeight: 700,
  },
  subContent: {
    marginLeft:   48,
    '&.disabled': {
      display: 'none',
    },
  },
  switchInput: {
    margin: 0,
  },
  searchFieldsToDisplayButton: {
    padding:      4,
    marginBottom: 3,
  },
  searchFieldsToDisplay: {
    '& > label': {
      paddingRight: 30,
      boxSizing:    'border-box',
      whiteSpace:   'nowrap',
      overflow:     'hidden',
      maxWidth:     '100%',
      textOverflow: 'ellipsis',
    },
  },
  errorMessage: {
    marginTop: 20,
    padding:   5,
    textAlign: 'center',
    color:     'red',
    fontSize:  '0.9em',
    border:    '1px solid red',
  },
});

export default modalAdvancedOptionsStyles;
