import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import fieldService from '../../sources/api/fieldService';

// constants
import { FIELD_BULK_CREATE, FIELD_BULK_CREATE_INIT } from '../../constants/fieldTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { fieldSchema } from '../../schemas/field';

export function* fieldBulkCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'fieldsForm';

  yield put(startSubmit(FORM));

  const { values, chapter } = action.payload || {};

  values.forEach((item, index) => {
    values[index].order = index;
  });

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(FIELD_BULK_CREATE, call(create, fieldService, values, options), {
      schema: fieldSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    if (FORM !== 'fieldsForm') {
      yield put(destroy(FORM));
    }
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(FIELD_BULK_CREATE, err));
  } finally {
    yield put(doneIndicator(FIELD_BULK_CREATE));
  }
}

export function* watchInviteBulkCreate() {
  yield takeEvery(FIELD_BULK_CREATE_INIT, fieldBulkCreate);
}

export default function* root() {
  yield all([
    fork(watchInviteBulkCreate),
  ]);
}
