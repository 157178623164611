import Immutable, { List, Map } from 'immutable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// components
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import Comment from '../Comment';
import Value from '../Value';
import AssignVoteModal from '../AssignVoteModal';

// styles
import './ListItem.scss';

class ListItem extends Component {
  static propTypes = {
    authorName:     PropTypes.string.isRequired,
    classes:        PropTypes.instanceOf(Object).isRequired,
    clearanceLevel: PropTypes.number.isRequired,
    comment:        PropTypes.string,
    currentUserId:  PropTypes.number.isRequired,
    isConcernChair: PropTypes.bool,
    first:          PropTypes.bool,
    id:             PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    item:           PropTypes.instanceOf(Map).isRequired,
    last:           PropTypes.bool,
    navigate:       PropTypes.func.isRequired,
    onDelete:       PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    pnmName:        PropTypes.string.isRequired,
    pnmId:          PropTypes.string.isRequired,
    pnmPhoto:       PropTypes.string.isRequired,
    renderTimeline: PropTypes.bool,
    rounds:         PropTypes.instanceOf(List).isRequired,
    showPNM:        PropTypes.bool,
    tags:           PropTypes.instanceOf(Array),
    teamLevel:      PropTypes.number.isRequired,
    type:           PropTypes.oneOf(['votes', 'notes', 'matches', 'tags']).isRequired,
    updatedAt:      PropTypes.string.isRequired,
    value:          PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    comment:        '',
    isConcernChair: false,
    first:          false,
    last:           false,
    onDelete:       false,
    renderTimeline: true,
    showPNM:        false,
    tags:           Immutable.List(),
    value:          '',
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.Map({
        hover:                  false,
        displayAssignVoteModal: false,
      }),
    };
  }

  setHover = () => {
    this.setState({ data: this.state.data.set('hover', true) });
  }

  removeHover = () => {
    this.setState({ data: this.state.data.set('hover', false) });
  }

  handleDelete = () => {
    const { onDelete, id } = this.props;

    onDelete(id);
  }

  handleOpenAssignVoteModal = () => {
    this.setState({ data: this.state.data.set('displayAssignVoteModal', true) });
  }

  handleCloseAssignVoteModal = () => {
    this.setState({ data: this.state.data.set('displayAssignVoteModal', false) });
  }

  renderDeleteButton = () => {
    const { classes, onDelete } = this.props;

    let element;

    if (this.state.data.get('hover') && onDelete) {
      element = (
        <div className='comment-list-item__delete-container'>
          <IconButton className={ classes.deleteButton } onClick={ this.handleDelete }>
            <Icon color='action'>delete</Icon>
          </IconButton>
        </div>
      );
    }

    return element;
  }

  render() {
    const {
      authorName,
      classes,
      clearanceLevel,
      comment,
      currentUserId,
      first,
      id,
      item,
      isConcernChair,
      last,
      navigate,
      pnmName,
      pnmId,
      pnmPhoto,
      renderTimeline,
      rounds,
      showPNM,
      tags,
      teamLevel,
      type,
      updatedAt,
      value,
    } = this.props;

    const extraClassNames = {
      'comment-list-item__timeline--first': first,
      'comment-list-item__timeline--last':  last,
    };

    const displayAssignVoteModal = this.state.data.get('displayAssignVoteModal');

    return (
      <Grid container
        className={ classes.voteListItemContainer }
        onMouseEnter={ this.setHover }
        onMouseLeave={ this.removeHover }>

        <Hidden smDown>
          { ((teamLevel > 1 || clearanceLevel > 0)
            || (item.get('author') || Map()).get('id') === currentUserId)
            && this.renderDeleteButton()}

          { renderTimeline && (
            <div className='comment-list-item__timeline-container'>
              <div className={ classNames('comment-list-item__timeline', extraClassNames) } />
            </div>
          ) }

        </Hidden>

        <Hidden xsDown>
          <Grid item sm={ 3 } md={ 4 }>
            <Value type={ type }
              tags={ tags }
              value={ value }
              item={ item }
              rounds={ rounds }
              teamLevel={ teamLevel }
              clearanceLevel={ clearanceLevel }
              isConcernChair={ isConcernChair } />
          </Grid>

          <Grid item sm={ 9 } md={ 8 }>
            <Comment authorName={ authorName }
              handleOpenModal={ this.handleOpenAssignVoteModal }
              text={ comment }
              showPNM={ showPNM }
              pnmName={ pnmName }
              pnmId={ pnmId }
              pnmPhoto={ pnmPhoto }
              item={ item || Map() }
              navigate={ navigate }
              type={ type }
              rounds={ rounds }
              updatedAt={ updatedAt } />
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Grid item xs={ 3 }>
            <Value type={ type }
              tags={ tags }
              value={ value }
              teamLevel={ teamLevel }
              clearanceLevel={ clearanceLevel } />
          </Grid>

          <Grid item xs={ 9 }>
            <Comment authorName={ authorName }
              handleOpenModal={ this.handleOpenAssignVoteModal }
              text={ comment }
              showPNM={ showPNM }
              pnmName={ pnmName }
              pnmId={ pnmId }
              pnmPhoto={ pnmPhoto }
              item={ item }
              rounds={ rounds }
              navigate={ navigate }
              updatedAt={ updatedAt } />
          </Grid>
        </Hidden>

        { type === 'votes' && (
          <AssignVoteModal open={ displayAssignVoteModal }
            voteId={ id }
            item={ item }
            onClose={ this.handleCloseAssignVoteModal } />
        )}

      </Grid>
    );
  }
}

const styles = () => ({
  voteListItemContainer: {
    marginBottom: '1em',
  },

  deleteButton: {
    position: 'absolute',
    left:     '-13px',
    zIndex:   1,
  },
});

export default withStyles(styles)(ListItem);
