import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form/immutable';
import Immutable, { Map } from 'immutable';

// components
import CheckboxInput from '../../../../../CheckboxInput';
import TextInput from '../../../../../TextInput';

import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class MemberInfoForm extends Component {
  static propTypes = {
    reporting:      PropTypes.instanceOf(Map).isRequired,
    classes:        PropTypes.instanceOf(Object).isRequired,
    memberInfoForm: PropTypes.instanceOf(Map).isRequired,
    pnms:           PropTypes.instanceOf(Immutable.List).isRequired,
  };

  renderPnms = ({ fields }) => {
    const {
      classes, pnms, memberInfoForm, reporting,
    } = this.props;

    const formErrors = memberInfoForm.get('syncErrors') || [];
    const reportingErrors = reporting.getIn(['data', 'errors'], Map());

    return (
      <List>
        { fields.map((field, index) => {
          const p = pnms.get(index);

          const errors = formErrors[index] || {};
          const emailError = (errors.email || [])[0];
          const phoneError = (errors.phone || [])[0];
          const reportingError = reportingErrors.get(String(p.get('id')));

          return (
            <ListItem key={ p.get('id') } divider>
              <Field name={ `${field}.checked` }
                component={ CheckboxInput } />
              <Avatar className={ p.get('email') ? classes.hasEmailAvatar : classes.noEmailAvatar }>
                <Icon>email</Icon>
              </Avatar>

              <ListItemText>
                <div>
                  <Typography>
                    { `${p.get('firstname')} ${p.get('lastname')}` }
                  </Typography>

                  <Field name={ `${field}.email` }
                    className={ classes.emailFieldContainer }
                    label='Email'
                    type='email'
                    component={ TextInput }
                    error={ Boolean(emailError || reportingError) }
                    fullWidth />

                  { emailError && <FormHelperText error>{ emailError }</FormHelperText> }

                  <Field name={ `${field}.phone` }
                    label='Phone'
                    type='phone'
                    component={ TextInput }
                    error={ Boolean(phoneError || reportingError) }
                    fullWidth />

                  { phoneError && <FormHelperText error>{ phoneError }</FormHelperText> }
                  {
                    reportingError && <FormHelperText error>{ reportingError }</FormHelperText>
                  }
                </div>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    );
  }

  render() {
    const { pnms } = this.props;

    const missingEmailPnms = pnms.filter(p => !p.get('email'));
    const dropdownMessage = 'Update email addresses and de-select any members you do not wish to report:';

    return (
      <Grid item xs={ 12 }>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={ <Icon>expand_more</Icon> }>
            <Grid container spacing={ 8 }>
              <Grid item xs={ 1 }>
                <Icon color='primary'>new_releases</Icon>
              </Grid>
              <Grid item xs={ 11 }>
                <Typography color='primary'>
                  { dropdownMessage }
                </Typography>
              </Grid>
            </Grid>

          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={ 8 }>
              <Grid item xs={ 12 }>
                <Typography color={ missingEmailPnms.size ? 'secondary' : 'primary' }>
                  There are
                  {' '}
                  { missingEmailPnms.size }
                  {' '}
                  new members without emails
                </Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <FieldArray name='pnms' component={ this.renderPnms } />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    );
  }
}

const styles = theme => ({
  hasEmailAvatar: {
    backgroundColor: theme.palette.primary.main,
  },

  noEmailAvatar: {
    backgroundColor: theme.palette.secondary.main,
  },

  emailFieldContainer: {
    marginBottom: 7,
  },
});

export default withStyles(styles)(MemberInfoForm);
