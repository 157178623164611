import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { push } from 'react-router-redux';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import client, { get } from '../../sources/api/client';
import userService from '../../sources/api/userService';
import chapterService from '../../sources/api/chapterService';

// constants
import { SESSION_CREATE } from '../../constants/sessionTypes';
import { CURRENT_USER_FETCH } from '../../constants/userTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSchema } from '../../schemas/user';
import { chapterSchema } from '../../schemas/chapter';

export function* sessionCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'login';

  yield put(startSubmit(FORM));

  const { email, password } = action.payload;

  const authOptions = {
    strategy: 'local',
    email,
    password,
  };

  try {
    const { data: { user:authUser } }      = yield* httpSaga(SESSION_CREATE, call(client.authenticate, authOptions));

    const response = yield* httpSaga(CURRENT_USER_FETCH, call(get, userService, authUser.id), {
      schema: userSchema,
    }) || {};

    const user = response.data;
    const chapters = user.chapters || [];

    if (chapters.length === 1 && user.clearance_level < 1) {
      const chapterId = (chapters[0] || {}).id;

      yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapterId), {
        schema: chapterSchema,
      });

      yield put(push('/'));
    } else if ([1, 2].includes(user.clearance_level) || chapters.length > 1) {
      yield put(push('/dashboard'));
    }

    yield put(setSubmitSucceeded(FORM));

    yield put(destroy(FORM));
  } catch (err) {
    yield put(error(SESSION_CREATE, err));
    yield put(setSubmitFailed(FORM));
  } finally {
    yield put(doneIndicator(SESSION_CREATE));
  }
}

export function* watchSessionCreate() {
  yield takeEvery(SESSION_CREATE, sessionCreate);
}

export default function* root() {
  yield all([
    fork(watchSessionCreate),
  ]);
}
