import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import Immutable from 'immutable';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { arrayPush } from 'redux-form/immutable';

// api
import { create } from '../../sources/api/client';
import roundService from '../../sources/api/roundService';

// constants
import { ROUND_CREATE, ROUND_CREATE_INIT } from '../../constants/roundTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { roundSchema } from '../../schemas/round';

export function* roundCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const values = action.payload || {};

  try {
    const response = yield* httpSaga(ROUND_CREATE, call(create, roundService, values), {
      schema: roundSchema,
    });

    const round = (response || {}).data;

    if (round) {
      yield put(arrayPush('roundsForm', 'rounds', Immutable.fromJS({ ...round, draggable: 1 })));
    }
  } catch (err) {
    yield put(error(ROUND_CREATE, err));
  } finally {
    yield put(doneIndicator(ROUND_CREATE));
  }
}

export function* watchInviteCreate() {
  yield takeEvery(ROUND_CREATE_INIT, roundCreate);
}

export default function* root() {
  yield all([
    fork(watchInviteCreate),
  ]);
}
