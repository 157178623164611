import { MODAL_ADVANCED_OPTIONS_SET_OPEN } from '../constants/modalAdvancedOptions';

export function setOpenModalAdvancedOptions(open) {
  return {
    type:    MODAL_ADVANCED_OPTIONS_SET_OPEN,
    payload: {
      open,
    },
  };
}
