/*
  React Data Grid Table
*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';
import ReactDataGrid from 'react-data-grid';
import pluralize from 'pluralize';

// mui components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../Button';

// styles for react-data-grid
import './importTable.scss';

// creates a list with one empty row
const defaultRows = Immutable.fromJS(Array(Object()));

class ImportTable extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    cols:           PropTypes.instanceOf(List).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    importState:    PropTypes.instanceOf(Map).isRequired,
    onUpload:       PropTypes.func.isRequired,
    pathname:       PropTypes.string.isRequired, // eslint-disable-line
    round:          PropTypes.instanceOf(Map).isRequired,
    rows:           PropTypes.instanceOf(List).isRequired, // eslint-disable-line
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        tableRows:       defaultRows,
        importRows:      [],
        snackbarOpen:    false,
        snackbarMessage: '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.formatData(nextProps);
  }

  getDisabled = () => {
    const {
      cols, pathname, currentChapter, round,
    } = this.props;

    const currentRound = currentChapter.getIn(['data', 'currentRound'], 0);
    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen'], false);
    const tableRows = this.state.data.get('tableRows') || List();

    // checks if the first cell, or in most cases the ID cell is empty, a key that all imports need
    const isPnmIdEmpty = tableRows.getIn([0, 'pnm'], '').length === 0;
    let disabled = false;
    let emptyVotes = false;

    if (pathname.includes('votes')) {
      const currentRoundExists = Map.isMap(round.getIn(['data', 'items'], List())
        .find(r => r.get('_id') === currentRound));

      // Checks to see if there are any empty vote values
      tableRows.forEach((row) => {
        // Checks size of row object vs # of header names
        if (!row.size || row.size < cols.size) {
          emptyVotes = true;
        }
        const list = row.toList();

        // Checks for items that are empty strings
        list.forEach((item) => {
          if (!String(item).length) {
            emptyVotes = true;
          }
        });
      });

      disabled = isPnmIdEmpty || !currentRound
      || !currentRoundExists || !isVotingOpen || emptyVotes;
    } else if (pathname.includes('pnms')) {
      const isCouncilIdEmpty = (tableRows.getIn([0, 'leg_tech_pnm_id'], '') || '').length === 0;
      disabled = isCouncilIdEmpty;
    } else if (pathname.includes('hometowns')) {
      const isHometownGroupEmpty = tableRows.getIn([0, 'hometownGroup'], '').length === 0;

      disabled = isPnmIdEmpty || isHometownGroupEmpty;
    } else if (pathname.includes('users')) {
      const areNamesEmpty = tableRows.getIn([0, 'firstname'], '').length === 0
        || tableRows.getIn([0, 'lastname'], '').length === 0;

      disabled = areNamesEmpty;
    } else {
      disabled = disabled && isPnmIdEmpty;
    }

    return disabled;
  }

  initializeStates = (importState, tableRows, importRows, pathname) => {
    const { rows } = this.props;
    const status = importState.get('status');

    if (pathname !== this.props.pathname) {
      // Reset the data grid when you switch import pages
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('importRows', List());
          map.set('tableRows', defaultRows);
        }),
      });
    } else if (status === 'success' && importState.get('loading') !== this.props.importState.get('loading')
        && (tableRows.size > 0 || rows.size > 0) && importRows.size > 0) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('importRows', List());
          map.set('snackbarMessage', 'Imported Successfully');
          map.set('snackbarOpen', true);
          map.set('tableRows', defaultRows);
        }),
      });
    } else if (status === 'error') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('importRows', importRows);
          map.set('snackbarMessage', 'Import Failed');
          map.set('snackbarOpen', true);
          map.set('tableRows', tableRows);
        }),
      });
    } else {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('importRows', importRows);
          map.set('tableRows', tableRows);
        }),
      });
    }
  }

  formatData = (props) => {
    const {
      rows, cols, importState, pathname,
    } = props;

    const initRows = rows.size > 0 && (rows.get(0) || Map()).size > 0 ? rows : defaultRows;

    let tableRows = List();
    let importRows = List();

    initRows.forEach((row) => {
      let tableRow = {}; // the data that we'll display in react-data-grid
      let importRow = {}; // the data that we'll use to import

      row.forEach((cell, index) => {
        const key = (cols.get(index) || Map()).get('key');

        const newProperty = { [key]: cell };
        tableRow = { ...tableRow, ...newProperty };

        if (key === 'phone' && pathname.includes('users') && cell.length > 1) {
          const phoneArea = { phone_area: cell.substring(0, 3) };
          const phoneNumber = { phone_number: cell.substring(3, 10) };
          importRow = { ...importRow, ...phoneArea, ...phoneNumber };
        } else {
          importRow = { ...importRow, ...newProperty };
        }
      });

      tableRows = tableRows.push(Map(tableRow));
      importRows = importRows.push(Map(importRow));
    });

    this.initializeStates(importState, tableRows, importRows, pathname);
  };

  handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    let updatedTableRows = this.state.data.get('tableRows') || List();
    let updatedImportRows = this.state.data.get('importRows') || List();

    for (let i = fromRow; i <= toRow; i += 1) {
      const updatedTableRow = (updatedTableRows.get(i) || Map()).mergeDeep({ ...updated });
      const updatedImportRow = (updatedImportRows.get(i) || Map()).mergeDeep({ ...updated });

      updatedTableRows = updatedTableRows.set(i, updatedTableRow);
      updatedImportRows = updatedImportRows.set(i, updatedImportRow);
    }

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('importRows', updatedImportRows);
        map.set('tableRows', updatedTableRows);
      }),
    });
  };

  handleSnackbarClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  }

  handleUpload = (rows) => {
    const { onUpload } = this.props;

    onUpload(rows);
  }

  rowGetter = (i) => {
    const tableRows = (this.state.data.get('tableRows') || List()).toJS();
    return tableRows[i];
  }

  renderEmptyRowsView = () => {
    const { classes } = this.props;

    return (
      <Grid container className={ classes.emptyRowMessage }>
        <Grid item xs={ 12 }>
          <Typography variant='subtitle1' color='primary'>
            No data to display
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      classes, cols, importState, pathname,
    } = this.props;
    const tableRows = this.state.data.get('tableRows') || List();
    const importRows = this.state.data.get('importRows') || List();
    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');
    const rowsWithEmptyCells = (!pathname.includes('votes') || (tableRows.size === 1 && tableRows.get(0).size === 0))
      ? []
      : tableRows.reduce(
        (acc, row, index) => {
          if (row.some(item => !String(item).length) || cols.size > row.size) acc.push(index + 1);
          return acc;
        },
        []
      );

    return (
      <Grid container spacing={ 8 }>
        <Grid item xs={ 12 }>
          <div className={ classes.importGrid }>
            <ReactDataGrid enableCellSelect
              columns={ cols.toJS() }
              rowGetter={ this.rowGetter }
              rowsCount={ tableRows.size }
              minHeight={ 400 }
              rowScrollTimeout={ 1000 }
              style={ { width: 'inherit' } }
              emptyRowsView={ this.renderEmptyRowsView }
              onGridRowsUpdated={ this.handleGridRowsUpdated } />
          </div>
        </Grid>

        <Grid item xs={ 12 } align='center' className={ classes.uploadButtonContainer }>
          <Typography variant='subtitle1' color='primary' gutterBottom>
            3. Click upload to import
          </Typography>

          <Button variant='contained'
            color='primary'
            className={ classes.uploadButton }
            loading={ tableRows.size >= 1 && importState.get('loading') }
            disabled={ this.getDisabled() }
            onClick={ () => this.handleUpload(importRows) }>

            Upload
          </Button>
        </Grid>

        <Snackbar key='message'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen || rowsWithEmptyCells.length > 0 }
          autoHideDuration={ 3000 }
          onClose={ () => this.handleSnackbarClose() }
          message={ rowsWithEmptyCells.length > 0 ? `There are empty cells in ${rowsWithEmptyCells.join(',')} ${pluralize('row', rowsWithEmptyCells.length)}!` : snackbarMessage } />
      </Grid>
    );
  }
}

const styles = () => ({
  emptyRowMessage: {
    marginTop: 20,
  },

  uploadButton: {
    marginBottom: 10,
  },

  uploadButtonContainer: {
    marginBottom: 25,
  },
});

export default withStyles(styles)(ImportTable);
