import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import { PNM_FETCH_ALL, PNM_FETCH_ALL_INIT } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmListSchema } from '../../schemas/pnm';

export function* fetchAllPnms(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    active,
    activeNotRecruited,
    chapter,
    event,
    filter,
    infiniteScroll,
    round,
    search,
    skip,
    sort,
  } = action.payload || {};

  try {
    const options = {
      query: {
        $limit:  25,
        $search: search,
        $skip:   skip,
        $sort:   sort,
        activeNotRecruited,
        chapter,
        event,
        round,
        ...filter,
      },
    };

    if (active) {
      options.query.active = true;
    }

    yield* httpSaga(PNM_FETCH_ALL, call(find, pnmService, options), {
      schema: pnmListSchema,
      infiniteScroll,
    });
  } catch (err) {
    yield put(error(PNM_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(PNM_FETCH_ALL));
  }
}

export function* watchPnmFetchAll() {
  yield takeLatest(PNM_FETCH_ALL_INIT, fetchAllPnms);
}

export default function* root() {
  yield all([
    fork(watchPnmFetchAll),
  ]);
}
