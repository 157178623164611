import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import voteService from '../../sources/api/voteService';

// constants
import { VOTE_FETCH_FOR_USER, VOTE_FETCH_FOR_USER_INIT } from '../../constants/voteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { voteListSchema } from '../../schemas/vote';

export function* voteFetchForUser(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter, userId, roundId } = action.payload || {};

  const options = {
    query: {
      chapter,
      author:            userId,
      round:             roundId,
      $populate:         'round',
      shouldPopulatePnm: true,
      $sort:             {
        createdAt: -1,
      },
    },
  };

  try {
    yield* httpSaga(VOTE_FETCH_FOR_USER, call(find, voteService, options), {
      schema: voteListSchema,
    });
  } catch (err) {
    yield put(error(VOTE_FETCH_FOR_USER, err));
  } finally {
    yield put(doneIndicator(VOTE_FETCH_FOR_USER));
  }
}

export function* watchVoteFetchForUser() {
  yield takeEvery(VOTE_FETCH_FOR_USER_INIT, voteFetchForUser);
}

export default function* root() {
  yield all([
    fork(watchVoteFetchForUser),
  ]);
}
