import { schema } from 'normalizr';

// schemas
import { fieldListSchema } from './field';
import { tagListSchema } from './tag';
import { hometownGroupListSchema } from './hometownGroup';
import { roundListSchema, roundSchema } from './round';
import { organizationSchema } from './organization';

export const chapterSchema = new schema.Entity('chapter', {
  fields:         fieldListSchema,
  tags:           tagListSchema,
  hometownGroups: hometownGroupListSchema,
  rounds:         roundListSchema,
  organization:   organizationSchema,
  currentRound:   roundSchema,
});

const chapterItemSchema = new schema.Entity('chapter', {
  fields:         fieldListSchema,
  tags:           tagListSchema,
  hometownGroups: hometownGroupListSchema,
  rounds:         roundListSchema,
  currentRound:   roundSchema,
});

export const chapterListSchema = [chapterItemSchema];
