import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';

// components
import Slideshow from './Slideshow';

// action creators
import { pnmFetch } from '../../actions/pnmActions';
import { noteFetchForPnm } from '../../actions/noteActions';
import { fetchMatchesForPnm } from '../../actions/matchActions';

// selectors
import getLoadingFromState from '../../lib/selectors/getLoadingFromState';
import getChapterAttribute from '../../lib/selectors/getChapterAttribute';
import getPnmsForSlideshow from '../../lib/selectors/getPnmsForSlideshow';
import getMatches from '../../lib/selectors/getMatches';
import getNotes from '../../lib/selectors/getNotes';

const mapStateToProps = state => ({
  currentChapterId: getChapterAttribute('id')(state) || '',
  pnms:             getPnmsForSlideshow(state),
  matches:          getMatches(state),
  notes:            getNotes(state),
  slideshowPnms:    state.getIn(['slideshow', 'data', 'pnms'], List()),
  slideshowLoading: getLoadingFromState('slideshow')(state),
});

const mapDispatch = {
  pnmFetch,
  noteFetchForPnm,
  fetchMatchesForPnm,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Slideshow));
