import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

// MUI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

// local components
import FilesSettingsForm from './components/FilesSettingsForm';

const Files = function ({
  currentChapter,
  parentclasses: { tabsCard, titleContainer, titleIcon },
}) {
  const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);

  return !isCdSite ? null : (
    <div>
      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>
            <Grid item xs={ 8 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>description</Icon>
              <Typography variant='h5' color='primary'>
                Manage Files
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Customize the visibility of the files that display on PNMs info tab
              </Typography>
            </Grid>

            <Fade in>
              <Grid item xs={ 12 }>
                <FilesSettingsForm currentChapter={ currentChapter } />
              </Grid>
            </Fade>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

Files.propTypes = {
  currentChapter: PropTypes.instanceOf(Map).isRequired,
  parentclasses:  PropTypes.instanceOf(Object).isRequired,
};

export default Files;
