import { connect } from 'react-redux';
import { reduxForm, isDirty } from 'redux-form/immutable';
import validate from 'validate.js';
import Immutable, { List, Map } from 'immutable';

// action creators
import { chapterUpdate } from '../../../../../../actions/chapterActions';

// selectors
import getRounds from '../../../../../../lib/selectors/getRounds';

// components
import CategoriesForm from './CategoriesForm';

// schema
const optionSchema = {
  label: {
    presence: {
      allowEmpty: false,
    },
  },

  value: {
    presence: {
      allowEmpty: false,
    },
  },
};

const createInitialValues = (props) => {
  const { currentChapter, organization } = props;

  let roundCategories = organization.get('roundCategories', List());

  // resolves issue where garbage undefined round category was being added to denormalized org later
  roundCategories = roundCategories.filter(rc => rc !== undefined);

  const customCategories = currentChapter.get('data', Map()).get('customCategories', List());

  return roundCategories.map((roundCategory = Map()) => {
    const oldCategories = roundCategory.get('categories');

    const newCategories = oldCategories.map((category) => {
      let newCategory = category;

      // Look to see if the chapter has made any changes to the category
      const customCategory = (customCategories.filter(c => c.get('_id') === category.get('_id')) || List()).get(0);

      if (customCategory) {
        // Use the custom category if one exists
        newCategory = customCategory;

        // Make sure to pull in the name and editable from the org category in case it has changed
        newCategory = newCategory.set('name', category.get('name'));
        newCategory = newCategory.set('editable', category.get('editable'));

        // Handling empty input type fields
        if (!newCategory.get('minOptionValue')) {
          newCategory = newCategory.set('minOptionValue', category.get('minOptionValue'));
        }
        if (!newCategory.get('maxOptionValue')) {
          newCategory = newCategory.set('maxOptionValue', category.get('maxOptionValue'));
        }
        if (!newCategory.get('optionIncrement')) {
          newCategory = newCategory.set('optionIncrement', category.get('optionIncrement'));
        }

        // Handling empty options array
        if (!newCategory.get('options')) {
          newCategory = newCategory.set('options', category.get('options', List()));
        }
      } else {
        newCategory = newCategory.set('enabled', false);
      }

      // set the enabled flag from the required attribute
      if (category.get('required')) {
        newCategory = newCategory.set('enabled', true);
      }

      // Always take the `required` attribute from the org defined category
      newCategory = newCategory.set('required', category.get('required'));

      if (category.get('order')) {
        newCategory = newCategory.set('order', category.get('order'));
      }

      // If the category does not have custom options, use the orgs default options
      if (newCategory.get('options').size === 0) {
        newCategory = newCategory.set('options', organization.get('defaultOptions'));
      } else if (newCategory.get('required')) {
        // otherwise, add any other configurations set by the org to options
        let orgCategory = Map();

        roundCategories.forEach((rc = Map()) => {
          const categories = rc.get('categories', List());
          orgCategory = categories.find(c => c.get('_id', '0').toString() === newCategory.get('_id', '1').toString()) || Map();
        });

        let newCategoryOptions = newCategory.get('options');
        const orgCategoryOptions = orgCategory.get('options') || List();

        // Loop through each option
        orgCategoryOptions.forEach((orgOption, index) => {
          // Loop through keys of each org option
          //  and assign any attributes that are missing from chapter option
          orgOption.keySeq().forEach((key) => {
            const chapterOption = newCategoryOptions.find(o => o.get('_id') === orgOption.get('_id')) || Map();

            // New, chapter option doesn't have key? Copy over the k/v pair from the org
            if (!chapterOption.has(key)) {
              const value = orgOption.get(key);

              newCategoryOptions = newCategoryOptions.setIn([index, key], value);
            }
          });
        });
        newCategory = newCategory.set('options', newCategoryOptions);
      }

      // Map `enableOptionVotingLimits` from the roundCategory onto each newCategory
      newCategory = newCategory.set('enableOptionVotingLimits', roundCategory.get('enableOptionVotingLimits'));

      return newCategory;
    }).sortBy(c => (c.get('order') ? c.get('order') : c.get('name')));

    return roundCategory.set('categories', newCategories);
  }).sortBy(r => r.get('roundType'));
};

const mapStateToProps = (state, props) => ({
  dirty:          isDirty('categoriesForm'),
  categoriesForm: (state.get('form')).get('categoriesForm') || Map(),
  initialValues:  { roundCategories: createInitialValues(props) },
  rounds:         getRounds(state),
});

const mapDispatchToProps = dispatch => ({
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
});

const validateForm = function (form) {
  let errors = Map();

  const roundCategories = form.get('roundCategories');

  // Get all categories
  const categories = roundCategories.map(roundCategory => roundCategory.get('categories'))
    .flatMap(a => a);

  // Get all options
  const options = categories.map(category => (category.get('options') || List())
    .map((option, index) => (option || Map()).withMutations((map) => {
      map.set('category', category.get('_id'));
      map.set('index', index);
    })))
    .flatMap(a => a);

  // Run validations on all options
  const validations = options.map(o => validate((o || Map()).toJS(), optionSchema));

  // Handle max/min number of categories for roundCategories
  roundCategories.forEach((roundCategory) => {
    const roundCategoriesErrors = errors.get('roundCategories') || Map();
    const roundCategoryErrors = roundCategoriesErrors.get(roundCategory.get('_id')) || List();
    const numberOfEnabledCategories = (roundCategory.get('categories') || List())
      .countBy(c => c.get('enabled'))
      .get(true);

    if (roundCategory.get('maxCategories')) {
      if (numberOfEnabledCategories > roundCategory.get('maxCategories')) {
        errors = errors.set('roundCategories', roundCategoriesErrors
          .set(roundCategory.get('_id'), roundCategoryErrors
            .push(`Enabled categories cannot exceed ${roundCategory.get('maxCategories')}`)));
      }
    }

    if (roundCategory.get('minCategories')) {
      if (numberOfEnabledCategories < roundCategory.get('minCategories')) {
        errors = errors.set('roundCategories', roundCategoriesErrors
          .set(roundCategory.get('_id'), roundCategoryErrors
            .push(`Enabled categories cannot be less than ${roundCategory.get('maxCategories')}`)));
      }
    }

    // Handle vote options validation
    roundCategory.get('categories').forEach((category) => {
      if (category.get('editable') || (category.get('required') && !category.get('editable'))) {
        if (category.get('type') === 'button') {
          category.get('options').forEach((option) => {
            // Handle min score validation
            if (option.get('value') < roundCategory.get('minScore')) {
              errors = errors.set('roundCategories', roundCategoriesErrors
                .set(roundCategory.get('_id'), roundCategoryErrors
                  .push(`Voting options for ${category.get('name')} category cannot be less than ${roundCategory.get('minScore')}`)));
            }
            // Handle max score validation
            if (option.get('value') > roundCategory.get('maxScore')) {
              errors = errors.set('roundCategories', roundCategoriesErrors
                .set(roundCategory.get('_id'), roundCategoryErrors
                  .push(`Voting options for ${category.get('name')} category cannot be greater than ${roundCategory.get('maxScore')}`)));
            }
          });
        } else if (category.get('type') === 'range') {
          const optionValueDifference = category.get('maxOptionValue') - category.get('minOptionValue');
          // Handle min score validation
          if (category.get('minOptionValue') < roundCategory.get('minScore')) {
            errors = errors.set('roundCategories', roundCategoriesErrors
              .set(roundCategory.get('_id'), roundCategoryErrors
                .push(`Min value for ${category.get('name')} category cannot be less than ${roundCategory.get('minScore')}`)));
          }
          // Handle max score validation
          if (category.get('maxOptionValue') > roundCategory.get('maxScore')) {
            errors = errors.set('roundCategories', roundCategoriesErrors
              .set(roundCategory.get('_id'), roundCategoryErrors
                .push(`Max value for ${category.get('name')} category cannot be greater than ${roundCategory.get('maxScore')}`)));
          }
          // Handle optionIncrement validation
          if (category.get('optionIncrement') > optionValueDifference) {
            errors = errors.set('roundCategories', roundCategoriesErrors
              .set(roundCategory.get('_id'), roundCategoryErrors
                .push(`Increment value for ${category.get('name')} category cannot be greater than ${optionValueDifference}`)));
          }
          if (category.get('optionIncrement') > optionValueDifference) {
            errors = errors.set('roundCategories', roundCategoriesErrors
              .set(roundCategory.get('_id'), roundCategoryErrors
                .push(`Increment value for ${category.get('name')} category cannot be greater than ${optionValueDifference}`)));
          }
        }
      }
    });
  });

  // Format error return for categories
  validations.forEach((v, index) => {
    if (v) {
      const option = options.get(index);
      const category = option.get('category');
      const categoryErrors = (errors.get('categories') || Map()).get(category) || Map();

      errors = errors.set('categories', (errors.get('categories') || Map())
        .set(category, categoryErrors.set(index, Immutable.fromJS(v))));
    }
  });

  return errors.toJS();
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedCategoriesForm = reduxForm({
  form:               'categoriesForm',
  enableReinitialize: true,
  validate:           validateForm,
  onChange:           handleChange,
})(CategoriesForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedCategoriesForm);
