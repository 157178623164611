import PropTypes from 'prop-types';
import React from 'react';
import Immutable, { List } from 'immutable';
import { Form } from 'react-final-form';

// local components
import Button from '../../Button';
import Header from '../../Header';
import SlideshowSettings from './components/SlideshowSettings';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const SlideshowModal = function ({
  classes,
  currentChapterId,
  dialogOpen,
  fetchSlideshowPnms,
  fullScreen,
  filter,
  handleDialogClose,
  history,
  selectedPnms,
  numSelected,
}) {
  const onSubmit = (values) => {
    const {
      displayHometownGroup,
      displayMatches,
      displayNotes,
      displayStatus,
      displayTags,
      fieldsToDisplay = [],
      order,
      sortBy,
    } = values;

    const slideshowParams = {
      pnmIds:  selectedPnms.map(p => p.get('id')).toJS(),
      chapter: currentChapterId,
      sortBy,
      order,
      filter,
    };

    // fetch all PNMs
    fetchSlideshowPnms(slideshowParams);

    // close modal and re-route
    handleDialogClose();
    history.push({
      pathname: '/slideshow',
      search:   '?slide=1',
      state:    {
        displayHometownGroup,
        displayMatches,
        displayNotes,
        displayStatus,
        displayTags,
        fieldsToDisplay: Immutable.fromJS(fieldsToDisplay),
      },
    });
  };

  const getInitialValues = () => ({
    sortBy:        'lastName',
    order:         'asc',
    displayStatus: true,
  });

  return (
    <Dialog open={ dialogOpen }
      onClose={ handleDialogClose }
      fullScreen={ fullScreen }
      maxWidth={ false }>

      <Form id='slideshowForm'
        onSubmit={ onSubmit }
        initialValues={ getInitialValues() }
        render={ ({
          handleSubmit, invalid, values,
        }) => (

          <DialogContent classes={ { root: classes.dialogContentRoot } } component='form' onSubmit={ handleSubmit }>
            <Header icon='slideshow'
              title='Create Slideshow'
              subtitle='Fine tune the order and data that you need for each PNM in order to generate slides'
              highlightedSubtitle={ `${numSelected} PNMs selected` } />

            <SlideshowSettings handleSubmit={ handleSubmit }
              invalid={ invalid }
              values={ values } />

            <DialogActions className={ classes.dialogActionsRoot }>
              <Button onClick={ handleDialogClose } color='primary'>
                Cancel
              </Button>

              <Button variant='contained'
                type='submit'
                onClick={ handleSubmit }
                color='primary'>

                Generate
              </Button>
            </DialogActions>
          </DialogContent>
        ) } />

    </Dialog>
  );
};

SlideshowModal.propTypes = {
  classes:            PropTypes.instanceOf(Object).isRequired,
  currentChapterId:   PropTypes.number.isRequired,
  dialogOpen:         PropTypes.bool.isRequired,
  fetchSlideshowPnms: PropTypes.func.isRequired,
  filter:             PropTypes.instanceOf(Object),
  fullScreen:         PropTypes.bool.isRequired,
  handleDialogClose:  PropTypes.func.isRequired,
  history:            PropTypes.instanceOf(Object).isRequired,
  numSelected:        PropTypes.number.isRequired,
  selectedPnms:       PropTypes.instanceOf(List).isRequired,
};

SlideshowModal.defaultProps = {
  filter: {},
};

const styles = theme => ({
  dialogContentRoot: {
    minWidth:  '50vw',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding:   25,

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(
  withStyles(styles)(SlideshowModal)
);
