import PropTypes from 'prop-types';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import Immutable, { List } from 'immutable';
import 'react-vis/dist/style.css';

import {
  LineMarkSeries,
  Crosshair,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

class LineGraph extends Component {
  static propTypes = {
    classes:          PropTypes.instanceOf(Object).isRequired,
    data:             PropTypes.instanceOf(List).isRequired,
    from:             PropTypes.instanceOf(Object).isRequired,
    icon:             PropTypes.string,
    loading:          PropTypes.bool,
    stroke:           PropTypes.string,
    color:            PropTypes.string,
    title:            PropTypes.string.isRequired,
    to:               PropTypes.instanceOf(Object).isRequired,
    width:            PropTypes.string,
    widthBreakpoints: PropTypes.instanceOf(Object).isRequired,
  };

  static defaultProps = {
    width:   'md',
    stroke:  '#4285f4',
    color:   '#0059c1',
    icon:    'insert_chart',
    loading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        data:            [],
        crosshairValues: [],
      }),
    };
  }

  formatDataForGraph = (items) => {
    let formattedItems = [];
    const xAttr = 'date';
    const yAttr = 'count';
    formattedItems = items.map(item =>
      ({ x: new Date(item.get(xAttr)), y: item.get(yAttr) }));

    return formattedItems.toJS();
  }

  /**
   * Event handler for onMouseLeave.
   */
  handleMouseLeave = () => {
    this.setState({ data: this.state.data.set('crosshairValues', List()) });
  };

  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   */
  handleNearestX = (value) => {
    const formattedValues =  Immutable.fromJS([
      {
        x: value.x,
        y: value.y,
      },
    ]);

    this.setState({ data: this.state.data.set('crosshairValues', formattedValues) });
  };

  renderGraphContent = () => {
    const {
      classes, color, data, loading, stroke, from, to, width, widthBreakpoints,
    } = this.props;

    const crosshairValues = (this.state.data.get('crosshairValues') || List()).toJS();
    const formattedData = this.formatDataForGraph(data);
    const chartWidth = widthBreakpoints[width];

    let element;

    if (loading) {
      element = (
        <Grid container spacing={ 16 } justify='center'>
          <Grid item xs={ 12 } align='center'>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    } else if (data.size > 0) {
      element = (
        <Fade in={ !loading }>
          <XYPlot height={ 300 }
            width={ chartWidth }
            xDomain={ [from, to] }
            xType='time'
            onMouseLeave={ this.handleMouseLeave }
            stroke={ stroke }
            color={ color }>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <LineMarkSeries onNearestX={ d => this.handleNearestX(d) }
              data={ formattedData }
              curve='curveBasis' />
            <Crosshair values={ crosshairValues }
              titleFormat={ d => ({
                title: 'Date',
                value: new Date(d[0].x).toLocaleDateString(),
              }) }
              itemsFormat={ d => [{
                title: 'Value',
                value: d[0].y,
              }] } />
          </XYPlot>
        </Fade>
      );
    } else {
      element = (
        <Fade in={ !loading }>
          <Grid container spacing={ 16 } justify='center'>
            <Grid item xs={ 12 } align='center'>
              <Icon color='secondary' className={ classes.largeIcon }>
                insert_chart_outlined
              </Icon>
            </Grid>
            <Grid item xs={ 12 } align='center'>
              <Typography variant='subtitle1' color='secondary'>
                No Data Found
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      );
    }

    return element;
  }

  render() {
    const {
      classes, icon, title,
    } = this.props;

    return (
      <Grid item sm={ 12 } md={ 6 }>
        <div className={ classes.graphContainer }>
          <Grid container spacing={ 16 } alignItems='center'>
            <Grid item xs={ 1 }>
              <Icon className={ classes.grayIcon }>{ icon }</Icon>
            </Grid>
            <Grid item xs={ 11 }>
              <Typography color='textSecondary' variant='h5' gutterBottom>{ title }</Typography>
            </Grid>
            <Grid item xs={ 12 }>
              { this.renderGraphContent() }
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }
}

const styles = theme => ({
  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      35,
  },

  titleContainer: {
    marginTop:     20,
    display:       'flex',
    flexDirection: 'row',
  },

  grayIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  largeIcon: {
    fontSize: 75,
  },

  fill: {
    main: theme.palette.primary.main,
    dark: theme.palette.primary.dark,
  },

  outlined: {
    padding:      12,
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
  },

  graphContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    padding:      20,
    minHeight:    350,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default compose(withStyles(styles), withWidth())(LineGraph);
