import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove } from '../../sources/api/client';
import userService from '../../sources/api/userService';

// constants
import { USER_DELETE_INIT, USER_DELETE } from '../../constants/userTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSchema } from '../../schemas/user';

export function* userDelete(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { user, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(USER_DELETE, call(remove, userService, user, options), {
      schema: userSchema,
    });
  } catch (err) {
    yield put(error(USER_DELETE, err));
  } finally {
    yield put(doneIndicator(USER_DELETE));
  }
}

export function* watchUserDelete() {
  yield takeEvery(USER_DELETE_INIT, userDelete);
}

export default function* root() {
  yield all([
    fork(watchUserDelete),
  ]);
}
