import { Map } from 'immutable';

// constants
import { PDF_GENERATE } from '../constants/pdfTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case PDF_GENERATE: return httpReducer(state, action);
    default: return state;
  }
};
