import httpReducerTests from '../../support/httpReducerTests';

// constants
import { USER_FETCH_ALL } from '../../constants/userTypes';

// reducer
import reducer from '../user';

describe('USER_FETCH_ALL', () => {
  httpReducerTests(USER_FETCH_ALL, reducer, { normalized: 'user' });
});
