import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map, List } from 'immutable';

// components
import ExportReportsForm from './ExportReportsForm';

// action creators
import { fetchAllMatches, clearMatches } from '../../../../../../actions/matchActions';
import { exportCreate, exportClear } from '../../../../../../actions/exportActions';

// selectors
import getRounds from '../../../../../../lib/selectors/getRounds';
import getChapterAttribute from '../../../../../../lib/selectors/getChapterAttribute';
import getOrganization from '../../../../../../lib/selectors/getOrganization';

const getSelectedRoundForReport = (state, rounds) => {
  if (List.isList(rounds)) {
    const firstRound = rounds.first();
    return Map.isMap(firstRound) ? firstRound.get('_id', 0) : 0;
  }

  return 0;
};

const createInitialValues = (state) => {
  const rounds = getRounds(state);
  const currentRoundId = getChapterAttribute('currentRound')(state);
  const isCdSite = getChapterAttribute('isCdSite')(state);

  const currentRound = rounds.find(round => round.get('_id', '').toString() === currentRoundId) || Map();
  const events = currentRound.get('events', 0);

  return {
    eventFrom:           1,
    eventTo:             isCdSite ? events : 0,
    selectedRound:       0,
    selectedRoundReport: getSelectedRoundForReport(state, rounds),
  };
};

const mapStateToProps = (state, props) => ({
  category:          state.get('category'),
  chapter:           state.get('chapter'),
  currentChapter:    state.get('currentChapter'),
  currentRoundId:    getChapterAttribute('currentRound')(state),
  exportReportsForm: (state.get('form')).get('exportReportsForm') || Map(),
  exportState:       state.get('export'),
  initialValues:     createInitialValues(state, props),
  isCdSite:          getChapterAttribute('isCdSite')(state),
  match:             state.get('match'),
  roundCategory:     state.get('roundCategory'),
  rounds:            getRounds(state),
  user:              state.get('user'),
  organization:      getOrganization(state),
  currentUser:       state.get('currentUser'),
});

const mapDispatchToProps = dispatch => ({
  fetchAllMatches: (params) => {
    dispatch(fetchAllMatches(params));
  },

  clearMatches: () => {
    dispatch(clearMatches());
  },

  createExport: (params) => {
    dispatch(exportCreate(params));
  },

  clearExports: () => {
    dispatch(exportClear());
  },
});

// schemas
const schema = {
  report: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedExportReportsForm = reduxForm({
  form:     'exportReportsForm',
  validate: validateForm,
  onChange: handleChange,
})(ExportReportsForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedExportReportsForm);
