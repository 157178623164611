import { Map, List } from 'immutable';

// constants
import { STORE_CLEAR, STORE_CLEAR_FOR_DASHBOARD } from '../constants/storeTypes';

const clearStoreForDashboard = function (reducers, state, action) {
  const stateToSave = List([
    'form',
    'currentUser',
    'analytics',
    'router',
    'user',
    'chapter',
    'drawer',
  ]);

  let newState = state;

  newState.keySeq().forEach((k) => {
    if (!stateToSave.includes(k)) {
      newState = newState.set(k, Map());
    }
  });

  newState = newState.setIn(['user', 'data', 'result'], List());

  // remove current chapter from local storage
  window.localStorage.removeItem('chapter');

  return reducers(newState, action);
};

export default reducers => (state, action) => {
  switch (action.type) {
    case STORE_CLEAR:
      return reducers(Map(), action);
    case STORE_CLEAR_FOR_DASHBOARD:
      return clearStoreForDashboard(reducers, state, action);
    default:
      return reducers(state, action);
  }
};
