import { Map } from 'immutable';

// constants
import {
  USER_SPECIALTY_CLEAR,
  USER_SPECIALTY_CREATE,
  USER_SPECIALTY_REMOVE,
  USER_SPECIALTY_BULK_FETCH,
  USER_SPECIALTY_FETCH,
} from '../constants/userSpecialtyTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { addToFront, remove } from '../lib/normalizedReducerHelpers';

export default (state = Map(), action) => {
  switch (action.type) {
    case USER_SPECIALTY_FETCH: return httpReducer(state, action, { entity: 'userSpecialty' });
    case USER_SPECIALTY_BULK_FETCH: return httpReducer(state, action, { entity: 'userSpecialty' });
    case USER_SPECIALTY_CREATE: return httpReducer(state, action, { cb: addToFront() });
    case USER_SPECIALTY_REMOVE: return httpReducer(state, action, { cb: remove() });
    case USER_SPECIALTY_CLEAR: return Map();
    default: return state;
  }
};
