import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { List, Map } from 'immutable';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

// components
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '../Button';

// styles
import '../../assets/stylesheets/Slider.scss';

class SliderPopover extends Component {
  static propTypes = {
    anchorEl:           PropTypes.instanceOf(HTMLElement),
    classes:            PropTypes.instanceOf(Object).isRequired,
    defaultValue:       PropTypes.number.isRequired,
    fields:             PropTypes.instanceOf(Object), // final-form array
    handlePopoverClose: PropTypes.func.isRequired,
    handleSubmit:       PropTypes.func.isRequired,
    item:               PropTypes.instanceOf(Map).isRequired,
    items:              PropTypes.instanceOf(List).isRequired,
    label:              PropTypes.string.isRequired,
    popoverOpen:        PropTypes.bool.isRequired,
    size:               PropTypes.number.isRequired,
  };

  static defaultProps = {
    anchorEl: null,
    fields:   {},
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        item:        {},
        sliderValue: 0,
      }),
    };
  }

  componentWillMount() {
    const { defaultValue, item } = this.props;

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('sliderValue', defaultValue);
        map.set('item', item);
      }),
    });
  }

  componentWillReceiveProps(nextProps) {
    const { defaultValue, item } = nextProps;

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('sliderValue', defaultValue);
        map.set('item', item);
      }),
    });
  }

  handleSliderChange = (value) => {
    this.setState({ data: this.state.data.set('sliderValue', value) });
  }

  handleSubmitButton = (item, sliderValue) => {
    const {
      defaultValue, fields, handleSubmit, items,
    } = this.props;
    handleSubmit(defaultValue - 1, items, sliderValue, fields);
  }

  render() {
    const {
      anchorEl,
      classes,
      handlePopoverClose,
      label,
      popoverOpen,
      size,
    } = this.props;

    const item = this.state.data.get('item');
    const sliderValue = this.state.data.get('sliderValue');

    return (
      <Popover open={ popoverOpen }
        key={ item.id }
        className={ classes.orderPopover }
        onClose={ handlePopoverClose }
        anchorEl={ anchorEl }
        anchorReference='anchorEl'>

        <DialogContent>
          <Typography variant='subtitle1' className={ classes.popoverTitle }>
            Set display order for
            { label }
          </Typography>

          <InputRange minValue={ 1 }
            maxValue={ size || 20 }
            value={ sliderValue }
            onChange={ value => this.handleSliderChange(value) } />
        </DialogContent>

        <DialogActions className={ classes.popoverActionsRoot }>

          <Button onClick={ handlePopoverClose } color='primary'>
            Cancel
          </Button>

          <Button onClick={ () => this.handleSubmitButton(item, sliderValue) }
            color='primary'>

            <Icon className={ classes.leftIcon } color='inherit'>swap_vert</Icon>
            Submit

          </Button>
        </DialogActions>
      </Popover>
    );
  }
}

const styles = theme => ({
  orderPopover: {
    width:   400,
    padding: 25,
  },

  popoverTitle: {
    marginBottom: 20,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  inputRange: {
    background: theme.palette.secondary.main,
  },

  slider: { // From react-input-range's styles. Use classNames with keys to override fully
    background: theme.palette.secondary.main,
    border:     `1px solid ${theme.palette.secondary.main}`,
  },
  activeTrack: {
    background: theme.palette.secondary.main,
  },

  popoverActionsRoot: {
    paddingRight:  20,
    paddingBottom: 10,
  },
});

export default withStyles(styles)(SliderPopover);
