import { FORGOT_PASSWORD_CREATE_INIT, FORGOT_PASSWORD_UPDATE_INIT } from '../constants/forgotPasswordTypes';

export function forgotPasswordCreate(params) {
  return {
    type:    FORGOT_PASSWORD_CREATE_INIT,
    payload: params,
  };
}

export function forgotPasswordUpdate(params) {
  return {
    type:    FORGOT_PASSWORD_UPDATE_INIT,
    payload: params,
  };
}
