import client from './client';

export default (function LocationOrgService() {
  let service;

  if (!service) {
    service = client.service('locationOrgs');
  }

  return service;
}());
