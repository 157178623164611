import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List } from 'immutable';

// components
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Autosuggest from '../Autosuggest';
import Button from '../Button';

class MultiAutosuggest extends Component {
  static propTypes = {
    classes:                     PropTypes.instanceOf(Object).isRequired,
    defaultSuggestion:           PropTypes.instanceOf(Object),
    deleteChip:                  PropTypes.func.isRequired,
    helperText:                  PropTypes.string,
    input:                       PropTypes.instanceOf(Object).isRequired,
    label:                       PropTypes.string,
    loading:                     PropTypes.bool,
    onSuggestionsClearRequested: PropTypes.func.isRequired,
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    handleSelectAll:             PropTypes.func,
    handleClearSelected:         PropTypes.func,
    selected:                    PropTypes.instanceOf(List).isRequired,
    suggestions:                 PropTypes.instanceOf(Array).isRequired,
    variant:                     PropTypes.string,
    relativeBox:                 PropTypes.bool,
  }

  static defaultProps = {
    label:               '',
    helperText:          '',
    defaultSuggestion:   null,
    loading:             false,
    variant:             'standard',
    handleSelectAll:     null,
    handleClearSelected: null,
    relativeBox:         false,
  }

  handleSelectAll = () => {

  }

  handleClearAll = () => {

  }

  renderChips = () => {
    const { classes, deleteChip, selected } = this.props;

    const chips = [];
    let othersCount = 0;
    const others = [];

    if (selected.size === 0) {
      return (
        <Typography color='secondary'>None selected.</Typography>
      );
    }

    selected.forEach((item, index) => {
      const label = (item.get('label') || '').substring(0, 60).concat('...');

      if (index > 4 && index < 16) {
        others.push(label);

        if (index === 15) {
          others.push('...');
        }
        othersCount += 1;
      } else if (index <= 4) {
        chips.push(
          <Chip key={ item.get('value') }
            label={ label }
            onDelete={ () => deleteChip(index) }
            className={ classes.chip } />
        );
      }
    });

    if (othersCount > 0) {
      const othersString = others.join(', ');

      chips.push(
        <Tooltip title={ othersString } key='others'>
          <Chip label={ `+ ${othersCount} others` }
            className={ classes.chip } />
        </Tooltip>
      );
    }

    return (
      <div className={ classes.chips }>
        { chips }
      </div>
    );
  }

  // Render buttons if handler functions are passed as props
  renderButtons = () => {
    const { handleClearSelected, handleSelectAll, selected } = this.props;

    return (
      <Grid container>
        { false && typeof handleSelectAll === 'function'
          && (
          <Grid item xs={ 3 }>
            <Button variant='outlined' onClick={ handleSelectAll }>Select All</Button>
          </Grid>
          )}
        { selected.size > 0 && typeof handleClearSelected === 'function'
          && (
          <Grid item xs={ 9 }>
            <Button variant='outlined' color='secondary' onClick={ handleClearSelected }>Clear All</Button>
          </Grid>
          )}
      </Grid>
    );
  }

  render() {
    const {
      classes,
      defaultSuggestion,
      helperText,
      input,
      label,
      loading,
      onSuggestionsClearRequested,
      onSuggestionsFetchRequested,
      suggestions,
      variant,
      relativeBox,
    } = this.props;

    return (
      <Grid container spacing={ 16 }>
        <Grid item xs={ 12 } className={ classes.autoSuggestContainer }>
          <Autosuggest defaultSuggestion={ defaultSuggestion }
            helperText={ helperText }
            input={ input }
            loading={ loading }
            onSuggestionsClearRequested={ onSuggestionsClearRequested }
            onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
            suggestions={ suggestions }
            variant={ variant }
            relativeBox={ relativeBox }
            label={ label } />
        </Grid>
        <Grid item xs={ 12 }>
          { this.renderChips() }
        </Grid>
        <Grid item xs={ 12 }>
          { this.renderButtons() }
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  autoSuggestContainer: {
    alignItems: 'center',
  },

  chip: {
    margin: theme.spacing.unit / 4,
  },

  chips: {
    margin:   5,
    display:  'flex',
    flexWrap: 'wrap',
  },
});

export default withStyles(styles)(MultiAutosuggest);
