import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { patch } from '../../sources/api/client';
import bumpGroupService from '../../sources/api/bumpGroupService';

// constants
import { BUMP_GROUP_BULK_UPDATE, BUMP_GROUP_BULK_UPDATE_INIT } from '../../constants/bumpGroupTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { bumpGroupListSchema } from '../../schemas/bumpGroup';

export function* bumpGroupBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'bumpGroupsForm';

  yield put(startSubmit(FORM));

  const { items, chapter, shouldPopulateUser = false } = action.payload || {};

  const options = {
    query: {
      bulkUpdate: true,
      chapter,
    },
  };

  if (shouldPopulateUser) {
    options.query.shouldPopulateUser = true;
  }

  try {
    yield* httpSaga(BUMP_GROUP_BULK_UPDATE, call(patch, bumpGroupService, null, { items }, options), { schema: bumpGroupListSchema });

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(BUMP_GROUP_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(BUMP_GROUP_BULK_UPDATE));
  }
}

export function* watchBumpGroupBulkUpdate() {
  yield takeEvery(BUMP_GROUP_BULK_UPDATE_INIT, bumpGroupBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchBumpGroupBulkUpdate),
  ]);
}
