import { connect } from 'react-redux';
import { Map } from 'immutable';

// action creators
import { generatePdf } from '../../../actions/pdfActions';

// selectors
import getFields from '../../../lib/selectors/getFields';
import getLoadingFromState from '../../../lib/selectors/getLoadingFromState';
import getChapterAttribute from '../../../lib/selectors/getChapterAttribute';

// components
import PdfModal from './PdfModal';

const mapStateToProps = state => ({
  currentChapterId: getChapterAttribute('id')(state),
  fields:           getFields(state),
  pdfLoading:       getLoadingFromState('pdf')(state),
  docDefinition:    state.getIn(['pdf', 'data'], Map()).toJS(),
});

const mapDispatchToProps = {
  generatePdf,
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfModal);
