import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import { Chip, Tooltip, Typography } from '@material-ui/core';

const AutoSuggestChips = function ({
  classes,
  deleteChip,
  selected,
  chipsToDisplay,
  maxItemsForTooltip,
  maxLabelLength,
}) {
  const chips = [];
  let othersCount = 0;
  const others = [];

  if (selected.size === 0) {
    return (
      <Typography id='noneSelectedLabel' variant='body2' color='secondary'>
        No items selected.
      </Typography>
    );
  }

  selected.forEach((item = Map(), index) => {
    let label = item.get('label', '');

    if (label) {
      label = label.substring(0, maxLabelLength);

      if (label.length > maxLabelLength) {
        label = label.concat('...');
      }

      const maxItemsToDisplay = chipsToDisplay + maxItemsForTooltip;

      if (index >= chipsToDisplay) {
        if (index < maxItemsToDisplay) {
          others.push(label);
        } else if (index === maxItemsToDisplay) {
        // Trail off with ellipses Tooltip text if max items reached
          others.push('...');
        }
        othersCount += 1;
      } else if (index <= chipsToDisplay) {
        chips.push(
          <Chip key={ item.get('value') }
            label={ label }
            onDelete={ () => deleteChip(index) }
            className={ classes.chip } />
        );
      }
    }
  });

  if (othersCount > 0) {
    const othersString = others.join(', ');

    chips.push(
      <Tooltip title={ othersString } key='others'>
        <Chip id='othersChip'
          label={ `+ ${othersCount} others` }
          className={ classes.chip } />
      </Tooltip>
    );
  }

  return <div className={ classes.chips }>{chips}</div>;
};

AutoSuggestChips.propTypes = {
  classes:            PropTypes.instanceOf(Object).isRequired,
  deleteChip:         PropTypes.func.isRequired,
  selected:           PropTypes.instanceOf(List).isRequired,
  chipsToDisplay:     PropTypes.number,
  maxItemsForTooltip: PropTypes.number,
  maxLabelLength:     PropTypes.number,
};

AutoSuggestChips.defaultProps = {
  chipsToDisplay:     5,
  maxItemsForTooltip: 1,
  maxLabelLength:     60,
};

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit * 0.25,
  },

  chips: {
    margin:   5,
    display:  'flex',
    flexWrap: 'wrap',
  },
});

export default withStyles(styles)(AutoSuggestChips);
