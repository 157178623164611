import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';

// api
import { patch } from '../../sources/api/client';
import tagService from '../../sources/api/tagService';

// constants
import { TAG_BULK_UPDATE, TAG_BULK_UPDATE_INIT } from '../../constants/tagTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { tagListSchema } from '../../schemas/tag';

export function* tagBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'tagsForm';

  yield put(startSubmit(FORM));

  const { items, chapter } = action.payload || {};

  try {
    yield* httpSaga(TAG_BULK_UPDATE, call(patch, tagService, null, { items }, {
      query: {
        bulkUpdate: true,
        chapter,
      },
    }), { schema: tagListSchema });

    yield put(setSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(TAG_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(TAG_BULK_UPDATE));
  }
}

export function* watchTagBulkUpdate() {
  yield takeEvery(TAG_BULK_UPDATE_INIT, tagBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchTagBulkUpdate),
  ]);
}
