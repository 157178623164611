import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';

// components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

class InfoTab extends Component {
  static propTypes = {
    classes:       PropTypes.instanceOf(Object).isRequired,
    fields:        PropTypes.instanceOf(Immutable.List).isRequired,
    fieldsLoading: PropTypes.bool.isRequired,
    isCdSite:      PropTypes.bool.isRequired,
    pnmData:       PropTypes.instanceOf(Map).isRequired,
    pnmsLoading:   PropTypes.bool.isRequired,
  };

  renderFieldListItem = (item) => {
    const type = item.getIn(['standardField', 'type']);
    let optionsArray;
    let listItem;

    switch (type) {
      case 'text':
        listItem = item.get('text');
        break;
      case 'link':
        listItem = item.get('text') ? <a href={ item.get('text') } target='_blank' rel='noopener noreferrer'>Link</a> : '';
        break;
      case 'number':
        listItem = item.get('number');
        break;
      case 'select':
        optionsArray = item.getIn(['standardField', 'options']).split(',');
        listItem = optionsArray[item.get('selector')];
        break;
      default:
        break;
    }

    return (
      <ListItem>
        <Typography>{ listItem }</Typography>
      </ListItem>
    );
  }

  render() {
    const {
      classes, fields,
      fieldsLoading, isCdSite, pnmsLoading, pnmData,
    } = this.props;
    const loading = pnmsLoading || fieldsLoading;

    const standardFields = pnmData.get('standardFields', Immutable.List());
    const files = pnmData.get('files', Immutable.List());

    const orderedFields = standardFields.map((sf) => {
      let order = 0;

      if (fields) {
        fields.forEach((f) => {
          if (f.getIn(['standardField', 'id']) === sf.getIn(['standardField', 'id'])) {
            order = f.get('order');
          }
        });
      }

      return sf.withMutations((map) => {
        map.set('order', order);
      });
    }).sortBy(sf => sf.get('order'));

    return (
      <List subheader={ <div /> }>

        { loading
          ? (
            <Grid container spacing={ 8 }>
              <Grid item xs={ 12 } align='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          )
          : (
            <div>
              {files.size > 0
              && (
              <>
                <Typography color='textSecondary' align='center' variant='subheading'>
                  Files
                </Typography>
                <Divider />
                {files.map((file, index) => (
                  <div key={ file.get('id') }>
                    {index !== 0 && <Divider />}

                    <ListSubheader disableSticky>
                      {file.get('label')}
                    </ListSubheader>

                    <ListItem>
                      <Typography>
                        {
                          file.get('url')
                            ? (
                              <a href={ file.get('url') } target='_blank' rel='noreferrer'>
                                View Document
                              </a>
                            )
                            : 'No file uploaded'
                          }
                      </Typography>
                    </ListItem>
                  </div>
                ))}
                <Typography color='textSecondary' align='center' variant='subheading'>
                  Fields
                </Typography>
                <Divider />
              </>
              )}

              { pnmData.get('officialMatches') > 0
              && (
              <div key='officialMatch'>
                <ListItem>
                  <ListItemIcon>
                    <Icon color='primary'>extension</Icon>
                  </ListItemIcon>
                  <Typography color='primary' variant='body2' style={ { fontWeight: 'bold' } }>
                    Matched to you for current round
                  </Typography>
                </ListItem>

                <Divider />

              </div>
              )}
              { pnmData.get('hometownGroup')
              && (
              <div key={ pnmData.get('hometownGroup') }>

                <ListSubheader disableSticky>Hometown Group</ListSubheader>
                <ListItem>
                  <Typography>{ pnmData.get('hometownGroup') }</Typography>
                </ListItem>

                <Divider />

              </div>
              )}
              { standardFields.size || loading ? orderedFields.map((item, index) => (
                <div key={ item.get('id') }>

                  { (index !== 0) && <Divider /> }

                  <ListSubheader disableSticky>{ item.getIn(['standardField', 'text']) }</ListSubheader>

                  { this.renderFieldListItem(item) }

                </div>
              )) : (
                <div>
                  <ListItem className={ classes.title }>
                    <Typography color='secondary' align='center' variant='h6'>No Info Found</Typography>
                  </ListItem>
                  <ListItem>
                    <Avatar className={ classes.avatar }>
                      <Icon className={ classes.avatarIcon }>list</Icon>
                    </Avatar>
                  </ListItem>
                  <ListItem className={ classes.title }>
                    { isCdSite ? <Typography color='secondary' align='center'>Sync with CampusDirector to pull in data</Typography>
                      : (
                        <Typography color='secondary' align='center'>
                          Have an admin upload a spreadsheet using PNM IDs and field data
                        </Typography>
                      )}
                  </ListItem>
                </div>
              ) }
            </div>
          )}
      </List>
    );
  }
}

const styles = theme => ({
  title: {
    display:    'block',
    margin:     'auto',
    paddingTop: 15,
  },
  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },
  avatarIcon: { fontSize: 50 },
});

export default withStyles(styles)(InfoTab);
