import { connect } from 'react-redux';
import { Map } from 'immutable';

// components
import Rounds from './Rounds';

// actions
import { roundCreate, roundRemove } from '../../../../actions/roundActions';

// selectors
import getRounds from '../../../../lib/selectors/getRounds';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getStatusFromState from '../../../../lib/selectors/getStatusFromState';
import getOrganization from '../../../../lib/selectors/getOrganization';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  organization:   getOrganization(state),
  roundLoading:   getLoadingFromState('round')(state),
  roundStatus:    getStatusFromState('round')(state),
  rounds:         getRounds(state),
  roundsForm:     (state.get('form') || Map()).get('roundsForm') || Map(),
});

const mapDispatchToProps = dispatch => ({
  createRound: (params) => {
    dispatch(roundCreate(params));
  },
  removeRound: (id, chapter, index) => {
    dispatch(roundRemove(id, chapter, index));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Rounds);
