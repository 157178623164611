import { Map } from 'immutable';

// constants
import {
  MATCH_FETCH_FOR_PNM,
  MATCH_FETCH_FOR_USER,
  MATCH_FETCH_ALL,
  MATCH_DELETE,
  MATCH_CREATE,
  MATCH_AUTO,
  MATCH_CLEAR,
} from '../constants/matchTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { addToFront, remove } from '../lib/normalizedReducerHelpers';

export default (state = Map(), action) => {
  switch (action.type) {
    case MATCH_FETCH_ALL: return httpReducer(state, action, { entity: 'match' });
    case MATCH_FETCH_FOR_PNM: return httpReducer(state, action, { entity: 'match' });
    case MATCH_FETCH_FOR_USER: return httpReducer(state, action, { entity: 'match' });
    case MATCH_DELETE: return httpReducer(state, action, { cb: remove() });
    case MATCH_CREATE: return httpReducer(state, action, { cb: addToFront() });
    case MATCH_AUTO: return httpReducer(state, action, { cb: addToFront() });
    case MATCH_CLEAR: return Map();
    default: return state;
  }
};
