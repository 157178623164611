import PropTypes from 'prop-types';
import { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';

// local components
import TextInput from '../../../../../TextInput';
import Button from '../../../../../Button';

class ChapterInfoForm extends Component {
  static propTypes = {
    dirty:           PropTypes.bool.isRequired,
    chapterInfoForm: PropTypes.instanceOf(Map).isRequired,
    chapterData:     PropTypes.instanceOf(Map).isRequired,
    organization:    PropTypes.instanceOf(Map).isRequired,
    updateChapter:   PropTypes.func.isRequired,
  };

  handleSave = () => {
    const { chapterInfoForm, chapterData, updateChapter } = this.props;

    if (!chapterInfoForm.get('syncErrors')) {
      const updateParams = {
        chapterId: chapterData.get('id'),
        formName:  'chapterInfoForm',
        ...chapterInfoForm.get('values').toJS(),
      };

      updateChapter(updateParams);
    }
  }

  render() {
    const { chapterInfoForm, dirty, organization } = this.props;

    let disableButton = true;
    if (chapterInfoForm.get('syncErrors')) { disableButton = true; }
    if (dirty) { disableButton = false; }

    const showCustomerChapterId = !!organization.get('reportingPlatform');

    return (
      <Grid container spacing={ 16 }>

        <Grid item xs={ 12 } sm={ 6 }>
          <Grid container spacing={ 24 }>
            <Grid item xs={ 12 }>
              <Field name='name'
                id='name'
                label='Chapter Name'
                type='text'
                variant='outlined'
                component={ TextInput }
                fullWidth
                required />
            </Grid>

            <Grid item xs={ 12 }>
              <Field name='school'
                label='School'
                type='text'
                variant='outlined'
                component={ TextInput }
                fullWidth
                required />
            </Grid>

            <Grid item xs={ 12 }>
              <Field name='location'
                label='Address'
                type='text'
                variant='outlined'
                component={ TextInput }
                fullWidth />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Grid container spacing={ 24 }>
            { showCustomerChapterId && (
              <Grid item xs={ 12 }>
                <Field name='customer_chapter_id'
                  label='Customer Chapter ID'
                  type='text'
                  variant='outlined'
                  placeholder='You have not added a customer chapter ID'
                  component={ TextInput }
                  fullWidth />
              </Grid>
            )}

            <Grid item xs={ 12 }>
              <Field name='numberOfVotingMembers'
                label='Number of voting members'
                type='number'
                variant='outlined'
                component={ TextInput }
                fullWidth />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Button variant='contained'
            color='primary'
            onClick={ () => this.handleSave() }
            disabled={ disableButton }
            loading={ chapterInfoForm.get('submitting') && !chapterInfoForm.get('submitSucceeded') }>

            Save Settings
          </Button>
        </Grid>

      </Grid>
    );
  }
}

export default ChapterInfoForm;
