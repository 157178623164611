import { Map } from 'immutable';
import { reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import validate from 'validate.js';

// components
import MembershipConcernForm from './MembershipConcernForm';

// selectors
import getOrganization from '../../../../lib/selectors/getOrganization';

const createInitialValues = (state, { pnm }) => {
  const membershipConcerns = state.getIn(['membershipConcern', 'data', 'items'], Map());
  const currentUser = state.getIn(['currentUser', 'data'], Map());
  const currentRoundId = state.getIn(['currentChapter', 'data', 'currentRound']);

  return membershipConcerns.find(mc => mc.get('pnm') === pnm.get('id') && mc.getIn(['author', 'id']) === currentUser.get('id')
  && mc.get('round') === currentRoundId);
};

const mapStateToProps = (state, props) => ({
  organization:  getOrganization(state),
  initialValues: createInitialValues(state, props),
});

// schemas
const schema = {
  comment: {
    length: {
      maximum: 1000,
    },

    presence: (value) => {
      let validation;

      if ((value || []).length === 0) {
        validation = { message: 'is required' };
      }

      return validation;
    },
  },

  category: {
    presence: (value) => {
      let validation;

      if (!value) {
        validation = { message: 'is required' };
      }

      return validation;
    },
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) {
    stopSubmit();
  }
};

const wrappedMembershipConcernForm = reduxForm({
  form:     'membershipConcern',
  validate: validateForm,
  onChange: handleChange,
})(MembershipConcernForm);

export default connect(mapStateToProps)(wrappedMembershipConcernForm);
