import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { push } from 'react-router-redux';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { patch } from '../../sources/api/client';
import forgotPasswordService from '../../sources/api/forgotPasswordService';

// constants
import { FORGOT_PASSWORD_UPDATE_INIT, FORGOT_PASSWORD_UPDATE } from '../../constants/forgotPasswordTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* forgotPasswordUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'forgotPassword';

  yield put(startSubmit(FORM));

  const { payload } = action;

  const { token, password, confirmPassword } = payload || {};

  try {
    yield* httpSaga(FORGOT_PASSWORD_UPDATE, call(patch, forgotPasswordService, token, { password, confirmPassword }));

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));

    yield put(push('/login'));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(FORGOT_PASSWORD_UPDATE, err));
  } finally {
    yield put(doneIndicator(FORGOT_PASSWORD_UPDATE));
  }
}

export function* watchForgotPasswordUpdate() {
  yield takeEvery(FORGOT_PASSWORD_UPDATE_INIT, forgotPasswordUpdate);
}

export default function* root() {
  yield all([
    fork(watchForgotPasswordUpdate),
  ]);
}
