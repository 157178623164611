import {
  FORM_DESTROY,
  FORM_START_SUBMIT,
  FORM_STOP_SUBMIT,
  FORM_SET_SUBMIT_FAILED,
  FORM_SET_SUBMIT_SUCCEEDED,
} from '../constants/formTypes';

export function destroyForm(formName) {
  return {
    type:    FORM_DESTROY,
    payload: { formName },
  };
}

export function startFormSubmit(formName) {
  return {
    type:    FORM_START_SUBMIT,
    payload: { formName },
  };
}

export function stopFormSubmit(formName) {
  return {
    type:    FORM_STOP_SUBMIT,
    payload: { formName },
  };
}

export function setFormSubmitFailed(formName, err) {
  const errorResponse = (err || {}).response;

  return {
    type:    FORM_SET_SUBMIT_FAILED,
    payload: { formName, error: errorResponse || err },
  };
}

export function setFormSubmitSucceeded(formName) {
  return {
    type:    FORM_SET_SUBMIT_SUCCEEDED,
    payload: { formName },
  };
}
