import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '../Button';

const DesignGuide = function ({ classes }) {
  return (
    <Grid container spacing={ 24 } justify='center'>
      <Grid item xs={ 8 }>
        <Card>
          <CardContent>
            <Typography variant='h1' color='primary' gutterBottom>
              h1 variant
            </Typography>
            <Typography variant='h2' color='primary' gutterBottom>
              h2 variant
            </Typography>
            <Typography variant='h3' color='primary' gutterBottom>
              h3 variant
            </Typography>
            <Typography variant='h4' color='primary' gutterBottom>
              h4 variant
            </Typography>
            <Typography variant='h5' gutterBottom>
              h5 variant
            </Typography>
            <Typography variant='h6' gutterBottom>
              h6 variant
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              subtitle1 variant
            </Typography>
            <Typography variant='subtitle2' gutterBottom>
              subtitle2 variant
            </Typography>
            <Typography gutterBottom>
              body2 variant (default)
            </Typography>
            <Typography variant='body1' gutterBottom>
              body1 variant
            </Typography>
            <Typography variant='caption' gutterBottom align='center'>
              caption variant
            </Typography>

            <Typography gutterBottom noWrap>
              {`
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              `}
            </Typography>

            <Typography variant='button' gutterBottom>
              Button Text
            </Typography>

            <Typography color='primary'>
              Primary Color
            </Typography>

            <Typography color='secondary'>
              Secondary Color
            </Typography>

            <Typography color='textSecondary'>
              Accent Color
            </Typography>

            <Typography color='error'>
              Error Color
            </Typography>

            <Typography color='default'>
              Default Color
            </Typography>

            <CardActions>
              <Button>Flat Button</Button>
              <Button disabled>Flat Disabled</Button>
              <Button loading>Flat Loading</Button>
            </CardActions>

            <CardActions>
              <Button color='primary'>Flat Button</Button>
              <Button color='primary' disabled>Flat Disabled</Button>
              <Button color='primary' loading>Flat Loading</Button>
            </CardActions>

            <CardActions>
              <Button variant='contained'>raised Button</Button>
              <Button disabled variant='contained'>raised Disabled</Button>
              <Button loading variant='contained'>raised Loading</Button>
            </CardActions>

            <CardActions>
              <Button color='primary' variant='contained'>raised Button</Button>
              <Button color='primary' disabled variant='contained'>raised Disabled</Button>
              <Button color='primary' loading variant='contained'>raised Loading</Button>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={ 8 }>
        <Card className={ classes.blueCard }>
          <CardContent>
            <Typography variant='h1' gutterBottom>
              Display 4
            </Typography>
            <Typography variant='h2' gutterBottom>
              Display 3
            </Typography>
            <Typography variant='h3' gutterBottom>
              Display 2
            </Typography>
            <Typography variant='h4' gutterBottom>
              Display 1
            </Typography>
            <Typography variant='h5' gutterBottom>
              Headline
            </Typography>
            <Typography variant='h6' gutterBottom>
              Title
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              Subheading
            </Typography>
            <Typography gutterBottom>
              Body 2
            </Typography>
            <Typography gutterBottom align='right'>
              Body 1
            </Typography>
            <Typography variant='caption' gutterBottom align='center'>
              Caption
            </Typography>

            <Typography gutterBottom noWrap>
              {`
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              `}
            </Typography>

            <Typography variant='button' gutterBottom>
              Button Text
            </Typography>

            <Typography color='primary'>
              Primary Color
            </Typography>

            <Typography color='secondary'>
              Secondary Color
            </Typography>

            <Typography color='textSecondary'>
              Accent Color
            </Typography>

            <Typography color='error'>
              Error Color
            </Typography>

            <Typography color='default'>
              Default Color
            </Typography>

            <CardActions>
              <Button>Flat Button</Button>
              <Button disabled>Flat Disabled</Button>
              <Button loading>Flat Loading</Button>
            </CardActions>

            <CardActions>
              <Button color='contrast'>Flat Button</Button>
              <Button color='contrast' disabled>Flat Disabled</Button>
              <Button color='contrast' loading>Flat Loading</Button>
            </CardActions>

            <CardActions>
              <Button variant='contained'>raised Button</Button>
              <Button disabled variant='contained'>raised Disabled</Button>
              <Button loading variant='contained'>raised Loading</Button>
            </CardActions>

            <CardActions>
              <Button color='contrast' variant='contained'>raised Button</Button>
              <Button color='contrast' disabled variant='contained'>raised Disabled</Button>
              <Button color='contrast' loading variant='contained'>raised Loading</Button>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

DesignGuide.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

const styles = theme => ({
  blueCard: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default withStyles(styles)(DesignGuide);
