import {
  USER_SPECIALTY_CLEAR,
  USER_SPECIALTY_CREATE_INIT,
  USER_SPECIALTY_REMOVE_INIT,
  USER_SPECIALTY_FETCH_INIT,
  USER_SPECIALTY_BULK_FETCH_INIT,
} from '../constants/userSpecialtyTypes';

export function fetchUserSpecialties({ user }) {
  return {
    type:    USER_SPECIALTY_FETCH_INIT,
    payload: { user },
  };
}

export function bulkFetchUserSpecialties({ userIds }) {
  return {
    type:    USER_SPECIALTY_BULK_FETCH_INIT,
    payload: { userIds },
  };
}

export function createUserSpecialty(params) {
  return {
    type:    USER_SPECIALTY_CREATE_INIT,
    payload: params,
  };
}

export function removeUserSpecialty({ id, user, chapter }) {
  return {
    type:    USER_SPECIALTY_REMOVE_INIT,
    payload: { id, user, chapter },
  };
}

export function clearUserSpecialties() {
  return { type: USER_SPECIALTY_CLEAR };
}
