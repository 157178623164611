import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Map, List } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../Button';
import ConcernCategoriesForm from './components/ConcernCategoriesForm';

const ConcernCategories = function ({
  organization,
  classes,
  createConcernCategory,
  concernCategoryLoading,
  concernCategoryStatus,
  concernCategoriesFormSubmitting,
  parentclasses: {
    tabsCard, titleContainer, titleIcon, topButton,
  },
  removeConcernCategory,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [categoriesSize, setCategoriesSize] = useState(0);

  // pagination state
  const [timerId, setTimerId] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  let searchedConcernCategories = organization.get('concernCategories', List());
  let newCategoryLabel = 'New Concern Category';
  const finder = category => category.get('label') === newCategoryLabel;
  for (let i = 1; searchedConcernCategories.find(finder); i += 1) {
    newCategoryLabel = `New Concern Category (${i})`;
  }
  if (search) {
    searchedConcernCategories = searchedConcernCategories.filter(category =>
      category.get('label', '').toLowerCase().includes(search));
  }

  const pageStart = page * rowsPerPage;
  const pageEnd = pageStart + rowsPerPage;

  useEffect(() => {
    if (categoriesSize < searchedConcernCategories.size && categoriesSize > 0) {
      if (concernCategoryStatus === 'success') {
        setSnackbarOpen(true);
        setSnackbarMessage('Added Successfully');
      } else if (concernCategoryStatus === 'error') {
        setSnackbarOpen(true);
        setSnackbarMessage('Failed To Add');
      }
    }

    setCategoriesSize(searchedConcernCategories.size);
  }, [searchedConcernCategories.size, concernCategoryStatus]);

  const onSearchChange = (value = '') => {
    setSearch(value.toLowerCase());
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = ({ target: { value } }) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    setTimerId(setTimeout(onSearchChange, 500, value));
  };

  const handleAddConcernCategory = () => {
    createConcernCategory({
      label:        newCategoryLabel,
      organization: organization.get('_id', 0),
    });
  };

  return (
    <div>
      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>

            <Grid item xs={ 8 } sm={ 8 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>list</Icon>
              <Typography variant='h5' color='primary'>Manage Membership Concern Categories</Typography>
            </Grid>

            <Hidden xsDown>
              <Grid item sm={ 4 } className={ topButton } align='right'>
                <Button variant='contained'
                  color='primary'
                  loading={ concernCategoryLoading }
                  onClick={ handleAddConcernCategory }>
                  <Icon className={ classes.leftIcon }>add</Icon>
                  Add Category
                </Button>
              </Grid>
            </Hidden>

            <Hidden smUp>
              <Grid item sm={ 4 } className={ topButton } align='right'>
                <Button variant='fab' color='primary' disabled={ concernCategoryLoading } loading={ concernCategoryLoading }>
                  <Icon>add</Icon>
                </Button>
              </Grid>
            </Hidden>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Manage the available Membership Concern Categories
              </Typography>
              <Typography color='primary'>
                Drag items to rearrange display order, click button to move to a different page
              </Typography>
            </Grid>

            <Grid item xs={ 6 }>
              <Grid container justify='flex-start'>
                <Grid item xs={ 5 }>
                  <TextField label='Search Categories'
                    name='searchCategories'
                    onChange={ handleSearchChange }
                    InputProps={ {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Icon>search</Icon>
                        </InputAdornment>
                      ),
                    } }
                    fullWidth />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={ 6 }>
              <Grid container justify='flex-end'>
                <TablePagination component='div'
                  rowsPerPageOptions={ [5, 10, 15] }
                  rowsPerPage={ rowsPerPage }
                  count={ searchedConcernCategories.size }
                  page={ page }
                  onChangePage={ handleChangePage }
                  onChangeRowsPerPage={ handleChangeRowsPerPage } />
              </Grid>
            </Grid>

            { (concernCategoryLoading) && (
              <Grid item xs={ 12 } align='center'>
                <CircularProgress />
              </Grid>
            )}

            { !concernCategoriesFormSubmitting
              && (
              <Fade in={ !concernCategoriesFormSubmitting }>

                <Grid item xs={ 12 }>
                  <ConcernCategoriesForm createConcernCategory={ createConcernCategory }
                    organizationConcernCategories={ searchedConcernCategories }
                    pageStart={ pageStart }
                    pageEnd={ pageEnd }
                    removeConcernCategory={ removeConcernCategory } />
                </Grid>
              </Fade>
              )}

          </Grid>
        </CardContent>
      </Card>

      <Snackbar key='add'
        anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'right',
        } }
        open={ snackbarOpen }
        autoHideDuration={ 3000 }
        onClose={ () => setSnackbarOpen(false) }
        message={ snackbarMessage } />
    </div>
  );
};

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

ConcernCategories.propTypes = {
  organization:                    PropTypes.instanceOf(Map).isRequired,
  classes:                         PropTypes.instanceOf(Object).isRequired,
  createConcernCategory:           PropTypes.func.isRequired,
  concernCategoryLoading:          PropTypes.bool.isRequired,
  concernCategoryStatus:           PropTypes.string.isRequired,
  concernCategoriesFormSubmitting: PropTypes.bool.isRequired,
  parentclasses:                   PropTypes.instanceOf(Object).isRequired,
  removeConcernCategory:           PropTypes.func.isRequired,
};

export default withStyles(styles)(ConcernCategories);
