const spaceStyles = () => ({
  root: {
    display:   'inline-block',
    width:     '0.35em',
    '&.small': {
      width: '0.15em',
    },
    '&.long05': {
      width: '0.5em',
    },
  },
});

export default spaceStyles;
