import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroyForm,
  startFormSubmit,
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from '../../actions/formActions';

// api
import { patch } from '../../sources/api/client';
import fieldService from '../../sources/api/fieldService';

// constants
import { FIELD_BULK_UPDATE, FIELD_BULK_UPDATE_INIT } from '../../constants/fieldTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { fieldSchema } from '../../schemas/field';

export function* fieldBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'fieldsForm';

  yield put(startFormSubmit(FORM));

  const { items, chapter } = action.payload || {};

  items.forEach((item, index) => {
    items[index].order = index;
  });

  try {
    yield* httpSaga(FIELD_BULK_UPDATE, call(patch, fieldService, null, { items }, {
      query: {
        bulkUpdate: true,
        chapter,
      },
    }), { schema: fieldSchema });

    yield put(setFormSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setFormSubmitFailed(FORM));
    yield put(error(FIELD_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(FIELD_BULK_UPDATE));
    yield put(destroyForm(FORM));
  }
}

export function* watchFieldBulkUpdate() {
  yield takeEvery(FIELD_BULK_UPDATE_INIT, fieldBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchFieldBulkUpdate),
  ]);
}
