import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';
import moment from 'moment';
import { denormalize } from 'normalizr';

// components
import ListItem from './components/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import './CommentList.scss';

// schemas
import { roundListSchema } from '../../schemas/round';

// selectors
import getUserRole from '../../lib/selectors/getUserRole';

function groupItemsByDay(items) {
  return (Immutable.fromJS(items) || List())
    .sortBy(i => i.get('updatedAt'))
    .reverse()
    .groupBy(item => moment(new Date((item || Map()).get('updatedAt'))).format('MM-DD-YYYY'));
}

class CommentList extends Component {
  static propTypes = {
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    hideHeaders:    PropTypes.bool,
    fullWidth:      PropTypes.bool,
    items:          PropTypes.instanceOf(List).isRequired,
    loading:        PropTypes.bool.isRequired,
    navigate:       PropTypes.func.isRequired,
    onDelete:       PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    pnm:            PropTypes.instanceOf(Map).isRequired,
    round:          PropTypes.instanceOf(Map).isRequired,
    showPNM:        PropTypes.bool,
    type:           PropTypes.oneOf(['votes', 'notes', 'matches', 'tags']).isRequired,
  }

  static defaultProps = {
    showPNM:     false,
    hideHeaders: false,
    onDelete:    false,
    fullWidth:   false,
  }

  getRounds = () => {
    const {
      round, currentChapter,
    } = this.props;

    const result = currentChapter.getIn(['data', 'rounds'], List());
    const rounds = round.getIn(['data', 'items'], List());

    const entities = {
      round: rounds.toJS(),
    };

    const items = denormalize(result.toJS(), roundListSchema, entities);
    return Immutable.fromJS(items);
  }

  renderHeader(date) {
    const { hideHeaders } = this.props;

    let element;

    if (!hideHeaders) {
      element = (
        <div className='comment-list__header' key={ `${date}-header` }>
          <Typography variant='h6'>{ moment(date, 'MM-DD-YYYY').format('MMMM Do, YYYY') }</Typography>
        </div>
      );
    }

    return element;
  }

  renderDivider(key) {
    const { hideHeaders } = this.props;

    let element;

    if (!hideHeaders) {
      element = (
        <Divider className='comment-list__divider' key={ `${key}-divider` } />
      );
    }

    return element;
  }

  renderList = () => {
    const {
      currentChapter, currentUser,
      type, showPNM,
      onDelete, pnm, navigate,
    } = this.props;

    const elements = [];
    let iterator = 0;

    groupItemsByDay(this.props.items).forEach((items, date, map) => {
      elements.push(this.renderHeader(date));

      items.forEach((item, i) => {
        let renderTimeline = true;
        let first = false;
        let last = false;

        const author = item.get('author', Map()) || Map();
        let authorName = `${author.get('firstname') || ''} ${author.get('lastname') || ''}`;

        if (authorName.length < 2) {
          authorName = author.get('email') || '';
        }
        const pnms = pnm.getIn(['data', 'items'], List());

        const pnmId = Map.isMap(item.get('pnm'))
          ? item.getIn(['pnm', 'id'], '').toString()
          : item.get('pnm', '').toString();

        const currentPnm = Map.isMap(item.get('pnm'))
          ? item.get('pnm', Map())
          : pnms.get(pnmId, Map());

        const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
        const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);
        const currentUserId = currentUser.getIn(['data', 'id'], 0);
        const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';
        const rounds = this.getRounds() || List();

        const pnmName = `${currentPnm.get('firstname') || ''} ${currentPnm.get('lastname') || ''}`;
        const pnmPhoto = currentPnm.get('image') || currentPnm.get('image_link') || '';

        if (items.size === 1) {
          renderTimeline = false;
        } else if (i === 0) {
          first = true;
        } else if (i === (items.size - 1)) {
          last = true;
        }

        elements.push(
          <ListItem authorName={ authorName }
            clearanceLevel={ clearanceLevel }
            comment={ item.get('comment') }
            currentUserId={ currentUserId }
            isConcernChair={ isConcernChair }
            first={ first }
            id={ item.get('_id') }
            item={ item }
            key={ item.get('_id') }
            last={ last }
            navigate={ navigate }
            onDelete={ onDelete }
            pnmName={ pnmName }
            pnmId={ pnmId }
            pnmPhoto={ pnmPhoto }
            renderTimeline={ renderTimeline }
            rounds={ rounds }
            showPNM={ showPNM }
            tags={ (item.get('tags') || List()).toJS() }
            teamLevel={ teamLevel }
            type={ type }
            updatedAt={ item.get('updatedAt') }
            value={ item.get('value') } />
        );
      });

      if (iterator !== map.size - 1) {
        elements.push(this.renderDivider(date));
      }

      iterator += 1;
    });

    return elements;
  }

  render() {
    const { fullWidth, loading } = this.props;

    return (
      <div className='comment-list__container' style={ fullWidth ? { width: '100%' } : {} }>
        { !loading && this.renderList() }
        { loading && <CircularProgress /> }
      </div>
    );
  }
}

export default CommentList;
