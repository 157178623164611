import { connect } from 'react-redux';

// components
import Files from './Files';

const mapStateToProps = (state) => ({
  currentChapter: state.get('currentChapter'),
});

export default connect(mapStateToProps)(Files);
