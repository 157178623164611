// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/chevron.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login{align-items:center;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-color:#4285f4;display:flex;flex-grow:1}.login__container{padding:40px 20px}.login__logo{max-height:60px}.login__button-container{align-items:center;display:flex;flex-direction:column;justify-content:flex-end;min-height:75px}.login__button{margin-top:15px}.login__link{text-decoration:none;text-transform:uppercase}.login__error-container{margin-bottom:10px}input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active{transition:background-color 5000s ease-in-out 0s}`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.scss","webpack://./src/assets/stylesheets/variables.scss"],"names":[],"mappings":"AAEA,OACE,kBAAA,CACA,kDAAA,CACA,wBCHiB,CDIjB,YAAA,CACA,WAAA,CAGF,kBACE,iBAAA,CAGF,aACE,eAAA,CAGF,yBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,eAAA,CAGF,eACE,eAAA,CAGF,aACE,oBAAA,CACA,wBAAA,CAGF,wBACE,kBAAA,CAKF,+GAII,gDAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
