import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

// components
import TabMenu from './components/TabMenu';
import Grid from '@material-ui/core/Grid';

function Match(props) {
  Match.propTypes = {
    bumpGroups:           PropTypes.instanceOf(List).isRequired,
    fetchAllSpecialties:  PropTypes.func.isRequired,
    fetchUserSpecialties: PropTypes.func.isRequired,
    match:                PropTypes.instanceOf(Object).isRequired,
    router:               PropTypes.instanceOf(Object).isRequired,
  };

  const {
    bumpGroups,
    router: { location: { pathname } },
    match,
    fetchAllSpecialties,
    fetchUserSpecialties,
  } = props;

  useEffect(() => {
    fetchAllSpecialties();
    // Empty array for initial setup, won't get called again
    //  because there are no props to watch
  }, []);

  useEffect(() => {
    const pathnameIncludesUser = pathname.includes('user');
    const pathnameIncludesBumpGroup = pathname.includes('bumpgroup');
    const { id } = match.params;

    if (id !== 0) {
      if (pathnameIncludesUser) {
        fetchUserSpecialties({ user: id });
      } else if (pathnameIncludesBumpGroup) {
        const ids = [];
        const currentBumpGroup = bumpGroups.find(bg => bg.get('_id', '').toString() === id.toString()) || Map();
        // Get all user ids for the current bump group
        currentBumpGroup.get('users', List()).forEach((user) => {
          if (!ids.includes(user.get('id'))) {
            ids.push(user.get('id'));
          }
        });
      }
    }
    // Execute effects when the path's id changes
  }, [match.params.id]);

  return (
    <Grid container spacing={ 24 }>
      <Grid item xs={ 12 }>
        <TabMenu match={ match }
          pathname={ pathname } />
      </Grid>
    </Grid>
  );
}

export default Match;
