const sum = array => array.reduce((total, num) => total + num, 0);
const mean = array => sum(array) / array.length;

// VOTE SCORE: A compose vote score is calculated for a single PNM,
//   based on formulas from the voteScore attribute
export function calculateVoteScore(
  formula,
  voteValues,
  categories,
) {
  let emotionalId;
  let emotionalValue = 0;

  if (voteValues.size > 1 && Array.isArray(voteValues.toJS())) {
    let values = (voteValues.map(v => v.get('value')))
      .filter(v => v !== 0)
      .toJS();

    switch (formula) {
      case 'mean':
        return mean(values);

      case 'mean + emotional':
        emotionalId = (categories.find(c => c.get('name') === 'Emotional Score')).get('_id');
        emotionalValue = voteValues.find(v => v.get('category') === emotionalId);
        values = voteValues
          .filter(v => v.get('category') !== emotionalId)
          .map(v => v.get('value')).toJS();

        // Calculate the mean of all non Emotional score vote values and add Emotional score to it
        return (values.length ? mean(values) : 0) + emotionalValue.get('value');

      case 'sum':
        return sum(values);

      case 'other':
        return mean(values);

      default:
        return mean(values);
    }
  } else if (voteValues.size === 1) {
    return voteValues.first().get('value');
  }
  return voteValues;
}
