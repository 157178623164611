import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Map, List } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../Button';
import HometownGroupsForm from './components/HometownGroupsForm';

const HometownGroups = function ({
  classes,
  createHometownGroup,
  currentChapter,
  hometownGroups,
  hometownGroupStatus,
  hometownGroupLoading,
  hometownGroupsFormSubmitting,
  parentclasses: {
    tabsCard, titleContainer, titleIcon, topButton,
  },
  removeHometownGroup,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hometownGroupsSize, setHometownGroupsSize] = useState(0);

  useEffect(() => {
    if (hometownGroupsSize < hometownGroups.size && hometownGroupsSize > 0) {
      if (hometownGroupStatus === 'success') {
        setSnackbarOpen(true);
        setSnackbarMessage('Added Successfully');
      } else if (hometownGroupStatus === 'error') {
        setSnackbarOpen(true);
        setSnackbarMessage('Failed To Add');
      }
    }

    setHometownGroupsSize(hometownGroups.size);
  }, [hometownGroups.size, hometownGroupStatus]);

  const handleAddHometown = () => {
    const maxOrder = Math.max(...hometownGroups.map(hometown => hometown.get('order')));

    const values = {
      name:       'New Hometown',
      team_level: 0,
      order:      maxOrder + 1,
      removed:    0,
    };

    createHometownGroup({
      chapter: currentChapter.getIn(['data', 'id'], 0),
      ...values,
    });
  };

  const renderLoadingIndicator = () => {
    let element;

    if (hometownGroupsFormSubmitting || hometownGroupLoading) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <CircularProgress />
        </Grid>
      );
    }

    return element;
  };

  return (
    <div>
      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 24 }>

            <Grid item xs={ 8 } sm={ 8 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>home</Icon>
              <Typography variant='h5' color='primary'>Manage Hometown Groups</Typography>
            </Grid>

            <Hidden xsDown>
              <Grid item sm={ 4 } className={ topButton } align='right'>
                <Button variant='contained' color='primary' onClick={ handleAddHometown }>
                  <Icon className={ classes.leftIcon }>add</Icon>
                  Add Hometown Group
                </Button>
              </Grid>
            </Hidden>

            <Hidden smUp>
              <Grid item sm={ 4 } className={ topButton } align='right'>
                <Button variant='fab' color='primary' loading={ hometownGroupLoading } disabled={ hometownGroupLoading }>
                  <Icon>add</Icon>
                </Button>
              </Grid>
            </Hidden>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Create hometown groups to categorize PNMs, and then manage them here.
              </Typography>
              <Typography color='primary'>
                Click and drag items to rearrange display order
              </Typography>
            </Grid>

            { renderLoadingIndicator() }

            <Grid item xs={ 12 }>
              <HometownGroupsForm hometownGroups={ hometownGroups }
                removeHometownGroup={ removeHometownGroup }
                hometownGroupStatus={ hometownGroupStatus }
                currentChapter={ currentChapter } />
            </Grid>

          </Grid>
        </CardContent>
      </Card>

      <Snackbar key='add'
        anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'right',
        } }
        open={ snackbarOpen }
        autoHideDuration={ 3000 }
        onClose={ () => setSnackbarOpen(false) }
        message={ snackbarMessage } />
    </div>
  );
};

HometownGroups.propTypes = {
  classes:                      PropTypes.instanceOf(Object).isRequired,
  createHometownGroup:          PropTypes.func.isRequired,
  currentChapter:               PropTypes.instanceOf(Map).isRequired,
  hometownGroups:               PropTypes.instanceOf(List).isRequired,
  parentclasses:                PropTypes.instanceOf(Object).isRequired,
  removeHometownGroup:          PropTypes.func.isRequired,
  hometownGroupsFormSubmitting: PropTypes.bool.isRequired,
  hometownGroupLoading:         PropTypes.bool.isRequired,
  hometownGroupStatus:          PropTypes.string.isRequired,
};

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(HometownGroups);
