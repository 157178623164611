import classNames from 'classnames';
import Immutable, { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { matchPath } from 'react-router-dom';

// components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import DefaultListItem from '@material-ui/core/ListItem';
import Grow from '@material-ui/core/Grow';
import Icon from '@material-ui/core/Icon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// styles
import './ListItem.scss';

class ListItem extends Component {
  static propTypes = {
    classes:             PropTypes.instanceOf(Object).isRequired,
    currentChapter:      PropTypes.instanceOf(Map).isRequired,
    currentUser:         PropTypes.instanceOf(Map).isRequired,
    pathname:            PropTypes.string.isRequired,
    navigateToBumpGroup: PropTypes.func.isRequired,
    navigateToPNM:       PropTypes.func.isRequired,
    navigateToUser:      PropTypes.func.isRequired,
    onCheckboxChange:    PropTypes.func.isRequired,
    item:                PropTypes.instanceOf(Object).isRequired,
    itemId:              PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    itemsToDisplay:      PropTypes.string.isRequired,
    reinitializeList:    PropTypes.func.isRequired,
    selectAllChecked:    PropTypes.bool.isRequired, // eslint-disable-line
    shouldResetList:     PropTypes.bool.isRequired,
    oneVotePerPnm:       PropTypes.bool,
  }

  static defaultProps = {
    oneVotePerPnm: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        itemHovered: false,
        isChecked:   false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { shouldResetList, selectAllChecked, reinitializeList } = nextProps;

    if (shouldResetList && !selectAllChecked) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('isChecked', false);
        }),
      });
    } else if (selectAllChecked) {
      reinitializeList();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('isChecked', true);
        }),
      });
    }
  }

  getExpectedPath = () => {
    const { itemsToDisplay } = this.props;

    switch (itemsToDisplay) {
      case 'pnms':
      default:
        return '/pnm/:id';
      case 'users':
        return '/match/user/:id';
      case 'bumpGroups':
        return '/match/bumpgroups/:id';
    }
  }

  getCount = (attribute) => {
    const { item, itemsToDisplay } = this.props;
    const initialValue = 0;

    //
    const sumCount = item.get('users', List())
      .reduce((acc, cur) => acc + cur.get(attribute.toString()), initialValue);

    switch (itemsToDisplay) {
      case 'users':
        return item.get(attribute.toString()) || 0;
      case 'bumpGroups':
        return sumCount || 0;
      default:
        return 0;
    }
  };

  handleNavigate = () => {
    const {
      navigateToBumpGroup,
      navigateToPNM,
      navigateToUser,
      itemId,
      itemsToDisplay,
    } = this.props;

    switch (itemsToDisplay) {
      case 'pnms':
        navigateToPNM(itemId);
        break;
      case 'users':
        navigateToUser(itemId);
        break;
      case 'bumpGroups':
        navigateToBumpGroup(itemId);
        break;
      default:
        break;
    }
  }

  handleItemHovered = () => {
    const { reinitializeList, shouldResetList } = this.props;

    // When the list was just reset by the X button, we want checkboxes to render on hover again
    if (shouldResetList) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('isChecked', false);
          map.set('itemHovered', true);
        }),
      });

      reinitializeList();
    } else {
      this.setState({ data: this.state.data.set('itemHovered', true) });
    }
  }

  handleHideCheckbox = () => {
    this.setState({ data: this.state.data.set('itemHovered', false) });
  }

  handleCheckboxClick = (event) => {
    // stops checkbox from navigating to PNM profile on click
    event.preventDefault();
    event.stopPropagation();
  }

  handleCheckboxChange = (event, pnm) => {
    const { onCheckboxChange } = this.props;
    event.preventDefault();
    event.stopPropagation();
    onCheckboxChange(event, pnm);

    this.setState({ data: this.state.data.set('isChecked', event.target.checked) });
  }

  renderCheckIcon = () => {
    const { classes, item, oneVotePerPnm } = this.props;

    const voted = oneVotePerPnm ? item.get('voted') : item.get('votedThisRound');

    let element;

    if (voted) {
      element = <Icon className={ classes.checkIcon } color='primary'>check</Icon>;
    }

    return element;
  }

  renderOfficialMatchIcon = () => {
    const { classes, item, itemsToDisplay } = this.props;

    const hasOfficialMatch = itemsToDisplay === 'pnms' && item.get('officialMatches') > 0;

    let element;

    if (hasOfficialMatch) {
      element = <Icon className={ classes.matchIcon } color='primary'>extension</Icon>;
    }
    return element;
  }

  renderItemIcons = () => {
    const {
      currentChapter, currentUser, item, itemsToDisplay,
    } = this.props;

    const isAdmin =      currentChapter.getIn(['data', 'team_level'], 0) > 0
      || currentUser.getIn(['data', 'clearance_level'], 0) > 0;

    const isCdSite = currentChapter.getIn(['data', 'isCdSite']);
    const cobMode = currentChapter.getIn(['data', 'cobMode']);
    const demoMode = currentChapter.getIn(['data', 'demoMode']);

    let icons;

    if (isAdmin && itemsToDisplay === 'pnms') {
      icons = [
        <Icon className='pnm-list-item__votes-icon'
          key='voteIcon'
          color='action'>
          thumbs_up_down
        </Icon>,
        <Typography color='textSecondary'
          key='voteLabel'
          variant='subtitle1'>
          { item.get('voteCount') || 0 }
        </Typography>,
      ];
    } else if (isAdmin && ['users', 'bumpGroups'].includes(itemsToDisplay)) {
      icons = [
        <Icon className='pnm-list-item__matches-icon'
          key='recIcon'
          color='action'>
          extension
        </Icon>,
        <Typography color='textSecondary'
          key='recLabel'
          variant='subtitle1'>
          { this.getCount('matchRecs') }
        </Typography>,

        <Icon className='pnm-list-item__votes-icon'
          key='matchIcon'
          color='action'>
          check_circle
        </Icon>,
        <Typography color='textSecondary'
          key='matchLabel'
          variant='subtitle1'>
          { this.getCount('officialMatches') }
        </Typography>,
      ];
    }

    const councilId = isCdSite && !demoMode && !cobMode
      ? item.get('group_pnm_id')
      : item.get('leg_tech_pnm_id');

    return (
      <div className='pnm-list-item__secondary-text'>
        { itemsToDisplay === 'pnms' && <Typography color='textSecondary' variant='subtitle1'>{ `ID ${councilId}` }</Typography> }
        { icons }
      </div>
    );
  }

  renderAvatar = () => {
    const {
      classes, currentChapter, currentUser, item, shouldResetList, itemsToDisplay,
    } = this.props;

    const itemHovered = this.state.data.get('itemHovered');
    const isChecked = this.state.data.get('isChecked');

    const showCheckbox = itemsToDisplay === 'pnms' && (itemHovered || isChecked);
    const avatarText = itemsToDisplay === 'bumpGroups'
      ? `${item.get('order')}`
      : `${item.get('firstname', '').charAt(0)}${item.get('lastname', '').charAt(0)}`;

    const isAdmin =      currentChapter.getIn(['data', 'team_level'], 0) > 0
      || currentUser.getIn(['data', 'clearance_level'], 0) > 0;

    let avatar = (
      <Avatar src={ item.get('image') }
        className={ classes.avatar }
        alt={ avatarText } />
    );

    if (isAdmin && showCheckbox && !shouldResetList) {
      avatar = (
        <Grow in={ showCheckbox }>
          <Avatar className={ classes.avatar }>
            <Checkbox checked={ isChecked }
              className={ classes.checkbox }
              onClick={ e => this.handleCheckboxClick(e) }
              color='primary'
              onChange={ event => this.handleCheckboxChange(event, item) } />
          </Avatar>
        </Grow>
      );
    } else if (!item.get('image')) {
      avatar = (
        <Avatar className={ classes.avatar }>
          <Typography className={ classes.initials }
            align='center'>
            { avatarText }
          </Typography>
        </Avatar>
      );
    }

    return avatar;
  }

  render() {
    const {
      classes, pathname, item, itemId, shouldResetList, itemsToDisplay,
    } = this.props;

    const name = itemsToDisplay === 'bumpGroups'
      ? `Bump Group ${item.get('order')}`
      : `${item.get('firstname')} ${item.get('lastname')}`;

    const path = this.getExpectedPath();
    const currentId = ((matchPath(pathname, { path }) || {}).params || {}).id;
    const isChecked = this.state.data.get('isChecked');

    const classNamesKey = {};
    classNamesKey[classes.activeListItem] = (parseInt(currentId, 10) === itemId || isChecked) && !shouldResetList;

    const className = classNames(classNamesKey);

    const avatar = this.renderAvatar();

    return (
      <DefaultListItem className={ className }
        onMouseEnter={ () => this.handleItemHovered() }
        onMouseLeave={ () => this.handleHideCheckbox() }
        onClick={ this.handleNavigate }
        button>
        { this.renderCheckIcon() }

        <ListItemAvatar>
          { avatar }
        </ListItemAvatar>

        { this.renderOfficialMatchIcon() }

        <ListItemText className='pnm-list-item__text'
          primary={ <Typography variant='subtitle1' noWrap>{ name }</Typography> }
          secondary={ this.renderItemIcons() }
          disableTypography />

        <div className='pnm-list-item__secondary-action'>
          <Typography color='textSecondary' variant='h6'>{ item.get('status') }</Typography>
        </div>
      </DefaultListItem>
    );
  }
}

const styles = theme => ({
  activeListItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },

  avatar: {
    width:           48,
    height:          48,
    borderRadius:    100,
    backgroundColor: '#bdbdbd',
  },

  initials: {
    color:    theme.palette.common.white,
    fontSize: '1.25rem',
  },

  checkbox: {
    backgroundColor: theme.palette.common.white,
    '&:hover':       {
      backgroundColor: '#bdbdbd',
    },
  },

  matchIcon: {
    left:     2,
    bottom:   3,
    position: 'absolute',
    fontSize: 19,
  },

  checkIcon: {
    left:     0,
    position: 'absolute',
    top:      0,
  },
});

export default withStyles(styles)(ListItem);
