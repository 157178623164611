import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { removeUserSpecialty } from '../../../../actions/userSpecialtyActions';

// helpers
import getBumpGroups from '../../../../lib/selectors/getBumpGroups';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getMatches from '../../../../lib/selectors/getMatches';
import getUserSpecialties from '../../../../lib/selectors/getUserSpecialties';
import getUsers from '../../../../lib/selectors/getUsers';
import getFormIsActive from '../../../../lib/selectors/getFormIsActive';
import getChapterAttribute from '../../../../lib/selectors/getChapterAttribute';
import getClearanceLevel from '../../../../lib/selectors/getClearanceLevel';
import getTeamLevel from '../../../../lib/selectors/getTeamLevel';

// components
import HandMatchTab from './HandMatchTab';

const mapStateToProps = state => ({
  bumpGroups:               getBumpGroups(state),
  clearanceLevel:           getClearanceLevel(state),
  currentChapter:           state.get('currentChapter'),
  currentUser:              state.get('currentUser'),
  groupMembersFormIsActive: getFormIsActive('groupMembersForm')(state),
  isCdSite:                 getChapterAttribute('isCdSite')(state),
  matchLoading:             getLoadingFromState('match')(state),
  matches:                  getMatches(state),
  showBestMatchedWith:      getChapterAttribute('showBestMatchedWith')(state),
  teamLevel:                getTeamLevel(state),
  userLoading:              getLoadingFromState('user')(state),
  userSpecialties:          getUserSpecialties(state),
  users:                    getUsers(state),
});

const mapDispatch = {
  removeUserSpecialty,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(HandMatchTab));
