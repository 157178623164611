import PropTypes from 'prop-types';
import React from 'react';
import Immutable, { Map, List } from 'immutable';

// components
import Tag from '../../../Tag';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

function TagsCard(props) {
  TagsCard.propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    clearanceLevel: PropTypes.number.isRequired,
    hasLetter:      PropTypes.bool.isRequired,
    initialTags:    PropTypes.instanceOf(List).isRequired,
    isCdSite:       PropTypes.bool.isRequired,
    isLegacy:       PropTypes.bool.isRequired,
    isConcernChair: PropTypes.bool.isRequired,
    loading:        PropTypes.bool.isRequired,
    tag:            PropTypes.instanceOf(Map).isRequired,
    teamLevel:      PropTypes.number.isRequired,
  };

  const {
    classes,
    clearanceLevel,
    hasLetter,
    isCdSite,
    isLegacy,
    isConcernChair,
    loading,
    initialTags,
    tag,
    teamLevel,
  } = props;

  let tags = initialTags || List();

  const legacyTag = tag.getIn(['data', 'items'], List())
    .find(t =>
      t.get('type') === 'campusDirector'
    && t.get('title', '').toLowerCase() === 'legacy') || Map();

  const canAccessTag = teamLevel >= legacyTag.get('visibleTo') || clearanceLevel > 0 || isConcernChair;

  if (isLegacy && isCdSite && canAccessTag) {
    if (legacyTag) {
      tags = tags.push(legacyTag);
    } else {
      tags = tags.push(Immutable.fromJS({
        color: 'deepPurple',
        title: 'Legacy',
        _id:   1,
        count: 1,
      }));
    }
  }

  if (hasLetter && isCdSite) {
    tags = tags.push(Immutable.fromJS({
      color: 'green',
      title: 'Letter of Rec',
      _id:   2,
      count: 1,
    }));
  }

  return (
    <Card className={ classes.tagsCard }>
      <CardContent>
        <Typography variant='subtitle1'>Tags</Typography>
        { loading
          ? (
            <Grid container justify='center'>
              <Grid item xs={ 12 } align='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Fade in={ !loading }>
              <div>
                { tags.size ? (
                  <Grid container spacing={ 24 } className={ classes.tagContainer }>
                    { tags.map((t = Map()) =>
                      (
                        <Tag key={ t.get('_id', 0) }
                          variant='default'
                          displayCount={ t.get('type') === 'chapter' }
                          type={ t.get('type', '') }
                          count={ t.get('count') || 0 }
                          title={ t.get('title', 'Tag') }
                          color={ t.get('color', 'red') } />
                      )) }
                  </Grid>
                ) : <Typography className={ classes.tagContainer } color='secondary'>No tags assigned.</Typography>}
              </div>
            </Fade>
          )}
      </CardContent>
    </Card>
  );
}

const styles = () => ({
  tagsCard: {
    borderRadius: 10,
  },

  tagContainer: {
    marginTop: 20,
  },
});

export default withStyles(styles)(TagsCard);
