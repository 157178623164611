import { Map, List } from 'immutable';

// constants
import { REPORTING_FETCH_PNMS_TO_CONFIRM, REPORTING_CONFIRM_PNMS } from '../constants/reportingTypes';
import { PNM_BULK_UPDATE } from '../constants/pnmTypes';

// lib
import httpReducer from '../lib/httpReducer';

function handleConfirmPnms() {
  return (map, action) => {
    const { payload } = action;

    if (payload.data.status === 'reporting') {
      map.set('status', 'reporting');
      map.set('loading', false);
      map.set('message', payload.data.message);
    }

    return map;
  };
}

function updateEmail(state, action) {
  const { payload, meta } = action;
  const { data } = payload || {}; // data from the action creator

  const oldData = state.get('data') || Map(); // data from the reporting app state

  const oldPnms = oldData.get('notConfirmed') || List();
  let newPnms = oldPnms;

  if (!meta.loading) {
    oldPnms.forEach((oldPnm, index) => {
      const newPnm = oldPnm.withMutations((map) => {
        // Find updated PNM in data from action creator
        const updatedPnm = data.find(pnm => map.get('id') === pnm.id);

        // Update the email property
        map.set('email', (updatedPnm || {}).email);
      });

      // Update the pnm
      newPnms = newPnms.set(index, newPnm);
    });
  }

  return state.setIn(['data', 'notConfirmed'], newPnms);
}

export default (state = Map(), action) => {
  switch (action.type) {
    case PNM_BULK_UPDATE: return updateEmail(state, action);
    case REPORTING_FETCH_PNMS_TO_CONFIRM: return httpReducer(state, action, { entity: 'reporting', updateResult: false });
    case REPORTING_CONFIRM_PNMS: return httpReducer(state, action, { cb: handleConfirmPnms() });
    default: return state;
  }
};
