import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const draggableGrid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin:     `0 0 ${draggableGrid * 1.25}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  padding:      draggableGrid,
  width:        '100%',
  borderRadius: 5,
});

class DraggableList extends Component {
  static propTypes = {
    fields:            PropTypes.instanceOf(Object).isRequired,
    handleItemsChange: PropTypes.func,
    items:             PropTypes.instanceOf(Immutable.List).isRequired,
    listItem:          PropTypes.instanceOf(Object).isRequired,
  };

  static defaultProps = {
    handleItemsChange() {},
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        items: [],
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { items } = nextProps;
    this.setState({ data: this.state.data.set('items', items) });
  }

  onDragEnd = (result) => {
    const { handleItemsChange, fields } = this.props;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Some list items are fixed to the top of the list and not draggable
    //   We want to make sure no item is placed above them
    const previousItemDragDisabled = this.getDragDisabled(result.destination.index);
    if (previousItemDragDisabled) {
      return;
    }

    // reorderForm(result.source.index, result.destination.index);
    fields.move(result.source.index, result.destination.index);
    handleItemsChange();
  }

  getDragDisabled = (index) => {
    const { items } = this.props;
    const item = items.get(index);

    if (!(item || Map()).get('draggable') && (item || Map()).has('draggable')) {
      return true;
    }
    return false;
  }

  render() {
    const {
      listItem, fields,
    } = this.props;

    return (
      <DragDropContext onDragEnd={ this.onDragEnd }>

        <Droppable droppableId='droppable'>
          { (providedDrop, snapshotDrop) => (

            <div ref={ providedDrop.innerRef }
              style={ getListStyle(snapshotDrop.isDraggingOver) }>

              { fields.map((field, index) => (

                <Draggable key={ `${field}` }
                  draggableId={ index }
                  isDragDisabled={ this.getDragDisabled(index) }
                  index={ index }>
                  { (providedDrag, snapshotDrag) => (
                    <div>
                      <div ref={ providedDrag.innerRef }
                        { ...providedDrag.draggableProps }
                        { ...providedDrag.dragHandleProps }
                        style={ getItemStyle(
                          snapshotDrag.isDragging,
                          providedDrag.draggableProps.style
                        ) }>

                        {React.cloneElement(listItem, { field, index })}

                      </div>
                      {providedDrag.placeholder}

                    </div>
                  )}
                </Draggable>
              ))}
              {providedDrop.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DraggableList;
