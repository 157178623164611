import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// components
import BulkActions from './BulkActions';
import { pnmDelete, pnmDeleteAllForChapter } from '../../../../actions/pnmActions';
import { exportCreate, exportClear } from '../../../../actions/exportActions';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  exportState:    state.get('export'),
  totalPnms:      state.getIn(['pnm', 'data', 'total'], 0),
});

const mapDispatchToProps = dispatch => ({
  deletePnm: (params) => {
    dispatch(pnmDelete(params));
  },
  deleteAllPnmsForChapter: (params) => {
    dispatch(pnmDeleteAllForChapter(params));
  },
  createExport: (params) => {
    dispatch(exportCreate(params));
  },
  clearExports: () => {
    dispatch(exportClear());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkActions));
