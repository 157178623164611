import { connect } from 'react-redux';

// components
import UserItem from './UserItem';

const mapStateToProps = state => ({
  formState: state.get('form'),
  userState: state.get('user'),
});

export default connect(mapStateToProps)(UserItem);
