import Immutable from 'immutable';

export default (state, action, options = {}) => {
  const meta = action.meta || {};

  const { payload } = action;
  const { error, loading, infiniteScroll } = meta;
  const { entity, cb } = options;
  const updateResult = (options.updateResult !== false) && true;

  return state.withMutations((map) => {
    // Remove error and status from the state
    map.delete('error');
    map.delete('status');
    map.delete('message');

    // Set loading on the state
    map.set('loading', loading || false);

    if (error) {
      // If there was an error, set 'error' to the payload and 'status' to 'error' on the state
      map.set('error', Immutable.fromJS(payload));
      map.set('status', 'error');
    } else if (typeof payload !== 'undefined') {
      const { data, normalized, ...restOfPayload } = payload;

      // If we've made it this far, the request was a success -- set 'status' to 'success' on state
      map.set('status', 'success');

      if (cb) {
        // Offload the rest onto a callback if defined
        cb(map, action);
      } else if (normalized) {
        const newItems = normalized.entities[entity] || {};

        const resultKey = options.result || 'result';

        let result = ((map.get('data') || Immutable.Map()).get(resultKey) || Immutable.List());

        if (typeof result.toJS === 'function') {
          result = result.toJS();
        }

        if (updateResult && !infiniteScroll) {
          const newResult = normalized.result;

          result = newResult;
        } else if (updateResult && infiniteScroll) {
          result = result.concat(normalized.result);
        }

        const newData = (map.get('data') || Immutable.Map()).mergeDeep({
          items: newItems,
          ...restOfPayload,
        });

        // Set the result independent of the merge
        map.set('data', newData.set(resultKey, Immutable.fromJS(result)));
      } else {
        // If we've made it here, we're not using normalizr -- set 'data' on
        // the state to payload.data
        map.set('data', Immutable.fromJS(payload.data));
      }
    }
  });
};
