import { Map } from 'immutable';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';

// action creators
import { fetchUsersForChapter } from '../../../../../../actions/userActions';

// components
import SingleBallotMatchForm from './SingleBallotMatchForm';

// selectors
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';
import getUsers from '../../../../../../lib/selectors/getUsers';

const mapStateToProps = state => ({
  matchForm:       state.getIn(['form', 'matchForm'], Map()),
  chapter:         state.get('chapter'),
  userLoading:     getLoadingFromState('user')(state),
  userSuggestions: getUsers(state, 'suggestions'),
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: (params) => {
    dispatch(fetchUsersForChapter(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS());
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:     'matchForm',
  validate: validateForm,
  onChange: handleChange,
})(SingleBallotMatchForm));
