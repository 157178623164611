import Immutable, { Map, List } from 'immutable';

export function bulkAddToFront(map, action) {
  // Get the data off the payload
  const data = (action.payload || {}).data || [];

  // Get the old data, items, and result off of the state
  let newData = map.get('data') || Map();
  let newResult = newData.get('result') || List();
  let newItems = newData.get('items') || Map();

  data.forEach((dataItem) => {
    // Get the item id
    const id = dataItem._id || dataItem.id;

    // Add the new data item to the old items
    newItems = newItems.set(id, Immutable.fromJS(dataItem));

    // Check to see if this item already exists in the result
    const indexOfItem = newResult.indexOf(id);

    // Set the new result
    newResult = newResult.withMutations((oldList) => {
      if (indexOfItem !== -1) {
        oldList.delete(indexOfItem);
      }

      oldList.unshift(id);
    });

    // Set the new data
    newData = newData.withMutations((oldMap) => {
      oldMap.set('items', newItems);
      oldMap.set('result', newResult);
    });
  });
  // Return the new state with the new data
  return map.set('data', newData);
}

export function addToFront() {
  return (map, action) => {
    // Get the data off the payload
    const data = (action.payload || {}).data || {};

    if (Array.isArray(data)) {
      return bulkAddToFront(map, action);
    }

    // Get the item id
    const id = data._id || data.id;

    // Get the old data, items, and result off of the state
    const oldData = map.get('data') || Map();
    const oldItems = oldData.get('items') || Map();
    const oldResult = oldData.get('result') || List();

    // Add the new data item to the old items
    const newItems = oldItems.set(id, Immutable.fromJS(data));

    // Check to see if this item already exists in the result
    const indexOfItem = oldResult.indexOf(id);

    // Set the new result
    const newResult = oldResult.withMutations((oldList) => {
      if (oldList.includes(id)) {
        oldList.delete(indexOfItem);
      }

      oldList.unshift(id);
    });

    // Set the new data
    const newData = oldData.withMutations((oldMap) => {
      oldMap.set('items', newItems);
      oldMap.set('result', newResult);
    });

    // Return the new state with the new data
    return map.set('data', newData);
  };
}

export function add(updateResult = true) {
  return (map, action) => {
    const data = (action.payload || {}).data || {};
    const id = data._id || data.id;

    const oldData = map.get('data') || Map();
    const oldItems = oldData.get('items') || Map();
    const oldResult = oldData.get('result') || List();

    const newItems = oldItems.set(id, Immutable.fromJS(data));
    let newResult = oldResult;

    if (updateResult && !oldResult.includes(id)) {
      newResult = oldResult.push(id);
    }

    const newData = oldData.withMutations((oldMap) => {
      oldMap.set('items', newItems);
      oldMap.set('result', newResult);
    });

    return map.set('data', newData);
  };
}

export function removeList(map, action) {
  const { result } = action.payload.normalized;

  if (result.size === 0) {
    return map;
  }

  const oldData = map.get('data') || Map();
  const oldItems = oldData.get('items') || List();
  const oldResult = oldData.get('result') || List();

  let newItems = oldItems;
  let newResult = oldResult;
  let convertToInt = false;

  if (oldResult.size) {
    convertToInt = (typeof oldResult.get(0)) === 'number';
  }

  result.forEach((r) => {
    let id = r;

    if (convertToInt && typeof r !== 'number') {
      id = parseInt(r, 10);
    }

    newItems = newItems.remove(id);
    newResult = newResult.delete(newResult.indexOf(id));
  });

  const newData = oldData.withMutations((oldMap) => {
    oldMap.set('items', newItems);
    oldMap.set('result', newResult);
  });

  return map.set('data', newData);
}

export function remove(updateResult = true) {
  return (map, action) => {
    const data = (action.payload || {}).data || {};

    if (Array.isArray(data)) {
      return removeList(map, action);
    }

    const id = (data._id || '').toString() || Number(data.id);

    const oldData = map.get('data') || Map();
    const oldItems = oldData.get('items') || Map();
    const oldResult = oldData.get('result') || List();

    const newItems = oldItems.remove(id);
    let newResult = oldResult;

    if (updateResult) {
      newResult = oldResult.delete(oldResult.indexOf(id));
    }

    const newData = oldData.withMutations((oldMap) => {
      oldMap.set('items', newItems);
      oldMap.set('result', newResult);
    });

    return map.set('data', newData);
  };
}

export function mapSingleEntityToData() {
  return (map, action) => {
    const { payload: { normalized: { entity, result, entities } } } = action;

    map.set('data', Immutable.fromJS(entities[entity][result]));

    return map;
  };
}

export function pushIdOntoListAttribute(key) {
  return (map, action) => {
    const payloadData = action.payload.data;
    const id = payloadData._id || payloadData.id;

    const oldData = map.get('data') || Map();
    const oldList = oldData.get(key) || List();

    const newList = oldList.push(id);
    const newData = oldData.set(key, newList);

    return map.set('data', newData);
  };
}

export function removeIdFromListAttribute(key) {
  return (map, action) => {
    const payloadData = action.payload.data;
    const id = payloadData._id || payloadData.id;

    const oldData = map.get('data') || Map();
    const oldList = oldData.get(key) || List();

    const newList = oldList.delete(oldList.indexOf(id));
    const newData = oldData.set(key, newList);

    return map.set('data', newData);
  };
}

export function replaceListWithResult(key) {
  return (map, action) => {
    const { payload: { normalized: { result } } } = action;

    const oldData = map.get('data') || Map();

    const newList = List(result);
    const newData = oldData.set(key, newList);

    return map.set('data', newData);
  };
}
