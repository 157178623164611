/* eslint-disable no-console */
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form/immutable';
import pluralize from 'pluralize';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';

// local components
import TextInput from '../../../../../TextInput';
import SelectInput from '../../../../../SelectInput';
import ActionModal from '../../../../../ActionModal';

// helpers
import capitalize from '../../../../../../lib/capitalize';

const PRESCORE_ROUND_TYPES = [6, 7];

const RoundItem = function ({
  classes,
  currentChapter,
  deleteVotes,
  field,
  handleRoundChange,
  handleSnackbarOpen,
  index,
  organization,
  removeRound,
  roundCategory,
  roundLoading,
  roundStatus,
  rounds,
  roundVotes,
  fetchRoundVotes,
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const scoreLabel = organization.getIn(['labels', 'score'], 'Score');

  useEffect(() => {
    (async () => {
      // Load currentChapter slice of state again, but this time include
      // the roundHasVotes information
      const chapterId = (currentChapter.get('data')).get('id');
      if (chapterId !== undefined) {
        await fetchRoundVotes();
      }
    })();
  }, []);

  const getRoundTypeOptions = (round) => {
    const alreadyHasPreScore = (rounds.filter(r => r.get('roundType') === 6) || List()).size > 0;
    const cobMode = currentChapter.getIn(['data', 'cobMode']) || false;

    let items = [];
    const defaultRoundTypes = [
      { value: 0, label: 'Standard Round' },
      { value: 1, label: 'Open House' },
      { value: 2, label: 'Invitation' },
      { value: 3, label: 'Priority' },
      { value: 4, label: 'Bid Matching' },
      { value: 5, label: 'Preference' },
      { value: 6, label: `Pre-${capitalize(scoreLabel)}` },
    ];

    // These are entries in MongoDB for the org in the roundcategories collection.
    // These get entered by the dev team on request for "custom" voting categories.
    // If a group doesn't have any roundcategories in the db, then they'll receive the
    // default set of round types.
    const customRoundCategories = organization.get('roundCategories', List()).filter(
      rc => rc && rc.get('removed', false) === false
    );

    if (customRoundCategories.size > 0) {
      customRoundCategories.forEach((rc) => {
        const rcLabel = rc.get('label');
        const rcRoundType = defaultRoundTypes.find(({ value }) => value === rc.get('roundType'));
        const label = rcLabel || (rcRoundType ? rcRoundType.label : 'label missing');
        items.push({
          value: rc.get('roundType'),
          label,
        });
      });
    } else {
      items.push(...defaultRoundTypes);
    }

    const orgId = (currentChapter.get('data')).get('organization');

    if (cobMode && orgId !== 100) { // Kapp Delta doesn't want this. Everyone else gets by default.
      items.push({ value: 9, label: 'COB (No Categories)' });
    }

    // Remove the pre-score round type from the list if they already have a different
    // round with that type since they can only have one pre-score round.
    if (alreadyHasPreScore && round.get('roundType') !== 6) {
      items = items.filter(rc => rc.value !== 6);
    }

    return items;
  };

  const getEventOptions = (round) => {
    // Fills array with 1 to n options for the # of events for a round
    const items = [{ value: 0, label: 'Open' }];

    if (!PRESCORE_ROUND_TYPES.includes(round.get('roundType'))) {
      const totalOptions = 50;

      for (let i = 1; i <= totalOptions; i += 1) {
        const currentOption = { value: i, label: i };
        items.push(currentOption);
      }
    }

    return items;
  };

  const getScoreOptions = () => {
    // Fills array with 1 to n options for the # of top score votes per user
    const items = [{ value: 0, label: 'Unlimited' }];

    const totalOptions = 50;

    for (let i = 1; i <= totalOptions; i += 1) {
      const currentOption = { value: i, label: i };
      items.push(currentOption);
    }

    return items;
  };

  const handleDelete = () => {
    const roundId = (rounds.get(index) || Map()).get('_id');
    const chapterId = (currentChapter.get('data')).get('id');

    removeRound(roundId, chapterId, index);
    deleteVotes({ id: null, chapter: chapterId, round: roundId });
    setDeleteModalOpen(false);

    handleSnackbarOpen(roundStatus);
  };

  const checkRange = (value) => {
    const minValue = 0;
    const maxValue = 1000;
    if (minValue !== undefined && value && value < minValue) {
      return minValue;
    } if (maxValue !== undefined && value && value > maxValue) {
      return maxValue;
    }
    return value;
  };

  const round = rounds.get(index) || Map();
  const currentRound = (currentChapter.get('data') || Map()).get('currentRound');
  const isCurrentRound = round.get('_id') === currentRound;

  const disabled = isCurrentRound;

  const getDeleteDialogText = () => {
    if (roundVotes[index] > 0) {
      const msg = `WARNING: ${pluralize('Vote', roundVotes[index], true)}  
      ${pluralize('has', roundVotes[index], false)} 
      already been cast for this round. All votes will be lost!`;

      return (
        <Typography color='error'>{msg}</Typography>
      );
    }
    return (
      <Typography>This round will no longer appear for any user.</Typography>
    );
  };

  const displayTopScore = organization.get('enableTopScoresPerUser') || false;
  const roundCategories = roundCategory.getIn(['data', 'items'], List());
  const displayVotingLimit = (roundCategories.find(rc =>
    rc.get('roundType') === round.get('roundType')
        && rc.get('displayVotingLimit')) || Map())
    .get('displayVotingLimit');

  return (
    <Paper className={ classes.itemContainer }>
      <Grid container
        key={ (round.get(index) || Map()).get('id') }
        spacing={ 24 }
        className={ classes.itemWrapper }
        justify='space-between'>

        <Grid item xs={ 3 } sm={ 1 }>
          <Tooltip id='round-move-tip'
            placement='bottom'
            title={
              round.get('draggable') ? 'Click and drag to reorder' : 'Round is locked in place'
          }>

            <Avatar className={ classes.roundIcon }>
              { PRESCORE_ROUND_TYPES.includes(round.get('roundType'))
                ? <Icon>star</Icon>
                : <Typography variant='h3'>{ round.get('order') }</Typography>}
            </Avatar>
          </Tooltip>
        </Grid>

        <Grid item xs={ 9 } sm={ displayTopScore ? 2 : 3 }>
          <Field name={ `${field}.roundType` }
            label='Round Type'
            variant='outlined'
            margin='dense'
            component={ SelectInput }
            onChange={ e => handleRoundChange(e, index) }
            options={ getRoundTypeOptions(round) }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 3 }>
          <Field name={ `${field}.name` }
            label='Round Name'
            variant='outlined'
            margin='dense'
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 12 } sm={ 2 }>
          <Field name={ `${field}.events` }
            label='Events'
            variant='outlined'
            margin='dense'
            component={ SelectInput }
            options={ getEventOptions(round) }
            fullWidth
            required />
        </Grid>

        { displayVotingLimit
        && (
        <Grid item xs={ 12 } sm={ 2 }>
          <Field name={ `${field}.votingLimit` }
            label='Voting Limit'
            variant='outlined'
            margin='dense'
            type='number'
            normalize={ checkRange }
            component={ TextInput }
            fullWidth
            required />
        </Grid>
        )}

        { displayTopScore
        && (
        <Grid item xs={ 9 } sm={ 3 }>
          <Field name={ `${field}.topScores` }
            label='Top Scores Per User'
            variant='outlined'
            margin='dense'
            placeholder='Choose One'
            component={ SelectInput }
            options={ getScoreOptions() }
            fullWidth
            required />
        </Grid>
        )}
        <Grid item xs={ 3 } sm={ 1 } align='right'>
          <Tooltip title={ isCurrentRound ? 'Currently selected round' : 'Delete Round' }>
            <div>
              <IconButton className={ !disabled ? classes.redButton : '' }
                disabled={ disabled }
                onClick={ () => setDeleteModalOpen(true) }>
                <Icon>delete</Icon>
              </IconButton>
            </div>
          </Tooltip>
        </Grid>

        <ActionModal open={ deleteModalOpen }
          description={ getDeleteDialogText() }
          onAccept={ () => handleDelete(index) }
          onReject={ () => setDeleteModalOpen(false) }
          acceptLabel='Delete'
          rejectLabel='Cancel'
          loading={ roundLoading }
          confirmDeleting
          title='Are you sure you want to delete this round?' />
      </Grid>
    </Paper>
  );
};

RoundItem.propTypes = {
  classes:            PropTypes.instanceOf(Object).isRequired,
  currentChapter:     PropTypes.instanceOf(Map).isRequired,
  deleteVotes:        PropTypes.func.isRequired,
  field:              PropTypes.string.isRequired,
  handleRoundChange:  PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  index:              PropTypes.number.isRequired,
  organization:       PropTypes.instanceOf(Map).isRequired,
  removeRound:        PropTypes.func.isRequired,
  roundCategory:      PropTypes.instanceOf(Map).isRequired,
  roundLoading:       PropTypes.bool.isRequired,
  roundStatus:        PropTypes.string.isRequired,
  rounds:             PropTypes.instanceOf(List).isRequired,
  roundVotes:         PropTypes.arrayOf(PropTypes.number).isRequired,
  fetchRoundVotes:    PropTypes.func.isRequired,
};

const styles = theme => ({
  roundIcon: {
    height:          50,
    width:           50,
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  itemContainer: {
    padding:      15,
    borderRadius: 10,
  },

  itemWrapper: {
    marginTop:    -4,
    marginBottom: -20,
  },

  redButton: {
    color: red[500],
  },

  orderField: {
    display: 'none',
  },
});

export default withStyles(styles)(RoundItem);
