import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  reduxForm, getFormValues, hasSubmitFailed, hasSubmitSucceeded, isSubmitting,
} from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { noteBulkCreate } from '../../../../../../actions/noteActions';
import getTags from '../../../../../../lib/selectors/getTags';
import getChapterAttribute from '../../../../../../lib/selectors/getChapterAttribute';
import getUserAttribute from '../../../../../../lib/selectors/getUserAttribute';

// components
import TagForm from './TagForm';

const mapStateToProps = state => ({
  currentChapterId: getChapterAttribute('id')(state),
  currentUserId:    getUserAttribute('id')(state),
  tags:             getTags(state),
  submitFailed:     hasSubmitFailed('tagPnmForm')(state),
  submitSucceeded:  hasSubmitSucceeded('tagPnmForm')(state),
  tagPnmForm:       state.getIn(['form', 'tagPnmForm'], Map()),
  submitting:       isSubmitting('tagPnmForm')(state),
  formValues:       getFormValues('tagPnmForm')(state) || Map(),
});

// We're using noteCreate to make a tag with no text in the note
const mapDispatchToProps = dispatch => ({
  bulkCreateNote: (params) => {
    dispatch(noteBulkCreate(params));
  },
});

// schemas
const schema = {
  tag: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedTagForm = reduxForm({
  form:     'tagPnmForm',
  validate: validateForm,
  onChange: handleChange,
})(TagForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(wrappedTagForm));
