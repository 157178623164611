import { connect } from 'react-redux';
import { reduxForm, isDirty } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { chapterUpdate } from '../../../../../../actions/chapterActions';
import { fetchAllPnms } from '../../../../../../actions/pnmActions';

// components
import ChapterSettingsForm from './ChapterSettingsForm';

const createInitialValues = (props) => {
  const { currentChapter, organization } = props;
  const chapterData = currentChapter.get('data', Map());

  const getSetting = (attribute) => {
    let setting = organization.get(attribute);

    if (setting === undefined) {
      setting = chapterData.get(attribute);
    }

    return setting;
  };

  const values = {
    allowVotingMembersToTag:         getSetting('allowVotingMembersToTag'),
    cobMode:                         getSetting('cobMode'),
    demoMode:                        getSetting('demoMode'),
    disableNotesFeature:             getSetting('disableNotesFeature'),
    displayPnmInfoOnVote:            getSetting('displayPnmInfoOnVote'),
    displayPnmProfileOnVotingClosed: getSetting('displayPnmProfileOnVotingClosed'),
    displayPnmsOnVotingClosed:       getSetting('displayPnmsOnVotingClosed'),
    displayPreScore:                 getSetting('displayPreScore'),
    hideMatchesFromVotingMembers:    getSetting('hideMatchesFromVotingMembers'),
    hideNoteTagsFromVotingMembers:   getSetting('hideNoteTagsFromVotingMembers'),
    hideNotesFromVotingMembers:      getSetting('hideNotesFromVotingMembers'),
    restrictPnmsToCurrentEvent:      getSetting('restrictPnmsToCurrentEvent'),
    showAdvancedMatching:            getSetting('showAdvancedMatching'),
    showBestMatchedWith:             getSetting('showBestMatchedWith'),
    showVotedOnVotingClosed:         getSetting('showVotedOnVotingClosed'),
    usePreferredName:                getSetting('usePreferredName'),
    displaySingleBallot:             getSetting('displaySingleBallot'),
  };

  return values;
};

const mapStateToProps = (state, props) => ({
  chapterSettingsForm: state.getIn(['form', 'chapterSettingsForm'], Map()),
  dirty:               isDirty('chapterSettingsForm'),
  isSystemAdmin:       state.getIn(['currentUser', 'data', 'clearance_level']) === 2,
  initialValues:       createInitialValues(props),
});

const mapDispatchToProps = dispatch => ({
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
  fetchPnms: (params) => {
    dispatch(fetchAllPnms(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS());
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedChapterSettingsForm = reduxForm({
  form:               'chapterSettingsForm',
  enableReinitialize: true,
  validate:           validateForm,
  onChange:           handleChange,
})(ChapterSettingsForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedChapterSettingsForm);
