export const FIELD_CREATE_INIT = 'FIELD_CREATE_INIT';
export const FIELD_CREATE = 'FIELD_CREATE';

export const FIELD_REMOVE_INIT = 'FIELD_REMOVE_INIT';
export const FIELD_REMOVE = 'FIELD_REMOVE';

export const FIELD_BULK_UPDATE = 'FIELD_BULK_UPDATE';
export const FIELD_BULK_UPDATE_INIT = 'FIELD_BULK_UPDATE_INIT';
export const FIELD_BULK_UPDATE_DONE = 'FIELD_BULK_UPDATE_DONE';

export const FIELD_BULK_CREATE = 'FIELD_BULK_CREATE';
export const FIELD_BULK_CREATE_INIT = 'FIELD_BULK_CREATE_INIT';
export const FIELD_BULK_CREATE_DONE = 'FIELD_BULK_CREATE_DONE';
