import { schema } from 'normalizr';

// schemas
import { roundCategorySchema } from './roundCategory';
import { concernCategorySchema } from './concernCategory';

export const organizationSchema = new schema.Entity('organization', {
  roundCategories:   [roundCategorySchema],
  concernCategories: [concernCategorySchema],
}, { idAttribute: '_id' });

export const organizationListSchema = [organizationSchema];
