import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';

// material-ui
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// components
import Button from '../../../Button';
import { FieldArray, Field } from 'redux-form/immutable';
import SelectInput from '../../../SelectInput';

class ColumnMatcher extends Component {
  static propTypes = {
    open:       PropTypes.bool.isRequired,
    classes:    PropTypes.instanceOf(Object).isRequired,
    data:       PropTypes.instanceOf(List).isRequired,
    onSave:     PropTypes.func.isRequired,
    onCancel:   PropTypes.func.isRequired,
    formValues: PropTypes.instanceOf(Map),
  }

  static defaultProps = {
    formValues: Map(),
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        options: [],
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data:nextData } = nextProps;
    const { data:currentData } = this.props;

    if (!Immutable.is(nextData, currentData)) {
      this.setOptions(nextProps.data);
    }
  }

  getOptions = (data) => {
    const firstRow = data.get(0, List());
    let options = Immutable.fromJS([
      {
        label: 'None',
        value: 'none',
      },
    ]);

    firstRow.forEach((item, index) => {
      options = options.push(Map({
        label: `Column ${index + 1}${item ? (` (${item})`) : ''}`,
        value: index,
      }));
    });

    return options;
  }

  setOptions = (data) => {
    this.setState({ data: this.state.data.set('options', this.getOptions(data)) });
  }

  handleSave = (onSave, formValues, data) => () => {
    const newColumnOrder = formValues.get('columns').map(column => column.get('match'));
    const newOrderedData = data.map(row =>
      newColumnOrder.map((column) => {
        if (column === 'none') {
          return undefined;
        }
        return row.get(column);
      }));

    onSave(newOrderedData.toJS());
  };

  renderFields = ({ fields }) => {
    const { classes } = this.props;
    const options = this.state.data.get('options', List()).toJS();

    return fields.map((item, index) => {
      const column = fields.get(index);

      return (
        <Grid item key={ item } xs={ 12 }>
          <Grid container className={ classes.fieldContainer } spacing={ 8 } alignItems='center'>
            <Grid item xs={ 6 }>
              <Typography>{ column.get('name') }</Typography>
            </Grid>
            <Grid item xs={ 6 }>
              <Field name={ `${item}.match` }
                label='Match To'
                component={ SelectInput }
                options={ options }
                containerClassName={ classes.selectInput }
                menuItemClassName={ classes.selectOption } />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  }

  render() {
    const {
      open, classes, data, onSave, onCancel, formValues,
    } = this.props;

    return (
      <Dialog open={ open }>
        <DialogTitle>Column Matcher</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Match columns from your file import to PNM fields
          </DialogContentText>

          <Grid container spacing={ 8 } alignItems='center' justify='center' component='form'>
            <FieldArray name='columns' component={ this.renderFields } />
          </Grid>
        </DialogContent>

        <DialogActions className={ classes.actionsContainer }>
          <Button onClick={ onCancel } color='primary'>Cancel</Button>
          <Button variant='contained' color='primary' onClick={ this.handleSave(onSave, formValues, data) }>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = {
  fieldContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    padding:      '0 20px',
    marginTop:    2,
  },

  selectOption: {
    maxWidth: '400px',
  },

  selectInput: {
    paddingTop: '15px',
  },

  actionsContainer: {
    padding: '10px',
  },
};

export default withStyles(styles)(ColumnMatcher);
