import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, Map } from 'immutable';

// components
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tag from '../../../Tag';

// styles
import './Value.scss';

const Value = function ({
  classes, item, rounds, type, tags, value, teamLevel, clearanceLevel, isConcernChair,
}) {
  Value.propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    item:           PropTypes.instanceOf(Map).isRequired,
    rounds:         PropTypes.instanceOf(List).isRequired,
    type:           PropTypes.oneOf(['votes', 'notes', 'matches', 'tags']).isRequired,
    tags:           PropTypes.instanceOf(Array),
    value:          PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    teamLevel:      PropTypes.number.isRequired,
    clearanceLevel: PropTypes.number.isRequired,
    isConcernChair: PropTypes.bool,
  };

  Value.defaultProps = {
    tags:           [],
    value:          '',
    isConcernChair: false,
  };

  let voteValue = value;

  if (type === 'votes') {
    voteValue = Math.round(value * 100) / 100;
  }

  const matchHasRound = type === 'matches' && rounds.size && item.get('round');
  const matchHasNoUser = type === 'matches' && !item.get('user');

  return (
    <div className='comment-list-item__left-container'>
      <Hidden smDown>
        <div className='comment-list-item__icon-container'>
          <div className='comment-list-item__icon'>
            { type === 'votes' && <Icon color='secondary'>thumbs_up_down</Icon> }
            { type === 'notes' && <Icon color='secondary'>message</Icon> }
            { type === 'concerns' && <Icon color='secondary'>info</Icon> }
            { type === 'matches' && <Icon color='secondary'>extension</Icon> }
            { type === 'tags' && <Icon color='secondary'>local_offer</Icon> }
          </div>
        </div>
      </Hidden>

      <div className='comment-list-item__value-container'>
        <div className='comment-list-item__value'>
          { matchHasRound
            && (
            <Tag key={ 0 }
              title='Official Match'
              color='primary'
              tooltipTitle='Match assigned by your chapter admin' />
            )}
          { matchHasNoUser
            && (
            <Tag key={ 0 }
              title='Match Tip'
              tooltipTitle='Match without an assigned member detailing general match criteria' />
            )}
          { type === 'votes' && <Typography className={ classes.voteValue } variant='h2' color='inherit'>{ voteValue }</Typography> }
          { (type === 'notes' || type === 'tags') && tags.filter(tag => teamLevel >= tag.visibleTo || clearanceLevel > 0 || isConcernChair)
            .map(tag => <Tag key={ tag._id } title={ tag.title } color={ tag.color } />) }
        </div>
      </div>
    </div>
  );
};

const styles = {
  voteValue: {
    fontFamily: 'Poppins',
  },
};

export default withStyles(styles)(Value);
