import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { List, Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../Button';
import FieldsForm from './components/FieldsForm';

const Fields = function ({
  chapterFields,
  classes,
  createField,
  currentChapter,
  fieldLoading,
  fieldStatus,
  fieldsFormSubmitting,
  parentclasses: {
    tabsCard, titleContainer, titleIcon, topButton,
  },
  removeField,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [fieldsSize, setFieldsSize] = useState(0);

  // pagination state
  const [timerId, setTimerId] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  let searchedChapterFields = chapterFields;

  if (search) {
    searchedChapterFields = searchedChapterFields.filter(field =>
      field.getIn(['standardField', 'text'], '').toLowerCase().includes(search));
  }

  const pageStart = page * rowsPerPage;
  const pageEnd = pageStart + rowsPerPage;

  useEffect(() => {
    if (fieldsSize < chapterFields.size && fieldsSize > 0) {
      if (fieldStatus === 'success') {
        setSnackbarOpen(true);
        setSnackbarMessage('Added Successfully');
      } else if (fieldStatus === 'error') {
        setSnackbarOpen(true);
        setSnackbarMessage('Failed To Add');
      }
    }

    setFieldsSize(chapterFields.size);
  }, [chapterFields.size, fieldStatus]);

  const onSearchChange = (value = '') => {
    setSearch(value.toLowerCase());
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = ({ target: { value } }) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    setTimerId(setTimeout(onSearchChange, 500, value));
  };

  const handleAddField = () => {
    const standardField = {
      text:    'New Field',
      type:    'text',
      options: '',
    };

    const values = {
      standardField,
      teamLevel: 0,
      cob:       currentChapter.getIn(['data', 'cobMode']) || false,
    };

    createField({
      chapter: currentChapter.getIn(['data', 'id'], 0),
      ...values,
    });
  };

  const isCdSite = currentChapter.getIn(['data', 'isCdSite']) || false;
  const cobMode = currentChapter.getIn(['data', 'cobMode']) || false;

  return (
    <div>
      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>

            <Grid item xs={ 8 } sm={ 8 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>list</Icon>
              <Typography variant='h5' color='primary'>Manage Fields</Typography>
            </Grid>

            { !isCdSite || cobMode ? (
              <Hidden xsDown>
                <Grid item sm={ 4 } className={ topButton } align='right'>
                  <Button variant='contained'
                    color='primary'
                    loading={ fieldLoading }
                    onClick={ handleAddField }>
                    <Icon className={ classes.leftIcon }>add</Icon>
                    Add Field
                  </Button>
                </Grid>
              </Hidden>
            ) : <Grid item sm={ 4 } /> }

            <Hidden smUp>
              <Grid item sm={ 4 } className={ topButton } align='right'>
                <Button variant='fab' color='primary' disabled={ fieldLoading } loading={ fieldLoading }>
                  <Icon>add</Icon>
                </Button>
              </Grid>
            </Hidden>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Customize the order, names, and visibility of the fields
                that display on a PNMs info tab
              </Typography>
              <Typography color='primary'>
                Drag items to rearrange display order, click button to move to a different page
              </Typography>
            </Grid>

            <Grid item xs={ 6 }>
              <Grid container justify='flex-start'>
                <Grid item xs={ 5 }>
                  <TextField label='Search Fields'
                    name='searchFields'
                    onChange={ handleSearchChange }
                    InputProps={ {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Icon>search</Icon>
                        </InputAdornment>
                      ),
                    } }
                    fullWidth />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={ 6 }>
              <Grid container justify='flex-end'>
                <TablePagination component='div'
                  rowsPerPageOptions={ [5, 10, 15] }
                  rowsPerPage={ rowsPerPage }
                  count={ searchedChapterFields.size }
                  page={ page }
                  onChangePage={ handleChangePage }
                  onChangeRowsPerPage={ handleChangeRowsPerPage } />
              </Grid>
            </Grid>

            { (fieldLoading) && (
              <Grid item xs={ 12 } align='center'>
                <CircularProgress />
              </Grid>
            )}

            { !fieldsFormSubmitting
              && (
              <Fade in={ !fieldsFormSubmitting }>

                <Grid item xs={ 12 }>
                  <FieldsForm createField={ createField }
                    currentChapter={ currentChapter }
                    chapterFields={ searchedChapterFields }
                    pageStart={ pageStart }
                    pageEnd={ pageEnd }
                    removeField={ removeField }
                    isCdSite={ isCdSite } />
                </Grid>
              </Fade>
              )}

          </Grid>
        </CardContent>
      </Card>

      <Snackbar key='add'
        anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'right',
        } }
        open={ snackbarOpen }
        autoHideDuration={ 3000 }
        onClose={ () => setSnackbarOpen(false) }
        message={ snackbarMessage } />
    </div>
  );
};

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

Fields.propTypes = {
  chapterFields:        PropTypes.instanceOf(List).isRequired,
  classes:              PropTypes.instanceOf(Object).isRequired,
  createField:          PropTypes.func.isRequired,
  currentChapter:       PropTypes.instanceOf(Map).isRequired,
  fieldLoading:         PropTypes.bool.isRequired,
  fieldStatus:          PropTypes.string.isRequired,
  fieldsFormSubmitting: PropTypes.bool.isRequired,
  parentclasses:        PropTypes.instanceOf(Object).isRequired,
  removeField:          PropTypes.func.isRequired,
};

export default withStyles(styles)(Fields);
