import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import CircularProgressbar from 'react-circular-progressbar';

// styles
import './CircularStatProgress.scss';

class CircularStatProgress extends Component {
  static propTypes = {
    max:   PropTypes.number,
    value: PropTypes.number,
  }

  static defaultProps = {
    value: 0,
    max:   100,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  componentWillMount() {
    const { value } = this.props;
    // this.timer = setInterval(this.progress, 1000);
    this.setState({ value });
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({ value });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { max } = this.props;
    const { value } = this.state;

    if (value < max) {
      this.setState({ value: value + 5 });
    } else {
      this.setState({ value: 0 });
    }
  }

  render() {
    const { value } = this.state;

    return (
      <CircularProgressbar initialAnimation
        percentage={ value }
        textForPercentage={ pct => pct.toString() } />
    );
  }
}

export default CircularStatProgress;
