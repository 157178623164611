import { MEMBERSHIP_CONCERN_CREATE_INIT, MEMBERSHIP_CONCERN_UPDATE_INIT } from '../constants/membershipConcernTypes';

export function membershipConcernCreate(params) {
  return {
    type:    MEMBERSHIP_CONCERN_CREATE_INIT,
    payload: params,
  };
}

export function membershipConcernUpdate(params) {
  return {
    type:    MEMBERSHIP_CONCERN_UPDATE_INIT,
    payload: params,
  };
}
