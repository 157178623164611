import React, { useCallback, useEffect, useState } from 'react';
import { Map } from 'immutable';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';

// MUI
import Grid from '@material-ui/core/Grid';

// local components
import FileSettingsItem from '../FileSettingsItem';
import Savebar from '../../../../../Savebar';

const FilesSettingsForm = function ({
  currentChapter,
  filesSettingsForm,
  dirty,
  initialValues,
  handleSubmit,
  reset,
  updateChapter,
}) {
  const [savebarState, setSavebarState] = useState({
    open:        false,
    message:     '',
    hideButtons: false,
  });

  useEffect(() => {
    if (dirty) {
      setSavebarState({
        open:        true,
        message:     'Save your changes?',
        hideButtons: false,
      });
    } else if (filesSettingsForm.get('submitSucceeded')) {
      setSavebarState({
        open:        true,
        message:     'Saved successfully',
        hideButtons: true,
      });
      setTimeout(() => {
        reset();
      }, 2000);
    } else if (filesSettingsForm.get('submitFailed')) {
      setSavebarState({
        open:        true,
        message:     'Save failed',
        hideButtons: true,
      });
      setTimeout(() => {
        setSavebarState(prev => ({ ...prev, open: false }));
      }, 2000);
    } else {
      setSavebarState({
        open:        false,
        message:     '',
        hideButtons: true,
      });
    }
  }, [dirty, filesSettingsForm]);

  const onSubmit = useCallback((values) => {
    const updateParams = {
      chapterId: currentChapter.getIn(['data', 'id'], 0),
      formName:  'filesSettingsForm',
    };

    const file1Enabled = currentChapter.getIn(['data', 'file1Label'], '').length > 0;
    const file2Enabled = currentChapter.getIn(['data', 'file2Label'], '').length > 0;

    const filesArray = values.get('files');

    // Handle file visibility level updates
    if (file1Enabled) {
      updateParams.file1VisibilityLevel = filesArray.getIn([0, 'visibleTo']);
    }
    if (file2Enabled) {
      const index = file1Enabled ? 1 : 0;
      updateParams.file2VisibilityLevel = filesArray.getIn([index, 'visibleTo']);
    }

    updateChapter(updateParams);
  }, [currentChapter, updateChapter]);

  return (
    <Grid container spacing={ 8 }>
      {initialValues.get('files', []).map((file, index) => {
        const uniqueKey = `file-${file.label}-${index}`;
        return (
          <Grid item xs={ 12 } key={ uniqueKey }>
            <Field name={ `files[${index}]` }
              component={ FileSettingsItem }
              file={ file } />
          </Grid>
        );
      })}

      <Savebar open={ savebarState.open }
        form={ filesSettingsForm }
        handleAccept={ handleSubmit(onSubmit) }
        handleReject={ () => reset() }
        hideButtons={ savebarState.hideButtons }
        message={ savebarState.message } />
    </Grid>
  );
};

FilesSettingsForm.propTypes = {
  currentChapter:    PropTypes.instanceOf(Map).isRequired,
  filesSettingsForm: PropTypes.instanceOf(Map).isRequired,
  dirty:             PropTypes.bool.isRequired,
  initialValues:     PropTypes.instanceOf(Object).isRequired,
  handleSubmit:      PropTypes.func.isRequired,
  reset:             PropTypes.func.isRequired,
  updateChapter:     PropTypes.func.isRequired,
};

export default FilesSettingsForm;
