/* eslint-disable no-underscore-dangle, no-param-reassign */
import { Map } from 'immutable';
import root from '../package.json';

const { version } = root;
import React from 'react';
import ReactDOM from 'react-dom';

// bugsnag
import createPlugin from 'bugsnag-react';
import bugsnag from 'bugsnag-js';

// redux / saga
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import reducers from './reducers';
import rootSaga from './sagas';

// router
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';

// components
import App from './components/App';

// theme
import theme from './assets/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

// create router history
const history = createBrowserHistory();

// create middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
];

// compose enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create store
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

// run the root saga
sagaMiddleware.run(rootSaga);

// Set up bugsnag
let ErrorBoundary;
let bugsnagClient;

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  bugsnagClient = bugsnag({
    apiKey:     process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: version,

    beforeSend(report) {
      const applicationState = store.getState() || Map();

      const user = applicationState.getIn(['currentUser', 'data'], false);
      const chapter = applicationState.getIn(['currentChapter', 'data'], false);

      if (user) {
        report.user = {
          id:        user.get('id'),
          firstname: user.get('firstname'),
          lastname:  user.get('lastname'),
          email:     user.get('email'),
        };
      }

      if (chapter) {
        report.updateMetaData('chapter', chapter.toJS());
      }

      if (process.env.REACT_APP_RELEASE_STAGE) {
        report.app.releaseStage = process.env.REACT_APP_RELEASE_STAGE;
      }

      report.updateMetaData('application state', applicationState.toJS());
    },
  });

  ErrorBoundary = bugsnagClient.use(createPlugin(React));
}

// render the app
if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  ReactDOM.render(
    <Provider store={ store }>
      <ConnectedRouter history={ history }>
        <MuiThemeProvider theme={ theme }>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <Provider store={ store }>
      <ConnectedRouter history={ history }>
        <MuiThemeProvider theme={ theme }>
          <App />
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
}

// Not sure if we should use the service worker yet...
// registerServiceWorker();
