import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove } from '../../sources/api/client';
import inviteService from '../../sources/api/inviteService';

// constants
import { INVITE_DELETE, INVITE_DELETE_INIT } from '../../constants/inviteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { inviteSchema } from '../../schemas/invite';

export function* inviteDelete(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(INVITE_DELETE, call(remove, inviteService, id, options), {
      schema: inviteSchema,
    });
  } catch (err) {
    yield put(error(INVITE_DELETE, err));
  } finally {
    yield put(doneIndicator(INVITE_DELETE));
  }
}

export function* watchInviteDelete() {
  yield takeEvery(INVITE_DELETE_INIT, inviteDelete);
}

export default function* root() {
  yield all([
    fork(watchInviteDelete),
  ]);
}
