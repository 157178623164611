import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { bumpGroupListSchema } from '../../schemas/bumpGroup';

export default function getBumpGroups(state) {
  const bumpGroup = state.get('bumpGroup');
  const user = state.get('user');
  const result = bumpGroup.getIn(['data', 'result'], List());
  const bumpGroupsData = bumpGroup.getIn(['data', 'items'], Map());
  const users = user.getIn(['data', 'items'], Map());

  const entities = {
    bumpGroup: bumpGroupsData.toJS(),
    user:      users.toJS(),
  };

  const items = denormalize(result.toJS(), bumpGroupListSchema, entities) || [];
  return Immutable.fromJS(items).sortBy(bg => bg.get('order')) || List();
}
