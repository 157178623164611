import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';

// components
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// local components
import Tag from '../../../Tag';

class Filter extends Component {
  static propTypes = {
    classes:        PropTypes.instanceOf(Object).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    organization:   PropTypes.instanceOf(Map).isRequired,
    filter:         PropTypes.instanceOf(Object).isRequired,
    hometowns:      PropTypes.instanceOf(List).isRequired,
    rounds:         PropTypes.instanceOf(List).isRequired,
    tags:           PropTypes.instanceOf(List).isRequired,
    itemsToDisplay: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onSortChange:   PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    let councilId = 'leg_tech_pnm_id';
    if (props.currentChapter.getIn(['data', 'isCdSite'])) {
      councilId = 'group_pnm_id';
    }

    this.state = {
      data: Immutable.fromJS({
        anchorEl:             null,
        expandEvents:         false,
        expandHometowns:      false,
        expandStatuses:       false,
        expandTags:           false,
        filter:               {},
        filterByCurrentEvent: null,
        filterByEvent:        null,
        filterByHometown:     null,
        filterByMatchedPnms:  null,
        filterByNotVotedOn:   null,
        filterByStatuses:     [],
        filterByTag:          null,
        filterByVotedOn:      null,
        order:                1,
        sortSelectedIndex:    1,

        sortOptions: [
          {
            label: 'First Name',
            value: ['firstname', 'lastname', councilId],
          },
          {
            label: 'Last Name',
            value: ['lastname', 'firstname', councilId],
          },
          {
            label: 'Status',
            value: ['status', 'lastname', 'firstname', councilId],
          },
          {
            label: 'PNM ID',
            value: [councilId, 'lastname', 'firstname'],
          },
        ],
      }),
    };
  }

  getStatuses = () => Immutable.fromJS([
    {
      id:    'R',
      label: 'Recruited (R)',
    },
    {
      id:    'A',
      label: 'Active (A)',
    },
    {
      id:    'NS',
      label: 'No Show (NS)',
    },
    {
      id:    'WD',
      label: 'Withdrawn (WD)',
    },
    {
      id:    'RG',
      label: 'Regret (RG)',
    },
    {
      id:    'RL',
      label: 'Released (RL)',
    },
    {
      id:    'NA',
      label: 'Not Applicable (NA)',
    },
  ])

  handleButtonClick = (event) => {
    this.setState({ data: this.state.data.set('anchorEl', event.currentTarget) });
  };

  handleMenuClose = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('anchorEl', null);
        map.set('expandHometowns', false);
      }),
    });
  }

  handleSortChange = (event, nextIndex) => {
    const { onSortChange } = this.props;
    const currentIndex = this.state.data.get('sortSelectedIndex');
    const currentOrder = this.state.data.get('order');
    const selection = this.state.data.getIn(['sortOptions', nextIndex, 'value']);

    let newOrder;

    this.setState({
      data: this.state.data.withMutations((map) => {
        if (currentIndex === nextIndex) {
          newOrder = currentOrder * -1;
        } else {
          newOrder = 1;

          map.set('sortSelectedIndex', nextIndex);
        }

        map.set('order', newOrder);
      }),
    });

    onSortChange(selection, newOrder);
  }

  handleExpandHometowns = () => {
    this.setState({ data: this.state.data.set('expandHometowns', !this.state.data.get('expandHometowns')) });
  }

  handleExpandEvents = () => {
    this.setState({ data: this.state.data.set('expandEvents', !this.state.data.get('expandEvents')) });
  }

  handleExpandTags = () => {
    this.setState({ data: this.state.data.set('expandTags', !this.state.data.get('expandTags')) });
  }

  handleExpandStatuses = () => {
    this.setState({ data: this.state.data.set('expandStatuses', !this.state.data.get('expandStatuses')) });
  }

  handleHometownChange = (hometown) => {
    const { filter, onFilterChange } = this.props;
    const filterByHometown = this.state.data.get('filterByHometown');

    const newFilter = filter;

    if (filterByHometown === hometown) {
      this.setState({ data: this.state.data.set('filterByHometown', null) });

      delete newFilter.hometown;
    } else {
      this.setState({ data: this.state.data.set('filterByHometown', hometown) });

      newFilter.hometown = hometown;
    }

    onFilterChange(newFilter);
  }

  handleEventChange = (eventNumber) => {
    const { filter, onFilterChange, currentChapter } = this.props;
    const filterByEvent = this.state.data.get('filterByEvent');

    const newFilter = filter;

    if (filterByEvent === eventNumber) {
      this.setState({ data: this.state.data.set('filterByEvent', null) });

      delete newFilter.event;
    } else {
      this.setState({ data: this.state.data.set('filterByEvent', eventNumber) });

      newFilter.event = eventNumber;
      newFilter.round = currentChapter.getIn(['data', 'currentRound']);
    }

    onFilterChange(newFilter);
  }

  handleTagChange = (tag) => {
    const { filter, onFilterChange } = this.props;
    const filterByTag = this.state.data.get('filterByTag');

    const newFilter = filter;

    if (filterByTag === tag) {
      this.setState({ data: this.state.data.set('filterByTag', null) });

      delete newFilter.tag;
    } else {
      this.setState({ data: this.state.data.set('filterByTag', tag) });

      newFilter.tag = tag;
    }

    onFilterChange(newFilter);
  }

  handleStatusChange = (status) => {
    const { filter, onFilterChange } = this.props;
    const filterByStatuses = this.state.data.get('filterByStatuses');

    const newFilter = filter;

    if (filterByStatuses.includes(status)) {
      const statusIndex = filterByStatuses.findIndex(filteredStatus => status === filteredStatus);
      const newFilterByStatuses = filterByStatuses.splice(statusIndex, 1);

      this.setState({ data: this.state.data.set('filterByStatuses', newFilterByStatuses) });

      if (!newFilterByStatuses.size) {
        delete newFilter.status;
      } else {
        newFilter.status.splice(statusIndex, 1);
      }
    } else {
      const newFilterByStatuses = filterByStatuses.push(status);
      this.setState({ data: this.state.data.set('filterByStatuses', newFilterByStatuses) });

      newFilter.status = newFilterByStatuses.toJS();
    }

    onFilterChange(newFilter);
  }

  handleFilterByCurrentEvent = () => {
    const { filter, onFilterChange } = this.props;

    const filterByCurrentEvent = this.state.data.get('filterByCurrentEvent');
    const newFilter = filter;

    if (filterByCurrentEvent) {
      this.setState({ data: this.state.data.set('filterByCurrentEvent', null) });
      delete newFilter.currentEvent;
    } else {
      this.setState({ data: this.state.data.set('filterByCurrentEvent', true) });
      newFilter.currentEvent = true;
    }

    onFilterChange(newFilter);
  }

  handleMatchedPnmsFilter = () => {
    const { filter, onFilterChange } = this.props;

    const newFilter = filter;
    const filterByMatchedPnms = this.state.data.get('filterByMatchedPnms');

    if (filterByMatchedPnms) {
      this.setState({ data: this.state.data.set('filterByMatchedPnms', null) });
      delete newFilter.matchedPnms;
    } else {
      this.setState({ data: this.state.data.set('filterByMatchedPnms', true) });
      newFilter.matchedPnms = true;
    }

    onFilterChange(newFilter);
  }

  handleVotedOnChange = () => {
    const { filter, onFilterChange } = this.props;

    const newFilter = filter;
    const filterByVotedOn = this.state.data.get('filterByVotedOn');
    const filterByNotVotedOn = this.state.data.get('filterByNotVotedOn');

    if (filterByVotedOn) {
      if (filterByNotVotedOn) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterByVotedOn', null);
            map.set('filterByNotVotedOn', null);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByVotedOn', null) });
      }

      delete newFilter.notVotedOn;
      delete newFilter.votedOn;
    } else {
      if (filterByNotVotedOn) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterByVotedOn', true);
            map.set('filterByNotVotedOn', null);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByVotedOn', true) });
      }

      delete newFilter.notVotedOn;
      newFilter.votedOn = true;
    }

    onFilterChange(newFilter);
  }

  handleNotVotedOnChange = () => {
    const { filter, onFilterChange } = this.props;

    const newFilter = filter;
    const filterByNotVotedOn = this.state.data.get('filterByNotVotedOn');
    const filterByVotedOn = this.state.data.get('filterByVotedOn');

    if (filterByNotVotedOn) {
      if (filterByVotedOn) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterVotedOn', null);
            map.set('filterByNotVotedOn', null);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByNotVotedOn', null) });
      }

      delete newFilter.notVotedOn;
      delete newFilter.votedOn;
    } else {
      this.setState({ data: this.state.data.set('filterByNotVotedOn', true) });

      if (filterByVotedOn) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterByVotedOn', null);
            map.set('filterByNotVotedOn', true);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByNotVotedOn', true) });
      }

      newFilter.notVotedOn = true;
      delete newFilter.votedOn;
    }

    onFilterChange(newFilter);
  }

  handleHasConcernsChange = () => {
    const { filter, onFilterChange } = this.props;

    const newFilter = filter;
    const filterByHasConcerns = this.state.data.get('filterByHasConcerns');
    const filterByHasNotConcerns = this.state.data.get('filterByHasNotConcerns');

    if (filterByHasConcerns) {
      if (filterByHasNotConcerns) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterByHasConcerns', null);
            map.set('filterByHasNotConcerns', null);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByHasConcerns', null) });
      }

      delete newFilter.hasNotConcerns;
      delete newFilter.hasConcerns;
    } else {
      if (filterByHasNotConcerns) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterByHasConcerns', true);
            map.set('filterByHasNotConcerns', null);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByHasConcerns', true) });
      }

      delete newFilter.hasNotConcerns;
      newFilter.hasConcerns = true;
    }

    onFilterChange(newFilter);
  }

  handleHasNotConcernsChange = () => {
    const { filter, onFilterChange } = this.props;

    const newFilter = filter;
    const filterByHasNotConcerns = this.state.data.get('filterByHasNotConcerns');
    const filterByHasConcerns = this.state.data.get('filterByHasConcerns');

    if (filterByHasNotConcerns) {
      if (filterByHasConcerns) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterByHasConcerns', null);
            map.set('filterByHasNotConcerns', null);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByHasNotConcerns', null) });
      }

      delete newFilter.hasNotConcerns;
      delete newFilter.hasConcerns;
    } else {
      this.setState({ data: this.state.data.set('filterByHasNotConcerns', true) });

      if (filterByHasConcerns) {
        this.setState({
          data: this.state.data.withMutations((map) => {
            map.set('filterByHasConcerns', null);
            map.set('filterByHasNotConcerns', true);
          }),
        });
      } else {
        this.setState({ data: this.state.data.set('filterByHasNotConcerns', true) });
      }

      newFilter.hasNotConcerns = true;
      delete newFilter.hasConcerns;
    }

    onFilterChange(newFilter);
  }

  renderArrowIcon = (index) => {
    const { classes } = this.props;

    let element;
    let icon = 'keyboard_arrow_up';

    if (this.state.data.get('sortSelectedIndex') === index) {
      if (this.state.data.get('order') === -1) {
        icon = 'keyboard_arrow_down';
      }

      element = <Icon className={ classes.arrowIcon }>{ icon }</Icon>;
    }

    return element;
  }

  render() {
    const {
      classes, filter, currentUser, currentChapter,
      itemsToDisplay, rounds, tags, hometowns, organization,
    } = this.props;

    const anchorEl = this.state.data.get('anchorEl');
    const sortOptions = this.state.data.get('sortOptions');
    const sortSelectedIndex = this.state.data.get('sortSelectedIndex');

    const statuses = this.getStatuses();
    let currentRound = currentChapter.getIn(['data', 'currentRound'], '');
    if (currentRound) currentRound = currentRound.toString();

    const numEvents = rounds.find(round =>
      round.get('_id', '').toString() === currentRound,
    {}, // context
    Map()).get('events');

    const eventsArray = Array(numEvents).fill();

    const { status = [] } = filter || {};

    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const clearanceLevel = currentUser.get(['data', 'clearance_level'], 0);

    return (
      <div>
        <IconButton onClick={ this.handleButtonClick }
          color='inherit'
          disabled={ itemsToDisplay !== 'pnms' }
          aria-owns={ anchorEl ? 'pnm-filter-menu' : null }
          aria-haspopup='true'>

          <Icon color='inherit'>filter_list</Icon>
        </IconButton>

        <Menu id='pnm-filter-menu'
          classes={ {
            paper: classes.menu,
          } }
          anchorEl={ anchorEl }
          anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
          getContentAnchorEl={ null }
          open={ Boolean(anchorEl) }
          onClose={ this.handleMenuClose }>

          <ListSubheader className={ classes.sectionHeader }
            disableSticky>
            Sort by:
          </ListSubheader>
          {
            sortOptions.map((option, index) => (
              <MenuItem className={ classes.menuItem }
                key={ option.get('value') }
                selected={ index === sortSelectedIndex }
                onClick={ event => this.handleSortChange(event, index) }>
                { option.get('label') }

                { this.renderArrowIcon(index) }
              </MenuItem>
            ))
          }

          <ListSubheader className={ classes.sectionHeader }
            disableSticky>
            Filter by:
          </ListSubheader>

          { (teamLevel !== 0 || clearanceLevel > 0) && (
            <MenuItem className={ classes.menuItem }
              selected={ Boolean(filter.currentEvent) }
              onClick={ this.handleFilterByCurrentEvent }>

              Current Event
            </MenuItem>
          ) }

          <MenuItem className={ classes.menuItem }
            selected={ Boolean(filter.event) }
            onClick={ this.handleExpandEvents }>

            Events
            <Icon className={ classes.arrowIcon }>{ this.state.data.get('expandEvents') ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }</Icon>
          </MenuItem>
          <Collapse in={ this.state.data.get('expandEvents') }>
            {
              eventsArray.map((event, index) => (
                <MenuItem key={ index + 1 } // eslint-disable-line
                  className={ classes.nestedMenuItem }
                  selected={ filter.event === index + 1 }
                  onClick={ () => this.handleEventChange(index + 1) }>
                  Event
                  {' '}
                  { index + 1 }
                </MenuItem>
              ))
            }
          </Collapse>

          <MenuItem className={ classes.menuItem }
            selected={ Boolean(filter.hometown) }
            onClick={ this.handleExpandHometowns }>

            Hometowns
            <Icon className={ classes.arrowIcon }>{ this.state.data.get('expandHometowns') ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }</Icon>
          </MenuItem>
          <Collapse in={ this.state.data.get('expandHometowns') }>
            {
              hometowns.map(hometown => (
                <MenuItem key={ hometown.get('id') }
                  className={ classes.nestedMenuItem }
                  selected={ filter.hometown === hometown.get('id') }
                  onClick={ () => this.handleHometownChange(hometown.get('id')) }>
                  { hometown.get('name') }
                </MenuItem>
              ))
            }
          </Collapse>

          <MenuItem className={ classes.menuItem }
            selected={ Boolean(filter.matchedPnms) }
            onClick={ this.handleMatchedPnmsFilter }>

            Matched PNMs
          </MenuItem>

          { (teamLevel > 0 || clearanceLevel > 0) && [
            <MenuItem className={ classes.menuItem }
              selected={ Boolean(status.length) }
              key='statusItem'
              onClick={ this.handleExpandStatuses }>

              Statuses
              <Icon className={ classes.arrowIcon }>{ this.state.data.get('expandStatuses') ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }</Icon>
            </MenuItem>,
            <Collapse in={ this.state.data.get('expandStatuses') } key='statusContent'>
              {
              statuses.map(s => (
                <MenuItem key={ s.get('id') }
                  className={ classes.nestedMenuItem }
                  selected={ status.includes(s.get('id')) }
                  onClick={ () => this.handleStatusChange(s.get('id')) }>
                  { s.get('label') }
                </MenuItem>
              ))
            }
            </Collapse>,
          ]}

          <MenuItem className={ classes.menuItem }
            selected={ Boolean(filter.votedOn) }
            onClick={ () => this.handleVotedOnChange() }>
            Voted On
          </MenuItem>

          <MenuItem className={ classes.menuItem }
            selected={ Boolean(filter.notVotedOn) }
            onClick={ () => this.handleNotVotedOnChange() }>
            Not Voted On
          </MenuItem>
          {organization.get('membershipConcernsFeatureEnabled', false) && [
            <MenuItem className={ classes.menuItem }
              selected={ Boolean(filter.hasConcerns) }
              onClick={ this.handleHasConcernsChange }>
              Has Concerns
            </MenuItem>,
            <MenuItem className={ classes.menuItem }
              selected={ Boolean(filter.hasNotConcerns) }
              onClick={ this.handleHasNotConcernsChange }>
              Has No Concerns
            </MenuItem>,
          ]}
          <MenuItem className={ classes.menuItem }
            selected={ Boolean(filter.tag) }
            onClick={ this.handleExpandTags }>
            Tags
            <Icon className={ classes.arrowIcon }>{ this.state.data.get('expandTags') ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }</Icon>
          </MenuItem>
          <Collapse in={ this.state.data.get('expandTags') }>
            {
              tags.map(tag => (
                <MenuItem key={ tag.get('_id') }
                  className={ classes.nestedMenuItem }
                  selected={ filter.tag === tag.get('_id') }
                  onClick={ () => this.handleTagChange(tag.get('_id')) }>
                  <Grid container>
                    <Grid item xs={ 2 }>
                      <Tag variant='mini'
                        title={ tag.get('title') }
                        color={ tag.get('color') } />
                    </Grid>
                    <Grid item xs={ 10 }>
                      { tag.get('title') }
                    </Grid>
                  </Grid>
                </MenuItem>
              ))
            }
          </Collapse>
        </Menu>
      </div>
    );
  }
}

const style = () => ({
  menu: {
    maxHeight: 500,
  },

  arrowIcon: {
    position: 'absolute',
    right:    5,
  },

  menuItem: {
    paddingRight: '35px',
  },

  nestedMenuItem: {
    paddingLeft: 25,
  },

  sectionHeader: {
    '&:focus': {
      outline: 'none',
    },
  },
});

export default withStyles(style)(Filter);
