import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import pluralize from 'pluralize';

// mui components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// local components
import Autosuggest from '../../../../../Autosuggest';
import Button from '../../../../../Button';
import Tag from '../../../../../Tag';
import SelectInput from '../../../../../SelectInput';

class MatchList extends Component {
  static propTypes = {
    classes:                   PropTypes.instanceOf(Object).isRequired,
    bulkCreateMatches:         PropTypes.func.isRequired,
    clearMatches:              PropTypes.func.isRequired,
    createMatch:               PropTypes.func.isRequired,
    currentChapterId:          PropTypes.number.isRequired,
    deleteMatch:               PropTypes.func.isRequired,
    fetchBumpGroupsForChapter: PropTypes.func.isRequired,
    fetchMatchesForUser:       PropTypes.func.isRequired, // eslint-disable-line
    fetchPnms:                 PropTypes.func.isRequired,
    formValues:                PropTypes.instanceOf(Map).isRequired,
    match:                     PropTypes.instanceOf(Object).isRequired, // eslint-disable-line
    matchLoading:              PropTypes.bool.isRequired,
    matches:                   PropTypes.instanceOf(Immutable.List).isRequired,
    pathname:                  PropTypes.string.isRequired, // eslint-disable-line
    pnmLoading:                PropTypes.bool.isRequired,
    pnmSuggestions:            PropTypes.instanceOf(Array).isRequired,
    rounds:                    PropTypes.instanceOf(Immutable.List).isRequired,
    selectedItem:              PropTypes.instanceOf(Map).isRequired,
  }

  componentWillReceiveProps(nextProps) {
    const {
      clearMatches,
      selectedItem,
      formValues,
      fetchBumpGroupsForChapter,
    } = this.props;

    const nextSelectedItem = nextProps.selectedItem;

    const selectedRoundId = formValues.get('round');
    const nextSelectedRoundId = nextProps.formValues.get('round');

    if ((selectedItem.get('id') !== nextSelectedItem.get('id')
        || selectedItem.get('_id') !== nextSelectedItem.get('_id')
        || selectedRoundId !== nextSelectedRoundId)
        && (nextSelectedItem.get('id') || nextSelectedItem.get('_id'))) {
      clearMatches();
      this.initMatches(nextProps);
    }

    if (this.props.pathname.includes('bumpGroup') && nextProps.pathname.includes('bumpgroup')
        && selectedRoundId !== nextSelectedRoundId) {
      fetchBumpGroupsForChapter({
        chapter:            nextProps.currentChapterId,
        round:              nextSelectedRoundId,
        shouldPopulateUser: true,
      });
    }
  }

  getMatchRecsCount = (matchesForPnm, matchRecPnmIds) => {
    const pnmId = matchesForPnm.first().getIn(['pnm', 'id']);

    if (matchRecPnmIds.includes(pnmId)) {
      return matchesForPnm.filter(m => !m.get('round')).size;
    }
    return 0;
  }

  getRoundOptions = () => {
    const { rounds } = this.props;

    const options = [];

    rounds.forEach((round) => {
      options.push({
        label: round.get('name'),
        value: round.get('_id'),
      });
    });

    return options;
  }

  initMatches = (props) => {
    const {
      pathname,
      formValues,
      currentChapterId,
      fetchMatchesForUser,
      match: { params: { id } },
      selectedItem,
    } = props;

    const selectedRoundId = formValues.get('round', '').toString();

    let ids = id;
    if (pathname.includes('bumpgroup')) {
      ids = selectedItem.get('users', Immutable.List()).map(u => u.get('id')).toJS();
    }

    if (ids) {
      fetchMatchesForUser({
        userId:    ids,
        round:     selectedRoundId,
        chapterId: currentChapterId,
      });
    }
  }

  handleUpdateSuggestions = ({ value }) => {
    const { currentChapterId, fetchPnms, formValues } = this.props;
    const selectedRoundId = formValues.get('round');

    const fetchParams = {
      search:             value,
      sort:               {},
      chapter:            currentChapterId,
      round:              selectedRoundId,
      activeNotRecruited: true,
      filter:             {
        unmatchedPnms: true,
      },
    };

    fetchPnms(fetchParams);
  }

  handleClearSuggestions = () => { }

  handleCreateMatch = (pnmId) => {
    const {
      bulkCreateMatches,
      createMatch,
      selectedItem,
      currentChapterId,
      formValues,
      matches,
      pathname,
    } = this.props;

    const selectedRoundId = formValues.get('round', '').toString();

    if (pathname.includes('user')) {
      const params = {
        chapter: currentChapterId,
        pnm:     pnmId,
        round:   selectedRoundId,
        user:    selectedItem.get('id'),
        type:    'user',
      };

      createMatch(params);
    } else {
      const values = [];

      selectedItem.get('users').forEach((u) => {
        // prevents an existing match for the round from being created
        const matchedThisRound = matches.find(m =>
          m.get('user') === u.get('id')
          && m.getIn(['pnm', 'id']) === pnmId
          && m.get('round') === selectedRoundId);

        if (!matchedThisRound) {
          values.push({
            chapter: currentChapterId,
            pnm:     pnmId,
            round:   selectedRoundId,
            user:    u.get('id'),
            type:    'bumpGroup',
          });
        }
      });

      if (values) {
        bulkCreateMatches({ formName: 'matchListForm', values });
      }
    }
  }

  handleDeleteMatch = (pnmMatches) => {
    const {
      deleteMatch,
      currentChapterId,
      pathname,
      selectedItem,
      match: { params: { id } },
    } = this.props;

    let params;

    if (pathname.includes('user')) {
      const itemToDelete = pnmMatches.find(m => m.get('round'));

      params = {
        chapter: currentChapterId,
        id:      itemToDelete.get('_id'),
        ids:     [],
        userIds: id,
      };
    } else {
      const userIds = selectedItem.get('users', Immutable.List()).map(u => u.get('id')).toJS();
      const matchesToDelete = pnmMatches.filter(m => m.get('round'));

      params = {
        chapter: currentChapterId,
        id:      null,
        ids:     [],
        userIds,
      };

      matchesToDelete.forEach((m) => {
        params.ids.push(
          m.get('_id'),
        );
      });
    }

    deleteMatch(params);
  }

  renderListItems = (matchesForPnm) => {
    const {
      classes,
      formValues,
      matches,
      pathname,
      selectedItem,
    } = this.props;

    let officialMatchPnmIds = Immutable.List();
    let matchRecPnmIds = Immutable.List();

    const selectedRoundId = formValues.get('round');

    matches.forEach((m) => {
      if (m.get('round') === selectedRoundId) {
        officialMatchPnmIds = officialMatchPnmIds.push(m.getIn(['pnm', 'id']));
      } else if (!m.get('round')) {
        matchRecPnmIds = matchRecPnmIds.push(m.getIn(['pnm', 'id']));
      }
    });

    let pnm = matchesForPnm.first().get('pnm', Map());
    if (!Map.isMap(pnm)) pnm = Map();

    const pnmFirstName = pnm.get('firstname');
    const pnmLastName = pnm.get('lastname');
    const pnmId = pnm.get('id');
    const matchesForRound = pnm.get('matchesForRound', 0);

    const overallScore = matchesForPnm.first().getIn(['pnm', 'overall', 'value'], 0);
    const matchRecsCount = this.getMatchRecsCount(matchesForPnm, matchRecPnmIds);
    const matchStrength = (matchesForPnm.find(m => m.get('matchStrength')) || Map()).get('matchStrength');
    const event = matchesForPnm.first().getIn(['pnm', 'eventForRound']) > 0
      ? matchesForPnm.first().getIn(['pnm', 'eventForRound'])
      : 'Not Assigned';

    const matchedName = pathname.includes('user')
      ? selectedItem.get('firstname')
      : 'Group';

    const finalizedDisabled = !selectedRoundId;

    return (
      <Grid item xs={ 12 } className={ classes.matchItem } key={ matchesForPnm.first().get('_id') }>
        <Grid container spacing={ 16 }>
          <Grid item xs={ 1 }>
            { this.renderAvatar(pnm) }
          </Grid>

          <Grid item xs={ 7 }>
            <Grid container spacing={ 16 }>
              <Grid item xs={ 12 }>
                <Typography variant='subtitle1'>
                  { `${pnmFirstName} ${pnmLastName}` }
                </Typography>
              </Grid>

              <Grid item xs={ 12 } className={ classes.eventText }>
                <Typography align='left' color='textSecondary'>
                  Event
                  {' '}
                  {event}
                </Typography>
              </Grid>

              <Grid item xs={ 1 }>
                <Icon className={ classes.lightgrayIcon }>extension</Icon>
              </Grid>
              <Grid item xs={ 5 }>
                <Typography color='textSecondary'>
                  { pluralize('Match Rec', matchRecsCount, true) }
                  {' '}
                  To
                  { matchedName }
                </Typography>
              </Grid>

              <Grid item xs={ 1 }>
                <Icon className={ classes.lightgrayIcon }>thumbs_up_down</Icon>
              </Grid>
              <Grid item xs={ 5 }>
                <Typography color='textSecondary'>
                  { overallScore }
                  {' '}
                  Overall Score
                </Typography>
              </Grid>

              { matchStrength && [
                <Grid item xs={ 1 } key='icon'>
                  <Icon className={ classes.lightgrayIcon }>fitness_center</Icon>
                </Grid>,
                <Grid item xs={ 5 } key='label'>
                  <Typography color='textSecondary'>
                    { matchStrength }
                    {' '}
                    Match Strength
                  </Typography>
                </Grid>,
              ]}
            </Grid>
          </Grid>

          <Grid item xs={ 4 }>
            <Grid container spacing={ 16 }>
              <Grid item xs={ 12 }>
                { officialMatchPnmIds.includes(pnmId) ? (
                  <Button variant='contained'
                    color='secondary'
                    onClick={ () => this.handleDeleteMatch(matchesForPnm) }>
                    <Icon className={ classes.leftIcon }>close</Icon>
                    Unfinalize Match
                  </Button>
                ) : (
                  <Button variant='contained'
                    color='primary'
                    disabled={ finalizedDisabled }
                    onClick={ () => this.handleCreateMatch(pnmId) }>
                    <Icon className={ classes.leftIcon }>check</Icon>
                    Finalize Match
                  </Button>
                )}
              </Grid>
              <Grid item xs={ 12 } />
              { officialMatchPnmIds.includes(pnmId)
                || matchesForRound > 0 ? (
                  <Typography color='secondary'>
                    Matched to
                    {' '}
                    { pluralize('member', matchesForRound, true) }
                    {' '}
                    this round
                  </Typography>
                ) : (
                  <Typography color='textSecondary'>Not matched this round</Typography>
                )}
            </Grid>
          </Grid>

          <Grid item xs={ 12 }>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={ <Icon>expand_more</Icon> }>
                <Grid container spacing={ 8 }>
                  <Grid item xs={ 12 }>
                    <Typography color='primary' align='left'>
                      More Info
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={ 8 }>
                  <Grid item xs={ 12 }>
                    { this.renderMoreInfo(matchesForPnm.first()) }
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderAvatar = (data) => {
    const { classes } = this.props;

    const firstName = data.get('firstname', '');
    const lastName = data.get('lastname', '');
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

    let avatar = (
      <Avatar className={ classes.initialsAvatar }>
        { initials }
      </Avatar>

    );

    if (data.get('image')) {
      avatar = (
        <Avatar className={ classes.avatar } src={ data.get('image') } />
      );
    }

    return avatar;
  }

  renderMoreInfo = (match) => {
    const legacyLabel = match.getIn(['pnm', 'isLegacy'], false) ? 'Yes' : 'No';

    const standardFields = match.getIn(['pnm', 'standardFields'], Immutable.List());
    let hometown = standardFields.find(f => f.get('label', '').toLowerCase() === 'hometown') || Map();

    // Add hometown groups value
    if (!hometown.get('value', '').length) {
      hometown = match.getIn(['pnm', 'hometown', 'name'], 'None Found');
    } else {
      hometown = hometown.get('value', 'None Found');
    }

    const yearInCollege = standardFields.find(f =>
      f.get('label', '').toLowerCase() === 'year in college'
      || f.get('standardField', 'label', '').toLowerCase() === 'college student status') || Map();

    return (
      <List>
        <ListSubheader disableSticky>Legacy</ListSubheader>
        <ListItem>
          <Typography>{ legacyLabel }</Typography>
        </ListItem>

        <Divider />

        <ListSubheader disableSticky>Overall Votes Received</ListSubheader>
        <ListItem>
          <Typography>{ match.getIn(['pnm', 'overall', 'voteCount'], 0) }</Typography>
        </ListItem>

        <Divider />

        <ListSubheader disableSticky>Tags</ListSubheader>
        <ListItem>
          <Grid container spacing={ 16 }>
            { match.getIn(['pnm', 'tags'], Immutable.List()).size
              ? match.getIn(['pnm', 'tags'], Immutable.List()).map(tag => (
                <Grid item xs={ 2 } key={ tag.get('_id') }>
                  <Tag title={ tag.get('title') } color={ tag.get('color') } />
                </Grid>
              ))
              : <Typography>No Tags Found.</Typography>}
          </Grid>
        </ListItem>

        <Divider />

        <ListSubheader disableSticky>Hometown</ListSubheader>
        <ListItem>
          <Typography>{ hometown }</Typography>
        </ListItem>

        <Divider />

        <ListSubheader disableSticky>Year In College</ListSubheader>
        <ListItem>
          <Typography>{ yearInCollege.get('value', 'None') }</Typography>
        </ListItem>
      </List>
    );
  }

  render() {
    const {
      classes,
      formValues,
      matchLoading,
      matches,
      pathname,
      pnmLoading,
      pnmSuggestions,
    } = this.props;

    const groupedMatches = matches.groupBy(m => m.getIn(['pnm', 'id'], Immutable.Map())).toList();

    let officialMatchPnmIds = Immutable.List();
    let matchRecPnmIds = Immutable.List();

    const selectedRoundId = formValues.get('round');

    matches.forEach((m) => {
      if (m.get('round') === selectedRoundId) {
        officialMatchPnmIds = officialMatchPnmIds.push(m.getIn(['pnm', 'id']));
      } else if (!m.get('round')) {
        matchRecPnmIds = matchRecPnmIds.push(m.getIn(['pnm', 'id']));
      }
    });

    const formPnm = formValues.get('pnm', {});
    const addPnmDisabled = !formPnm.value;

    return (
      <Card className={ classes.card }>
        <CardContent>
          <Grid container spacing={ 32 } className={ classes.headerContainer } alignItems='center'>
            <Grid item xs={ 7 }>
              <Typography variant='h5' gutterBottom>Matches</Typography>
              <Typography color='textSecondary' gutterBottom>
                { pathname.includes('match')
                  ? 'Create and approve PNM match assignments for every user in this group'
                  : 'Create and approve PNM match assignments for this member'}
              </Typography>

              <Field name='round'
                options={ this.getRoundOptions() }
                component={ SelectInput }
                label='Matching For Round'
                fullWidth
                required />
            </Grid>

            <Grid item xs={ 4 }>
              <Field name='pnm'
                label='Add New Match'
                variant='outlined'
                component={ Autosuggest }
                loading={ pnmLoading }
                suggestions={ pnmSuggestions }
                onSuggestionsFetchRequested={ this.handleUpdateSuggestions }
                onSuggestionsClearRequested={ this.handleClearSuggestions } />
            </Grid>
            <Grid item xs={ 1 }>
              <Tooltip title={ addPnmDisabled ? 'Search and select a PNM to match' : 'Create match' }>
                <div>
                  <IconButton onClick={ () => this.handleCreateMatch(formPnm.value) }
                    color='primary'
                    disabled={ addPnmDisabled }>
                    <Icon>add_circle</Icon>
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>

            { matchLoading
              ? (
                <Grid item xs={ 12 } className={ classes.progressIndicator }>
                  <CircularProgress />
                </Grid>
              )
              : groupedMatches.map(matchesForPnm =>
                this.renderListItems(matchesForPnm))}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const styles = theme => ({
  card: {
    borderRadius: 10,
  },

  paddedContainer: {
    padding: 16,
  },

  lightgrayIcon: {
    color: '#bfbfbf',
  },

  initialsAvatar: {
    width:           60,
    height:          60,
    backgroundColor: theme.palette.primary.main,
  },

  avatar: {
    width:  60,
    height: 60,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  headerContainer: {
    paddingLeft: 30,
    paddingTop:  10,
  },

  progressIndicator: {
    textAlign: 'center',
  },

  matchItem: {
    borderRadius: 10,
    border:       '1px solid #bfbfbf',
    margin:       15,
  },

  eventText: {
    marginTop: -15,
  },

  bottomBorderContainer: {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
});

export default withStyles(styles)(MatchList);
