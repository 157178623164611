import { Map } from 'immutable';

// constants
import { SPECIALTY_FETCH_ALL } from '../constants/specialtyTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case SPECIALTY_FETCH_ALL: return httpReducer(state, action, { entity: 'specialty' });
    default: return state;
  }
};
