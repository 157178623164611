import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// components
import CommentList from './CommentList';

// action creators

const mapStateToProps = state => ({
  pnm:            state.get('pnm'),
  round:          state.get('round'),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
});

const mapDispatchToProps = dispatch => ({
  navigate: (route) => {
    dispatch(push(`${route}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
