import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

// components
import DefaultDrawer from '@material-ui/core/Drawer';
import PnmList from '../../../PnmList';

const Drawer = function ({ closeDrawer, drawer }) {
  return (
    <DefaultDrawer onClose={ closeDrawer } open={ drawer.get('open') } keepMounted>
      <PnmList />
    </DefaultDrawer>
  );
};

Drawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  drawer:      PropTypes.instanceOf(Map).isRequired,
};

export default Drawer;
