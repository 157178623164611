import {
  all, call, fork, put, takeEvery, select,
} from 'redux-saga/effects';
import { Map, List } from 'immutable';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { updateCategoryVoteCounts } from '../../actions/voteActions';

// api
import { patch } from '../../sources/api/client';
import voteService from '../../sources/api/voteService';

// constants
import { VOTE_DELETE, VOTE_DELETE_INIT } from '../../constants/voteTypes';

// lib
import httpSaga from '../../lib/httpSaga';
// import { getCurrentUser, getVotes } from '../../lib/selectors';

// schemas
import { voteSchema } from '../../schemas/vote';

export function* voteDelete(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    id, chapter, round, modeToClear,
  } = action.payload || {};

  try {
    const currentUser = yield select(state => state.get('currentUser'));
    const votes = yield select(state => state.getIn(['vote', 'data', 'items'], Map()));

    // Get data for the vote that we're deleting
    const voteToDelete = yield votes.find(v => v.get('_id') === id) || Map();

    const values = {
      chapter,
      round,
      removed: true,
      modeToClear,
      pnm:     voteToDelete.get('pnm'),
    };

    yield* httpSaga(VOTE_DELETE, call(patch, voteService, id, { ...values }), {
      schema: voteSchema,
    });

    // Get the current category vote counts from the current user
    const currentCategoryVoteCounts = currentUser.getIn(['data', 'categoryVotes'], Map());

    let newCategoryVoteCounts = Map();

    const voteToDeleteCategoryId = voteToDelete
      .get('categories', List())
      .first()
      .get('category', '');

    // Get the category of the vote that is being deleted
    const categoryToUpdate = yield currentCategoryVoteCounts.find((category, key) =>
      voteToDeleteCategoryId.toString() === key.toString());

    const voteToDeleteValue = voteToDelete.get('value');

    let newOptions = Map();
    const options = categoryToUpdate.get('options', Map());

    // Update the count and limitReached of the options
    options.forEach((option) => {
      let newOption = option;

      if (option.get('value') === voteToDeleteValue) {
        newOption = newOption.set('count', newOption.get('count') - 1);
      }

      if (option.get('count') >= option.get('limit')) {
        newOption = newOption.set('limitReached', false);
      }

      newOptions = newOptions.set(option.get('_id'), newOption);
    });

    newCategoryVoteCounts = yield newCategoryVoteCounts.set(voteToDeleteCategoryId, categoryToUpdate.set('options', newOptions));

    yield put(updateCategoryVoteCounts({ newCategoryVotes: newCategoryVoteCounts.toJS() }));
  } catch (err) {
    yield put(error(VOTE_DELETE, err));
  } finally {
    yield put(doneIndicator(VOTE_DELETE));
  }
}

export function* watchVoteDelete() {
  yield takeEvery(VOTE_DELETE_INIT, voteDelete);
}

export default function* root() {
  yield all([
    fork(watchVoteDelete),
  ]);
}
