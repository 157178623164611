import { REPORTING_FETCH_PNMS_TO_CONFIRM_INIT, REPORTING_CONFIRM_PNMS_INIT } from '../constants/reportingTypes';

export function fetchPnmsToConfirm(params) {
  return {
    type:    REPORTING_FETCH_PNMS_TO_CONFIRM_INIT,
    payload: params,
  };
}

export function confirmPnms(params) {
  return {
    type:    REPORTING_CONFIRM_PNMS_INIT,
    payload: params,
  };
}
