import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

// components
import DefaultButton from '@material-ui/core/Button';
import DefaultFab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import './Button.scss';

const Button = function (props) {
  const {
    classes,
    className,
    children,
    disabled,
    loading,
    color,
    variant,
    ...rest
  } = props;

  const newButtonClassNames = {};
  const newIconClassNames = {};

  newButtonClassNames[classes.contrastRaised] = (color === 'contrast') && !disabled && !loading && (variant === 'contained');
  newButtonClassNames[classes.primaryRaised] = (color === 'primary') && !disabled && !loading && (variant === 'contained');
  newButtonClassNames[classes.contrastFlat] = (color === 'contrast') && !disabled && !loading && (variant !== 'contained');
  newButtonClassNames[classes.contained] = (color === 'contrast') && !disabled && !loading && (variant === 'extendedFab');
  newButtonClassNames[classes.primaryFab] = !disabled && !loading && (variant === 'fab');
  newButtonClassNames[classes.contrastFab] = (color === 'contrast') && !disabled && !loading && (variant === 'fab');
  newIconClassNames[classes.contrastIcon] = color === 'contrast';

  if (variant === 'fab' || variant === 'extendedFab') {
    return (
      <DefaultFab className={ classNames(className, newButtonClassNames) }
        disabled={ disabled || loading }
        variant={ variant === 'fab' ? 'round' : 'extended' }
        color={ color === 'contrast' ? 'inherit' : color }
        { ...rest }>

        { children }
        { loading && <CircularProgress className={ classNames('button__activity-indicator', newIconClassNames) } size={ 24 } /> }
      </DefaultFab>
    );
  }

  return (
    <DefaultButton className={ classNames(className, newButtonClassNames) }
      disabled={ disabled || loading }
      color={ color === 'contrast' ? 'inherit' : color }
      variant={ variant }
      { ...rest }>

      { children }
      { loading && <CircularProgress className={ classNames('button__activity-indicator', newIconClassNames) } size={ 24 } /> }
    </DefaultButton>
  );
};

Button.propTypes = DefaultButton.propTypes;

const styles = theme => ({
  contrastRaised: {
    backgroundColor: theme.palette.common.white,
    color:           theme.palette.primary.main,
  },

  primaryRaised: { // creates blue drop shadow
    boxShadow: '0 9px 16px 0 rgba(66,133,244,0.5)',

    '&:active': {
      boxShadow: '0 10px 20px 0 rgba(66,133,244,0.75)',
    },
  },

  contrastFlat: {
    color: theme.palette.common.white,
  },

  primaryFab: {
    backgroundColor: theme.palette.common.main,
    color:           theme.palette.primary.white,

    boxShadow: '7px 7px 16px 0 rgba(66,133,244,0.5)',

    '&:active': {
      boxShadow: '7px 7px 16px 0 rgba(66,133,244,0.5)',
    },
  },

  contrastFab: {
    backgroundColor: theme.palette.common.white,
    color:           theme.palette.primary.main,
  },

  contained: {
    backgroundColor: theme.palette.common.white,
  },

  contrastIcon: {
    color: theme.palette.common.white,
  },
});

export default withStyles(styles)(Button);
