export const PNM_FETCH_ALL = 'PNM_FETCH_ALL';
export const PNM_FETCH_ALL_INIT = 'PNM_FETCH_ALL_INIT';
export const PNM_FETCH_ALL_DONE = 'PNM_FETCH_ALL_DONE';

export const PNM_FETCH_ALL_FOR_ANONYMOUS = 'PNM_FETCH_ALL_FOR_ANONYMOUS';
export const PNM_FETCH_ALL_FOR_ANONYMOUS_INIT = 'PNM_FETCH_ALL_FOR_ANONYMOUS_INIT';
export const PNM_FETCH_ALL_FOR_ANONYMOUS_DONE = 'PNM_FETCH_ALL_FOR_ANONYMOUS_DONE';

export const PNM_FETCH = 'PNM_FETCH';
export const PNM_FETCH_INIT = 'PNM_FETCH_INIT';
export const PNM_FETCH_DONE = 'PNM_FETCH_DONE';

export const PNM_CLEAR = 'PNM_CLEAR';

export const PNM_BULK_UPDATE = 'PNM_BULK_UPDATE';
export const PNM_BULK_UPDATE_INIT = 'PNM_BULK_UPDATE_INIT';
export const PNM_BULK_UPDATE_DONE = 'PNM_BULK_UPDATE_DONE';

export const PNM_UPDATE_ALL_FOR_CHAPTER = 'PNM_UPDATE_ALL_FOR_CHAPTER';
export const PNM_UPDATE_ALL_FOR_CHAPTER_INIT = 'PNM_UPDATE_ALL_FOR_CHAPTER_INIT';
export const PNM_UPDATE_ALL_FOR_CHAPTER_DONE = 'PNM_UPDATE_ALL_FOR_CHAPTER_DONE';

export const PNM_UPDATE = 'PNM_UPDATE';
export const PNM_UPDATE_INIT = 'PNM_UPDATE_INIT';
export const PNM_UPDATE_DONE = 'PNM_UPDATE_DONE';

export const PNM_CREATE = 'PNM_CREATE';
export const PNM_CREATE_INIT = 'PNM_CREATE_INIT';
export const PNM_CREATE_DONE = 'PNM_CREATE_DONE';

export const PNM_DELETE = 'PNM_DELETE';
export const PNM_DELETE_INIT = 'PNM_DELETE_INIT';
export const PNM_DELETE_DONE = 'PNM_DELETE_DONE';

export const PNM_DELETE_ALL_FOR_CHAPTER = 'PNM_DELETE_ALL_FOR_CHAPTER';
export const PNM_DELETE_ALL_FOR_CHAPTER_INIT = 'PNM_DELETE_ALL_FOR_CHAPTER_INIT';
export const PNM_DELETE_ALL_FOR_CHAPTER_DONE = 'PNM_DELETE_ALL_FOR_CHAPTER_DONE';

export const ENABLE_DISPLAY_ACTIVE_LIST = 'ENABLE_DISPLAY_ACTIVE_LIST';
export const DISABLE_DISPLAY_ACTIVE_LIST = 'DISABLE_DISPLAY_ACTIVE_LIST';
