import classNames from 'classnames';
import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';

// local components
import Toolbar from './components/Toolbar';
import ListItem from './components/ListItem';
import SelectAllListItem from './SelectAllListItem';
import InfiniteScroll from '../InfiniteScroll';
import BulkActions from './components/BulkActions';

// selectors
import getUserRole from '../../lib/selectors/getUserRole';

// styles
import './PnmList.scss';

class PnmList extends Component {
  static propTypes = {
    bumpGroup:                 PropTypes.instanceOf(Map).isRequired,
    bumpGroupLoading:          PropTypes.bool.isRequired,
    bumpGroups:                PropTypes.instanceOf(Immutable.List).isRequired,
    classes:                   PropTypes.instanceOf(Object).isRequired,
    clearanceLevel:            PropTypes.number.isRequired,
    currentChapter:            PropTypes.instanceOf(Map).isRequired,
    currentChapterId:          PropTypes.number.isRequired,
    currentChapterLoading:     PropTypes.bool.isRequired,
    currentUser:               PropTypes.instanceOf(Map).isRequired,
    fetchBumpGroupsForChapter: PropTypes.func.isRequired,
    fetchPnms:                 PropTypes.func.isRequired,
    fetchUsersForChapter:      PropTypes.func.isRequired,
    navigateToListTab:         PropTypes.func.isRequired,
    organization:              PropTypes.instanceOf(Map).isRequired,
    pnm:                       PropTypes.instanceOf(Map).isRequired,
    pathname:                  PropTypes.string.isRequired,
    pnmLoading:                PropTypes.bool.isRequired,
    pnms:                      PropTypes.instanceOf(Immutable.List).isRequired,
    round:                     PropTypes.instanceOf(Map).isRequired,
    user:                      PropTypes.instanceOf(Map).isRequired,
    userLoading:               PropTypes.bool.isRequired,
    users:                     PropTypes.instanceOf(Immutable.List).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        search:           null,
        sortBy:           ['lastname', 'firstname'],
        filter:           null,
        order:            1,
        formWasReset:     true,
        selectedPnms:     [],
        shouldResetList:  false,
        selectAllChecked: false,
      }),
    };
  }

  componentWillMount() {
    const {
      currentChapter,
      currentChapterId,
      currentUser,
      fetchBumpGroupsForChapter,
      pathname,
    } = this.props;

    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen']);
    const displayPnmsOnVotingClosed = currentChapter.getIn(['data', 'displayPnmsOnVotingClosed']);
    const teamLevel = currentChapter.getIn(['data', 'team_level']);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level']);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';

    const shouldFetchPnms = isVotingOpen || isAdmin || isConcernChair || displayPnmsOnVotingClosed;
    const shouldFetchUsers = pathname.includes('match/user') && isAdmin;
    const shouldFetchBumpGroups = pathname.includes('match/bumpgroup') && isAdmin;

    if (currentChapter.get('data')) {
      if (shouldFetchUsers) {
        this.initUsers(currentChapterId);
      } else if (shouldFetchBumpGroups) {
        fetchBumpGroupsForChapter({
          chapter:            currentChapterId,
          shouldPopulateUser: true,
        });
      } else if (shouldFetchPnms) {
        this.initPNMs(this.props);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentChapter,
      currentUser,
      fetchBumpGroupsForChapter,
      pathname,
    } = this.props;

    const nextCurrentChapter = nextProps.currentChapter;
    const nextCurrentUser = nextProps.currentUser;

    const chapterId = nextCurrentChapter.getIn(['data', 'id']);
    const isVotingOpen = nextCurrentChapter.getIn(['data', 'isVotingOpen']);
    const displayPnmsOnVotingClosed = nextCurrentChapter.getIn(['data', 'displayPnmsOnVotingClosed']);
    const teamLevel = nextCurrentChapter.getIn(['data', 'team_level']);
    const clearanceLevel = nextCurrentUser.getIn(['data', 'clearance_level']);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';

    const nextPathname = nextProps.pathname;

    const chapterChanged = !currentChapter.get('data') && nextCurrentChapter.get('data');

    // Handles switching items in PNM list when entering and leaving advanced matching
    const pageChanged = pathname.includes('match/bumpgroup') !== nextPathname.includes('match/bumpgroup')
      || pathname.includes('match/user') !== nextPathname.includes('match/user');

    const shouldFetchPnms = isVotingOpen || isAdmin || isConcernChair || displayPnmsOnVotingClosed;
    const shouldFetchUsers = nextPathname.includes('match/user') && isAdmin;
    const shouldFetchBumpGroups = nextPathname.includes('match/bumpgroup') && isAdmin;

    if (chapterChanged || pageChanged) {
      this.setState({ data: this.state.data.set('search', null) });

      if (shouldFetchUsers) {
        this.initUsers(chapterId);
      } else if (shouldFetchBumpGroups) {
        fetchBumpGroupsForChapter({
          chapter:            chapterId,
          shouldPopulateUser: true,
        });
      } else if (shouldFetchPnms) {
        this.initPNMs(nextProps);
      }
    }
  }

  getFetchParams = (props) => {
    const { currentChapterId } = props;

    const search = this.state.data.get('search');
    const sortBy = this.state.data.get('sortBy');
    const order =  this.state.data.get('order');
    const filter = this.state.data.get('filter');

    const fetchParams = {
      sort:    {},
      chapter: currentChapterId,
    };

    if (search) {
      fetchParams.search = search;
    }

    if (filter) {
      fetchParams.filter = filter;
    }

    // set the sort param to the current value
    sortBy.forEach((sortByKey) => {
      fetchParams.sort[sortByKey] = order;
    });

    return fetchParams;
  }

  setSelectAllChecked = (checked) => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('selectAllChecked', checked);
        map.set('selectedPnms', Immutable.List());
        map.set('shouldResetList', true);
      }),
    });
  }

  initPNMs = (props) => {
    const { fetchPnms } = props;
    const fetchParams = this.getFetchParams(props);

    fetchPnms({ ...fetchParams });
  }

  initUsers = (id) => {
    const { fetchUsersForChapter } = this.props;

    fetchUsersForChapter({
      chapter:              id,
      shouldAddMatchCounts: true,
    });
  };

  shouldRenderLoadingIndicator = () => {
    const {
      bumpGroupLoading,
      currentChapterLoading,
      pathname,
      pnmLoading,
      userLoading,
    } = this.props;

    let loading = false;

    if (currentChapterLoading) {
      loading = true;
    } else if (userLoading && pathname.includes('user')) {
      loading = true;
    } else if (bumpGroupLoading && pathname.includes('bumpgroup')) {
      loading = true;
    } else if (pnmLoading && !pathname.includes('match')) {
      loading = true;
    }

    return loading;
  }

  handleSearchChange = (value) => {
    const {
      currentUser, fetchPnms, fetchUsersForChapter, pathname,
    } = this.props;

    const fetchParams = this.getFetchParams(this.props);

    // Prevents user's email from being searched when navigating to Account page
    //  Not sure why it's happening, checking email fixes it
    if (value && value !== currentUser.getIn(['data', 'email'], '')) {
      this.setState({ data: this.state.data.set('search', value) });

      // set the search param to the current value
      fetchParams.search = value;
    } else {
      this.setState({ data: this.state.data.set('search', null) });

      // remove the search param if search is undefined
      delete fetchParams.search;
    }

    if (!pathname.includes('match')) {
      fetchPnms({ ...fetchParams });
    } else if (pathname.includes('user')) {
      fetchUsersForChapter({
        shouldAddMatchCounts: true,
        ...fetchParams,
      });
    }
  }

  handleSortChange = (sortBy, order) => {
    const { fetchPnms } = this.props;

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('sortBy', sortBy);
        map.set('order', order);
      }),
    });

    const fetchParams = this.getFetchParams(this.props);

    // clear the sort param
    fetchParams.sort = {};

    // set the sort param to the current value
    sortBy.forEach((sortByKey) => {
      fetchParams.sort[sortByKey] = order;
    });

    fetchPnms(fetchParams);
  }

  handleFilterChange = (filter) => {
    const { currentChapter, fetchPnms } = this.props;
    const currentEvent = currentChapter.getIn(['data', 'currentEvent']);

    this.setState({ data: this.state.data.set('filter', filter) });

    const fetchParams = this.getFetchParams(this.props);

    // clear the filter params;
    delete fetchParams.filter;

    // set the filter params to the current value
    if (filter) {
      fetchParams.filter = filter;
      fetchPnms({ ...fetchParams });
    } else {
      fetchPnms({ currentEvent, ...fetchParams });
    }
  }

  handleLoadData = ({ skip }) => {
    const { fetchPnms, fetchUsersForChapter, pathname } = this.props;

    const fetchParams = this.getFetchParams(this.props);

    if (!pathname.includes('match')) {
      fetchPnms({
        skip,
        infiniteScroll: true,
        ...fetchParams,
      });
    } else if (pathname.includes('user')) {
      fetchUsersForChapter({
        shouldAddMatchCounts: true,
        ...fetchParams,
      });
    }
  }

  resetSelectedPnms = (shouldResetList = true) => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('selectedPnms', Immutable.List());
        map.set('shouldResetList', shouldResetList);
      }),
    });
  }

  updateSelectedPnms = (event, pnm) => {
    const selectedPnms = this.state.data.get('selectedPnms');
    const selectAllChecked = this.state.data.get('selectAllChecked');
    const isChecked = event.target.checked;
    const pnmIndex = selectedPnms.indexOf(pnm);
    const newSelectedPnms = isChecked
      ? selectedPnms.push(pnm)
      : selectedPnms.splice(pnmIndex, 1);

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('selectedPnms', !selectAllChecked ? newSelectedPnms : Immutable.List());
        map.set('selectAllChecked', false);
        map.set('shouldResetList', selectAllChecked);
      }),
    });
  }

  reinitializeList = () => {
    this.setState({
      data: this.state.data.set('shouldResetList', false),
    });
  }

  renderBulkActions = () => {
    const selectedPnms = this.state.data.get('selectedPnms');
    const filter = this.state.data.get('filter');
    const selectAllChecked = this.state.data.get('selectAllChecked');
    const numChecked = selectedPnms.size;

    const element = (
      <BulkActions key='bulkActions'
        filter={ filter }
        numChecked={ numChecked }
        selectAllChecked={ selectAllChecked }
        setSelectAllChecked={ this.setSelectAllChecked }
        resetSelections={ this.resetSelectedPnms }
        selectedPnms={ selectedPnms } />
    );
    return element;
  }

  renderList = (className, itemsToDisplay) => {
    const {
      bumpGroupLoading,
      bumpGroup,
      classes,
      clearanceLevel,
      currentChapter,
      currentUser,
      pathname,
      pnm,
      pnmLoading,
      userLoading,
      user,
    } = this.props;

    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen']);
    const displayPnmsOnVotingClosed = currentChapter.getIn(['data', 'displayPnmsOnVotingClosed']);
    const teamLevel = currentChapter.getIn(['data', 'team_level']);

    const shouldFetchPnms = isVotingOpen || displayPnmsOnVotingClosed;
    const isAdmin = clearanceLevel > 0 || teamLevel > 0;
    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';

    let displayListState = pnm.get('displayList');

    if (displayListState === undefined) {
      displayListState = true;
    }

    const shouldNotDisplayPnms = !shouldFetchPnms && currentChapter.get('data') && !pnmLoading;
    const notMatchRoute = !pathname.includes('match') && !pathname.includes('bumpgroup');

    if (shouldNotDisplayPnms && notMatchRoute && !isAdmin && !isConcernChair) {
      return (
        <List>
          <Grid container key='closedMessage'>
            <Grid item xs={ 12 } align='center'>
              <Typography color='textSecondary'>
                PNMs are not visible when voting is closed
              </Typography>
            </Grid>
          </Grid>
        </List>
      );
    }

    let limit =  pnm.getIn(['data', 'limit']);
    let skip =  pnm.getIn(['data', 'skip']);
    let total =  pnm.getIn(['data', 'total']);
    let loading = pnmLoading;

    if (itemsToDisplay === 'users') {
      limit = user.getIn(['data', 'limit']);
      skip = user.getIn(['data', 'skip']);
      total = user.getIn(['data', 'total']);
      loading = userLoading;
    } else if (itemsToDisplay === 'bumpGroups') {
      limit = bumpGroup.getIn(['data', 'limit']);
      skip = bumpGroup.getIn(['data', 'skip']);
      total = bumpGroup.getIn(['data', 'total']);
      loading = bumpGroupLoading;
    }

    if (!displayListState) { // Hides pnms when voting modal is open
      return (<List />);
    }

    return (
      <List className={ classNames(className, classes.pnmList, classes.list) }
        component={ InfiniteScroll }
        itemHeight={ 65 }
        limit={ limit }
        skip={ skip }
        total={ total }
        loading={ loading }
        onLoadData={ this.handleLoadData }>

        { this.renderListItems(itemsToDisplay) }
        { this.shouldRenderLoadingIndicator()
          && (
            <div className={ classes.pnmListSpinner }>
              <CircularProgress />
            </div>
          )}
      </List>
    );
  }

  renderListItems = (itemsToDisplay) => {
    const {
      bumpGroups,
      currentChapter,
      currentUser,
      organization,
      pathname,
      pnms,
      round,
      users,
    } = this.props;

    const elements = [];

    const teamLevel = currentChapter.getIn(['data', 'team_level']);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level']);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const numChecked = this.state.data.get('selectedPnms').size;
    const shouldResetList = this.state.data.get('shouldResetList');
    const selectAllChecked = this.state.data.get('selectAllChecked');
    const roundCategories = organization.get('roundCategories', Immutable.List());
    const currentChapterData = currentChapter.get('data') || Map();
    const currentRound = currentChapterData.get('currentRound', '') || '';
    const currentRoundType = round.getIn(['data', 'items', currentRound.toString(), 'roundType'], '').toString();
    const currentRoundCategory = roundCategories.find(rc => rc.get('roundType', '').toString() === currentRoundType, {}, Map());
    let oneVotePerPnm = false;

    if (organization) {
      oneVotePerPnm = currentRoundCategory.get('oneVotePerPnm') || organization.get('oneVotePerPnm');
    }

    const currentPath = pathname || '';

    if (isAdmin && itemsToDisplay === 'pnms') {
      elements.push(
        <SelectAllListItem key='selectAll'
          isChecked={ selectAllChecked }
          shouldResetList={ false }
          reinitializeList={ this.reinitializeList }
          handleCheckboxChange={ this.setSelectAllChecked } />
      );
    }

    let items = Immutable.List();

    switch (itemsToDisplay) {
      case 'pnms':
        items = pnms;
        break;
      case 'users':
        items = users;
        break;
      case 'bumpGroups':
        items = bumpGroups;
        break;
      default:
        break;
    }

    items = items.filter(item => item !== undefined);

    if (!currentPath.includes('dashboard')) {
      items.forEach((item = Map()) => {
        elements.push(
          <ListItem currentChapter={ currentChapter }
            currentUser={ currentUser }
            itemsToDisplay={ itemsToDisplay }
            key={ item.get('id') || item.get('_id') || 0 }
            itemId={ item.get('id') || item.get('_id') || 0 }
            pathname={ pathname }
            numChecked={ numChecked }
            onCheckboxChange={ this.updateSelectedPnms }
            item={ item || Map() }
            reinitializeList={ this.reinitializeList }
            oneVotePerPnm={ oneVotePerPnm }
            selectAllChecked={ selectAllChecked }
            shouldResetList={ shouldResetList } />
        );
      });
    }

    return elements;
  }

  render() {
    const {
      bumpGroups,
      bumpGroupLoading,
      classes,
      pnm,
      pnmLoading,
      currentChapter,
      currentUser,
      pathname,
      navigateToListTab,
      userLoading,
    } = this.props;
    const numChecked = this.state.data.get('selectedPnms').size;
    const selectAllChecked = this.state.data.get('selectAllChecked');

    const teamLevel = currentChapter.getIn(['data', 'team_level']);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level']);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;

    const onUserMatching = pathname.includes('match/user') && isAdmin;
    const onBumpGroupMatching = pathname.includes('match/bumpgroup') && isAdmin;

    let itemsToDisplay = 'pnms';
    let loading = pnmLoading;
    if (onUserMatching) {
      itemsToDisplay = 'users';
      loading = userLoading;
    } else if (onBumpGroupMatching) {
      itemsToDisplay = 'bumpGroups';
      loading = bumpGroupLoading;
    }

    const bumpGroupId = (bumpGroups.first() || Map())
      .get('_id', 0)
      .toString();

    return (
      <div className='pnm-list-container'>
        { currentChapter.get('data') ? [
          <Toolbar key='toolbar'
            bumpGroupId={ bumpGroupId }
            currentUser={ currentUser }
            onSearchChange={ this.handleSearchChange }
            onSortChange={ this.handleSortChange }
            onFilterChange={ this.handleFilterChange }
            navigateToListTab={ navigateToListTab }
            filter={ this.state.data.get('filter') || {} }
            pnm={ pnm }
            numChecked={ numChecked }
            pathname={ pathname }
            itemsToDisplay={ itemsToDisplay }
            loading={ loading } />,

          this.renderBulkActions(),

          <Hidden key='hidden1' implementation='css' mdDown>
            { numChecked > 0
              ? this.renderList(selectAllChecked
                ? classes.pnmListBulkSidebarCompact
                : classes.pnmListBulkSidebar, itemsToDisplay)
              : this.renderList(classes.pnmListSidebar, itemsToDisplay)}
          </Hidden>,

          <Hidden key='hidden2' implementation='css' lgUp>
            { this.renderList(classes.pnmListDrawer, itemsToDisplay)}
          </Hidden>,
        ] : (
          <div className={ classes.pnmListSpinner }>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  list: {
    paddingBottom: 0,
    paddingTop:    0,
  },

  pnmList: {
    overflow: 'scroll',
  },

  pnmListSidebar: {
    height: 'calc(100vh - 140px)',
  },

  pnmListBulkSidebar: {
    height: 'calc(100vh - 380px)', // adds on the 280px of the bulk actions menu
  },

  pnmListBulkSidebarCompact: {
    height: 'calc(100vh - 200px)', // adds on the 100px of the bulk actions menu
  },

  pnmListDrawer: {
    height: 'calc(100vh - 75px)',
  },

  pnmListSpinner: {
    display:        'flex',
    justifyContent: 'center',
    padding:        '1em 0',
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  errorButton: {
    color: red[500],
  },
});

export default withStyles(styles)(PnmList);
