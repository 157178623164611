import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { voteListSchema } from '../../schemas/vote';

export default function getVotes(state) {
  const vote = state.get('vote');
  const chapter = state.get('chapter');
  const round = state.get('round');
  const user = state.get('user');

  const voteData = vote.get('data', Map());
  const result = voteData.get('result', List());

  let items = [];

  if (result.size > 0) {
    const chapters = chapter.getIn(['data', 'items'], Map());
    const rounds = round.getIn(['data', 'items'], Map());
    const votes = vote.getIn(['data', 'items'], Map())
      .map(v => v.set(
        'author',
        v.getIn(['author', 'id']) ? v.getIn(['author', 'id']) : v.get('author')
      ));

    const users = user.getIn(['data', 'items'], Map());

    const entities = {
      vote:    votes.toJS(),
      round:   rounds.toJS(),
      chapter: chapters.toJS(),
      user:    users.toJS(),
    };

    items = denormalize(result.toJS(), voteListSchema, entities);
  }

  return Immutable.fromJS(items);
}
