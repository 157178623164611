import { List, Map } from 'immutable';
import getRoundTypeName from '../../../helpers/getRoundTypeName';
import getCategoriesFromOrganization from './getCategoriesFromOrganization';

const getAllCategoriesFromListAddData = (organization) => {
  const categories = getCategoriesFromOrganization(organization);
  let newCategories = List();

  categories.forEach((item) => {
    if (Map.isMap(item)) {
      const roundType = item.get('roundType');
      const roundTypeName = getRoundTypeName(roundType, organization);
      const org = item.get('org');
      const roundCategoryId = item.get('_id');
      item.get('categories', List()).forEach((category) => {
        if (Map.isMap(category)) {
          newCategories = newCategories.push(
            category.set('roundType', roundType)
              .set('org', org)
              .set('roundCategoryId', roundCategoryId)
              .set('roundTypeName', roundTypeName)
          );
        }
      });
    }
  });

  return newCategories.sortBy(item => item.get('roundType'));
};

export default getAllCategoriesFromListAddData;
