import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';

import { getVoteScore } from '../../../../lib/formulas/voteScoreFormulas';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Divider from '@material-ui/core/Divider';

import Button from '../../../Button';

// components
import SingleBallotVoteForm from './components/SingleBallotVoteForm';
import SingleBallotMatchForm from './components/SingleBallotMatchForm';
import SingleBallotNoteForm from './components/SingleBallotNoteForm';

const SingleBallotModal = function ({
  allowVotingMembersToTag,
  categories,
  classes,
  clearanceLevel,
  createMatch,
  createNote,
  createVote,
  currentChapter,
  currentUser,
  currentVote,
  dialogOpen,
  disableNotesFeature,
  fullScreen,
  handleDialogClose,
  matchForm,
  noteForm,
  notes,
  organization,
  pnm,
  pnmId,
  rounds,
  teamLevel,
  updateVote,
  voteForm,
}) {
  const submitting = voteForm.get('submitting') || matchForm.get('submitting') || noteForm.get('submitting');
  const submitSucceeded = voteForm.get('submitSucceeded');
  const submitFailed = voteForm.get('submitFailed') || matchForm.get('submitFailed') || noteForm.get('submitSucceeded');
  const disabled = voteForm.get('syncErrors');
  const currentChapterId = currentChapter.getIn(['data', 'id']);

  useEffect(() => {
    if (submitSucceeded) {
      handleDialogClose();
    }
  }, [submitSucceeded]);

  const submitVote = () => {
    const {
      user, round, ...restOfValues
    } = voteForm.get('values', Map()).toJS();

    // We use the comment from the note form to make the vote form's comment
    const { comment } = noteForm.get('values', Map()).toJS();

    const currentRound = (currentChapter.get('data') || Map()).get('currentRound');
    const currentEvent = (currentChapter.get('data') || Map()).get('currentEvent');
    const chapter = currentChapter.getIn(['data', 'id'], 0);
    const userForVote = (user || {}).value;
    const currentVoteId = currentVote.get('_id') || 0;

    const voteValues = (restOfValues.vote === undefined)
      ? Object.keys(restOfValues).map(key => (
        { category: key, value: parseFloat(restOfValues[key]) }))
      : restOfValues.vote;

    const roundForVote = round || currentRound || 0;

    const value = getVoteScore(
      organization, Immutable.fromJS(voteValues), categories, currentChapter, roundForVote, rounds
    );

    let submittedByAdmin = 0;
    if (userForVote && currentUser.getIn(['data', 'id']) !== userForVote) {
      submittedByAdmin = currentUser.getIn(['data', 'id']);
    }

    const standardParams = {
      pnm:    pnmId,
      author: userForVote || currentUser.getIn(['data', 'id']),
      round:  roundForVote,
      chapter,
      value,
      comment,
      event:  currentEvent,
    };

    if (submittedByAdmin > 0) {
      standardParams.submittedByAdmin = submittedByAdmin;
    }

    const voteParams = { ...standardParams };

    if (Array.isArray(voteValues)) {
      voteParams.categories = voteValues;
    }

    if (!voteForm.get('syncErrors')) {
      if (currentVoteId) {
        updateVote({
          voteId:   currentVoteId,
          chapter,
          formName: 'voteForm',
          ...voteParams,
        });
      } else {
        createVote(voteParams);
      }
    }
  };

  const submitMatch = () => {
    if (!matchForm.get('syncErrors')) {
      const { user, ...values } = matchForm.get('values', Map()).toJS();

      const params = {
        skipPnmPopulate: true,
        formName:        'matchForm',
        chapter:         currentChapter.getIn(['data', 'id']),
        pnm:             pnmId,
        user:            (user || {}).value || '',
        ...values,
      };

      if (user || values.comment) {
        createMatch(params);
      }
    }
  };

  const submitNote = () => {
    const chapter = currentChapter.getIn(['data', 'id'], 0);
    const currentRound = currentChapter.getIn(['data', 'currentRound'], 0);
    const orgId = currentChapter.getIn(['data', 'organization'], 0);

    const roundTags = organization.getIn(['data', 'items', orgId.toString(), 'roundTags']) || List();

    let values = noteForm.get('values') || Map();

    const tags = values.get('tags') || List();
    tags.forEach((tag) => {
      if (roundTags.includes(tag)) {
        values = values.set('round', currentRound);
      }
    });

    if (!noteForm.get('syncErrors')) {
      createNote({
        pnm: pnm.get('id'),
        chapter,
        ...values.toJS(),
      });
    }
  };

  const handleSubmit = () => {
    submitVote();
    submitMatch();
    submitNote();
  };

  const renderErrorMessage = () => {
    let element;

    if (submitFailed) {
      element = <FormHelperText error>Vote failed, try again.</FormHelperText>;
    }
    return element;
  };

  return (
    <Dialog open={ dialogOpen }
      onClose={ handleDialogClose }
      fullScreen={ fullScreen }
      maxWidth={ false }>

      <DialogContent classes={ { root: classes.dialogContentRoot } }>
        <SingleBallotVoteForm clearanceLevel={ clearanceLevel }
          currentVote={ currentVote }
          handleDialogClose={ handleDialogClose }
          pnm={ pnm }
          teamLevel={ teamLevel } />

        <Divider />

        <SingleBallotNoteForm clearanceLevel={ clearanceLevel }
          currentChapter={ currentChapter }
          disableNotesFeature={ disableNotesFeature }
          allowVotingMembersToTag={ allowVotingMembersToTag }
          notes={ notes }
          pnm={ pnm }
          teamLevel={ teamLevel } />

        <Divider className={ classes.divider } />

        <SingleBallotMatchForm handleDialogClose={ handleDialogClose }
          currentChapterId={ currentChapterId }
          pnmData={ pnm } />

        <DialogActions className={ classes.dialogActionsRoot }>
          { renderErrorMessage() }

          <Button onClick={ handleDialogClose } disabled={ submitting } color='primary'>
            Cancel
          </Button>

          <Button variant='contained'
            onClick={ handleSubmit }
            loading={ submitting }
            disabled={ disabled }
            color='primary'>

            <Icon className={ classes.leftIcon } color='inherit'>thumbs_up_down</Icon>
            Submit
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

SingleBallotModal.propTypes = {
  allowVotingMembersToTag: PropTypes.bool.isRequired,
  categories:              PropTypes.instanceOf(List).isRequired,
  classes:                 PropTypes.instanceOf(Object).isRequired,
  clearanceLevel:          PropTypes.number.isRequired,
  createMatch:             PropTypes.func.isRequired,
  createNote:              PropTypes.func.isRequired,
  createVote:              PropTypes.func.isRequired,
  currentChapter:          PropTypes.instanceOf(Map).isRequired,
  currentUser:             PropTypes.instanceOf(Map).isRequired,
  currentVote:             PropTypes.instanceOf(Map).isRequired,
  dialogOpen:              PropTypes.bool.isRequired,
  disableNotesFeature:     PropTypes.bool.isRequired,
  fullScreen:              PropTypes.bool.isRequired,
  handleDialogClose:       PropTypes.func.isRequired,
  matchForm:               PropTypes.instanceOf(Map).isRequired,
  noteForm:                PropTypes.instanceOf(Map).isRequired,
  notes:                   PropTypes.instanceOf(List).isRequired,
  organization:            PropTypes.instanceOf(Map).isRequired,
  pnm:                     PropTypes.instanceOf(Object).isRequired,
  pnmId:                   PropTypes.number.isRequired,
  rounds:                  PropTypes.instanceOf(List).isRequired,
  teamLevel:               PropTypes.number.isRequired,
  updateVote:              PropTypes.func.isRequired,
  voteForm:                PropTypes.instanceOf(Map).isRequired,
};

const styles = theme => ({
  dialogContentRoot: {
    minHeight:       '60vh',
    minWidth:        '50vw',
    overflowX:       'hidden',
    overflowY:       'auto',
    padding:         0,
    '&:first-child': {
      paddingTop: 0,
    },

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  divider: {
    marginTop: 20,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(withStyles(styles)(SingleBallotModal));
