import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import specialtyService from '../../sources/api/specialtyService';

// constants
import { SPECIALTY_FETCH_ALL, SPECIALTY_FETCH_ALL_INIT } from '../../constants/specialtyTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { specialtyListSchema } from '../../schemas/specialty';

export function* specialtyFetchAll(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  try {
    yield* httpSaga(SPECIALTY_FETCH_ALL, call(find, specialtyService, {}), {
      schema: specialtyListSchema,
    });
  } catch (err) {
    yield put(error(SPECIALTY_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(SPECIALTY_FETCH_ALL));
  }
}

export function* watchSpecialtyFetchAll() {
  yield takeEvery(SPECIALTY_FETCH_ALL_INIT, specialtyFetchAll);
}

export default function* root() {
  yield all([
    fork(watchSpecialtyFetchAll),
  ]);
}
