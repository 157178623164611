import { all, fork, takeEvery } from 'redux-saga/effects';

// constants
import { CURRENT_CHAPTER_CLEAR } from '../../constants/chapterTypes';

export function* currentChapterClear(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  yield window.localStorage.removeItem('chapter');
}

export function* watchCurrentChapterClear() {
  yield takeEvery(CURRENT_CHAPTER_CLEAR, currentChapterClear);
}

export default function* root() {
  yield all([
    fork(watchCurrentChapterClear),
  ]);
}
