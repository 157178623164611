import React from 'react';
import Immutable, { List } from 'immutable';
import PropTypes from 'prop-types';

// mui components
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

class EnhancedTableHead extends React.Component {
  static propTypes = {
    numCols:      PropTypes.number.isRequired,
    onSortChange: PropTypes.func.isRequired,
    order:        PropTypes.string.isRequired,
    sortBy:       PropTypes.string.isRequired,
    specialties:  PropTypes.instanceOf(List).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        columns: [],
      }),
    };
  }

  componentWillMount() {
    const { specialties } = this.props;

    let newColumns = Immutable.fromJS([
      {
        id:        'firstName',
        align:     'left',
        label:     'First Name',
        allowSort: true,
      },
      {
        id:        'lastName',
        align:     'left',
        label:     'Last Name',
        allowSort: true,
      },
    ]);

    specialties.forEach((s) => {
      newColumns = newColumns.push(Immutable.fromJS({
        id:        s.get('_id'),
        align:     'right',
        label:     s.get('name'),
        allowSort: false,
      }));
    });

    this.setState({ data: this.state.data.set('columns', newColumns) });
  }

  componentWillReceiveProps() {
    const { specialties } = this.props;

    if (specialties.size) {
      let newColumns = Immutable.fromJS([
        {
          id:        'firstName',
          align:     'left',
          label:     'First Name',
          allowSort: true,
        },
        {
          id:        'lastName',
          align:     'left',
          label:     'Last Name',
          allowSort: true,
        },
      ]);

      specialties.forEach((s) => {
        newColumns = newColumns.push(Immutable.fromJS({
          id:           s.get('_id'),
          align:        'left',
          label:        s.get('title'),
          tooltipTitle: s.get('description'),
          allowSort:    false,
        }));
      });

      this.setState({ data: this.state.data.set('columns', newColumns) });
    }
  }

  handleRequestSort = (event, property) => {
    this.props.onSortChange(property);
  };

  renderColumnHeader = (column) => {
    const { sortBy, order } = this.props;

    let element = column.get('label');

    if (column.get('allowSort')) {
      element = (
        <Tooltip title='Sort'
          placement={ column.get('numeric') ? 'bottom-end' : 'bottom-start' }
          enterDelay={ 300 }>
          <TableSortLabel active={ sortBy === column.get('id') }
            direction={ order }
            onClick={ e => this.handleRequestSort(e, column.get('id')) }>
            {column.get('label')}
          </TableSortLabel>
        </Tooltip>
      );
    } else if (column.get('tooltipTitle')) {
      element = (
        <Tooltip title={ column.get('tooltipTitle') }
          placement={ column.get('numeric') ? 'bottom-end' : 'bottom-start' }
          enterDelay={ 300 }>
          <TableSortLabel>
            {column.get('label')}
          </TableSortLabel>
        </Tooltip>
      );
    }

    return element;
  }

  render() {
    const {
      numCols, sortBy, order,
    } = this.props;

    const columns = this.state.data.get('columns');

    return (
      <TableHead>
        <TableRow colSpan={ numCols }>
          {columns.map(column => (
            <TableCell key={ column.get('id') }
              align={ column.get('align') }
              padding='dense'
              sortDirection={ sortBy === column.get('id') ? order : false }>

              { this.renderColumnHeader(column) }
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
