import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import React, { Component } from 'react';

// components
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import InfoTab from '../InfoTab';
import VotesTab from '../VotesTab';
import NotesTab from '../NotesTab';
import ConcernsTab from '../ConcernsTab';
import MatchesTab from '../MatchesTab';

class TabMenu extends Component {
  static propTypes = {
    classes:                          PropTypes.instanceOf(Object).isRequired,
    chapterId:                        PropTypes.number.isRequired,
    disableNotesFeature:              PropTypes.bool.isRequired,
    hideVotesFromVotingMembers:       PropTypes.bool.isRequired,
    isAdmin:                          PropTypes.bool.isRequired,
    isCdSite:                         PropTypes.bool.isRequired,
    notes:                            PropTypes.instanceOf(List).isRequired,
    membershipConcerns:               PropTypes.instanceOf(List).isRequired,
    pnm:                              PropTypes.instanceOf(Map).isRequired,
    showBestMatchedWith:              PropTypes.bool.isRequired,
    membershipConcernsFeatureEnabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        currentTab: 'info',
        tabs:       [],
      }),
    };
  }

  componentWillMount() {
    const currentTab = 'info';
    this.setState({ data: this.state.data.set('value', currentTab) });

    const {
      pnm, notes, membershipConcerns,
    } = this.props;
    const matchRecs = pnm.get('matchRecs');
    const officialMatches = pnm.get('officialMatches');

    const tabs = Immutable.fromJS([
      {
        label: 'Info',
        value: 'info',
        icon:  'list',
      },
      {
        label: 'Votes',
        value: 'votes',
        icon:  'thumbs_up_down',
        count: pnm.get('voteCount'),
      },
      {
        label: 'Notes',
        value: 'notes',
        icon:  'speaker_notes',
        count: notes.size || 0,
      },
      {
        label: 'Concerns',
        value: 'concerns',
        icon:  'info',
        count: membershipConcerns.size || 0,
      },
      {
        label: 'Matches',
        value: 'matches',
        icon:  'extension',
        count: (matchRecs + officialMatches) || 0,
      },
    ]);

    this.setState({ data: this.state.data.set('tabs', tabs) });
  }

  componentWillReceiveProps(nextProps) {
    const {
      pnm, notes, membershipConcerns,
      showBestMatchedWith, disableNotesFeature,
      membershipConcernsFeatureEnabled,
    } = nextProps;
    const matchRecs = pnm.get('matchRecs');
    const officialMatches = pnm.get('officialMatches');

    let tabs = Immutable.fromJS([
      {
        label: 'Info',
        value: 'info',
        icon:  'list',
      },
      {
        label: 'Votes',
        value: 'votes',
        icon:  'thumbs_up_down',
        count: pnm.get('voteCount'),
      },
    ]);

    if (!disableNotesFeature) {
      tabs = tabs.push(Immutable.fromJS(
        {
          label: 'Notes',
          value: 'notes',
          icon:  'speaker_notes',
          count: notes.size || 0,
        }
      ));
    } else { // Otherwise push just the tags, so users can view and delete
      tabs = tabs.push(Immutable.fromJS(
        {
          label: 'Tags',
          value: 'tags',
          icon:  'local_offer',
          count: notes.size || 0,
        }
      ));
    }

    if (membershipConcernsFeatureEnabled) {
      tabs = tabs.push(Immutable.fromJS(
        {
          label: 'Concerns',
          value: 'concerns',
          icon:  'info',
          count: membershipConcerns.size || 0,
        }
      ));
    }

    if (showBestMatchedWith) {
      tabs = tabs.push(Immutable.fromJS(
        {
          label: 'Matches',
          value: 'matches',
          icon:  'extension',
          count: (matchRecs || officialMatches) || 0,
        },
      ));
    }

    this.setState({ data: this.state.data.set('tabs', tabs) });
  }

  handleChange = (event, value) => {
    this.setState({ data: this.state.data.set('currentTab', value) });
  }

  renderTabs() {
    const {
      classes, isAdmin,
      hideVotesFromVotingMembers,
    } = this.props;
    const tabs = this.state.data.get('tabs');
    const elements = [];

    tabs.forEach((tab) => {
      let label = tab.get('label');
      const key = tab.get('value');
      const icon = tab.get('icon');
      const count = tab.get('count');

      if (count) label = `${label} ( ${count} )`;

      const element = (
        <Tab className={ classes.tab }
          classes={
            {
              wrapper: classes.wrapper,
              root:    classes.root,
            }
          }
          key={ key }
          label={ label }
          value={ key }
          icon={ <Icon>{ icon }</Icon> } />
      );

      if (key === 'notes' || key === 'tags') {
        elements.push(element);
      } else if (key === 'votes') {
        if (!hideVotesFromVotingMembers
            || isAdmin) {
          elements.push(element);
        }
      } else if (key === 'matches') {
        elements.push(element);
      } else {
        elements.push(element);
      }
    });

    return elements;
  }

  render() {
    const {
      chapterId,
      classes,
      disableNotesFeature,
      hideVotesFromVotingMembers,
      isAdmin,
      isCdSite,
      notes,
      pnm,
      showBestMatchedWith,
    } = this.props;

    const currentTab = this.state.data.get('currentTab');

    return (
      <Card className={ classes.tabsCard }>
        <AppBar position='static' color='default'>
          <Tabs value={ currentTab }
            onChange={ this.handleChange }
            indicatorColor='primary'
            textColor='primary'
            centered
            variant='fullWidth'>
            { this.renderTabs() }
          </Tabs>
        </AppBar>
        <CardContent>
          { currentTab === 'info'
            && <InfoTab pnmData={ pnm } isCdSite={ isCdSite } /> }
          { currentTab === 'votes'
            && (!hideVotesFromVotingMembers
              || isAdmin)
              && <VotesTab />}
          { (currentTab === 'notes' || currentTab === 'tags')
            && (
            <NotesTab disableNotesFeature={ disableNotesFeature }
              chapterId={ chapterId }
              pnmId={ pnm.get('id') }
              notes={ notes } />
            ) }

          { currentTab === 'concerns'
            && <ConcernsTab pnmId={ pnm.get('id') } /> }

          { currentTab === 'matches' && showBestMatchedWith
            && <MatchesTab chapterId={ chapterId } /> }
        </CardContent>
      </Card>
    );
  }
}

const styles = () => ({
  tabsCard: {
    backgroundColor: '#fff',
    height:          'auto',
    minHeight:       '74.75vh',
    borderRadius:    10,
  },
  root: {
    maxWidth: 'inherit', // tabs were not going full width
  },
});

export default withStyles(styles)(TabMenu);
