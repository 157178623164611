import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map, List } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local components
import Button from '../../../Button';
import BumpGroupsForm from './components/BumpGroupsForm';
import BumpGroupGenerator from './components/BumpGroupGenerator';

class BumpGroups extends Component {
  static propTypes = {
    bumpGroup:                 PropTypes.instanceOf(Map).isRequired,
    bumpGroupLoading:          PropTypes.bool.isRequired,
    bumpGroups:                PropTypes.instanceOf(List).isRequired,
    classes:                   PropTypes.instanceOf(Object).isRequired,
    createBumpGroup:           PropTypes.func.isRequired,
    currentChapterId:          PropTypes.number.isRequired,
    currentChapterLoading:     PropTypes.bool.isRequired,
    fetchBumpGroupsForChapter: PropTypes.func.isRequired,
    formValues:                PropTypes.instanceOf(List).isRequired,
    parentclasses:             PropTypes.instanceOf(Object).isRequired,
    removeBumpGroup:           PropTypes.func.isRequired,
    submitFailed:              PropTypes.bool.isRequired,
    submitSucceeded:           PropTypes.bool.isRequired,
    fetchUsersForChapter:      PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        snackbarOpen:    false,
        snackbarMessage: '',
        formValues:      [],
      }),
    };
  }

  componentWillMount() {
    const { currentChapterId, fetchBumpGroupsForChapter } = this.props;

    fetchBumpGroupsForChapter({
      chapter:            currentChapterId,
      shouldPopulateUser: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { formValues } = this.props;
    const nextFormValues = nextProps.formValues;

    const oldSize = formValues.size;
    const nextSize = nextFormValues.size;

    if (nextProps.submitSucceeded && nextFormValues && nextSize > oldSize) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarMessage', 'Added Successfully');
          map.set('snackbarOpen', true);
        }),
      });
    } else if (nextProps.submitFailed) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarMessage', 'Failed To Add');
          map.set('snackbarOpen', true);
        }),
      });
    } else {
      this.setState({ data: this.state.data.set('formValues', nextFormValues) });
    }
  }

  handleAddBumpGroup = () => {
    const {
      bumpGroups,
      createBumpGroup,
      currentChapterId,
    } = this.props;

    const totalGroups = bumpGroups.size;

    const item = {
      users:   [],
      chapter: currentChapterId,
      order:   totalGroups + 1,
    };

    createBumpGroup({
      item,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  }

  render() {
    const {
      bumpGroupLoading,
      bumpGroups,
      classes,
      currentChapterId,
      currentChapterLoading,
      parentclasses: {
        tabsCard, titleContainer, titleIcon, topButton,
      },
      bumpGroup,
      fetchUsersForChapter,
      removeBumpGroup,
    } = this.props;

    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');
    const formValues = this.state.data.get('formValues');
    const loading = bumpGroupLoading || currentChapterLoading;

    return (
      <div>
        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 24 }>

              <Grid item xs={ 12 } className={ titleContainer }>
                <Icon className={ titleIcon } color='primary'>scatter_plot</Icon>
                <Typography variant='h5' color='primary'>Generate Bump Groups</Typography>
              </Grid>

              <Grid item xs={ 12 }>
                <Typography variant='subtitle1' color='textSecondary' gutterBottom>
                  Automatically create random bump groups and fine tune them afterwards
                </Typography>
              </Grid>

              <BumpGroupGenerator bumpGroups={ bumpGroups }
                bumpGroup={ bumpGroup }
                currentChapterId={ currentChapterId } />

            </Grid>
          </CardContent>
        </Card>

        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 24 }>
              <Grid item xs={ 8 } sm={ 8 } className={ titleContainer }>
                <Icon className={ titleIcon } color='primary'>group</Icon>
                <Typography variant='h5' color='primary'>Manage Bump Groups</Typography>
              </Grid>

              <Hidden xsDown>
                <Grid item sm={ 4 } className={ topButton } align='right'>
                  <Button variant='contained'
                    color='primary'
                    loading={ loading }
                    onClick={ this.handleAddBumpGroup }>
                    <Icon className={ classes.leftIcon }>add</Icon>
                    Add Bump Group
                  </Button>
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid item sm={ 4 } className={ topButton } align='right'>
                  <Button variant='fab'
                    color='primary'
                    loading={ loading }
                    onClick={ this.handleAddBumpGroup }>
                    <Icon>add</Icon>
                  </Button>
                </Grid>
              </Hidden>

              <Grid item xs={ 12 }>
                <Typography variant='subtitle1' color='textSecondary' gutterBottom>
                  Create bump groups to organize your chapter for intentional recruitment.
                </Typography>
              </Grid>
              <Grid item xs={ 12 } className={ classes.bumpGroupsFormContainer }>
                <BumpGroupsForm bumpGroups={ bumpGroups }
                  currentChapterId={ currentChapterId }
                  fetchUsersForChapter={ fetchUsersForChapter }
                  bumpGroup={ bumpGroup }
                  formValues={ formValues }
                  removeBumpGroup={ removeBumpGroup } />
              </Grid>

            </Grid>
          </CardContent>
        </Card>

        <Snackbar key='add'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ this.handleSnackbarClose }
          message={ snackbarMessage } />
      </div>
    );
  }
}

const styles = theme => ({
  bumpGroupsFormContainer: {
    marginTop: 15,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(BumpGroups);
