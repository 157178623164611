import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import voteService from '../../sources/api/voteService';

// constants
import { VOTE_FETCH_ALL, VOTE_FETCH_ALL_INIT } from '../../constants/voteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { voteListSchema } from '../../schemas/vote';

export function* voteFetchAll(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter, search, infiniteScroll, skip, sort, limit,
    roundId, eventNumber, shouldPopulatePnm,
  } = action.payload || {};

  const options = {
    query: {
      chapter,
      event:     eventNumber,
      round:     roundId,
      $search:   search,
      $populate: 'round',
      $skip:     skip,
      $sort:     {
        createdAt: -1,
        ...sort,
      },
    },
  };

  if (limit) {
    options.query.$limit = limit;
  }
  if (shouldPopulatePnm) {
    options.query.shouldPopulatePnm = shouldPopulatePnm;
  }

  try {
    yield* httpSaga(VOTE_FETCH_ALL, call(find, voteService, options), {
      schema: voteListSchema,
      infiniteScroll,
    });
  } catch (err) {
    yield put(error(VOTE_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(VOTE_FETCH_ALL));
  }
}

export function* watchVoteFetchAll() {
  yield takeEvery(VOTE_FETCH_ALL_INIT, voteFetchAll);
}

export default function* root() {
  yield all([
    fork(watchVoteFetchAll),
  ]);
}
