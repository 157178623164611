import client from './client';

export default (function AnalyticService() {
  let service;

  if (!service) {
    service = client.service('analytics');
  }

  return service;
}());
