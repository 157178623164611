import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// mui components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

// local components
import Button from '../Button';

// helpers
import { toString } from '../../helpers/transform';

// styles
import modalBoxStyles from './modalBox.style';

class ModalBox extends React.PureComponent {
  static propTypes = {
    classes:               PropTypes.instanceOf(Object).isRequired,
    children:              PropTypes.node.isRequired,
    open:                  PropTypes.bool.isRequired,
    title:                 PropTypes.string.isRequired,
    subtitle:              PropTypes.string.isRequired,
    icon:                  PropTypes.node,
    onAccept:              PropTypes.func,
    onReject:              PropTypes.func,
    acceptLabel:           PropTypes.string,
    rejectLabel:           PropTypes.string,
    loading:               PropTypes.bool,
    isAcceptButtonVisible: PropTypes.bool,
    isRejectButtonVisible: PropTypes.bool,
    isCloseButtonVisible:  PropTypes.bool,
    acceptButtonProps:     PropTypes.shape({
      color:     PropTypes.string,
      className: PropTypes.string,
      disabled:  PropTypes.bool,
    }),
    rejectButtonProps: PropTypes.shape({
      color:     PropTypes.string,
      className: PropTypes.string,
    }),
    fullScreen:       PropTypes.bool,
    headerAlignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline', '']),
  }

  static defaultProps = {
    icon:                  <Icon>settings</Icon>,
    acceptLabel:           'OK',
    rejectLabel:           'close',
    onAccept:              () => {},
    onReject:              () => {},
    loading:               false,
    isAcceptButtonVisible: true,
    isRejectButtonVisible: true,
    isCloseButtonVisible:  true,
    fullScreen:            false,
    acceptButtonProps:     {
      color:     'primary',
      className: '',
      disabled:  false,
    },
    rejectButtonProps: {
      color:     'contrast',
      className: '',
    },
    headerAlignItems: '',
  }

  render() {
    const {
      classes,
      children,
      fullScreen,
      onAccept,
      onReject,
      open,
      acceptLabel,
      rejectLabel,
      title,
      subtitle,
      loading,
      isAcceptButtonVisible,
      isRejectButtonVisible,
      isCloseButtonVisible,
      acceptButtonProps,
      rejectButtonProps,
      icon,
      headerAlignItems,
    } = this.props;

    return (
      <Dialog fullScreen={ fullScreen }
        open={ open }
        onClose={ onReject }
        classes={ {
          paperWidthSm: classes.paperWidthSm,
          paperWidthXs: classes.paperWidthXs,
          paperWidthMd: classes.paperWidthMd,
          paperWidthLg: classes.paperWidthLg,
          paperWidthXl: classes.paperWidthXl,
        } }>

        <DialogTitle disableTypography>
          <Grid container spacing={ 8 }>
            <Grid item xs>
              <Grid container
                spacing={ 8 }
                { ...(headerAlignItems ? { alignItems: headerAlignItems } : {}) }>
                <Grid item style={ { width: '85px' } }>
                  <div className={ classes.icon }>
                    {icon}
                  </div>
                </Grid>
                <Grid item xs>
                  <h3 className={ classes.title }>
                    { title }
                  </h3>
                  <div className={ classes.subtitle }>
                    {subtitle}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {isCloseButtonVisible && (
              <Grid item style={ { width: '35px' } }>
                <IconButton onClick={ onReject }>
                  <Icon color='primary'>close</Icon>
                </IconButton>
              </Grid>
            )}
          </Grid>
        </DialogTitle>

        <DialogContent>
          { children }
        </DialogContent>

        <DialogActions>
          { isRejectButtonVisible && (
            <Button variant='contained'
              onClick={ onReject }
              className={ `${classes.rejectButton} ${toString(rejectButtonProps.className || '')}` }
              color={ toString(rejectButtonProps.color || 'contrast') }
              disabled={ loading }>
              { rejectLabel }
            </Button>
          )}

          {isAcceptButtonVisible && (
            <Button variant='contained'
              onClick={ onAccept }
              className={ `${classes.acceptButton} ${toString(acceptButtonProps.className || '')}` }
              loading={ loading }
              disabled={ acceptButtonProps.disabled }
              color={ toString(acceptButtonProps.color || 'primary') }>
              { acceptLabel }
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(modalBoxStyles)(ModalBox);
