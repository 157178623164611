import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  startSubmit, setSubmitFailed, setSubmitSucceeded, destroy,
} from 'redux-form/immutable';

// api
import { patch } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import { PNM_UPDATE, PNM_UPDATE_INIT } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmSchema } from '../../schemas/pnm';

export function* pnmUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter, formName, id, ...values
  } = action.payload;

  const FORM = formName || ' ';
  yield put(startSubmit(FORM));

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(PNM_UPDATE, call(patch, pnmService, id, values, options), {
      schema: pnmSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(PNM_UPDATE, err));
  } finally {
    yield put(doneIndicator(PNM_UPDATE));
  }
}

export function* watchPnmUpdate() {
  yield takeEvery(PNM_UPDATE_INIT, pnmUpdate);
}

export default function* root() {
  yield all([
    fork(watchPnmUpdate),
  ]);
}
