import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import chapterService from '../../sources/api/chapterService';

// constants
import { CHAPTER_FETCH_ALL_INIT, CHAPTER_FETCH_ALL } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { chapterListSchema } from '../../schemas/chapter';

export function* chapterFetchAll(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    skip, limit, search, sort,
  } = action.payload || {};

  const options = {
    query: {
      $skip:   skip,
      $limit:  limit,
      $search: search,
      $sort:   sort,
    },
  };

  try {
    yield* httpSaga(CHAPTER_FETCH_ALL, call(find, chapterService, options), {
      schema: chapterListSchema,
    });
  } catch (err) {
    yield put(error(CHAPTER_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(CHAPTER_FETCH_ALL));
  }
}

export function* watchChapterFetchAll() {
  yield takeLatest(CHAPTER_FETCH_ALL_INIT, chapterFetchAll);
}

export default function* root() {
  yield all([
    fork(watchChapterFetchAll),
  ]);
}
