import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// components
import MuiSvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

// helpers
import { toString } from '../../helpers/transform';

// styles
import svgIconStyles from './svgIcon.style';

class SvgIcon extends PureComponent {
  static propTypes = {
    classes:   PropTypes.instanceOf(Object).isRequired,
    src:       PropTypes.string.isRequired,
    viewBox:   PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    viewBox:   undefined,
    className: '',
  }

  render() {
    const {
      classes,
      src,
      viewBox,
      className,
    } = this.props;

    return (
      <MuiSvgIcon component='object'
        viewBox={ viewBox }
        className={ `${classes.object} ${toString(className)}` }>
        <embed type='image/svg+xml'
          src={ src }
          className={ classes.embed } />
      </MuiSvgIcon>
    );
  }
}

export default (withStyles(svgIconStyles)(SvgIcon));
