import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import validate from 'validate.js';
import deepEqual from 'fast-deep-equal';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import DraggableList from '../../../../../DraggableList';
import SliderPopover from '../../../../../SliderPopover';
import Savebar from '../../../../../Savebar';
import FieldItem from '../FieldItem';

const FieldsForm = function ({
  bulkCreateFields,
  bulkUpdateFields,
  chapterFields,
  currentChapter,
  fieldLoading,
  fieldStatus,
  fieldsFormSubmitFailed,
  fieldsFormSubmitSucceeded,
  fieldsFormSubmitting,
  initialValues,
  pageEnd,
  pageStart,
  removeField,
}) {
  const [savebarMessage, setSavebarMessage] = useState('Save your changes?');
  const [hideButtons, setHideButtons] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [fieldsSize, setFieldsSize] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(0);

  useEffect(() => {
    if (fieldsFormSubmitSucceeded) {
      setSnackbarOpen(true);
      setSnackbarMessage('Saved successfully');
    } else if (fieldsFormSubmitFailed) {
      setSnackbarOpen(true);
      setSnackbarMessage('Save failed');
    } else {
      setSavebarMessage('Save your changes?');
      setHideButtons(false);
      setAutoHideDuration(null);
    }
  }, [fieldsFormSubmitSucceeded, fieldsFormSubmitFailed]);

  useEffect(() => {
    if (fieldsSize === chapterFields.size - 1 && fieldsSize > 0) {
      if (fieldStatus === 'success') {
        setSnackbarOpen(true);
        setSnackbarMessage('Removed Successfully');
      } else if (fieldStatus === 'error') {
        setSnackbarOpen(true);
        setSnackbarMessage('Remove Failed');
      }
    }

    setFieldsSize(chapterFields.size);
  }, [chapterFields.size, fieldStatus]);

  const handleSave = (values) => {
    const formFields = values.fields;
    const chapter = currentChapter.getIn(['data', 'id'], 0);
    const fieldsToCreate = [];
    const fieldsToUpdate = [];

    formFields.forEach((f) => {
      const idIsNumber = typeof f._id === 'number';

      // Check if this CD field already exists
      const isAbsentCdField = !formFields.find(
        (field = { standardField: { id: '' } }) =>
          field.standardField.id.toString() === f._id.toString()
      );

      const shouldCreateField = idIsNumber && isAbsentCdField;
      if (shouldCreateField) { // This field exists in CD but not MV, so we create it
        const updatedField = f;
        delete updatedField._id;
        updatedField.chapter = parseInt(updatedField.chapter, 10);

        fieldsToCreate.push(updatedField);
      } else {
        fieldsToUpdate.push(f);
      }
    });

    if (fieldsToCreate.length > 0) {
      bulkCreateFields({
        values: fieldsToCreate,
        chapter,
      });
    }

    if (fieldsToUpdate.length > 0) {
      bulkUpdateFields(
        fieldsToUpdate,
        chapter,
      );
    }
  };

  const handleCancel = (reset) => {
    setSnackbarOpen(true);
    setSnackbarMessage('Changes reset.');
    setAutoHideDuration(2000);

    reset();
  };

  const handlePopoverOpen = (event, index) => {
    setSelectedFieldIndex(index);
    setPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  const handleChangeOrder = (fromPosition, items, toPosition, fields) => {
    // const newPnms = Immutable.fromJS(reorder(pnms, oldRank, newRank - 1));
    fields.move(fromPosition, toPosition - 1);

    setPopoverOpen(false);
    setAnchorEl(null);
  };

  const schema = {};
  const selectedField = chapterFields.get(selectedFieldIndex, Map());
  const selectedFieldName = selectedField.getIn(['standardField', 'text'], 'selected field');
  const popoverLabel = selectedFieldName.length < 16 ? selectedFieldName : 'selected field';

  return (
    <Form id='fieldsForm'
      onSubmit={ handleSave }
      mutators={ { ...arrayMutators } }
      initialValues={ initialValues.toJS() }
      initialValuesEqual={ deepEqual }
      keepDirtyOnReinitialize={ fieldsFormSubmitting }
      validate={ values => validate(values, schema) }
      render={ ({
        handleSubmit, invalid, values = [], form: { reset }, dirty,
      }) => (
        <Grid container spacing={ 8 } component='form' onSubmit={ handleSubmit }>

          <FieldArray name='fields'>
            {({ fields }) => [
              <DraggableList items={ chapterFields }
                key='list'
                fields={ fields }
                listItem={ (
                  <FieldItem { ...{
                    currentChapter,
                    chapterFields: Immutable.fromJS(values.fields),
                    loading:       fieldLoading,
                    removeField,
                    handlePopoverOpen,
                    pageEnd,
                    pageStart,
                    values:        Immutable.fromJS(values),
                  } } />
                  ) } />,
              <SliderPopover anchorEl={ anchorEl }
                defaultValue={ selectedFieldIndex + 1 }
                key='popover'
                fields={ fields }
                handlePopoverClose={ handlePopoverClose }
                handleSubmit={ handleChangeOrder }
                item={ selectedField }
                items={ Immutable.fromJS(values.fields) }
                label={ popoverLabel }
                size={ chapterFields.size }
                popoverOpen={ popoverOpen } />,

            ]}
          </FieldArray>

          <Savebar open={ dirty
              && !fieldsFormSubmitSucceeded
              && !fieldsFormSubmitting }
            autoHideDuration={ autoHideDuration }
            form={ Map({ syncErrors: invalid, loading: fieldLoading }) }
            handleAccept={ handleSubmit }
            handleReject={ () => handleCancel(reset) }
            hideButtons={ hideButtons }
            message={ savebarMessage } />

          <Snackbar key='remove'
            anchorOrigin={ {
              vertical:   'bottom',
              horizontal: 'right',
            } }
            open={ snackbarOpen }
            autoHideDuration={ 3000 }
            onClose={ () => setSnackbarOpen(false) }
            message={ snackbarMessage } />
        </Grid>
      ) } />
  );
};

FieldsForm.propTypes = {
  pageStart:                 PropTypes.number.isRequired,
  pageEnd:                   PropTypes.number.isRequired,
  bulkCreateFields:          PropTypes.func.isRequired,
  bulkUpdateFields:          PropTypes.func.isRequired,
  currentChapter:            PropTypes.instanceOf(Map).isRequired,
  initialValues:             PropTypes.instanceOf(Map).isRequired,
  chapterFields:             PropTypes.instanceOf(List).isRequired,
  removeField:               PropTypes.func.isRequired,
  fieldsFormSubmitSucceeded: PropTypes.bool.isRequired,
  fieldsFormSubmitting:      PropTypes.bool.isRequired,
  fieldsFormSubmitFailed:    PropTypes.bool.isRequired,
  fieldLoading:              PropTypes.bool.isRequired,
  fieldStatus:               PropTypes.string.isRequired,
};

export default FieldsForm;
