import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import React, { Component } from 'react';
import { FieldArray } from 'redux-form/immutable';

// MUI components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

// local components
import CategoryItem from '../CategoryItem';
import Savebar from '../../../../../Savebar';

// helpers
import getRoundTypeName from '../../../../../../helpers/getRoundTypeName';

class CategoriesForm extends Component {
  static propTypes = {
    categoriesForm: PropTypes.instanceOf(Map).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    organization:   PropTypes.instanceOf(Map).isRequired,
    dirty:          PropTypes.bool.isRequired,
    reset:          PropTypes.func.isRequired,
    rounds:         PropTypes.instanceOf(List).isRequired,
    updateChapter:  PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        autoHideDuration: null,
        hideButtons:      false,
        savebarMessage:   'Save your changes?',
        savebarOpen:      false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      dirty, categoriesForm, reset,
    } = nextProps;

    const savebarOpen = this.state.data.get('savebarOpen');

    const currentErrors = this.props.categoriesForm.get('syncErrors');
    const nextErrors = categoriesForm.get('syncErrors');

    if (!savebarOpen) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', dirty);
          map.set('savebarMessage', 'Save your changes?');
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    } else if (categoriesForm.get('submitSucceeded')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Saved Successfully');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    } else if (categoriesForm.get('submitFailed')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save Failed');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    } else if (!currentErrors && nextErrors) {
      this.setState({ data: this.state.data.set('savebarMessage', 'Fix errors to save') });
    } else if (currentErrors && !nextErrors) {
      this.setState({ data: this.state.data.set('savebarMessage', 'Save your changes?') });
    }
  }

  handleCloseSavebar = (event, reason) => {
    if (reason === 'timeout') {
      this.setState({ data: this.state.data.set('savebarOpen', false) });
    }
  }

  handleSave = () => {
    const { currentChapter, updateChapter, categoriesForm } = this.props;

    const categories = ((categoriesForm.get('values') || Map())
      .get('roundCategories') || Map())
      .map(roundCategory => roundCategory.get('categories'))
      .flatMap(a => a)
      .toJS();

    // We update the chapter with custom voting category data
    if (!categoriesForm.get('syncErrors')) {
      const updateParams = {
        chapterId:        currentChapter.getIn(['data', 'id'], 0),
        formName:         'categoriesForm',
        customCategories: categories,
      };

      updateChapter(updateParams);
    }
  }

  handleCancel = () => {
    const { reset } = this.props;

    reset();
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('savebarMessage', 'Changes reset.');
        map.set('autoHideDuration', 2000);
        map.set('hideButtons', true);
      }),
    });
  }

  renderRoundTitle = (roundType, roundTypeName, errors) => {
    const { rounds } = this.props;

    const roundNames = rounds
      .filter(r => r.get('roundType') === roundType)
      .map(r => r.get('name'));
    const roundNamesString = roundNames.join(', ');

    return (
      <Grid item xs={ 12 }>
        <Typography color='textSecondary' variant='subtitle1'>Round Type:</Typography>
        <Typography variant='h6' color='secondary' gutterBottom>
          { roundTypeName }
        </Typography>

        <Typography color='textSecondary'>
          Your chapter has
          {' '}
          { roundNames.size }
          {' '}
          rounds that use
          {' '}
          { roundTypeName }
          {' '}
          categories:
        </Typography>
        <Typography variant='subtitle1' color='primary'>
          { roundNamesString }
        </Typography>

        {
          errors ? (
            errors.map(error => <Typography key={ error } variant='subtitle1' color='error'>{ error }</Typography>)
          ) : null
        }
      </Grid>
    );
  }

  renderCategories = ({ fields }) => fields.map((field, index) => {
    const { categoriesForm } = this.props;

    const category = fields.get(index);

    const errors = ((categoriesForm.get('syncErrors') || {}).categories || {})[category.get('_id')];

    return (
      <Grid item xs={ 12 } key={ category.get('_id') }>
        <CategoryItem field={ field }
          category={ category }
          errors={ errors } />
      </Grid>
    );
  })

  renderRoundCategoriesList = ({ fields }) => {
    const {
      categoriesForm,
      organization,
    } = this.props;

    return fields.map((field, index) => {
      const roundCategory = fields.get(index);

      const roundType = roundCategory.get('roundType');
      const errors = (categoriesForm.get('syncErrors', {}).roundCategories || {})[roundCategory.get('_id')];
      const roundTypeName = getRoundTypeName(roundType, organization);

      return (
        <Grid container key={ field } spacing={ 16 }>

          <Grid item xs={ 12 }>
            <ExpansionPanel key={ field }>
              <ExpansionPanelSummary expandIcon={ <Icon>expand_more</Icon> }
                id={ `panel${roundType}` }>
                { this.renderRoundTitle(roundType, roundTypeName, errors) }
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Grid container spacing={ 8 }>
                  { roundCategory.get('description')
                    && (
                    <Grid item xs={ 12 }>
                      <Typography color='textSecondary' variant='caption'>Description:</Typography>
                      <Typography variant='caption' gutterBottom>{roundCategory.get('description')}</Typography>
                    </Grid>
                    )}
                  <FieldArray name={ `${field}.categories` }
                    component={ this.renderCategories } />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      );
    });
  }

  render() {
    const { categoriesForm } = this.props;

    const savebarOpen = this.state.data.get('savebarOpen');
    const savebarMessage = this.state.data.get('savebarMessage');
    const hideButtons = this.state.data.get('hideButtons');
    const autoHideDuration = this.state.data.get('autoHideDuration');

    return (
      <div>
        <Grid item xs={ 12 }>
          <FieldArray name='roundCategories'
            forwardRef
            component={ this.renderRoundCategoriesList } />
        </Grid>

        <Savebar open={ savebarOpen }
          autoHideDuration={ autoHideDuration }
          form={ categoriesForm }
          handleAccept={ this.handleSave }
          handleReject={ this.handleCancel }
          handleClose={ this.handleCloseSavebar }
          hideButtons={ hideButtons }
          message={ savebarMessage } />
      </div>
    );
  }
}

export default CategoriesForm;
