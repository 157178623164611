import SagaTester from 'redux-saga-tester';

// actions
import * as actions from '../../../actions/sessionActions';

// constants
import { SESSION_DESTROY_DONE } from '../../../constants/sessionTypes';

// sagas
import saga from '../sessionDestroy';

describe('sessionDestroy saga', () => {
  let sagaTester;
  let action;

  beforeEach(() => {
    sagaTester = new SagaTester({ initialState: {} });

    sagaTester.start(saga);

    action = actions.sessionDestroy();

    window.localStorage.setItem('feathers-jwt', 'token');
  });

  afterEach(() => {
    window.localStorage.clear();
  });

  describe('when the logout request is successful', () => {
    it('should destroy the session and redirect to "/login"', async () => {
      fetch.mockResponseOnce(JSON.stringify({ accessToken: 'abc123' }));
      fetch.mockResponseOnce(JSON.stringify({ accessToken: 'abc123' }));

      expect(window.localStorage.getItem('feathers-jwt')).toBe('token');

      sagaTester.dispatch(action);

      await sagaTester.waitFor(SESSION_DESTROY_DONE);

      expect(sagaTester.getCalledActions()).toMatchSnapshot();
      expect(window.localStorage.getItem('feathers-jwt')).toBe(null);
    });
  });

  describe('when the logout request fails', () => {
    it('should destroy the session and redirect to "/login"', async () => {
      fetch.mockRejectOnce(new Error('error'));
      fetch.mockResponseOnce(JSON.stringify({ accessToken: 'abc123' }));

      expect(window.localStorage.getItem('feathers-jwt')).toBe('token');

      sagaTester.dispatch(action);

      await sagaTester.waitFor(SESSION_DESTROY_DONE);

      expect(sagaTester.getCalledActions()).toMatchSnapshot();
      expect(window.localStorage.getItem('feathers-jwt')).toBe(null);
    });
  });
});
