import React from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';

// components
import Drawer from './components/Drawer';
import Header from './components/Header';
import AddButton from './components/AddButton';
import Home from '../Home';
import Pnm from '../Pnm';
import PnmList from '../PnmList';
import Match from '../Match';
import { Route } from 'react-router-dom';
import Results from '../Results';
import Import from '../Import';
import Settings from '../Settings';
import DesignGuide from '../DesignGuide';
import Slideshow from '../Slideshow';

// mui components
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

// dashboard-specific components
import DashboardHome from '../Dashboard/Home';
import Analytics from '../Dashboard/Analytics';

// selectors
import getUserRole from '../../lib/selectors/getUserRole';

// images
import logo from '../../assets/images/logo.png';

// styles
import './Layout.scss';

class Layout extends React.Component {
  static propTypes = {
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    currentUser:    PropTypes.instanceOf(Map).isRequired,
    router:         PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        anchorEl:          null,
        clearanceLevel:    '',
        teamLevel:         '',
        elevioInitialized: false,
      }),
    };
  }

  componentWillMount() {
    const { currentChapter, currentUser } = this.props;
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level']);
    const teamLevel = currentChapter.getIn(['data', 'team_level']);

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('clearanceLevel', clearanceLevel);
        map.set('teamLevel', teamLevel);
      }),
    });
  }

  componentWillReceiveProps(nextProps) {
    const { currentChapter, currentUser } = nextProps;
    const elevio = window._elev || {}; // eslint-disable-line
    const elevioInitialized = this.state.data.get('elevioInitialized');

    const clearanceLevel = currentUser.getIn(['data', 'clearance_level']);
    const teamLevel = currentChapter.getIn(['data', 'team_level']);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    let elevioGroup = 'none';

    if (isAdmin) {
      elevioGroup = 'admin';
    } else if (teamLevel === 0) {
      elevioGroup = 'vm';
    }

    if (currentUser.get('data') && elevio && !elevioInitialized) {
      elevio.on('load', (_elev) => {
        _elev.setUser({
          groups: [elevioGroup],
        });
      });
    }

    /* TODO: Fix this. The .on function can't call for some reason */
    // if (!currentUser.get('data') && elevio) {
    //   // TODO: Find way to hide elevio data on logout
    //   elevio.on('load', (_elev) => { // eslint-disable-line
    //     _elev.setUser({
    //       groups: [],
    //     });
    //   });
    // }

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('clearanceLevel', clearanceLevel);
        map.set('teamLevel', teamLevel);
        map.set('elevioInitialized', elevio && currentUser.get('data'));
      }),
    });
  }

  getRoutes = (clearanceLevel, teamLevel, showAdvancedMatching) => {
    const { currentChapter, currentUser } = this.props;

    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';
    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen']);
    const displayPnmProfileOnVotingClosed = currentChapter.getIn(['data', 'displayPnmProfileOnVotingClosed']);

    const routes = [
      <Route exact path='/' component={ Home } key='home' />,
      <Route path='/settings' component={ Settings } key='settings' />,
      <Route exact path='/dashboard' component={ DashboardHome } key='dashboardHome' />,
    ];

    if ([1, 2, 3].includes(teamLevel) || [1, 2].includes(clearanceLevel)) {
      routes.push(
        <Route path='/results' component={ Results } key='results' />,
        <Route path='/import' component={ Import } key='import' />,
      );
    }

    if (isAdmin || isConcernChair || isVotingOpen || displayPnmProfileOnVotingClosed) {
      const path = '/pnm/:id(\\d+)'; // Regexp to accept only numeric values for id
      routes.push(
        <Route path={ path } component={ Pnm } key='pnm' />
      );
    }

    if (showAdvancedMatching && isAdmin) {
      routes.push(
        <Route path='/match/user/:id' component={ Match } key='handmatchUser' />,
        <Route path='/match/bumpgroup/:id' component={ Match } key='handmatchGroup' />
      );
    }

    if (clearanceLevel === 1) {
      routes.push(
        <Route path='/dashboard/analytics' component={ Analytics } key='analytics' />,
        <Route path='/dashboard/settings' component={ Settings } key='dashboardSettings' />,
      );
    }

    if (clearanceLevel === 2) {
      routes.push(
        <Route path='/design-guide' component={ DesignGuide } key='designGuide' />,
        <Route path='/dashboard/analytics' component={ Analytics } key='analytics' />,
        <Route path='/dashboard/settings' component={ Settings } key='dashboardSettings' />,
      );
    }

    return routes;
  }

  renderSidebar = () => {
    const { currentChapter, router: { location: { pathname } } } = this.props;

    let element = null;

    if (!pathname.includes('dashboard') && currentChapter.get('data')) {
      element = (
        <Route path='/' component={ PnmList } key='pnmList' />
      );
    }

    return element;
  }

  render() {
    const { currentUser, currentChapter, router: { location: { pathname } } } = this.props;

    const clearanceLevel = currentUser.getIn(['data', 'clearance_level']);
    const teamLevel = currentChapter.getIn(['data', 'team_level']);
    const showAdvancedMatching = currentChapter.getIn(['data', 'showAdvancedMatching']);

    const onSlideshow = pathname.includes('slideshow');

    const routes = this.getRoutes(clearanceLevel, teamLevel, showAdvancedMatching);

    const userLoading = !currentUser.get('data');
    const chapterLoading = !currentChapter.get('data') && !pathname.includes('/dashboard') && !pathname.includes('/account');

    let element = (
      <Fade in={ userLoading }>
        <div className='loading-indicator'>
          <img className='logo' src={ logo } alt='MyVote' />

          <CircularProgress size={ 50 } />
        </div>
      </Fade>
    );

    if (!userLoading) {
      if (onSlideshow) {
        element = (
          <div className='slideshow-layout'>
            <Slideshow />
          </div>
        );
      } else {
        element = (
          <div className='layout'>
            <Header />

            <Hidden mdDown key='mainSidebar'>
              { this.renderSidebar() }
            </Hidden>

            <Hidden lgUp key='mobileSidebar'>
              <Drawer>
                { this.renderSidebar() }
              </Drawer>
            </Hidden>

            <Hidden xsDown key='mainLayout'>
              <div className='layout__content'>
                { chapterLoading ? (
                  <div className='loading-indicator'>
                    <CircularProgress />
                  </div>
                ) : routes }
              </div>
            </Hidden>

            <Hidden smUp key='mobileLayout'>
              <div className='layout__content layout__content--xs'>
                { chapterLoading ? (
                  <div className='loading-indicator'>
                    <CircularProgress />
                  </div>
                ) : routes }
              </div>
            </Hidden>

            { !pathname.includes('/dashboard')
                && <AddButton currentUser={ currentUser } currentChapter={ currentChapter } />}
          </div>
        );
      }
    }

    return element;
  }
}

export default Layout;
