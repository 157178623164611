import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';
import { denormalize } from 'normalizr';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';

// local components
import InviteForm from './components/InviteForm';
import InviteItem from './components/InviteItem';
import ExistingUsersForm from './components/ExistingUsersForm';
import RemoveUsersForm from './components/RemoveUsersForm';

// schemas
import { inviteListSchema } from '../../../../schemas/invite';
import { userListSchema } from '../../../../schemas/user';

const USER_TYPE_OPTIONS = [
  { value: 'ALL_USERS', label: 'All Users' },
  { value: 'VOTING_MEMBER', label: 'Voting Member' },
  { value: 'CONCERN_CHAIR', label: 'Concern Chair' },
  { value: 'RECRUITMENT_TEAM', label: 'Recruitment Team' },
  { value: 'TECHNOLOGY_TEAM', label: 'Technology Team' },
];

class Users extends Component {
  static propTypes = {
    classes:                PropTypes.instanceOf(Object).isRequired,
    clearanceLevel:         PropTypes.number.isRequired,
    parentclasses:          PropTypes.instanceOf(Object).isRequired,
    currentChapter:         PropTypes.instanceOf(Map).isRequired,
    invite:                 PropTypes.instanceOf(Map).isRequired,
    fetchInvitesForChapter: PropTypes.func.isRequired,
    fetchUsersForChapter:   PropTypes.func.isRequired,
    user:                   PropTypes.instanceOf(Map).isRequired,
    chapter:                PropTypes.instanceOf(Map).isRequired,
    teamLevel:              PropTypes.number.isRequired,
    deleteUser:             PropTypes.func.isRequired,
    bulkUpdateUsers:        PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        timerId:        null,
        userSearch:     null,
        userFilter:     USER_TYPE_OPTIONS[0].value,
        inviteSearch:   null,
        userPagination: {
          rowsPerPage: 5,
          page:        0,
        },
        invitePagination: {
          rowsPerPage: 5,
          page:        0,
        },
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { currentChapter } = nextProps;

    const chapterId = currentChapter.getIn(['data', 'id'], 0);
    const initialized = this.state.data.get('initialized') || false;

    if (chapterId && !initialized) {
      this.initInvites(chapterId);
      this.initUsers(chapterId);

      this.setState({ data: this.state.data.set('initialized', true) });
    }
  }

  onUserSearchChange = (value) => {
    this.setState({ data: this.state.data.set('userSearch', (value || '').toLowerCase()) });
  }

  onInviteSearchChange = (value) => {
    this.setState({ data: this.state.data.set('inviteSearch', (value || '').toLowerCase()) });
  }

  getInvites = () => {
    const { invite } = this.props;

    let elements = [];

    let result = invite.getIn(['data', 'result'], false);

    if (result) {
      if (!List.isList(result)) {
        result = List(result);
      }

      const entities = { invite: invite.getIn(['data', 'items'], List()).toJS() };

      elements = denormalize(result.toJS(), inviteListSchema, entities);
    }

    return Immutable.fromJS(elements);
  }

  getUsers = () => {
    const { user, chapter } = this.props;

    let elements = [];

    const userData = user.get('data') || Map();
    const chapterData = chapter.get('data') || Map();

    if (userData.get('result') && typeof userData.get('result').toJS === 'function') {
      const result = (userData.get('result') || List()).toJS();
      const entities = {
        user:    (userData.get('items') || List()).toJS(),
        chapter: (chapterData.get('items') || List()).toJS(),
      };

      elements = denormalize(result, userListSchema, entities);
    }

    return Immutable.fromJS(elements);
  }

  initUsers = (chapter) => {
    const { fetchUsersForChapter } = this.props;

    fetchUsersForChapter({ chapter });
  }

  initInvites = (id) => {
    const { fetchInvitesForChapter } = this.props;

    fetchInvitesForChapter(id);
  }

  handleChange = (event, value) => {
    this.setState({ data: this.state.data.set('value', value) });
  }

  handleDeleteUser = (id) => {
    const { currentChapter, deleteUser } = this.props;

    const chapter = currentChapter.getIn(['data', 'id'], 0);

    deleteUser(id, chapter);
  }

  handleBulkUpdate = (items) => {
    const { currentChapter, bulkUpdateUsers } = this.props;

    const chapter = currentChapter.getIn(['data', 'id'], 0);

    bulkUpdateUsers(items, chapter);
  }

  handleUserChangeRowsPerPage = ({ target: { value } }) => {
    this.setState({ data: this.state.data.setIn(['userPagination', 'rowsPerPage'], value) });
  }

  handleUserChangePage = (event, newPage) => {
    this.setState({ data: this.state.data.setIn(['userPagination', 'page'], newPage) });
  }

  handleInviteChangeRowsPerPage = ({ target: { value } }) => {
    this.setState({ data: this.state.data.setIn(['invitePagination', 'rowsPerPage'], value) });
  }

  handleInviteChangePage = (event, newPage) => {
    this.setState({ data: this.state.data.setIn(['invitePagination', 'page'], newPage) });
  }

  handleUserSearchChange = ({ target: { value } }) => {
    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(this.onUserSearchChange, 500, value)),
    });
  }

  handleInviteSearchChange = ({ target: { value } }) => {
    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(this.onInviteSearchChange, 500, value)),
    });
  }

  handleUserTypeChange = ({ target: { value } }) => {
    this.setState({ data: this.state.data.set('userFilter', value) });
  }

  renderInviteList = (invites) => {
    const { invite } = this.props;

    const loading = invite.get('loading') && !invite.get('data');

    let elements = [];

    if (loading) {
      elements = <CircularProgress />;
    } else if (invites.size === 0) {
      elements = <Typography>You have no pending invites</Typography>;
    } else {
      invites.forEach((i) => {
        elements.push(
          <InviteItem key={ i.get('_id') }
            invite={ i } />
        );
      });
    }

    return elements;
  }

  renderUserList = (users) => {
    const {
      clearanceLevel, teamLevel, user,
    } = this.props;

    const loading =      user.get('loading')
      && user.getIn(['data', 'items'], List()).size <= 1; // current user will always be in user app state

    let element;

    if (loading) {
      element = <CircularProgress />;
    } else if (users.size === 0) {
      element = (
        <Typography>
          Uh oh, you have no users! Please contact support.
        </Typography>
      );
    } else {
      element = (
        <ExistingUsersForm users={ users }
          onUserDelete={ this.handleDeleteUser }
          clearanceLevel={ clearanceLevel }
          teamLevel={ teamLevel }
          onUpdate={ this.handleBulkUpdate } />

      );
    }

    return element;
  }

  render() {
    const {
      classes,
      clearanceLevel,
      teamLevel,
      parentclasses: { tabsCard, titleContainer, titleIcon },
    } = this.props;

    const users = this.getUsers() || List();
    const invites = this.getInvites() || List();

    const sortedUsers = users.sortBy((u = Map()) => u.get('lastname'));
    let searchedUsers = sortedUsers;
    const userSearch = this.state.data.get('userSearch', false);
    const userFilter = this.state.data.get('userFilter', USER_TYPE_OPTIONS[0].value);

    if (userSearch) {
      searchedUsers = sortedUsers.filter(u =>
        u.get('email', '').toLowerCase().includes(userSearch)
          || u.get('firstname', '').toLowerCase().includes(userSearch)
          || u.get('lastname', '').toLowerCase().includes(userSearch));
    }

    if (userFilter !== USER_TYPE_OPTIONS[0].value) {
      searchedUsers = searchedUsers.filter(u => u.get('role', '') === userFilter);
    }

    const sortedInvites = invites.sortBy(inv => inv.get('email'));
    let searchedInvites = sortedInvites;
    const inviteSearch = this.state.data.get('inviteSearch', false);

    if (inviteSearch) {
      searchedInvites = searchedInvites.filter(u =>
        u.get('email', '').toLowerCase().includes(inviteSearch));
    }

    const currentPageUser = this.state.data.getIn(['userPagination', 'page']);
    const rowsPerPageUser = this.state.data.getIn(['userPagination', 'rowsPerPage']);
    const pageStartUser = currentPageUser * rowsPerPageUser;
    const pageEndUser = pageStartUser + rowsPerPageUser;
    const paginatedUsers = searchedUsers.slice(pageStartUser, pageEndUser);

    const currentPageInvite = this.state.data.getIn(['invitePagination', 'page']);
    const rowsPerPageInvite = this.state.data.getIn(['invitePagination', 'rowsPerPage']);
    const pageStartInvite = currentPageInvite * rowsPerPageInvite;
    const pageEndInvite = pageStartInvite + rowsPerPageInvite;
    const paginatedInvites = searchedInvites.slice(pageStartInvite, pageEndInvite);

    return (
      <div>
        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 8 }>

              <Grid item xs={ 12 } className={ titleContainer }>
                <Icon className={ titleIcon } color='primary'>group_add</Icon>
                <Typography variant='h5' color='primary'>Invite Users</Typography>
              </Grid>

              <Grid item xs={ 12 }>
                <Typography variant='subtitle1' color='textSecondary'>
                  Invite a user by entering their email address & selecting a user description
                </Typography>
              </Grid>

              <Grid item xs={ 12 }>
                <InviteForm />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 8 }>

              <Grid item xs={ 8 }>
                <Grid item xs={ 12 } className={ titleContainer }>
                  <Icon className={ titleIcon } color='primary'>email</Icon>
                  <Typography variant='h5' color='primary'>Manage Invited Users</Typography>
                </Grid>

                <Grid item xs={ 12 }>
                  <Typography variant='subtitle1' color='textSecondary'>
                    The following users have been invited to join your team.
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={ 4 }>
                <Grid container justify='flex-end'>
                  <Grid item xs={ 8 }>
                    <TextField label='Search Invites'
                      name='searchInvites'
                      onChange={ this.handleInviteSearchChange }
                      InputProps={ {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Icon>search</Icon>
                          </InputAdornment>
                        ),
                      } }
                      fullWidth />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={ 12 }>
                <Grid container justify='flex-end'>
                  <TablePagination component='div'
                    rowsPerPageOptions={ [5, 10, 15] }
                    rowsPerPage={ this.state.data.getIn(['invitePagination', 'rowsPerPage']) }
                    count={ searchedInvites.size }
                    page={ this.state.data.getIn(['invitePagination', 'page']) }
                    onChangePage={ this.handleInviteChangePage }
                    onChangeRowsPerPage={ this.handleInviteChangeRowsPerPage } />
                </Grid>
              </Grid>

              <Grid item xs={ 12 }>
                { this.renderInviteList(paginatedInvites) }
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 8 }>
              <Grid item xs={ 4 }>
                <Grid item xs={ 12 } className={ titleContainer }>
                  <Icon className={ titleIcon } color='primary'>group</Icon>
                  <Typography variant='h5' color='primary'>Manage Existing Users</Typography>
                </Grid>

                <Grid item xs={ 12 }>
                  <Typography variant='subtitle1' color='textSecondary'>
                    View your team and manage their roles
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={ 4 }>
                <Grid container justify='center'>
                  <Grid item xs={ 12 }>
                    <TextField select
                      label='Filter Users'
                      name='userFilter'
                      value={ userFilter }
                      onChange={ this.handleUserTypeChange }
                      InputProps={ {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Icon>filter_list</Icon>
                          </InputAdornment>),
                      } }
                      fullWidth>
                      { USER_TYPE_OPTIONS.map(option =>
                        (
                          <MenuItem key={ option.value } value={ option.value }>
                            <Typography noWrap>
                              { option.label }
                            </Typography>
                          </MenuItem>
                        )) }
                    </TextField>

                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={ 4 }>
                <Grid container justify='flex-end'>
                  <Grid item xs={ 8 }>
                    <TextField label='Search Users'
                      name='searchUsers'
                      onChange={ this.handleUserSearchChange }
                      InputProps={ {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Icon>search</Icon>
                          </InputAdornment>),
                      } }
                      fullWidth />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={ 12 }>
                <Grid container justify='flex-end'>
                  <TablePagination component='div'
                    rowsPerPageOptions={ [5, 10, 15] }
                    rowsPerPage={ this.state.data.getIn(['userPagination', 'rowsPerPage']) }
                    count={ searchedUsers.size }
                    page={ this.state.data.getIn(['userPagination', 'page']) }
                    onChangePage={ this.handleUserChangePage }
                    onChangeRowsPerPage={ this.handleUserChangeRowsPerPage } />
                </Grid>
              </Grid>

              <Grid item xs={ 12 }>
                { this.renderUserList(paginatedUsers) }
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        { (clearanceLevel > 0 || teamLevel > 1)
          ? (
            <Card className={ tabsCard }>
              <CardContent>
                <Grid container spacing={ 8 }>

                  <Grid item xs={ 12 } className={ titleContainer }>
                    <Icon className={ titleIcon } color='primary'>delete</Icon>
                    <Typography variant='h5' color='primary'>Remove All Users</Typography>
                  </Grid>

                  <Grid item xs={ 12 }>
                    <Typography variant='subtitle1' color='textSecondary'>
                      Type REMOVE in the field and then click the button to confirm
                    </Typography>
                    <Typography className={ classes.redText }>
                      Headquarters users will not be deleted.
                    </Typography>
                  </Grid>

                  <Grid item xs={ 12 }>
                    <RemoveUsersForm />
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          )
          : null}
      </div>
    );
  }
}

const styles = () => ({
  redText: {
    color: red[500],
  },
});

export default withStyles(styles)(Users);
