import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { get } from '../../sources/api/client';
import organizationService from '../../sources/api/organizationService';

// constants
import { ORGANIZATION_FETCH_INIT, ORGANIZATION_FETCH } from '../../constants/organizationTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { organizationSchema } from '../../schemas/organization';

export function* organizationFetch(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { organizationId } = action.payload || {};

  try {
    yield* httpSaga(ORGANIZATION_FETCH, call(get, organizationService, organizationId), {
      schema: organizationSchema,
    });
  } catch (err) {
    yield put(error(ORGANIZATION_FETCH, err));
  } finally {
    yield put(doneIndicator(ORGANIZATION_FETCH));
  }
}

export function* watchOrganizationFetch() {
  yield takeLatest(ORGANIZATION_FETCH_INIT, organizationFetch);
}

export default function* root() {
  yield all([
    fork(watchOrganizationFetch),
  ]);
}
