import { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { fieldListSchema } from '../../schemas/field';

export default function getFieldSuggestions(state) {
  const field = state.get('field', Map());
  const currentChapter = state.get('currentChapter', Map());
  const suggestions = [];

  const result = currentChapter.getIn(['data', 'fields'], List());
  const fields = field.getIn(['data', 'items'], Map());

  const entities = {
    field: fields.toJS(),
  };

  const items = denormalize(result.toJS(), fieldListSchema, entities);

  items.forEach((item) => {
    suggestions.push({
      label: item.standardField.text,
      value: item.standardField.id,
    });
  });

  return suggestions;
}
