import { Map } from 'immutable';

// constants
import {
  HOMETOWN_GROUP_PNM_CREATE,
  HOMETOWN_GROUP_PNM_FETCH,
} from '../constants/hometownGroupPnmTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { add } from '../lib/normalizedReducerHelpers';

export default (state = Map(), action) => {
  switch (action.type) {
    case HOMETOWN_GROUP_PNM_CREATE: return httpReducer(state, action, { entity: 'hometownGroupPnm', cb: add(false) });
    case HOMETOWN_GROUP_PNM_FETCH: return httpReducer(state, action, { entity: 'hometownGroupPnm', updateResult: false });
    default: return state;
  }
};
