import { reduxForm, resetSection } from 'redux-form/immutable';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { Map } from 'immutable';

// components
import VoteForm from './VoteForm';

const createInitialValues = (props) => {
  const { currentRound } = props;

  const values = {
    round: currentRound,
  };

  return values;
};

const mapStateToProps = (state, props) => ({
  displayName:    true,
  currentChapter: state.get('currentChapter'),
  voteForm:       state.getIn(['form', 'voteForm'], Map()),
  initialValues:  createInitialValues(props),
});

const validateForm = function (form, props) {
  const { categories } = props;
  const schemaAttributes = [];

  categories.forEach((category) => {
    if (category.get('type') !== 'none') {
      const data = {
        vote: `${category.get('_id')}`,
      };

      schemaAttributes.push(data);
    }
  });

  if (schemaAttributes.length === 0) {
    schemaAttributes.push({
      vote: 'vote',
    });
  }

  const schema = {
    round: {
      presence: true,
    },
  };

  schemaAttributes.forEach((item) => {
    schema[item.vote] = {
      presence: true,
    };
  });

  schema.comment = {
    length: {
      maximum: 1000,
    },
  };

  if (props.teamLevel > 0) {
    schema.round = { presence: true };
  }

  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }, prevFields) {
  if (fields.get('round') !== prevFields.get('round')) {
    const [...keys] = fields.keys();
    dispatch(resetSection('voteForm', ...keys.filter(item => !['user', 'round'].includes(item))));
  }
  if (submitFailed) { stopSubmit(); }
};

const wrappedVoteForm = reduxForm({
  form:     'voteForm',
  validate: validateForm,
  onChange: handleChange,
})(VoteForm);

export default connect(mapStateToProps)(wrappedVoteForm);
