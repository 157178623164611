import { Map } from 'immutable';

// constants
import { EXPORT_CLEAR, EXPORT_CREATE } from '../constants/exportTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case EXPORT_CREATE: return httpReducer(state, action);
    case EXPORT_CLEAR: return Map();
    default: return state;
  }
};
