import {
  NOTE_FETCH_FOR_PNM_INIT,
  NOTE_FETCH_ALL_INIT,
  NOTE_DELETE_INIT,
  NOTE_CREATE_INIT,
  NOTE_CLEAR,
  NOTE_BULK_CREATE_INIT,
} from '../constants/noteTypes';

export function noteFetchForPnm({ pnmId, chapterId }) {
  return {
    type:    NOTE_FETCH_FOR_PNM_INIT,
    payload: {
      pnmId, chapterId,
    },
  };
}

export function noteFetchAll(params) {
  return {
    type:    NOTE_FETCH_ALL_INIT,
    payload: params,
  };
}

export function noteDelete(params) {
  return {
    type:    NOTE_DELETE_INIT,
    payload: params,
  };
}

export function noteCreate(params) {
  return {
    type:    NOTE_CREATE_INIT,
    payload: params,
  };
}

export function noteBulkCreate(params) {
  return {
    type:    NOTE_BULK_CREATE_INIT,
    payload: params,
  };
}

export function noteClear() {
  return { type: NOTE_CLEAR };
}
