import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Immutable, { Map } from 'immutable';

// MUI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';

// local components
import Tag from '../../../Tag';

const SlideCard = function ({
  classes,
  displayHometownGroup,
  displayMatches,
  displayNotes,
  displayStatus,
  displayTags,
  fieldsToDisplay,
  pnmLoading,
  matchLoading,
  noteLoading,
  pnm,
}) {
  const pnmName = `${pnm.get('firstname')} ${pnm.get('lastname')}`;
  const infoLoading = noteLoading || matchLoading || pnmLoading;
  const hasInfoToDisplay = displayHometownGroup
    || displayMatches
    || displayNotes
    || fieldsToDisplay.size
    || displayTags;

  const renderLoadingIndicator = () => (
    <Grid container justify='center' alignItems='center' className={ classes.loadingContainer }>
      <Grid item xs={ 12 }>
        <CircularProgress />
      </Grid>
    </Grid>
  );

  const getFieldValue = (item) => {
    const type = (item.get('standardField') || Map()).get('type');
    let optionsArray;
    let fieldValue;

    switch (type) {
      case 'text':
        fieldValue = item.get('text', 'No info found');
        break;
      case 'link':
        fieldValue = item.get('text') ? <a href={ item.get('text') } target='_blank' rel='noopener noreferrer'>Link</a> : '';
        break;
      case 'number':
        fieldValue = item.get('number', 'No info found');
        break;
      case 'select':
        optionsArray = (item.get('standardField') || Map()).get('options').split(',');
        fieldValue = optionsArray[item.get('selector', 'No info found')];
        break;
      default:
        break;
    }

    return fieldValue;
  };

  const renderPnmInfo = () => {
    let element;

    let itemsToDisplay = Immutable.List();

    if (displayHometownGroup) {
      itemsToDisplay = itemsToDisplay.push(Map({
        id:         'hometown',
        header:     'Hometown Group',
        hasContent: Boolean(pnm.get('hometownGroup')),
        content:    pnm.get('hometownGroup', 'No hometown group assigned'),
      }));
    }
    if (fieldsToDisplay.size) {
      pnm.get('standardFields', Immutable.List()).forEach((field) => {
        // disregard any fields that are not in the selected ids to display,
        //  fields from a prior pnm get may still be present, this includes duplicates
        const fieldId = field.getIn(['standardField', 'id']);
        const fieldIdsToDisplay = fieldsToDisplay.map(f => f.get('value'));

        const fieldAlreadyAdded = itemsToDisplay.map(item => item.get('id')).includes(fieldId);

        if (fieldIdsToDisplay.includes(fieldId) && !fieldAlreadyAdded) {
          itemsToDisplay = itemsToDisplay.push(Map({
            id:         fieldId,
            header:     field.getIn(['standardField', 'text'], 'Field Name'),
            hasContent: Boolean(field.get('text')),
            content:    getFieldValue(field),
          }));
        }
      });
    }

    if (displayMatches) {
      const matchedUsers = [];

      pnm.get('matches', Immutable.List()).forEach((match) => {
        const userName = `${match.getIn(['user', 'firstname'], 'User not found')} ${match.getIn(['user', 'lastname'], '')}`;

        if (!matchedUsers.includes(userName)) {
          matchedUsers.push(userName);
        }
      });

      itemsToDisplay = itemsToDisplay.push(Map({
        id:         'matches',
        header:     'Recommended Matches',
        hasContent: Boolean(matchedUsers.length),
        content:    matchedUsers.length ? matchedUsers.join(', ') : 'No matches found',
      }));
    }

    if (displayNotes) {
      const noteAuthors = [];

      pnm.get('notes', Immutable.List()).forEach((note) => {
        const authorName = `${note.getIn(['author', 'firstname'])} ${note.getIn(['author', 'lastname'])}`;

        if (!noteAuthors.includes(authorName)) {
          noteAuthors.push(authorName);
        }
      });

      itemsToDisplay = itemsToDisplay.push(Map({
        id:         'notes',
        header:     'Notes',
        hasContent: Boolean(noteAuthors.length),
        content:    noteAuthors.length ? noteAuthors.join(', ') : 'No notes found',
      }));
    }

    if (!itemsToDisplay.size) {
      return element;
    }

    if (infoLoading) {
      element = (
        <Grid item xs={ 12 }>
          <List className={ classes.infoContainer }>
            <ListSubheader disableSticky>
              PNM Info
            </ListSubheader>

            <ListItem className={ classes.loadingContainer }>
              { renderLoadingIndicator()}
            </ListItem>
          </List>
        </Grid>
      );
    } else {
      element = (
        <Fade in={ !infoLoading }>
          <Grid item xs={ 12 }>
            <List className={ classes.infoContainer }>
              { itemsToDisplay.map((item, index) => [
                <ListSubheader key={ `${item.get('id')}_header` } disableSticky>
                  {item.get('header')}
                </ListSubheader>,

                <ListItem key={ `${item.get('id')}_content` }
                  className={ itemsToDisplay.size - 1 === index
                    ? classes.infoItemContainer
                    : classNames(classes.infoItemContainer, classes.borderedItemContainer) }>
                  <Typography variant={ item.get('hasContent') ? 'subtitle1' : 'body2' }
                    color={ item.get('hasContent') ? 'inherit' : 'textSecondary' }>
                    {item.get('content') }
                  </Typography>
                </ListItem>,
              ])}
            </List>
          </Grid>
        </Fade>
      );
    }

    return element;
  };

  const renderTags = () => (

    <Grid item xs={ 12 }>
      <List className={ classes.infoContainer }>
        <ListSubheader disableSticky>Tags</ListSubheader>

        { !pnmLoading ? (
          <Fade in={ !pnmLoading }>
            <Grid item>
              <ListItem className={ classes.tagItemContainer }>
                { pnm.get('tags', Immutable.List()).size ? (

                  <Grid container spacing={ 24 }>
                    { pnm.get('tags', Immutable.List()).map((t = Map()) => (
                      <Tag key={ t.get('_id', 0) }
                        variant='default'
                        displayCount={ t.get('type') === 'chapter' }
                        type={ t.get('type', '') }
                        count={ t.get('count') || 0 }
                        title={ t.get('title', 'Tag') }
                        color={ t.get('color', 'red') } />
                    )) }
                  </Grid>
                ) : (
                  <Typography color='textSecondary'>No tags assigned</Typography>
                )}
              </ListItem>
            </Grid>
          </Fade>
        ) : (
          <ListItem className={ classes.infoItemContainer }>
            {renderLoadingIndicator()}
          </ListItem>
        )}
      </List>
    </Grid>
  );

  const renderSlide = () => {
    let element;

    if (hasInfoToDisplay) {
      element = [
        <Grid item xs={ 6 } key='left'>
          <Grid container alignItems='center' spacing={ 8 }>
            <Grid item xs={ pnm.get('status') ? 10 : 12 }>
              <Typography color='primary' className={ classes.slideTitle }>{pnmName}</Typography>
            </Grid>

            { displayStatus && (
              <Grid item xs={ 2 }>
                <Grid container justify='center' alignItems='center' className={ classes.statusContainer }>
                  <Grid item xs={ 10 }>
                    <Typography align='center' className={ classes.statusLabel }>{pnm.get('status')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            { pnm.get('image') && (
              <Grid item xs={ 12 }>
                <img src={ pnm.get('image') } alt='pnm' className={ classes.slideImageContent } />
              </Grid>
            )}

          </Grid>
        </Grid>,

        <Grid item xs={ 6 } key='right' className={ classes.rightContainer }>
          { displayTags && renderTags() }
          { renderPnmInfo() }
        </Grid>,
      ];
    } else {
      element = (
        <Grid item xs={ 12 }>
          <Grid container alignItems='center' justify='center' spacing={ 8 }>
            <Grid item xs={ 6 }>
              <Grid container>
                <Grid item xs={ pnm.get('status') ? 11 : 12 }>
                  <Typography color='primary' className={ classes.slideTitle }>{pnmName}</Typography>
                </Grid>

                { displayStatus && (
                  <Grid item xs={ 1 }>
                    <Grid container justify='center' alignItems='center' className={ classes.statusContainer }>
                      <Grid item xs={ 10 }>
                        <Typography align='center' className={ classes.statusLabel }>{pnm.get('status')}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            { pnm.get('image') && (
              <Grid item xs={ 12 } className={ classes.slideImageContentContainer }>
                <img src={ pnm.get('image') } alt='pnm' className={ classes.slideImageContentLarge } />
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }

    return element;
  };

  return (
    <Grid container
      spacing={ 8 }
      justify='center'>
      { pnm.get('id')
        ? renderSlide()
        : (
          <Grid item xs={ 12 }>
            <Typography color='secondary' className={ classes.slideTitle }>No Slide Found</Typography>
          </Grid>
        )}
    </Grid>
  );
};

SlideCard.propTypes = {
  classes:              PropTypes.instanceOf(Object).isRequired,
  pnm:                  PropTypes.instanceOf(Map).isRequired,
  displayHometownGroup: PropTypes.bool,
  displayMatches:       PropTypes.bool,
  displayNotes:         PropTypes.bool,
  displayStatus:        PropTypes.bool,
  displayTags:          PropTypes.bool,
  pnmLoading:           PropTypes.bool.isRequired,
  noteLoading:          PropTypes.bool.isRequired,
  matchLoading:         PropTypes.bool.isRequired,
  fieldsToDisplay:      PropTypes.instanceOf(Immutable.List),
};

SlideCard.defaultProps = {
  fieldsToDisplay:      Immutable.List(),
  displayHometownGroup: false,
  displayMatches:       false,
  displayNotes:         false,
  displayTags:          false,
  displayStatus:        false,
};

const styles = theme => ({
  rightContainer: {
    overflowY: 'scroll',
    height:    '90vh',
  },

  infoContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       10,
    padding:      '0 !important',
  },

  infoItemContainer: {
    padding: '5px 10px 10px 16px',
  },

  tagItemContainer: {
    padding: '5px 10px 20px 16px',
  },

  borderedItemContainer: {
    borderBottom: '1px solid #bfbfbf',
  },

  tagContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       10,
    padding:      '25px !important',
  },

  statusContainer: {
    borderRadius:    100,
    height:          65,
    width:           65,
    backgroundColor: theme.palette.primary.main,
  },

  statusLabel: {
    fontWeight: 700,
    fontSize:   28,
    color:      theme.palette.common.white,
  },

  slideTitle: {
    fontWeight: 800,
    fontSize:   55,
  },

  slideImageContent: {
    boxShadow:    '0 2px 15px 2px rgba(0,0,0,0.27)',
    borderRadius: 10,

    [theme.breakpoints.down('md')]: {
      height: '80%',
      width:  '80%',
    },

    [theme.breakpoints.only('lg')]: {
      height: '78%',
      width:  '78%',
    },

    [theme.breakpoints.only('xl')]: {
      height: '67%',
      width:  '67%',
    },

  },

  slideImageContentContainer: {
    textAlign: 'center',
  },

  slideImageContentLarge: {
    boxShadow:    '0 2px 15px 2px rgba(0,0,0,0.27)',
    borderRadius: 10,
    height:       '55%',
    width:        '55%',
  },

  loadingContainer: {
    textAlign: 'center',
  },

  tagsTitle: {
    marginBottom: 15,
  },

  bold: {
    fontWeight: 800,
  },
});

export default withStyles(styles)(SlideCard);
