import PropTypes from 'prop-types';
import React from 'react';

// components
import HeaderMenu from '../HeaderMenu';

import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';

import ProfileMenu from '../ProfileMenu';

// images
import logo from '../../../../assets/images/logo.png';

// styles
import './Header.scss';

const Header = function (props) {
  Header.propTypes = {
    openDrawer: PropTypes.func.isRequired,
  };

  const { openDrawer } = props;

  return (
    <AppBar color='default'>
      <Toolbar disableGutters>
        <Hidden implementation='css' lgUp>
          <IconButton className='app-header__menu-button' onClick={ openDrawer }>
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>

        <Hidden implementation='css' mdDown>
          <div className='app-header__logo-container'>
            <img className='app-header__logo' alt='MyVote' src={ logo } />
          </div>
        </Hidden>

        <HeaderMenu />

        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
