import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form/immutable';

// components
import ConcernCategoryItem from './ConcernCategoryItem';

const mapStateToProps = state => ({
  form:       state.get('form'),
  submitting: isSubmitting('concernCategoriesForm')(state),
});

export default connect(mapStateToProps)(ConcernCategoryItem);
