import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// components
import { withStyles } from '@material-ui/core/styles';

// styles
import spaceStyles from './space.style';

class Space extends PureComponent {
  static propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    type:    PropTypes.oneOf(['default', 'small', 'long05']),
  }

  static defaultProps = {
    type: 'default',
  }

  render() {
    const {
      classes,
      type,
    } = this.props;

    return (
      <span className={ `${classes.root} ${type}` } />
    );
  }
}

export default (withStyles(spaceStyles)(Space));
