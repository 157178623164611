import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import validate from 'validate.js';
import Immutable, { List, Map } from 'immutable';

// action creators
import {
  deleteMatch, fetchAllMatches, clearMatches, createMatch,
} from '../../../../actions/matchActions';
import { fetchUsersForChapter } from '../../../../actions/userActions';

// helpers
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getMatches from '../../../../lib/selectors/getMatches';
import getUsers from '../../../../lib/selectors/getUsers';
import getRounds from '../../../../lib/selectors/getRounds';
import getChapterAttribute from '../../../../lib/selectors/getChapterAttribute';

// components
import MatchTable from './MatchTable';

const createInitialValues = (state) => {
  const currentRoundId = getChapterAttribute('currentRound')(state);

  return Immutable.fromJS({
    round: currentRoundId,
  });
};

const mapStateToProps = state => ({
  currentChapterId:     getChapterAttribute('id')(state),
  currentRoundId:       getChapterAttribute('currentRound')(state),
  isCdSite:             getChapterAttribute('isCdSite')(state),
  initialValues:        createInitialValues(state),
  matchLoading:         getLoadingFromState('match')(state),
  matchTableFormValues: getFormValues('matchTableForm')(state) || Map(),
  matches:              getMatches(state) || List(),
  rounds:               getRounds(state),
  user:                 state.get('user'),
  userLoading:          getLoadingFromState('user')(state),
  users:                getUsers(state),
});

const mapDispatchToProps = {
  clearMatches,
  createMatch,
  deleteMatch,
  fetchAllMatches,
  fetchUsersForChapter,
};

const schema = {
  round: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:     'matchTableForm',
  validate: validateForm,
  onChange: handleChange,
})(MatchTable)));
