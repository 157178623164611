import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { patch } from '../../sources/api/client';
import inviteService from '../../sources/api/inviteService';

// constants
import { INVITE_UPDATE, INVITE_UPDATE_INIT } from '../../constants/inviteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { inviteSchema } from '../../schemas/invite';

export function* inviteUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { invite, ...restOfPayload } = action.payload || {};

  try {
    yield* httpSaga(INVITE_UPDATE, call(patch, inviteService, invite, { ...restOfPayload }), {
      schema: inviteSchema,
    });
  } catch (err) {
    yield put(error(INVITE_UPDATE, err));
  } finally {
    yield put(doneIndicator(INVITE_UPDATE));
  }
}

export function* watchInviteUpdate() {
  yield takeEvery(INVITE_UPDATE_INIT, inviteUpdate);
}

export default function* root() {
  yield all([
    fork(watchInviteUpdate),
  ]);
}
