import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import React, { useState } from 'react';
import { Field } from 'react-final-form';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import red from '@material-ui/core/colors/red';

// local components
import Button from '../../../../../Button';
import TextInput from '../../../../../TextInput';
import ActionModal from '../../../../../ActionModal';

const ConcernCategoryItem = function ({
  organizationConcernCategories,
  classes,
  field,
  handlePopoverOpen,
  index,
  loading,
  pageEnd,
  pageStart,
  removeConcernCategory,
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const concernCategoryItem = organizationConcernCategories.get(index, Map());

  const handleDelete = () => {
    const concernCategoryId = organizationConcernCategories.getIn([index, '_id']);
    removeConcernCategory(concernCategoryId);
    setDeleteModalOpen(false);
  };

  // Handles pagination filtering, without leaving items out of final-form
  //  Filtering before the Form component will prevent items from reordering
  if (index < pageStart || index > pageEnd - 1) {
    return null;
  }

  return (
    <Paper className={ classes.itemContainer }>
      <Grid container
        key={ concernCategoryItem.get('_id') || field }
        alignItems='center'>

        <Grid item xs={ 2 } align='left'>
          <Tooltip id='concernCategory-move-tip'
            placement='bottom'
            title='Click or drag to reorder'>

            <Fab color='primary' onClick={ event => handlePopoverOpen(event, index) }>
              <Icon>
                list
              </Icon>
            </Fab>
          </Tooltip>
        </Grid>

        <Grid item xs={ 4 }>
          <Field name={ `${field}.label` }
            label='Category Label'
            type='text'
            variant='outlined'
            margin='dense'
            component={ TextInput }
            fullWidth
            required />
        </Grid>
        <Grid item xs={ 6 } align='right' key='button' className={ classes.redButton }>
          <div>
            <Button variant='text' color='inherit' onClick={ () => setDeleteModalOpen(true) }>
              <Icon className={ classes.leftIcon }>delete</Icon>
              Delete
            </Button>
          </div>
        </Grid>
      </Grid>
      <ActionModal open={ deleteModalOpen }
        key='modal'
        description='This concern category will no longer appear for any user in PNM’s profiles.'
        onAccept={ () => handleDelete(index) }
        onReject={ () => setDeleteModalOpen(false) }
        acceptLabel='Delete'
        rejectLabel='Cancel'
        loading={ loading }
        title='Are you sure you want to delete this concern category?' />
    </Paper>
  );
};

const styles = theme => ({
  itemContainer: {
    padding:      15,
    borderRadius: 10,
  },

  redButton: {
    color: red[500],
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

ConcernCategoryItem.propTypes = {
  organizationConcernCategories: PropTypes.instanceOf(List).isRequired,
  classes:                       PropTypes.instanceOf(Object).isRequired,
  field:                         PropTypes.string.isRequired,
  handlePopoverOpen:             PropTypes.func.isRequired,
  index:                         PropTypes.number.isRequired,
  loading:                       PropTypes.bool.isRequired,
  pageEnd:                       PropTypes.number.isRequired,
  pageStart:                     PropTypes.number.isRequired,
  removeConcernCategory:         PropTypes.func.isRequired,
};

export default withStyles(styles)(ConcernCategoryItem);
