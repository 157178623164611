import {
  PNM_FETCH_ALL_INIT,
  PNM_FETCH_ALL_FOR_ANONYMOUS_INIT,
  PNM_FETCH_INIT,
  PNM_CLEAR,
  PNM_BULK_UPDATE_INIT,
  PNM_UPDATE_INIT,
  PNM_CREATE_INIT,
  PNM_DELETE_INIT,
  PNM_DELETE_ALL_FOR_CHAPTER_INIT,
  PNM_UPDATE_ALL_FOR_CHAPTER_INIT,
  DISABLE_DISPLAY_ACTIVE_LIST,
  ENABLE_DISPLAY_ACTIVE_LIST,
} from '../constants/pnmTypes';

export function fetchAllPnms({
  active,
  activeNotRecruited,
  chapter,
  event,
  filter,
  infiniteScroll = false,
  round,
  search,
  skip,
  sort,
} = {}) {
  return {
    type: PNM_FETCH_ALL_INIT,

    payload: {
      active,
      activeNotRecruited,
      chapter,
      event,
      filter,
      infiniteScroll,
      round,
      search,
      skip,
      sort,
    },
  };
}

export function fetchAllPnmsForAnonymous({
  skip, infiniteScroll = false, search, sort, chapter, filter,
} = {}) {
  return {
    type: PNM_FETCH_ALL_FOR_ANONYMOUS_INIT,

    payload: {
      search,
      sort,
      filter,
      skip,
      infiniteScroll,
      chapter,
    },
  };
}

export function pnmFetch(params) {
  return {
    type:    PNM_FETCH_INIT,
    payload: params,
  };
}

export function disableDisplayActiveList() {
  return {
    type: DISABLE_DISPLAY_ACTIVE_LIST,
  };
}

export function enableDisplayActiveList() {
  return {
    type: ENABLE_DISPLAY_ACTIVE_LIST,
  };
}

export function pnmClear() {
  return {
    type: PNM_CLEAR,
  };
}

export function pnmBulkUpdate(params) {
  return {
    type:    PNM_BULK_UPDATE_INIT,
    payload: params,
  };
}

export function pnmUpdateAllForChapter(params) {
  return {
    type:    PNM_UPDATE_ALL_FOR_CHAPTER_INIT,
    payload: params,
  };
}

export function pnmUpdate(params) {
  return {
    type:    PNM_UPDATE_INIT,
    payload: params,
  };
}

export function pnmCreate(params) {
  return {
    type:    PNM_CREATE_INIT,
    payload: params,
  };
}

export function pnmDelete(params) {
  return {
    type:    PNM_DELETE_INIT,
    payload: params,
  };
}

export function pnmDeleteAllForChapter(params) {
  return {
    type:    PNM_DELETE_ALL_FOR_CHAPTER_INIT,
    payload: params,
  };
}
