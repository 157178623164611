import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import ReactAutoSuggest from 'react-autosuggest';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

class Autosuggest extends Component {
  static propTypes = {
    classes:                     PropTypes.instanceOf(Object).isRequired,
    label:                       PropTypes.string,
    helperText:                  PropTypes.string,
    input:                       PropTypes.instanceOf(Object).isRequired,
    suggestions:                 PropTypes.instanceOf(Array).isRequired,
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    onSuggestionsClearRequested: PropTypes.func.isRequired,
    defaultSuggestion:           PropTypes.instanceOf(Object),
    loading:                     PropTypes.bool,
    variant:                     PropTypes.string,
    relativeBox:                 PropTypes.bool,
  }

  static defaultProps = {
    label:             '',
    helperText:        '',
    defaultSuggestion: null,
    loading:           false,
    variant:           'standard',
    relativeBox:       false,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Map({
        displayValue: '',
        formValue:    {},
      }),
    };
  }

  componentWillMount() {
    const { input: { onChange } } = this.props;

    const defaultSuggestion = this.props.defaultSuggestion || {};
    const { label, value } = defaultSuggestion;

    if (label && value) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('displayValue', label);
          map.set('formValue', defaultSuggestion);
        }),
      });

      onChange(defaultSuggestion);
    }
  }

  getSuggestionValue = ({ label }) => label;

  handleChange = (event, { newValue, method }) => {
    if (method === 'type') {
      this.setState({ data: this.state.data.set('displayValue', newValue) });
    }
  }

  handleBlur = (e, ...rest) => {
    const formValue = this.state.data.get('formValue') || {};

    if (e.target.getAttribute('type') === 'text' && formValue.label && formValue.value) {
      const newEvent = e;

      newEvent.target.value = formValue;

      this.setState({ data: this.state.data.set('displayValue', formValue.label) });
    } else {
      const { input : { onChange } } = this.props;

      onChange(e, ...rest);
    }
  }

  handleFocus = (...params) => {
    const { input: { onFocus } } = this.props;

    this.setState({ data: this.state.data.set('displayValue', '') });

    onFocus(...params);
  }

  handleSuggestionSelected = (event, { suggestion }) => {
    const { input: { onChange } } = this.props;

    onChange(suggestion);

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('displayValue', suggestion.label);
        map.set('formValue', suggestion);
      }),
    });
  }

  renderSuggestion = ({ label }, { isHighlighted }) => (
    <MenuItem selected={ isHighlighted } component='div'>
      <Typography>{ label }</Typography>
    </MenuItem>
  )

  renderInput = ({
    classes, label, helperText, ref, variant, ...other
  }) => (
    <FormControl className={ classes.formControl }>
      <TextField inputRef={ ref }
        label={ label }
        margin='normal'
        variant={ variant }
        helperText={ helperText }
        InputProps={ {
          startAdornment: (
            <InputAdornment position='start'>
              <Icon>search</Icon>
            </InputAdornment>
          ),
          endAdornment: this.renderLoadingIndicator(),
          ...other,
        } } />
    </FormControl>
  )

  renderSuggestionsContainer = ({ containerProps, children }) => (
    <Paper square { ...containerProps }>
      { children }
    </Paper>
  )

  renderLoadingIndicator() {
    const { classes, loading } = this.props;

    let element;

    if (loading) {
      element = <CircularProgress className={ classes.loadingIndicator } size={ 20 } />;
    }

    return element;
  }

  render() {
    const {
      classes,
      helperText,
      label,
      onSuggestionsClearRequested,
      onSuggestionsFetchRequested,
      suggestions,
      variant,
      relativeBox,
      input: {
        onChange, onBlur, onFocus, value, ...restOfInput
      },
    } = this.props;

    const theme = {
      container:                classes.container,
      suggestionsContainerOpen: `${classes.suggestionsContainerOpen}${relativeBox ? ' relativeBox' : ''}`,
      suggestionsList:          classes.suggestionsList,
      suggestion:               classes.suggestion,
    };

    const inputProps = {
      classes,
      helperText,
      label,
      onBlur:   this.handleBlur,
      onChange: this.handleChange,
      onFocus:  this.handleFocus,
      value:    this.state.data.get('displayValue'),
      variant,
      ...restOfInput,
    };

    return (
      <div className={ classes.autoSuggestContainer }>
        <ReactAutoSuggest suggestions={ suggestions }
          focusInputOnSuggestionClick={ false }
          theme={ theme }
          onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
          onSuggestionsClearRequested={ onSuggestionsClearRequested }
          getSuggestionValue={ this.getSuggestionValue }
          renderSuggestion={ this.renderSuggestion }
          onSuggestionSelected={ this.handleSuggestionSelected }
          inputProps={ inputProps }
          renderInputComponent={ this.renderInput }
          renderSuggestionsContainer={ this.renderSuggestionsContainer } />
      </div>
    );
  }
}

const styles = theme => ({
  autoSuggestContainer: {
    display:    'flex',
    alignItems: 'center',
  },

  loadingIndicator: {
    position: 'absolute',
    right:    25,
  },

  container: {
    flexGrow: 1,
    position: 'relative',
  },

  formControl: {
    width: '100%',
  },

  suggestionsContainerOpen: {
    position:        'absolute',
    marginTop:       theme.spacing.unit * 1,
    marginBottom:    theme.spacing.unit * 3,
    left:            0,
    right:           0,
    zIndex:          100,
    '&.relativeBox': {
      position:  'relative',
      maxHeight: 190,
      overflowY: 'scroll',
    },
  },

  suggestion: {
    display: 'block',
  },

  suggestionsList: {
    margin:        0,
    padding:       0,
    listStyleType: 'none',
  },
});

export default withStyles(styles)(Autosuggest);
