import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ReactAutoSuggest from 'react-autosuggest';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

// icons

// components
import TextInput from '../TextInput';

const AutoSuggestInput = function ({
  autoSuggestType,
  circularProgressProps,
  classes,
  defaultSuggestion = {},
  displayAllSuggestionsOnFocus,
  handleClearSelected: handleClearSelectedFromProps,
  handleSelectAll,
  handleAddItemClick,
  helperText,
  input: {
    onChange, onBlur, onFocus, value, ...restOfInput
  },
  label,
  loading,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  placeholder,
  suggestions,
  suggestionsCardType,
  variant,
  ...restOfProps
}) {
  const [displayValue, setDisplayValue] = useState('');

  const [formValue, setFormValue] = useState(Map());
  const [addItemSelected, setAddItemSelected] = useState(false);

  useEffect(() => {
    const { label, value } = defaultSuggestion; // eslint-disable-line

    if (label && value) {
      setDisplayValue(label);
      setFormValue(defaultSuggestion);
      onChange(defaultSuggestion);
    }
  }, [defaultSuggestion, onChange]);

  const getSuggestionValue = () => label;

  const handleChange = (event, { newValue, method }) => {
    if (method === 'type') {
      setDisplayValue(newValue);
      setFormValue(newValue);
    }
  };

  const handleBlur = (event) => {
    const { label } = formValue; // eslint-disable-line

    // added this condition because the event target was coming across null at first
    if (event.target) {
      if (event.target.getAttribute('type') === 'text' && label) {
        const newEvent = event;
        newEvent.target.value = formValue;

        setDisplayValue(label);
        setFormValue(formValue);
      } else {
        setFormValue('');
        setDisplayValue('');
      }
    }
  };

  const handleFocus = (...params) => {
    setAddItemSelected(false);
    onFocus(params);
  };

  const handleClearSelected = () => {
    setDisplayValue('');
    setFormValue('');
    onChange(null);
  };

  const shouldRenderSuggestions = () => {
    if (addItemSelected) {
      return false;
    } if (!displayValue && displayAllSuggestionsOnFocus) {
      // displays all suggestions on initial focus
      return true;
    } if (displayValue.length > 0) {
      // displays suggestions once character has been typed
      return true;
    }
    return false;
  };

  const onAddItem = () => {
    setAddItemSelected(true);
    const fieldId = handleAddItemClick(displayValue);
    const newSuggestion = { label: displayValue, value: fieldId };

    // Set the form's value to that of the newly created field
    setFormValue(newSuggestion);

    onChange(newSuggestion);
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    // Don't allow error message results to be selected
    if (suggestion.value === 'noneFound') {
      return;
    }

    if (suggestion.value === 'addItem') {
      onAddItem(); // create the field and set the form value
    } else {
      setDisplayValue(suggestion.label);

      setFormValue(suggestion);
      onChange(suggestion);
    }
  };

  const renderSuggestion = ({ label, value }, { isHighlighted }) => ( // eslint-disable-line
    <MenuItem id='menuItem' selected={ isHighlighted } component='div'>
      <Typography>{label}</Typography>
    </MenuItem>
  );

  const renderInput = (inputProps) => {
    const {
      label, helperText, ref, variant, ...restOfInput // eslint-disable-line
    } = inputProps;

    restOfProps.InputProps = { // eslint-disable-line
      startAdornment: (
        <InputAdornment position='start'>
          <Icon>search</Icon>
        </InputAdornment>
      ),
      endAdornment: loading ? (
        renderLoadingIndicator() // eslint-disable-line
      ) : (
        <InputAdornment position='end'>
          <Zoom in={ Boolean(value) && Boolean(displayValue) }>
            <IconButton onClick={ () => handleClearSelected() }>
              <Icon>clear</Icon>
            </IconButton>
          </Zoom>
        </InputAdornment>
      ),
      ...restOfProps.InputProps,
    };

    return (
      <FormControl className={ classes.formControl }>
        <TextInput placeholder={ placeholder }
          label={ label }
          margin='normal'
          variant={ variant }
          helperText={ helperText }
          inputRef={ ref }
          InputProps={ restOfProps.InputProps }
          { ...restOfInput }
          { ...restOfProps } />
      </FormControl>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <Paper classes={ { root: children ? '' : classes.suggestionsCardClosed } }
      { ...containerProps }>
      {children}

      {children && <div className={ classes.bottomDivider } />}
    </Paper>
  );

  const renderLoadingIndicator = () => (
    <CircularProgress className={ classes.loadingIndicator }
      size={ 20 }
      { ...circularProgressProps } />
  );

  const theme = {
    container:            classes.container,
    suggestionsContainer:
      suggestionsCardType === 'popup'
        ? classes.suggestionsCardPopup
        : classes.suggestionsCardExpand,
    suggestionsList: classes.suggestionsList,
    suggestion:      classes.suggestion,
  };

  const inputProps = {
    helperText,
    label,
    onBlur:   handleBlur,
    onChange: handleChange,
    onFocus:  handleFocus,
    value:    displayValue,
    variant,
    ...restOfInput,
  };

  const newSuggestions = suggestions.length
    ? suggestions
    : [{ label: 'No results found.', value: 'noneFound' }];

  return (
    <div className={ classes.autoSuggestContainer }>
      <ReactAutoSuggest focusInputOnSuggestionClick={ false }
        getSuggestionValue={ getSuggestionValue }
        highlightFirstSuggestion
        shouldRenderSuggestions={ shouldRenderSuggestions }
        inputProps={ inputProps }
        onSuggestionSelected={ handleSuggestionSelected }
        onSuggestionsClearRequested={ onSuggestionsClearRequested }
        onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
        renderInputComponent={ renderInput }
        renderSuggestion={ renderSuggestion }
        renderSuggestionsContainer={ renderSuggestionsContainer }
        suggestions={ newSuggestions }
        theme={ theme } />
    </div>
  );
};

AutoSuggestInput.propTypes = {
  autoSuggestType:              PropTypes.oneOf(['fields', 'default']),
  circularProgressProps:        PropTypes.instanceOf(Object),
  classes:                      PropTypes.instanceOf(Object).isRequired,
  children:                     PropTypes.instanceOf(Array), // eslint-disable-line
  containerProps:               PropTypes.instanceOf(Object), // eslint-disable-line
  defaultSuggestion:            PropTypes.instanceOf(Object),
  displayAllSuggestionsOnFocus: PropTypes.bool,
  handleClearSelected:          PropTypes.func, // eslint-disable-line
  handleSelectAll:              PropTypes.func, // eslint-disable-line
  handleAddItemClick:           PropTypes.func,
  helperText:                   PropTypes.string,
  input:                        PropTypes.instanceOf(Object).isRequired,
  label:                        PropTypes.string,
  loading:                      PropTypes.bool,
  onSuggestionsClearRequested:  PropTypes.func.isRequired,
  onSuggestionsFetchRequested:  PropTypes.func.isRequired,
  placeholder:                  PropTypes.string,
  suggestions:                  PropTypes.instanceOf(Array).isRequired,
  suggestionsCardType:          PropTypes.oneOf(['expand', 'popup']),
  variant:                      PropTypes.string,
};

AutoSuggestInput.defaultProps = {
  autoSuggestType:              'default',
  circularProgressProps:        {},
  defaultSuggestion:            null,
  displayAllSuggestionsOnFocus: true,
  handleAddItemClick:           () => {},
  helperText:                   '',
  label:                        '',
  loading:                      false,
  placeholder:                  '',
  suggestionsCardType:          'popup',
  variant:                      'standard',
};

const styles = theme => ({
  autoSuggestContainer: {
    display:    'flex',
    alignItems: 'center',
  },

  addItem: {
    borderTop: `1px solid ${theme.palette.background.lightPaper}`,
  },

  bottomDivider: {
    backgroundColor:         theme.palette.background.lightPaper,
    height:                  10,
    position:                'sticky',
    bottom:                  0,
    top:                     165,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius:  4,
  },

  loadingIndicator: {
    position: 'absolute',
    right:    25,
  },

  container: {
    flexGrow: 1,
    position: 'relative',
  },

  formControl: {
    width: '100%',
  },

  suggestionsCardPopup: {
    position:        'absolute',
    marginTop:       theme.spacing.unit * (1),
    marginBottom:    theme.spacing.unit * (3),
    minHeight:       175,
    maxHeight:       175,
    left:            0,
    right:           0,
    zIndex:          100,
    overflowY:       'scroll',
    transition:      'all 0.4s ease',
    backgroundColor: theme.palette.background.default,
  },

  suggestionsCardExpand: {
    position:        'relative',
    marginTop:       theme.spacing.unit * (1),
    marginBottom:    theme.spacing.unit * (3),
    left:            0,
    right:           0,
    zIndex:          100,
    transition:      'all 0.4s ease',
    backgroundColor: theme.palette.background.default,
  },

  suggestionsCardClosed: {
    // transition: 'all 0.4s ease',
    margin:          0,
    minHeight:       0,
    backgroundColor: theme.palette.background.default,
  },

  suggestion: {
    display: 'block',
  },

  suggestionsList: {
    margin:        0,
    padding:       0,
    listStyleType: 'none',
  },
});

export default withStyles(styles)(AutoSuggestInput);
