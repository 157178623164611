import client from './client';

export default (function ExportsService() {
  let service;

  if (!service) {
    service = client.service('exports');
  }

  return service;
}());
