import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const SelectAllListItem = function ({
  shouldResetList, isChecked, handleCheckboxChange, classes, reinitializeList,
}) {
  useEffect(() => {
    if (shouldResetList) {
      reinitializeList();
    }
  }, [shouldResetList]);

  return (
    <ListItem className={ classes.listItem }>
      <ListItemAvatar>
        <Avatar className={ classes.avatar }>
          <Checkbox checked={ isChecked }
            className={ classes.checkbox }
            color='primary'
            onChange={ event => handleCheckboxChange(event.target.checked) } />
        </Avatar>
      </ListItemAvatar>

      <ListItemText className={ classes.listItemText }
        primary={ (
          <Typography variant='subtitle2' noWrap>
            { isChecked ? 'Deselect All' : 'Select All'}
          </Typography>
        ) }
        disableTypography />
    </ListItem>
  );
};

SelectAllListItem.propTypes = {
  classes:              PropTypes.instanceOf(Object).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  isChecked:            PropTypes.bool.isRequired,
  reinitializeList:     PropTypes.func.isRequired,
  shouldResetList:      PropTypes.bool.isRequired,
};

const styles = theme => ({
  listItem: {
    backgroundColor: '#ddd',
  },

  listItemText: {
    marginRight:  5,
    overflow:     'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:   'nowrap',
  },

  avatar: {
    width:           40,
    height:          40,
    borderRadius:    100,
    backgroundColor: '#bdbdbd',
  },

  initials: {
    color:    theme.palette.common.white,
    fontSize: '1.25rem',
  },

  checkbox: {
    backgroundColor: theme.palette.common.white,
    '&:hover':       {
      backgroundColor: '#bdbdbd',
    },
  },

  matchIcon: {
    left:     2,
    bottom:   3,
    position: 'absolute',
    fontSize: 19,
  },

  checkIcon: {
    left:     0,
    position: 'absolute',
    top:      0,
  },
});

export default withStyles(styles)(SelectAllListItem);
