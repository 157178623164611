import { Map } from 'immutable';

// constants
import {
  HOMETOWN_GROUP_CREATE,
  HOMETOWN_GROUP_REMOVE,
  HOMETOWN_GROUP_BULK_UPDATE,
} from '../constants/hometownGroupTypes';

import { CURRENT_CHAPTER_FETCH } from '../constants/chapterTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { add, remove } from '../lib/normalizedReducerHelpers';

export default (state = Map(), action) => {
  switch (action.type) {
    case HOMETOWN_GROUP_BULK_UPDATE: return httpReducer(state, action, { entity: 'hometownGroup', updateResult: false });
    case HOMETOWN_GROUP_CREATE: return httpReducer(state, action, { entity: 'hometownGroup', cb: add(false) });
    case HOMETOWN_GROUP_REMOVE: return httpReducer(state, action, { entity: 'hometownGroup', cb: remove(false) });
    case CURRENT_CHAPTER_FETCH: return httpReducer(state, action, { entity: 'hometownGroup', updateResult: false });
    default: return state;
  }
};
