import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import validate from 'validate.js';
import deepEqual from 'fast-deep-equal';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import DraggableList from '../../../../../DraggableList';
import SliderPopover from '../../../../../SliderPopover';
import Savebar from '../../../../../Savebar';
import ConcernCategoryItem from '../ConcernCategoryItem';

const ConcernCategoriesForm = function ({
  // bulkCreateConcernCategories,
  bulkUpdateConcernCategories,
  organizationConcernCategories,
  currentChapter,
  concernCategoryLoading,
  concernCategoryStatus,
  concernCategoriesFormSubmitFailed,
  concernCategoriesFormSubmitSucceeded,
  concernCategoriesFormSubmitting,
  initialValues,
  pageEnd,
  pageStart,
  removeConcernCategory,
}) {
  const [savebarMessage, setSavebarMessage] = useState('Save your changes?');
  const [hideButtons, setHideButtons] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [concernCategoriesSize, setConcernCategoriesSize] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedConcernCategoryIndex, setSelectedConcernCategoryIndex] = useState(0);

  useEffect(() => {
    if (concernCategoriesFormSubmitSucceeded) {
      setSnackbarOpen(true);
      setSnackbarMessage('Saved successfully');
    } else if (concernCategoriesFormSubmitFailed) {
      setSnackbarOpen(true);
      setSnackbarMessage('Save failed');
    } else {
      setSavebarMessage('Save your changes?');
      setHideButtons(false);
      setAutoHideDuration(null);
    }
  }, [concernCategoriesFormSubmitSucceeded, concernCategoriesFormSubmitFailed]);

  useEffect(() => {
    if (
      concernCategoriesSize === organizationConcernCategories.size - 1 && concernCategoriesSize > 0
    ) {
      if (concernCategoryStatus === 'success') {
        setSnackbarOpen(true);
        setSnackbarMessage('Removed Successfully');
      } else if (concernCategoryStatus === 'error') {
        setSnackbarOpen(true);
        setSnackbarMessage('Remove Failed');
      }
    }

    setConcernCategoriesSize(organizationConcernCategories.size);
  }, [organizationConcernCategories.size, concernCategoryStatus]);

  const handleSave = (values) => {
    bulkUpdateConcernCategories(
      values.concernCategories,
    );
  };

  const handleCancel = (reset) => {
    setSnackbarOpen(true);
    setSnackbarMessage('Changes reset.');
    setAutoHideDuration(2000);

    reset();
  };

  const handlePopoverOpen = (event, index) => {
    setSelectedConcernCategoryIndex(index);
    setPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  const handleChangeOrder = (fromPosition, items, toPosition, concernCategories) => {
    // const newPnms = Immutable.fromJS(reorder(pnms, oldRank, newRank - 1));
    concernCategories.move(fromPosition, toPosition - 1);

    setPopoverOpen(false);
    setAnchorEl(null);
  };

  const schema = {};
  const selectedConcernCategory = organizationConcernCategories.get(
    selectedConcernCategoryIndex, Map()
  );
  const selectedConcernCategoryName = selectedConcernCategory.getIn(
    ['standardConcernCategory', 'text'], 'selected concernCategory'
  );
  const popoverLabel = selectedConcernCategoryName.length < 16 ? selectedConcernCategoryName : 'selected concernCategory';

  return (
    <Form id='concernCategoriesForm'
      onSubmit={ handleSave }
      mutators={ { ...arrayMutators } }
      initialValues={ initialValues.toJS() }
      initialValuesEqual={ deepEqual }
      keepDirtyOnReinitialize={ concernCategoriesFormSubmitting }
      validate={ values => validate(values, schema) }
      render={ ({
        handleSubmit, invalid, values = [], form: { reset }, dirty,
      }) => (
        <Grid container spacing={ 8 } component='form' onSubmit={ handleSubmit }>

          <FieldArray name='concernCategories'>
            {({ fields }) => [
              <DraggableList items={ organizationConcernCategories }
                key='list'
                fields={ fields }
                listItem={ (
                  <ConcernCategoryItem { ...{
                    currentChapter,
                    organizationConcernCategories: Immutable.fromJS(values.concernCategories),
                    loading:                       concernCategoryLoading,
                    removeConcernCategory,
                    handlePopoverOpen,
                    pageEnd,
                    pageStart,
                    values:                        Immutable.fromJS(values),
                  } } />
                  ) } />,
              <SliderPopover anchorEl={ anchorEl }
                defaultValue={ selectedConcernCategoryIndex + 1 }
                key='popover'
                fields={ fields }
                handlePopoverClose={ handlePopoverClose }
                handleSubmit={ handleChangeOrder }
                item={ selectedConcernCategory }
                items={ Immutable.fromJS(values.concernCategories) }
                label={ popoverLabel }
                size={ organizationConcernCategories.size }
                popoverOpen={ popoverOpen } />,

            ]}
          </FieldArray>

          <Savebar open={ dirty
              && !concernCategoriesFormSubmitSucceeded
              && !concernCategoriesFormSubmitting }
            autoHideDuration={ autoHideDuration }
            form={ Map({ syncErrors: invalid, loading: concernCategoryLoading }) }
            handleAccept={ handleSubmit }
            handleReject={ () => handleCancel(reset) }
            hideButtons={ hideButtons }
            message={ savebarMessage } />

          <Snackbar key='remove'
            anchorOrigin={ {
              vertical:   'bottom',
              horizontal: 'right',
            } }
            open={ snackbarOpen }
            autoHideDuration={ 3000 }
            onClose={ () => setSnackbarOpen(false) }
            message={ snackbarMessage } />
        </Grid>
      ) } />
  );
};

ConcernCategoriesForm.propTypes = {
  pageStart:                            PropTypes.number.isRequired,
  pageEnd:                              PropTypes.number.isRequired,
  bulkCreateConcernCategories:          PropTypes.func.isRequired,
  bulkUpdateConcernCategories:          PropTypes.func.isRequired,
  currentChapter:                       PropTypes.instanceOf(Map).isRequired,
  initialValues:                        PropTypes.instanceOf(Map).isRequired,
  organizationConcernCategories:        PropTypes.instanceOf(List).isRequired,
  removeConcernCategory:                PropTypes.func.isRequired,
  concernCategoriesFormSubmitSucceeded: PropTypes.bool.isRequired,
  concernCategoriesFormSubmitting:      PropTypes.bool.isRequired,
  concernCategoriesFormSubmitFailed:    PropTypes.bool.isRequired,
  concernCategoryLoading:               PropTypes.bool.isRequired,
  concernCategoryStatus:                PropTypes.string.isRequired,
};

export default ConcernCategoriesForm;
