import { Map } from 'immutable';

const isOverallScoreVisible = (currentChapter, organization) =>
  Map.isMap(organization)
  && Map.isMap(currentChapter)
  && (
    organization.get('overallScore', '') !== 'none'
    || (currentChapter.getIn(['data', 'cobMode']) && organization.get('displayOverallCob', ''))
  );

export default isOverallScoreVisible;
