import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';

import red from '@material-ui/core/colors/red';

// local components
import Button from '../Button';

class Savebar extends Component {
  static propTypes = {
    autoHideDuration: PropTypes.number,
    classes:          PropTypes.instanceOf(Object).isRequired,
    form:             PropTypes.instanceOf(Map).isRequired,
    handleAccept:     PropTypes.func.isRequired,
    handleReject:     PropTypes.func.isRequired,
    handleClose:      PropTypes.func,
    hideButtons:      PropTypes.bool.isRequired,
    open:             PropTypes.bool.isRequired,
    message:          PropTypes.string.isRequired,
  }

  static defaultProps = {
    autoHideDuration: null,
    handleClose:      () => {},
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        timeout: null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { autoHideDuration, handleClose } = nextProps;

    const timeout = this.state.data.get('timeout');

    if (autoHideDuration && !timeout) {
      this.setState({ data: this.state.data.set('timeout', autoHideDuration) });

      setTimeout(() => {
        handleClose(null, 'timeout');
        this.setState({ data: this.state.data.set('timeout', null) });
      }, autoHideDuration);
    }
  }

  renderButtons = () => {
    const {
      form, classes, hideButtons, handleReject, handleAccept,
    } = this.props;

    const disabled = Boolean(form.get('syncErrors'));

    let element;

    if (!hideButtons) {
      element = (
        <span>
          <Button variant='text'
            className={ classes.redButton }
            onClick={ handleReject }>

            Cancel
          </Button>

          <Button variant='text'
            type='submit'
            color='primary'
            loading={ form.get('submitting') }
            disabled={ disabled }
            onClick={ handleAccept }>

            Save
          </Button>
        </span>
      );
    }

    return element;
  }

  render() {
    const {
      handleClose,
      open,
      message,
    } = this.props;

    return (
      <Grid container spacing={ 8 }>
        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ open }
          onClose={ handleClose }
          ContentProps={ {
            'aria-describedby': 'save-message-id',
          } }
          message={ <span id='save-message-id'>{ message }</span> }
          action={ this.renderButtons() } />
      </Grid>
    );
  }
}

const style = () => ({
  redButton: {
    color: red[500],
  },
});

export default withStyles(style)(Savebar);
