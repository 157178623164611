import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// MUI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const Header = function ({
  classes, icon, title, subtitle, highlightedSubtitle,
}) {
  return (
    <Grid container
      spacing={ 16 }
      className={ classes.headerContainer }
      alignItems='center'
      id='headerContainer'>
      {icon && (
        <Grid item xs={ 3 } sm='auto' id='icon'>
          <Grid container
            alignItems='center'
            justify='center'
            className={ classes.iconContainer }>
            <Icon color='primary' fontSize='large'>{icon}</Icon>
          </Grid>
        </Grid>
      )}

      <Grid item
        xs={ 9 }
        sm='auto'
        className={ classes.titleContainer }
        id='headerTitleContainer'>
        <Grid container direction='column'>
          <Grid item>
            <Typography variant='h5' color='primary' className={ classes.bold }>
              {title}
            </Typography>
          </Grid>

          {subtitle && (
            <Grid item id='subtitle'>
              <Typography variant='subtitle1'
                color='textSecondary'
                className={ classNames(classes.semibold) }>
                {subtitle}
              </Typography>
            </Grid>
          )}

          {highlightedSubtitle && (
            <Grid item id='highlightedSubtitle'>
              <Typography variant='body2' color='secondary'>
                {highlightedSubtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  classes:             PropTypes.instanceOf(Object).isRequired,
  title:               PropTypes.string.isRequired,
  subtitle:            PropTypes.string,
  highlightedSubtitle: PropTypes.string,
  icon:                PropTypes.string,
};

Header.defaultProps = {
  icon:                '',
  subtitle:            '',
  highlightedSubtitle: '',
};

const styles = theme => ({
  headerContainer: {
    marginBottom: 15,
  },
  titleContainer: {
    paddingLeft: 20,

    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },

  iconContainer: {
    borderRadius:    100,
    height:          75,
    width:           75,
    backgroundColor: '#f1f1f1',
    textAlign:       'center',
  },
  bold: {
    fontWeight: 800,
  },
  semibold: {
    fontWeight: 600,
  },
});

export default withStyles(styles)(Header);
