import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Map, List } from 'immutable';
import { Field } from 'redux-form/immutable';

// mui components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

// local components
import Button from '../../../../../../../Button';
import SpecialtyButtons from '../SpecialtyButtons';

function SpecialtiesModal(props) {
  SpecialtiesModal.propTypes = {
    classes:             PropTypes.instanceOf(Object).isRequired,
    currentUserId:       PropTypes.number.isRequired,
    selectedItem:        PropTypes.instanceOf(Map).isRequired,
    dialogOpen:          PropTypes.bool.isRequired,
    fullScreen:          PropTypes.bool.isRequired,
    createUserSpecialty: PropTypes.func.isRequired,
    handleDialogClose:   PropTypes.func.isRequired,
    specialties:         PropTypes.instanceOf(List).isRequired,
    userSpecialties:     PropTypes.instanceOf(List).isRequired,
    userSpecialtiesForm: PropTypes.instanceOf(Map).isRequired,
  };

  const {
    classes,
    createUserSpecialty,
    currentUserId,
    dialogOpen,
    fullScreen,
    handleDialogClose,
    selectedItem,
    specialties,
    userSpecialties,
    userSpecialtiesForm,
  } = props;

  useEffect(() => {
    handleDialogClose();
  }, [userSpecialtiesForm.get('submitSucceeded')]);

  let disableButton = false;
  if (userSpecialtiesForm.get('syncErrors')) { disableButton = true; }
  if (!userSpecialtiesForm.getIn(['values', 'specialties'], List()).size) { disableButton = true; }

  const handleSubmit = () => {
    const values = [];
    const newUserSpecialties = userSpecialtiesForm.getIn(['values', 'specialties'], List());

    newUserSpecialties.forEach((specialty) => {
      values.push({
        specialty,
        user:   selectedItem.get('id'),
        author: currentUserId,
      });
    });

    if (!userSpecialtiesForm.get('syncErrors') && values.length) {
      createUserSpecialty({
        values,
      });
    }
    handleDialogClose();
  };

  const renderErrorMessage = () => {
    let element;

    if (userSpecialtiesForm.get('submitFailed')) {
      element = <FormHelperText error>Failed to assign specialty, try again.</FormHelperText>;
    }

    return element;
  };

  return (
    <Dialog open={ dialogOpen }
      onClose={ handleDialogClose }
      fullScreen={ fullScreen }
      maxWidth={ false }>

      <DialogContent classes={ { root: classes.dialogContentRoot } }>
        <Grid container spacing={ 24 } className={ classes.specialtyHeader }>
          <Grid item sm={ 12 } xs={ 11 }>
            <Typography variant='h6' align='center' color='inherit' gutterBottom>
              Assign A Specialty
            </Typography>
          </Grid>

          <Hidden smUp>
            <IconButton className={ classes.closeIcon } onClick={ handleDialogClose }>
              <Icon>close</Icon>
            </IconButton>
          </Hidden>
        </Grid>

        <Grid container justify='center' alignItems='center'>
          <Grid item md={ 8 } xs={ 11 }>
            <Field name='specialties'
              component={ SpecialtyButtons }
              userSpecialties={ userSpecialties }
              specialties={ specialties.sortBy(s => s.get('category')) } />
          </Grid>
        </Grid>

        <DialogActions className={ classes.dialogActionsRoot }>
          { renderErrorMessage(userSpecialtiesForm) }

          <Button onClick={ handleDialogClose } color='primary'>
            Cancel
          </Button>

          <Button variant='contained'
            onClick={ handleSubmit }
            loading={ userSpecialtiesForm.get('submitting') }
            disabled={ disableButton }
            color='primary'>

            <Icon className={ classes.leftIcon } color='inherit'>local_offer</Icon>
            Submit

          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

const styles = theme => ({
  dialogContentRoot: {
    minWidth:        '50vw',
    overflowX:       'hidden',
    overflowY:       'auto',
    padding:         0,
    '&:first-child': {
      paddingTop: 0,
    },

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  specialtyHeader: {
    backgroundColor: theme.palette.primary.main,
    paddingTop:      15,
    marginBottom:    15,
    color:           theme.palette.common.white,
  },

  closeIcon: {
    position: 'absolute',
    top:      10,
    right:    0,
    color:    theme.palette.common.white,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(
  withStyles(styles)(SpecialtiesModal)
);
