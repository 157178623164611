import { connect } from 'react-redux';

// action creators
import { inviteFetchAllForChapter } from '../../../../actions/inviteActions';
import { fetchUsersForChapter, userDelete, userBulkUpdate } from '../../../../actions/userActions';

// components
import Users from './Users';

const mapStateToProps = state => ({
  form:           state.get('form'),
  invite:         state.get('invite'),
  currentChapter: state.get('currentChapter'),
  user:           state.get('user'),
  chapter:        state.get('chapter'),
});

const mapDispatchToProps = dispatch => ({
  fetchInvitesForChapter: (id) => {
    dispatch(inviteFetchAllForChapter(id));
  },

  fetchUsersForChapter: (params) => {
    dispatch(fetchUsersForChapter(params));
  },

  deleteUser: (id, chapter) => {
    dispatch(userDelete(id, chapter));
  },

  bulkUpdateUsers: (items, chapter) => {
    dispatch(userBulkUpdate(items, chapter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
