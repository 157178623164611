import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import React, { Component } from 'react';

// components
import Button from '../../../Button';
import VoteModal from '../VoteModal';
import SingleBallotModal from '../SingleBallotModal';
import NoteTagModal from '../../../NoteTagModal';
import MatchModal from '../../../MatchModal';
import PnmPhotoModal from '../PnmPhotoModal';
import EditPnmModal from '../../../Layout/components/EditPnmModal';
import MembershipConcernModal from '../MembershipConcernModal';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';

// styles
import './PnmCard.scss';

// selectors
import getUserRole from '../../../../lib/selectors/getUserRole';

class PnmCard extends Component {
  static propTypes = {
    allowMemberToVote:                PropTypes.bool.isRequired,
    allowVotingMembersToTag:          PropTypes.bool.isRequired,
    classes:                          PropTypes.instanceOf(Object).isRequired,
    clearanceLevel:                   PropTypes.number.isRequired,
    clearVotes:                       PropTypes.func.isRequired,
    currentChapter:                   PropTypes.instanceOf(Map).isRequired,
    currentUser:                      PropTypes.instanceOf(Map).isRequired,
    currentRoundExists:               PropTypes.bool.isRequired,
    currentRoundName:                 PropTypes.string.isRequired,
    currentUserId:                    PropTypes.number.isRequired,
    disableList:                      PropTypes.func.isRequired,
    disableNotesFeature:              PropTypes.bool.isRequired,
    displayPnmInfoOnVote:             PropTypes.bool.isRequired,
    displaySingleBallot:              PropTypes.bool.isRequired,
    membershipConcernsFeatureEnabled: PropTypes.bool.isRequired,
    enableList:                       PropTypes.func.isRequired,
    fetchVotesForPnm:                 PropTypes.func.isRequired, // eslint-disable-line
    notes:                            PropTypes.instanceOf(List).isRequired,
    oneVotePerPnm:                    PropTypes.bool,
    oneMemberVotePerPnm:              PropTypes.bool,
    pnmData:                          PropTypes.instanceOf(Map).isRequired,
    pnmId:                            PropTypes.number.isRequired,
    pnmLoading:                       PropTypes.bool.isRequired,
    votes:                            PropTypes.instanceOf(List).isRequired,
    votingLimit:                      PropTypes.number.isRequired,
  };

  static defaultProps = {
    oneVotePerPnm:       false,
    oneMemberVotePerPnm: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        voteModalOpen:              false,
        noteTagModalOpen:           false,
        matchModalOpen:             false,
        editPnmModalOpen:           false,
        pnmPhotoModalOpen:          false,
        singleBallotModalOpen:      false,
        membershipConcernModalOpen: false,
      }),
    };
  }

  componentWillMount() {
    const {
      clearVotes,
      clearanceLevel,
      currentChapter,
      oneVotePerPnm,
      pnmData,
    } = this.props;

    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen'], false);
    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const voted = oneVotePerPnm ? pnmData.get('voted') : pnmData.get('votedThisRound');
    const allowResubmit = isVotingOpen && voted
      && (teamLevel === 0 && clearanceLevel === 0);
    if (currentChapter.get('data') && allowResubmit) {
      clearVotes();
      this.initVotes(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      clearVotes,
      clearanceLevel,
      oneVotePerPnm,
      pnmData,
      pnmId,
    } = this.props;

    const newPnmId = nextProps.pnmId;
    const { currentChapter } = nextProps;

    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen'], false);
    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const voted = oneVotePerPnm ? pnmData.get('voted') : pnmData.get('votedThisRound');
    const allowResubmit = isVotingOpen && voted
    && (teamLevel === 0 && clearanceLevel === 0);

    // Checks if PNM has changed, and pulls latest info for them if so
    if (pnmId !== newPnmId && currentChapter.get('data') && allowResubmit) {
      clearVotes();
      this.initVotes(nextProps);
    }
  }

  getVoteButtonLabel = () => {
    const {
      allowMemberToVote,
      clearanceLevel,
      currentChapter,
      currentUser,
      currentRoundExists,
      pnmData,
      oneVotePerPnm,
      oneMemberVotePerPnm,
      votingLimit,
    } = this.props;

    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen'], false);
    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const roundVoteCount = currentUser.getIn(['data', 'roundVoteCount'], 0);
    const isAdmin = teamLevel > 1 || clearanceLevel > 0;
    const isVotingMember = teamLevel === 0 && clearanceLevel === 0;

    const voted = oneVotePerPnm ? pnmData.get('voted') : pnmData.get('votedThisRound');
    const remainingVotes = votingLimit - roundVoteCount;

    if (isAdmin) {
      return 'Vote';
    } if (!isVotingOpen && isVotingMember) {
      return 'Voting Closed';
    } if (oneMemberVotePerPnm && !voted && pnmData.get('votesThisRound', List()).size > 0) {
      return 'Voted On By Another Member';
    } if (pnmData.get('votedThisRound') && !isAdmin) {
      return 'Resubmit Vote';
    } if (voted && oneVotePerPnm && isVotingMember) {
      return 'Voted';
    } if (!currentRoundExists) {
      return 'No Round Set';
    } if (votingLimit && roundVoteCount >= votingLimit) {
      return 'Vote Limit Reached';
    } if (votingLimit) {
      return `Vote (${remainingVotes} votes left)`;
    } if (!allowMemberToVote) {
      return 'Admin Only Voting';
    }
    return 'Vote';
  }

  initVotes = (props) => {
    const { currentChapter, fetchVotesForPnm, pnmId } = props;

    fetchVotesForPnm({
      pnm:     pnmId,
      chapter: currentChapter.get('data').get('id'),
    });
  }

  handleVoteModalOpen = () => {
    const { disableList, displayPnmInfoOnVote } = this.props;

    if (!displayPnmInfoOnVote) { disableList(); }
    this.setState({ data: this.state.data.set('voteModalOpen', true) });
  }

  handleVoteModalClose = () => {
    const { enableList, displayPnmInfoOnVote } = this.props;

    if (!displayPnmInfoOnVote) { enableList(); }
    this.setState({ data: this.state.data.set('voteModalOpen', false) });
  }

  handleSingleBallotModalOpen = () => {
    const { disableList, displayPnmInfoOnVote } = this.props;

    if (!displayPnmInfoOnVote) { disableList(); }
    this.setState({ data: this.state.data.set('singleBallotModalOpen', true) });
  }

  handleSingleBallotModalClose = () => {
    const { enableList, displayPnmInfoOnVote } = this.props;

    if (!displayPnmInfoOnVote) { enableList(); }
    this.setState({ data: this.state.data.set('singleBallotModalOpen', false) });
  }

  handleNoteTagModalOpen = () => {
    this.setState({ data: this.state.data.set('noteTagModalOpen', true) });
  }

  handleNoteTagModalClose = () => {
    this.setState({ data: this.state.data.set('noteTagModalOpen', false) });
  }

  handlemembershipConcernModalOpen = () => {
    this.setState({ data: this.state.data.set('membershipConcernModalOpen', true) });
  }

  handlemembershipConcernModalClose = () => {
    this.setState({ data: this.state.data.set('membershipConcernModalOpen', false) });
  }

  handleMatchModalOpen = () => {
    this.setState({ data: this.state.data.set('matchModalOpen', true) });
  }

  handleMatchModalClose = () => {
    this.setState({ data: this.state.data.set('matchModalOpen', false) });
  }

  handleOpenEditPnmModal = () => {
    this.setState({ data: this.state.data.set('editPnmModalOpen', true) });
  }

  handleCloseEditPnmModal = () => {
    this.setState({ data: this.state.data.set('editPnmModalOpen', false) });
  }

  handleOpenPnmPhotoModal = () => {
    this.setState({ data: this.state.data.set('pnmPhotoModalOpen', true) });
  }

  handleClosePnmPhotoModal = () => {
    this.setState({ data: this.state.data.set('pnmPhotoModalOpen', false) });
  }

  renderAvatar = () => {
    const { classes, pnmData = Map() } = this.props;

    const firstName = pnmData.get('firstname') || '';
    const lastName = pnmData.get('lastname') || '';
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

    let avatar = (
      <Grid container spacing={ 8 } justify='center'>
        <Grid item xs={ 12 }>
          <Avatar className={ classes.initialsAvatar }>
            <Typography variant='h1' color='primary'>{ initials }</Typography>
          </Avatar>
        </Grid>
      </Grid>

    );

    if (pnmData.get('image')) {
      avatar = (
        <Grid container spacing={ 8 } justify='center'>
          <Grid item xs={ 12 }>
            <Avatar className={ classes.avatar } src={ pnmData.get('image') } />
          </Grid>
        </Grid>
      );
    }

    return avatar;
  }

  renderNotesButton = () => {
    const {
      classes, clearanceLevel, currentChapter, currentUser, disableNotesFeature,
    } = this.props;

    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';
    const allowVotingMembersToTag = currentChapter.getIn(['data', 'allowVotingMembersToTag'], false);

    let element;
    let buttonText;
    let buttonIcon;

    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    const enableNotes = !disableNotesFeature || isAdmin;
    const enableTags = isAdmin || isConcernChair || allowVotingMembersToTag;

    if (enableNotes && enableTags) {
      buttonText = 'Write Note & Tag';
      buttonIcon = 'speaker_notes';
    } else if (enableNotes && !enableTags) {
      buttonText = 'Write Note';
      buttonIcon = 'speaker_notes';
    } else if (!enableNotes && enableTags) {
      buttonText = 'Tag';
      buttonIcon = 'local_offer';
    }

    if (buttonText) {
      element = (
        <Button className={ classes.pnmCardButton }
          color='contrast'
          onClick={ this.handleNoteTagModalOpen }
          fullWidth>

          <Icon className={ classes.leftIcon }>{ buttonIcon }</Icon>
          { buttonText }
        </Button>
      );
    }

    return element;
  }

  render() {
    const {
      allowMemberToVote,
      allowVotingMembersToTag,
      classes,
      clearanceLevel,
      currentChapter,
      currentUser,
      currentRoundExists,
      currentRoundName,
      currentUserId,
      disableNotesFeature,
      displaySingleBallot,
      membershipConcernsFeatureEnabled,
      notes,
      oneVotePerPnm,
      oneMemberVotePerPnm,
      pnmData = Map(),
      pnmId,
      pnmLoading,
      votingLimit,
      votes,
    } = this.props;

    const cobMode = currentChapter.getIn(['data', 'cobMode'], false);
    const currentChapterId = currentChapter.getIn(['data', 'id'], 0);
    const currentEvent = currentChapter.getIn(['data', 'currentEvent'], 0);
    const currentRound = currentChapter.getIn(['data', 'currentRound'], 0);
    const demoMode = currentChapter.getIn(['data', 'demoMode'], false);
    const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);
    const isVotingOpen = currentChapter.getIn(['data', 'isVotingOpen'], false);
    const showBestMatchedWith = currentChapter.getIn(['data', 'showBestMatchedWith'], false);
    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const roundVoteCount = currentUser.getIn(['data', 'roundVoteCount'], 0);

    const voteModalOpen = this.state.data.get('voteModalOpen');

    const noteTagModalOpen = this.state.data.get('noteTagModalOpen');
    const membershipConcernModalOpen = this.state.data.get('membershipConcernModalOpen');
    const matchModalOpen = this.state.data.get('matchModalOpen');
    const editPnmModalOpen = this.state.data.get('editPnmModalOpen');
    const pnmPhotoModalOpen = this.state.data.get('pnmPhotoModalOpen');
    const singleBallotModalOpen = this.state.data.get('singleBallotModalOpen');

    const councilId = isCdSite && !cobMode && !demoMode
      ? pnmData.get('group_pnm_id')
      : pnmData.get('leg_tech_pnm_id');

    const voted = oneVotePerPnm ? pnmData.get('voted') : pnmData.get('votedThisRound');
    const isAdmin = teamLevel > 1 || clearanceLevel > 0;
    const isVotingMember = teamLevel === 0 && clearanceLevel === 0;
    const allowEdit = isAdmin && !demoMode && (!isCdSite || (isCdSite && cobMode));
    const allowResubmit = isVotingOpen && voted && isVotingMember;

    let currentVote = Map();

    if (allowResubmit) {
      // Finds the previously casted vote via PNM and user data
      const pnmVotes = (pnmData.get('votes') || List()).size
        ? pnmData.get('votes') || List()
        : votes || List();

      currentVote = pnmVotes.find(v =>
        v.get('pnm') === pnmData.get('id')

        // Checks author
        && (v.get('author') === currentUserId
        || v.getIn(['author', 'id'], 0) === currentUserId)

        // Checks round
        && (v.get('round') === currentRound
        || v.getIn(['round', '_id'], 0) === currentRound)

        && v.get('event') === currentEvent) || Map();
    }

    const voteButtonDisabled = ((
      !isVotingOpen
      || !currentRoundExists
      || (oneMemberVotePerPnm && !voted && pnmData.get('votesThisRound', List()).size > 0)
      || (votingLimit && roundVoteCount >= votingLimit && !voted)
      || (voted && oneVotePerPnm && isVotingMember && !pnmData.get('votedThisRound'))
      || !allowMemberToVote
    ) && !isAdmin) || pnmLoading;

    return (
      <Card className={ classes.pnmCard }>

        { allowEdit
          && (
          <Grid container>
            <Grid item xs={ 2 }>
              <IconButton className='pnm__voting-status-icon' onClick={ this.handleOpenPnmPhotoModal }>
                <Icon className={ classes.whiteIcon }>add_a_photo</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={ 10 }>
              <IconButton className='pnm__voting-status-icon' onClick={ this.handleOpenEditPnmModal }>
                <Icon className={ classes.whiteIcon }>edit</Icon>
              </IconButton>
            </Grid>

          </Grid>
          )}

        { this.renderAvatar() }
        <Typography variant='h3' align='center' gutterBottom>{ `${pnmData.get('firstname')} ${pnmData.get('lastname')}` }</Typography>

        <span className='pnm__divider-line' />
        <Typography className={ classes.idText } color='inherit' align='center' gutterBottom>
          ID
          { councilId }
        </Typography>
        <span className='pnm__divider-line' />

        { isVotingOpen && (
          <Typography color='inherit' align='center' gutterBottom>
            Round:
            {' '}
            {currentRoundName}
          </Typography>
        )}

        <Button color='contrast'
          variant='contained'
          onClick={ displaySingleBallot
            ? () => this.handleSingleBallotModalOpen()
            : () => this.handleVoteModalOpen() }
          disabled={ voteButtonDisabled }
          fullWidth>

          <Icon className={ classes.leftIcon } color='primary'>thumbs_up_down</Icon>
          { this.getVoteButtonLabel() }
        </Button>

        { !displaySingleBallot && this.renderNotesButton() }

        {
          membershipConcernsFeatureEnabled
            && (
            <Button className={ classes.pnmCardButton }
              color='contrast'
              disabled={ pnmLoading }
              onClick={ this.handlemembershipConcernModalOpen }
              fullWidth>

              <Icon className={ classes.leftIcon }>info</Icon>
              Membership Concern
            </Button>
            )
        }

        { showBestMatchedWith && !displaySingleBallot
          && (
          <Button className={ classes.pnmCardButton }
            color='contrast'
            onClick={ this.handleMatchModalOpen }
            fullWidth>

            <Icon className={ classes.leftIcon }>extension</Icon>
            Recommend A Match
          </Button>
          )}

        <VoteModal clearanceLevel={ clearanceLevel }
          currentVote={ currentVote }
          dialogOpen={ voteModalOpen }
          handleDialogClose={ this.handleVoteModalClose }
          pnmId={ pnmId }
          pnm={ pnmData }
          teamLevel={ teamLevel } />

        <SingleBallotModal clearanceLevel={ clearanceLevel }
          currentVote={ currentVote }
          dialogOpen={ singleBallotModalOpen }
          handleDialogClose={ this.handleSingleBallotModalClose }
          pnmId={ pnmId }
          pnm={ pnmData }
          disableNotesFeature={ disableNotesFeature }
          allowVotingMembersToTag={ allowVotingMembersToTag }
          teamLevel={ teamLevel } />

        <NoteTagModal clearanceLevel={ clearanceLevel }
          currentChapter={ currentChapter }
          disableNotesFeature={ disableNotesFeature }
          allowVotingMembersToTag={ allowVotingMembersToTag }
          dialogOpen={ noteTagModalOpen }
          handleDialogClose={ this.handleNoteTagModalClose }
          notes={ notes }
          pnm={ pnmData }
          teamLevel={ teamLevel } />

        <MembershipConcernModal currentChapter={ currentChapter }
          dialogOpen={ membershipConcernModalOpen }
          handleDialogClose={ this.handlemembershipConcernModalClose }
          pnm={ pnmData } />

        <MatchModal dialogOpen={ matchModalOpen }
          handleDialogClose={ this.handleMatchModalClose }
          currentChapterId={ currentChapterId }
          pnmData={ pnmData } />

        <EditPnmModal open={ editPnmModalOpen }
          pnmData={ pnmData }
          snackbarSuccessMessage='PNM Updated Successfully.'
          onClose={ this.handleCloseEditPnmModal } />

        <PnmPhotoModal open={ pnmPhotoModalOpen }
          pnmData={ pnmData }
          snackbarSuccessMessage='PNM Updated Successfully.'
          onClose={ this.handleClosePnmPhotoModal } />

      </Card>
    );
  }
}

const styles = theme => ({
  pnmCard: {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.common.white,
    padding:         '20px',
    borderRadius:    10,
  },

  avatar: {
    width:  '200px',
    height: '200px',
    margin: 'auto auto 15px auto',
  },

  initialsAvatar: {
    width:           '200px',
    height:          '200px',
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.common.white,
  },

  whiteIcon: {
    color: '#fff',
  },

  idText: {
    display: 'inline-block',
    width:   '36%',
  },

  pnmCardButton: {
    margin:      theme.spacing.unit,
    marginLeft:  0,
    marginRight: 0,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(PnmCard);
