import Immutable, { List, Map } from 'immutable';

// constants
import {
  BUMP_GROUP_GENERATE,
  BUMP_GROUP_BULK_UPDATE,
  BUMP_GROUP_UPDATE,
  BUMP_GROUP_CREATE,
  BUMP_GROUP_REMOVE,
  BUMP_GROUP_FETCH_FOR_CHAPTER,
} from '../constants/bumpGroupTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { addToFront, remove } from '../lib/normalizedReducerHelpers';

function overwriteUsers(state, action) {
  // Get the data off the payload
  const { payload = {}, meta } = action;
  const { data = [] } = payload;

  const dataArray = data && Array.isArray(data)
    ? data
    : [data];

  // Get the old data, items, and result off of the state
  const oldData = state.get('data') || Map();
  let newData = oldData;

  if (!meta.loading) {
    let newItems = newData.get('items', List());

    // For each item on the payload, copy its users into the the new state
    dataArray.forEach((item) => {
      newItems = newItems.setIn([item._id, 'users'], Immutable.fromJS(item.users));
      newData = newData.set('items', newItems);
    });
  }

  return state.set('data', newData);
}

function updateGeneratedGroups(state, action) {
  // Get the data off the payload
  const { payload = {}, meta } = action;
  const { data = [] } = payload;

  // Get the old data, items, and result off of the state
  const oldData = state.get('data', Map());
  let newData = oldData;

  if (!meta.loading) {
    // Copy all generated groups over with _id as index
    let newItems = Map();
    let newResult = List();
    data.forEach((item) => {
      newItems = newItems.set(item._id, Immutable.fromJS(item));
      newResult = newResult.push(item._id);
    });

    newData = newData.set('items', newItems);
    newData = newData.set('result', newResult);
  }

  return state.set('data', newData);
}

export default (state = Map(), action) => {
  switch (action.type) {
    case BUMP_GROUP_CREATE: return httpReducer(state, action, { cb: addToFront() });
    case BUMP_GROUP_REMOVE: return httpReducer(state, action, { cb: remove() });
    case BUMP_GROUP_BULK_UPDATE: return httpReducer(state, action, { entity: 'bumpGroup', updateResult: false });
    case BUMP_GROUP_UPDATE: return overwriteUsers(state, action);
    case BUMP_GROUP_FETCH_FOR_CHAPTER: return httpReducer(state, action, { entity: 'bumpGroup' });
    case BUMP_GROUP_GENERATE: return updateGeneratedGroups(state, action);
    default: return state;
  }
};
