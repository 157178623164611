import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

function HeaderCard(props) {
  HeaderCard.propTypes = {
    classes:   PropTypes.instanceOf(Object).isRequired,
    chapter:   PropTypes.instanceOf(Map).isRequired,
    analytics: PropTypes.instanceOf(Map).isRequired,
  };

  const { classes, chapter, analytics } = props;

  const chapterCount = chapter.getIn(['data', 'total'], 0);
  const schoolCount = analytics.getIn(['data', 'schoolCount'], 0);
  const organizationCount = analytics.getIn(['data', 'organizationCount'], 0);
  return (
    <Card className={ classes.headerCard }>
      <CardContent>
        <Grid container spacing={ 8 }>
          <Grid item sm={ 3 } xs={ 12 }>
            <Typography className={ classes.whiteText } variant='h3'>TechniPhi</Typography>
            <Typography className={ classes.whiteText }>ADMIN DASHBOARD</Typography>
          </Grid>

          <Grid item sm={ 3 } xs={ 12 }>
            <Grid container spacing={ 8 }>
              <Grid item xs={ 4 }>
                <Avatar className={ classes.avatar }>
                  <Icon color='primary'>school</Icon>
                </Avatar>
              </Grid>
              <Grid item xs={ 8 }>
                <Typography className={ classes.label }>Schools</Typography>
                <Typography className={ classes.whiteText } variant='h6'>{ schoolCount }</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={ 3 } xs={ 12 }>
            <Grid container spacing={ 8 }>
              <Grid item xs={ 4 }>
                <Avatar className={ classes.avatar }>
                  <Icon color='primary'>group</Icon>
                </Avatar>
              </Grid>
              <Grid item xs={ 8 }>
                <Typography className={ classes.label }>HQs</Typography>
                <Typography className={ classes.whiteText } variant='h6'>{ organizationCount }</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={ 3 } xs={ 12 }>
            <Grid container spacing={ 8 }>
              <Grid item xs={ 4 }>
                <Avatar className={ classes.avatar }>
                  <Icon color='primary'>account_balance</Icon>
                </Avatar>
              </Grid>
              <Grid item xs={ 8 }>
                <Typography className={ classes.label }>Chapters</Typography>
                <Typography className={ classes.whiteText } variant='h6'>{ chapterCount }</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const styles = theme => ({
  headerCard: {
    marginTop:       theme.spacing.unit,
    padding:         '8px 0 8px 0',
    width:           '100%',
    height:          'auto',
    backgroundColor: theme.palette.primary.main,
    borderRadius:    10,
  },

  whiteText: {
    color:         theme.palette.common.white,
    letterSpacing: 1,
  },

  avatar: {
    height:          50,
    width:           50,
    backgroundColor: theme.palette.common.white,
  },

  label: {
    color:   theme.palette.common.white,
    opacity: 0.6,
  },
});

export default withStyles(styles)(HeaderCard);
