import { connect } from 'react-redux';

// action creators
import { analyticsFetchAll } from '../../../actions/analyticsActions';
import { chapterFetchAll } from '../../../actions/chapterActions';
import { storeClearForDashboard } from '../../../actions/storeActions';

// components
import Home from './Home';

const mapStateToProps = state => ({
  router:      state.get('router'),
  chapter:     state.get('chapter'),
  currentUser: state.get('currentUser'),
  analytics:   state.get('analytics'),
});

const mapDispatchToProps = dispatch => ({
  fetchChapters: (params) => {
    dispatch(chapterFetchAll(params));
  },

  fetchAnalytics: () => {
    dispatch(analyticsFetchAll());
  },

  clearStoreForDashboard: () => {
    dispatch(storeClearForDashboard());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
