export const HOMETOWN_GROUP_CREATE_INIT = 'HOMETOWN_GROUP_CREATE_INIT';
export const HOMETOWN_GROUP_CREATE = 'HOMETOWN_GROUP_CREATE';

export const HOMETOWN_GROUP_REMOVE_INIT = 'HOMETOWN_GROUP_REMOVE_INIT';
export const HOMETOWN_GROUP_REMOVE = 'HOMETOWN_GROUP_REMOVE';

export const HOMETOWN_GROUP_UPDATE_INIT = 'HOMETOWN_GROUP_UPDATE_INIT';
export const HOMETOWN_GROUP_UPDATE = 'HOMETOWN_GROUP_UPDATE';

export const HOMETOWN_GROUP_BULK_UPDATE = 'HOMETOWN_GROUP_BULK_UPDATE';
export const HOMETOWN_GROUP_BULK_UPDATE_INIT = 'HOMETOWN_GROUP_BULK_UPDATE_INIT';
export const HOMETOWN_GROUP_BULK_UPDATE_DONE = 'HOMETOWN_GROUP_BULK_UPDATE_DONE';
