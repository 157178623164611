import { Map } from 'immutable';

// constants
import { RESULT_FETCH_ALL_FOR_CHAPTER, RESULT_CLEAR } from '../constants/resultTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map({ displayList: true }), action) => {
  switch (action.type) {
    case RESULT_FETCH_ALL_FOR_CHAPTER: return httpReducer(state, action);
    case RESULT_CLEAR: return Map();
    default: return state;
  }
};
