import { Map } from 'immutable';

// constants
import { ROUND_PNM_CREATE } from '../constants/roundPnmTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case ROUND_PNM_CREATE: return httpReducer(state, action, { entity: 'roundPnm', updateResult: false });
    default: return state;
  }
};
