import { Map, List } from 'immutable';

// constants
import { CURRENT_CHAPTER_FETCH } from '../constants/chapterTypes';
import { ORGANIZATION_FETCH, ORGANIZATION_UPDATE } from '../constants/organizationTypes';
import { CONCERN_CATEGORY_CREATE, CONCERN_CATEGORY_REMOVE, CONCERN_CATEGORY_BULK_UPDATE } from '../constants/concernCategoryTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { replaceListWithResult } from '../lib/normalizedReducerHelpers';

function pushIdOntoListAttribute(key) {
  return (map, action) => {
    const payloadData = action.payload.data;
    const id = payloadData._id || payloadData.id;
    const organizationId = payloadData.organization;

    const oldData = map.getIn(['data', 'items', organizationId.toString()]) || Map();
    const oldList = oldData.get(key) || List();

    const newList = oldList.push(id);
    const newData = oldData.set(key, newList);

    return map.setIn(['data', 'items', organizationId.toString()], newData);
  };
}

function removeIdFromListAttribute(key) {
  return (map, action) => {
    const payloadData = action.payload.data;
    const id = payloadData._id || payloadData.id;
    const organizationId = payloadData.organization;

    const oldData = map.getIn(['data', 'items', organizationId.toString()]) || Map();
    const oldList = oldData.get(key) || List();

    const newList = oldList.delete(oldList.indexOf(id));
    const newData = oldData.set(key, newList);

    return map.setIn(['data', 'items', organizationId.toString()], newData);
  };
}

export default (state = Map(), action) => {
  switch (action.type) {
    case CONCERN_CATEGORY_BULK_UPDATE: return httpReducer(state, action, { cb: replaceListWithResult('concernCategories'), updateResult: false });
    case CONCERN_CATEGORY_REMOVE: return httpReducer(state, action, { cb: removeIdFromListAttribute('concernCategories'), updateResult: false });
    case CONCERN_CATEGORY_CREATE: return httpReducer(state, action, { cb: pushIdOntoListAttribute('concernCategories'), updateResult: false });
    case CURRENT_CHAPTER_FETCH: return httpReducer(state, action, { entity: 'organization', updateResult: false });
    case ORGANIZATION_UPDATE: return httpReducer(state, action, { entity: 'organization' });
    case ORGANIZATION_FETCH: return httpReducer(state, action, { entity: 'organization', updateResult: false });
    default: return state;
  }
};
