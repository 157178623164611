import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import { PNM_DELETE_INIT, PNM_DELETE } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmListSchema } from '../../schemas/pnm';

export function* pnmDelete(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { pnmIds, chapter, modeToClear } = action.payload || {};

  const options = {
    query: {
      pnmIds,
      chapter,
      modeToClear,
    },
  };

  try {
    yield* httpSaga(PNM_DELETE, call(remove, pnmService, null, options), {
      schema: pnmListSchema,
    });
  } catch (err) {
    yield put(error(PNM_DELETE, err));
  } finally {
    yield put(doneIndicator(PNM_DELETE));
  }
}

export function* watchPnmDelete() {
  yield takeEvery(PNM_DELETE_INIT, pnmDelete);
}

export default function* root() {
  yield all([
    fork(watchPnmDelete),
  ]);
}
