import { connect } from 'react-redux';

// components
import MainCardBody from './MainCardBody';

// actions
import { voteFetchAll } from '../../../../actions/voteActions';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  organization:   state.get('organization'),
  pnm:            state.get('pnm'),
  tag:            state.get('tag'),
  user:           state.get('user'),
  vote:           state.get('vote'),
});

const mapDispatchToProps = dispatch => ({
  fetchVotes: (params) => {
    dispatch(voteFetchAll(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCardBody);
