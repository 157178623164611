import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class InfiniteScroll extends Component {
  static propTypes = {
    children:       PropTypes.node.isRequired,
    onLoadData:     PropTypes.func.isRequired,
    loading:        PropTypes.bool.isRequired,
    limit:          PropTypes.number,
    skip:           PropTypes.number,
    total:          PropTypes.number,
    itemHeight:     PropTypes.number,
    itemLoadOffset: PropTypes.number,
  }

  static defaultProps = {
    itemHeight:     0,
    itemLoadOffset: 5,
    limit:          0,
    skip:           0,
    total:          0,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Map({
        loading: false,
      }),
    };
  }

  componentDidMount() {
    this.element.addEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {
    const currentLoading = this.props.loading;
    const nextLoading = nextProps.loading;

    if (this.state.data.get('loading') && currentLoading && !nextLoading) {
      this.setState({ data: this.state.data.set('loading', false) });
    }
  }

  componentWillUnmount() {
    this.element.removeEventListener('scroll', this.handleScroll);
  }

  buildQuery() {
    const { limit, skip } = this.props;

    return {
      skip: skip + limit,
    };
  }

  handleScroll = () => {
    const {
      onLoadData,
      itemHeight,
      itemLoadOffset,
      limit,
      skip,
      total,
    } = this.props;

    const { scrollHeight, scrollTop, clientHeight } = this.element;

    // scrollHeight = the total height of the div with all of the items rendered, even hidden things
    // scrollTop = how many pixels the div has been scrolled down
    // clientHeight = the height of the actual viewport of the div, what you see
    // itemHeight = the given height of each list item
    // itemLoadOffset = the number of items from the bottom that you would like the load to start
    //   for example: start loading more data when the user is 5 items from the bottom
    // bottom = a boolean for if we've reached the bottom of the scroll list
    const bottom = scrollHeight - scrollTop <= (clientHeight + (itemHeight * itemLoadOffset));
    const loading = this.state.data.get('loading');
    const more = (limit + skip) < total;

    // Call onLoadData when the user is x (itemLoadOffset) items from the bottom of the list
    // If itemHeight and itemLoadOffset are not given, call when at the bottom of the list
    if (bottom && !loading && more) {
      this.setState({ data: this.state.data.set('loading', true) });

      onLoadData(this.buildQuery());
    }
  }

  render() {
    const {
      children,
      itemHeight,
      itemLoadOffset,
      loading,
      limit,
      skip,
      total,
      onLoadData,
      ...rest
    } = this.props;

    return (
      <ul ref={ (ref) => { this.element = ref; } } { ...rest }>
        { children }
      </ul>
    );
  }
}

export default InfiniteScroll;
