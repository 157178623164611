import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// action creators
import { organizationFetch } from '../../actions/organizationActions';

// components
import Settings from './Settings';

const mapStateToProps = state => ({
  router:         state.get('router'),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
});

const mapDispatchToProps = dispatch => ({
  navigateToSettingsTab: (route) => {
    dispatch(push(`${route}`));
  },
  fetchOrganization: (params) => {
    dispatch(organizationFetch(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
