import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { get } from '../../sources/api/client';
import analyticsService from '../../sources/api/analyticsService';

// constants
import { ANALYTICS_FETCH_FOR_CHAPTER, ANALYTICS_FETCH_FOR_CHAPTER_INIT } from '../../constants/analyticsTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* analyticsFetchForChapters(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    clearanceLevel, chapters, chapterA, chapterB, from, to, user,
  } = action.payload || {};

  const options = {
    query: {
      user,
      clearanceLevel,
      chapters,
      chapterA,
      chapterB,
      from,
      to,
    },
  };

  // Ensures that services methods are reached.
  //  An empty array id will prevent chapters list from returning
  const FETCH_ID = user;

  try {
    yield* httpSaga(ANALYTICS_FETCH_FOR_CHAPTER, call(get, analyticsService, FETCH_ID, options));
  } catch (err) {
    yield put(error(ANALYTICS_FETCH_FOR_CHAPTER, err));
  } finally {
    yield put(doneIndicator(ANALYTICS_FETCH_FOR_CHAPTER));
  }
}

export function* watchAnalyticsFetchForChapter() {
  yield takeEvery(ANALYTICS_FETCH_FOR_CHAPTER_INIT, analyticsFetchForChapters);
}

export default function* root() {
  yield all([
    fork(watchAnalyticsFetchForChapter),
  ]);
}
