import { reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';

// action creators
import { forgotPasswordCreate } from '../../../actions/forgotPasswordActions';

// components
import ForgotPasswordEmailForm from './ForgotPasswordEmailForm';

// schemas
const schema = {
  email: {
    presence: true,

    email: {
      message: 'not valid',
    },

    length: {
      maximum: 255,
    },

  },
};

const mapStateToProps = state => ({
  forgotPasswordEmailForm: (state.get('form') || Map()).get('forgotPasswordEmail') || Map(),
});

const mapDispatchToProps = dispatch => ({
  createForgotPassword: (params) => {
    dispatch(forgotPasswordCreate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default reduxForm({
  form:     'forgotPasswordEmail',
  validate: validateForm,
  onChange: handleChange,
})(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordEmailForm));
