import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local components
import AccountForm from './components/AccountForm';
import PasswordForm from './components/PasswordForm';
import PhotoForm from './components/PhotoForm';

function Account(props) {
  Account.propTypes = {
    classes:       PropTypes.instanceOf(Object).isRequired,
    parentclasses: PropTypes.instanceOf(Object).isRequired,
    currentUser:   PropTypes.instanceOf(Map).isRequired,
  };

  const {
    classes,
    parentclasses: { tabsCard, titleContainer, titleIcon },
  } = props;

  const currentUser = (props.currentUser || Map()).get('data') || Map();

  return (
    <div>
      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>

            <Grid item xs={ 12 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>account_box</Icon>
              <Typography variant='h5' color='primary'>Account Settings</Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary' gutterBottom>
                Manage your personal MyVote settings
              </Typography>
            </Grid>

            <Grid item xs={ 12 } sm={ 6 }>
              <AccountForm currentUser={ currentUser } />
            </Grid>

            <Grid item xs={ 12 } sm={ 6 } align='center'>
              <PhotoForm currentUser={ currentUser } />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>

            <Grid item xs={ 12 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>lock_outline</Icon>
              <Typography variant='h5' color='primary'>Change Password</Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary' gutterBottom>
                Reset your MyVote password
              </Typography>
            </Grid>

            <Grid item xs={ 12 } className={ classes.example }>
              <PasswordForm currentUser={ currentUser } />
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

const styles = theme => ({
  avatar: {
    width:  '200px',
    height: '200px',

    [theme.breakpoints.up('sm')]: {
      marginTop: -85,
    },
  },
});

export default withStyles(styles)(Account);
