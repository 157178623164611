import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { specialtyListSchema } from '../../schemas/specialty';

export default function getSpecialties(state) {
  const specialty = state.get('specialty');

  const specialtyData = specialty.get('data', Map());
  const result = specialtyData.get('result', List());
  let items = [];

  if (result.size > 0) {
    const specialties = specialty.getIn(['data', 'items'], Map());

    const entities = {
      specialty: specialties.toJS(),
    };

    items = denormalize(result.toJS(), specialtyListSchema, entities);
  }

  return Immutable.fromJS(items);
}
