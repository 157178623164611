import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import voteService from '../../sources/api/voteService';

// constants
import { VOTE_FETCH_FOR_PNM, VOTE_FETCH_FOR_PNM_INIT } from '../../constants/voteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { voteListSchema } from '../../schemas/vote';

export function* voteFetchForPnm(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { pnm, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
      pnm,
      $populate: 'round',
      $sort:     {
        createdAt: -1,
      },
    },
  };

  try {
    yield* httpSaga(VOTE_FETCH_FOR_PNM, call(find, voteService, options), {
      schema: voteListSchema,
    });
  } catch (err) {
    yield put(error(VOTE_FETCH_FOR_PNM, err));
  } finally {
    yield put(doneIndicator(VOTE_FETCH_FOR_PNM));
  }
}

export function* watchVoteFetchForPnm() {
  yield takeEvery(VOTE_FETCH_FOR_PNM_INIT, voteFetchForPnm);
}

export default function* root() {
  yield all([
    fork(watchVoteFetchForPnm),
  ]);
}
