import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';

// local components
import Button from '../../Button';
import ChaptersTable from './components/ChaptersTable';
import HeaderCard from './components/HeaderCard';
import AddChapterModal from './components/AddChapterModal';

class Home extends Component {
  static propTypes = {
    classes:                PropTypes.instanceOf(Object).isRequired,
    fetchChapters:          PropTypes.func.isRequired,
    currentUser:            PropTypes.instanceOf(Map).isRequired,
    chapter:             PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    fetchAnalytics:         PropTypes.func.isRequired,
    analytics:              PropTypes.instanceOf(Map).isRequired,
    clearStoreForDashboard: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        displayAddChapterModal: false,
        search:                 null,
        sortBy:                 'name',
        order:                  1,
        timerId:                null,
      }),
    };
  }

  componentWillMount() {
    const {
      currentUser, fetchAnalytics, clearStoreForDashboard,
    } = this.props;

    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;

    clearStoreForDashboard();

    if (clearanceLevel > 0) {
      this.initChapters(this.props);
      fetchAnalytics();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { fetchAnalytics } = nextProps;
    const nextClearanceLevel = (nextProps.currentUser.get('data') || Map()).get('clearance_level') || 0;
    const currentClearanceLevel = (this.props.currentUser.get('data') || Map()).get('clearance_level') || 0;

    if (nextClearanceLevel > 0 && currentClearanceLevel === 0) {
      this.initChapters(nextProps);
      fetchAnalytics();
    }
  }

  onSearchChange = (value) => {
    const { fetchChapters } = this.props;

    const fetchParams = this.getFetchParams(this.props);

    if (value) {
      this.setState({ data: this.state.data.set('search', value) });

      fetchParams.search = value;
      fetchParams.skip = 0;
    } else {
      this.setState({ data: this.state.data.set('search', null) });

      delete fetchParams.search;
    }

    fetchChapters(fetchParams);
  }

  getFetchParams = (props) => {
    const chapter = props.chapter.get('data') || Map();

    const limit = chapter.get('limit') || 10;
    const skip = chapter.get('skip') || 0;
    const search = this.state.data.get('search');
    const sortBy = this.state.data.get('sortBy');
    const order = this.state.data.get('order');

    const params = {
      sort: {},
      limit,
      skip,
    };

    if (search) {
      params.search = search;
    }

    params.sort[sortBy] = order;

    return params;
  }

  initChapters = (props) => {
    const { fetchChapters } = props;

    fetchChapters(this.getFetchParams(props));
  }

  handleSortChange = (property) => {
    const { fetchChapters } = this.props;

    const sortBy = this.state.data.get('sortBy');

    let order = this.state.data.get('order');

    if (property === sortBy) {
      order *= -1;
    } else {
      order = 1;
    }

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('sortBy', property);
        map.set('order', order);
      }),
    });

    const fetchParams = this.getFetchParams(this.props);

    fetchParams.sort = {};
    fetchParams.sort[sortBy] = order;

    fetchChapters(fetchParams);
  }

  handlePaginationChange = (params) => {
    const { fetchChapters } = this.props;

    const fetchParams = {
      ...this.getFetchParams(this.props),
      ...params,
    };

    fetchChapters(fetchParams);
  }

  handleSearchChange = ({ target: { value } }) => {
    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(this.onSearchChange, 500, value)),
    });
  }

  handleCloseAddChapterModal = () => {
    this.setState({ data: this.state.data.set('displayAddChapterModal', false) });
  }

  handleOpenAddChapterModal = () => {
    this.setState({ data: this.state.data.set('displayAddChapterModal', true) });
  }

  renderSearchLoadingIndicator = () => {
    const { chapter } = this.props;

    let element;

    if (chapter.get('loading')) {
      element = <CircularProgress size={ 20 } />;
    }

    return element;
  }

  render() {
    const {
      classes, currentUser, chapter, analytics,
    } = this.props;

    const sortBy = this.state.data.get('sortBy');
    const order = this.state.data.get('order');
    const search = this.state.data.get('search');
    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;

    return (
      <Grid container alignItems='center' spacing={ 16 }>
        {
          clearanceLevel > 0 ? (
            <Grid item xs={ 8 } align='left' key='chapterSearch'>
              <div className={ classes.searchFieldContainer }>
                <Icon className={ classes.searchFieldIcon } color='primary'>search</Icon>
                <TextField InputProps={ { disableUnderline: true } }
                  placeholder='Search for chapters'
                  onChange={ this.handleSearchChange }
                  fullWidth />

                { this.renderSearchLoadingIndicator() }
              </div>
            </Grid>
          ) : null
        }

        {
          clearanceLevel === 2 ? [
            <Hidden xsDown key='addChapterSmall'>
              <Grid item sm={ 4 } className={ classes.topButton } align='right'>
                <Button variant='contained' color='primary' onClick={ this.handleOpenAddChapterModal }>
                  <Icon className={ classes.leftIcon }>add</Icon>
                  Add Chapter
                </Button>
              </Grid>
            </Hidden>,

            <Hidden smUp key='addChapterLarge'>
              <Grid item sm={ 4 } className={ classes.topButton } align='right'>
                <Button variant='fab' color='primary' onClick={ this.handleOpenAddChapterModal }>
                  <Icon>add</Icon>
                </Button>
              </Grid>
            </Hidden>,

            <Grid item xs={ 12 } key='headerCard'>
              <HeaderCard chapter={ chapter }
                analytics={ analytics } />
            </Grid>,
          ] : null
        }

        <Grid item xs={ 12 } className={ classes.tableContainer }>
          <ChaptersTable onPaginationChange={ this.handlePaginationChange }
            onSortChange={ this.handleSortChange }
            search={ search }
            sortBy={ sortBy }
            order={ order } />
        </Grid>

        <AddChapterModal open={ this.state.data.get('displayAddChapterModal') }
          onClose={ this.handleCloseAddChapterModal } />
      </Grid>
    );
  }
}

const styles = theme => ({
  searchFieldContainer: {
    alignItems:      'center',
    display:         'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius:    30,
    boxShadow:       '0 6px 20px 0 rgba(157, 157, 157, .7)',
    maxWidth:        300,
    padding:         '5px 20px 5px 40px',
    position:        'relative',
    marginLeft:      theme.spacing.unit,
  },

  searchFieldIcon: {
    left:     9,
    position: 'absolute',
    top:      9,
  },

  leftIcon: {
    marginRight: theme.spacing.unit * 2,
  },

  tableContainer: {
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },

  topButton: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 40,
    },
  },
});

export default withStyles(styles)(Home);
