import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import React, { Component } from 'react';

// MUI components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local components
import CategoriesForm from './components/CategoriesForm';

class Categories extends Component {
  static propTypes = {
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    parentclasses:  PropTypes.instanceOf(Object).isRequired,
    organization:   PropTypes.instanceOf(Map).isRequired,
  };

  renderCategoriesForm = () => {
    const { currentChapter, organization } = this.props;

    let element;

    if (organization.get('roundCategories', List()).size > 0) {
      element = <CategoriesForm currentChapter={ currentChapter } organization={ organization } />;
    } else {
      element = (
        <Typography>
          Your organization has not added any rounds for category-specific voting.
        </Typography>
      );
    }

    return element;
  }

  render() {
    const {
      parentclasses: { tabsCard, titleContainer, titleIcon },
    } = this.props;

    return (
      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>

            <Grid item xs={ 12 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>thumbs_up_down</Icon>
              <Typography variant='h5' color='primary'>Manage Voting Categories</Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Manage what categories your members can vote on, organized by round type
              </Typography>
            </Grid>

            <Grid item xs={ 12 }>
              { this.renderCategoriesForm() }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default Categories;
