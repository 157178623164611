import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List, Map } from 'immutable';
import 'react-vis/dist/style.css';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ListComponent from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class Overview extends Component {
  static propTypes = {
    analytics:        PropTypes.instanceOf(Map).isRequired,
    classes:          PropTypes.instanceOf(Object).isRequired,
    initOverviewData: PropTypes.func.isRequired,
    loading:          PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  }

  componentWillMount() {
    const {
      initOverviewData,
      loading,
    } = this.props;

    if (!loading) {
      initOverviewData(List());
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      initOverviewData,
      loading,
    } = nextProps;

    if (this.props.loading && !loading) {
      initOverviewData();
    }
  }

  renderTitle = (title, icon) => {
    const { classes } = this.props;

    return (
      <Grid item xs={ 12 } key={ title }>
        <div className={ classes.statTitleContainer }>
          <Grid container spacing={ 32 } className={ classes.statTitleText }>
            <Grid item xs={ 1 }>
              <Icon color='primary'>{ icon }</Icon>
            </Grid>
            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='primary'>{ title }</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }

  renderCampus = () => {
    const { analytics, classes } = this.props;

    return (
      <Grid item xs={ 12 } sm={ 4 }>
        <Grid container spacing={ 32 }>
          <Grid item xs={ 12 }>
            <div className={ classes.outlinedContainer }>
              <Grid container spacing={ 16 }>
                { this.renderTitle('Campus Statistics', 'school') }

                { analytics.get('loading') ? (
                  <Grid item xs={ 12 } align='center' className={ classes.loadingContainer }>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid item xs={ 12 }>
                    <Fade in={ !analytics.get('loading') }>
                      <ListComponent>
                        <ListItem>
                          <Avatar>
                            <Icon>school</Icon>
                          </Avatar>

                          <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'totalCampuses'], 0) } secondary='Total Campuses' />
                        </ListItem>

                        <ListItem>
                          <Grid container>
                            <Grid item xs={ 1 } align='center'>
                              <Icon className={ classes.grayIcon }>subdirectory_arrow_right</Icon>
                            </Grid>

                            <Grid item xs={ 7 }>
                              <div className={ classes.roundedContainer }>
                                <Typography>{`${analytics.getIn(['data', 'overviewData', 'totalCampusDirector'], 0)} CampusDirector`}</Typography>
                              </div>
                            </Grid>

                            <Grid item xs={ 4 }>
                              <div className={ classes.roundedContainer }>
                                <Typography>{`${analytics.getIn(['data', 'overviewData', 'totalIcs'], 0)} ICS`}</Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <ListItem />
                      </ListComponent>
                    </Fade>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={ 12 }>
            { this.renderHqUser() }
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderHqUser = () => {
    const { analytics, classes } = this.props;

    return (
      <div className={ classes.outlinedContainer }>
        <Grid container spacing={ 16 }>
          { this.renderTitle('HQ Users', 'group') }

          { analytics.get('loading') ? (
            <Grid item xs={ 12 } align='center' className={ classes.loadingContainer }>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item xs={ 12 }>
              <Fade in={ !analytics.get('loading') }>
                <ListComponent>
                  <ListItem>
                    <Avatar>
                      <Icon>group</Icon>
                    </Avatar>

                    <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'hqUsers'], 0) } secondary='Total HQ Users' />
                  </ListItem>
                </ListComponent>
              </Fade>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }

  renderChapterActivity = () => {
    const { analytics, classes } = this.props;

    return (
      <Grid item xs={ 12 } sm={ 4 }>
        <div className={ classes.outlinedContainer }>
          <Grid container spacing={ 16 }>
            { this.renderTitle('Chapter Activity', 'trending_up') }

            { analytics.get('loading') ? (
              <Grid item xs={ 12 } align='center' className={ classes.loadingContainer }>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xs={ 12 }>
                <Fade in={ !analytics.get('loading') }>
                  <ListComponent>
                    <ListItem>
                      <Avatar>
                        <Icon>today</Icon>
                      </Avatar>

                      <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'activity', 'week'], 0) }
                        secondary='Active Past Week' />
                    </ListItem>

                    <ListItem>
                      <Avatar>
                        <Icon>date_range</Icon>
                      </Avatar>

                      <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'activity', 'twoWeek'], 0) }
                        secondary='Active Past 2 Weeks' />
                    </ListItem>

                    <ListItem>
                      <Avatar>
                        <Icon>event_note</Icon>
                      </Avatar>

                      <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'activity', 'month'], 0) }
                        secondary='Active Past Month' />
                    </ListItem>

                  </ListComponent>
                </Fade>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    );
  }

  renderChapterStatus = () => {
    const { analytics, classes } = this.props;

    return (
      <Grid item xs={ 12 } sm={ 4 }>
        <div className={ classes.outlinedContainer }>
          <Grid container spacing={ 16 }>
            { this.renderTitle('Chapter Status', 'account_balance') }

            { analytics.get('loading') ? (
              <Grid item xs={ 12 } align='center' className={ classes.loadingContainer }>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xs={ 12 }>
                <Fade in={ !analytics.get('loading') }>
                  <ListComponent>
                    <ListItem>
                      <Avatar>
                        <Icon>smartphone</Icon>
                      </Avatar>

                      <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'modes', 'standard', 'total'], 0) }
                        secondary='In Standard Mode' />
                    </ListItem>
                    <ListItem>
                      <Grid container>
                        <Grid item xs={ 1 } align='center'>
                          <Icon className={ classes.grayIcon }>subdirectory_arrow_right</Icon>
                        </Grid>

                        <Grid item xs={ 7 }>
                          <div className={ classes.roundedContainer }>
                            <Typography>{`${analytics.getIn(['data', 'overviewData', 'modes', 'standard', 'recruited'], 0)} Have Recruited PNMs`}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem />

                    <ListItem>
                      <Avatar>
                        <Icon>mobile_screen_share</Icon>
                      </Avatar>

                      <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'modes', 'cob', 'total'], 0) }
                        secondary='In COB Mode' />
                    </ListItem>
                    <ListItem>
                      <Grid container>
                        <Grid item xs={ 1 } align='center'>
                          <Icon className={ classes.grayIcon }>subdirectory_arrow_right</Icon>
                        </Grid>

                        <Grid item xs={ 7 }>
                          <div className={ classes.roundedContainer }>
                            <Typography>{`${analytics.getIn(['data', 'overviewData', 'modes', 'cob', 'recruited'], 0)} Have Recruited PNMs`}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <ListItem>
                      <Avatar>
                        <Icon>device_unknown</Icon>
                      </Avatar>

                      <ListItemText primary={ analytics.getIn(['data', 'overviewData', 'modes', 'demo', 'total'], 0) }
                        secondary='In Training Mode' />
                    </ListItem>
                  </ListComponent>
                </Fade>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container justify='center' spacing={ 32 }>
        <Grid item xs={ 12 } className={ classes.titleContainer }>
          <Icon className={ classes.titleIcon } color='primary'>high_quality</Icon>
          <Typography variant='h5' color='primary'>Overview</Typography>
        </Grid>

        <Grid item xs={ 12 }>
          <Typography variant='subtitle1' color='textSecondary' gutterBottom>
            Explore a bird&rsquo;s eye view of your organization
          </Typography>
        </Grid>

        { this.renderChapterStatus() }
        { this.renderCampus() }
        { this.renderChapterActivity() }
      </Grid>
    );
  }
}

const styles = () => ({
  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      35,
  },

  titleContainer: {
    marginTop:     20,
    display:       'flex',
    flexDirection: 'row',
  },

  statTitleContainer: {
    paddingTop:    15,
    borderBottom:  '1px solid #bfbfbf',
    paddingBottom: 10,
  },

  statTitleText: {
    marginLeft: 5,
  },

  paddedChapterItemContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       15,
  },

  outlinedContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
  },

  chapterItemContainer: {
    margin: 20,
  },

  chapterItemText: {
    marginLeft: 15,
  },

  grayIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  roundedContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 100,
    textAlign:    'center',
    width:        '80%',
    padding:      5,
  },

  loadingContainer: {
    marginTop:    25,
    marginBottom: 25,
  },
});

export default withStyles(styles)(Overview);
