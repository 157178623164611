import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { tagListSchema } from '../../schemas/tag';

export default function getTags(state) {
  const tag = state.get('tag');
  const currentChapter = state.get('currentChapter');

  const result = currentChapter.getIn(['data', 'tags'], List());
  const tags = tag.getIn(['data', 'items'], Map());
  let items = [];

  if (result.size > 0) {
    const entities = {
      tag: tags.toJS(),
    };

    items = denormalize(result.toJS(), tagListSchema, entities);
  }

  return Immutable.fromJS(items);
}
