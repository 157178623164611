import { Map } from 'immutable';

// constants
import { MODAL_ADVANCED_OPTIONS_SET_OPEN } from '../constants/modalAdvancedOptions';

export default (state = Map(), action) => {
  const {
    type,
    payload,
  } = action || {};
  switch (type) {
    case MODAL_ADVANCED_OPTIONS_SET_OPEN: return state.set('open', !!payload.open);
    default: return state;
  }
};
