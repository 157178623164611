import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import CommentList from '../../../CommentList';

class NotesTab extends Component {
  static propTypes = {
    classes:             PropTypes.instanceOf(Object).isRequired,
    chapterId:           PropTypes.number.isRequired,
    disableNotesFeature: PropTypes.bool.isRequired,
    note:                PropTypes.instanceOf(Map).isRequired,
    notes:               PropTypes.instanceOf(List).isRequired,
    deleteNote:          PropTypes.func.isRequired,
  }

  handleDelete = (id) => {
    const { chapterId, deleteNote } = this.props;

    deleteNote({ chapter: chapterId, id });
  }

  render() {
    const {
      classes, disableNotesFeature,
      notes, note,
    } = this.props;

    const notesLoading = !note.get('data') && note.get('loading', false);
    const type = disableNotesFeature ? 'tags' : 'notes';
    const emptyMessageIcon = disableNotesFeature ? 'local_offer' : 'speaker_notes';
    const emptyMessageTitle = disableNotesFeature ? 'No Tags Found' : 'No Notes Found';
    const emptyMessageSubtitle = disableNotesFeature
      ? 'Assign tags to keep track of your PNMs'
      : 'Jot down a note and assign tags to keep track of your PNMs';

    return (
      <div>
        { notes.size || notesLoading
          ? (
            <CommentList type={ type }
              items={ notes }
              loading={ notesLoading }
              onDelete={ this.handleDelete } />
          ) : (
            <div>
              <ListItem className={ classes.title }>
                <Typography color='secondary' align='center' variant='h6'>
                  { emptyMessageTitle }
                </Typography>
              </ListItem>
              <ListItem>
                <Avatar className={ classes.avatar }>
                  <Icon className={ classes.avatarIcon }>{ emptyMessageIcon }</Icon>
                </Avatar>
              </ListItem>
              <ListItem className={ classes.title }>
                <Typography color='secondary' align='center'>
                  { emptyMessageSubtitle }
                </Typography>
              </ListItem>
            </div>
          ) }
      </div>
    );
  }
}

const styles = theme => ({
  title: {
    display: 'block',
    margin:  'auto',
  },

  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatarIcon: {
    fontSize: 50,
  },
});

export default withStyles(styles)(NotesTab);
