import { Map } from 'immutable';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';

import getUserSpecialties from '../../../../../../../../lib/selectors/getUserSpecialties';
import getSpecialties from '../../../../../../../../lib/selectors/getSpecialties';
import getUserAttribute from '../../../../../../../../lib/selectors/getUserAttribute';

// action creators
import { createUserSpecialty } from '../../../../../../../../actions/userSpecialtyActions';

// components
import SpecialtiesModal from './SpecialtiesModal';

const mapStateToProps = state => ({
  currentUserId:       getUserAttribute('id')(state),
  specialties:         getSpecialties(state),
  userSpecialties:     getUserSpecialties(state),
  userSpecialtiesForm: state.getIn(['form', 'userSpecialtiesForm'], Map()),
});

const mapDispatch = {
  createUserSpecialty,
};

// schemas
const schema = {
  specialties: {
    presence: (value) => {
      let validation;

      if ((value || []).length === 0) {
        validation = { message: ' is required' };
      }

      return validation;
    },
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedSpecialtiesModal = reduxForm({
  form:     'userSpecialtiesForm',
  validate: validateForm,
  onChange: handleChange,
})(SpecialtiesModal);

export default connect(mapStateToProps, mapDispatch)(wrappedSpecialtiesModal);
