import PropTypes from 'prop-types';
import React from 'react';
import { Map, List } from 'immutable';

// components
import NoteTagForm from '../../../../../NoteTagModal/components/NoteTagForm';

// selectors
import getUserRole from '../../../../../../lib/selectors/getUserRole';

const SingleBallotNoteForm = function ({
  allowVotingMembersToTag,
  clearanceLevel,
  currentChapter,
  currentUser,
  disableNotesFeature,
  notes,
  organization,
  pnm,
  tags,
  teamLevel,
}) {
  const isConcernChair = getUserRole(currentUser, currentChapter) === 'CONCERN_CHAIR';
  const filteredTags =    tags.filter(t => ['chapter', 'organization'].includes(t.get('type'))
    && (isConcernChair || clearanceLevel > 0 || teamLevel >= t.get('usableBy')));

  return (
    <NoteTagForm { ...{
      allowVotingMembersToTag,
      clearanceLevel,
      currentChapter,
      currentUser,
      disableNotesFeature,
      handleDialogClose: () => {},
      notes,
      organization,
      pnm,
      tags:              filteredTags,
      teamLevel,
      singleBallot:      true,
    } } />
  );
};

SingleBallotNoteForm.propTypes = {
  allowVotingMembersToTag: PropTypes.bool.isRequired,
  classes:                 PropTypes.instanceOf(Object).isRequired,
  clearanceLevel:          PropTypes.number.isRequired,
  currentChapter:          PropTypes.instanceOf(Map).isRequired,
  currentUser:             PropTypes.instanceOf(Map).isRequired,
  disableNotesFeature:     PropTypes.bool.isRequired,
  form:                    PropTypes.instanceOf(Map).isRequired,
  noteForm:                PropTypes.instanceOf(Map).isRequired,
  notes:                   PropTypes.instanceOf(List).isRequired,
  organization:            PropTypes.instanceOf(Map).isRequired,
  pnm:                     PropTypes.instanceOf(Map).isRequired,
  tags:                    PropTypes.instanceOf(List).isRequired,
  teamLevel:               PropTypes.number.isRequired,
};

export default SingleBallotNoteForm;
