import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import { Field } from 'redux-form/immutable';

// mui components
import Grid from '@material-ui/core/Grid';

// local components
import MultiAutosuggest from '../../../MultiAutosuggest';

class SearchCustomFields extends Component {
  static propTypes = {
    fields:                    PropTypes.instanceOf(List).isRequired,
    advancedDisplayOptionForm: PropTypes.instanceOf(Map).isRequired,
    change:                    PropTypes.func.isRequired,
    clearSelectedFields:       PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        search:           null,
        timerId:          null,
        selectionDeleted: false,
        suggestions:      [],
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { advancedDisplayOptionForm } = this.props;
    const nextadvancedDisplayOptionForm = nextProps.advancedDisplayOptionForm;

    const selectedFields = advancedDisplayOptionForm.getIn(['values', 'selectedField'], List());
    const selectionDeleted = this.state.data.get('selectionDeleted');

    const nextSelectedField = Immutable.fromJS(nextadvancedDisplayOptionForm.getIn(['values', 'selectedFieldOneValue'], Map()));

    const alreadySelected = selectedFields.includes(nextSelectedField);

    if (selectionDeleted) {
      this.setState({ data: this.state.data.set('selectionDeleted', false) });
    } else if (Map.isMap(nextSelectedField) && nextSelectedField.get('value') && !alreadySelected) {
      this.props.change('selectedField', selectedFields.push(nextSelectedField));
    }
  }

getSuggestions = (standardField) => {
  const fieldOptions = standardField.map(f => (
    { value: f.id, label: f.text }
  ));
  return fieldOptions;
};

filterSuggestions = (value) => {
  const { fields } = this.props;

  const standardField = fields.map(e => e.get('standardField'));

  const withoutEmptyFields = standardField.toJS().filter(i => Object.keys(i).length !== 0);
  const suggestions = this.getSuggestions(withoutEmptyFields);

  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : suggestions.filter(i =>
    i.label.toLowerCase().match(inputValue));
};

handleFetchSuggestions = ({ value }) => {
  this.setState({
    data: this.state.data.set('suggestions', this.filterSuggestions(value)),
  });
};

handleClearSuggestions = () => {
  this.setState({
    data: this.state.data.set('suggestions', []),
  });
}

handleClearSelected = () => {
  const { clearSelectedFields } = this.props;
  clearSelectedFields();
  this.props.change('selectedField', List());
  this.setState({
    data: this.state.data.withMutations((item) => {
      item.set('search', null);
      item.set('selectionDeleted', true);
    }),
  });
}

handleDeleteChip = (index) => {
  const { clearSelectedFields } = this.props;
  clearSelectedFields();

  const { advancedDisplayOptionForm } = this.props;
  const selectedFields = advancedDisplayOptionForm.getIn(['values', 'selectedField'], List());
  const newSelected = selectedFields.splice(index, 1);

  this.props.change('selectedField', newSelected);

  this.setState({
    data: this.state.data.withMutations((item) => {
      item.set('selectionDeleted', true);
    }),
  });
}

render() {
  const { advancedDisplayOptionForm } = this.props;
  const selectedFields = advancedDisplayOptionForm.getIn(['values', 'selectedField'], List());
  const suggestions = this.state.data.get('suggestions');

  return (
    <Grid item xs={ 12 }>
      <Field name='selectedFieldOneValue'
        label='Search Fields To Display'
        variant='outlined'
        displaySelectAll
        displayClear
        deleteChip={ this.handleDeleteChip }
        selected={ selectedFields }
        component={ MultiAutosuggest }
        suggestions={ suggestions }
        handleClearSelected={ this.handleClearSelected }
        onSuggestionsFetchRequested={ this.handleFetchSuggestions }
        onSuggestionsClearRequested={ this.handleClearSuggestions }
        relativeBox
        required />
      <Field name='selectedField' component={ () => (<input type='hidden' />) } />
    </Grid>
  );
}
}

export default SearchCustomFields;
