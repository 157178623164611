import { DRAWER_CLOSE, DRAWER_OPEN } from '../constants/drawerTypes';

export function drawerClose() {
  return {
    type: DRAWER_CLOSE,
  };
}

export function drawerOpen() {
  return {
    type: DRAWER_OPEN,
  };
}
