import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// components
import ConcernsTab from './ConcernsTab';
import getMembershipConcerns from '../../../../lib/selectors/getMembershipConcerns';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';

const mapStateToProps = (state, { pnmId }) => ({
  membershipConcerns: getMembershipConcerns(pnmId)(state),
  concernsLoading:    getLoadingFromState('membershipConcern')(state),
});

export default withRouter(connect(mapStateToProps)(ConcernsTab));
