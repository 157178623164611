import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { pnmFetch } from '../../actions/pnmActions';
import { fetchAllSpecialties } from '../../actions/specialtyActions';
import { fetchUserSpecialties } from '../../actions/userSpecialtyActions';

// helpers
import getBumpGroups from '../../lib/selectors/getBumpGroups';

// components
import Match from './Match';

const mapStateToProps = state => ({
  bumpGroups:     getBumpGroups(state),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  router:         state.get('router'),
});

const mapDispatch = {
  pnmFetch,
  fetchAllSpecialties,
  fetchUserSpecialties,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Match));
