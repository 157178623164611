import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { userSpecialtyListSchema } from '../../schemas/userSpecialty';

export default function getUserSpecialties(state) {
  const userSpecialty = state.get('userSpecialty');
  const specialty = state.get('specialty');
  const user = state.get('user');

  const userSpecialtyData = userSpecialty.get('data', Map());
  const result = userSpecialtyData.get('result', List());
  let items = [];

  if (result.size > 0 && (!userSpecialty.get('loading') || specialty.get('loading') || user.get('loading'))) {
    const specialties = specialty.getIn(['data', 'items'], Map());
    const users = user.getIn(['data', 'items'], Map());
    const userSpecialties = userSpecialty.getIn(['data', 'items'], Map());

    const entities = {
      userSpecialty: userSpecialties.toJS(),
      specialty:     specialties.toJS(),
      user:          users.toJS(),
      author:        users.toJS(),
    };

    items = denormalize(result.toJS(), userSpecialtyListSchema, entities);
  }

  return Immutable.fromJS(items);
}
