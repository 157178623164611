import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create } from '../../sources/api/client';
import exportService from '../../sources/api/exportService';

// constants
import { EXPORT_CREATE, EXPORT_CREATE_INIT } from '../../constants/exportTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* exportCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter, ...restOfPayload } = action.payload || {};

  const options = {
    query: {
      chapter,
      ...restOfPayload,
    },
  };

  const { votes: { includeOpenVotes } = {} } = restOfPayload || {};

  if (includeOpenVotes) {
    options.query.votes.includeOpenVotes = true;
  } else if (includeOpenVotes !== undefined) {
    options.query.votes.includeOpenVotes = null;
  }

  try {
    yield* httpSaga(EXPORT_CREATE, call(create, exportService, {}, options));
  } catch (err) {
    yield put(error(EXPORT_CREATE, err));
  } finally {
    yield put(doneIndicator(EXPORT_CREATE));
  }
}

export function* watchExportCreate() {
  yield takeEvery(EXPORT_CREATE_INIT, exportCreate);
}

export default function* root() {
  yield all([
    fork(watchExportCreate),
  ]);
}
