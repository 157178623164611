import { connect } from 'react-redux';

// components
import Results from './Results';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  round:          state.get('round'),
});
export default connect(mapStateToProps)(Results);
