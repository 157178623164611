import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import { PNM_DELETE_ALL_FOR_CHAPTER_INIT, PNM_DELETE_ALL_FOR_CHAPTER } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmListSchema } from '../../schemas/pnm';

export function* pnmDeleteAllForChapterSaga(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;
  const TYPE = PNM_DELETE_ALL_FOR_CHAPTER;

  const { chapter, modeToClear, filter } = action.payload || {};

  const options = {
    query: {
      pnmIds: [],
      chapter,
      modeToClear,
    },
  };

  if (filter) {
    options.query.filter = filter;
  }

  try {
    yield* httpSaga(TYPE, call(remove, pnmService, null, options), {
      schema: pnmListSchema,
    });
  } catch (err) {
    yield put(error(TYPE, err));
  } finally {
    yield put(doneIndicator(TYPE));
  }
}

export function* watchPnmDeleteAllForChapter() {
  yield takeEvery(PNM_DELETE_ALL_FOR_CHAPTER_INIT, pnmDeleteAllForChapterSaga);
}

export default function* root() {
  yield all([
    fork(watchPnmDeleteAllForChapter),
  ]);
}
