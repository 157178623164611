import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { getFormValues, hasSubmitSucceeded, hasSubmitFailed } from 'redux-form/immutable';
import {
  createBumpGroup,
  fetchBumpGroupsForChapter,
  removeBumpGroup,
} from '../../../../actions/bumpGroupActions';
import { fetchUsersForChapter } from '../../../../actions/userActions';

// local helpers
import getBumpGroups from '../../../../lib/selectors/getBumpGroups';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';

// components
import BumpGroups from './BumpGroups';

const mapStateToProps = state => ({
  bumpGroup:             state.get('bumpGroup'),
  formValues:            (getFormValues('bumpGroupsForm')(state) || Map()).get('bumpGroups') || List(),
  submitSucceeded:       hasSubmitSucceeded('bumpGroupsForm')(state),
  submitFailed:          hasSubmitFailed('bumpGroupsForm')(state),
  currentChapter:        state.get('currentChapter'),
  bumpGroups:            getBumpGroups(state),
  bumpGroupLoading:      getLoadingFromState('bumpGroup')(state),
  currentChapterLoading: getLoadingFromState('currentChapter')(state),
  currentChapterId:      state.getIn(['currentChapter', 'data', 'id'], 0),
});

const mapDispatchToProps = {
  createBumpGroup,
  fetchBumpGroupsForChapter,
  removeBumpGroup,
  fetchUsersForChapter,
};

export default connect(mapStateToProps, mapDispatchToProps)(BumpGroups);
