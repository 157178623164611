import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import Button from '../Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import MUILink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';
import { Field } from 'redux-form/immutable';
import TextInput from '../TextInput';

// images
import logo from '../../assets/images/logo.png';

// styles
import './Signup.scss';

const handleKeyDown = function (e, cb) {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault();
    cb();
  }
};

class Signup extends Component {
  static propTypes = {
    invite:      PropTypes.instanceOf(Map).isRequired,
    fetchInvite: PropTypes.func.isRequired,
    match:       PropTypes.instanceOf(Object).isRequired,
    signupForm:  PropTypes.instanceOf(Map).isRequired,
    createUser:  PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = { isAgreedToPrivacyPolicy: false };
  }

  componentWillMount() {
    const { match: { params: { token } } } = this.props;

    this.initInvite(token);
  }

  getInvite = () => {
    const { invite } = this.props;

    const data = invite.get('data') || Map();
    const result = data.get('result');

    return (data.get('items') || Map()).get(result) || Map();
  }

  initInvite = (token) => {
    const { fetchInvite } = this.props;

    fetchInvite(token);
  }

  handleSubmit = () => {
    const { createUser, signupForm } = this.props;

    const invite = this.getInvite();
    const { confirmPassword, ...values } = (signupForm.get('values') || Map()).toJS();

    if (!signupForm.get('syncErrors')) {
      createUser({
        token: invite.get('_id'),
        ...values,
      });
    }
  }

  toggleCheckBox(value) {
    this.setState({ isAgreedToPrivacyPolicy: value });
  }

  renderInviteLoadingIndicator = () => {
    const { invite } = this.props;

    let element;

    if (invite.get('loading')) {
      element = (
        <div className='signup-form__input-spinner'>
          <CircularProgress size={ 20 } />
        </div>
      );
    }

    return element;
  }

  renderErrorMessage = (signupForm) => {
    let element;

    if (signupForm.get('submitFailed')) {
      element = (
        <div className='signup-form__error-container'>
          <FormHelperText error>Sign up failed, please try again</FormHelperText>
        </div>
      );
    }

    return element;
  }

  render() {
    const { signupForm } = this.props;
    const { isAgreedToPrivacyPolicy } = this.state;

    const invite = this.getInvite();

    let disableButton = false;

    if (signupForm.get('syncErrors')) { disableButton = true; }

    const error = (this.props.invite.get('error') || {}).code;

    return (
      <div className='signup'>
        <Grid container justify='center'>

          <Grid xs={ 12 } sm={ 12 } md={ 7 } lg={ 6 } xl={ 5 } item>
            <Paper className='signup__container' style={ { borderRadius: 10 } } elevation={ 24 }>
              <Grid container alignItems='center' direction='column' spacing={ 24 }>

                <Grid item>
                  <img className='signup__logo' src={ logo } alt='MyVote' />
                </Grid>

                {
                  error === 404 ? 'Invite has expired' : (
                    <Grid item component='form' onKeyDown={ (e) => { handleKeyDown(e, this.handleSubmit); } }>
                      <Grid container spacing={ 24 }>
                        <Grid item xs={ 6 }>
                          <div className='signup-form__input-container'>
                            <Icon className='signup-form__icon' color='primary'>person_outline</Icon>
                            <Field errorClassName='signup-form__error'
                              InputProps={ { disableUnderline: true } }
                              name='firstname'
                              type='text'
                              component={ TextInput }
                              placeholder='First Name'
                              required
                              fullWidth />
                          </div>
                        </Grid>

                        <Grid item xs={ 6 }>
                          <div className='signup-form__input-container'>
                            <Icon className='signup-form__icon' color='primary'>person_outline</Icon>
                            <Field errorClassName='signup-form__error'
                              InputProps={ { disableUnderline: true } }
                              name='lastname'
                              type='text'
                              component={ TextInput }
                              placeholder='Last Name'
                              required
                              fullWidth />
                          </div>
                        </Grid>

                        <Grid item xs={ 6 }>
                          <div className='signup-form__input-container'>
                            <Icon className='signup-form__icon' color='primary'>mail_outline</Icon>
                            <Field errorClassName='signup-form__error'
                              InputProps={ { disableUnderline: true } }
                              name='email'
                              type='email'
                              component={ TextInput }
                              placeholder={ invite.get('email') }
                              disabled
                              fullWidth />

                            { this.renderInviteLoadingIndicator() }
                          </div>
                        </Grid>

                        <Grid item xs={ 6 }>
                          <div className='signup-form__input-container'>
                            <Icon className='signup-form__icon' color='primary'>phone_outline</Icon>
                            <Field errorClassName='signup-form__error'
                              InputProps={ { disableUnderline: true } }
                              name='phone'
                              type='tel'
                              component={ TextInput }
                              placeholder='Phone Number'
                              fullWidth />
                          </div>
                        </Grid>
                      </Grid>

                      <div className='signup-form__password-container'>
                        <Grid container spacing={ 24 }>
                          <Grid item xs={ 6 }>
                            <div className='signup-form__input-container'>
                              <Icon className='signup-form__icon' color='primary'>lock_outline</Icon>
                              <Field errorClassName='signup-form__error'
                                InputProps={ { disableUnderline: true } }
                                name='password'
                                placeholder='Enter Password'
                                type='password'
                                component={ TextInput }
                                required
                                fullWidth />
                            </div>
                          </Grid>

                          <Grid item xs={ 6 }>
                            <div className='signup-form__input-container'>
                              <Icon className='signup-form__icon' color='primary'>lock_outline</Icon>
                              <Field errorClassName='signup-form__error'
                                InputProps={ { disableUnderline: true } }
                                name='confirmPassword'
                                placeholder='Confirm Password'
                                type='password'
                                component={ TextInput }
                                required
                                fullWidth />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )
                }

                {
                  error === 404 ? '' : (
                    <Grid xs={ 12 } item>
                      <div className='signup__button-container'>

                        { this.renderErrorMessage(signupForm) }

                        <Grid container direction='row' alignItems='center'>
                          <Checkbox checked={ isAgreedToPrivacyPolicy }
                            color='primary'
                            onChange={ () => this.toggleCheckBox(!isAgreedToPrivacyPolicy) } />
                          <Typography>
                            I have read and agree to
                            {' '}
                            <MUILink href='https://www.phiredup.com/privacy-policy'
                              rel='noopener'
                              target='_blank'
                              color='primary'>
                              Privacy Policy
                            </MUILink>
                            {' and '}
                            <MUILink href='https://www.phiredup.com/eula'
                              rel='noopener'
                              target='_blank'
                              color='primary'>
                              End User License Agreement
                            </MUILink>
                          </Typography>
                        </Grid>

                        <Button className='signup__button'
                          color='primary'
                          onClick={ this.handleSubmit }
                          disabled={ disableButton || !isAgreedToPrivacyPolicy }
                          loading={ signupForm.get('loading') }
                          variant='contained'>
                          Sign Up
                        </Button>
                      </div>
                    </Grid>
                  )
                }

                <Grid item>
                  <Link className='signup__link' to='/login' href='/'>
                    <Typography variant='button' color='primary'>Login</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Signup;
