import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

// components
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

class TextInput extends Component {
  static propTypes = {
    containerClassName: PropTypes.string,
    label:              PropTypes.string,
    classes:            PropTypes.instanceOf(Object).isRequired,
    helpertext:         PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
    ]),
    maxLength:      PropTypes.number,
    errorClassName: PropTypes.string,
    input:          PropTypes.instanceOf(Object).isRequired,
    meta:           PropTypes.instanceOf(Object).isRequired,
    InputProps:     PropTypes.shape({
      endAdornment: PropTypes.node,
    }),
  }

  static defaultProps = {
    containerClassName: '',
    errorClassName:     '',
    helpertext:         '',
    label:              '',
    InputProps:         {},
    maxLength:          0,
  }

  getInputClasses = () => {
    const { classes, label } = this.props;

    if (!label.length) {
      return {};
    }

    if (label.length > 40 && label.length < 100) {
      return { classes: { input: classes.shortInput } };
    } if (label.length >= 100) {
      return { classes: { input: classes.tallInput } };
    }
    return {};
  };

  shouldDisplayError = () => {
    const {
      meta: {
        active,
        dirty,
        error,
        touched,
      },
    } = this.props;

    return error && !active && (dirty || touched);
  }

  renderError = () => {
    const {
      input, helpertext, errorClassName, maxLength, meta: { error },
    } = this.props;

    let element;

    if (this.shouldDisplayError() || (maxLength && input.value.length > maxLength)) {
      element = (
        <FormHelperText className={ errorClassName } error>
          {!!maxLength && (
          <>
            {' '}
            {input.value.length}
            {' '}
            /
            {' '}
            {maxLength}
            {' '}
            <br />
            {' '}
          </>
          )}
          { error[0] }
        </FormHelperText>
      );
    } else if (helpertext || maxLength) {
      element = (
        <FormHelperText>
          {!!maxLength && (
          <>
            {' '}
            {input.value.length}
            {' '}
            /
            {' '}
            {maxLength}
            {' '}
            <br />
            {' '}
          </>
          )}
          { helpertext }
        </FormHelperText>
      );
    }

    return element;
  }

  render() {
    const {
      classes,
      containerClassName,
      errorClassName,
      input,
      InputProps,
      ...rest
    } = this.props;

    return (
      <div>
        <TextField error={ this.shouldDisplayError() }
          { ...input }
          { ...rest }
          InputProps={ {
            ...InputProps,
            ...this.getInputClasses() || {},
          } } />

        { this.renderError() }
      </div>
    );
  }
}

const styles = () => ({
  root: {
    color: 'red',
  },
  shortInput: {
    marginBottom: 5,
    paddingTop:   15,
  },
  tallInput: {
    marginBottom: 10,
    paddingTop:   30,
  },
});

export default (withStyles(styles)(TextInput));
