import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  startSubmit, setSubmitFailed, setSubmitSucceeded, destroy,
} from 'redux-form/immutable';

// api
import { patch } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import { PNM_BULK_UPDATE, PNM_BULK_UPDATE_INIT } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmListSchema } from '../../schemas/pnm';

export function* pnmBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter, formName, items } = action.payload;

  const FORM = formName || ' ';
  yield put(startSubmit(FORM));

  const options = {
    query: {
      chapter,
      bulkUpdate: true,
    },
  };

  try {
    yield* httpSaga(PNM_BULK_UPDATE, call(patch, pnmService, null, { items }, options), {
      schema: pnmListSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    if (FORM !== 'memberInfoForm' || FORM !== 'managePnmForm') {
      yield put(destroy(FORM));
    }
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(PNM_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(PNM_BULK_UPDATE));
  }
}

export function* watchPnmBulkUpdate() {
  yield takeEvery(PNM_BULK_UPDATE_INIT, pnmBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchPnmBulkUpdate),
  ]);
}
