import { Map } from 'immutable';

// constants
import { LOCATION_SCHOOL_FETCH_ALL, LOCATION_SCHOOL_CLEAR } from '../constants/locationSchoolTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case LOCATION_SCHOOL_FETCH_ALL: return httpReducer(state, action, { entity: 'locationSchool' });
    case LOCATION_SCHOOL_CLEAR: return Map();
    default: return state;
  }
};
