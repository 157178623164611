import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';

// local components
import Button from '../../../../../Button';
import ActionModal from '../../../../../ActionModal';
import SelectInput from '../../../../../SelectInput';

class UserItem extends Component {
  static propTypes = {
    clearanceLevel: PropTypes.number.isRequired,
    classes:        PropTypes.instanceOf(Object).isRequired,
    field:          PropTypes.string.isRequired,
    teamLevel:      PropTypes.number.isRequired,
    user:           PropTypes.instanceOf(Map).isRequired,
    userState:      PropTypes.instanceOf(Map).isRequired,
    onDelete:       PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        deleteModalOpen: false,
      }),
    };
  }

  getUserLevelOptions = () => {
    const { clearanceLevel, teamLevel, user } = this.props;

    const items = [
      { value: 'VOTING_MEMBER', label: 'Voting Member' },
      { value: 'CONCERN_CHAIR', label: 'Concern Chair' },
      { value: 'RECRUITMENT_TEAM', label: 'Recruitment Team' },
    ];

    // Makes tech team an option if:
    // a) Current user is on recruitment team.
    //    Meaning we want to show tech team as option, because it is disabled for theme
    // b) Current user is on tech team / HQ team, so they can change it
    if (clearanceLevel > 0 || (user.get('team_level') > 1 && teamLevel < 2) || teamLevel > 1) {
      items.push({ value: 'TECHNOLOGY_TEAM', label: 'Technology Team' });
    }

    return items;
  }

  handleOpen = () => {
    this.setState({ data: this.state.data.set('deleteModalOpen', true) });
  };

  handleClose = () => {
    this.setState({ data: this.state.data.set('deleteModalOpen', false) });
  };

  render() {
    const {
      clearanceLevel, classes, teamLevel, user, field, onDelete, userState,
    } = this.props;

    const deleteModalOpen = this.state.data.get('deleteModalOpen');

    // We don't want recruitment team users to be able to change tech team
    const disabled = teamLevel < 2 && clearanceLevel === 0;

    return (
      <Grid container
        spacing={ 8 }
        alignItems='center'
        justify='flex-start'>
        <Grid item xs={ 12 } sm={ 4 }>
          <Typography variant='subtitle1'>{ `${user.get('firstname')} ${user.get('lastname')}` }</Typography>
          <Typography variant='subtitle1' color='textSecondary'>{ user.get('email') }</Typography>
        </Grid>

        { user.get('clearance_level') > 0
          ? [
            <Grid item xs={ 12 } sm={ 4 } align='center' key='hqAdmin'>
              <Typography>HQ Admin</Typography>
            </Grid>,
            null,
          ] : [
            <Grid item xs={ 12 } sm={ 4 } align='center' key='teamLevelInput'>
              <Field name={ `${field}.role` }
                label='What best describes this user?'
                component={ SelectInput }
                options={ this.getUserLevelOptions() }
                fullWidth
                disabled={ disabled }
                required />
            </Grid>,

            <Grid item xs={ 12 } sm={ 4 } align='center' key='deleteButton'>
              <Button variant='text'
                className={ classes.redButton }
                onClick={ this.handleOpen }>
                <Icon className={ classes.leftIcon }>delete</Icon>
                Delete
              </Button>
            </Grid>,
          ]}

        <ActionModal open={ deleteModalOpen }
          description='This user will no longer be a part of your chapter’s account. All votes from this user will no longer appear.'
          onAccept={ () => onDelete(user.get('id')) }
          onReject={ this.handleClose }
          acceptLabel='Delete'
          rejectLabel='Cancel'
          loading={ userState.get('loading') || false }
          title='Are you sure you want to remove this user?' />
      </Grid>
    );
  }
}

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  redButton: {
    color: red[500],
  },
});

export default withStyles(styles)(UserItem);
