import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create } from '../../sources/api/client';
import userSpecialtyService from '../../sources/api/userSpecialtyService';

// constants
import { USER_SPECIALTY_CREATE, USER_SPECIALTY_CREATE_INIT } from '../../constants/userSpecialtyTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSpecialtySchema } from '../../schemas/userSpecialty';

export function* userSpecialtyCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { values } = action.payload;

  try {
    yield* httpSaga(USER_SPECIALTY_CREATE, call(create, userSpecialtyService, values), {
      schema: userSpecialtySchema,
    });
  } catch (err) {
    yield put(error(USER_SPECIALTY_CREATE, err));
  } finally {
    yield put(doneIndicator(USER_SPECIALTY_CREATE));
  }
}

export function* watchInviteCreate() {
  yield takeEvery(USER_SPECIALTY_CREATE_INIT, userSpecialtyCreate);
}

export default function* root() {
  yield all([
    fork(watchInviteCreate),
  ]);
}
