import { createMuiTheme } from '@material-ui/core/styles';

const defaultHeaderFont = 'Barlow Condensed';
const defaultFont = 'Poppins';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#80b4ff',
      main:  '#4285f4',
      dark:  '#0059c1',
    },
    secondary: {
      light: '#ff8a50',
      main:  '#ff5722',
      dark:  '#D84315',
    },
  },

  /*
  Typography migration:
    display4 => h1
    display3 => h2
    display2 => h3
    display1 => h4
    headline => h5
    title => h6
    subheading => subtitle1
    body2 => body1
    body1 (default) => body2 (default)
  */

  typography: {
    useNextVariants: true,
    fontFamily:      defaultFont,

    button: {
      fontWeight:    'bold',
      textTransform: 'capitalize',
      letterSpacing: '.4px',
    },

    h4: {
      fontFamily:    defaultHeaderFont,
      fontWeight:    300,
      fontSize:      '1.75rem',
      color:         '#fff',
      textTransform: 'uppercase',
    },

    h3: {
      fontFamily:    defaultHeaderFont,
      fontSize:      '2rem',
      fontWeight:    'bold',
      color:         '#fff',
      textTransform: 'uppercase',
      letterSpacing: '.8px',
    },

    h2: {
      fontFamily:    defaultHeaderFont,
      fontWeight:    'bold',
      textTransform: 'uppercase',
      color:         '#fff',
      lineHeight:    '1em',
      letterSpacing: '.1px',
    },

    h1: {
      fontFamily:    defaultHeaderFont,
      fontWeight:    'bold',
      textTransform: 'uppercase',
      color:         '#fff',
    },

    h5: {
      fontFamily: defaultFont,
      fontWeight: 700,
      fontSize:   '1.5rem',
    },

    h6: {
      fontFamily: defaultFont,
      fontWeight: 600,
      fontSize:   '1.25rem',
    },

    subtitle1: {
      fontFamily: defaultFont,
      fontWeight: 600,
      fontSize:   '1rem',
    },
  },
});
