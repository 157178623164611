import Immutable, { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { denormalize } from 'normalizr';

// components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import CommentList from '../../../CommentList';
import { matchListSchema } from '../../../../schemas/match';

class MatchesTab extends Component {
  static propTypes = {
    chapterId:          PropTypes.number.isRequired,
    classes:            PropTypes.instanceOf(Object).isRequired,
    currentChapter:     PropTypes.instanceOf(Map).isRequired,
    user:               PropTypes.instanceOf(Map).isRequired,
    chapter:            PropTypes.instanceOf(Map).isRequired,
    match:              PropTypes.instanceOf(Object).isRequired,
    matchState:         PropTypes.instanceOf(Map).isRequired,
    deleteMatch:        PropTypes.func.isRequired,
    clearMatches:       PropTypes.func.isRequired,
    fetchMatchesForPnm: PropTypes.func.isRequired, // eslint-disable-line
  }

  componentWillMount() {
    const { currentChapter, clearMatches } = this.props;
    if (currentChapter.get('data')) {
      clearMatches();
      this.initMatches(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: { params: { id } }, clearMatches,
    } = this.props;
    const newId = nextProps.match.params.id;
    const { currentChapter } = nextProps;

    // Checks if PNM has changed, and pulls latest info for them if so
    if (id !== newId && currentChapter.get('data')) {
      clearMatches();
      this.initMatches(nextProps);
    }
  }

  getMatches = () => {
    const {
      matchState, user, chapter,
    } = this.props;

    const matchData = matchState.get('data') || Map();
    const result = matchData.get('result') || List();

    let items = [];

    if (result.size > 0) {
      const users = (user.get('data') || Map()).get('items') || List();
      const chapters = (chapter.get('data') || Map()).get('items') || List();
      const matchesData = matchData.get('items') || List();

      const entities = {
        match:   matchesData.toJS(),
        user:    users.toJS(),
        chapter: chapters.toJS(),
      };

      items = denormalize(result.toJS(), matchListSchema, entities);
    }

    return Immutable.fromJS(items);
  }

  handleDelete = (id) => {
    const { deleteMatch, chapterId } = this.props;

    deleteMatch({ chapter: chapterId, id });
  }

  initMatches = (props) => {
    const {
      fetchMatchesForPnm,
      chapterId,
      match: { params: { id } },
    } = props;

    fetchMatchesForPnm({
      pnmId: id,
      chapterId,
    });
  }

  render() {
    const {
      classes, matchState,
    } = this.props;

    const matchesData = this.getMatches() || List();

    const loading = (!matchState.get('data') && matchState.get('loading')) || false;

    return (
      <div>
        { matchesData.size || loading ? (
          <CommentList type='matches'
            items={ matchesData }
            loading={ loading }
            onDelete={ this.handleDelete } />
        ) : (
          <div>
            <ListItem className={ classes.title }>
              <Typography color='secondary' align='center' variant='h6'>No Matches Found</Typography>
            </ListItem>
            <ListItem>
              <Avatar className={ classes.avatar }>
                <Icon className={ classes.avatarIcon }>extension</Icon>
              </Avatar>
            </ListItem>
            <ListItem className={ classes.title }>
              <Typography color='secondary' align='center'>Make matches to easily connect your members with PNMs</Typography>
            </ListItem>
          </div>
        ) }
      </div>
    );
  }
}

const styles = theme => ({
  title: {
    display: 'block',
    margin:  'auto',
  },

  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatarIcon: {
    fontSize: 50,
  },
});

export default withStyles(styles)(MatchesTab);
