import { connect } from 'react-redux';
import { reduxForm, isDirty } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

import { userUpdate } from '../../../../../../actions/userActions';

// components
import AccountForm from './AccountForm';

const createInitialValues = (props) => {
  const { currentUser } = props;

  const firstname = currentUser.get('firstname') || '';
  const lastname = currentUser.get('lastname') || '';
  const email = currentUser.get('email') || '';
  const phoneArea = currentUser.get('phone_area') || '';
  const phoneNumber = currentUser.get('phone_number') || '';
  const phone = `${phoneArea}${phoneNumber}`;

  const values = {
    firstname,
    lastname,
    email,
    phone,
  };

  return values;
};

const mapStateToProps = (state, props) => ({
  accountForm:   (state.get('form')).get('accountForm') || Map(),
  dirty:         isDirty('accountForm'),
  initialValues: createInitialValues(props),
});

const mapDispatchToProps = dispatch => ({
  updateUser: (params) => {
    dispatch(userUpdate(params));
  },
});

// schemas
const schema = {
  firstname: {
    presence: { allowEmpty: false },
  },
  lastname: {
    presence: { allowEmpty: false },

    length: {
      maximum: 100,
    },
  },
  email: {
    presence: { allowEmpty: false },

    email: {
      message: 'not valid',
    },

    length: {
      maximum: 100,
    },
  },
  phone: {
    length: {
      maximum: 10,
    },
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedAccountForm = reduxForm({
  form:               'accountForm',
  enableReinitialize: true,
  validate:           validateForm,
  onChange:           handleChange,
})(AccountForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedAccountForm);
