import { Map } from 'immutable';

// constants
import { NOTE_FETCH_FOR_PNM } from '../constants/noteTypes';
import { CURRENT_CHAPTER_FETCH } from '../constants/chapterTypes';
import { TAG_BULK_UPDATE } from '../constants/tagTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case TAG_BULK_UPDATE: return httpReducer(state, action, { entity: 'tag', updateResult: false });
    case NOTE_FETCH_FOR_PNM: return httpReducer(state, action, { entity: 'tag', updateResult: false });
    case CURRENT_CHAPTER_FETCH: return httpReducer(state, action, { entity: 'tag', updateResult: false });
    default: return state;
  }
};
