import { connect } from 'react-redux';
import Immutable, { Map } from 'immutable';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';

// components
import NewMemberCard from './MemberInfoForm';

// schemas
const schema = ({ isEduEmailAllowed }) => ({
  email: {
    presence: { allowEmpty: false },

    email: {
      message: 'not valid',
    },

    length: {
      maximum: 100,
    },

    format: isEduEmailAllowed ? undefined : {
      pattern: /^(?!.*\.edu$).*$/,
      message: 'cannot end with \'.edu\' domain',
    },
  },

  phone: {
    presence: { allowEmpty: true },

    length: {
      maximum: 40,
    },
  },
});

const createInitialValues = (props) => {
  const { pnms } = props;

  const values = pnms.map((p) =>
    Immutable.fromJS({
      id:      p.get('id'),
      checked: true,
      email:   p.get('email'),
      phone:   p.get('phone').replace(/\D/g, ''),
    }));

  return values;
};

const mapStateToProps = (state, props) => ({
  reporting:      state.get('reporting'),
  initialValues:  Immutable.fromJS({ pnms: createInitialValues(props) }),
  memberInfoForm: state.getIn(['form', 'memberInfoForm'], Map()),
});

const validateForm = function (form, props) {
  const pnms = form.get('pnms').toJS() || [];

  let error = false;

  const validations = pnms.map((pnm) => {
    if (!pnm.checked) return null;

    const validation = validate(pnm, schema(props));

    if (validation) {
      error = true;
    }

    return validation;
  });

  if (error) {
    return validations;
  }

  return undefined;
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) {
    stopSubmit();
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form:               'memberInfoForm',
    enableReinitialize: true,
    validate:           validateForm,
    onChange:           handleChange,
  })(NewMemberCard)
);
