import {
  HOMETOWN_GROUP_CREATE_INIT,
  HOMETOWN_GROUP_REMOVE_INIT,
  HOMETOWN_GROUP_BULK_UPDATE_INIT,
} from '../constants/hometownGroupTypes';

export function hometownGroupCreate(params) {
  return {
    type:    HOMETOWN_GROUP_CREATE_INIT,
    payload: params,
  };
}

export function hometownGroupRemove(hometownGroup, chapter) {
  return {
    type:    HOMETOWN_GROUP_REMOVE_INIT,
    payload: { hometownGroup, chapter },
  };
}

export function hometownGroupBulkUpdate(items, chapter) {
  return {
    type:    HOMETOWN_GROUP_BULK_UPDATE_INIT,
    payload: {
      items,
      chapter,
    },
  };
}
