import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';
import { FieldArray } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import BumpGroupItem from '../BumpGroupItem';

class BumpGroupsForm extends Component {
  static propTypes = {
    activeField:          PropTypes.string.isRequired,
    bumpGroup:            PropTypes.instanceOf(Map).isRequired,
    bumpGroups:           PropTypes.instanceOf(List).isRequired,
    currentChapterId:     PropTypes.number.isRequired,
    formValues:           PropTypes.instanceOf(List).isRequired,
    removeBumpGroup:      PropTypes.func.isRequired,
    updateBumpGroup:      PropTypes.func.isRequired,
    user:                 PropTypes.instanceOf(Map).isRequired,
    userSuggestions:      PropTypes.instanceOf(Array).isRequired,
    fetchUsersForChapter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        snackbarMessage: '',
        snackbarOpen:    false,
      }),
    };
  }

  handleSnackbarOpen = (status) => {
    if (status === 'success') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Removed Successfully');
        }),
      });
    } else {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Remove Failed');
        }),
      });
    }
  }

  handleSnackbarClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  }

  renderBumpGroupList = ({ fields }) => {
    const {
      activeField,
      currentChapterId,
      removeBumpGroup,
      formValues,
      updateBumpGroup,
      bumpGroup,
      bumpGroups,
      fetchUsersForChapter,
      user,
      userSuggestions,
    } = this.props;

    return fields.map((field, index) => {
      const bumpGroupItem = bumpGroups.get(index) || Map();

      return (
        <BumpGroupItem key={ bumpGroupItem.get('_id') }
          activeField={ activeField }
          updateBumpGroup={ updateBumpGroup }
          bumpGroup={ bumpGroup }
          bumpGroups={ bumpGroups }
          formValues={ formValues }
          currentChapterId={ currentChapterId }
          field={ field }
          handleSnackbarOpen={ this.handleSnackbarOpen }
          index={ index }
          removeBumpGroup={ removeBumpGroup }
          user={ user }
          fetchUsersForChapter={ fetchUsersForChapter }
          userSuggestions={ userSuggestions } />
      );
    });
  }

  render() {
    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');

    return (
      <Grid container component='form' spacing={ 24 }>
        <FieldArray name='bumpGroups' component={ this.renderBumpGroupList } />

        <Snackbar key='remove'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ () => this.handleSnackbarClose() }
          message={ snackbarMessage } />
      </Grid>
    );
  }
}

export default BumpGroupsForm;
