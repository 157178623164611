const checkboxInputStyles = () => ({
  formControlLabel: {
    '&.small': {
      marginLeft: -7,
    },
  },
  formControlCheckbox: {
    '&.small': {
      padding: 5,
    },
  },
});

export default checkboxInputStyles;
