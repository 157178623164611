import PropTypes from 'prop-types';
import React from 'react';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

function FixedRow(props) {
  FixedRow.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    type:    PropTypes.string.isRequired,
    colSpan: PropTypes.number.isRequired,
  };

  const {
    classes, colSpan, type,
  } = props;

  let label;
  let icon;
  switch (type) {
    case 'flexPlus':
      label = 'Flex +';
      icon = 'arrow_upward';
      break;

    case 'flexMinus':
      label = 'Flex -';
      icon = 'arrow_downward';
      break;

    case 'invitations':
      label = 'Invitations';
      icon = 'arrow_upward';
      break;

    default:
      label = 'Fixed row';
      icon = 'star';
      break;
  }

  return (
    <tr className={ classes.fixedRow }>
      <TableCell colSpan={ colSpan }
        className={ classes.fixedCell }>
        <Grid container spacing={ 24 } alignItems='center'>
          <Icon className={ classes.fixedRowIcon }>{ icon }</Icon>
          <Typography color='inherit'>{ label }</Typography>
        </Grid>
      </TableCell>
    </tr>
  );
}

const styles = theme => ({
  fixedRow: {
    color:           'inherit',
    height:          48,
    display:         'table-row',
    verticalAlign:   'middle',
    backgroundColor: theme.palette.primary.main,
  },

  fixedCell: {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.common.white,
  },

  fixedRowIcon: {
    marginRight: 10,
  },
});

export default withStyles(styles)(FixedRow);
