import React from 'react';
import PropTypes from 'prop-types';

// mui components
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// local components
import Space from '../../../Space';

// helpers
import { isEmpty } from '../../../../helpers/check';
import { toString } from '../../../../helpers/transform';

// styles
import categoryLabelStyles from './categoryLabel.style';

class CategoryLabel extends React.PureComponent {
  static propTypes = {
    classes:       PropTypes.instanceOf(Object).isRequired,
    name:          PropTypes.string.isRequired,
    roundType:     PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    roundTypeName: PropTypes.string.isRequired,
    nowrapInline:  PropTypes.bool,
  }

  static defaultProps = {
    nowrapInline: false,
  }

  render() {
    const {
      classes,
      name,
      roundType,
      roundTypeName,
      nowrapInline,
    } = this.props;
    const newRoundType = toString(roundType);
    const rootClasses = nowrapInline ? ' nowrapInline' : '';

    return (
      <div className={ `${classes.root}${rootClasses}` }>
        {name}
        {!isEmpty(newRoundType) && (
          <>
            <Space type='small' />
            (
            {!isEmpty(roundTypeName) ? (
              <Tooltip title={ roundTypeName }>
                <span className={ classes.dot }>
                  {newRoundType}
                </span>
              </Tooltip>
            ) : newRoundType}
            )
          </>
        )}
      </div>
    );
  }
}

export default withStyles(categoryLabelStyles)(CategoryLabel);
