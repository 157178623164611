import { CONCERN_CATEGORY_CREATE_INIT, CONCERN_CATEGORY_REMOVE_INIT, CONCERN_CATEGORY_BULK_UPDATE_INIT } from '../constants/concernCategoryTypes';

export function concernCategoryCreate(params) {
  return {
    type:    CONCERN_CATEGORY_CREATE_INIT,
    payload: params,
  };
}

export function concernCategoryRemove(id) {
  return {
    type:    CONCERN_CATEGORY_REMOVE_INIT,
    payload: { id },
  };
}

export function concernCategoryBulkUpdate(items) {
  return {
    type:    CONCERN_CATEGORY_BULK_UPDATE_INIT,
    payload: {
      items,
    },
  };
}
