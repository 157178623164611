import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import moment from 'moment';

// components
import TextInput from '../../../../../TextInput';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function MemberDateForm(props) {
  MemberDateForm.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
  };

  const { classes } = props;

  return (
    <Grid item xs={ 12 } className={ classes.dateContainer }>
      <Grid container spacing={ 24 }>
        <Grid item xs={ 12 }>
          <Typography>Enter Bid Day Date</Typography>
        </Grid>

        <Grid item xs={ 12 }>
          <Field name='date'
            type='date'
            component={ TextInput }
            variant='outlined'
            max={ moment().format('YYYY-MM-DD') }
            placeholder='Enter Date'
            required />
        </Grid>
      </Grid>

    </Grid>
  );
}

const styles = () => ({
  dateContainer: {
    marginTop:    20,
    marginBottom: 20,
  },
});

export default withStyles(styles)(MemberDateForm);
