import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { List, Map } from 'immutable';

// components
import Button from '../Button';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

class MultiSelectInput extends Component {
  static propTypes = {
    classes:            PropTypes.instanceOf(Object).isRequired,
    containerClassName: PropTypes.string,
    disabled:           PropTypes.bool,
    displayClear:       PropTypes.bool,
    displaySelectAll:   PropTypes.bool,
    withoutSorting:     PropTypes.bool,
    errorClassName:     PropTypes.string,
    helpertext:         PropTypes.string,
    id:                 PropTypes.string,
    input:              PropTypes.instanceOf(Object).isRequired,
    label:              PropTypes.string,
    meta:               PropTypes.instanceOf(Object).isRequired,
    options:            PropTypes.instanceOf(List),
  }

  static defaultProps = {
    containerClassName: '',
    disabled:           false,
    displayClear:       true,
    displaySelectAll:   true,
    withoutSorting:     false,
    errorClassName:     '',
    helpertext:         '',
    id:                 '',
    label:              '',
    options:            [],
  }

  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0,
    };
  }

  componentDidMount() {
    this.setState({ // eslint-disable-line
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth, // eslint-disable-line
    });
  }

  shouldDisplayError = () => {
    const {
      meta: {
        active,
        dirty,
        error,
        touched,
      },
    } = this.props;

    return error && !active && (dirty || touched);
  }

  handleSelectAll = () => {
    const { input, options } = this.props;

    input.onChange(options.map(o => o.get('value')).toJS());
  }

  handleClearSelected = () => {
    const { input } = this.props;

    input.onChange([]);
  }

  renderError = () => {
    const { helpertext, errorClassName, meta: { error } } = this.props;

    let element;

    if (this.shouldDisplayError()) {
      element = (
        <FormHelperText className={ errorClassName } error>
          { error[0] }
        </FormHelperText>
      );
    } else {
      element = (
        <FormHelperText>
          { helpertext }
        </FormHelperText>
      );
    }

    return element;
  }

  renderChips = (selected) => {
    const { classes, options } = this.props;

    const chips = [];
    let othersCount = 0;
    const others = [];

    selected.forEach((value, index) => {
      const label = (options.find(option => option.get('value') === value) || Map()).get('label');

      if (index > 2 && index < 16) {
        others.push(label);

        if (index === 15) {
          others.push('...');
        }

        othersCount += 1;
      } else if (index <= 2) {
        chips.push(<Chip key={ value }
          label={ label }
          className={ classes.chip } />);
      }
    });

    if (othersCount) {
      const othersString = others.join(', ');

      chips.push(
        <Tooltip title={ othersString } key='others'>
          <Chip label={ `+ ${othersCount} others` }
            className={ classes.chip } />
        </Tooltip>
      );
    }

    return (
      <div className={ classes.chips }>
        { chips }
      </div>
    );
  }

  renderOptions = () => {
    const { options, withoutSorting } = this.props;

    const sortedOptions = withoutSorting ? options : options.sortBy(o => o.get('label'));

    const elements = [];

    sortedOptions.forEach((option) => {
      elements.push(
        <MenuItem key={ option.get('value') } value={ option.get('value') }>
          { option.get('label') }
        </MenuItem>
      );
    });

    return elements;
  }

  render() {
    const {
      classes,
      containerClassName,
      disabled,
      displayClear,
      displaySelectAll,
      errorClassName,
      id,
      input,
      label,
      options,
      ...rest
    } = this.props;

    const { value } = input || {};

    const formattedInput = {
      ...input || {},
      value: List.isList(value) ? value.toJS() : value || [],
    };

    const { labelWidth } = this.state;

    return (
      <FormControl variant='outlined' className={ classNames(containerClassName, classes.formControl) }>
        <InputLabel htmlFor='select-multiple-chip'
          ref={ (ref) => {
            this.InputLabelRef = ref;
          } }>
          {label}
        </InputLabel>

        <Select id={ id }
          multiple
          error={ this.shouldDisplayError() }
          inputProps={ { id, ...formattedInput } }
          value={ formattedInput.value }
          input={ (
            <OutlinedInput id='select-multiple-chip'
              disabled={ disabled }
              name={ id }
              classes={ { inputMarginDense: classes.input } }
              labelWidth={ labelWidth } />
          ) }
          renderValue={ selected => this.renderChips(selected) }
          { ...rest }>

          { this.renderOptions() }
        </Select>

        { this.renderError() }

        { (displaySelectAll || displayClear)
          && (
          <Grid container>
            { displaySelectAll
              && (
              <Grid item xs={ 5 }>
                <Button variant='outlined' onClick={ this.handleSelectAll }>Select All</Button>
              </Grid>
              )}
            { displayClear
              && (
              <Grid item xs={ 7 }>
                <Button variant='outlined' onClick={ this.handleClearSelected }>Clear</Button>
              </Grid>
              )}
          </Grid>
          )}

      </FormControl>
    );
  }
}

const styles = theme => ({
  formControl: {
    width:  '100%',
    margin: theme.spacing.unit,
  },

  input: {
    paddingBottom: 25,
  },

  chips: {
    display:  'flex',
    flexWrap: 'wrap',
  },

  chip: {
    margin: theme.spacing.unit / 4,
  },
});

export default withStyles(styles)(MultiSelectInput);
