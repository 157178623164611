import { connect } from 'react-redux';
import { reduxForm, isDirty, change } from 'redux-form/immutable';
import validate from 'validate.js';
import { List, Map } from 'immutable';

// components
import ModalAdvancedOptions from './ModalAdvancedOptions';

// action creators
import { userUpdate } from '../../../../actions/userActions';
import { setOpenModalAdvancedOptions } from '../../../../actions/modalAdvancedOptions';

// helpers
import getTags from '../../../../lib/selectors/getTags';
import getFields from '../../../../lib/selectors/getFields';
import getOrganization from '../../../../lib/selectors/getOrganization';
import getUserAttribute from '../../../../lib/selectors/getUserAttribute';
import getModalAdvancedOptionsAttribute from '../../../../lib/selectors/getModalAdvancedOptionsAttribute';
import { isEmpty } from '../../../../helpers/check';
import { toArray } from '../../../../helpers/transform';

const FORM_NAME = 'advancedDisplayOptionForm';

const getRounds = ({ rounds }) => (rounds && List.isList(rounds) ? rounds : List());

const getDefaultRounds = (rounds) => {
  const defaultRounds = {};
  rounds.toJS().forEach(({ _id: id } = {}) => {
    if (!isEmpty(id)) {
      defaultRounds[id] = true;
    }
  });

  return { ...defaultRounds };
};

const createInitialValues = (state, props, customFields) => {
  const advancedDisplayOption = getUserAttribute('advancedDisplayOption')(state);

  const {
    includeGPA1 = false,
    includeGPA2 = false,
    includePnmTags = false,
    includePnmImages = false,
    includePnmStatus = false,
    displayOverallScores = false,
    displayRoundScores = false,
    displayCategoryScores = false,
    displayRoundScoresList = {},
    displayCategoryScoresList = {},
    selectedField = [],
    wasAdvancedDisplayOptionChanged = false,
  } = Map.isMap(advancedDisplayOption) ? advancedDisplayOption.toJS() : {};

  const customFieldsList = List.isList(customFields) ? customFields : List();

  const values = {
    includeGPA1:            wasAdvancedDisplayOptionChanged ? includeGPA1 : true,
    includeGPA2:            wasAdvancedDisplayOptionChanged ? includeGPA2 : true,
    includePnmTags:         wasAdvancedDisplayOptionChanged ? includePnmTags : true,
    includePnmImages,
    includePnmStatus,
    displayOverallScores:   wasAdvancedDisplayOptionChanged ? displayOverallScores : true,
    displayRoundScores:     wasAdvancedDisplayOptionChanged ? displayRoundScores : true,
    displayCategoryScores,
    displayRoundScoresList: wasAdvancedDisplayOptionChanged
      ? displayRoundScoresList : getDefaultRounds(getRounds(props)),
    displayCategoryScoresList,
    selectedField: toArray(selectedField)
      .filter(item => item && item.value && customFieldsList.find(customField => customField.getIn(['standardField', 'id'], 0) === item.value)),
    selectedFieldOneValue: Map(),
  };

  return values;
};

const mapStateToProps = (state, props) => {
  const customFields = getFields(state);
  return {
    dirty:                     isDirty(FORM_NAME),
    field:                     state.get('field'),
    advancedDisplayOptionForm: state.getIn(['form', FORM_NAME], Map()),
    initialValues:             createInitialValues(state, props, customFields),
    fields:                    customFields,
    organization:              getOrganization(state),
    tags:                      getTags(state),
    rounds:                    getRounds(props),
    currentUserId:             getUserAttribute('id')(state),
    open:                      getModalAdvancedOptionsAttribute('open')(state),
    formName:                  FORM_NAME,
  };
};

const mapDispatchToProps = dispatch => ({
  updateUser: (params) => {
    dispatch(userUpdate(params));
  },
  setOpenModalAdvancedOptions: (open) => {
    dispatch(setOpenModalAdvancedOptions(open));
  },
  clearSelectedFields: () => {
    dispatch(change(FORM_NAME, 'selectedField', []));
  },
});

// schemas
const schema = {};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedDisplayOptionsForm = reduxForm({
  form:               FORM_NAME,
  validate:           validateForm,
  enableReinitialize: true,
  onChange:           handleChange,
})(ModalAdvancedOptions);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedDisplayOptionsForm);
