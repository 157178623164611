import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

// MUI components
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// local components
import Profile from './components/Profile';
import Specialties from './components/Specialties';
import MatchList from './components/MatchList';
import GroupMembers from './components/GroupMembers';

function HandMatchTab(props) {
  HandMatchTab.propTypes = {
    bumpGroups:               PropTypes.instanceOf(List).isRequired,
    groupMembersFormIsActive: PropTypes.bool.isRequired,
    match:                    PropTypes.instanceOf(Object).isRequired,
    matchLoading:             PropTypes.bool.isRequired,
    matches:                  PropTypes.instanceOf(List).isRequired,
    pathname:                 PropTypes.string.isRequired,
    removeUserSpecialty:      PropTypes.func.isRequired,
    userLoading:              PropTypes.bool.isRequired,
    userSpecialties:          PropTypes.instanceOf(List).isRequired,
    users:                    PropTypes.instanceOf(List).isRequired,
  };

  const {
    bumpGroups,
    groupMembersFormIsActive,
    match: { params: { id } },
    matchLoading,
    matches,
    pathname,
    removeUserSpecialty,
    users,
    userLoading,
    userSpecialties,
  } = props;

  const loading = pathname.includes('user')
    ? userLoading
    : userLoading && !groupMembersFormIsActive;

  const selectedItem = pathname.includes('user')
    ? users.find((u = Map()) => u.get('id', '').toString() === id) || Map()
    : bumpGroups.find(bg => bg.get('_id', '').toString() === id.toString()) || Map();

  const renderTabContent = () => {
    let element;

    if (id !== '0') {
      element = (
        <Grid container spacing={ 24 }>
          <Grid item xs={ 12 } sm={ 4 }>
            <Profile selectedItem={ selectedItem }
              matchLoading={ matchLoading }
              pathname={ pathname }
              userSpecialties={ userSpecialties } />
          </Grid>

          <Grid item xs={ 12 } sm={ 8 }>
            { pathname.includes('user')
              ? (
                <Specialties selectedItem={ selectedItem }
                  userSpecialties={ userSpecialties }
                  removeUserSpecialty={ removeUserSpecialty }
                  pathname={ pathname } />
              )
              : (
                <GroupMembers selectedItem={ selectedItem }
                  pathname={ pathname } />
              )}
          </Grid>

          <Grid item xs={ 12 }>
            <MatchList selectedItem={ selectedItem }
              matchLoading={ matchLoading }
              matches={ matches }
              pathname={ pathname } />
          </Grid>
        </Grid>
      );
    } else {
      element = (
        <Grid container style={ { marginTop: 25 } }>
          <Grid item xs={ 12 }>
            <Typography variant='h6' color='primary' align='center'>
              Select an item from the side list to view and create matches
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return element;
  };

  return (
    <div>
      { loading
        ? (
          <Grid container spacing={ 24 } justify='center'>
            <Grid item xs={ 12 } style={ { textAlign: 'center', paddingTop: 30 } }>
              <CircularProgress />
            </Grid>
          </Grid>
        )
        : renderTabContent() }
    </div>
  );
}
export default HandMatchTab;
