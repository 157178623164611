import { normalize } from 'normalizr';
import { put } from 'redux-saga/effects';

// action creators
import {
  errorIndicator,
  load,
  success,
  successIndicator,
} from '../actions/httpActions';

export default function* infiniteScrollGenerator(type, request, options = {}) {
  const { infiniteScroll } = options;
  let { schema } = options;

  yield put(load(type));

  try {
    let response = yield request;

    if (!response.data) {
      response = { data: response };
    }

    if (response && schema) {
      if (Array.isArray(response.data) && !Array.isArray(schema)) {
        schema = [schema];
      }

      const normalizedData = normalize(response.data, schema);

      response.normalized = {
        entity: (schema[0] || schema).key,
        ...normalizedData,
      };
    }

    yield put(successIndicator(type));

    yield put(success(type, response, { infiniteScroll }));

    return response;
  } catch (err) {
    yield put(errorIndicator(type));

    throw err;
  }
}
