import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';

// MUI components
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

// local components
import EnhancedTableHead from './components/EnhancedTableHead';
import TableToolbar from './components/TableToolbar';
import TableActions from './components/TableActions';
import EnhancedTableRow from './components/EnhancedTableRow';

function SpecialtiesTable(props) {
  SpecialtiesTable.propTypes = {
    classes:                  PropTypes.instanceOf(Object).isRequired,
    createUserSpecialty:      PropTypes.func.isRequired,
    currentChapterId:         PropTypes.number.isRequired,
    currentUserId:            PropTypes.number.isRequired,
    fetchAllSpecialties:      PropTypes.func.isRequired,
    clearUserSpecialties:     PropTypes.func.isRequired,
    bulkFetchUserSpecialties: PropTypes.func.isRequired,
    fetchUsersForChapter:     PropTypes.func.isRequired,
    removeUserSpecialty:      PropTypes.func.isRequired,
    specialties:              PropTypes.instanceOf(List).isRequired,
    userLoading:              PropTypes.bool.isRequired,
    userSpecialties:          PropTypes.instanceOf(List).isRequired,
    users:                    PropTypes.instanceOf(List).isRequired,
  };

  const {
    clearUserSpecialties,
    currentChapterId,
    fetchAllSpecialties,
    fetchUsersForChapter,
    bulkFetchUserSpecialties,
    users,
    classes,
    createUserSpecialty,
    removeUserSpecialty,
    userLoading,
    currentUserId,
    specialties,
    userSpecialties,
  } = props;

  const [order, setOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('firstname');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Initial render
  useEffect(() => {
    const userIds = users.map(u => u.get('id'));

    fetchUsersForChapter({
      chapter:              currentChapterId,
      shouldAddMatchCounts: true,
    });

    fetchAllSpecialties();

    clearUserSpecialties();
    bulkFetchUserSpecialties({ userIds });
  }, []);

  // When specialties load in
  useEffect(() => {
    const userIds = users.map(u => u.get('id'));

    fetchAllSpecialties();
    bulkFetchUserSpecialties({ userIds });
  }, [specialties.size]);

  const onSortChange = (property) => {
    const newSortBy = property;
    let newOrder = 'desc';

    if (sortBy === property && order === 'desc') {
      newOrder = 'asc';
    }

    setOrder(newOrder);
    setSortBy(newSortBy);
  };

  const getSpecialtiesForUser = user => userSpecialties.filter(us =>
    us.getIn(['user', 'id'], us.get('user')) === user.get('id')) || List();

  const sortMatches = () => {
    let sortedMatches = users;

    switch (sortBy) {
      case 'firstname':
        sortedMatches = users.sortBy(u => u.get('firstname'));
        break;
      case 'lastname':
        sortedMatches = users.sortBy(u => u.get('lastname'));
        break;
      default:
        break;
    }

    if (order === 'desc') {
      sortedMatches = sortedMatches.reverse();
    }

    return sortedMatches;
  };

  const renderLoadingIndicator = () => {
    const loading = userLoading || false;
    let element;

    if (loading) {
      element = (
        <TableRow>
          <TableCell colSpan={ 8 } className={ classes.spinner }>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    return element;
  };

  const numCols = 2 + specialties.size;
  const orderedUsers = sortMatches()
    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

  return (
    <div>
      <Paper className={ classes.root }>
        <TableToolbar icon='local_offer'
          items={ orderedUsers }
          subtitle={ 'View and manage your members\' specialties' }
          title='Bulk Assign Specialties' />

        <div className={ classes.tableWrapper }>

          <Table className={ classes.table }>
            <EnhancedTableHead numCols={ numCols }
              order={ order }
              sortBy={ sortBy }
              specialties={ specialties }
              onSortChange={ onSortChange }
              rowCount={ users.size || 0 } />

            <TableBody>
              { renderLoadingIndicator() }

              { (users.size === 0 && !userLoading) ? (
                <TableRow>
                  <TableCell colSpan={ 1 } padding='dense'>
                    <Icon>error_outline</Icon>
                  </TableCell>
                  <TableCell colSpan={ numCols } padding='none'>
                    <Typography variant='subtitle1'>
                      No Specialties Found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : orderedUsers.map(u => (
                <EnhancedTableRow key={ u.get('id') }
                  numCols={ numCols }
                  removeUserSpecialty={ removeUserSpecialty }
                  createUserSpecialty={ createUserSpecialty }
                  specialties={ specialties }
                  currentUserId={ currentUserId }
                  userSpecialties={ getSpecialtiesForUser(u) }
                  user={ u } />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination ActionsComponent={ TableActions }
                  backIconButtonProps={ {
                    'aria-label': 'Previous Page',
                  } }
                  nextIconButtonProps={ {
                    'aria-label': 'Next Page',
                  } }
                  onChangePage={ (e, pageNum) => setPage(pageNum) }
                  onChangeRowsPerPage={ e => setRowsPerPage(e.target.value) }
                  page={ page }
                  rowsPerPage={ rowsPerPage }
                  count={ users.size } />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    </div>
  );
}

const styles = () => ({
  root: {
    width:        '100%',
    marginTop:    20,
    height:       'auto',
    overflowY:    'scroll',
    borderRadius: 10,
  },

  table: {
    minWidth: 500,
  },

  tableWrapper: {
    overflowX: 'visible',
  },

  spinner: {
    textAlign: 'center',
  },
});

export default withStyles(styles)(SpecialtiesTable);
