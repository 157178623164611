import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import inviteService from '../../sources/api/inviteService';

// constants
import { INVITE_BULK_CREATE, INVITE_BULK_CREATE_INIT } from '../../constants/inviteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { inviteListSchema } from '../../schemas/invite';

export function* inviteBulkCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'invite';

  yield put(startSubmit(FORM));

  const { items, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(INVITE_BULK_CREATE, call(create, inviteService, items, options), {
      schema: inviteListSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(INVITE_BULK_CREATE, err));
  } finally {
    yield put(doneIndicator(INVITE_BULK_CREATE));
  }
}

export function* watchInviteBulkCreate() {
  yield takeEvery(INVITE_BULK_CREATE_INIT, inviteBulkCreate);
}

export default function* root() {
  yield all([
    fork(watchInviteBulkCreate),
  ]);
}
