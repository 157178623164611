import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove } from '../../sources/api/client';
import fieldService from '../../sources/api/fieldService';

// constants
import { FIELD_REMOVE, FIELD_REMOVE_INIT } from '../../constants/fieldTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { fieldSchema } from '../../schemas/field';

export function* fieldRemove(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id, chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  try {
    yield* httpSaga(FIELD_REMOVE, call(remove, fieldService, id, options), {
      schema: fieldSchema,
    });
  } catch (err) {
    yield put(error(FIELD_REMOVE, err));
  } finally {
    yield put(doneIndicator(FIELD_REMOVE));
  }
}

export function* watchFieldRemove() {
  yield takeEvery(FIELD_REMOVE_INIT, fieldRemove);
}

export default function* root() {
  yield all([
    fork(watchFieldRemove),
  ]);
}
