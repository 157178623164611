export const INVITE_CREATE = 'INVITE_CREATE';
export const INVITE_CREATE_INIT = 'INVITE_CREATE_INIT';
export const INVITE_CREATE_DONE = 'INVITE_CREATE_DONE';

export const INVITE_BULK_CREATE = 'INVITE_BULK_CREATE';
export const INVITE_BULK_CREATE_INIT = 'INVITE_BULK_CREATE_INIT';
export const INVITE_BULK_CREATE_DONE = 'INVITE_BULK_CREATE_DONE';

export const INVITE_FETCH = 'INVITE_FETCH';
export const INVITE_FETCH_INIT = 'INVITE_FETCH_INIT';
export const INVITE_FETCH_DONE = 'INVITE_FETCH_DONE';

export const INVITE_FETCH_ALL_FOR_CHAPTER = 'INVITE_FETCH_ALL_FOR_CHAPTER';
export const INVITE_FETCH_ALL_FOR_CHAPTER_INIT = 'INVITE_FETCH_ALL_FOR_CHAPTER_INIT';
export const INVITE_FETCH_ALL_FOR_CHAPTER_DONE = 'INVITE_FETCH_ALL_FOR_CHAPTER_DONE';

export const INVITE_DELETE = 'INVITE_DELETE';
export const INVITE_DELETE_INIT = 'INVITE_DELETE_INIT';
export const INVITE_DELETE_DONE = 'INVITE_DELETE_DONE';

export const INVITE_UPDATE = 'INVITE_UPDATE';
export const INVITE_UPDATE_INIT = 'INVITE_UPDATE_INIT';
export const INVITE_UPDATE_DONE = 'INVITE_UPDATE_DONE';
