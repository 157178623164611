import { schema } from 'normalizr';

// schemas
import { categoryListSchema } from './category';

export const roundCategorySchema = new schema.Entity('roundCategory', {
  categories: categoryListSchema,
}, { idAttribute: '_id' });

export const roundCategoryListSchema = [roundCategorySchema];
