import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';
import { FieldArray } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import Savebar from '../../../../../Savebar';
import TagItem from '../TagItem';

class TagsForm extends Component {
  static propTypes = {
    bulkUpdateTags:   PropTypes.func.isRequired,
    createTag:        PropTypes.func.isRequired,
    currentChapter:   PropTypes.instanceOf(Map).isRequired,
    currentChapterId: PropTypes.number.isRequired,
    initializeForm:   PropTypes.func.isRequired,
    initialValues:    PropTypes.instanceOf(Object).isRequired,
    manualDirty:      PropTypes.bool.isRequired,
    removeTag:        PropTypes.func.isRequired,
    reset:            PropTypes.func.isRequired,
    tagLoading:       PropTypes.bool.isRequired,
    tagStatus:        PropTypes.bool.isRequired,
    tags:             PropTypes.instanceOf(List).isRequired,
    tagsForm:         PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        autoHideDuration: null,
        hideButtons:      false,
        savebarMessage:   'Save your changes?',
        savebarOpen:      false,
        snackbarMessage:  '',
        snackbarOpen:     false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      manualDirty, tagsForm, reset, initializeForm, initialValues,
    } = nextProps;
    const savebarOpen = this.state.data.get('savebarOpen');
    const oldSize = (tagsForm.getIn(['values', 'tags'], List())).size;
    const newSize = initialValues.get('tags', List()).size;
    if (oldSize !== newSize) {
      initializeForm(initialValues);
    }

    if (!savebarOpen) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', manualDirty);
          map.set('savebarMessage', 'Save your changes?');
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    } else if (tagsForm.get('submitSucceeded')) {
      initializeForm(initialValues);
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Saved Successfully');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    } else if (tagsForm.get('submitFailed')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save Failed');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    }
  }

  handleCloseSavebar = (event, reason) => {
    if (reason === 'timeout') {
      this.setState({ data: this.state.data.set('savebarOpen', false) });
    }
  }

  handleSnackbarOpen = (status) => {
    if (status === 'success') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Removed Successfully');
        }),
      });
    } else {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('snackbarOpen', true);
          map.set('snackbarMessage', 'Remove Failed');
        }),
      });
    }
  }

  handleSnackbarClose = () => {
    this.setState({ data: this.state.data.set('snackbarOpen', false) });
  }

  handleSave = () => {
    const {
      currentChapterId, tagsForm, bulkUpdateTags, createTag,
    } = this.props;

    const tags = tagsForm.getIn(['values', 'tags'], List());
    const cdTags = tags.filter(t => t.get('type') === 'campusDirector' && t.get('chapter') === 0).toJS();
    const chapterTags = tags.filter(t => t.get('chapter') === currentChapterId);

    if (!tagsForm.get('syncErrors')) {
      if (cdTags.length) {
        const values = cdTags.map(t => ({
          chapter:   currentChapterId,
          title:     t.title,
          color:     t.color,
          usableBy:  t.usableBy,
          visibleTo: t.visibleTo,
          removed:   0,
          order:     t.order,
          type:      t.type,
          group:     1,
        }));

        createTag({
          chapter: currentChapterId,
          values,
        });
      }
      bulkUpdateTags(chapterTags, currentChapterId);
    }
  }

  handleCancel = () => {
    const { reset } = this.props;

    reset();
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('savebarMessage', 'Changes reset.');
        map.set('autoHideDuration', 2000);
        map.set('hideButtons', true);
      }),
    });
  }

  renderTagList = ({ fields }) => {
    const {
      currentChapter, removeTag, tagLoading, tagStatus, tags, tagsForm,
    } = this.props;

    return fields.map((field, index) => {
      const tagItem = tags.get(index) || Map();

      return (
        <TagItem key={ tagItem.get('_id') }
          currentChapter={ currentChapter }
          tagsForm={ tagsForm }
          field={ field }
          index={ index }
          removeTag={ removeTag }
          handleSnackbarOpen={ this.handleSnackbarOpen }
          tagStatus={ tagStatus }
          tagLoading={ tagLoading }
          tags={ tags } />
      );
    });
  }

  render() {
    const { tagsForm } = this.props;

    const savebarOpen = this.state.data.get('savebarOpen');
    const savebarMessage = this.state.data.get('savebarMessage');
    const hideButtons = this.state.data.get('hideButtons');
    const autoHideDuration = this.state.data.get('autoHideDuration');
    const snackbarOpen = this.state.data.get('snackbarOpen');
    const snackbarMessage = this.state.data.get('snackbarMessage');

    return (
      <Grid container spacing={ 24 }>
        <FieldArray name='tags' component={ this.renderTagList } />

        <Savebar open={ savebarOpen }
          autoHideDuration={ autoHideDuration }
          form={ tagsForm }
          handleAccept={ this.handleSave }
          handleReject={ this.handleCancel }
          handleClose={ this.handleCloseSavebar }
          hideButtons={ hideButtons }
          message={ savebarMessage } />

        <Snackbar key='remove'
          anchorOrigin={ {
            vertical:   'bottom',
            horizontal: 'right',
          } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ () => this.handleSnackbarClose() }
          message={ snackbarMessage } />
      </Grid>
    );
  }
}

export default TagsForm;
