export const VOTE_FETCH_FOR_PNM = 'VOTE_FETCH_FOR_PNM';
export const VOTE_FETCH_FOR_PNM_INIT = 'VOTE_FETCH_FOR_PNM_INIT';
export const VOTE_FETCH_FOR_PNM_DONE = 'VOTE_FETCH_FOR_PNM_DONE';

export const VOTE_FETCH_ALL = 'VOTE_FETCH_ALL';
export const VOTE_FETCH_ALL_INIT = 'VOTE_FETCH_ALL_INIT';
export const VOTE_FETCH_ALL_DONE = 'VOTE_FETCH_ALL_DONE';

export const VOTE_FETCH_FOR_USER = 'VOTE_FETCH_FOR_USER';
export const VOTE_FETCH_FOR_USER_INIT = 'VOTE_FETCH_FOR_USER_INIT';
export const VOTE_FETCH_FOR_USER_DONE = 'VOTE_FETCH_FOR_USER_DONE';

export const VOTE_DELETE = 'VOTE_DELETE';
export const VOTE_DELETE_INIT = 'VOTE_DELETE_INIT';
export const VOTE_DELETE_DONE = 'VOTE_DELETE_DONE';

export const VOTE_CREATE = 'VOTE_CREATE';
export const VOTE_CREATE_INIT = 'VOTE_CREATE_INIT';
export const VOTE_CREATE_DONE = 'VOTE_CREATE_DONE';

export const VOTE_UPDATE = 'VOTE_UPDATE';
export const VOTE_UPDATE_INIT = 'VOTE_UPDATE_INIT';
export const VOTE_UPDATE_DONE = 'VOTE_UPDATE_DONE';

export const VOTE_UPDATE_COUNTS = 'VOTE_UPDATE_COUNTS';
export const VOTE_UPDATE_COUNTS_INIT = 'VOTE_UPDATE_COUNTS_INIT';
export const VOTE_UPDATE_COUNTS_DONE = 'VOTE_UPDATE_COUNTS_DONE';

export const VOTE_BULK_CREATE = 'VOTE_BULK_CREATE';
export const VOTE_BULK_CREATE_INIT = 'VOTE_BULK_CREATE_INIT';
export const VOTE_BULK_CREATE_DONE = 'VOTE_BULK_CREATE_DONE';

export const VOTE_CLEAR = 'VOTE_CLEAR';
