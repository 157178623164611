import { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { pnmListSchema } from '../../schemas/pnm';

export default function getPnmSuggestions(state) {
  const pnm = state.get('pnm');
  const suggestions = [];
  const pnmData = pnm.get('data') || Map();

  if (pnmData.get('result')) {
    const result = pnmData.get('result', List()).toJS();
    const entities = {
      pnm: (pnmData.get('items', Map())).toJS(),
    };

    const elements = denormalize(result, pnmListSchema, entities);

    elements.forEach((item) => {
      suggestions.push({
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      });
    });
  }

  return suggestions;
}
