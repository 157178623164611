import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

class TableActions extends React.Component {
  static propTypes = {
    classes:      PropTypes.instanceOf(Object).isRequired,
    count:        PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page:         PropTypes.number.isRequired,
    rowsPerPage:  PropTypes.number.isRequired,
    theme:        PropTypes.instanceOf(Object).isRequired,
  };

  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const {
      classes, count, page, rowsPerPage, theme,
    } = this.props;

    return (
      <div className={ classes.root }>
        <IconButton onClick={ this.handleFirstPageButtonClick }
          disabled={ page === 0 }
          aria-label='First Page'>
          <Icon>
            {theme.direction === 'rtl' ? 'last_page' : 'first_page'}
          </Icon>
        </IconButton>
        <IconButton onClick={ this.handleBackButtonClick }
          disabled={ page === 0 }
          aria-label='Previous Page'>
          <Icon>
            {theme.direction === 'rtl' ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
          </Icon>
        </IconButton>
        <IconButton onClick={ this.handleNextButtonClick }
          disabled={ page >= Math.ceil(count / rowsPerPage) - 1 }
          aria-label='Next Page'>
          <Icon>
            {theme.direction === 'rtl' ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
          </Icon>
        </IconButton>
        <IconButton onClick={ this.handleLastPageButtonClick }
          disabled={ page >= Math.ceil(count / rowsPerPage) - 1 }
          aria-label='Last Page'>
          <Icon>
            {theme.direction === 'rtl' ? 'first_page' : 'last_page'}
          </Icon>
        </IconButton>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexShrink: 0,
    color:      theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

export default withStyles(styles, { withTheme: true })(TableActions);
