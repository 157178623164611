import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'react-final-form';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import red from '@material-ui/core/colors/red';

// local components
import TextInput from '../../../../../TextInput';
import SelectInput from '../../../../../SelectInput';
import ActionModal from '../../../../../ActionModal';

class HometownGroupsItem extends Component {
    static propTypes = {
      classes:             PropTypes.instanceOf(Object).isRequired,
      currentChapter:      PropTypes.instanceOf(Map).isRequired,
      field:               PropTypes.string.isRequired,
      hometownGroups:      PropTypes.instanceOf(List).isRequired,
      values:              PropTypes.instanceOf(Map).isRequired,
      index:               PropTypes.number.isRequired,
      removeHometownGroup: PropTypes.func.isRequired,
      loading:             PropTypes.bool.isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        data: Immutable.fromJS({
          deleteModalOpen: false,
        }),
      };
    }

    getVisibilityOptions = () => {
      const items = [
        { value: 0, label: 'All' },
        { value: 1, label: 'Admins' },
        { value: 2, label: 'None' },
      ];

      return items;
    }

    handleOpen = () => {
      this.setState({ data: this.state.data.set('deleteModalOpen', true) });
    };

    handleClose = () => {
      this.setState({ data: this.state.data.set('deleteModalOpen', false) });
    };

    handleDelete = (index) => {
      const {
        currentChapter, hometownGroups, removeHometownGroup,
      } = this.props;
      const hometownGroupId = hometownGroups.get(index).get('id');
      const chapterId = (currentChapter.get('data')).get('id');

      removeHometownGroup(hometownGroupId, chapterId);

      this.handleClose();
    }

    render() {
      const {
        classes, index, field, loading, values,
      } = this.props;

      const deleteModalOpen = this.state.data.get('deleteModalOpen');

      const formValues = values.get('hometownGroups');
      const pnmCount = (formValues.get(index) || Map()).get('pnmCount');

      return (
        <div>
          <Paper className={ classes.itemContainer }>

            <Grid container
              id={ index }
              spacing={ 24 }
              className={ classes.itemWrapper }
              justify='flex-start'>

              <Grid item xs={ 3 } sm={ 1 }>
                <Tooltip id='hometown-move-tip'
                  placement='bottom'
                  title='Click and drag to reorder'>

                  <Avatar className={ classes.hometownIcon }>
                    <Icon>home</Icon>
                  </Avatar>
                </Tooltip>
              </Grid>

              <Grid item xs={ 12 } sm={ 2 } className={ classes.pnmCount } align='center'>
                <Typography variant='subtitle1'>
                  { pnmCount }
                  {' '}
                  { pnmCount === 1 ? 'PNM' : 'PNMs' }
                </Typography>
                <Typography color='textSecondary'>In Group</Typography>
              </Grid>

              <Grid item xs={ 9 } sm={ 5 } align='left'>
                <Field name={ `${field}.name` }
                  label='Hometown Name'
                  type='text'
                  variant='outlined'
                  component={ TextInput }
                  fullWidth
                  required />
              </Grid>

              <Grid item xs={ 12 } sm={ 3 }>
                <Field name={ `${field}.team_level` }
                  label='Visible To'
                  variant='outlined'
                  component={ SelectInput }
                  options={ this.getVisibilityOptions() }
                  fullWidth
                  required />
              </Grid>

              <Grid item xs={ 12 } sm={ 1 }>
                <IconButton className={ classes.leftIcon } onClick={ this.handleOpen }>
                  <Icon className={ classes.redButton }>
                    delete
                  </Icon>
                </IconButton>
              </Grid>

              <ActionModal open={ deleteModalOpen }
                description='This hometown group will no longer appear for any user on a PNM’s profile.'
                onAccept={ () => this.handleDelete(index) }
                onReject={ this.handleClose }
                acceptLabel='Delete'
                rejectLabel='Cancel'
                loading={ loading }
                title='Are you sure you want to delete this hometown group?' />
            </Grid>
          </Paper>
        </div>
      );
    }
}
const styles = theme => ({
  itemContainer: {
    padding:      15,
    borderRadius: 10,
    marginBottom: 15,
  },

  itemWrapper: {
    marginTop:    0,
    marginBottom: -24,
  },

  hometownIcon: {
    height:          50,
    width:           50,
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  pnmCount: {
    display: 'block',
  },

  redButton: {
    color: red[500],
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(HometownGroupsItem);
