import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import green from '@material-ui/core/colors/green';

// local components
import SwitchInput from '../../../SwitchInput';
import Savebar from '../../../Savebar';

// helpers
import capitalize from '../../../../lib/capitalize';

class OrganizationSettingsForm extends Component {
  static propTypes = {
    classes:                  PropTypes.instanceOf(Object).isRequired,
    currentUser:              PropTypes.instanceOf(Map).isRequired,
    dirty:                    PropTypes.bool.isRequired, // eslint-disable-line
    organization:             PropTypes.instanceOf(Map).isRequired,
    organizationSettingsForm: PropTypes.instanceOf(Map).isRequired,
    parentclasses:            PropTypes.instanceOf(Object).isRequired,
    reset:                    PropTypes.func.isRequired,
    updateOrganization:       PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        savebarOpen:      false,
        savebarMessage:   'Save your changes?',
        hideButtons:      false,
        autoHideDuration: null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      dirty, organizationSettingsForm, reset,
    } = nextProps;

    const savebarOpen = this.state.data.get('savebarOpen');

    if (!savebarOpen) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', dirty);
          map.set('savebarMessage', 'Save your changes?');
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    } else if (organizationSettingsForm.get('submitSucceeded')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Saved Successfully');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });

      reset();
    } else if (organizationSettingsForm.get('submitFailed')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save Failed');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });

      reset();
    }
  }

  handleCloseSavebar = (event, reason) => {
    if (reason === 'timeout') {
      this.setState({ data: this.state.data.set('savebarOpen', false) });
    }
  }

  handleSave = () => {
    const {
      organizationSettingsForm, currentUser, updateOrganization,
    } = this.props;

    const organizationId = currentUser.getIn(['data', 'organization'], 0);

    if (!organizationSettingsForm.get('syncErrors')) {
      const updateParams = {
        organizationId,
        formName: 'organizationSettingsForm',
        ...organizationSettingsForm.get('values').toJS(),
      };
      updateOrganization(updateParams);
    }
  }

  handleCancel = () => {
    const { reset } = this.props;

    reset();
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('savebarMessage', 'Changes reset.');
        map.set('autoHideDuration', 2000);
        map.set('hideButtons', true);
      }),
    });
  }

  renderSubmitMessage = (form) => {
    const { classes, organization } = this.props;
    let element;

    if (!form.get('syncErrors') && form.get('submitSucceeded') && (organization.get('status') || '') === 'success') {
      element = (
        <FormHelperText className={ classNames(classes.helperText, classes.success) }>
          Settings saved successfully.
        </FormHelperText>
      );
    } else if (form.get('submitFailed')) {
      element = (
        <FormHelperText className={ classes.helperText } error>
          Saving settings failed.
        </FormHelperText>
      );
    }

    return element;
  }

  render() {
    const {
      classes,
      organizationSettingsForm,
      parentclasses: { tabsCard, titleContainer, titleIcon },
      organization,
    } = this.props;

    const savebarOpen = this.state.data.get('savebarOpen');
    const savebarMessage = this.state.data.get('savebarMessage');
    const hideButtons = this.state.data.get('hideButtons');
    const autoHideDuration = this.state.data.get('autoHideDuration');

    const scoreLabel = organization.getIn(['labels', 'score'], 'Score');

    return (
      <div>
        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 8 }>

              <Grid item xs={ 12 } className={ titleContainer }>
                <Icon className={ titleIcon } color='primary'>settings</Icon>
                <Typography variant='h5' color='primary'>Organization Settings</Typography>
              </Grid>

              <Grid item xs={ 12 }>
                <Typography variant='subtitle1' color='textSecondary' style={ { marginBottom: 20 } }>
                  Each chapter will be able to fine tune many of the following settings.
                  Enable a setting below to enforce it for all chapters.
                </Typography>
              </Grid>

              <Grid item xs={ 12 }>
                <Grid container spacing={ 40 } justify='center'>
                  <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
                    <Grid container
                      spacing={ 16 }
                      className={ classes.settingsItemHeaderContainer }>
                      <Grid item xs={ 1 }>
                        <Icon className={ classes.settingsIcon }>settings_application</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography variant='subtitle1' color='textSecondary'>
                          General
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='hideSupportNumber'
                        id='hideSupportNumber'
                        label='Hide Support Number'
                        subText='Hides TechniPhi support phone number from chapter users'
                        component={ SwitchInput } />
                    </div>
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
                    <Grid container
                      spacing={ 16 }
                      className={ classes.settingsItemHeaderContainer }>
                      <Grid item xs={ 1 }>
                        <Icon className={ classes.settingsIcon }>extension</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography variant='subtitle1' color='textSecondary'>
                          Matching
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='hideMatchesFromVotingMembers'
                        id='hideMatchesFromVotingMembers'
                        label='Hide Matches From Voting Members'
                        subText='Hides matches from voting members that are not the author or matched user'
                        component={ SwitchInput } />
                    </div>
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
                    <Grid container
                      spacing={ 16 }
                      className={ classes.settingsItemHeaderContainer }>
                      <Grid item xs={ 1 }>
                        <Icon className={ classes.settingsIcon }>phonelink_setup</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography variant='subtitle1' color='textSecondary'>
                          Modes
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='displayOverallCob'
                        id='displayOverallCob'
                        label='Display Overall Score in COB Mode'
                        subText='Allows chapter admins to view overall scores for PNMs in Continuous Open Bidding (COB) mode'
                        component={ SwitchInput } />
                    </div>
                  </Grid>
                  <Grid item
                    xs={ 12 }
                    sm={ 6 }
                    className={ classes.settingsGroupContainer }>
                    <Grid container
                      spacing={ 16 }
                      className={ classes.settingsItemHeaderContainer }>
                      <Grid item xs={ 1 }>
                        <Icon className={ classes.settingsIcon }>info</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography variant='subtitle1' color='textSecondary'>
                          Membership Concerns
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='membershipConcernsFeatureEnabled'
                        id='membershipConcernsFeatureEnabled'
                        label='Turn on Membership Concerns Feature for Chapters'
                        subText='Enable and create categories for chapter members to report concerns about PNMs. By enabling the feature will be turned on for all of your chapters. All created categories will be displayed.'
                        component={ SwitchInput } />
                    </div>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='exportingConcernsFeatureEnabled'
                        id='exportingConcernsFeatureEnabled'
                        label='Turn on Exporting Membership Concerns Feature for Chapters'
                        subText='Enables exporting posted membership concerns for chapters'
                        component={ SwitchInput } />
                    </div>
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
                    <Grid container
                      spacing={ 16 }
                      className={ classes.settingsItemHeaderContainer }>
                      <Grid item xs={ 1 }>
                        <Icon className={ classes.settingsIcon }>comment</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography variant='subtitle1' color='textSecondary'>
                          Notes
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='disableNotesFeature'
                        id='disableNotesFeature'
                        label='Disable Notes Feature'
                        subText='For all users, admins included'
                        component={ SwitchInput } />
                    </div>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='hideNotesFromVotingMembers'
                        id='hideNotesFromVotingMembers'
                        label='Hide Notes From Voting Members'
                        subText='Hides the content of a note comment for notes that a voting member did not author'
                        component={ SwitchInput } />
                    </div>
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
                    <Grid container
                      spacing={ 16 }
                      className={ classes.settingsItemHeaderContainer }>
                      <Grid item xs={ 1 }>
                        <Icon className={ classes.settingsIcon }>local_offer</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography variant='subtitle1' color='textSecondary'>
                          Tags
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='allowVotingMembersToTag'
                        id='allowVotingMembersToTag'
                        label='Allow Voting Members To Tag PNMs'
                        component={ SwitchInput } />
                    </div>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='hideNoteTagsFromVotingMembers'
                        id='hideNoteTagsFromVotingMembers'
                        label='Hide Note Tags From Voting Members'
                        subText='Hides the tags of a note comment for notes that a voting member did not author'
                        component={ SwitchInput } />
                    </div>
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } className={ classes.settingsGroupContainer }>
                    <Grid container
                      spacing={ 16 }
                      className={ classes.settingsItemHeaderContainer }>
                      <Grid item xs={ 1 }>
                        <Icon className={ classes.settingsIcon }>thumbs_up_down</Icon>
                      </Grid>

                      <Grid item xs={ 11 }>
                        <Typography variant='subtitle1' color='textSecondary'>
                          Voting
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='displayPnmInfoOnVote'
                        id='displayPnmInfoOnVote'
                        label='Display PNM Name On Vote Dialog'
                        component={ SwitchInput } />
                    </div>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='displayPreScore'
                        id='displayPrescore'
                        label={ `Display Pre-${capitalize(scoreLabel)} Votes` }
                        subText={ `Allows voting members to see pre-${scoreLabel} votes on a PNM profile` }
                        component={ SwitchInput } />
                    </div>

                    <div className={ classes.settingsItemContainer }>
                      <Field name='singlePreScoreVote'
                        id='singlePreScoreVote'
                        label={ `Enable Single Pre-${capitalize(scoreLabel)} Vote` }
                        subText={ `Restricts chapters to one pre-${scoreLabel} vote per PNM` }
                        component={ SwitchInput } />
                    </div>
                  </Grid>

                  <Savebar open={ savebarOpen }
                    autoHideDuration={ autoHideDuration }
                    form={ organizationSettingsForm }
                    handleAccept={ this.handleSave }
                    handleReject={ this.handleCancel }
                    handleClose={ this.handleCloseSavebar }
                    hideButtons={ hideButtons }
                    message={ savebarMessage } />

                  <Grid item xs={ 12 } sm={ 6 }>
                    { this.renderSubmitMessage(organizationSettingsForm) }
                  </Grid>
                </Grid>

              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const styles = () => ({
  helperText: {
    textAlign: 'left',
  },

  settingsGroupContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       10,
    padding:      '0 !important',
  },

  settingsItemHeaderContainer: {
    padding: '15px 10px 0px 15px',
  },

  settingsItemContainer: {
    borderTop: '1px solid #bfbfbf',
    padding:   '5px 10px 10px 10px',
  },

  settingsIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  success: {
    color: green.A700,
  },
});

export default withStyles(styles)(OrganizationSettingsForm);
