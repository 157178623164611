import { Map } from 'immutable';

// constants
import { CURRENT_CHAPTER_FETCH } from '../constants/chapterTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case CURRENT_CHAPTER_FETCH: return httpReducer(state, action, { entity: 'category', updateResult: false });
    default: return state;
  }
};
