import { connect } from 'react-redux';

// components
import Home from './Home';

import {
  voteFetchAll, voteFetchForUser, voteClear, voteDelete,
} from '../../actions/voteActions';
import { fetchAllMatches, deleteMatch } from '../../actions/matchActions';
import { fetchPnmsToConfirm } from '../../actions/reportingActions';

import getMatches from '../../lib/selectors/getMatches';
import getVotes from '../../lib/selectors/getVotes';
import getOrganization from '../../lib/selectors/getOrganization';
import getChapterAttribute from '../../lib/selectors/getChapterAttribute';
import getLoadingFromState from '../../lib/selectors/getLoadingFromState';

// action creators
import { push } from 'react-router-redux';

const mapStateToProps = state => ({
  reporting:        state.get('reporting'),
  currentChapter:   state.get('currentChapter'),
  currentChapterId: getChapterAttribute('id')(state),
  currentUser:      state.get('currentUser'),
  round:            state.get('round'),
  router:           state.get('router'),
  vote:             state.get('vote'),
  matches:          getMatches(state),
  matchLoading:     getLoadingFromState('match')(state),
  votes:            getVotes(state),
  organization:     getOrganization(state),
});

const mapDispatchToProps = dispatch => ({
  navigate: (route) => {
    dispatch(push(`${route}`));
  },
  fetchVotes: (params) => {
    dispatch(voteFetchAll(params));
  },
  fetchVotesForUser: (params) => {
    dispatch(voteFetchForUser(params));
  },
  clearVotes: (params) => {
    dispatch(voteClear(params));
  },
  deleteVote: (params) => {
    dispatch(voteDelete(params));
  },
  deleteMatch: (params) => {
    dispatch(deleteMatch(params));
  },
  fetchPnmsToConfirm: (params) => {
    dispatch(fetchPnmsToConfirm(params));
  },
  fetchAllMatches: (params) => {
    dispatch(fetchAllMatches(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
