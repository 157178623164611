import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';

// components
import Button from '../../../Button';
import MembershipConcernForm from '../MembershipConcernForm';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class MembershipConcernModal extends Component {
  static propTypes = {
    classes:                 PropTypes.instanceOf(Object).isRequired,
    membershipConcernCreate: PropTypes.func.isRequired,
    membershipConcernUpdate: PropTypes.func.isRequired,
    currentChapter:          PropTypes.instanceOf(Map).isRequired,
    dialogOpen:              PropTypes.bool.isRequired,
    fullScreen:              PropTypes.bool.isRequired,
    handleDialogClose:       PropTypes.func.isRequired,
    membershipConcernForm:   PropTypes.instanceOf(Map).isRequired,
    pnm:                     PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = Immutable.fromJS({
      snackbarOpen:    false,
      snackbarMessage: '',
    });
  }

  componentWillReceiveProps(nextProps) {
    const { membershipConcernForm, handleDialogClose } = nextProps;

    if (membershipConcernForm.get('submitSucceeded')) {
      handleDialogClose();
    }
  }

  setSnackbarOpen(isOpen) {
    this.setState({ snackbarOpen: isOpen });
  }

  setSnackbarMessage(message) {
    this.setState({ snackbarMessage: message });
  }

  handleShowSnackbar(message) {
    const { handleDialogClose } = this.props;
    handleDialogClose();
    this.setSnackbarMessage(message);
    this.setSnackbarOpen(true);
  }

  handleHideSnackbar() {
    this.setSnackbarOpen(false);
    this.setSnackbarMessage('');
  }

  handleSubmit = () => {
    const {
      membershipConcernCreate, membershipConcernUpdate, membershipConcernForm, pnm, currentChapter,
    } = this.props;

    const chapter = currentChapter.getIn(['data', 'id'], 0);

    const round = currentChapter.getIn(['data', 'currentRound'], 0);

    const values = membershipConcernForm.get('values') || Map();

    if (!membershipConcernForm.get('syncErrors')) {
      if (!values.get('_id')) {
        membershipConcernCreate({
          pnm: pnm.get('id'),
          chapter,
          round,
          ...values.toJS(),
        });
      } else {
        membershipConcernUpdate({
          ...values.toJS(),
          membershipConcernId: values.get('_id'),
          pnm:                 pnm.get('id'),
          chapter,
          author:              values.getIn(['author', 'id']),
        });
      }

      this.handleShowSnackbar('Submitted successfully!');
    } else {
      this.handleShowSnackbar('Failed to submit!');
    }
  }

  renderErrorMessage = (membershipConcernForm) => {
    let element;

    if (membershipConcernForm.get('submitFailed')) {
      element = (
        <FormHelperText error>
          Failed to create membership concern, try again.
        </FormHelperText>
      );
    }

    return element;
  };

  render() {
    const {
      classes,
      currentChapter,
      dialogOpen,
      fullScreen,
      handleDialogClose,
      membershipConcernForm,
      pnm,
    } = this.props;

    const { snackbarOpen, snackbarMessage } = this.state;

    let disableButton = false;

    if (membershipConcernForm.get('syncErrors')) { disableButton = true; }

    return (
      <div>
        <Dialog open={ dialogOpen }
          onClose={ handleDialogClose }
          fullScreen={ fullScreen }
          maxWidth={ false }>

          <DialogContent classes={ { root: classes.dialogContentRoot } }>
            <MembershipConcernForm { ...{
              currentChapter,
              handleDialogClose,
              pnm,
            } } />

            <DialogActions className={ classes.dialogActionsRoot }>
              { this.renderErrorMessage(membershipConcernForm) }

              <Button onClick={ handleDialogClose } color='primary'>
                Cancel
              </Button>

              <Button variant='contained'
                onClick={ this.handleSubmit }
                loading={ membershipConcernForm.get('submitting') }
                disabled={ disableButton }
                color='primary'>

                <Icon className={ classes.leftIcon } color='inherit'>send</Icon>
                Submit

              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ snackbarOpen }
          autoHideDuration={ 3000 }
          onClose={ () => this.handleHideSnackbar() }
          message={ snackbarMessage } />
      </div>
    );
  }
}

const styles = theme => ({
  dialogContentRoot: {
    minWidth:        '50vw',
    overflowX:       'hidden',
    overflowY:       'auto',
    padding:         0,
    '&:first-child': {
      paddingTop: 0,
    },

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(
  withStyles(styles)(MembershipConcernModal)
);
