import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { chapterUpdate } from '../../../../../../actions/chapterActions';

// components
import RoundsExtraSettingsForm from './RoundsExtraSettingsForm';

const createInitialValues = (props) => {
  const { currentChapter } = props;

  const values = {
    roundToSync: (currentChapter.get('data') || Map()).get('roundToSync'),
  };
  return values;
};

const mapStateToProps = (state, props) => ({
  roundsExtraSettingsForm: (state.get('form')).get('roundsExtraSettingsForm') || Map(),
  initialValues:           createInitialValues(props),
});

const mapDispatchToProps = dispatch => ({
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
});

// schemas
const schema = {
  roundToSync: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedRoundsExtraSettingsForm = reduxForm({
  form:     'roundsExtraSettingsForm',
  validate: validateForm,
  onChange: handleChange,
})(RoundsExtraSettingsForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedRoundsExtraSettingsForm);
