import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import voteService from '../../sources/api/voteService';

// constants
import { VOTE_CREATE, VOTE_CREATE_INIT } from '../../constants/voteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { voteSchema } from '../../schemas/vote';

export function* voteCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'voteForm';

  const { payload } = action;

  let values;

  const options = {};

  if (payload.bulkCreate) {
    values = payload.items;
    options.query = { chapter: payload.chapter, bulkCreate: true };
  } else {
    values = payload;
  }

  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(VOTE_CREATE, call(create, voteService, values, options), {
      schema: voteSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(VOTE_CREATE, err));
  } finally {
    yield put(doneIndicator(VOTE_CREATE));
  }
}

export function* watchVoteCreate() {
  yield takeEvery(VOTE_CREATE_INIT, voteCreate);
}

export default function* root() {
  yield all([
    fork(watchVoteCreate),
  ]);
}
