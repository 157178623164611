import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import { List, Map } from 'immutable';
import classNames from 'classnames';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import green from '@material-ui/core/colors/green';

// local components
import Button from '../../../../../Button';
import SelectInput from '../../../../../SelectInput';

const PRESCORE_ROUND_TYPES = [6, 7];

class RoundsExtraSettingsForm extends Component {
  static propTypes = {
    classes:                 PropTypes.instanceOf(Object).isRequired,
    currentChapter:          PropTypes.instanceOf(Map).isRequired,
    roundsExtraSettingsForm: PropTypes.instanceOf(Map).isRequired,
    rounds:                  PropTypes.instanceOf(List).isRequired,
    updateChapter:           PropTypes.func.isRequired,
  };

  getRoundOptions = () => {
    const { rounds } = this.props;

    const availableRounds = rounds.filter(round => !PRESCORE_ROUND_TYPES.includes(round.get('roundType')));

    const items = [];

    for (let index = 0; index <= availableRounds.size - 1; index += 1) {
      const order = (availableRounds.get(index) || Map()).get('order');
      const name = (availableRounds.get(index) || Map()).get('name');

      const currentRoundOption = { value: order, label: name };
      items.push(currentRoundOption);
    }

    return items;
  };

  handleSave = () => {
    const { currentChapter, roundsExtraSettingsForm, updateChapter } = this.props;
    if (!roundsExtraSettingsForm.get('syncErrors')) {
      const updateParams = {
        chapterId: currentChapter.getIn(['data', 'id'], 0),
        formName:  'roundsExtraSettingsForm',
        ...roundsExtraSettingsForm.get('values').toJS(),
      };

      updateChapter(updateParams);
    }
  }

  renderStatusMessage = () => {
    const { classes, currentChapter, roundsExtraSettingsForm } = this.props;
    let element;

    if (!roundsExtraSettingsForm.get('syncErrors')
      && roundsExtraSettingsForm.get('submitSucceeded')
      && (currentChapter.get('status') || '') === 'success') {
      element = (
        <FormHelperText className={ classNames(classes.helperText, classes.success) }>
          Settings saved successfully.
        </FormHelperText>
      );
    } else if (roundsExtraSettingsForm.get('submitFailed')) {
      element = (
        <FormHelperText className={ classes.helperText } error>
          Settings save failed.
        </FormHelperText>
      );
    }

    return element;
  };

  render() {
    const { roundsExtraSettingsForm } = this.props;

    let disableButton = false;
    if (roundsExtraSettingsForm.get('syncErrors')) { disableButton = true; }

    return (
      <Grid container spacing={ 24 }>
        <Grid item xs={ 12 }>
          <Typography variant='subtitle1' color='textSecondary'>
            Which MyVote round should sync with the first round in Campus Director?
          </Typography>
        </Grid>

        <Grid item xs={ 12 } sm={ 4 }>
          <Field name='roundToSync'
            label='Round To Sync'
            variant='outlined'
            component={ SelectInput }
            options={ this.getRoundOptions() }
            required />
        </Grid>

        <Grid item xs={ 12 } align='center'>

          <Button variant='contained'
            color='primary'
            disabled={ disableButton }
            onClick={ () => this.handleSave() }
            loading={ roundsExtraSettingsForm.get('submitting') && !roundsExtraSettingsForm.get('submitSucceeded') }>

            Save Settings
          </Button>

          <Grid item xs={ 12 }>
            { this.renderStatusMessage() }
          </Grid>
        </Grid>

      </Grid>
    );
  }
}

const styles = () => ({
  helperText: {
    textAlign: 'center',
  },

  success: {
    color: green.A700,
  },
});

export default withStyles(styles)(RoundsExtraSettingsForm);
