import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import moment from 'moment';
import Immutable, { List, Map } from 'immutable';
import 'react-vis/dist/style.css';
import { denormalize } from 'normalizr';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import SelectInput from '../../SelectInput';
import Button from '../../Button';

// local components
import CompareChapters from './components/CompareChapters';
import Graphs from './components/Graphs';
import Overview from './components/Overview';

// schemas
import { chapterListSchema } from '../../../schemas/chapter';

class Analytics extends Component {
  static propTypes = {
    analytics:                 PropTypes.instanceOf(Map).isRequired,
    analyticsForm:             PropTypes.instanceOf(Map).isRequired,
    chapter:                   PropTypes.instanceOf(Map).isRequired,
    classes:                   PropTypes.instanceOf(Object).isRequired,
    compareChaptersForm:       PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    currentUser:               PropTypes.instanceOf(Map).isRequired,
    fetchAnalyticsForChapters: PropTypes.func.isRequired, // eslint-disable-line
    fetchChapters:             PropTypes.func.isRequired,
    fetchOverviewData:         PropTypes.func.isRequired, // eslint-disable-line
  };

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        tabs:       [],
        currentTab: 'overview',
      }),
    };
  }

  componentWillMount() {
    const tabs = Immutable.fromJS([
      {
        label: 'Overview',
        value: 'overview',
        icon:  'high_quality',
      },
      {
        label: 'Compare Chapters',
        value: 'compare',
        icon:  'swap_horizontal_circle',
      },
      {
        label: 'Graphs',
        value: 'graphs',
        icon:  'insert_chart',
      },
    ]);

    this.setState({ data: this.state.data.set('tabs', tabs) });
  }

  onSearchChange = (value) => {
    const { fetchChapters } = this.props;

    const fetchParams = this.getFetchParams(this.props);

    if (value) {
      this.setState({ data: this.state.data.set('search', value) });

      fetchParams.search = value;
      fetchParams.skip = 0;
      fetchParams.limit = 25;
    } else {
      this.setState({ data: this.state.data.set('search', null) });

      delete fetchParams.search;
    }
    fetchChapters(fetchParams);
  }

  getFetchParams = (props) => {
    const chapter = props.chapter.get('data') || Map();

    const limit = chapter.get('limit') || 25;
    const skip = chapter.get('skip') || 0;
    const search = this.state.data.get('search');

    const params = {
      sort: {},
      limit,
      skip,
    };

    if (search) {
      params.search = search;
    }

    return params;
  }

  getChapters = () => {
    const { chapter, currentUser } = this.props;

    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;
    const chapterData = chapter.get('data') || Map();

    let elements = [];
    let result;

    if (clearanceLevel > 0) {
      result = chapterData.get('result');
    } else {
      result = currentUser.getIn(['data', 'chapters'], Immutable.List());
    }

    if (result) {
      const entities = { chapter: (chapterData.get('items') || Immutable.List()).toJS() };

      elements = denormalize(result.toJS(), chapterListSchema, entities);
    }

    return Immutable.fromJS(elements);
  }

  // Gets suggestions: an array of objects containing chapter names and ids
  getSuggestions = () => {
    const chapters = this.getChapters();

    const chapterOptions = chapters.map((c) => {
      const label = c.get('school')
        ? `${c.get('name')} (${c.get('school')})`
        : c.get('name');

      return { label, value: c.get('id') };
    }) || List();

    return chapterOptions.toJS();
  };

  getReportOptions = () => {
    const dates = [
      { label: 'Chapter Summary Report', value: 'chapterSummary' },
      { label: 'Legacies Marked As Grade Risk', value: 'legaciesGradeRisk' },
      { label: 'New Member Report', value: 'newMember' },
      { label: 'Released Legacies', value: 'legaciesReleased' },
    ];

    return dates;
  };

  initChapters = () => {
    const { fetchChapters } = this.props;
    fetchChapters(this.getFetchParams(this.props));
  }

  initOverviewData = () => {
    const { currentUser, fetchOverviewData } = this.props;
    const user = currentUser.get('data', Map()).toJS();
    const orgId = currentUser.getIn(['data', 'organization'], 0);

    fetchOverviewData({
      user: {
        id:              user.id,
        clearance_level: user.clearance_level,
      },
      orgId,
      overview: true,
    });
  }

  initAnalytics = (selected, fromState = new Date(), toState = new Date()) => {
    const {
      analyticsForm,
      compareChaptersForm,
      currentUser,
      fetchAnalyticsForChapters,
    } = this.props;

    const chapterIds = selected.map(c => c.get('value')).toJS();

    const chapterA = compareChaptersForm.getIn(['values', 'chapterA'], {});
    const chapterB = compareChaptersForm.getIn(['values', 'chapterB'], {});

    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);
    const userId = currentUser.getIn(['data', 'id'], 0);

    const dates = analyticsForm.getIn(['values', 'date'], Map());
    const from = dates.get('from') || fromState;
    const to = dates.get('to') || toState;

    fetchAnalyticsForChapters({
      clearanceLevel,
      user:     userId,
      chapters: chapterIds,
      chapterA: chapterA.value || 0,
      chapterB: chapterB.value || 0,
      from:     moment(from).toDate(),
      to:       moment(to).toDate(),
    });
  }

  handleExport = () => {
    // Add exporting code
  };

  handleChangeTab = (event, value) => {
    this.setState({ data: this.state.data.set('currentTab', value) });
  }

  handleUpdateSuggestions = ({ value }) => {
    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(this.onSearchChange, 1000, value)),
    });
  }

  handleClearSuggestions = () => { }

  renderExportForm = () => {
    const { classes } = this.props;

    return (
      <Grid item sm={ 12 } md={ 6 }>
        <div className={ classes.exportContainer }>
          <Grid container spacing={ 16 } alignItems='center'>
            <Grid item xs={ 1 }>
              <Icon className={ classes.grayIcon }>get_app</Icon>
            </Grid>
            <Grid item xs={ 11 }>
              <Typography color='textSecondary' variant='h5' gutterBottom>Export</Typography>
            </Grid>

            <Grid item xs={ 8 }>
              <Field name='export'
                label='Select A Report'
                component={ SelectInput }
                variant='outlined'
                margin='dense'
                options={ this.getReportOptions() }
                fullWidth
                required />
            </Grid>
            <Grid item xs={ 3 } align='right' className={ classes.exportButtonContainer }>
              <Button variant='outlined' color='primary' onClick={ this.handleExport }>
                <Icon className={ classes.leftIcon }>file_download</Icon>
                Download
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }

  renderTabs() {
    const tabs = this.state.data.get('tabs');
    const elements = [];

    tabs.forEach((tab) => {
      const label = tab.get('label');
      const key = tab.get('value');
      const icon = tab.get('icon');

      const element = (
        <Tab key={ key }
          label={ label }
          value={ key }
          icon={ <Icon>{ icon }</Icon> } />
      );
      elements.push(element);
    });

    return elements;
  }

  render() {
    const {
      analytics, chapter, classes,
    } = this.props;

    const currentTab = this.state.data.get('currentTab');
    const suggestions = this.getSuggestions();

    return (
      <Grid container justify='center'>
        <Grid item xs={ 12 }>
          <Card className={ classes.card }>
            <AppBar position='static' color='default'>
              <Tabs value={ currentTab }
                onChange={ this.handleChangeTab }
                indicatorColor='primary'
                textColor='primary'
                centered
                variant='fullWidth'>
                { this.renderTabs() }
              </Tabs>
            </AppBar>
            <CardContent className={ classes.cardContent }>
              { currentTab === 'overview'
              && (
              <Overview analytics={ analytics }
                chapter={ chapter }
                initOverviewData={ this.initOverviewData }
                loading={ chapter.get('loading') } />
              )}

              { currentTab === 'compare'
              && (
              <CompareChapters analytics={ analytics }
                handleUpdateSuggestions={ this.handleUpdateSuggestions }
                handleClearSuggestions={ this.handleClearSuggestions }
                initAnalytics={ this.initAnalytics }
                loading={ chapter.get('loading') }
                suggestions={ suggestions } />
              )}

              { currentTab === 'graphs'
              && (
              <Graphs analytics={ analytics }
                initAnalytics={ this.initAnalytics }
                initChapters={ this.initChapters }
                suggestions={ suggestions }
                loading={ analytics.get('loading') || chapter.get('loading') || false }
                handleUpdateSuggestions={ this.handleUpdateSuggestions }
                handleClearSuggestions={ this.handleClearSuggestions } />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  card: {
    borderRadius: 10,
  },

  grayIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  cardContent: {
    padding: 25,
  },

  exportContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    padding:      20,
    minHeight:    350,
  },

  exportButtonContainer: {
    marginBottom: 15,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(Analytics);
