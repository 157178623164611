import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import noteService from '../../sources/api/noteService';

// constants
import { NOTE_FETCH_ALL, NOTE_FETCH_ALL_INIT } from '../../constants/noteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { noteListSchema } from '../../schemas/note';

export function* noteFetchAll(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter, search, infiniteScroll, skip, sort,
  } = action.payload || {};

  const options = {
    query: {
      chapter,
      $search:   search,
      $populate: 'tags',
      $skip:     skip,
      $sort:     {
        createdAt: -1,
        ...sort,
      },
    },
  };

  try {
    yield* httpSaga(NOTE_FETCH_ALL, call(find, noteService, options), {
      schema: noteListSchema,
      infiniteScroll,
    });
  } catch (err) {
    yield put(error(NOTE_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(NOTE_FETCH_ALL));
  }
}

export function* watchNoteFetchAll() {
  yield takeEvery(NOTE_FETCH_ALL_INIT, noteFetchAll);
}

export default function* root() {
  yield all([
    fork(watchNoteFetchAll),
  ]);
}
