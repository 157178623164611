import React from 'react';

import MatchTable from '../MatchTable';

function ResultsTab() {
  return (
    <div>
      <MatchTable />
    </div>
  );
}

export default ResultsTab;
