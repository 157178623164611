import { connect } from 'react-redux';

// action creators
import { fetchUsersForChapter } from '../../../../actions/userActions';

// components
import AdminVoteOptions from './AdminVoteOptions';

const mapStateToProps = state => ({
  currentUser: state.get('currentUser'),
  chapter:     state.get('chapter'),
  user:        state.get('user'),
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: (params) => {
    dispatch(fetchUsersForChapter(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminVoteOptions);
