import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { deleteMatch, fetchMatchesForPnm, clearMatches } from '../../../../actions/matchActions';

// components
import MatchesTab from './MatchesTab';

const mapStateToProps = state => ({
  matchState:     state.get('match'),
  user:           state.get('user'),
  chapter:        state.get('chapter'),
  currentChapter: state.get('currentChapter'),
});

const mapDispatchToProps = dispatch => ({
  deleteMatch: (params) => {
    dispatch(deleteMatch(params));
  },
  fetchMatchesForPnm: (params) => {
    dispatch(fetchMatchesForPnm(params));
  },
  clearMatches: () => {
    dispatch(clearMatches());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchesTab));
