export const CONCERN_CATEGORY_CREATE_INIT = 'CONCERN_CATEGORY_CREATE_INIT';
export const CONCERN_CATEGORY_CREATE = 'CONCERN_CATEGORY_CREATE';

export const CONCERN_CATEGORY_REMOVE_INIT = 'CONCERN_CATEGORY_REMOVE_INIT';
export const CONCERN_CATEGORY_REMOVE = 'CONCERN_CATEGORY_REMOVE';

export const CONCERN_CATEGORY_BULK_UPDATE = 'CONCERN_CATEGORY_BULK_UPDATE';
export const CONCERN_CATEGORY_BULK_UPDATE_INIT = 'CONCERN_CATEGORY_BULK_UPDATE_INIT';

export const CONCERN_CATEGORY_BULK_CREATE = 'CONCERN_CATEGORY_BULK_CREATE';
export const CONCERN_CATEGORY_BULK_CREATE_INIT = 'CONCERN_CATEGORY_BULK_CREATE_INIT';
