import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import { Field } from 'redux-form/immutable';

// local components
import Autosuggest from '../../../../../Autosuggest';
import TextInput from '../../../../../TextInput';

// components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class SingleBallotMatchForm extends Component {
  static propTypes = {
    classes:          PropTypes.instanceOf(Object).isRequired,
    currentChapterId: PropTypes.number.isRequired,
    fetchUsers:       PropTypes.func.isRequired,
    userLoading:      PropTypes.bool.isRequired,
    userSuggestions:  PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        timerId: null,
      }),
    };
  }

  handleSearchChange = (value) => {
    const { currentChapterId, fetchUsers } = this.props;

    fetchUsers({ search: value, chapter: currentChapterId });
  }

  handleUpdateSuggestions = ({ value }) => {
    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(this.handleSearchChange, 500, value)),
    });
  }

  handleClearSuggestions = () => { }

  render() {
    const {
      classes, userLoading, userSuggestions,
    } = this.props;

    return (
      <Grid container className={ classes.matchFormContainer }>
        <Grid container spacing={ 24 } justify='flex-start' className={ classes.matchSearchContainer }>
          <Grid item sm={ 7 } xs={ 11 }>
            <Typography variant='subtitle1' color='primary'>Make A Match Recommendation</Typography>
          </Grid>

          <Grid item sm={ 7 } xs={ 11 } align='center'>
            <Field name='user'
              label='Search for members to recommend as a match'
              component={ Autosuggest }
              loading={ userLoading }
              suggestions={ userSuggestions }
              onSuggestionsFetchRequested={ this.handleUpdateSuggestions }
              onSuggestionsClearRequested={ this.handleClearSuggestions } />
          </Grid>
        </Grid>

        <Grid container spacing={ 24 } justify='flex-start'>
          <Grid item md={ 10 } xs={ 12 }>
            <Field name='comment'
              key='multiline-flexible'
              label='Share more about this match recommendation'
              multiline
              variant='outlined'
              type='text'
              component={ TextInput }
              fullWidth />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

SingleBallotMatchForm.propTypes = {
  classes:          PropTypes.instanceOf(Object).isRequired,
  currentChapterId: PropTypes.number.isRequired,
  fetchUsers:       PropTypes.func.isRequired,
};

const styles = theme => ({
  matchFormContainer: {
    padding:    40,
    paddingTop: 20,
  },

  matchHeaderContainer: {
    backgroundColor: theme.palette.primary.main,
  },

  matchSearchContainer: {
    marginBottom: 20,
  },

  matchTitle: {
    color: theme.palette.common.white,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingTop:    20,
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withStyles(styles)(SingleBallotMatchForm);
