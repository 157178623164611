import { EXPORT_CLEAR, EXPORT_CREATE_INIT, EXPORT_FETCH_FOR_PDF_INIT } from '../constants/exportTypes';

export function exportCreate(params) {
  return {
    type:    EXPORT_CREATE_INIT,
    payload: params,
  };
}

export function exportClear() {
  return {
    type: EXPORT_CLEAR,
  };
}

export function exportFetchForPdf(params) {
  return {
    type:    EXPORT_FETCH_FOR_PDF_INIT,
    payload: params,
  };
}
