import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import { red } from '@material-ui/core/colors';

// local components
import Button from '../../../../../Button';
import TextInput from '../../../../../TextInput';
import SelectInput from '../../../../../SelectInput';
import ActionModal from '../../../../../ActionModal';
import Tag from '../../../../../Tag';
import { Typography } from '@material-ui/core';
import Logo from '../../../../../../assets/images/cd_logo.png';

class TagItem extends Component {
    static propTypes = {
      classes:            PropTypes.instanceOf(Object).isRequired,
      currentChapter:     PropTypes.instanceOf(Map).isRequired,
      field:              PropTypes.string.isRequired,
      handleSnackbarOpen: PropTypes.func.isRequired,
      index:              PropTypes.number.isRequired,
      removeTag:          PropTypes.func.isRequired,
      tagStatus:          PropTypes.bool.isRequired,
      tagLoading:         PropTypes.bool.isRequired,
      tags:               PropTypes.instanceOf(Immutable.List).isRequired,
      tagsForm:           PropTypes.instanceOf(Map).isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        data: Immutable.fromJS({
          deleteModalOpen: false,
        }),
      };
    }

    getPermissionOptions = () => {
      const items = [
        { value: 0, label: 'All' },
        { value: 1, label: 'Recruitment Team & Up' },
        { value: 2, label: 'Admins Only' },
      ];

      return items;
    }

    getTagColorOptions = () => {
      const items = [
        { value: 'red', label: 'Red' },
        { value: 'pink', label: 'Pink' },
        { value: 'purple', label: 'Light Purple' },
        { value: 'deepPurple', label: 'Deep Purple' },
        { value: 'indigo', label: 'Indigo' },
        { value: 'blue', label: 'Blue' },
        { value: 'lightBlue', label: 'Light Blue' },
        { value: 'cyan', label: 'Cyan' },
        { value: 'teal', label: 'Teal' },
        { value: 'darkGreen', label: 'Dark Green' },
        { value: 'green', label: 'Green' },
        { value: 'lightGreen', label: 'Light Green' },
        { value: 'lime', label: 'Lime' },
        { value: 'yellow', label: 'Yellow' },
        { value: 'amber', label: 'Amber' },
        { value: 'orange', label: 'Orange' },
        { value: 'lightOrange', label: 'Light Orange' },
        { value: 'brown', label: 'Brown' },
        { value: 'grey', label: 'Grey' },
        { value: 'blueGrey', label: 'Blue Grey' },
      ];

      return items;
    }

    handleOpen = () => {
      this.setState({ data: this.state.data.set('deleteModalOpen', true) });
    };

    handleClose = () => {
      this.setState({ data: this.state.data.set('deleteModalOpen', false) });
    };

    handleDelete = (index) => {
      const {
        currentChapter, handleSnackbarOpen, tagStatus, tags, removeTag,
      } = this.props;
      const tagId = tags.get(index).get('_id');
      const chapterId = (currentChapter.get('data')).get('id');

      removeTag(tagId, chapterId);

      handleSnackbarOpen(tagStatus);
    }

    renderTagItemIcon = (tag, disabled) => {
      const { classes } = this.props;
      let element;

      if (tag.get('type') === 'organization') {
        element = (
          <Grid container>
            <Grid item xs={ 12 }>
              <Icon color='primary'>star</Icon>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography color='textSecondary' variant='subtitle2'>Set By HQ</Typography>
            </Grid>
          </Grid>
        );
      } else if (tag.get('type') === 'campusDirector') {
        element = (
          <Grid container>
            <Grid item xs={ 12 }>
              <Icon className={ classes.logoIcon }>sync</Icon>
            </Grid>
            <Grid item xs={ 12 }>
              <Tooltip title='Synced with CampusDirector'>
                <img src={ Logo } alt='CampusDirector Sync' className={ classes.logoImage } />
              </Tooltip>
            </Grid>
          </Grid>
        );
      } else {
        element = (
          <Button variant='text'
            color='inherit'
            className={ classes.redButton }
            disabled={ disabled }
            onClick={ this.handleOpen }>
            <Icon className={ classes.leftIcon }>delete</Icon>
            Delete
          </Button>
        );
      }
      return element;
    }

    render() {
      const {
        classes, index, field, tags, tagsForm, tagLoading,
      } = this.props;
      const deleteModalOpen = this.state.data.get('deleteModalOpen');

      const tag = tags.get(index);
      const title = (((tagsForm.get('values') || Map()).get('tags') || Map()).get(index) || Map()).get('title') || 'Tag';
      const color = (((tagsForm.get('values') || Map()).get('tags') || Map()).get(index) || Map()).get('color') || 'deepPurple';
      const disabledByOrg = tag.get('type') === 'organization';
      const disabledByCd = tag.get('type') === 'campusDirector';
      const disabled = disabledByOrg || disabledByCd;

      return (
        <Grid container
          spacing={ 40 }
          justify='flex-start'
          className={ classes.tagItemContainer }>

          <Grid item xs={ 12 } sm={ 2 }>
            <Tag key={ tag.get('id') } title={ title } color={ color } />
          </Grid>

          <Grid item xs={ 12 } sm={ 2 } align='left'>
            <Field name={ `${field}.title` }
              label='Tag Title'
              type='text'
              disabled={ disabled }
              variant='outlined'
              component={ TextInput }
              fullWidth
              required />
          </Grid>

          <Grid item xs={ 12 } sm={ 2 } align='left'>
            <Field name={ `${field}.color` }
              label='Tag Color'
              component={ SelectInput }
              variant='outlined'
              disabled={ disabledByOrg }
              options={ this.getTagColorOptions() }
              fullWidth
              required />
          </Grid>

          <Grid item xs={ 12 } sm={ 2 } align='left'>
            <Field name={ `${field}.usableBy` }
              label='Usable By'
              component={ SelectInput }
              variant='outlined'
              disabled={ disabledByOrg }
              options={ this.getPermissionOptions() }
              fullWidth
              required />
          </Grid>

          <Grid item xs={ 12 } sm={ 2 } align='left'>
            <Field name={ `${field}.visibleTo` }
              label='Visible To'
              component={ SelectInput }
              variant='outlined'
              disabled={ disabledByOrg }
              options={ this.getPermissionOptions() }
              fullWidth
              required />
          </Grid>

          <Grid item xs={ 12 } sm={ 2 } align='center'>
            { this.renderTagItemIcon(tag, disabled) }

            <Hidden smUp>
              <Divider className={ classes.mobileDivider } />
            </Hidden>

          </Grid>

          <ActionModal open={ deleteModalOpen }
            description='This tag will no longer appear for any user in a PNM’s profile.'
            onAccept={ () => this.handleDelete(index) }
            onReject={ this.handleClose }
            acceptLabel='Delete'
            rejectLabel='Cancel'
            loading={ tagLoading }
            title='Are you sure you want to delete this tag?' />
        </Grid>
      );
    }
}
const styles = theme => ({
  tagItemContainer: {
    marginBottom: 0,
  },

  tagIcon: {
    height:          50,
    width:           50,
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  redButton: {
    color: red[500],
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  logoIcon: {
    color:       'rgba(0, 0, 0, 0.54)',
    marginRight: 5,
  },

  logoImage: {
    width: 125,
  },

  mobileDivider: {
    marginTop:    10,
    marginBottom: 20,
  },
});

export default withStyles(styles)(TagItem);
