const svgIconStyles = () => ({
  object: {
    lineHeight: '1em',
  },
  embed: {
    width:      '1em',
    height:     '1em',
    lineHeight: '1em',
  },
});

export default svgIconStyles;
