import Immutable, { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '../../../Button';
import TextInput from '../../../TextInput';
import { Field } from 'redux-form/immutable';

class EditPnmModal extends Component {
  static propTypes = {
    classes:                PropTypes.instanceOf(Object).isRequired,
    createPnm:              PropTypes.func.isRequired,
    currentChapter:         PropTypes.instanceOf(Map).isRequired,
    editPnmForm:            PropTypes.instanceOf(Map).isRequired,
    fields:                 PropTypes.instanceOf(List).isRequired,
    isPnmLoading:           PropTypes.bool.isRequired,
    onClose:                PropTypes.func.isRequired,
    open:                   PropTypes.bool.isRequired,
    pnmData:                PropTypes.instanceOf(Map),
    snackbarSuccessMessage: PropTypes.string, // eslint-disable-line
    submitFailed:           PropTypes.bool.isRequired, // eslint-disable-line
    submitSucceeded:        PropTypes.bool.isRequired, // eslint-disable-line
    submitting:             PropTypes.bool.isRequired, // eslint-disable-line
    title:                  PropTypes.string, // eslint-disable-line
  }

  static defaultProps = {
    pnmData:                Map(),
    snackbarSuccessMessage: 'PNM Edited Successfully',
    title:                  'Edit PNM',
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        showSnackbar:    false,
        snackbarMessage: 'Unable to update pnm, please try again',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      editPnmForm, open, snackbarSuccessMessage, onClose, submitFailed, submitSucceeded, submitting,
    } = nextProps;
    const showSnackbar = this.state.data.get('showSnackbar');

    if (submitFailed && !showSnackbar) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', true);
          map.set('snackbarMessage', 'Unable to update pnm, please try again');
        }),
      });
    } else if (showSnackbar && submitting) {
      this.setState({ data: this.state.data.set('showSnackbar', false) });
    } else if (submitSucceeded && !showSnackbar && editPnmForm.get('registeredFields')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', true);
          map.set('snackbarMessage', snackbarSuccessMessage);
        }),
      });

      onClose();
    } else if (!open && !submitSucceeded) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', false);
        }),
      });
    }
  }

  handleSubmit = () => {
    const {
      editPnmForm, createPnm, currentChapter, fields, pnmData,
    } = this.props;

    let values = editPnmForm.get('values') || Map();
    const chapter = currentChapter.getIn(['data', 'id'], 0);
    const cobModeValue = currentChapter.getIn(['data', 'cobMode']) ? 1 : 0;

    let allStandardFields = List();

    fields.forEach((f) => { // eslint-disable-line
      allStandardFields = allStandardFields.push(f.get('standardField') || Map());
    }) || List();

    // Create array of inputted standard fields using all possible fields
    let inputtedStandardFields = List();

    allStandardFields.forEach((standardField) => { // eslint-disable-line
      const id = standardField.get('id') || 0;

      if (values.get(id.toString())) {
        const type = standardField.get('type');

        inputtedStandardFields = inputtedStandardFields.push(
          Immutable.fromJS({
            id,
            value: values.get(id.toString()),
            type,
          })
        );
      }
    }) || List();

    values = values.set('standardFields', inputtedStandardFields);

    const pnmId = pnmData.get('id');

    if (!editPnmForm.get('syncErrors')) {
      createPnm({
        ...values.toJS(),
        preserveForm:              true,
        reloadOnSubmit:            true,
        id:                        pnmId,
        formName:                  'editPnmForm',
        chapter,
        chapter_for_votiphi_no_cd: chapter,
        cob:                       cobModeValue,
      });
    }
  }

  render() {
    const {
      classes, onClose, open, editPnmForm, isPnmLoading, title, fields,
    } = this.props;

    let disableButton = false;

    if (editPnmForm.get('syncErrors')) { disableButton = true; }
    const snackbarMessage = this.state.data.get('snackbarMessage');

    return (
      <Dialog open={ open }
        onClose={ onClose }
        classes={ {
          paperWidthSm: classes.paperWidthSm,
          paperWidthMd: classes.paperWidthMd,
          paperWidthLg: classes.paperWidthLg,
          paperWidthXl: classes.paperWidthXl,
        } }>
        <div className={ classes.header }>
          <Typography variant='h3'>{ title }</Typography>
        </div>

        <DialogContent className={ classes.dialogContent }>
          <Grid container spacing={ 16 }>
            <Grid item xs={ 10 }>
              <Typography variant='subtitle1' color='textSecondary'>
                Basic Info
              </Typography>
            </Grid>
            <Grid item xs={ 10 } sm={ 5 } className={ classes.inputContainer }>
              <Field name='firstname'
                label='First name'
                component={ TextInput }
                fullWidth
                required />
            </Grid>

            <Grid item xs={ 10 } sm={ 5 } className={ classes.inputContainer }>
              <Field name='lastname'
                label='Last name'
                fullWidth
                component={ TextInput }
                required />
            </Grid>

            <Grid item
              xs={ 10 }
              sm={ 5 }
              className={ classes.inputContainer }>
              <Field name='leg_tech_pnm_id'
                label='PNM ID (from council)'
                component={ TextInput }
                fullWidth
                required />
            </Grid>

            <Grid item xs={ 10 }>
              <Divider className={ classes.divider } />
            </Grid>
            <Grid item xs={ 10 }>
              <Typography variant='subtitle1' color='textSecondary'>
                PNM Fields
              </Typography>
            </Grid>

            { fields.map(f =>
              (
                <Grid item
                  xs={ 8 }
                  align='center'
                  key={ f.getIn(['standardField', 'id'], '0') }
                  className={ classes.inputContainer }>
                  <Field name={ `${f.getIn(['standardField', 'id'], '0')}` }
                    label={ f.getIn(['standardField', 'text'], 'Field') }
                    fullWidth
                    component={ TextInput } />
                </Grid>
              ))}
          </Grid>

          <DialogActions className={ classes.actionContainer }>
            <Button onClick={ onClose } color='primary'>
              Cancel
            </Button>

            <Button variant='contained'
              onClick={ this.handleSubmit }
              disabled={ disableButton }
              loading={ isPnmLoading }
              color='primary'>

              Submit
            </Button>
          </DialogActions>
        </DialogContent>

        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ this.state.data.get('showSnackbar') }
          message={ snackbarMessage } />
      </Dialog>
    );
  }
}

const styles = theme => ({
  paperWidthSm: {
    maxWidth: '60%',
  },
  paperWidthMd: {
    maxWidth: '50%',
  },
  paperWidthLg: {
    maxWidth: '40%',
  },
  paperWidthXl: {
    maxWidth: '40%',
  },

  dialogContent: {
    padding: '0 40px 40px',
  },

  divider: {
    marginTop: 20,
  },

  actionContainer: {
    marginTop: 50,
  },

  avatar: {
    width:  '100px',
    height: '100px',
  },

  header: {
    backgroundColor: theme.palette.primary.main,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    padding:         15,
    marginBottom:    10,
  },

  inputContainer: {
    textAlign: 'left',
  },
});

export default withMobileDialog()(withStyles(styles)(EditPnmModal));
