import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { pnmFetch, disableDisplayActiveList, enableDisplayActiveList } from '../../actions/pnmActions';
import { noteFetchForPnm, noteClear } from '../../actions/noteActions';
import { voteFetchForPnm, voteClear } from '../../actions/voteActions';

// components
import Pnm from './Pnm';

// selectors
import getNotes from '../../lib/selectors/getNotes';
import getVotes from '../../lib/selectors/getVotes';
import getOrganization from '../../lib/selectors/getOrganization';
import getMembershipConcerns from '../../lib/selectors/getMembershipConcerns';

const mapStateToProps = (state, { match: { params: { id:pnmId } } }) => ({
  currentChapter:     state.get('currentChapter'),
  currentUser:        state.get('currentUser'),
  note:               state.get('note'),
  notes:              getNotes(state),
  organization:       getOrganization(state),
  pnm:                state.get('pnm'),
  round:              state.get('round'),
  tag:                state.get('tag'),
  votes:              getVotes(state),
  membershipConcerns: getMembershipConcerns(pnmId)(state),
});

const mapDispatchToProps = dispatch => ({
  fetchPnm: (params) => {
    dispatch(pnmFetch(params));
  },
  fetchNotesForPnm: (pnmId, chapterId) => {
    dispatch(noteFetchForPnm({ pnmId, chapterId }));
  },
  fetchVotesForPnm: (params) => {
    dispatch(voteFetchForPnm(params));
  },
  clearNotes: () => {
    dispatch(noteClear());
  },
  clearVotes: () => {
    dispatch(voteClear());
  },
  disableList: () => {
    dispatch(disableDisplayActiveList());
  },
  enableList: () => {
    dispatch(enableDisplayActiveList());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pnm));
