import Immutable, { Map, List } from 'immutable';

// constants
import {
  USER_FETCH_ALL,
  USER_UPDATE,
  USER_FETCH_ALL_FOR_CHAPTER,
  USER_DELETE,
  USER_BULK_UPDATE,
  CURRENT_USER_FETCH,
  USER_REMOVE_ALL_FOR_CHAPTER,
} from '../constants/userTypes';

import { NOTE_FETCH_FOR_PNM } from '../constants/noteTypes';
import { VOTE_FETCH_FOR_PNM, VOTE_FETCH_ALL } from '../constants/voteTypes';
import {
  MATCH_FETCH_FOR_PNM, MATCH_FETCH_ALL, MATCH_DELETE, MATCH_CREATE,
} from '../constants/matchTypes';
import { INVITE_CREATE } from '../constants/inviteTypes';

// lib
import httpReducer from '../lib/httpReducer';
import { remove } from '../lib/normalizedReducerHelpers';

function inviteCreate() {
  return (map, action) => {
    const { payload: { data: { user } } } = action;

    const result = map.getIn(['data', 'result'], List());

    if (!user) {
      return map;
    }

    if (result.includes(user.id)) {
      return map;
    }

    return map.withMutations((m) => {
      m.setIn(['data', 'items', user.id], Immutable.fromJS(user));
      m.setIn(['data', 'result'], m.getIn(['data', 'result'], List()).push(user.id));
    });
  };
}

function updateMatchCount(state, action, method) {
  const { meta, payload } = action;
  const { data } = payload || {}; // the match data

  const oldData = state.get('data') || Map(); // the user state data
  let newData = oldData;

  if (((meta === undefined || !meta.loading) && data)) {
    newData = oldData.withMutations((map) => {
    // find the users affected by the match action
      data.forEach((match) => {
        if (match.user) {
          const user = (match.user || '').toString();
          const officialMatches = map.getIn(['items', match.user, 'officialMatches'], 0);

          // update their match counts
          if (method === 'create') {
            // increment officialMatches
            map.setIn(['items', user, 'officialMatches'], officialMatches + 1);
          } else if (method === 'delete' && officialMatches > 0) {
            // decrement officialMatches
            map.setIn(['items', user, 'officialMatches'], officialMatches - 1);
          }
        }
      });
    });
  }

  return state.set('data', newData);
}

export default (state = Map(), action) => {
  switch (action.type) {
    case CURRENT_USER_FETCH: return httpReducer(state, action, { entity: 'user', updateResult: false });
    case INVITE_CREATE: return httpReducer(state, action, { cb: inviteCreate() });
    case MATCH_FETCH_ALL: return httpReducer(state, action, { entity: 'user', updateResult: false });
    case MATCH_FETCH_FOR_PNM: return httpReducer(state, action, { entity: 'user', updateResult: false });
    case MATCH_CREATE: return updateMatchCount(state, action, 'create');
    case MATCH_DELETE: return updateMatchCount(state, action, 'delete');
    case NOTE_FETCH_FOR_PNM: return httpReducer(state, action, { entity: 'user', updateResult: false });
    case USER_BULK_UPDATE: return httpReducer(state, action, { entity: 'user', updateResult: false });
    case USER_DELETE: return httpReducer(state, action, { entity: 'user', cb: remove() });
    case USER_FETCH_ALL_FOR_CHAPTER: return httpReducer(state, action, { entity: 'user' });
    case USER_FETCH_ALL: return httpReducer(state, action, { entity: 'user' });
    case USER_REMOVE_ALL_FOR_CHAPTER: return httpReducer(state, action, { cb: () => Map() });
    case USER_UPDATE: return httpReducer(state, action, { entity: 'user' });
    case VOTE_FETCH_FOR_PNM: return httpReducer(state, action, { entity: 'user', updateResult: false });
    case VOTE_FETCH_ALL: return httpReducer(state, action, { entity: 'user', updateResult: true });
    default: return state;
  }
};
