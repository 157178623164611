import { Map } from 'immutable';

export function hasSubmitFailed(formName) {
  return state => state.getIn(['finalForm', formName, 'submitFailed'], false);
}

export function isSubmitting(formName) {
  return state => state.getIn(['finalForm', formName, 'submitting'], false);
}

export function hasSubmitSucceeded(formName) {
  return state => state.getIn(['finalForm', formName, 'submitSucceeded'], false);
}

export function getError(formName) {
  return state => state.getIn(['finalForm', formName, 'error'], false);
}

export function isFormEmpty(formName) {
  return state => state.getIn(['finalForm', formName], Map()).size === 0;
}
