import { Map } from 'immutable';

// constants
import {
  CURRENT_CHAPTER_FETCH,
  CURRENT_CHAPTER_FETCH_FOR_VOTES,
} from '../constants/chapterTypes';
import { ROUND_CREATE, ROUND_REMOVE, ROUND_BULK_UPDATE } from '../constants/roundTypes';
import { add, remove } from '../lib/normalizedReducerHelpers';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case ROUND_BULK_UPDATE: return httpReducer(state, action, { entity: 'round', updateResult: false });
    case ROUND_REMOVE: return httpReducer(state, action, { entity: 'round', cb: remove(false) });
    case ROUND_CREATE: return httpReducer(state, action, { entity: 'round', cb: add(false) });
    case CURRENT_CHAPTER_FETCH: return httpReducer(state, action, { entity: 'round', updateResult: false });
    case CURRENT_CHAPTER_FETCH_FOR_VOTES: return httpReducer(state, action, { entity: 'round', updateResult: false });
    default: return state;
  }
};
