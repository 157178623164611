import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { get } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import { PNM_FETCH, PNM_FETCH_INIT } from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmSchema } from '../../schemas/pnm';

export function* pnmFetch(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter, pnm, includeFields } = action.payload || {};

  const options = {
    query: {
      chapter,
    },
  };

  if (includeFields) {
    options.query.includeFields = includeFields;
  }

  try {
    yield* httpSaga(PNM_FETCH, call(get, pnmService, pnm, options), {
      schema: pnmSchema,
    });
  } catch (err) {
    yield put(error(PNM_FETCH, err));
  } finally {
    yield put(doneIndicator(PNM_FETCH));
  }
}

export function* watchPnmFetch() {
  yield takeEvery(PNM_FETCH_INIT, pnmFetch);
}

export default function* root() {
  yield all([
    fork(watchPnmFetch),
  ]);
}
