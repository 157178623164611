import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';
import { denormalize } from 'normalizr';

// MUI components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

// local components
import ExportReportsForm from './components/ExportReportsForm';

// schemas
import { pnmListSchema } from '../../../../schemas/pnm';

class Export extends Component {
  static propTypes = {
    clearanceLevel: PropTypes.number.isRequired,
    form:           PropTypes.instanceOf(Map).isRequired,
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    pnm:            PropTypes.instanceOf(Map).isRequired,
    note:           PropTypes.instanceOf(Map).isRequired,
    vote:           PropTypes.instanceOf(Map).isRequired,
    roundCategory:  PropTypes.instanceOf(Map).isRequired,
    category:       PropTypes.instanceOf(Map).isRequired,
    parentclasses:  PropTypes.instanceOf(Object).isRequired,
    teamLevel:      PropTypes.number.isRequired,
    fetchNotes:     PropTypes.func.isRequired,
    fetchVotes:     PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        pnms:  null,
        notes: null,
        votes: null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { pnm } = nextProps;

    // If the PNMs have loaded, set them on the component state
    if (!this.state.data.get('pnms') && (pnm.get('data') || Map()).get('resultsResult')) {
      this.setPnms(nextProps);
    }
  }

  // Denormalize PNMs and set on component state
  setPnms = (props) => {
    const { pnm } = props;

    let items = [];

    const pnmData = pnm.get('data') || Map();
    const result = pnmData.get('resultsResult') || List();

    if (result) {
      const entities = { pnm: (pnmData.get('items') || List()).toJS() };

      items = denormalize(result.toJS(), pnmListSchema, entities);
    }

    this.setState({ data: this.state.data.set('pnms', Immutable.fromJS(items)) });
  }

  initData = (props) => {
    const {
      currentChapter, fetchExportsForPdf,
    } = props;

    const chapter = currentChapter.getIn(['data', 'id'], 0);

    fetchExportsForPdf({ chapter });
  }

  renderErrorMessage = (form) => {
    let element;

    if (form.get('submitFailed')) {
      element = <FormHelperText error>Save failed.</FormHelperText>;
    }
    return element;
  };

  render() {
    const {
      clearanceLevel, parentclasses: { tabsCard, titleContainer, titleIcon }, teamLevel,
    } = this.props;

    return (
      <div>
        <Card className={ tabsCard }>
          <CardContent>
            <Grid container spacing={ 8 }>

              <Grid item xs={ 12 } className={ titleContainer }>
                <Icon className={ titleIcon } color='primary'>file_download</Icon>
                <Typography variant='h5' color='primary'>Export Reports</Typography>
              </Grid>

              <Grid item xs={ 12 }>
                <Typography variant='subtitle1' color='textSecondary'>
                  Download one of the following spreadsheets of MyVote data
                </Typography>
              </Grid>

              <ExportReportsForm { ...{ clearanceLevel, teamLevel } } />
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default Export;
