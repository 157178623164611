import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import red from '@material-ui/core/colors/red';

// local components
import Button from '../../../Button';
import StatusForm from './components/StatusForm';
import HometownGroupsForm from './components/HometownGroupsForm';
import TagsForm from './components/TagForm';
import SlideshowModal from '../../SlideshowModal';
import PdfModal from '../../PdfModal';
import ActionModal from '../../../ActionModal';

class BulkActions extends Component {
  static propTypes = {
    classes:                 PropTypes.instanceOf(Object).isRequired,
    clearExports:            PropTypes.func.isRequired, // eslint-disable-line
    createExport:            PropTypes.func.isRequired, // eslint-disable-line
    currentChapter:          PropTypes.instanceOf(Map).isRequired,
    currentUser:             PropTypes.instanceOf(Map).isRequired,
    // exportState:             PropTypes.instanceOf(Map).isRequired,
    deleteAllPnmsForChapter: PropTypes.func.isRequired,
    deletePnm:               PropTypes.func.isRequired,
    filter:                  PropTypes.instanceOf(Object),
    setSelectAllChecked:     PropTypes.func.isRequired,
    selectAllChecked:        PropTypes.bool.isRequired,
    numChecked:              PropTypes.number.isRequired,
    resetSelections:         PropTypes.func.isRequired,
    selectedPnms:            PropTypes.instanceOf(Immutable.List).isRequired,
    totalPnms:               PropTypes.number.isRequired,
  }

  static defaultProps = {
    filter: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        actionToDisplay:    'menu',
        saveMessage:        '',
        slideshowModalOpen: false,
        exported:           false,
        pdfModalOpen:       false,
        deleteModalOpen:    false,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selectAllChecked, numChecked } = this.props;

    if ((nextProps.numChecked && numChecked !== nextProps.numChecked)
        || (!selectAllChecked && nextProps.selectAllChecked)) {
      // this.handleCreateExport(nextProps);
    }
  }

  handleDisplayActionChange = (action) => {
    this.setState({ data: this.state.data.set('actionToDisplay', action) });
  }

  handleCreateExport = (props) => {
    const {
      currentChapter, clearExports, createExport, filter, selectedPnms, numChecked,
    } = props;

    const pnmIds = selectedPnms.map(pnm => parseInt(pnm.get('id'), 10)).toJS();

    const params = {
      chapter:  currentChapter.getIn(['data', 'id'], 0),
      timezone: moment.tz.guess(),
    };

    if (filter) {
      Object.assign(params, { filter });
    }

    if (numChecked > 0) {
      Object.assign(params, { pnms: { pnmIds } });
    } else {
      Object.assign(params, { pnms: true });
    }

    clearExports();
    createExport(params);
    this.setState({ data: this.state.data.set('exported', false) });
  }

  handleExport = () => {
    this.setState({ data: this.state.data.set('exported', true) });
  }

  handleSlideshowModalOpen = () => {
    const slideshowModalOpen = this.state.data.get('slideshowModalOpen');
    this.setState({ data: this.state.data.set('slideshowModalOpen', !slideshowModalOpen) });
  }

  handleDeleteModalOpen = () => {
    const deleteModalOpen = this.state.data.get('deleteModalOpen');
    this.setState({ data: this.state.data.set('deleteModalOpen', !deleteModalOpen) });
  }

  handlePdfModalOpen = () => {
    const pdfModalOpen = this.state.data.get('pdfModalOpen');
    this.setState({ data: this.state.data.set('pdfModalOpen', !pdfModalOpen) });
  }

  handleCloseBulkActions = () => {
    const {
      resetSelections, setSelectAllChecked, numChecked, selectAllChecked,
    } = this.props;

    if (numChecked > 0) {
      resetSelections();
    } else if (selectAllChecked) {
      setSelectAllChecked(false);
    }
  }

  handleDeleteAllPnmsForChapter = () => {
    const { currentChapter, deleteAllPnmsForChapter, filter } = this.props;
    const modeToClear = currentChapter.getIn(['data', 'cobMode']) ? 'cob' : 'standard';

    deleteAllPnmsForChapter({
      chapter: currentChapter.getIn(['data', 'id']),
      modeToClear,
      filter,
    });
  }

  handleDeletePnm = (pnms) => {
    const { currentChapter, deletePnm, resetSelections } = this.props;
    this.handleDeleteModalOpen();

    const ids = pnms.map(p => p.get('id'));
    const chapter = currentChapter.getIn(['data', 'id'], 0);

    this.handleDisplayActionChange('menu');

    deletePnm({ pnmIds: ids.toJS(), chapter });
    resetSelections();
  }

  renderBulkMenu = () => {
    const {
      classes, currentChapter, currentUser, numChecked,
      // exportState,
    } = this.props;

    const isCdSite = currentChapter.getIn(['data', 'isCdSite'], false);
    const cobMode = currentChapter.getIn(['data', 'cobMode'], false);
    const demoMode = currentChapter.getIn(['data', 'demoMode'], false);
    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;
    // const exported = this.state.data.get('exported');
    // const exportLoading = Boolean(exportState.get('loading'));
    // const fileId = exportState.getIn(['data', 'id'], false);

    return (
      <div>
        { isAdmin
        && (
        <Grid item xs={ 12 } key='slideshow'>
          <Button variant='text'
            color='primary'
            onClick={ () => this.handleSlideshowModalOpen() }>
            <Icon className={ classes.leftIcon }>slideshow</Icon>
            Create Slideshow
          </Button>
        </Grid>
        )}

        {/* <Grid item xs={ 12 } key='pdf'>
          <Button variant='text'
            color='primary'
            onClick={ () => this.handlePdfModalOpen() }>
            <Icon className={ classes.leftIcon }>picture_as_pdf</Icon>
              Create PDF
          </Button>
        </Grid>

        <Grid item xs={ 12 } key='export'>
          <Button variant='text'
            color='primary'
            loading={ exportLoading }
            href={ `${process.env.REACT_APP_API_URL}/exports/${fileId}` }
            disabled={ Boolean(!fileId || exported) }
            onClick={ this.handleExport }>
            <Icon className={ classes.leftIcon }>file_download</Icon>
              Export Spreadsheet
          </Button>
        </Grid> */}

        { numChecked > 0 && [
          <Grid item xs={ 12 } key='tag'>
            <Button variant='text'
              color='primary'
              onClick={ () => this.handleDisplayActionChange('tag') }>
              <Icon className={ classes.leftIcon }>local_offer</Icon>
              Tag
            </Button>
          </Grid>,

          <Grid item xs={ 12 } key='hometown'>
            <Button variant='text'
              color='primary'
              onClick={ () => this.handleDisplayActionChange('hometown') }>
              <Icon className={ classes.leftIcon }>home</Icon>
              Set Hometown
            </Button>
          </Grid>,
        ]}

        { (!isCdSite || cobMode)
        && (
        <Grid item xs={ 12 } key='status'>
          <Button variant='text'
            color='primary'
            onClick={ () => this.handleDisplayActionChange('status') }>
            <Icon className={ classes.leftIcon }>update</Icon>
            Update Status
          </Button>
        </Grid>
        )}

        { (!isCdSite || cobMode) && isAdmin
          && (
          <Grid item xs={ 12 } key='delete'>
            <Button variant='text'
              disabled={ demoMode }
              className={ classes.errorButton }
              onClick={ () => this.handleDisplayActionChange('delete') }>
              <Icon className={ classes.leftIcon }>delete</Icon>
              Delete
            </Button>
          </Grid>
          )}
      </div>
    );
  }

  renderPnmDeleteConfirm = (pnms) => {
    const {
      numChecked, selectAllChecked, totalPnms,
    } = this.props;
    const numSelected = selectAllChecked ? totalPnms : numChecked;
    const handleDelete = selectAllChecked
      ? this.handleDeleteAllPnmsForChapter
      : this.handleDeletePnm;

    return [
      <ActionModal open={ () => this.handleDeleteModalOpen() }
        description={ (
          <>
            <Typography color='textSecondary' variant='subtitle1'>Deleting the PNMS will remove all votes, tags, comments, and results pertaining to them.</Typography>
            <Typography color='error' variant='subtitle1' style={ { paddingTop: '10px', textDecoration: 'underline', fontStyle: 'italic' } }>You will not be able to undo this action.</Typography>
          </>
        ) }
        onAccept={ () => handleDelete(pnms) }
        onReject={ () => this.handleDisplayActionChange('menu') }
        acceptLabel='Delete'
        rejectLabel='Cancel'
        confirmDeleting={ selectAllChecked }
        title={ `Are you sure you want to delete ${pluralize('this', numSelected, false)} ${numSelected} ${pluralize('PNM', numSelected, false)}?` } />];
  }

  render() {
    const {
      classes, filter, selectAllChecked, numChecked, selectedPnms, totalPnms,
    } = this.props;

    const actionToDisplay = this.state.data.get('actionToDisplay');
    const slideshowModalOpen = this.state.data.get('slideshowModalOpen');
    const pdfModalOpen = this.state.data.get('pdfModalOpen');
    const bulkHeaderContainerClass = actionToDisplay !== 'menu' ? classes.bulkHeaderContainer : '';
    const numSelected = selectAllChecked ? totalPnms : numChecked;

    return (
      <div>
        <Collapse in={ numChecked > 0 || selectAllChecked }>
          <Grid container
            alignContent='flex-start'
            className={ classes.bulkActionsContainer }
            spacing={ 0 }>

            <Grid item xs={ 12 }>
              <Grid container className={ bulkHeaderContainerClass }>
                { actionToDisplay !== 'menu'
                  && (
                  <Grid item xs={ 2 }>
                    <IconButton onClick={ () => this.handleDisplayActionChange('menu') }>
                      <Icon>arrow_backward</Icon>
                    </IconButton>
                  </Grid>
                  )}

                <Grid item xs={ actionToDisplay === 'menu' ? 10 : 8 } className={ classes.bulkHeaderLabel }>
                  <Typography color='textSecondary'>
                    { numSelected }
                    {' '}
                    PNMs Selected
                  </Typography>
                </Grid>
                <Grid item xs={ 2 }>
                  <IconButton onClick={ () => this.handleCloseBulkActions() }>
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            { actionToDisplay === 'menu' && this.renderBulkMenu() }
            { actionToDisplay === 'status' && <StatusForm pnms={ selectedPnms } allSelected={ selectAllChecked } filter={ filter } /> }
            { actionToDisplay === 'hometown' && <HometownGroupsForm pnms={ selectedPnms } allSelected={ selectAllChecked } filter={ filter } /> }
            { actionToDisplay === 'tag' && <TagsForm pnms={ selectedPnms } allSelected={ selectAllChecked } filter={ filter } /> }
            { actionToDisplay === 'delete' && this.renderPnmDeleteConfirm(selectedPnms) }
          </Grid>
        </Collapse>

        <SlideshowModal dialogOpen={ slideshowModalOpen }
          filter={ filter }
          selectedPnms={ selectedPnms }
          numSelected={ numSelected }
          handleDialogClose={ this.handleSlideshowModalOpen } />

        <PdfModal dialogOpen={ pdfModalOpen }
          filter={ filter }
          selectedPnms={ selectedPnms }
          numSelected={ numSelected }
          handleDialogClose={ this.handlePdfModalOpen } />
      </div>
    );
  }
}

const styles = theme => ({
  bulkActionsContainer: {
    height:          238,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    padding:         '0 10px 10px',
    marginBottom:    0,
    borderBottom:    `3px solid ${theme.palette.primary.main}`,
  },

  bulkActionItem: {
    padding: 0,
  },

  bulkHeaderContainer: {
    marginBottom: 20,
  },

  bulkHeaderLabel: {
    margin: 'auto',
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  errorButton: {
    color: red[500],
  },
});

export default withStyles(styles)(BulkActions);
