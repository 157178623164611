import { Map } from 'immutable';

// constants
import { DRAWER_CLOSE, DRAWER_OPEN } from '../constants/drawerTypes';

export default (state = Map({ open: false }), action) => {
  switch (action.type) {
    case DRAWER_CLOSE: return state.set('open', false);
    case DRAWER_OPEN: return state.set('open', true);
    default: return state;
  }
};
