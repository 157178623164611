import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

// MUI components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local components
import ChapterInfoForm from './components/ChapterInfoForm';
import ChapterSettingsForm from './components/ChapterSettingsForm';

const Chapter = function (props) {
  Chapter.propTypes = {
    currentChapter: PropTypes.instanceOf(Map).isRequired,
    parentclasses:  PropTypes.instanceOf(Object).isRequired,
    organization:   PropTypes.instanceOf(Map).isRequired,
  };

  const {
    currentChapter,
    organization,
    parentclasses: { tabsCard, titleContainer, titleIcon },
  } = props;

  const chapterData = currentChapter.get('data') || Map();

  return (
    <div>
      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>

            <Grid item xs={ 12 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>account_balance</Icon>
              <Typography variant='h5' color='primary'>Chapter Info</Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary' gutterBottom>
                Customize your MyVote chapter
              </Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <ChapterInfoForm organization={ organization } chapterData={ chapterData } />
            </Grid>

          </Grid>
        </CardContent>
      </Card>

      <Card className={ tabsCard }>
        <CardContent>
          <Grid container spacing={ 8 }>

            <Grid item xs={ 12 } className={ titleContainer }>
              <Icon className={ titleIcon } color='primary'>settings</Icon>
              <Typography variant='h5' color='primary'>Chapter Settings</Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <Typography variant='subtitle1' color='textSecondary' style={ { marginBottom: 20 } }>
                Fine tune your chapter settings, then scroll down to save
              </Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <ChapterSettingsForm currentChapter={ currentChapter }
                organization={ organization } />
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default Chapter;
