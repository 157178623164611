import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// components
import AutoSuggestButtons from './components/AutoSuggestButtons';
import AutoSuggestInput from './components/AutoSuggestInput';
import AutoSuggestChips from './components/AutoSuggestChips';

const AutoSuggest = function ({
  onSelectCallback,
  classes,
  defaultSuggestion,
  formValues,
  getAllSuggestions,
  helperText,
  input,
  label,
  loading,
  multiSelect,
  onSearchChange,
  placeholder,
  suggestions,
  variant,
  ...restOfProps
}) {
  const [timerId, setTimerId] = useState(null);
  const [selectedItems, setSelectedItems] = useState(List());

  if (!formValues.size) {
    restOfProps.input = { // eslint-disable-line
      onChange: () => {},
      onBlur:   () => {},
      onFocus:  () => {},
    };
  }

  const { name } = input;
  const formValue = multiSelect
    ? formValues.get(name, List())
    : formValues.get(name, Map());

  useEffect(() => {
    let selectedItem = Map();

    if (multiSelect) {
      if (formValue.size) {
        setSelectedItems(formValue);
      }
    } else {
      if (typeof formValue === 'object') {
        selectedItem = Map(formValue);
      } else {
        selectedItem = Map({
          ...formValue,
        });
      }

      const alreadySelected = selectedItems.includes(selectedItem);

      if (selectedItem.get('value') && !alreadySelected) {
        setSelectedItems(selectedItems.push(selectedItem));
      }
    }
  }, [formValues, selectedItems, name]);

  const handleUpdateSuggestions = ({ value }) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    // Search after 3+, or no characters have been typed
    if (value.length > 2) {
      setTimerId(setTimeout(onSearchChange, 500, value));
    }
  };

  const handleClearSuggestions = () => {
    onSearchChange(null);
  };

  const handleSelectAll = () => {
    const allSuggestions = getAllSuggestions();
    input.onChange(allSuggestions);
    setSelectedItems(allSuggestions);
  };

  const handleClearAll = () => {
    input.onChange([]);

    setSelectedItems(List());
  };

  const handleDeleteChip = (index) => {
    const newSelected = selectedItems.splice(index, 1);

    input.onChange(newSelected);
    setSelectedItems(newSelected);
  };

  return (
    <Grid container spacing={ 16 }>
      <Grid item xs={ 12 } className={ classes.autoSuggestContainer }>
        <AutoSuggestInput onSelectCallback={ onSelectCallback }
          defaultSuggestion={ defaultSuggestion }
          formValue={ formValue.toJS() }
          helperText={ helperText }
          input={ input }
          label={ label }
          loading={ loading }
          multiSelect={ multiSelect }
          onSuggestionsClearRequested={ handleClearSuggestions }
          onSuggestionsFetchRequested={ handleUpdateSuggestions }
          placeholder={ placeholder }
          suggestions={ suggestions }
          variant={ variant }
          { ...restOfProps } />
      </Grid>

      {multiSelect && (
        <Grid item xs={ 12 }>
          <AutoSuggestChips selected={ selectedItems }
            deleteChip={ handleDeleteChip } />
        </Grid>
      )}
      {multiSelect && (
        <Grid item xs={ 12 }>
          <AutoSuggestButtons onSelectAll={ handleSelectAll }
            onClearAll={ handleClearAll }
            hasSelectedItems={ selectedItems.size > 0 } />
        </Grid>
      )}
    </Grid>
  );
};

AutoSuggest.propTypes = {
  classes:           PropTypes.instanceOf(Object).isRequired,
  defaultSuggestion: PropTypes.instanceOf(Object),
  formValues:        PropTypes.instanceOf(Object).isRequired,
  onSelectCallback:  PropTypes.func,
  getAllSuggestions: PropTypes.func,
  helperText:        PropTypes.string,
  input:             PropTypes.instanceOf(Object),
  label:             PropTypes.string,
  loading:           PropTypes.bool,
  multiSelect:       PropTypes.bool,
  onSearchChange:    PropTypes.func.isRequired,
  placeholder:       PropTypes.string,
  suggestions:       PropTypes.instanceOf(Array).isRequired,
  variant:           PropTypes.string,
};

AutoSuggest.defaultProps = {
  defaultSuggestion: {},
  onSelectCallback:  () => {},
  getAllSuggestions: () => {},
  helperText:        '',
  input:             {},
  label:             '',
  loading:           false,
  multiSelect:       false,
  placeholder:       '',
  variant:           'outlined',
};

const styles = () => ({
  autoSuggestContainer: {
    alignItems:    'center',
    paddingBottom: 0,
  },
});

export default withStyles(styles)(AutoSuggest);
