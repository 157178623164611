import { Map } from 'immutable';
import AddPnmModal from './AddPnmModal';
import {
  reduxForm, isSubmitting, hasSubmitFailed, hasSubmitSucceeded,
} from 'redux-form/immutable';
import validate from 'validate.js';
import { connect } from 'react-redux';

// action creators
import { pnmCreate, pnmUpdate } from '../../../../actions/pnmActions';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getFields from '../../../../lib/selectors/getFields';

const schema = {
  firstname: {
    presence: true,
  },

  lastname: {
    presence: true,
  },

  leg_tech_pnm_id: {
    presence: true,
  },
};

const mapStateToProps = state => ({
  addPnmForm:      state.getIn(['form', 'addPnmForm'], Map()),
  currentChapter:  state.get('currentChapter'),
  currentUser:     state.get('currentUser'),
  fields:          getFields(state),
  isPnmLoading:    getLoadingFromState('pnm')(state),
  submitFailed:    hasSubmitFailed('addPnmForm')(state),
  submitSucceeded: hasSubmitSucceeded('addPnmForm')(state),
  submitting:      isSubmitting('addPnmForm')(state),
  user:            state.get('user'),
});

const mapDispatchToProps = dispatch => ({
  createPnm: (params) => {
    dispatch(pnmCreate(params));
  },
  updatePnm: (params) => {
    dispatch(pnmUpdate(params));
  },
});

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:               'addPnmForm',
  validate:           validateForm,
  onChange:           handleChange,
  enableReinitialize: true,
})(AddPnmModal));
