import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import inviteService from '../../sources/api/inviteService';

// constants
import { INVITE_CREATE, INVITE_CREATE_INIT } from '../../constants/inviteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { inviteSchema } from '../../schemas/invite';

export function* inviteCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'invite';

  yield put(startSubmit(FORM));

  const { payload } = action;

  try {
    yield* httpSaga(INVITE_CREATE, call(create, inviteService, payload), {
      schema: inviteSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(INVITE_CREATE, err));
  } finally {
    yield put(doneIndicator(INVITE_CREATE));
  }
}

export function* watchInviteCreate() {
  yield takeEvery(INVITE_CREATE_INIT, inviteCreate);
}

export default function* root() {
  yield all([
    fork(watchInviteCreate),
  ]);
}
