import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { hometownGroupListSchema } from '../../schemas/hometownGroup';

export default function getHometowns(state) {
  const hometownGroup = state.get('hometownGroup');
  const currentChapter = state.get('currentChapter');

  const result = currentChapter.getIn(['data', 'hometownGroups'], List());
  const hometownGroups = hometownGroup.getIn(['data', 'items'], Map());

  const entities = {
    hometownGroup: hometownGroups.toJS(),
  };

  const items = denormalize(result.toJS(), hometownGroupListSchema, entities);
  return Immutable.fromJS(items);
}
