import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

// MUI components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// local components
import CheckboxInput from '../../../../../CheckboxInput';
import RangeInput from '../../../../../RangeInput';

function WeightItem(props) {
  WeightItem.propTypes = {
    classes:       PropTypes.instanceOf(Object).isRequired,
    field:         PropTypes.string.isRequired,
    item:          PropTypes.instanceOf(Map).isRequired,
    parentClasses: PropTypes.instanceOf(Object).isRequired,
    specialties:   PropTypes.instanceOf(List).isRequired,
  };

  const {
    classes,
    field,
    item,
    parentClasses,
    specialties,
  } = props;

  const getTitle = () => {
    const key = item.get('key');

    if (key === 'matchRecs') {
      return 'Match Recommendations';
    }
    return `Specialty: ${(specialties.find(s => s.get('_id') === key) || Map()).get('title')}`;
  };

  const getAlgorithm = () => {
    const key = item.get('key');

    if (key === 'matchRecs') {
      return 'Increases chance of being matched to a PNM for every recommendation a member has received for them';
    }
    return (specialties.find(s => s.get('_id') === key) || Map()).get('algorithm', '');
  };

  return (
    <div className={ parentClasses.outlinedContainer } key={ item.get('key') }>
      <Grid container alignItems='center'>
        <Grid item xs={ 1 }>
          <Field name={ `${field}.enabled` }
            component={ CheckboxInput } />
        </Grid>
        <Grid item xs={ 11 } className={ classes.weightTitle }>
          <Typography color='textSecondary' variant='subtitle2' gutterBottom>{ getTitle() }</Typography>
        </Grid>

        <Grid item xs={ 12 } className={ classes.weightDescription }>
          <Typography color='textSecondary' variant='body2' gutterBottom>{ getAlgorithm() }</Typography>
        </Grid>

        <Grid item xs={ 12 } className={ classes.sliderContainer }>
          <Field name={ `${field}.weight` }
            render={ fieldProps => (
              <RangeInput initialValue={ item.get('weight') }
                minValue={ 1 }
                maxValue={ 10 }
                { ...fieldProps } />
            ) } />
        </Grid>
      </Grid>
    </div>
  );
}

const styles = () => ({
  sliderContainer: {
    marginBottom: 20,
    marginTop:    10,
  },

  weightTitle: {
    marginTop:   6,
    paddingLeft: 5,
  },

  weightDescription: {
    height: 75,
  },
});

export default withStyles(styles)(WeightItem);
