import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { roundListSchema } from '../../schemas/round';

export default function getRounds(state) {
  const round = state.get('round');
  const currentChapter = state.get('currentChapter');

  const result = currentChapter.getIn(['data', 'rounds'], List());
  const rounds = round.getIn(['data', 'items'], Map());

  const entities = {
    round: rounds.toJS(),
  };

  const items = denormalize(result.toJS(), roundListSchema, entities);
  return Immutable.fromJS(items);
}
