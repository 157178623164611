import { toArray, toString } from './transform';

export const isEmpty = (value) => {
  try {
    return (
      value === undefined
      || value === ''
      || value === null
      || JSON.stringify(value) === JSON.stringify({})
      || value.length === 0
    );
  } catch (e) {
    return false;
  }
};

export const isNumber = (value) => {
  const result = !Number.isNaN(parseFloat(value)) && Number.isFinite(parseFloat(value));
  if (result) {
    return String(parseFloat(value)).length === String(value).length;
  }

  return false;
};

export const isObject = value => typeof value === 'object' && value !== null;

export const isString = value => typeof value === 'string' || value instanceof String;

export const isArray = value => Array.isArray(value);

export const isFunc = value => value && {}.toString.call(value) === '[object Function]';

export const inArray = (value, array) => toArray(array).indexOf(value) !== -1;

export const isSubStrInStr = (subStr, str, flags = 'gi') => !!(toString(str).match(new RegExp(toString(subStr), flags)));
