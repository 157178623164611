import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
// import { currentChapterSet } from '../../actions/chapterActions';
import { storeClear } from '../../actions/storeActions';
import { push } from 'react-router-redux';

// api
import * as clientExport from '../../sources/api/client';
import userService from '../../sources/api/userService';
import chapterService from '../../sources/api/chapterService';

// constants
import { SESSION_VERIFY } from '../../constants/sessionTypes';
import { CURRENT_USER_FETCH } from '../../constants/userTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSchema } from '../../schemas/user';
import { chapterSchema } from '../../schemas/chapter';

export function* sessionVerify(action) {
  const { default: client, get } = clientExport;
  if (action['@@redux-saga/SAGA_ACTION']) return;

  let userId;
  let chapterId;

  // authenticate client and grab user id from response
  try {
    yield client.reAuthenticate();
    const auth = yield client.get('authentication');
    userId = auth.user.id;
  } catch (err) {
    yield client.logout();
    yield put(push('/login'));
    yield put(storeClear());
  }

  // if authentication was successful and the user was present in the response, fetch the user
  if (userId) {
    const chapterFromSession = yield window.localStorage.getItem('chapter');
    const options = {};

    if (chapterFromSession) {
      chapterId = chapterFromSession;

      options.query = {
        chapter: chapterFromSession,
      };
    }

    try {
      const response = yield* httpSaga(
        CURRENT_USER_FETCH,
        call(get, userService, userId, options),
        {
          schema: userSchema,
        }
      ) || {};

      if (!chapterId) {
        const user = response.data || {};
        const chapters = user.chapters || [];

        if (chapters.length === 1) {
          chapterId = chapters[0].id;
        } else if ([1, 2].includes(user.clearance_level) || chapters.length > 1) {
          if (!chapterId) {
            yield put(push('/dashboard'));
          }
        }
      }
    } catch (err) {
      yield put(error(CURRENT_USER_FETCH, err));
    } finally {
      yield put(doneIndicator(CURRENT_USER_FETCH));
    }
  }

  // fetch the current chapter
  if (chapterId) {
    try {
      yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapterId), {
        schema: chapterSchema,
      });
    } catch (err) {
      yield put(error(CURRENT_CHAPTER_FETCH, err));
    } finally {
      yield put(doneIndicator(CURRENT_CHAPTER_FETCH));
    }
  }

  // dispatch the done indicator for session verify
  yield put(doneIndicator(SESSION_VERIFY));
}

export function* watchSessionVerify() {
  yield takeEvery(SESSION_VERIFY, sessionVerify);
}

export default function* root() {
  yield all([
    fork(watchSessionVerify),
  ]);
}
