import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Field } from 'redux-form/immutable';
import TextInput from '../../TextInput';
import Button from '../../Button';
import { Link } from 'react-router-dom';

// styles
import '../ForgotPassword.scss';

const handleKeyDown = function (e, cb) {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault();
    cb();
  }
};

class ForgotPasswordForm extends Component {
  static propTypes = {
    forgotPasswordForm:   PropTypes.instanceOf(Map).isRequired,
    updateForgotPassword: PropTypes.func.isRequired,
    match:                PropTypes.instanceOf(Object).isRequired,
  }

  handleSubmit = () => {
    const {
      forgotPasswordForm,
      updateForgotPassword,
      match: { params: { token } },
    } = this.props;

    const values = (forgotPasswordForm.get('values') || Map()).toJS();

    if (!forgotPasswordForm.get('syncErrors')) {
      updateForgotPassword({ token, ...values });
    }
  }

  renderErrorMessage = () => {
    const { forgotPasswordForm } = this.props;

    let element;

    if (forgotPasswordForm.get('submitFailed')) {
      element = (
        <div className='forgot-password__error-container'>
          <FormHelperText error>Failed to reset password, please try again</FormHelperText>
        </div>
      );
    }

    return element;
  }

  render() {
    const { forgotPasswordForm } = this.props;

    let disableButton = false;

    if (forgotPasswordForm.get('syncErrors')) { disableButton = true; }

    return [
      <Grid item xs={ 12 } component='form' key='form' onKeyDown={ (e) => { handleKeyDown(e, this.handleSubmit); } }>
        <Grid container spacing={ 24 }>
          <Grid item xs={ 12 }>
            <div className='forgot-password-form__input-container'>
              <Icon className='forgot-password-form__icon' color='primary'>lock_outline</Icon>
              <Field errorClassName='forgot-password-form__error'
                InputProps={ { disableUnderline: true } }
                name='password'
                type='password'
                component={ TextInput }
                placeholder='Enter New Password'
                required
                fullWidth />
            </div>
          </Grid>

          <Grid item xs={ 12 }>
            <div className='forgot-password-form__input-container'>
              <Icon className='forgot-password-form__icon' color='primary'>lock_outline</Icon>
              <Field errorClassName='forgot-password-form__error'
                InputProps={ { disableUnderline: true } }
                name='confirmPassword'
                type='password'
                component={ TextInput }
                placeholder='Confirm New Password'
                required
                fullWidth />
            </div>
          </Grid>
        </Grid>
      </Grid>,

      <Grid xs={ 12 } item key='button'>
        <div className='forgot-password__button-container'>
          { this.renderErrorMessage() }

          <Button className='forgot-password__button'
            color='primary'
            onClick={ this.handleSubmit }
            disabled={ disableButton }
            loading={ forgotPasswordForm.get('submitting') }
            variant='contained'>
            Save New Password
          </Button>
        </div>
      </Grid>,

      <Grid item key='link'>
        <Link className='forgot-password__link' to='/login' href='/login'>
          <Typography variant='button' color='primary'>Login</Typography>
        </Link>
      </Grid>,
    ];
  }
}

export default ForgotPasswordForm;
