import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';

// components
import MainCard from './components/MainCard';
import NewMemberCard from './components/NewMemberCard';
import Banner from '../Banner';
import ConfirmedMemberCard from './components/ConfirmedMemberCard';
import Button from '../Button';
import CommentList from '../CommentList';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

// styles
import './Home.scss';

class Home extends Component {
  static propTypes = {
    classes:            PropTypes.instanceOf(Object).isRequired,
    clearVotes:         PropTypes.func.isRequired,
    currentChapter:     PropTypes.instanceOf(Map).isRequired,
    currentChapterId:   PropTypes.number.isRequired,
    currentUser:        PropTypes.instanceOf(Map).isRequired,
    deleteMatch:        PropTypes.func.isRequired,
    deleteVote:         PropTypes.func.isRequired,
    fetchAllMatches:    PropTypes.func.isRequired,
    fetchPnmsToConfirm: PropTypes.func.isRequired,
    fetchVotes:          PropTypes.func.isRequired, // eslint-disable-line
    fetchVotesForUser:   PropTypes.func.isRequired, // eslint-disable-line
    matchLoading:       PropTypes.bool.isRequired,
    matches:            PropTypes.instanceOf(List).isRequired,
    navigate:           PropTypes.func.isRequired,
    organization:       PropTypes.instanceOf(Map).isRequired,
    round:              PropTypes.instanceOf(Map).isRequired,
    reporting:          PropTypes.instanceOf(Map).isRequired,
    vote:               PropTypes.instanceOf(Map).isRequired,
    votes:              PropTypes.instanceOf(List).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        currentTab:    'feed',
        expandedPanel: 0,
        groupedVotes:  List(),
        userToFetch:   null,
      }),
    };
  }

  componentWillMount() {
    const {
      clearVotes,
      currentChapter,
      currentUser,
      fetchPnmsToConfirm,
      organization,
    } = this.props;

    const customerChapterId = currentChapter.getIn(['data', 'customer_chapter_id']);
    const reportingEnabled = !!organization.get('reportingPlatform') && !!customerChapterId;

    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);

    const shouldFetchPnmsToConfirm = teamLevel > 0 || clearanceLevel > 0;

    if (currentChapter.get('data')) {
      clearVotes();
      this.initVotes(this.props);
    }

    if (
      organization
      && reportingEnabled
      && shouldFetchPnmsToConfirm
    ) {
      fetchPnmsToConfirm({ chapter: currentChapter.getIn(['data', 'id'], 0) });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      clearVotes,
      currentChapter,
      currentChapterId,
      currentUser,
      fetchPnmsToConfirm,
      organization,
      vote,
      votes,
    } = nextProps;

    const userToFetch = this.state.data.get('userToFetch');
    const newCurrentEvent = currentChapter.getIn(['data', 'currentEvent'], Map());
    const oldCurrentEvent = this.props.currentChapter.getIn(['data', 'currentEvent'], Map());

    const newCurrentRound = currentChapter.getIn(['data', 'currentRound'], Map());
    const oldCurrentRound = this.props.currentChapter.getIn(['data', 'currentRound'], Map());

    const newOrganization = organization || Map();
    const oldOrganization = this.props.organization || Map();
    const customerChapterId = currentChapter.getIn(['data', 'customer_chapter_id']);
    const reportingEnabled = !!organization.get('reportingPlatform') && !!customerChapterId;

    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);

    const isAdmin = teamLevel > 1 || clearanceLevel > 0;
    const shouldFetchPnmsToConfirm = teamLevel > 0 || clearanceLevel > 0;

    if (!this.props.currentChapter.get('data') && currentChapter.get('data')) {
      clearVotes();
      this.initVotes(nextProps);
    } else if (oldCurrentEvent !== newCurrentEvent || oldCurrentRound !== newCurrentRound) {
      clearVotes();
      this.initVotes(nextProps);
    } else if (!vote.get('loading') && userToFetch) {
      this.setState({ data: this.state.data.set('userToFetch', null) });
    } else if (!this.props.votes.size && votes.size) {
      // Gets votes grouped by author for use in the voters tab
      let groupedVotes = votes.groupBy(v => v.getIn(['author', 'id'], List()));

      groupedVotes = groupedVotes.filter((v, authorId) => {
        if (isAdmin) {
          return v.size > 0;
        }

        const isAuthor = authorId === currentUser.getIn(['data', 'id'], 0);
        return v.size > 0 && isAuthor;
      });

      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('groupedVotes', groupedVotes);
        }),
      });
    }

    if (
      (!oldOrganization && newOrganization)
      && reportingEnabled
      && shouldFetchPnmsToConfirm
    ) {
      fetchPnmsToConfirm({ chapter: currentChapterId });
    }
  }

  initVotes = (props) => {
    const { currentChapter, currentChapterId, fetchVotes } = props;

    const roundId = (currentChapter.get('data') || Map()).get('currentRound');
    const eventNumber = (currentChapter.get('data') || Map()).get('currentEvent');

    fetchVotes({
      chapter:           currentChapterId,
      eventNumber,
      limit:             25,
      shouldPopulatePnm: true,
      roundId,
    });
  };

  handleExpand = (event, expanded, userId, panel) => {
    if (expanded) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('userToFetch', userId);
          map.set('expandedPanel', panel);
        }),
      });
    } else {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('userToFetch', null);
          map.set('expandedPanel', 0);
        }),
      });
    }
  }

  handleChange = (event, value) => {
    const { matches, currentChapterId, fetchAllMatches } = this.props;
    this.setState({ data: this.state.data.set('currentTab', value) });

    if (value === 'matches' && !(matches || List()).size) {
      fetchAllMatches({
        chapter:         currentChapterId,
        skipVoteData:    true,
        skipFieldData:   true,
        skipPnmPopulate: false,
      });
    }
  }

  handleDeleteVote = (id) => {
    const { currentChapterId, deleteVote } = this.props;

    deleteVote({
      id,
      chapter: currentChapterId,
    });
  }

  handleDeleteMatch = (id) => {
    const { currentChapterId, deleteMatch } = this.props;

    deleteMatch({
      id,
      chapter: currentChapterId,
    });
  }

  renderTabs = () => {
    const {
      classes, currentChapter, currentUser, votes,
    } = this.props;

    const elements = [];

    // filters down items to pnms that were voted on anonymously only
    const anonymousVotesCount = (votes.filter(v => v.getIn(['pnm', 'id'], 0) === 0) || List()).size;
    let anonymousVotesLabel = 'Anonymous Votes';

    const teamLevel = currentChapter.getIn(['data', 'team_level'], 0);
    const showBestMatchedWith = currentChapter.getIn(['data', 'showBestMatchedWith'], false);
    const clearanceLevel = currentUser.getIn(['data', 'clearance_level'], 0);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;

    if (anonymousVotesCount) {
      anonymousVotesLabel = `${anonymousVotesLabel} ( ${anonymousVotesCount} )`;
    }

    elements.push(
      <Tab className={ classes.tab }
        key='feed'
        label='Vote Feed'
        value='feed'
        icon={ <Icon>thumbs_up_down</Icon> } />
    );

    if (showBestMatchedWith) {
      elements.push(
        <Tab className={ classes.tab }
          key='matches'
          label='Matches'
          value='matches'
          icon={ <Icon>extension</Icon> } />
      );
    }

    if (isAdmin) {
      elements.push(
        <Tab className={ classes.tab }
          key='voters'
          label='Voters'
          value='voters'
          icon={ <Icon>group</Icon> } />
      );
    }

    if (isAdmin) {
      elements.push(
        <Tab className={ classes.tab }
          key='anon'
          label={ anonymousVotesLabel }
          value='anon'
          icon={ <Icon>help</Icon> } />
      );
    }

    return elements;
  }

  renderEmptyMessage = () => {
    const { classes } = this.props;

    return (
      <div>
        <ListItem className={ classes.title }>
          <Typography color='secondary' align='center' variant='h6'>No Votes Found</Typography>
        </ListItem>
        <ListItem>
          <Avatar className={ classes.avatar }>
            <Icon className={ classes.avatarIcon }>thumbs_up_down</Icon>
          </Avatar>
        </ListItem>
        <ListItem className={ classes.title }>
          <Typography color='secondary' align='center'>Cast votes to view feed</Typography>
        </ListItem>
      </div>
    );
  }

  renderTabTitle = () => {
    const { classes } = this.props;
    const currentTab = this.state.data.get('currentTab');
    let title;
    let icon;
    let subtitle;

    switch (currentTab) {
      case 'feed':
        title = 'Vote Feed';
        icon = 'thumbs_up_down';
        subtitle = 'View and manage all votes for the current round';
        break;
      case 'matches':
        title = 'Matches Feed';
        icon = 'extension';
        subtitle = 'View and manage match recommendations';
        break;
      case 'voters':
        title = 'Voters';
        icon = 'group';
        subtitle = 'Click a voting member to view and delete votes for the current round';
        break;
      case 'anon':
        title = 'Anonymous Votes Feed';
        icon = 'help';
        subtitle = 'Match these votes to a PNM! These were cast by a member that was unable to identify who the PNM was at the time.';
        break;
      default:
        title = 'Vote Feed';
        subtitle = 'View and manage all votes for the current round';
        break;
    }

    return [
      <Grid item xs={ 1 } key='titleIcon'>
        <Icon className={ classes.titleIcon } color='primary'>{ icon }</Icon>
      </Grid>,
      <Grid item xs={ 11 } key='titleText'>
        <Typography variant='h6' color='primary'>
          { title }
        </Typography>
      </Grid>,

      <Grid item xs={ 12 } key='subtitle'>
        <Typography color='textSecondary' gutterBottom>
          { subtitle }
        </Typography>
      </Grid>,
    ];
  }

  renderTabContent = () => {
    const {
      classes, vote, matches, votes, matchLoading,
    } = this.props;

    const loading = vote.get('loading') || false;
    const currentTab = this.state.data.get('currentTab');
    const expandedPanel = this.state.data.get('expandedPanel');
    const groupedVotes = this.state.data.get('groupedVotes').toList();

    // filters down items to pnms that were voted on anonymously only
    const anonymousVotes = votes.filter(v => v.getIn(['pnm', 'id'], 0) === 0) || List();

    if (currentTab === 'feed' && (votes.size || vote.get('loading'))) {
      return (
        <Grid container spacing={ 16 }>
          { this.renderTabTitle() }

          <Grid item xs={ 12 }>
            <CommentList loading={ loading }
              items={ votes }
              type='votes'
              onDelete={ this.handleDeleteVote }
              showPNM />
          </Grid>
        </Grid>
      );
    } if (currentTab === 'matches') {
      return matches.size || matchLoading ? (
        <CommentList type='matches'
          items={ matches }
          loading={ matchLoading }
          showPNM
          onDelete={ this.handleDeleteMatch } />
      ) : (
        <div>
          <ListItem className={ classes.title }>
            <Typography color='secondary' align='center' variant='h6'>No Matches Found</Typography>
          </ListItem>
          <ListItem>
            <Avatar className={ classes.avatar }>
              <Icon className={ classes.avatarIcon }>extension</Icon>
            </Avatar>
          </ListItem>
          <ListItem className={ classes.title }>
            <Typography color='secondary' align='center'>
              Make matches to easily connect your members with PNMs
            </Typography>
          </ListItem>
        </div>
      );
    } if (currentTab === 'voters' && groupedVotes.size) {
      return (
        <Grid container spacing={ 16 }>
          { this.renderTabTitle() }

          <Grid item xs={ 12 }>
            { groupedVotes.map(votesForUser => (
              <ExpansionPanel key={ votesForUser.first().get('_id') }
                className={ classes.expansionContainer }
                expanded={ expandedPanel === `panel${votesForUser.getIn([0, 'author', 'id'])}` }
                onChange={ (event, expanded) =>
                  this.handleExpand(event, expanded, votesForUser.getIn([0, 'author', 'id']), `panel${votesForUser.getIn([0, 'author', 'id'])}`) }>

                <ExpansionPanelSummary expandIcon={ <Icon>expand_more</Icon> }>
                  <Badge badgeContent={ votesForUser.size }
                    color='primary'>
                    <Typography variant='subtitle1'>
                      { `${votesForUser.first().getIn(['author', 'firstname'])} ${votesForUser.first().getIn(['author', 'lastname'])}`}
                    </Typography>
                  </Badge>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  { loading || votes.filter(v => v.getIn(['author', 'id'], 0) === votesForUser.getIn([0, 'author', 'id'])).size
                    ? (
                      <CommentList loading={ loading }
                        fullWidth
                        items={ votes.filter(v => v.getIn(['author', 'id'], 0) === votesForUser.getIn([0, 'author', 'id'])) }
                        type='votes'
                        onDelete={ this.handleDeleteVote }
                        showPNM />
                    ) : (
                      <Typography color='secondary' variant='subtitle1'>No Votes Found.</Typography>
                    )}

                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Grid>
        </Grid>
      );
    } if (currentTab === 'anon' && anonymousVotes.size) {
      return (
        <Grid container spacing={ 16 }>
          { this.renderTabTitle() }

          <Grid item xs={ 12 }>
            <CommentList loading={ loading }
              items={ anonymousVotes }
              type='votes'
              onDelete={ this.handleDeleteVote }
              showPNM />
          </Grid>
        </Grid>
      );
    }
    return this.renderEmptyMessage();
  }

  render() {
    const {
      classes,
      currentChapter,
      currentUser,
      fetchVotesForUser,
      navigate,
      organization,
      reporting,
      round,
      votes,
    } = this.props;

    const isCdSite = currentChapter.getIn(['data', 'isCdSite']) || false;
    const numberOfVotingMembers = currentChapter.getIn(['data', 'numberOfVotingMembers']);
    const teamLevel = (currentChapter.get('data') || Map()).get('team_level') || 0;
    const clearanceLevel = (currentUser.get('data') || Map()).get('clearance_level') || 0;
    const chapterId = currentChapter.getIn(['data', 'id'], 0);
    const roundId = currentChapter.getIn(['data', 'currentRound'], 0);
    const isAdmin = teamLevel > 0 || clearanceLevel > 0;

    const reportingPlatform = organization.get('reportingPlatform');
    const customerChapterId = currentChapter.getIn(['data', 'customer_chapter_id']);
    const reportingEnabled = !!reportingPlatform && !!customerChapterId;
    const reportingPlatformDisplayName = reportingPlatform && reportingPlatform.get('displayName');
    const isEduEmailAllowed = reportingPlatform && reportingPlatform.get('isEduEmailAllowed');

    const notConfirmedPnms = reporting.getIn(['data', 'notConfirmed']) || List();
    const confirmedPnms = reporting.getIn(['data', 'confirmed']) || List();

    const displayNewMemberCard = notConfirmedPnms.size && isAdmin && reportingEnabled;
    const displayConfirmedCard = confirmedPnms.size && isAdmin && reportingEnabled;

    const rounds = round.getIn(['data', 'items'], List());
    const noRoundsCreated = rounds.size === 0;
    const currentTab = this.state.data.get('currentTab');

    const userToFetch = this.state.data.get('userToFetch');
    if (userToFetch) {
      fetchVotesForUser({
        chapter:           chapterId,
        limit:             100,
        roundId,
        shouldPopulatePnm: true,
        userId:            userToFetch,
      });
    }

    const hideVotesFromVotingMembers = organization.get('hideVotesFromVotingMembers', false);
    const requiredFields = organization.get('requiredFields', List());

    return (
      <Grid container spacing={ 24 } justify='center'>
        {
          (!numberOfVotingMembers && requiredFields.includes('numberOfVotingMembers') && isAdmin)
            && (
            <Banner title='Set Voting Member Count'
              subtitle={
                'Your organization\'s results require you to input the number of active voting members in your chapter. Ensure accurate vote results by setting it!'
              }
              buttonIcon='group'
              buttonLabel='Set Voting Members'
              onButtonClick={ () => navigate('/settings/chapter') } />
            )
        }

        { noRoundsCreated
          && (
          <Grid item xs={ 12 } md={ 12 } lg={ 10 } xl={ 8 }>
            <Card>
              <CardContent>
                <Grid container spacing={ 16 }>
                  <Grid item xs={ 12 }>
                    <Typography color='textSecondary' variant='subtitle1'>
                      Get Started By Creating Rounds
                    </Typography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <Typography>
                      Set up rounds of recruitment and return here to open voting!
                    </Typography>
                  </Grid>
                  <Grid item xs={ 4 }>
                    <Button color='primary' variant='contained' fullWidth onClick={ () => navigate('/settings/rounds') }>
                      <Icon>group_work</Icon>
                      <span className='home__button-label'>Configure Rounds</span>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          )}
        <Grid item xs={ 12 } md={ 12 } lg={ 10 } xl={ 8 }>
          <MainCard teamLevel={ teamLevel } clearanceLevel={ clearanceLevel } votes={ votes } />
        </Grid>

        { displayNewMemberCard
          ? (
            <Grid item xs={ 12 } md={ 12 } lg={ 10 } xl={ 8 }>
              <NewMemberCard pnms={ notConfirmedPnms }
                isEduEmailAllowed={ isEduEmailAllowed }
                reportingPlatformDisplayName={ reportingPlatformDisplayName }
                reporting={ reporting } />
            </Grid>
          )
          : null}

        { displayConfirmedCard
          ? (
            <Grid item xs={ 12 } md={ 12 } lg={ 10 } xl={ 8 }>
              <ConfirmedMemberCard pnms={ confirmedPnms }
                reportingPlatformDisplayName={ reportingPlatformDisplayName }
                reporting={ reporting } />
            </Grid>
          )
          : null}

        { isAdmin && [
          <Grid key='buttons' item xs={ 12 } md={ 12 } lg={ 10 } xl={ 8 }>
            <Grid container justify='space-between' spacing={ 32 }>
              <Grid item xs={ 12 } md={ 4 }>
                <Button color='primary' variant='contained' fullWidth onClick={ () => navigate('/settings/categories') }>
                  <Icon>thumbs_up_down</Icon>
                  <span className='home__button-label'>Configure Voting</span>
                </Button>
              </Grid>

              { !isCdSite
                ? (
                  <Grid item xs={ 12 } md={ 4 }>
                    <Button color='primary' variant='contained' fullWidth onClick={ () => navigate('/import/events') }>
                      <Icon>event</Icon>
                      <span className='home__button-label'>Import Events</span>
                    </Button>
                  </Grid>
                )
                : (
                  <Grid item xs={ 12 } md={ 4 }>
                    <Button color='primary' variant='contained' fullWidth onClick={ () => navigate('/import/users') }>
                      <Icon>group_add</Icon>
                      <span className='home__button-label'>Import Users</span>
                    </Button>
                  </Grid>
                )}

              { !isCdSite
                ? (
                  <Grid item xs={ 12 } md={ 4 }>
                    <Button color='primary' variant='contained' fullWidth onClick={ () => navigate('/import/pnms') }>
                      <Icon>group_add</Icon>
                      <span className='home__button-label'>Import PNMs</span>
                    </Button>
                  </Grid>
                )
                : (
                  <Grid item xs={ 12 } md={ 4 }>
                    <Button color='primary' variant='contained' fullWidth onClick={ () => navigate('/import/votes') }>
                      <Icon>thumbs_up_down</Icon>
                      <span className='home__button-label'>Import Votes</span>
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Grid>,
        ]}

        { (!hideVotesFromVotingMembers || isAdmin)
          && (
          <Grid key='feed' item xs={ 12 } md={ 12 } lg={ 10 } xl={ 8 }>
            <Card className={ classes.tabsCard }>
              <AppBar position='static' color='default'>
                <Tabs value={ currentTab }
                  onChange={ this.handleChange }
                  indicatorColor='primary'
                  textColor='primary'
                  centered
                  variant='fullWidth'>
                  { this.renderTabs() }
                </Tabs>
              </AppBar>
              <CardContent>
                { this.renderTabContent() }
              </CardContent>
            </Card>
          </Grid>
          )}
      </Grid>
    );
  }
}

const styles = theme => ({
  tabsCard: {
    backgroundColor: '#fff',
    height:          'auto',
    minHeight:       '74.75vh',
    borderRadius:    10,
  },

  title: {
    display: 'block',
    margin:  'auto',
  },

  titleIcon: {
    marginTop:   5,
    marginRight: 10,
  },

  expansionContainer: {
    paddingTop:    10,
    paddingBottom: 5,
    width:         '100%',
  },

  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatarIcon: {
    fontSize: 50,
  },
});

export default withStyles(styles)(Home);
