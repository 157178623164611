export const NOTE_FETCH_FOR_PNM = 'NOTE_FETCH_FOR_PNM';
export const NOTE_FETCH_FOR_PNM_INIT = 'NOTE_FETCH_FOR_PNM_INIT';
export const NOTE_FETCH_FOR_PNM_DONE = 'NOTE_FETCH_FOR_PNM_DONE';

export const NOTE_FETCH_ALL = 'NOTE_FETCH_ALL';
export const NOTE_FETCH_ALL_INIT = 'NOTE_FETCH_ALL_INIT';
export const NOTE_FETCH_ALL_DONE = 'NOTE_FETCH_ALL_DONE';

export const NOTE_DELETE = 'NOTE_DELETE';
export const NOTE_DELETE_INIT = 'NOTE_DELETE_INIT';
export const NOTE_DELETE_DONE = 'NOTE_DELETE_DONE';

export const NOTE_CREATE = 'NOTE_CREATE';
export const NOTE_CREATE_INIT = 'NOTE_CREATE_INIT';
export const NOTE_CREATE_DONE = 'NOTE_CREATE_DONE';

export const NOTE_BULK_CREATE = 'NOTE_BULK_CREATE';
export const NOTE_BULK_CREATE_INIT = 'NOTE_BULK_CREATE_INIT';
export const NOTE_BULK_CREATE_DONE = 'NOTE_BULK_CREATE_DONE';

export const NOTE_CLEAR = 'NOTE_CLEAR';
