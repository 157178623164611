import React from 'react';

// components
import Layout from '../Layout';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import Signup from '../Signup';
import { Route, Switch } from 'react-router-dom';

// lib
import authenticated from '../../lib/authenticated';

// styles
import './App.scss';

// wrapped components
const AuthenticatedLayout = authenticated(Layout);

const App = function () {
  return (
    <div className='body'>
      <Switch>
        <Route path='/signup/:token' component={ Signup } />

        <Route path='/forgot-password/:token' component={ ForgotPassword } />

        <Route path='/forgot-password' component={ ForgotPassword } />

        <Route path='/login' component={ Login } />

        <Route path='/' component={ AuthenticatedLayout } />
      </Switch>
    </div>
  );
};

export default App;
