import Immutable, { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { pnmListSchema } from '../../schemas/pnm';

export default function getPnms(state) {
  const pnm = state.get('pnm');
  let elements = [];

  const pnmData = pnm.get('data') || Map();

  if (pnmData.get('result')) {
    const result = (pnmData.get('result', List())).toJS();
    const entities = { pnm: (pnmData.get('items', Map())).toJS() };

    elements = denormalize(result, pnmListSchema, entities) || [];
  }

  return Immutable.fromJS(elements);
}
