// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/chevron.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password{align-items:center;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-color:#4285f4;display:flex;flex-grow:1}.forgot-password__container{padding:40px}.forgot-password__logo{max-height:60px}.forgot-password__button-container{align-items:center;display:flex;flex-direction:column;justify-content:flex-end;min-height:75px}.forgot-password__button{margin-top:15px}.forgot-password__link{text-decoration:none;text-transform:uppercase}.forgot-password-form{align-items:center;display:flex;flex-direction:column;width:100%}.forgot-password-form__input-container{border-radius:30px;box-shadow:0 6px 20px 0 rgba(157,157,157,.7);margin:0 auto;max-width:300px;min-width:250px;padding:5px 20px 5px 40px;position:relative}.forgot-password-form__icon{left:9px;position:absolute;top:9px}.forgot-password-form__error{bottom:-17px;position:absolute;white-space:nowrap}.forgot-password-form__password-container{margin-top:25px}.forgot-password-form__input-spinner{position:absolute;right:10px;top:10px}.forgot-password-form__error-container{margin-bottom:10px}input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active{transition:background-color 5000s ease-in-out 0s}`, "",{"version":3,"sources":["webpack://./src/components/ForgotPassword/ForgotPassword.scss","webpack://./src/assets/stylesheets/variables.scss"],"names":[],"mappings":"AAIA,iBACE,kBAAA,CACA,kDAAA,CACA,wBCLiB,CDMjB,YAAA,CACA,WAAA,CAGF,4BACE,YAAA,CAGF,uBACE,eAAA,CAGF,mCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,eAAA,CAGF,yBACE,eAAA,CAGF,uBACE,oBAAA,CACA,wBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CAGF,uCACE,kBAAA,CACA,4CA5CwB,CA6CxB,aAAA,CACA,eAAA,CACA,eAAA,CACA,yBAAA,CACA,iBAAA,CAGF,4BACE,QAAA,CACA,iBAAA,CACA,OAAA,CAGF,6BACE,YAAA,CACA,iBAAA,CACA,kBAAA,CAGF,0CACE,eAAA,CAGF,qCACE,iBAAA,CACA,UAAA,CACA,QAAA,CAGF,uCACE,kBAAA,CAIF,+GAII,gDAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
