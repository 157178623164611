import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import exportService from '../../sources/api/exportService';

// constants
import { EXPORT_FETCH_FOR_PDF, EXPORT_FETCH_FOR_PDF_INIT } from '../../constants/exportTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* exportFetchAllForPdf(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter } = action.payload || {};

  const options = {
    query: {
      chapter,
      results: {
        includeFields: 'all',
      },
    },
  };

  try {
    yield* httpSaga(EXPORT_FETCH_FOR_PDF, call(find, exportService, options));
  } catch (err) {
    yield put(error(EXPORT_FETCH_FOR_PDF, err));
  } finally {
    yield put(doneIndicator(EXPORT_FETCH_FOR_PDF));
  }
}

export function* watchExportFetchAllForPdf() {
  yield takeEvery(EXPORT_FETCH_FOR_PDF_INIT, exportFetchAllForPdf);
}

export default function* root() {
  yield all([
    fork(watchExportFetchAllForPdf),
  ]);
}
