import Immutable, { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../../../Button';
import SelectInput from '../../../../../SelectInput';

class BulkActions extends Component {
  static propTypes = {
    classes:                 PropTypes.instanceOf(Object).isRequired,
    currentChapter:          PropTypes.instanceOf(Map).isRequired,
    pnms:                    PropTypes.instanceOf(List).isRequired,
    statusForm:              PropTypes.instanceOf(Map).isRequired,
    bulkUpdatePnms:          PropTypes.func.isRequired,
    updateAllPnmsForChapter: PropTypes.func.isRequired,
    allSelected:             PropTypes.bool.isRequired,
    filter:                  PropTypes.instanceOf(Object).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        errorMessage:   '',
        successMessage: '',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { statusForm } = nextProps;

    if (statusForm.get('submitSucceeded')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', null);
          map.set('successMessage', 'Status Updated Successfully');
        }),
      });
    } else if (statusForm.get('submitFailed')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', 'Failed to Update Status');
          map.set('successMessage', null);
        }),
      });
    }
  }

  getStatusOptions = () => {
    const items = [
      { value: 0, label: 'Recruited' },
      { value: 1, label: 'Active' },
      { value: 2, label: 'No Show' },
      { value: 3, label: 'Withdrawn' },
      { value: 4, label: 'Regret' },
      { value: 5, label: 'Release' },
      { value: 6, label: 'Not Applicable' },
    ];

    return items;
  }

  handleSave = () => {
    const {
      currentChapter, statusForm, bulkUpdatePnms,
      pnms, updateAllPnmsForChapter, allSelected, filter,
    } = this.props;

    const statusOptions = [
      { value: 0, abbr: 'R' },
      { value: 1, abbr: 'A' },
      { value: 2, abbr: 'NS' },
      { value: 3, abbr: 'WD' },
      { value: 4, abbr: 'RG' },
      { value: 5, abbr: 'RL' },
      { value: 6, abbr: 'NA' },
      { value: 7, abbr: 'IN' },
    ];

    if (!statusForm.get('syncErrors')) {
      const status = statusForm.getIn(['values', 'status']);

      let statusAbbr = 'A';
      statusOptions.forEach((s) => {
        if (status === s.value) {
          statusAbbr = s.abbr;
        }
      });

      const items = pnms.map(pnm => ({
        id:     pnm.get('id'),
        status: statusAbbr,
      }));

      if (allSelected) {
        updateAllPnmsForChapter({
          formName: 'statusForm',
          chapter:  currentChapter.getIn(['data', 'id'], 0),
          newData:  {
            status: statusAbbr,
          },
          filter,
        });
      } else {
        bulkUpdatePnms({
          formName: 'statusForm',
          chapter:  currentChapter.getIn(['data', 'id'], 0),
          items:    items.toJS(),
        });
      }
    }
  }

  renderMessage = () => {
    const { classes } = this.props;
    const successMessage = this.state.data.get('successMessage');
    const errorMessage = this.state.data.get('errorMessage');

    let element;

    if (successMessage) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <Typography className={ classes.successLabel }>{ successMessage }</Typography>
        </Grid>
      );
    } else if (errorMessage) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <Typography color='error'>{ errorMessage }</Typography>
        </Grid>
      );
    }

    return element;
  }

  render() {
    const { statusForm } = this.props;

    let disabled = false;
    if (statusForm.get('syncErrors')) { disabled = true; }

    return (
      <Grid item xs={ 12 }>
        <Grid container spacing={ 8 }>
          <Grid item xs={ 12 }>
            <Field name='status'
              options={ this.getStatusOptions() }
              component={ SelectInput }
              label='Select Status'
              fullWidth
              required />
          </Grid>

          <Grid item xs={ 12 } align='center'>
            <Button variant='contained'
              color='primary'
              loading={ statusForm.get('submitting') }
              disabled={ disabled }
              onClick={ this.handleSave }>

              Save
            </Button>
          </Grid>

          { this.renderMessage() }
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  successLabel: {
    color: '#00E676',
  },
});

export default withStyles(styles)(BulkActions);
