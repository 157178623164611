import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import XLSX from 'xlsx';
import pluralize from 'pluralize';

// mui components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { lighten } from '@material-ui/core/styles/colorManipulator';

// local components
import Button from '../../../../../Button';

// image assets
import Logo from '../../../../../../assets/images/cd_logo.png';

// helpers
import { toFloat, toString } from '../../../../../../helpers/transform';
import { isArray, isEmpty } from '../../../../../../helpers/check';

class TableToolbar extends Component {
  static propTypes = {
    chapterTags:         PropTypes.instanceOf(List).isRequired,
    classes:             PropTypes.instanceOf(Object).isRequired,
    cobMode:             PropTypes.bool.isRequired,
    currentRound:        PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    displayOverallScore: PropTypes.bool.isRequired,
    extraColumnType:     PropTypes.string.isRequired,
    fields:              PropTypes.instanceOf(Map).isRequired,
    flexMinus:           PropTypes.number,
    flexPlus:            PropTypes.number,
    hometownGroups:      PropTypes.instanceOf(List).isRequired,
    icon:                PropTypes.string.isRequired,
    invitations:         PropTypes.number,
    isCdSite:            PropTypes.bool.isRequired,
    isCurrentRoundUsed:  PropTypes.bool.isRequired,
    pnms:                PropTypes.instanceOf(List).isRequired,
    rounds:              PropTypes.instanceOf(List).isRequired,
    categories:          PropTypes.instanceOf(List).isRequired,
    subtitle:            PropTypes.string.isRequired,
    title:               PropTypes.string.isRequired,
    displayOptions:      PropTypes.shape({
      includeGPA1:               PropTypes.bool,
      includeGPA2:               PropTypes.bool,
      includePnmTags:            PropTypes.bool,
      includePnmImages:          PropTypes.bool,
      includePnmStatus:          PropTypes.bool,
      displayCategoryScores:     PropTypes.bool,
      displayCategoryScoresList: PropTypes.arrayOf(PropTypes.string),
      displayRoundScores:        PropTypes.bool,
      displayRoundScoresList:    PropTypes.arrayOf(PropTypes.string),
      selectedField:             PropTypes.instanceOf(Array),
    }),
  };

  static defaultProps = {
    flexMinus:      0,
    flexPlus:       0,
    invitations:    0,
    displayOptions: {},
  }

  getCategories = () => {
    const { categories } = this.props;

    return List.isList(categories) ? categories : List();
  };

  getExportCols = () => {
    const {
      chapterTags,
      displayOverallScore,
      extraColumnType,
      fields,
      hometownGroups,
      isCdSite,
      cobMode,
      rounds,
      displayOptions,
    } = this.props;

    const {
      includeGPA1,
      includeGPA2,
      includePnmTags,
      includePnmStatus,
      displayCategoryScores,
      displayRoundScores,
      displayCategoryScoresList = [],
      displayRoundScoresList = [],
      selectedField = [],
    } = displayOptions || {};

    const newCols = [
      { key: 0, name: 'Council ID' },
      { key: 1, name: 'List' },
      { key: 2, name: 'Score Rank' },
      { key: 3, name: 'First Name' },
      { key: 4, name: 'Last Name' },
      { key: 5, name: 'Pool' },
    ];

    let nextKey = 6;

    if (includePnmStatus) {
      newCols.push({
        key:  nextKey,
        name: 'Status',
      });
      nextKey += 1;
    }

    if (includeGPA1) {
      newCols.push({
        key:  nextKey,
        name: 'GPA 1',
      });
      nextKey += 1;
    }

    if (includeGPA2) {
      newCols.push({
        key:  nextKey,
        name: 'GPA 2',
      });
      nextKey += 1;
    }

    newCols.push({
      key:  nextKey,
      name: 'Grade Risk',
    });
    nextKey += 1;

    newCols.push({
      key:  nextKey,
      name: 'Event',
    });
    nextKey += 1;

    if (isArray(selectedField) && !isEmpty(selectedField)) {
      selectedField.forEach((item) => {
        newCols.push({
          key:  nextKey,
          name: item.label || '',
        });
        nextKey += 1;
      });
    }

    let yearInCollegeName = '';

    const hasYearInCollegeField = fields.find((f) => {
      yearInCollegeName = f.getIn(['standardField', 'text'], '');

      return yearInCollegeName.toLowerCase() === 'year in college'
        || yearInCollegeName.toLowerCase() === 'college student status';
    });

    if (hasYearInCollegeField) {
      newCols.push({ key: nextKey, name: yearInCollegeName });
      nextKey += 1;
    }

    if (isCdSite && !cobMode) {
      newCols.push({ key: nextKey, name: 'Legacy (via CampusDirector)' });
      nextKey += 1;
    }

    if (hometownGroups.size) {
      newCols.push({ key: nextKey, name: 'Hometown Group' });
      nextKey += 1;
    }

    if (includePnmTags) {
      chapterTags.forEach((t, index) => {
        newCols.push({ key: nextKey + index, name: t.get('title') });
        nextKey += 1;
      });
    }

    if (displayOverallScore) {
      newCols.push({ key: nextKey, name: 'Overall' });
      nextKey += 1;
      newCols.push({ key: nextKey, name: 'Overall # Votes' });
      nextKey += 1;
    }

    if (extraColumnType) {
      switch (extraColumnType) {
        case 'raw':
          newCols.push({ key: nextKey, name: 'Raw Score' });
          nextKey += 1;
          newCols.push({ key: nextKey, name: 'Raw Score # Votes' });
          nextKey += 1;
          break;
        default:
          break;
      }
    }

    if (displayRoundScores && displayRoundScoresList.length > 0) {
      rounds.forEach((r, index) => {
        const id = r.get('_id', '').toString();
        if (displayRoundScoresList.indexOf(id) !== -1) {
          newCols.push({ key: nextKey + index, name: r.get('name') });
          nextKey += 1;
          newCols.push({ key: nextKey + index, name: `${r.get('name')} # Votes` });
          nextKey += 1;

          if (r.get('roundType') === 5 && extraColumnType === 'pre-pref') {
            newCols.push({ key: nextKey, name: 'Pre-Pref Score' });
            nextKey += 1;
            newCols.push({ key: nextKey, name: 'Pre-Pref Score # Votes' });
            nextKey += 1;
          }
        }
      });
    }

    if (displayCategoryScores && displayCategoryScoresList.length > 0) {
      this.getCategories().forEach((category, index) => {
        const id = category.get('_id', '').toString();
        const name = category.get('name', '');
        const roundType = category.get('roundType', '');
        const roundTypeName = category.get('roundTypeName', '');
        const newName = `${name} (${roundType} - ${roundTypeName})`;
        if (displayCategoryScoresList.indexOf(id) !== -1) {
          newCols.push({
            key:  nextKey + index,
            name: newName,
          });
          nextKey += 1;
          newCols.push({
            key:  nextKey + index,
            name: `${newName} # Votes`,
          });
          nextKey += 1;
        }
      });
    }

    return newCols;
  }

  getFieldValue = (item = Map()) => {
    const type = item.getIn(['standardField', 'type'], '');
    let optionsArray;

    switch (type) {
      case 'text':
        return item.get('text');
      case 'link':
        return item.get('text') ? <a href={ item.get('text') } target='_blank' rel='noopener noreferrer'>Link</a> : '';
      case 'number':
        return item.get('number');
      case 'select':
        optionsArray = item.getIn(['standardField', 'options'], List()).split(',');
        return optionsArray[item.get('selector')];
      default:
        return '';
    }
  }

  getListEntry = (index) => {
    const { flexMinus, flexPlus, invitations } = this.props;

    if (invitations > 0) {
      if (index < (invitations - flexMinus)) {
        return 'Invited';
      }
      if (index < invitations && flexMinus > 0) {
        return 'Flex Minus';
      }
      if (index < (invitations + flexPlus) && flexPlus > 0) {
        return 'Flex Plus';
      }
    }
    return '';
  }

  formatData = (pnms) => {
    const {
      chapterTags,
      displayOverallScore,
      extraColumnType,
      fields,
      hometownGroups,
      isCdSite,
      cobMode,
      rounds,
      displayOptions,
    } = this.props;

    const {
      includeGPA1,
      includeGPA2,
      includePnmTags,
      includePnmStatus,
      displayCategoryScores,
      displayRoundScores,
      displayCategoryScoresList = [],
      displayRoundScoresList = [],
      selectedField = [],
    } = displayOptions || {};

    // Creates an array of arrays without keys, only properties for exporting
    const data = [];

    let yearInCollegeName = '';
    const hasYearInCollegeField = fields.find((f) => {
      yearInCollegeName = f.getIn(['standardField', 'text'], '');

      return yearInCollegeName.toLowerCase() === 'year in college'
        || yearInCollegeName.toLowerCase() === 'college student status';
    });

    let flexMinusStart = 0;
    let flexMinusEnd = pnms.size + 1;

    pnms.forEach((pnm, index) => {
      const councilId = isCdSite && !cobMode
        ? pnm.get('group_pnm_id')
        : pnm.get('leg_tech_pnm_id');

      const yearInCollegeField = (pnm.get('standardFields') || List())
        .find(p => p.getIn(['standardField', 'text'], '') === yearInCollegeName);

      const yearInCollegeValue = this.getFieldValue(yearInCollegeField);

      const legacy = pnm.get('isLegacy') ? 'Yes' : 'No';
      const gradeRisk = pnm.get('isGradeRisk') ? 'Yes' : 'No';
      const pool = pnm.get('cd_pool_id') === 0 ? 'Primary' : 'Secondary';
      const pnmTags = pnm.get('tags') || List();

      const listEntry = this.getListEntry(index);
      const prevListEntry = this.getListEntry(index - 1);

      if (prevListEntry !== listEntry) { // creates empty, or +/- row after flex/invite dividers
        let columnData = '';
        if (listEntry === 'Flex Plus') {
          columnData = '+++';
        } else if (listEntry === 'Flex Minus') {
          columnData = '---';
          flexMinusStart = data.length + 1;
        }
        if (prevListEntry === 'Flex Minus') {
          flexMinusEnd = data.length;
        }

        data.push([
          columnData,
        ]);
      }
      // Add rounds scores to the export
      const pnmRounds = (pnm.get('rounds') || List()).reverse();

      let exportRow = [
        councilId,
        listEntry,
        pnm.get('scoreRank'),
        pnm.get('firstname'),
        pnm.get('lastname'),
        pool,
        ...includePnmStatus ? [pnm.get('status', '')] : [],
        ...includeGPA1 ? [pnm.get('gpa1', '')] : [],
        ...includeGPA2 ? [pnm.get('gpa2', '')] : [],
        gradeRisk,
        pnm.get('event'),
      ];

      if (isArray(selectedField) && !isEmpty(selectedField)) {
        const customFieldResult = pnm.get('customFieldResult', Map());
        selectedField.forEach((item) => {
          const { value: key = '' } = item || {};
          exportRow = exportRow.concat([
            customFieldResult.getIn([toString(key), 'text']),
          ]);
        });
      }

      if (hasYearInCollegeField) {
        exportRow = exportRow.concat([yearInCollegeValue]);
      }

      if (isCdSite && !cobMode) {
        exportRow = exportRow.concat([legacy]);
      }

      if (hometownGroups.size) {
        exportRow = exportRow.concat(pnm.get('hometown'));
      }

      if (includePnmTags) {
        exportRow = exportRow.concat(
          chapterTags.map((t) => {
            const pnmTag = pnmTags.find(pt => t.get('_id') === pt.get('_id'));
            if (pnmTag) {
              const countText = pluralize('time', pnmTag.get('count'), true);
              const tagCellText = `${t.get('title')} (${countText})`;
              return [tagCellText];
            }
            return [''];
          }).toJS()
        );
      }

      if (displayOverallScore) {
        const overallPair = extraColumnType
          ? [
            pnm.getIn(['overall', 'value', 'overallScore'], 'N/A'),
            pnm.getIn(['overall', 'voteCount'], 0),
          ]
          : [
            pnm.getIn(['overall', 'value'], 'N/A'),
            pnm.getIn(['overall', 'voteCount'], 0),
          ];
        exportRow = exportRow.concat(overallPair);
      }

      if (extraColumnType) {
        switch (extraColumnType) {
          case 'raw':
            exportRow = exportRow.concat(
              [
                pnm.getIn(['overall', 'value', 'rawScore'], 'N/A'),
                pnm.getIn(['overall', 'extraColumnVoteCount'], 0),
              ]
            );
            break;
          default:
            break;
        }
      }

      if (displayRoundScores && displayRoundScoresList.length > 0) {
        rounds.forEach((r) => {
          const id = r.get('_id', '').toString();
          if (displayRoundScoresList.indexOf(id) !== -1) {
            const roundValue = (pnmRounds.find(pr => pr.get('roundId') === r.get('_id')) || Map()).get('value', 'N/A');
            const roundVoteCount = (pnmRounds.find(pr => pr.get('roundId') === r.get('_id')) || Map()).get('voteCount', 0);
            const roundPair = [roundValue, roundVoteCount];
            exportRow = exportRow.concat(roundPair);

            if (r.get('roundType') === 5 && extraColumnType === 'pre-pref') {
              const extraCellValue = ((pnm.get('overall') || Map()).get('value') || Map()).get('prePreferenceScore', 'N/A');
              const extraCellCount = (pnm.get('overall') || Map()).get('extraColumnVoteCount', 0);
              const extraCellPair = [extraCellValue, extraCellCount];
              exportRow = exportRow.concat(extraCellPair);
            }
          }
        });
      }

      if (displayCategoryScores && displayCategoryScoresList.length > 0) {
        const roundCategoriesResult = pnm.get('roundCategoriesResult', Map());
        this.getCategories().forEach((category) => {
          const id = category.get('_id', '').toString();
          if (displayCategoryScoresList.indexOf(id) !== -1) {
            const value = roundCategoriesResult.getIn([id, 'value'], 0);
            const vote = roundCategoriesResult.getIn([id, 'voteCount'], 0);
            const newValue = value && vote ? toFloat((value / vote).toFixed(2)) : 'N/A';
            exportRow = exportRow.concat([
              vote > 0 && value === 0 ? value : newValue,
              vote,
            ]);
          }
        });
      }

      data.push(exportRow);
    });

    // If there are PNMs in Flex Minus section,
    // add them to Invited section and flip in Flex Minus section
    if (flexMinusStart > 0) {
      const flexMinusPnms =  data.splice(
        flexMinusStart,
        flexMinusEnd - flexMinusStart,
        ...data.slice(flexMinusStart, flexMinusEnd).reverse()
      );
      data.splice(
        flexMinusStart - 1,
        0,
        ...flexMinusPnms.map(pnm => [...pnm.slice(0, 1), 'Invited', ...pnm.slice(2)])
      );
    }

    return data;
  };

  exportFile = (cols, unformattedData, sheetName, fileName) => {
    const data = this.formatData(unformattedData);
    // make array of column names
    const colNames = [];
    cols.forEach(col =>
      colNames.push(col.name));

    // create array with cols and data combined
    data.unshift(colNames);

    // autofit table cell width
    const colWidths = [];
    colNames.forEach((item) => {
      // Sets the cell width in characters, multiplied for more spacing
      colWidths.push({ wch: item.length * 2.2 });
    });

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(data);

    ws['!cols'] = colWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  render() {
    const {
      classes, cobMode, icon, isCdSite, pnms, subtitle, title,
      isCurrentRoundUsed, currentRound,
    } = this.props;

    const cols = this.getExportCols();

    const sheetName = 'Results Report';
    const fileName = 'results_report';

    return (
      <Toolbar className={ classes.root }>
        <Grid container className={ classes.tableToolbar } spacing={ 16 }>
          <Grid item xs={ 6 } className={ classes.titleContainer }>
            <Icon className={ classes.titleIcon } color='primary'>{ icon }</Icon>
            <Typography variant='h5' color='primary'>{ title }</Typography>
          </Grid>

          <Hidden smDown>
            <Grid item xs={ 6 } align='right'>
              <Grid container alignItems='center' alignContent='center' spacing={ 16 }>
                <Grid item xs={ 6 } sm={ 8 }>
                  { (isCdSite && !cobMode)
                  && (
                  <Tooltip title='Synced with CampusDirector'>
                    <img src={ Logo } alt='CampusDirector Sync' className={ classes.logo } />
                  </Tooltip>
                  )}
                </Grid>

                <Grid item xs={ 6 } sm={ 4 }>
                  <Tooltip title='Download results to spreadsheet'>
                    <Button color='primary' variant='contained' onClick={ () => this.exportFile(cols, pnms, sheetName, fileName) }>
                      <Icon className={ classes.leftIcon }>file_download</Icon>

                      Download
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={ 12 } sm={ 12 } align='left' className={ classes.subtitleContainer }>
            <Typography variant='subtitle1' color='textSecondary'>{ subtitle }</Typography>

            <Typography color='primary' gutterBottom>
              GPA 1 will only mark first years as grade risks,
              and GPA 2 will only mark second years and up.
            </Typography>

            { isCurrentRoundUsed && !currentRound
              && (
              <Typography color='textSecondary'>
                Get started with the Results table:
                open a round for the first time to ensure that your results are accurate!
              </Typography>
              )}
          </Grid>
        </Grid>

      </Toolbar>
    );
  }
}

const styles = theme => ({
  highlight:
    theme.palette.type === 'light'
      ? {
        color:           theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color:           theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },

  actions: {
    color: theme.palette.text.secondary,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  tableToolbar: {
    padding: 24,
  },

  title: {
    flex: '0 0 auto',
  },

  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      30,
  },

  titleContainer: {
    display:       'flex',
    flexDirection: 'row',
  },

  subtitleContainer: {
    marginTop: -20,
  },

  logo: {
    width: 105,
  },
});

export default withStyles(styles)(TableToolbar);
