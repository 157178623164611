import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { fetchAllPnms } from '../../../../../../actions/pnmActions';
import { createMatch } from '../../../../../../actions/matchActions';

// helpers
import getPnms from '../../../../../../lib/selectors/getPnms';
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';

// components
import CellOptionsMenu from './CellOptionsMenu';

const mapStateToProps = state => ({
  pnms:             getPnms(state),
  pnmLoading:       getLoadingFromState('pnm')(state),
  matchLoading:     getLoadingFromState('match')(state),
  currentChapterId: state.getIn(['currentChapter', 'data', 'id'], 0),
});

const mapDispatch = {
  fetchAllPnms,
  createMatch,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CellOptionsMenu));
