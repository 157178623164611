import { Map } from 'immutable';

// constants
import { SLIDESHOW_FETCH_PNMS } from '../constants/slideshowTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case SLIDESHOW_FETCH_PNMS: return httpReducer(state, action);
    default: return state;
  }
};
