import PropTypes from 'prop-types';
import Immutable from 'immutable';
import React, { useEffect, useState } from 'react';

// components
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import HandMatchTab from '../HandMatchTab';
import ResultsTab from '../ResultsTab';
import AutoMatchTab from '../AutoMatchTab';
import BulkSpecialtiesTab from '../BulkSpecialtiesTab';

function TabMenu(props) {
  TabMenu.propTypes = {
    classes:  PropTypes.instanceOf(Object).isRequired,
    match:    PropTypes.instanceOf(Object).isRequired,
    pathname: PropTypes.string.isRequired,
  };

  const tabs = Immutable.fromJS([
    {
      label: 'Hand Match',
      value: 'handmatch',
      icon:  'extension',
    },
    {
      label: 'Match Results',
      value: 'results',
      icon:  'list_alt',
    },
    {
      label: 'Assign Specialties',
      value: 'specialties',
      icon:  'local_offer',
    },
    {
      label: 'Auto Match',
      value: 'automatch',
      icon:  'autorenew',
    },
  ]);

  const [currentTab, setCurrentTab] = useState('handmatch');

  const {
    classes,
    match,
    pathname,
  } = props;

  useEffect(() => {
    setCurrentTab('handmatch');
  }, [pathname]);

  const renderTabs = () => {
    const elements = [];

    tabs.forEach((tab) => {
      const label = tab.get('label');
      const key = tab.get('value');
      const icon = tab.get('icon');

      const element = (
        <Tab className={ classes.tab }
          classes={
            {
              wrapper: classes.wrapper,
              root:    classes.root,
            }
          }
          key={ key }
          label={ label }
          value={ key }
          icon={ <Icon>{ icon }</Icon> } />
      );
      elements.push(element);
    });

    return elements;
  };

  return (
    <div>
      <AppBar position='static' color='default' className={ classes.tabsAppBar }>
        <Tabs value={ currentTab }
          onChange={ (event, value) => setCurrentTab(value) }
          indicatorColor='primary'
          textColor='primary'
          centered
          variant='fullWidth'>
          { renderTabs() }
        </Tabs>
      </AppBar>
      { currentTab === 'handmatch'
      && (
      <HandMatchTab match={ match }
        pathname={ pathname } />
      )}
      { currentTab === 'results'
      && <ResultsTab />}
      { currentTab === 'specialties'
      && <BulkSpecialtiesTab />}
      { currentTab === 'automatch'
      && <AutoMatchTab />}
    </div>
  );
}

const styles = () => ({
  tabsAppBar: {
    borderRadius: 10,
  },
  root: {
    maxWidth: 'inherit', // tabs were not going full width
  },
});

export default withStyles(styles)(TabMenu);
