import { ORGANIZATION_UPDATE_INIT, ORGANIZATION_FETCH_INIT } from '../constants/organizationTypes';

export function organizationUpdate(params) {
  return {
    type:    ORGANIZATION_UPDATE_INIT,
    payload: params,
  };
}

export function organizationFetch(params) {
  return {
    type:    ORGANIZATION_FETCH_INIT,
    payload: params,
  };
}
