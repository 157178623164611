import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// components
import AutoSuggestButtons from './AutoSuggestButtons';
import AutoSuggestInput from './AutoSuggestInput';
import AutoSuggestChips from './AutoSuggestChips';

const AutoSuggest = function ({
  allowSelectAll,
  defaultSuggestion,
  defaultSelectedItems,
  classes,
  formValues,
  getAllSuggestions,
  helperText,
  input,
  label,
  loading,
  multiSelect,
  onSearchChange,
  placeholder,
  suggestions,
  variant,
  pristine,
  ...restOfProps
}) {
  const [timerId, setTimerId] = useState(null);
  const initialSelectedItems = List.isList(defaultSelectedItems) ? defaultSelectedItems : List();
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  const { name } = input;

  useEffect(() => {
    if (multiSelect) {
      let selectedItem = Map();

      if (Map.isMap(formValues)) {
        const itemValue = formValues.get(name, {});

        if (typeof itemValue === 'object') {
          selectedItem = Map(itemValue);
        } else {
          selectedItem = Map({
            ...itemValue,
          });
        }
      }

      const alreadySelected = selectedItems.includes(selectedItem);

      if (selectedItem.get('value') && !alreadySelected) {
        setSelectedItems(selectedItems.push(selectedItem));
      }
    }
  }, [formValues, selectedItems, name, input, multiSelect]);

  useEffect(() => {
    if (input.onChange && multiSelect && (!pristine)) {
      input.onChange(selectedItems.toJS());
    }
  }, [selectedItems]); //eslint-disable-line

  const handleUpdateSuggestions = ({ value }) => {
    if (value.length > 1) {
      if (timerId) {
        clearTimeout(timerId);
      }

      setTimerId(setTimeout(onSearchChange, 500, value));
    }
  };

  const handleClearSuggestions = () => {
    onSearchChange(null);
  };

  const handleSelectAll = () => {
    setSelectedItems(getAllSuggestions());
  };

  const handleClearAll = () => {
    input.onChange();

    setSelectedItems(List());
  };

  const handleDeleteChip = (index) => {
    input.onChange({});
    const newSelected = selectedItems.splice(index, 1);

    setSelectedItems(newSelected);
  };

  return (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 12 } className={ classes.autoSuggestContainer }>
        <AutoSuggestInput defaultSuggestion={ defaultSuggestion }
          helperText={ helperText }
          input={ input }
          loading={ loading }
          onSuggestionsClearRequested={ handleClearSuggestions }
          onSuggestionsFetchRequested={ handleUpdateSuggestions }
          placeholder={ placeholder }
          suggestions={ suggestions }
          variant={ variant }
          label={ label }
          { ...restOfProps } />
      </Grid>

      {multiSelect && (
        <Grid item xs={ 12 }>
          <AutoSuggestChips selected={ selectedItems }
            deleteChip={ handleDeleteChip } />
        </Grid>
      )}
      {multiSelect && allowSelectAll && (
        <Grid item xs={ 12 }>
          <AutoSuggestButtons onSelectAll={ handleSelectAll }
            onClearAll={ handleClearAll }
            hasSelectedItems={ selectedItems.size > 0 } />
        </Grid>
      )}
    </Grid>
  );
};

AutoSuggest.propTypes = {
  classes:              PropTypes.instanceOf(Object).isRequired,
  allowSelectAll:       PropTypes.bool,
  defaultSuggestion:    PropTypes.instanceOf(Object),
  defaultSelectedItems: PropTypes.instanceOf(List),
  formValues:           PropTypes.instanceOf(Map).isRequired,
  getAllSuggestions:    PropTypes.func,
  helperText:           PropTypes.string,
  input:                PropTypes.instanceOf(Object),
  label:                PropTypes.string,
  loading:              PropTypes.bool,
  pristine:             PropTypes.bool,
  multiSelect:          PropTypes.bool,
  onSearchChange:       PropTypes.func.isRequired,
  placeholder:          PropTypes.string,
  suggestions:          PropTypes.instanceOf(Array).isRequired,
  variant:              PropTypes.string,
};

AutoSuggest.defaultProps = {
  allowSelectAll:       false,
  defaultSuggestion:    {},
  getAllSuggestions:    () => {},
  helperText:           '',
  input:                {},
  label:                '',
  loading:              false,
  multiSelect:          false,
  placeholder:          '',
  variant:              'outlined',
  pristine:             false,
  defaultSelectedItems: List(),
};

const styles = () => ({
  autoSuggestContainer: {
    alignItems:    'center',
    paddingBottom: 0,
  },
});

export default withStyles(styles)(AutoSuggest);
