import PropTypes from 'prop-types';
import { Map } from 'immutable';
import React, { Component } from 'react';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

// local components
import FileInput from '../../../../../FileInput';

class PhotoForm extends Component {
  static propTypes = {
    classes:     PropTypes.instanceOf(Object).isRequired,
    currentUser: PropTypes.instanceOf(Map).isRequired,
    updateUser:  PropTypes.func.isRequired,
    user:        PropTypes.instanceOf(Map).isRequired,
  };

  getInitials = (currentUser) => {
    const firstname = currentUser.get('firstname') || '';
    const lastname = currentUser.get('lastname') || '';
    let initials = `${firstname.charAt(0)}${lastname.charAt(0)}`;

    if (!firstname) {
      initials = <CircularProgress />;
    }
    return <Typography variant='h2'>{ initials }</Typography>;
  };

  renderErrorMessage = (form) => {
    let element;

    if (form.get('submitFailed')) {
      element = <FormHelperText error>Save failed.</FormHelperText>;
    }
    return element;
  };

  render() {
    const {
      classes, currentUser, updateUser, user,
    } = this.props;

    return (
      <Grid container
        justify='center'
        alignItems='center'>

        <Grid item xs={ 12 }>
          <Avatar className={ classes.avatar }
            src={ currentUser.get('image') }
            alt='Image'>
            { !currentUser.get('image') && this.getInitials(currentUser) }
          </Avatar>
        </Grid>

        <Grid item xs={ 12 }>
          <FileInput buttonLabel='Upload Photo'
            profileData={ currentUser }
            profileState={ user }
            fileType='image'
            update={ updateUser } />
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  avatar: {
    width:  '200px',
    height: '200px',

    [theme.breakpoints.up('sm')]: {
      marginTop: -85,
    },
  },
});

export default withStyles(styles)(PhotoForm);
