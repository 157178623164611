import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create, find, get } from '../../sources/api/client';
import pnmService from '../../sources/api/pnmService';

// constants
import {
  PNM_CREATE_INIT, PNM_CREATE, PNM_FETCH_ALL, PNM_FETCH,
} from '../../constants/pnmTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { pnmSchema, pnmListSchema } from '../../schemas/pnm';

export function* pnmCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    preserveForm, reloadOnSubmit, formName = '', id, ...payload
  } = action.payload;

  const FORM = formName;

  yield put(startSubmit(FORM));

  let values;
  const options = {};

  if (payload.bulkCreate) {
    values = payload.items;
    options.query = { chapter: payload.chapter };
  } else {
    values = payload;
    options.query = { chapter: payload.chapter };
  }

  try {
    yield* httpSaga(PNM_CREATE, call(create, pnmService, values, options), {
      schema: pnmSchema,
    });

    // Fetch pnm again, because reformatting PNM standard fields for redux is rough
    if (reloadOnSubmit) {
      const pnmFetchOptions = {
        query: {
          chapter: payload.chapter,
        },
      };
      yield* httpSaga(PNM_FETCH, call(get, pnmService, id, pnmFetchOptions), {
        schema: pnmSchema,
      });
    }

    yield put(setSubmitSucceeded(FORM));

    if (!preserveForm) {
      yield put(destroy(FORM));
    }

    if (!preserveForm) {
      yield* httpSaga(PNM_FETCH_ALL, call(find, pnmService, {
        query: {
          chapter: payload.chapter,
          $limit:  25,
        },
      }), {
        schema: pnmListSchema,
      });
    }
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(PNM_CREATE, err));
  } finally {
    yield put(doneIndicator(PNM_CREATE));
  }
}

export function* watchPnmCreate() {
  yield takeEvery(PNM_CREATE_INIT, pnmCreate);
}

export default function* root() {
  yield all([
    fork(watchPnmCreate),
  ]);
}
