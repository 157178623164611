import Immutable, { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';

// MUI components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../../../Button';
import SelectInput from '../../../../../SelectInput';

class TagForm extends Component {
  static propTypes = {
    bulkCreateNote:   PropTypes.func.isRequired,
    classes:          PropTypes.instanceOf(Object).isRequired,
    currentChapterId: PropTypes.number.isRequired,
    currentUserId:    PropTypes.number.isRequired,
    formValues:       PropTypes.instanceOf(Map).isRequired,
    pnms:             PropTypes.instanceOf(List).isRequired,
    submitFailed:     PropTypes.bool.isRequired, // eslint-disable-line
    submitSucceeded:  PropTypes.bool.isRequired, // eslint-disable-line
    submitting:       PropTypes.bool.isRequired,
    tagPnmForm:       PropTypes.instanceOf(Map).isRequired,
    tags:             PropTypes.instanceOf(List).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        successMessage: null,
        errorMessage:   null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { submitFailed, submitSucceeded } = nextProps;

    if (submitSucceeded) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', null);
          map.set('successMessage', 'Tags Assigned Successfully');
        }),
      });
    } else if (submitFailed) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('errorMessage', 'Failed to Assign Tags');
          map.set('successMessage', null);
        }),
      });
    }
  }

  getTagOptions = () => {
    const { tags } = this.props;
    const filteredTags = tags.filter(t => t.get('type') === 'chapter');

    const tagOptions = filteredTags.map(t => ({
      value: t.get('_id'),
      label: t.get('title'),
    })).toJS();

    return tagOptions;
  }

  handleSubmit = () => {
    const {
      bulkCreateNote, formValues, pnms, currentChapterId, currentUserId, tagPnmForm,
    } = this.props;

    const items = pnms.map(pnm => ({
      pnm:     pnm.get('id'),
      chapter: currentChapterId,
      author:  currentUserId,
      tags:    [formValues.get('tag')],
    }));

    if (!tagPnmForm.get('syncErrors')) {
      bulkCreateNote({
        items:      items.toJS(),
        bulkCreate: true,
      });
    }
  }

  renderMessage = () => {
    const { classes } = this.props;
    const successMessage = this.state.data.get('successMessage');
    const errorMessage = this.state.data.get('errorMessage');

    let element;

    if (successMessage) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <Typography className={ classes.successLabel }>{ successMessage }</Typography>
        </Grid>
      );
    } else if (errorMessage) {
      element = (
        <Grid item xs={ 12 } align='center'>
          <Typography color='error'>{ errorMessage }</Typography>
        </Grid>
      );
    }

    return element;
  }

  renderField = (options) => {
    let element;

    if (options.length > 0) {
      element = (
        <Field name='tag'
          options={ options }
          component={ SelectInput }
          label='Select Tag'
          fullWidth
          required />
      );
    } else {
      element = <Typography>You have no tags set up</Typography>;
    }

    return element;
  }

  render() {
    const { tagPnmForm, submitting } = this.props;
    const options = this.getTagOptions();

    let disabled = !options.length;
    if (tagPnmForm.get('syncErrors')) { disabled = true; }

    return (
      <Grid item xs={ 12 }>
        <Grid container spacing={ 8 }>
          <Grid item xs={ 12 } align='center'>
            { this.renderField(options) }
          </Grid>

          <Grid item xs={ 12 } align='center'>
            <Button variant='contained'
              color='primary'
              loading={ submitting }
              disabled={ disabled }
              onClick={ this.handleSubmit }>

              Save
            </Button>
          </Grid>

          { this.renderMessage() }
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  successLabel: {
    color: '#00E676',
  },
});

export default withStyles(styles)(TagForm);
