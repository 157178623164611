import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { updateBumpGroup } from '../../../../../../actions/bumpGroupActions';
import { fetchUsersForChapter } from '../../../../../../actions/userActions';

// helpers
import getUserSuggestions from '../../../../../../lib/selectors/getUserSuggestions';
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';
import getChapterAttribute from '../../../../../../lib/selectors/getChapterAttribute';

// components
import GroupMembers from './GroupMembers';

const mapStateToProps = state => ({
  formValues:       getFormValues('groupMembersForm')(state) || Map(),
  currentChapterId: getChapterAttribute('id')(state),
  userSuggestions:  getUserSuggestions(state),
  userLoading:      getLoadingFromState('user')(state),
  bumpGroupLoading: getLoadingFromState('bumpGroup')(state),
});

const mapDispatch = {
  updateBumpGroup,
  fetchUsersForChapter,
};

const handleChange = function (bumpGroups, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const validateForm = function (form) {
  return validate(form.toJS());
};

const wrappedGroupMembers = reduxForm({
  form:               'groupMembersForm',
  validate:           validateForm,
  enableReinitialize: true,
  onChange:           handleChange,
})(GroupMembers);

export default connect(mapStateToProps, mapDispatch)(wrappedGroupMembers);
