const categoryLabelStyles = () => ({
  root: {
    '&.nowrapInline': {
      display:    'inline-block',
      whiteSpace: 'nowrap',
    },
  },
  dot: {
    display:      'inline-block',
    lineHeight:   '1em',
    borderBottom: '1px dotted #000000',
  },
});

export default categoryLabelStyles;
