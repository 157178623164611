import { Map } from 'immutable';

// constants
import { LOCATION_ORG_FETCH_ALL, LOCATION_ORG_CLEAR } from '../constants/locationOrgTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case LOCATION_ORG_FETCH_ALL: return httpReducer(state, action, { entity: 'locationOrg' });
    case LOCATION_ORG_CLEAR: return Map();
    default: return state;
  }
};
