import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import Immutable, { List, Map } from 'immutable';
import 'react-vis/dist/style.css';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ListComponent from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Autosuggest from '../../../../Autosuggest';
import Tag from '../../../../Tag';

const roundVoteCols = Immutable.fromJS([
  {
    id:    'round',
    name:  'Round',
    align: 'right',
  },
  {
    id:   'average',
    name: 'Average Vote Score',
  },
  {
    id:   'count',
    name: 'Total Votes',
  },
]);

const pnmStatusCols = Immutable.fromJS([
  {
    id:    'status',
    name:  'Status',
    align: 'right',
  },
  {
    id:    'count',
    name:  'Count',
    align: 'right',
  },
]);

class CompareChapters extends Component {
  static propTypes = {
    analytics:               PropTypes.instanceOf(Map).isRequired,
    compareChaptersForm:     PropTypes.instanceOf(Map).isRequired,
    classes:                 PropTypes.instanceOf(Object).isRequired,
    handleClearSuggestions:  PropTypes.func.isRequired,
    handleUpdateSuggestions: PropTypes.func.isRequired,
    initAnalytics:           PropTypes.func.isRequired,
    loading:                 PropTypes.bool,
    suggestions:             PropTypes.instanceOf(Array).isRequired,
  };

  static defaultProps = {
    loading: false,
  }

  componentWillReceiveProps(nextProps) {
    const {
      compareChaptersForm,
      initAnalytics,
    } = this.props;

    const nextCompareChaptersForm = nextProps.compareChaptersForm;

    const chapterA = compareChaptersForm.getIn(['values', 'chapterA'], {});
    const nextChapterA = nextCompareChaptersForm.getIn(['values', 'chapterA'], {});

    const chapterB = compareChaptersForm.getIn(['values', 'chapterB'], {});
    const nextChapterB = nextCompareChaptersForm.getIn(['values', 'chapterB'], {});

    if (chapterA.value !== nextChapterA.value || chapterB.value !== nextChapterB.value) {
      initAnalytics(List());
    }
  }

  renderTable = (cols, rows) => {
    const { analytics, classes } = this.props;

    return (
      <Grid item xs={ 12 } key='tableContent'>
        <Fade in={ !analytics.get('loading') }>
          <div className={ classes.paddedChapterItemContainer }>
            <Table>
              <TableHead>
                <TableRow>
                  { cols.map(col => (
                    <TableCell align={ col.get('align') } key={ col.get('id') }>
                      { col.get('name') }
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                { rows.size ? (
                  rows.map(row => (
                    <TableRow key={ row.get('id') }>
                      { row.get('cells').map(cell =>
                        (
                          <TableCell key={ cell }>
                            {cell}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow key='noData'>
                    <TableCell variant='footer' scope='row'>
                      <Typography color='secondary'>No Data Found.</Typography>
                    </TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </div>
        </Fade>
      </Grid>
    );
  }

  renderUserStats = (chapter) => {
    const { analytics, classes } = this.props;
    const totalUsers = chapter.getIn(['users', 'totalUsers'], 0);

    return (
      <Grid item xs={ 12 } key='usersContent'>
        <Fade in={ !analytics.get('loading') }>
          <ListComponent>
            <ListItem>
              <Avatar>
                <Icon>group</Icon>
              </Avatar>

              <ListItemText primary={ totalUsers } secondary='Total Users' />
            </ListItem>

            <ListItem>
              <Grid container>
                <Grid item xs={ 1 } align='center'>
                  <Icon className={ classes.grayIcon }>subdirectory_arrow_right</Icon>
                </Grid>

                <Grid item xs={ 4 }>
                  <div className={ classes.roundedContainer }>
                    <Typography>{`${chapter.getIn(['users', 'totalVotingMembers'], 0)} Voting Members`}</Typography>
                  </div>
                </Grid>

                <Grid item xs={ 4 }>
                  <div className={ classes.roundedContainer }>
                    <Typography>{`${chapter.getIn(['users', 'totalRecruitmentTeam'], 0)} Recruitment Team`}</Typography>
                  </div>
                </Grid>

                <Grid item xs={ 3 }>
                  <div className={ classes.roundedContainer }>
                    <Typography>{`${chapter.getIn(['users', 'totalTechTeam'], 0)} Tech Team`}</Typography>
                  </div>
                </Grid>
              </Grid>
            </ListItem>
          </ListComponent>
        </Fade>
      </Grid>
    );
  }

  renderPnmStats = (chapter) => {
    const { classes } = this.props;

    const pnmStatusData = Immutable.fromJS(chapter.getIn(['pnms', 'statuses'], []));
    const totalLegacies = chapter.getIn(['pnms', 'legacies', 'totalLegacies'], 0);

    return (
      <Grid item xs={ 12 } key='pnmsContent'>
        <ListComponent>
          <ListItem>
            <Avatar>
              <Icon>group</Icon>
            </Avatar>

            <ListItemText primary={ chapter.getIn(['pnms', 'totalPnms'], 0) } secondary='Total PNMs' />
          </ListItem>

          <ListItem>
            <Avatar>
              <Icon>star</Icon>
            </Avatar>

            <ListItemText primary={ totalLegacies } secondary='Legacies' />
          </ListItem>

          <ListItem>
            <Grid container>
              <Grid item xs={ 1 } align='center'>
                <Icon className={ classes.grayIcon }>subdirectory_arrow_right</Icon>
              </Grid>

              <Grid item xs={ 4 }>
                <div className={ classes.roundedContainer }>
                  <Typography>{`${chapter.getIn(['pnms', 'legacies', 'recruitedLegacies'], 0)} Recruited`}</Typography>
                </div>
              </Grid>

              <Grid item xs={ 4 }>
                <div className={ classes.roundedContainer }>
                  <Typography>{`${chapter.getIn(['pnms', 'legacies', 'notRecruitedLegacies'], 0)} Not Recruited`}</Typography>
                </div>
              </Grid>
            </Grid>
          </ListItem>

        </ListComponent>

        { this.renderTable(pnmStatusCols, pnmStatusData) }
      </Grid>
    );
  }

  renderTags = (chapter) => {
    const { analytics, classes } = this.props;
    const tags = chapter.get('tags') || List();

    return (
      <Grid item xs={ 12 } key='tagsContent'>
        <Fade in={ !analytics.get('loading') }>
          <div className={ classes.chapterItemContainer }>
            <Grid container spacing={ 24 }>
              { tags.size ? (
                tags.map(tag =>
                  (
                    <Tag key={ (tag || Map()).get('_id') || 0 }
                      variant='default'
                      displayCount
                      count={ tag.get('count') || 0 }
                      title={ (tag || Map()).get('title') || 'Tag' }
                      color={ (tag || Map()).get('color') || 'red' } />
                  ))
              ) : (
                <Typography color='secondary' className={ classes.chapterItemText }>
                  No tags assigned
                </Typography>
              )}
            </Grid>
          </div>
        </Fade>
      </Grid>
    );
  }

  renderTitle = (title, icon) => {
    const { classes } = this.props;

    return (
      <Grid item xs={ 12 } key={ title }>
        <div className={ classes.statTitleContainer }>
          <Grid container className={ classes.statTitleText }>
            <Grid item xs={ 1 }>
              <Icon color='primary'>{ icon }</Icon>
            </Grid>
            <Grid item xs={ 11 }>
              <Typography variant='subtitle1' color='primary'>{ title }</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }

  renderChapter = (side) => {
    const {
      analytics,
      classes,
      handleClearSuggestions,
      handleUpdateSuggestions,
      loading,
      suggestions,
    } = this.props;

    const chapter = analytics.getIn(['data', side], Map());

    const roundVoteData =      (chapter.get('votes') || List()).map(round =>
      Immutable.fromJS({
        id:    round.get('roundId'),
        cells: [
          round.get('roundName'),
          round.get('averageVoteValue'),
          round.get('voteCount'),
        ],
      }));

    return (
      <Grid item xs={ 12 } sm={ 6 }>
        <div className={ classes.outlinedContainer }>
          <Grid container spacing={ 16 }>
            <Grid item xs={ 12 } className={ classes.chapterItemContainer }>
              <Field name={ `${side}` }
                label='Search Chapters (by name or school)'
                component={ Autosuggest }
                variant='outlined'
                margin='dense'
                suggestions={ suggestions }
                loading={ loading }
                onSuggestionsFetchRequested={ handleUpdateSuggestions }
                onSuggestionsClearRequested={ handleClearSuggestions }
                fullWidth
                required />
            </Grid>

            { analytics.get('loading') ? (
              <Grid item xs={ 12 } align='center' className={ classes.loadingContainer }>
                <CircularProgress />
              </Grid>
            ) : [
              this.renderTitle('User Statistics', 'group'),
              this.renderUserStats(chapter),

              this.renderTitle('PNM Statistics', 'group'),
              this.renderPnmStats(chapter),

              this.renderTitle('Tag Statistics', 'local_offer'),
              this.renderTags(chapter),

              this.renderTitle('Vote Statistics', 'thumbs_up_down'),
              this.renderTable(roundVoteCols, roundVoteData),
            ]}
          </Grid>
        </div>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container justify='center' spacing={ 32 }>
        <Grid item xs={ 12 } className={ classes.titleContainer }>
          <Icon className={ classes.titleIcon } color='primary'>swap_horizontal_circle</Icon>
          <Typography variant='h5' color='primary'>Compare Chapters</Typography>
        </Grid>

        <Grid item xs={ 12 }>
          <Typography variant='subtitle1' color='textSecondary' gutterBottom>
            Analyze a side-by-side rundown for two of your chapters
          </Typography>
        </Grid>

        { this.renderChapter('chapterA') }
        { this.renderChapter('chapterB') }
      </Grid>
    );
  }
}

const styles = () => ({
  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      35,
  },

  titleContainer: {
    marginTop:     20,
    display:       'flex',
    flexDirection: 'row',
  },

  statTitleContainer: {
    borderTop:     '1px solid #bfbfbf',
    paddingTop:    15,
    borderBottom:  '1px solid #bfbfbf',
    paddingBottom: 10,
  },

  statTitleText: {
    marginLeft: 20,
  },

  paddedChapterItemContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    margin:       15,
  },

  outlinedContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
  },

  chapterItemContainer: {
    margin: 20,
  },

  chapterItemText: {
    marginLeft: 15,
  },

  grayIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  roundedContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 100,
    textAlign:    'center',
    width:        '80%',
    padding:      5,
  },

  loadingContainer: {
    marginBottom: 25,
  },
});

export default withStyles(styles)(CompareChapters);
