import { Map } from 'immutable';
import { connect } from 'react-redux';

// action creators
import { voteCreate, voteUpdate } from '../../../../actions/voteActions';
import { createMatch } from '../../../../actions/matchActions';
import { noteCreate } from '../../../../actions/noteActions';

// selectors
import getOrganization from '../../../../lib/selectors/getOrganization';
import getRounds from '../../../../lib/selectors/getRounds';
import getNotes from '../../../../lib/selectors/getNotes';

// components
import SingleBallotModal from './SingleBallotModal';
import getCategories from '../../../../lib/selectors/getCategories';

const mapStateToProps = state => ({
  categories:     getCategories(state),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  rounds:         getRounds(state),
  organization:   getOrganization(state),
  notes:          getNotes(state),
  voteForm:       state.getIn(['form', 'voteForm'], Map()),
  matchForm:      state.getIn(['form', 'matchForm'], Map()),
  noteForm:       state.getIn(['form', 'noteTag'], Map()),
});

const mapDispatchToProps = dispatch => ({
  createVote: (params) => {
    dispatch(voteCreate(params));
  },
  updateVote: (params) => {
    dispatch(voteUpdate(params));
  },
  createMatch(params) {
    dispatch(createMatch(params));
  },
  createNote(params) {
    dispatch(noteCreate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleBallotModal);
