import { connect } from 'react-redux';
import { Map } from 'immutable';

// action creators
import { inviteBulkCreate } from '../../actions/inviteActions';
import { pnmCreate } from '../../actions/pnmActions';
import { voteCreate } from '../../actions/voteActions';
import { roundPnmCreate } from '../../actions/roundPnmActions';
import { hometownGroupPnmCreate } from '../../actions/hometownGroupPnmActions';
import { noteBulkCreate } from '../../actions/noteActions';

// components
import Import from './Import';

const mapStateToProps = state => ({
  category:         state.get('category'),
  currentChapter:   state.get('currentChapter'),
  currentUser:      state.get('currentUser'),
  field:            state.get('field'),
  hometownGroupPnm: state.get('hometownGroupPnm'),
  invite:           state.get('invite'),
  pnm:              state.get('pnm'),
  round:            state.get('round'),
  roundPnm:         state.get('roundPnm'),
  router:           state.get('router'),
  organization:     state.get('organization'),
  roundCategory:    state.get('roundCategory'),
  vote:             state.get('vote'),
  note:             state.get('note'),
  importForm:       state.getIn(['form', 'importForm'], Map()),
});

const mapDispatchToProps = dispatch => ({
  bulkCreateInvites: (params) => {
    dispatch(inviteBulkCreate(params));
  },
  createPnm: (params) => {
    dispatch(pnmCreate(params));
  },
  createVote: (params) => {
    dispatch(voteCreate(params));
  },
  bulkCreateHometownGroupPnms: (params) => {
    dispatch(hometownGroupPnmCreate(params));
  },
  bulkCreateNote: (params) => {
    dispatch(noteBulkCreate(params));
  },
  createRoundPnm: (params) => {
    dispatch(roundPnmCreate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Import);
