import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { Component } from 'react';

// components
import Grid from '@material-ui/core/Grid';
import UserItem from '../UserItem';
import Savebar from '../../../../../Savebar';
import { FieldArray } from 'redux-form/immutable';

class ExistingUsersForm extends Component {
  static propTypes = {
    dirty:          PropTypes.bool.isRequired,
    clearanceLevel: PropTypes.number.isRequired,
    usersForm:      PropTypes.instanceOf(Map).isRequired,
    reset:          PropTypes.func.isRequired,
    teamLevel:      PropTypes.number.isRequired,
    users:          PropTypes.instanceOf(List).isRequired,
    onUserDelete:   PropTypes.func.isRequired,
    onUpdate:       PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        savebarOpen:      false,
        savebarMessage:   'Save your changes?',
        hideButtons:      false,
        autoHideDuration: null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { users: currentUsers } = this.props;
    const {
      dirty, reset, usersForm, users: nextUsers,
    } = nextProps;

    const savebarOpen = this.state.data.get('savebarOpen');
    const usersChanged = !currentUsers.equals(nextUsers);

    if (!savebarOpen) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', dirty);
          map.set('savebarMessage', 'Save your changes?');
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    } else if (usersForm.get('submitSucceeded')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Saved Successfully');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    } else if (usersForm.get('submitFailed')) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save Failed');
          map.set('hideButtons', true);
          map.set('autoHideDuration', 2000);
        }),
      });
    } else if (usersChanged && savebarOpen) {
      reset();
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarMessage', 'Save your changes?');
          map.set('savebarOpen', false);
          map.set('hideButtons', false);
          map.set('autoHideDuration', null);
        }),
      });
    }
  }

  handleCloseSavebar = (event, reason) => {
    if (reason === 'timeout') {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('savebarOpen', false);
        }),
      });
    }
  }

  handleSave = () => {
    const { usersForm, onUpdate } = this.props;

    onUpdate(usersForm.get('values').get('users').toJS());
  }

  handleCancel = () => {
    const { reset } = this.props;

    reset();
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('savebarMessage', 'Changes reset.');
        map.set('autoHideDuration', 2000);
        map.set('hideButtons', true);
      }),
    });
  }

  renderUserList = ({ fields }) => {
    const {
      users, clearanceLevel, teamLevel, onUserDelete,
    } = this.props;

    return fields.map((field, index) => {
      const user = users.get(index) || Map();

      return (
        <Grid item xs={ 12 } key={ user.get('id') }>
          <UserItem index={ index }
            user={ user }
            field={ field }
            clearanceLevel={ clearanceLevel }
            teamLevel={ teamLevel }
            onDelete={ onUserDelete } />
        </Grid>
      );
    });
  }

  render() {
    const { usersForm } = this.props;

    const savebarOpen = this.state.data.get('savebarOpen');
    const savebarMessage = this.state.data.get('savebarMessage');
    const hideButtons = this.state.data.get('hideButtons');
    const autoHideDuration = this.state.data.get('autoHideDuration');

    return (
      <div>
        <Grid container spacing={ 16 }>
          <FieldArray name='users'
            component={ this.renderUserList } />
        </Grid>

        <Savebar open={ savebarOpen }
          autoHideDuration={ autoHideDuration }
          form={ usersForm }
          handleAccept={ this.handleSave }
          handleReject={ this.handleCancel }
          handleClose={ this.handleCloseSavebar }
          hideButtons={ hideButtons }
          message={ savebarMessage } />
      </div>
    );
  }
}

export default ExistingUsersForm;
