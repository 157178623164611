import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { List } from 'immutable';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import pdfService from '../../sources/api/pdfService';

// constants
import { PDF_GENERATE, PDF_GENERATE_INIT } from '../../constants/pdfTypes';

// lib
import httpSaga from '../../lib/httpSaga';

export function* generatePdf(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter,
    pnmIds = [],
    sortBy,
    order,
    displayHometownGroup,
    displayImage,
    displayMatches,
    displayNotes,
    displayStatus,
    displayTags,
    pnmsToDisplay,
    fieldsToDisplay = List(),
    filter = {},
  } = action.payload || {};

  try {
    const options = {
      query: {
        chapter,
        pnmIds,
        sortBy,
        order,
        pnmsToDisplay,
      },
    };

    if (fieldsToDisplay.size) {
      const fieldIds = fieldsToDisplay.map(field => field.get('value')).toJS();
      options.query.fieldIds = fieldIds;
    }

    if (displayHometownGroup) {
      options.query.displayHometownGroup = true;
    }
    if (displayImage) {
      options.query.displayImage = true;
    }
    if (displayMatches) {
      options.query.displayMatches = true;
    }
    if (displayNotes) {
      options.query.displayNotes = true;
    }
    if (displayStatus) {
      options.query.displayStatus = true;
    }
    if (displayTags) {
      options.query.displayTags = true;
    }

    if (filter) {
      options.query.filter = filter;
    }

    yield* httpSaga(PDF_GENERATE, call(find, pdfService, options));
  } catch (err) {
    yield put(error(PDF_GENERATE, err));
  } finally {
    yield put(doneIndicator(PDF_GENERATE));
  }
}

export function* watchGeneratePdf() {
  yield takeLatest(PDF_GENERATE_INIT, generatePdf);
}

export default function* root() {
  yield all([
    fork(watchGeneratePdf),
  ]);
}
