import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { List } from 'immutable';
import { Form } from 'react-final-form';
import pdfMake from 'pdfmake/build/pdfmake.min';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { usePrevious } from '../../../lib/hooks/usePrevious';

// local components
import Button from '../../Button';
import Header from '../../Header';
import PdfSettings from './components/PdfSettings';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const PdfModal = function ({
  classes,
  currentChapterId,
  dialogOpen,
  docDefinition,
  generatePdf,
  fullScreen,
  filter,
  handleDialogClose,
  pdfLoading,
  selectedPnms,
  numSelected,
}) {
  const previousPdfLoading = usePrevious(pdfLoading);

  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  useEffect(() => {
    if (previousPdfLoading === true && pdfLoading === false) {
      pdfMake.createPdf(docDefinition).open();
    }
  }, [previousPdfLoading, pdfLoading]);

  const onSubmit = (values) => {
    const pdfParams = {
      pnmIds:  selectedPnms.map(p => p.get('id')).toJS(),
      chapter: currentChapterId,
      filter,
      ...values,
    };

    // fetch all PNMs
    generatePdf(pdfParams);
  };

  const getInitialValues = () => ({
    sortBy:        'lastName',
    order:         'asc',
    displayStatus: true,
    pnmsToDisplay: 'active',
  });

  return (
    <Dialog open={ dialogOpen }
      onClose={ handleDialogClose }
      fullScreen={ fullScreen }
      maxWidth={ false }>

      <Form id='pdfForm'
        onSubmit={ onSubmit }
        initialValues={ getInitialValues() }
        render={ ({
          handleSubmit, invalid, values,
        }) => (

          <DialogContent classes={ { root: classes.dialogContentRoot } } component='form' onSubmit={ handleSubmit }>
            <Header icon='picture_as_pdf'
              title='Create PDF'
              subtitle='Fine tune the order and data that you need for each PNM in order to generate a PDF'
              highlightedSubtitle={ `${numSelected} PNMs selected` } />

            <PdfSettings handleSubmit={ handleSubmit }
              invalid={ invalid }
              values={ values } />

            <DialogActions className={ classes.dialogActionsRoot }>
              <Button onClick={ handleDialogClose } color='primary'>
                Cancel
              </Button>

              <Button variant='contained'
                type='submit'
                onClick={ handleSubmit }
                loading={ pdfLoading }
                color='primary'>

                Generate
              </Button>
            </DialogActions>
          </DialogContent>
        ) } />

    </Dialog>
  );
};

PdfModal.propTypes = {
  classes:           PropTypes.instanceOf(Object).isRequired,
  docDefinition:     PropTypes.instanceOf(Object).isRequired,
  currentChapterId:  PropTypes.number.isRequired,
  dialogOpen:        PropTypes.bool.isRequired,
  generatePdf:       PropTypes.func.isRequired,
  filter:            PropTypes.instanceOf(Object),
  fullScreen:        PropTypes.bool.isRequired,
  pdfLoading:        PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  numSelected:       PropTypes.number.isRequired,
  selectedPnms:      PropTypes.instanceOf(List).isRequired,
};

PdfModal.defaultProps = {
  filter: {},
};

const styles = theme => ({
  dialogContentRoot: {
    minWidth:  '50vw',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding:   25,

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(
  withStyles(styles)(PdfModal)
);
