import React, { useMemo } from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';

// MUI
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

// local components
import SelectInput from '../../../../../SelectInput';
import TextInput from '../../../../../TextInput';

const FileSettingsItem = function ({
  classes,
  file,
  input,
}) {
  const visibilityOptions = useMemo(() => [
    { value: 0, label: 'All' },
    { value: 1, label: 'Admins' },
    { value: 2, label: 'None' },
  ], []);

  return (
    <Paper className={ classes.itemContainer }>
      <Grid container
        key={ file.label }
        spacing={ 24 }
        className={ classes.itemWrapper }
        justify='space-between'>
        <Grid item xs={ 1 }>
          <Fab color='primary'>
            <Icon>
              description
            </Icon>
          </Fab>
        </Grid>

        <Grid item xs={ 8 } sm={ 7 }>
          <Field name={ `${input.name}.label` }
            label='File Name'
            type='text'
            variant='outlined'
            margin='dense'
            disabled
            component={ TextInput }
            fullWidth
            required />
        </Grid>

        <Grid item xs={ 3 } sm={ 4 }>
          <Field name={ `${input.name}.visibleTo` }
            label='Visible To'
            component={ SelectInput }
            variant='outlined'
            margin='dense'
            options={ visibilityOptions }
            fullWidth
            required />
        </Grid>
      </Grid>
    </Paper>
  );
};
const styles = () => ({
  itemContainer: {
    padding:      15,
    borderRadius: 10,
  },

  itemWrapper: {
    marginTop:    -4,
    marginBottom: -24,
  },
});

FileSettingsItem.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  file:    PropTypes.instanceOf(Object).isRequired,
  input:   PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(FileSettingsItem);
