import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Map } from 'immutable';
import { push } from 'react-router-redux';

// action creators
import { fetchAllPnms, pnmClear } from '../../actions/pnmActions';
import { fetchUsersForChapter } from '../../actions/userActions';
import { fetchBumpGroupsForChapter } from '../../actions/bumpGroupActions';

// helpers
import getPnms from '../../lib/selectors/getPnms';
import getUsers from '../../lib/selectors/getUsers';
import getBumpGroups from '../../lib/selectors/getBumpGroups';
import getLoadingFromState from '../../lib/selectors/getLoadingFromState';
import getClearanceLevel from '../../lib/selectors/getClearanceLevel';
import getChapterAttribute from '../../lib/selectors/getChapterAttribute';
import getOrganization from '../../lib/selectors/getOrganization';

// components
import PnmList from './PnmList';

const mapStateToProps = state => ({
  bumpGroup:             state.get('bumpGroup', Map()),
  bumpGroupLoading:      getLoadingFromState('bumpGroup')(state),
  bumpGroups:            getBumpGroups(state),
  currentChapter:        state.get('currentChapter'),
  currentChapterId:      getChapterAttribute('id')(state),
  currentChapterLoading: getLoadingFromState('currentChapter')(state),
  currentUser:           state.get('currentUser'),
  clearanceLevel:        getClearanceLevel(state),
  organization:          getOrganization(state),
  pnm:                   state.get('pnm'),
  pnmLoading:            getLoadingFromState('pnm')(state),
  pnms:                  getPnms(state) || List(),
  pathname:              (state.get('router').location || {}).pathname || '',
  round:                 state.get('round'),
  user:                  state.get('user'),
  userLoading:           getLoadingFromState('user')(state),
  users:                 getUsers(state) || List(),
});

const mapDispatchToProps = dispatch => ({
  fetchPnms: (params) => {
    dispatch(fetchAllPnms(params));
  },
  clearPnms: () => {
    dispatch(pnmClear());
  },
  fetchUsersForChapter: (params) => {
    dispatch(fetchUsersForChapter(params));
  },
  navigateToListTab: (route) => {
    dispatch(push(`${route}`));
  },
  fetchBumpGroupsForChapter: ({ chapter, shouldPopulateUser }) => {
    dispatch(fetchBumpGroupsForChapter({ chapter, shouldPopulateUser }));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PnmList));
