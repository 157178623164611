import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Helmet from 'react-helmet';
import Immutable from 'immutable';

// components
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class DayPickerRangeInput extends Component {
  static propTypes = {
    classes:            PropTypes.instanceOf(Object).isRequired,
    containerClassName: PropTypes.string,
    errorClassName:     PropTypes.string,
    helpertext:         PropTypes.string,
    input:              PropTypes.instanceOf(Object).isRequired,
    label:              PropTypes.string,
    meta:               PropTypes.instanceOf(Object).isRequired,
    theme:              PropTypes.instanceOf(Object).isRequired,
  }

  static defaultProps = {
    containerClassName: '',
    errorClassName:     '',
    label:              'Select dates',
    helpertext:         '',
  }

  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to:   undefined,
    };
  }

  componentWillMount() {
    const { input } = this.props;

    this.setState({
      from: new Date(input.value.get('from')),
      to:   new Date(input.value.get('to')),
    });
  }

  getWidth = () => {
    const { theme } = this.props;

    theme.breakpoints.down('xs');
  }

  showFromMonth() {
    // const { input: { from, to } } = this.props;
    const { from, to } = this.state;

    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    const { input: { onChange } } = this.props;
    const { to } = this.state;
    // Updates redux form
    onChange(Immutable.fromJS({
      from,
      to: to || '',
    }));
    this.setState({ from });
  }

  handleToChange(to) {
    const { input: { onChange } } = this.props;
    const { from } = this.state;

    // Updates redux form
    onChange(Immutable.fromJS({
      from: from || '',
      to,
    }));
    this.setState({ to }, this.showFromMonth);
  }

  shouldDisplayError = () => {
    const {
      meta: {
        active,
        dirty,
        error,
        touched,
      },
    } = this.props;

    return error && !active && (dirty || touched);
  }

  renderError = () => {
    const { helpertext, errorClassName, meta: { error } } = this.props;

    let element;

    if (this.shouldDisplayError()) {
      element = (
        <FormHelperText className={ errorClassName } error>
          { error[0] }
        </FormHelperText>
      );
    } else {
      element = (
        <FormHelperText>
          { helpertext }
        </FormHelperText>
      );
    }

    return element;
  }

  render() {
    const {
      classes,
      containerClassName,
      label,
    } = this.props;

    // Component state is used in order to correctly update UI of calendar
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const fromPlaceholder = `${formatDate(from)}`.length ? `${formatDate(from)}` : 'From';
    const toPlaceholder = `${formatDate(to)}`.length ? `${formatDate(to)}` : 'To';

    return (
      <FormControl className={ classNames(
        containerClassName,
        classes.formControl,
      ) }>
        <Typography gutterBottom>{ label }</Typography>

        <div className='InputFromTo'>
          <DayPickerInput value={ from }
            placeholder={ fromPlaceholder }
            formatDate={ formatDate }
            parseDate={ parseDate }
            classNames={ { container: classes.outlined, overlay: classes.wrapper } }
            dayPickerProps={ {
              selectedDays:   [from, { from, to }],
              disabledDays:   { after: to },
              toMonth:        to,
              modifiers,
              numberOfMonths: 2,
              onDayClick:     () => this.to.getInput().focus(),
            } }
            onDayChange={ this.handleFromChange } />

          <Divider className={ classes.divider } />

          <span className='InputFromTo-to'>
            <DayPickerInput ref={ el => (this.to = el) /* eslint-disable-line */ }
              value={ to }
              placeholder={ toPlaceholder }
              formatDate={ formatDate }
              parseDate={ parseDate }
              classNames={ { container: classes.outlined, overlay: classes.wrapper } }
              dayPickerProps={ {
                selectedDays:   [from, { from, to }],
                disabledDays:   { before: from },
                modifiers,
                month:          from,
                fromMonth:      from,
                numberOfMonths: 2,
              } }
              onDayChange={ this.handleToChange } />
          </span>
          <Helmet>
            <style>
              {`
              .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #f0f8ff !important;
              color: #4a90e2;
              }
              .InputFromTo .DayPicker-Day {
              border-radius: 0 !important;
              }
              .InputFromTo .DayPicker-Day--start {
              border-top-left-radius: 50% !important;
              border-bottom-left-radius: 50% !important;
              }
              .InputFromTo .DayPicker-Day--end {
              border-top-right-radius: 50% !important;
              border-bottom-right-radius: 50% !important;
              }
              .InputFromTo .DayPickerInput-Overlay {
              width: 550px;
              }
              .InputFromTo-to .DayPickerInput-Overlay {
              margin-left: -198px;
              }
              .InputFromTo .DayPickerInput {
                padding:      12px;
                border:       1px solid #bfbfbf;
                borderRadius: 4px;
              }
              `}
            </style>
          </Helmet>
        </div>

        { this.renderError() }
      </FormControl>
    );
  }
}

const styles = theme => ({
  formControl: {
    width: '100%',
  },

  wrapper: {
    position: 'relative',
  },

  divider: {
    marginTop:    10,
    marginBottom: 10,
  },

  outlined: {
    '& $input': {
      padding:      12,
      border:       '1px solid #bfbfbf',
      borderRadius: 10,
      fontSize:     15,
      transition:   'border-color 0.25s',

      '&:hover': {
        borderColor: theme.palette.common.black,
      },
    },
  },
});

export default withStyles(styles, { withTheme: true })(DayPickerRangeInput);
