import Immutable from 'immutable';
import SagaTester from 'redux-saga-tester';
import getJwt from '../../../support/getJwt';

// actions
import { sessionVerify } from '../../../actions/sessionActions';

// constants
import { SESSION_VERIFY_DONE } from '../../../constants/sessionTypes';

// sagas
import saga from '../sessionVerify';

describe('sessionVerify saga', () => {
  const reducers = function rootReducer(state) {
    return state;
  };

  let initialState;
  let sagaTester;

  afterEach(() => {
    window.localStorage.clear();
  });

  describe('when the user is not authenticated', () => {
    beforeEach(() => {
      initialState = {};

      sagaTester = new SagaTester({ initialState });

      sagaTester.start(saga);
      fetch.mockResponseOnce(JSON.stringify({}));
    });

    it('should redirect the user to "/" and clear the store', async () => {
      sagaTester.dispatch(sessionVerify());

      await sagaTester.waitFor(SESSION_VERIFY_DONE);

      expect(sagaTester.getCalledActions()).toMatchSnapshot();
    });
  });

  describe('when the user is authenticated', () => {
    beforeEach(() => {
      window.localStorage.setItem('feathers-jwt', getJwt({ user: { id: 123 } }));
      fetch.mockResponseOnce(JSON.stringify({ user: { id: 123 } }));
    });

    describe('when the current user is not present', () => {
      beforeEach(() => {
        initialState = Immutable.fromJS({
          currentUser: {},
        });

        sagaTester = new SagaTester({ initialState, reducers });

        sagaTester.start(saga);
      });

      it('should fetch the current user', async () => {
        fetch.mockResponseOnce(JSON.stringify({ id: 123, email: 'gerrit@techniphi.com', chapters: [{ id: 1 }] }));
        fetch.mockResponseOnce(JSON.stringify({ id: 123 }));
        sagaTester.dispatch(sessionVerify());

        await sagaTester.waitFor(SESSION_VERIFY_DONE);

        expect(sagaTester.getCalledActions()).toMatchSnapshot();
      });
    });
  });
});
