import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroyForm,
  startFormSubmit,
  setFormSubmitFailed,
  setFormSubmitSucceeded,
} from '../../actions/formActions';

// api
import { patch } from '../../sources/api/client';
import concernCategoryService from '../../sources/api/concernCategoryService';

// constants
import { CONCERN_CATEGORY_BULK_UPDATE, CONCERN_CATEGORY_BULK_UPDATE_INIT } from '../../constants/concernCategoryTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { concernCategorySchema } from '../../schemas/concernCategory';

export function* concernCategoriesBulkUpdate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'concernCategoriesForm';

  yield put(startFormSubmit(FORM));

  const { items, chapter } = action.payload || {};

  items.forEach((_, index) => {
    items[index].order = index;
  });

  try {
    yield* httpSaga(CONCERN_CATEGORY_BULK_UPDATE, call(patch, concernCategoryService, null, { items }, {
      query: {
        bulkUpdate: true,
        chapter,
      },
    }), { schema: concernCategorySchema });

    yield put(setFormSubmitSucceeded(FORM));
  } catch (err) {
    yield put(setFormSubmitFailed(FORM));
    yield put(error(CONCERN_CATEGORY_BULK_UPDATE, err));
  } finally {
    yield put(doneIndicator(CONCERN_CATEGORY_BULK_UPDATE));
    yield put(destroyForm(FORM));
  }
}

export function* watchConcernCategoryBulkUpdate() {
  yield takeEvery(CONCERN_CATEGORY_BULK_UPDATE_INIT, concernCategoriesBulkUpdate);
}

export default function* root() {
  yield all([
    fork(watchConcernCategoryBulkUpdate),
  ]);
}
