import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '../../../Button';
import FileInput from '../../../FileInput';

class PnmPhotoModal extends Component {
  static propTypes = {
    classes:                PropTypes.instanceOf(Object).isRequired,
    onClose:                PropTypes.func.isRequired,
    bulkUpdatePnms:         PropTypes.func.isRequired,
    open:                   PropTypes.bool.isRequired,
    snackbarSuccessMessage: PropTypes.string, // eslint-disable-line
    pnmPhotoForm:           PropTypes.instanceOf(Map).isRequired, // eslint-disable-line
    pnm:                    PropTypes.instanceOf(Map).isRequired,
    pnmData:                PropTypes.instanceOf(Map),
  }

  static defaultProps = {
    snackbarSuccessMessage: 'Photo Uploaded Successfully',
    pnmData:                Map(),
  }

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        showSnackbar:    false,
        snackbarMessage: 'Unable to upload photo, please try again',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { pnmPhotoForm, open, snackbarSuccessMessage } = nextProps;
    const showSnackbar = this.state.data.get('showSnackbar');

    if (pnmPhotoForm.get('submitFailed') && !showSnackbar) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', true);
          map.set('snackbarMessage', 'Unable to upload photo, please try again');
        }),
      });
    } else if (showSnackbar && pnmPhotoForm.get('submitting')) {
      this.setState({ data: this.state.data.set('showSnackbar', false) });
    } else if (pnmPhotoForm.get('submitSucceeded')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', true);
          map.set('snackbarMessage', snackbarSuccessMessage);
        }),
      });
    } else if (!open && !pnmPhotoForm.get('submitSucceeded')) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('showSnackbar', false);
        }),
      });
    }
  }

  getInitials = (pnmData) => {
    const firstname = pnmData.get('firstname') || '?';
    const lastname = pnmData.get('lastname') || '';
    let initials = `${firstname.charAt(0)}${lastname.charAt(0)}`;

    if (!firstname) {
      initials = <CircularProgress />;
    }
    return <Typography variant='h2'>{ initials }</Typography>;
  };

  handleSubmit = () => {
  }

  render() {
    const {
      bulkUpdatePnms, classes, onClose, open, pnm, pnmData,
    } = this.props;

    const snackbarMessage = this.state.data.get('snackbarMessage');

    return (
      <Dialog open={ open }
        onClose={ onClose }
        classes={ {
          paperWidthSm: classes.paperWidthSm,
          paperWidthMd: classes.paperWidthMd,
          paperWidthLg: classes.paperWidthLg,
          paperWidthXl: classes.paperWidthXl,
        } }>
        <div className={ classes.header }>
          <Typography variant='h3'>Manage PNM Photo</Typography>
        </div>

        <DialogContent className={ classes.dialogContent }>
          <Grid container spacing={ 16 }>

            <Grid item
              xs={ 10 }
              sm={ 12 }
              className={ classes.inputContainer }>
              <Grid item xs={ 12 }>
                <Avatar className={ classes.avatar }
                  src={ pnmData.get('image') }
                  alt='Image'>
                  { !pnmData.get('image') && this.getInitials(pnmData) }
                </Avatar>
              </Grid>
            </Grid>
            <Grid item
              xs={ 10 }
              sm={ 12 }
              className={ classes.inputContainer }>
              <FileInput buttonLabel='Upload Photo'
                profileData={ pnmData }
                profileState={ pnm }
                fileType='image'
                update={ bulkUpdatePnms } />
            </Grid>
          </Grid>

          <DialogActions className={ classes.actionContainer }>
            <Button onClick={ onClose }
              loading={ pnm.get('loading', false) }
              color='primary'>
              Done
            </Button>
          </DialogActions>
        </DialogContent>

        <Snackbar anchorOrigin={ {
          vertical:   'bottom',
          horizontal: 'center',
        } }
          open={ this.state.data.get('showSnackbar') }
          message={ snackbarMessage } />
      </Dialog>
    );
  }
}

const styles = theme => ({
  paperWidthSm: {
    maxWidth: '60%',
  },
  paperWidthMd: {
    maxWidth: '50%',
  },
  paperWidthLg: {
    maxWidth: '40%',
  },
  paperWidthXl: {
    maxWidth: '40%',
  },

  dialogContent: {
    padding: '0 40px 40px',
  },

  divider: {
    marginTop: 20,
  },

  actionContainer: {
    marginTop: 50,
  },

  avatar: {
    width:  '100px',
    height: '100px',
  },

  header: {
    backgroundColor: theme.palette.primary.main,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    padding:         15,
    marginBottom:    10,
  },

  inputContainer: {
    textAlign: 'left',
  },
});

export default withMobileDialog()(withStyles(styles)(PnmPhotoModal));
