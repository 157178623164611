import { connect } from 'react-redux';
import { reduxForm, isDirty } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// action creators
import { chapterUpdate } from '../../../../../../actions/chapterActions';

// components
import ChapterInfoForm from './ChapterInfoForm';

const createInitialValues = (props) => {
  const { chapterData } = props || Map();

  const values = {
    name:                  chapterData.get('name'),
    school:                chapterData.get('school'),
    location:              chapterData.get('location'),
    numberOfVotingMembers: chapterData.get('numberOfVotingMembers'),
    customer_chapter_id:   chapterData.get('customer_chapter_id'),
  };

  return values;
};

const mapStateToProps = (state, props) => ({
  chapterInfoForm: (state.get('form')).get('chapterInfoForm') || Map(),
  dirty:           isDirty('chapter'),
  initialValues:   createInitialValues(props),
});

const mapDispatchToProps = dispatch => ({
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
});

// schemas
const schema = {
  name: {
    presence: { allowEmpty: false },

    length: {
      maximum: 100,
    },
  },
  school: {
    presence: { allowEmpty: false },

    length: {
      maximum: 100,
    },
  },

  location: {
    length: {
      maximum: 100,
    },
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedChapterInfoForm = reduxForm({
  form:               'chapterInfoForm',
  enableReinitialize: true,
  validate:           validateForm,
  onChange:           handleChange,
})(ChapterInfoForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedChapterInfoForm);
