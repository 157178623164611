import feathers, { authentication as auth, rest } from '@feathersjs/client';

export default (function Client() {
  let client;

  if (!client) {
    const baseUrl = process.env.REACT_APP_API_URL;

    client = feathers()
      .configure(rest(baseUrl).fetch(window.fetch.bind(window)))
      .configure(auth({ storage: window.localStorage }));
  }

  return client;
}());

export function find(service, ...params) {
  return service.find(...params);
}

export function get(service, ...params) {
  return service.get(...params);
}

export function create(service, ...params) {
  return service.create(...params);
}

export function update(service, ...params) {
  return service.update(...params);
}

export function patch(service, ...params) {
  return service.patch(...params);
}

export function remove(service, ...params) {
  return service.remove(...params);
}
