import { schema } from 'normalizr';
import { specialtySchema } from './specialty';
import { userSchemaNoChapters } from './user';

export const userSpecialtySchema = new schema.Entity('userSpecialty', {
  specialty: specialtySchema,
  user:      userSchemaNoChapters,
  author:    userSchemaNoChapters,
}, {
  idAttribute: '_id',
});
export const userSpecialtyListSchema = [userSpecialtySchema];
