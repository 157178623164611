import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import matchService from '../../sources/api/matchService';

// constants
import { MATCH_FETCH_ALL, MATCH_FETCH_ALL_INIT } from '../../constants/matchTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { matchListSchema } from '../../schemas/match';

export function* matchFetchAll(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    chapter,
    search,
    infiniteScroll,
    skip,
    sort,
    officialOnly,
    round,
    skipVoteData,
    skipFieldData,
    skipTagData,
    skipHometownData,
    skipPnmPopulate,
    skipPopulateUsers,
  } = action.payload || {};

  const options = {
    query: {
      chapter,
      round,
      $search: search,
      $skip:   skip,
      $sort:   {
        createdAt: -1,
        ...sort,
      },
    },
  };

  if (officialOnly) {
    options.query.officialOnly = true;
  }
  if (skipVoteData) {
    options.query.skipVoteData = true;
  }
  if (skipTagData) {
    options.query.skipTagData = true;
  }
  if (skipHometownData) {
    options.query.skipHometownData = true;
  }
  if (skipFieldData) {
    options.query.skipFieldData = true;
  }
  if (skipPnmPopulate) {
    options.query.skipPnmPopulate = true;
  }
  if (skipPopulateUsers) {
    options.query.skipPopulateUsers = true;
  }

  try {
    yield* httpSaga(MATCH_FETCH_ALL, call(find, matchService, options), {
      schema: matchListSchema,
      infiniteScroll,
    });
  } catch (err) {
    yield put(error(MATCH_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(MATCH_FETCH_ALL));
  }
}

export function* watchMatchFetchAll() {
  yield takeEvery(MATCH_FETCH_ALL_INIT, matchFetchAll);
}

export default function* root() {
  yield all([
    fork(watchMatchFetchAll),
  ]);
}
