import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';

// action creators
import { drawerClose } from '../../../../actions/drawerActions';
import { push } from 'react-router-redux';
import { resultFetchAllForChapter, resultClear } from '../../../../actions/resultActions';

// selectors
import getHometowns from '../../../../lib/selectors/getHometowns';
import getOrganization from '../../../../lib/selectors/getOrganization';
import getTags from '../../../../lib/selectors/getTags';
import getResult from '../../../../lib/selectors/getResult';
import getFields from '../../../../lib/selectors/getFields';

// components
import ResultsTable from './ResultsTable';

const mapStateToProps = state => ({
  category:                  state.get('category'),
  chapter:                   state.get('chapter'),
  currentChapter:            state.get('currentChapter'),
  currentUser:               state.get('currentUser'),
  field:                     state.get('field'),
  filtersForm:               state.getIn(['form', 'filtersForm'], Map()),
  advancedDisplayOptionForm: state.getIn(['form', 'advancedDisplayOptionForm'], Map()),
  hometownGroups:            getHometowns(state),
  organization:              getOrganization(state),
  result:                    getResult({
    addRoundCategoriesResult: true,
    addCustomFieldResult:     true,
  })(state),
  customFields:  getFields(state),
  round:         state.get('round'),
  roundCategory: state.get('roundCategory'),
  roundPnm:      state.get('roundPnm'),
  chapterTags:   getTags(state),
  user:          state.get('user'),
});

const mapDispatchToProps = dispatch => ({
  fetchResultsForChapter: (params) => {
    dispatch(resultFetchAllForChapter(params));
  },

  clearResults: () => {
    dispatch(resultClear());
  },

  navigateToPNM: (id) => {
    dispatch(drawerClose());
    dispatch(push(`/pnm/${id}`));
  },
  navigate: (route) => {
    dispatch(push(`${route}`));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsTable));
