import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create, get } from '../../sources/api/client';
import hometownGroupService from '../../sources/api/hometownGroupService';
import chapterService from '../../sources/api/chapterService';

// constants
import { HOMETOWN_GROUP_CREATE, HOMETOWN_GROUP_CREATE_INIT } from '../../constants/hometownGroupTypes';
import { CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { hometownGroupSchema } from '../../schemas/hometownGroup';
import { chapterSchema } from '../../schemas/chapter';

export function* hometownGroupCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { chapter } = action.payload;
  const values = action.payload || {};

  try {
    yield* httpSaga(HOMETOWN_GROUP_CREATE, call(create, hometownGroupService, values), {
      schema: hometownGroupSchema,
    });

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapter), {
      schema: chapterSchema,
    });
  } catch (err) {
    yield put(error(HOMETOWN_GROUP_CREATE, err));
  } finally {
    yield put(doneIndicator(HOMETOWN_GROUP_CREATE));
  }
}

export function* watchHometownGroupCreate() {
  yield takeEvery(HOMETOWN_GROUP_CREATE_INIT, hometownGroupCreate);
}

export default function* root() {
  yield all([
    fork(watchHometownGroupCreate),
  ]);
}
