export const USER_SPECIALTY_FETCH_INIT = 'USER_SPECIALTY_FETCH_INIT';
export const USER_SPECIALTY_FETCH = 'USER_SPECIALTY_FETCH';

export const USER_SPECIALTY_BULK_FETCH_INIT = 'USER_SPECIALTY_BULK_FETCH_INIT';
export const USER_SPECIALTY_BULK_FETCH = 'USER_SPECIALTY_BULK_FETCH';

export const USER_SPECIALTY_CREATE_INIT = 'USER_SPECIALTY_CREATE_INIT';
export const USER_SPECIALTY_CREATE = 'USER_SPECIALTY_CREATE';

export const USER_SPECIALTY_CLEAR = 'USER_SPECIALTY_CLEAR';

export const USER_SPECIALTY_REMOVE_INIT = 'USER_SPECIALTY_REMOVE_INIT';
export const USER_SPECIALTY_REMOVE = 'USER_SPECIALTY_REMOVE';
