import { connect } from 'react-redux';

import { concernCategoryCreate, concernCategoryRemove } from '../../../../actions/concernCategoryActions';

// components
import ConcernCategories from './ConcernCategories';

// selectors
import { isSubmitting } from '../../../../lib/selectors/formSelectors';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getStatusFromState from '../../../../lib/selectors/getStatusFromState';
import getOrganization from '../../../../lib/selectors/getOrganization';

const mapStateToProps = state => ({
  organization:                    getOrganization(state),
  concernCategoryLoading:          getLoadingFromState('concernCategory')(state),
  concernCategoryStatus:           getStatusFromState('concernCategory')(state),
  concernCategoriesFormSubmitting: isSubmitting('concernCategoriesForm')(state),
});

const mapDispatchToProps = dispatch => ({
  createConcernCategory: (params) => {
    dispatch(concernCategoryCreate(params));
  },
  removeConcernCategory: (id) => {
    dispatch(concernCategoryRemove(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConcernCategories);
