import { Map } from 'immutable';

// constants
import {
  ANALYTICS_FETCH,
  ANALYTICS_FETCH_FOR_CHAPTER,
  ANALYTICS_FETCH_OVERVIEW_DATA,
} from '../constants/analyticsTypes';

// lib
import httpReducer from '../lib/httpReducer';

export default (state = Map(), action) => {
  switch (action.type) {
    case ANALYTICS_FETCH: return httpReducer(state, action);
    case ANALYTICS_FETCH_FOR_CHAPTER: return httpReducer(state, action);
    case ANALYTICS_FETCH_OVERVIEW_DATA: return httpReducer(state, action);
    default: return state;
  }
};
