import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import matchService from '../../sources/api/matchService';

// constants
import { MATCH_FETCH_FOR_PNM, MATCH_FETCH_FOR_PNM_INIT } from '../../constants/matchTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { matchListSchema } from '../../schemas/match';

export function* matchFetchForPnm(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    pnmId, chapterId, skipPnmPopulate,
  } = action.payload || {};

  const options = {
    query: {
      pnm:     pnmId,
      chapter: chapterId,

      $sort: {
        createdAt: -1,
      },
    },
  };

  if (skipPnmPopulate) {
    options.query.skipPnmPopulate = true;
  }

  try {
    yield* httpSaga(MATCH_FETCH_FOR_PNM, call(find, matchService, options), {
      schema: matchListSchema,
    });
  } catch (err) {
    yield put(error(MATCH_FETCH_FOR_PNM, err));
  } finally {
    yield put(doneIndicator(MATCH_FETCH_FOR_PNM));
  }
}

export function* watchMatchFetchForPnm() {
  yield takeEvery(MATCH_FETCH_FOR_PNM_INIT, matchFetchForPnm);
}

export default function* root() {
  yield all([
    fork(watchMatchFetchForPnm),
  ]);
}
