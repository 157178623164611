import SagaTester from 'redux-saga-tester';

// actions
import * as actions from '../../../actions/sessionActions';

// constants
import { SESSION_CREATE_DONE } from '../../../constants/sessionTypes';

// sagas
import saga from '../sessionCreate';

describe('sessionCreate saga', () => {
  let sagaTester;
  let action;

  beforeEach(() => {
    sagaTester = new SagaTester({ initialState: {} });

    sagaTester.start(saga);

    action = actions.sessionCreate({ email: 'email', password: 'password' });
  });

  describe('when authentication is successful', () => {
    it('should create a new session, fetch the current user, and redirect to "/"', async () => {
      fetch.mockResponseOnce(JSON.stringify({ user: { id: 123 } }));
      fetch.mockResponseOnce(JSON.stringify({ id: 123, email: 'gerrit@techniphi.com', chapters: [{ id: 1 }] }));

      sagaTester.dispatch(action);

      await sagaTester.waitFor(SESSION_CREATE_DONE);

      expect(sagaTester.getCalledActions()).toMatchSnapshot();
    });
  });

  describe('when authentication fails', () => {
    it('should do nothing except dispatch error actions', async () => {
      fetch.mockRejectOnce(new Error('invalid login'));

      sagaTester.dispatch(action);

      await sagaTester.waitFor(SESSION_CREATE_DONE);

      expect(sagaTester.getCalledActions()).toMatchSnapshot();
    });
  });
});
