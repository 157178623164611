import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import matchService from '../../sources/api/matchService';

// constants
import { MATCH_CREATE, MATCH_CREATE_INIT } from '../../constants/matchTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { matchSchema } from '../../schemas/match';

export function* matchCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const {
    formName, skipPnmPopulate, ...values
  } = action.payload || {};
  const FORM = formName;

  const options = {
    query: {
      chapter: values.chapter,
    },
  };

  if (skipPnmPopulate) {
    options.query.skipPnmPopulate = true;
  }

  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(MATCH_CREATE, call(create, matchService, values, options), {
      schema: matchSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(MATCH_CREATE, err));
  } finally {
    yield put(doneIndicator(MATCH_CREATE));
  }
}

export function* watchMatchCreate() {
  yield takeEvery(MATCH_CREATE_INIT, matchCreate);
}

export default function* root() {
  yield all([
    fork(watchMatchCreate),
  ]);
}
