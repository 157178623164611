import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import matchService from '../../sources/api/matchService';

// constants
import { MATCH_FETCH_FOR_USER, MATCH_FETCH_FOR_USER_INIT } from '../../constants/matchTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { matchListSchema } from '../../schemas/match';

export function* matchFetchForUser(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { userId, round, chapterId } = action.payload || {};

  const options = {
    query: {
      user:    userId,
      round,
      chapter: chapterId,

      $sort: {
        createdAt: -1,
      },
    },
  };

  try {
    yield* httpSaga(MATCH_FETCH_FOR_USER, call(find, matchService, options), {
      schema: matchListSchema,
    });
  } catch (err) {
    yield put(error(MATCH_FETCH_FOR_USER, err));
  } finally {
    yield put(doneIndicator(MATCH_FETCH_FOR_USER));
  }
}

export function* watchMatchFetchForUser() {
  yield takeEvery(MATCH_FETCH_FOR_USER_INIT, matchFetchForUser);
}

export default function* root() {
  yield all([
    fork(watchMatchFetchForUser),
  ]);
}
