import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { create } from '../../sources/api/client';
import fieldService from '../../sources/api/fieldService';

// constants
import { FIELD_CREATE, FIELD_CREATE_INIT } from '../../constants/fieldTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { fieldSchema } from '../../schemas/field';

export function* fieldCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const values = action.payload || {};

  try {
    yield* httpSaga(FIELD_CREATE, call(create, fieldService, values), {
      schema: fieldSchema,
    });
  } catch (err) {
    yield put(error(FIELD_CREATE, err));
  } finally {
    yield put(doneIndicator(FIELD_CREATE));
  }
}

export function* watchInviteCreate() {
  yield takeEvery(FIELD_CREATE_INIT, fieldCreate);
}

export default function* root() {
  yield all([
    fork(watchInviteCreate),
  ]);
}
