import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { startSubmit, setSubmitFailed, setSubmitSucceeded } from 'redux-form/immutable';
import { push } from 'react-router-redux';

// api
import { create, get } from '../../sources/api/client';
import chapterService from '../../sources/api/chapterService';

// constants
import { CHAPTER_CREATE, CHAPTER_CREATE_INIT, CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { chapterSchema } from '../../schemas/chapter';

const FORM = 'addChapterForm';

export function* chapterCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { locationOrg, locationSchool } = action.payload || {};

  yield put(startSubmit(FORM));

  try {
    const { data } = yield* httpSaga(CHAPTER_CREATE, call(create, chapterService, { locationOrg, locationSchool }), {
      schema: chapterSchema,
    });

    yield window.localStorage.setItem('chapter', data.id);

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, data.id), {
      schema: chapterSchema,
    });

    yield put(setSubmitSucceeded(FORM));

    yield put(push('/'));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(CHAPTER_CREATE, err));
  } finally {
    yield put(doneIndicator(CHAPTER_CREATE));
  }
}

export function* watchChapterUpdate() {
  yield takeEvery(CHAPTER_CREATE_INIT, chapterCreate);
}

export default function* root() {
  yield all([
    fork(watchChapterUpdate),
  ]);
}
