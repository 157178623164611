import React from 'react';
import PropTypes from 'prop-types';

// components
import Button from './Button';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

function Banner({
  classes, title, subtitle, buttonIcon, buttonLabel, onButtonClick,
}) {
  return (
    <Grid item xs={ 12 } md={ 12 } lg={ 10 } xl={ 8 }>
      <Card className={ classes.card }>
        <CardContent>
          <Grid container spacing={ 16 }>
            <Grid item xs={ 12 }>
              <Typography color='textSecondary' variant='subtitle1'>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography>
                {subtitle}
              </Typography>
            </Grid>
            {Boolean(buttonLabel)
          && (
          <Grid item xs={ 4 }>
            <Button color='primary' variant='contained' fullWidth onClick={ onButtonClick }>
              { Boolean(buttonIcon) && <Icon>{buttonIcon}</Icon> }
              <span className={ buttonIcon ? classes.buttonLabel : {} }>{buttonLabel}</span>
            </Button>
          </Grid>
          )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

Banner.propTypes = {
  classes:       PropTypes.instanceOf(Object).isRequired,
  title:         PropTypes.string.isRequired,
  subtitle:      PropTypes.string.isRequired,
  buttonIcon:    PropTypes.string,
  buttonLabel:   PropTypes.string,
  onButtonClick: PropTypes.func,
};

Banner.defaultProps = {
  buttonIcon:    '',
  buttonLabel:   '',
  onButtonClick: () => {},
};

const styles = () => ({
  card: {
    borderRadius: 10,
  },
  buttonLabel: {
    marginLeft: '1em',
  },
});

export default withStyles(styles)(Banner);
