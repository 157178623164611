import { LOCATION_ORG_FETCH_ALL_INIT, LOCATION_ORG_CLEAR } from '../constants/locationOrgTypes';

export function locationOrgFetchAll(params) {
  return {
    type:    LOCATION_ORG_FETCH_ALL_INIT,
    payload: params,
  };
}

export function locationOrgClear() {
  return {
    type: LOCATION_ORG_CLEAR,
  };
}
