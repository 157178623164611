import { List, Map } from 'immutable';

import { toFloat } from '../../helpers/transform';

const getResult = ({
  addRoundCategoriesResult = false,
  addCustomFieldResult = false,
} = {}) => (state) => {
  let result =  state.get('result');

  if (addRoundCategoriesResult && Map.isMap(result)) {
    result = result.set('data', result.get('data', List()).map((itemData) => {
      const roundCategories = itemData.get('roundCategories', Map()).toList();
      let roundCategoriesResult = Map();

      roundCategories.forEach((roundCategory) => {
        const round = roundCategory.get('round', '');
        const parentValue = roundCategory.get('value');
        const subCategories = roundCategory.get('categories', List());
        if (List.isList(subCategories) && subCategories.size > 0) {
          subCategories.forEach((subCategory) => {
            const categoryId = subCategory.get('category', '');
            const value = toFloat(subCategory.get('value', 0));
            const roundCategoryResult = roundCategoriesResult.get(categoryId);
            if (roundCategoryResult && Map.isMap(roundCategoryResult)) {
              const newValue = roundCategoryResult.get('value', 0) + value;
              const newVoteCount = roundCategoryResult.get('voteCount', 0) + 1;
              roundCategoriesResult = roundCategoriesResult.set(
                categoryId,
                roundCategoryResult
                  .set('value', newValue)
                  .set('voteCount', newVoteCount)
              );
            } else {
              roundCategoriesResult = roundCategoriesResult.set(categoryId, Map({
                categoryId,
                round,
                parentValue,
                value,
                voteCount: 1,
              }));
            }
          });
        }
      });

      return itemData.set('roundCategoriesResult', roundCategoriesResult);
    }));
  }

  if (addCustomFieldResult && Map.isMap(result)) {
    result = result.set('data', result.get('data', List()).map((itemData) => {
      const standardFields = itemData.get('standardFields', Map()).toList();
      let customFieldResult = Map();

      standardFields.forEach((item) => {
        const standardField = item.get('standardField', Map());
        const standardFieldId = Map.isMap(standardField) ? standardField.get('id') : false;
        if (standardFieldId) {
          customFieldResult = customFieldResult.set(standardFieldId, item);
        }
      });

      return itemData.set('customFieldResult', customFieldResult);
    }));
  }

  return result;
};

export default getResult;
