import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { List, Map } from 'immutable';

// MUI components
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

function EnhancedTableRow(props) {
  EnhancedTableRow.propTypes = {
    classes:             PropTypes.instanceOf(Object).isRequired,
    createUserSpecialty: PropTypes.func.isRequired,
    currentUserId:       PropTypes.number.isRequired,
    numCols:             PropTypes.number.isRequired,
    removeUserSpecialty: PropTypes.func.isRequired,
    specialties:         PropTypes.instanceOf(List).isRequired,
    user:                PropTypes.instanceOf(Map).isRequired,
    userSpecialties:     PropTypes.instanceOf(List).isRequired,
  };

  const {
    classes,
    createUserSpecialty,
    currentUserId,
    numCols,
    removeUserSpecialty,
    specialties,
    user,
    userSpecialties,
  } = props;

  const [cellContent, setCellContent] = useState('');
  const [showAddButtons, setShowAddButtons] = useState(false);

  useEffect(() => {
    const element = (
      <IconButton className={ classes.iconPlaceholder }>
        <Icon>add</Icon>
      </IconButton>
    );

    setCellContent(element);
  }, []);

  const handleDelete = (itemToDelete) => {
    removeUserSpecialty({
      user: user.get('id'),
      id:   itemToDelete.get('_id'),
    });
  };

  const handleAddUserSpecialty = (specialtyId) => {
    const values = {
      user:      user.get('id'),
      specialty: specialtyId,
      author:    currentUserId,
    };

    createUserSpecialty({
      values,
    });
  };

  const handleMouseOver = (specialtyId) => {
    const element = (
      <Fade in={ showAddButtons }>
        <IconButton onClick={ () => handleAddUserSpecialty(specialtyId) }>
          <Icon>add</Icon>
        </IconButton>
      </Fade>
    );

    setCellContent(element);
    setShowAddButtons(true);
  };

  const handleMouseLeave = () => {
    const element = (
      <Fade in={ !showAddButtons }>
        <div className={ classes.iconPlaceholder }>
          <IconButton>
            <Icon>add</Icon>
          </IconButton>
        </div>
      </Fade>
    );

    setCellContent(element);
    setShowAddButtons(false);
  };

  const renderSpecialtyCells = () => specialties.map((s) => {
    const userSpecialtyItem = userSpecialties.find(us =>
      us.getIn(['specialty', '_id']) === s.get('_id'));

    return (
      <TableCell padding='dense'
        key={ s.get('_id') }
        onMouseOver={ () => handleMouseOver(s.get('_id')) }
        onFocus={ () => handleMouseOver(s.get('_id')) }
        onMouseLeave={ handleMouseLeave }>
        { userSpecialtyItem
          ? (
            <Fade in>
              <Chip label={ s.get('title') }
                onDelete={ () => handleDelete(userSpecialtyItem) }
                className={ classes.chip } />
            </Fade>
          )
          : (
            <div>
              { cellContent }
            </div>
          )}

      </TableCell>
    );
  });

  return (
    <TableRow colSpan={ numCols }
      className={ classes.row }>

      <TableCell padding='dense'>
        <Typography>{ user.get('firstname') }</Typography>
      </TableCell>
      <TableCell padding='dense'>
        <Typography>{ user.get('lastname') }</Typography>
      </TableCell>

      { renderSpecialtyCells() }

    </TableRow>
  );
}

const styles = () => ({
  row: {
    cursor: 'pointer',
  },

  iconPlaceholder: {
    opacity: 0,
  },
});

export default withStyles(styles)(EnhancedTableRow);
