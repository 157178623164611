import { Map } from 'immutable';
import { connect } from 'react-redux';

// action creators
import { noteCreate } from '../../actions/noteActions';

import getTags from '../../lib/selectors/getTags';

// components
import NoteTagModal from './NoteTagModal';

const mapStateToProps = state => ({
  currentUser:  state.get('currentUser'),
  noteTagForm:  state.getIn(['form', 'noteTag'], Map()),
  organization: state.get('organization'),
  tags:         getTags(state),
});

const mapDispatchToProps = dispatch => ({
  createNote(params) {
    dispatch(noteCreate(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteTagModal);
