import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map, List } from 'immutable';
import { denormalize } from 'normalizr';
import { Field } from 'redux-form/immutable';

// local components
import Autosuggest from '../Autosuggest';
import TextInput from '../TextInput';

// schemas
import { userListSchema } from '../../schemas/user';

// components
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '../Button';

class MatchModal extends Component {
  static propTypes = {
    createMatch:       PropTypes.func.isRequired,
    chapter:           PropTypes.instanceOf(Map).isRequired,
    classes:           PropTypes.instanceOf(Object).isRequired,
    currentChapterId:  PropTypes.number.isRequired,
    dialogOpen:        PropTypes.bool.isRequired,
    fetchUsers:        PropTypes.func.isRequired,
    fullScreen:        PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    matchForm:         PropTypes.instanceOf(Map).isRequired,
    pnmData:           PropTypes.instanceOf(Map).isRequired,
    user:              PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        timerId: null,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { matchForm, handleDialogClose } = nextProps;

    if (matchForm.get('submitSucceeded')) {
      handleDialogClose();
    }
  }

  getUsers = () => {
    const { chapter, user } = this.props;

    let items = List();

    const userData = user.get('data') || Map();

    const result = List.isList(userData.get('result'))
      ? (userData.get('result') || List()).toJS()
      : [userData.get('result')] || [];

    if (userData.get('result')) {
      const users = (user.get('data') || Map()).get('items') || List();
      const chapters = (chapter.get('data') || Map()).get('items') || List();

      const entities = {
        user:    users.toJS(),
        chapter: chapters.toJS(),
      };

      items = denormalize(result, userListSchema, entities);
    }
    return Immutable.fromJS(items);
  }

  getSuggestions = (items) => {
    const suggestions = [];
    items.forEach((item = {}) => {
      const suggestion = {
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      };

      suggestions.push(suggestion);
    });
    return suggestions;
  };

  handleSearchChange = (value) => {
    const { currentChapterId, fetchUsers } = this.props;

    fetchUsers({ search: value, chapter: currentChapterId });
  }

  handleUpdateSuggestions = ({ value }) => {
    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(this.handleSearchChange, 500, value)),
    });
  }

  handleClearSuggestions = () => { }

  handleSubmit = () => {
    const {
      createMatch, currentChapterId, matchForm, pnmData,
    } = this.props;

    if (!matchForm.get('syncErrors')) {
      const { user, ...values } = (matchForm.get('values') || Map()).toJS();

      const params = {
        skipPnmPopulate: true,
        formName:        'matchForm',
        chapter:         currentChapterId,
        pnm:             pnmData.get('id'),
        user:            (user || {}).value || '',
        ...values,
      };

      createMatch(params);
    }
  }

  renderErrorMessage = (matchForm) => {
    let element;

    if (matchForm.get('submitFailed')) {
      element = <FormHelperText error>Failed to match, try again.</FormHelperText>;
    }

    return element;
  };

  render() {
    const {
      classes, matchForm, fullScreen,
      handleDialogClose, dialogOpen, user,
    } = this.props;

    const users = this.getUsers() || List();
    let disableButton = false;
    if (matchForm.get('syncErrors')) { disableButton = true; }

    return (
      <Dialog open={ dialogOpen }
        onClose={ handleDialogClose }
        fullScreen={ fullScreen }
        maxWidth={ false }>

        <DialogContent classes={ { root: classes.dialogContentRoot } }>
          <Grid container spacing={ 24 } justify='center' className={ classes.matchHeaderContainer }>
            <Grid item sm={ 12 } xs={ 11 }>
              <Typography variant='h6' align='center' className={ classes.matchTitle } gutterBottom>
                Who Should Meet This PNM?
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={ 24 } justify='center' className={ classes.matchSearchContainer }>
            <Grid item sm={ 7 } xs={ 11 } align='center'>
              <Field name='user'
                label='Search for users'
                component={ Autosuggest }
                loading={ user.get('loading') }
                suggestions={ this.getSuggestions(users.toJS()) }
                onSuggestionsFetchRequested={ this.handleUpdateSuggestions }
                onSuggestionsClearRequested={ this.handleClearSuggestions } />
            </Grid>
          </Grid>

          <Grid container spacing={ 24 } justify='center'>
            <Grid item md={ 10 } xs={ 12 }>
              <Field name='comment'
                key='multiline-flexible'
                label='Share more about this match recommendation'
                multiline
                variant='outlined'
                type='text'
                component={ TextInput }
                fullWidth />
            </Grid>
          </Grid>

          <DialogActions className={ classes.dialogActionsRoot }>
            { this.renderErrorMessage(matchForm) }

            <Button onClick={ handleDialogClose }
              disabled={ matchForm.get('submitting') }
              color='primary'>
              Cancel
            </Button>

            <Button variant='contained'
              onClick={ () => this.handleSubmit(users) }
              loading={ matchForm.get('submitting') }
              disabled={ disableButton }
              color='primary'>

              <Icon className={ classes.leftIcon } color='inherit'>extension</Icon>
              Submit

            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContentRoot: {
    minWidth:        '50vw',
    overflowX:       'hidden',
    overflowY:       'auto',
    padding:         0,
    '&:first-child': {
      paddingTop: 0,
    },

    [theme.breakpoints.down('md')]: { minWidth: '90vw' },
  },

  matchHeaderContainer: {
    paddingTop:      20,
    backgroundColor: theme.palette.primary.main,
  },

  matchSearchContainer: {
    paddingTop:   20,
    marginBottom: 20,
  },

  matchTitle: {
    color: theme.palette.common.white,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  dialogActionsRoot: {
    paddingTop:    20,
    paddingRight:  20,
    paddingBottom: 20,
  },
});

export default withMobileDialog()(
  withStyles(styles)(MatchModal)
);
