import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
// import { push } from 'react-router-redux';
import { currentChapterFetchForAdmin, chapterUpdate, chapterFetchAll } from '../../../../../actions/chapterActions';
import { pnmDelete } from '../../../../../actions/pnmActions';
import { voteDelete } from '../../../../../actions/voteActions';
import { noteDelete } from '../../../../../actions/noteActions';
import { deleteMatch } from '../../../../../actions/matchActions';

// components
import ChaptersTable from './ChaptersTable';

const mapStateToProps = state => ({
  chapter:     state.get('chapter'),
  currentUser: state.get('currentUser'),
  formState:   state.get('form'),
  match:       state.get('match'),
  note:        state.get('note'),
  pnm:         state.get('pnm'),
  vote:        state.get('vote'),
});

const mapDispatchToProps = dispatch => ({
  fetchCurrentChapterForAdmin: ({ chapterId, userId }) => {
    dispatch(currentChapterFetchForAdmin({ chapterId, userId }));
  },
  fetchChapters: (params) => {
    dispatch(chapterFetchAll(params));
  },
  deletePnms: (params) => {
    dispatch(pnmDelete(params));
  },
  deleteMatch: (params) => {
    dispatch(deleteMatch(params));
  },
  deleteNotes: (params) => {
    dispatch(noteDelete(params));
  },
  deleteVotes: (params) => {
    dispatch(voteDelete(params));
  },
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChaptersTable));
