import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { List, Map } from 'immutable';

// components
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import CardContent from '@material-ui/core/CardContent';

// styles
import './Comment.scss';

const Comment = function ({
  classes, showPNM, text, handleOpenModal,
  authorName, pnmName, pnmId, item, pnmPhoto, updatedAt, navigate, type, rounds,
}) {
  Comment.propTypes = {
    classes:         PropTypes.instanceOf(Object).isRequired,
    text:            PropTypes.string.isRequired,
    showPNM:         PropTypes.bool,
    authorName:      PropTypes.string.isRequired,
    rounds:          PropTypes.instanceOf(List).isRequired,
    item:            PropTypes.instanceOf(Map).isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    updatedAt:       PropTypes.string.isRequired,
    pnmName:         PropTypes.string.isRequired,
    pnmId:           PropTypes.string.isRequired,
    pnmPhoto:        PropTypes.string.isRequired,
    navigate:        PropTypes.func.isRequired,
    type:            PropTypes.oneOf(['votes', 'notes', 'matches', 'tags']).isRequired,
  };

  Comment.defaultProps = {
    showPNM: false,
  };

  const renderAvatar = () => {
    const itemHasPnm = Boolean(parseInt(pnmId, 10));
    const firstInitial = `${pnmName.charAt(0)}`;
    const url = `pnm/${pnmId}`;

    let avatar = (
      <Avatar className={ classes.initialsAvatar }
        onClick={ () => navigate(url) }>
        <div className={ classes.initialsLabel }>{ firstInitial }</div>
      </Avatar>
    );

    if (!itemHasPnm) { // then this is an anonymous pnm, display an add button
      avatar = (
        <IconButton className={ classes.anonAvatar } onClick={ handleOpenModal }>
          <Icon className={ classes.addButton }>add</Icon>
        </IconButton>
      );
    }

    if (pnmPhoto) {
      avatar = <Avatar className={ classes.photoAvatar } src={ pnmPhoto } onClick={ () => navigate(`pnm/${pnmId}`) } />;
    }

    return avatar;
  };

  let roundName = 'Round name';
  const voteHasRound = type === 'votes' && rounds.size && item.get('round');
  const noteHasRound = type === 'notes' && rounds.size && item.get('round');
  const matchHasRound = type === 'matches' && rounds.size && item.get('round');

  if (voteHasRound || noteHasRound || matchHasRound) {
    const roundForItem = (item || Map()).get('round') || Map();
    const roundForItemId = roundForItem.length ? roundForItem : roundForItem.get('_id') || 0;

    roundName = (rounds.find(r => (r || Map()).get('_id') === roundForItemId) || Map()).get('name');
  }

  let matchedUserName = `${item.getIn(['user', 'firstname'], '')} ${item.getIn(['user', 'lastname'], '')}`;
  if (!matchedUserName.length) {
    matchedUserName = item.getIn(['user', 'email']);
  }

  const submittedByAdmin = Map.isMap(item.get('submittedByAdmin'))
    ? item.get('submittedByAdmin')
    : Map();
  const adminName = `${submittedByAdmin.get('firstname') || ''} ${submittedByAdmin.get('lastname') || ''}`;

  let sectionContainerClass;
  let onContainerClickProps = {};

  if (type === 'votes') {
    sectionContainerClass = classes.pnmSectionContainer;
    onContainerClickProps = {
      onClick: () => navigate(`pnm/${pnmId}`),
    };
  }

  return (
    <div>
      <CardContent className={ classes.commentCardContent }>
        { showPNM
          ? (
            <Hidden xsDown>
              { renderAvatar() }
            </Hidden>
          ) : '' }

        <div>
          <div className={ sectionContainerClass } { ...onContainerClickProps }>
            { showPNM ? <Typography className={ classes.pnm }>{ pnmName }</Typography> : ''}
            { type === 'matches'
              ? (
                <Typography className={ classes.pnm }>
                  Match to
                  {' '}
                  { matchedUserName }
                </Typography>
              ) : ''}
            { type === 'concerns'
              && (
              <Typography className={ classes.pnm }>
                Membership Concern -
                {' '}
                {item.getIn(['category', 'label'], 'N/A')}
              </Typography>
              )}

            <Typography>
              { text }
            </Typography>
          </div>

          { (type === 'votes' || (type === 'notes' && item.get('round'))
            || (type === 'matches' && item.get('round')))
            && (
            <div className='comment-list-item__author-container'>
              <Typography className={ classes.time } color='primary'>{ roundName }</Typography>
            </div>
            )}

          <div className='comment-list-item__author-container'>
            <Icon className={ classes.calendarIcon } color='action'>event</Icon>
            <Typography className={ classes.time } color='textSecondary'>
              { type === 'votes' && submittedByAdmin.size > 0
                ? `${moment(updatedAt).format('LT')} - ${adminName}`
                : `${moment(updatedAt).format('LT')} - ${authorName}`}
            </Typography>
          </div>
          { type === 'votes' && submittedByAdmin.size > 0
            ? (
              <Typography className={ classes.time } color='textSecondary'>
                On behalf of
                {' '}
                {authorName}
              </Typography>
            ) : null}
        </div>
      </CardContent>
    </div>
  );
};

const styles = theme => ({
  calendarIcon: {
    marginRight: 5,
    fontSize:    18,
  },

  pnm: {
    fontWeight:  600,
    marginRight: 10,
  },

  photoAvatar: {
    marginRight: 15,
    height:      50,
    width:       50,
    cursor:      'pointer',
  },

  pnmSectionContainer: {
    cursor: 'pointer',
  },

  anonAvatar: {
    width:           50,
    height:          50,
    marginRight:     15,
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.common.white,
  },

  initialsAvatar: {
    width:       50,
    height:      50,
    marginRight: 15,
    cursor:      'pointer',
  },

  initialsLabel: {
    color: theme.palette.common.white,
  },

  commentCardContent: {
    alignItems: 'center',
    display:    'flex',
    marginLeft: 25,

    '&:last-child': {
      paddingBottom: '16px',
    },
  },

  time: {
    whiteSpace: 'nowrap',
  },
});

export default withStyles(styles)(Comment);
