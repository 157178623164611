import {
  CURRENT_CHAPTER_SET,
  CHAPTER_UPDATE_INIT,
  CHAPTER_FETCH_ALL_INIT,
  CURRENT_CHAPTER_FETCH_FOR_ADMIN_INIT,
  CURRENT_CHAPTER_CLEAR,
  CHAPTER_CREATE_INIT,
  CURRENT_CHAPTER_FETCH_FOR_VOTES_INIT,
} from '../constants/chapterTypes';

export function chapterCreate(params) {
  return {
    type:    CHAPTER_CREATE_INIT,
    payload: params,
  };
}

export function currentChapterSet(chapter) {
  return {
    type:    CURRENT_CHAPTER_SET,
    payload: chapter,
  };
}

export function currentChapterClear() {
  return {
    type: CURRENT_CHAPTER_CLEAR,
  };
}

export function currentChapterFetchForAdmin({ chapterId, userId }) {
  return {
    type:    CURRENT_CHAPTER_FETCH_FOR_ADMIN_INIT,
    payload: {
      chapterId,
      userId,
    },
  };
}

export function currentChapterFetchVotes() {
  return {
    type:    CURRENT_CHAPTER_FETCH_FOR_VOTES_INIT,
    payload: {},
  };
}

export function chapterUpdate(params) {
  return {
    type:    CHAPTER_UPDATE_INIT,
    payload: params,
  };
}

export function chapterFetchAll(params) {
  return {
    type:    CHAPTER_FETCH_ALL_INIT,
    payload: params,
  };
}
