import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { List, Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';

// MUI components
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

// local components
import EnhancedTableHead from './components/EnhancedTableHead';
import TableToolbar from './components/TableToolbar';
import TableActions from './components/TableActions';
import EnhancedTableRow from './components/EnhancedTableRow';

function MatchTable(props) {
  MatchTable.propTypes = {
    classes:              PropTypes.instanceOf(Object).isRequired,
    clearMatches:         PropTypes.func.isRequired,
    currentChapterId:     PropTypes.number.isRequired,
    currentRoundId:       PropTypes.string.isRequired,
    deleteMatch:          PropTypes.func.isRequired,
    fetchAllMatches:      PropTypes.func.isRequired,
    fetchUsersForChapter: PropTypes.func.isRequired,
    isCdSite:             PropTypes.bool.isRequired,
    matchLoading:         PropTypes.bool.isRequired,
    matchTableFormValues: PropTypes.instanceOf(Map).isRequired,
    matches:              PropTypes.instanceOf(List).isRequired,
    rounds:               PropTypes.instanceOf(List).isRequired,
    userLoading:          PropTypes.bool.isRequired,
    users:                PropTypes.instanceOf(List).isRequired,
  };

  const {
    classes,
    clearMatches,
    currentChapterId,
    currentRoundId,
    deleteMatch,
    fetchAllMatches,
    fetchUsersForChapter,
    isCdSite,
    matchLoading,
    matchTableFormValues,
    matches,
    rounds,
    userLoading,
    users,
  } = props;

  const [order, setOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('lastname');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const round = matchTableFormValues.get('round');

  // initial render
  useEffect(() => {
    const initialRound = round || currentRoundId;

    clearMatches();

    fetchUsersForChapter({
      chapter:              currentChapterId,
      round,
      shouldAddMatchCounts: true,
    });
    fetchAllMatches({
      chapter:           currentChapterId,
      round:             initialRound,
      officialOnly:      true,
      skipPopulateUsers: true,
      skipFieldData:     true,
      skipVoteData:      true,
      skipTagData:       true,
      skipHometownData:  true,
    });
  }, []);

  // handles form value (round) changes
  useEffect(() => {
    if (round) {
      clearMatches();
      fetchUsersForChapter({
        chapter:              currentChapterId,
        round,
        shouldAddMatchCounts: true,
      });
      fetchAllMatches({
        chapter:           currentChapterId,
        round,
        officialOnly:      true,
        skipPopulateUsers: true,
        skipFieldData:     true,
        skipVoteData:      true,
        skipTagData:       true,
        skipHometownData:  true,
      });
    }
  }, [round]);

  const onSortChange = (property) => {
    const newSortBy = property;
    let newOrder = 'desc';

    if (sortBy === property && order === 'desc') {
      newOrder = 'asc';
    }

    setOrder(newOrder);
    setSortBy(newSortBy);
  };

  const getNumberOfColumns = () => {
    const selectedRoundId = matchTableFormValues.get('round', '');

    const selectedRound = rounds.find(r => r.get('_id') === selectedRoundId) || Map();
    return selectedRound.get('events', 0);
  };

  const getMatchesForUser = user => matches.filter((m = Map()) =>
    m.getIn(['user', 'id']) === user.get('id'));

  const sortMatches = () => {
    let sortedMatches = users;

    switch (sortBy) {
      case 'firstname':
        sortedMatches = users.sortBy(u => u.get('firstname'));
        break;
      case 'lastname':
        sortedMatches = users.sortBy(u => u.get('lastname'));
        break;
      case 'matchRecs':
        sortedMatches = users.sortBy(u => u.get('matchRecs'));
        break;
      case 'matches':
        sortedMatches = users.sortBy(u => u.get('officialMatches'));
        break;
      default:
        break;
    }

    if (order === 'desc') {
      sortedMatches = sortedMatches.reverse();
    }

    return sortedMatches;
  };

  const renderLoadingIndicator = () => {
    const loading = (matchLoading || userLoading || !matchTableFormValues.get('round')
    ) || false;
    let element;

    if (loading) {
      element = (
        <TableRow>
          <TableCell colSpan={ 10 } className={ classes.spinner }>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    return element;
  };

  const selectedRoundId = matchTableFormValues.get('round', '');
  const selectedRound = rounds.find(r => r.get('_id', '').toString() === selectedRoundId.toString()) || Map();
  const numEvents = selectedRound.get('events', 0);

  const roundHasEvents = selectedRound.get('events', 0) > 0;
  const warningMessage = roundHasEvents ? '' : 'No events set, configure them in your Settings to manage matches';
  const orderedUsers = sortMatches()
    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

  return (
    <div>
      <Paper className={ classes.root }>
        <TableToolbar isCdSite={ isCdSite }
          currentChapterId={ currentChapterId }
          matches={ matches }
          deleteMatch={ deleteMatch }
          numEvents={ numEvents }
          rounds={ rounds }
          selectedRound={ selectedRound }
          subtitle={ `View and manage your members' official matches for ${selectedRound.get('name', '')}` }
          title='Match Results'
          users={ sortMatches() }
          warningMessage={ warningMessage }
          icon='list_alt' />

        <div className={ classes.tableWrapper }>

          <Table className={ classes.table }>
            <EnhancedTableHead numCols={ getNumberOfColumns() }
              order={ order }
              sortBy={ sortBy }
              round={ selectedRound }
              onSortChange={ onSortChange }
              rowCount={ users.size || 0 } />

            <TableBody>

              { renderLoadingIndicator() }

              { (users.size === 0 && !userLoading) ? (
                <TableRow>
                  <TableCell colSpan={ 1 } padding='dense'>
                    <Icon>error_outline</Icon>
                  </TableCell>
                  <TableCell colSpan={ getNumberOfColumns() - 1 } padding='none'>
                    <Typography variant='subtitle1'>
                      No Matches Found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : orderedUsers.map(u => (
                <EnhancedTableRow key={ u.get('id') }
                  currentChapterId={ currentChapterId }
                  numCols={ getNumberOfColumns() }
                  round={ selectedRound }
                  deleteMatch={ deleteMatch }
                  matches={ getMatchesForUser(u) }
                  user={ u } />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination ActionsComponent={ TableActions }
                  backIconButtonProps={ {
                    'aria-label': 'Previous Page',
                  } }
                  nextIconButtonProps={ {
                    'aria-label': 'Next Page',
                  } }
                  onChangePage={ (e, pageNum) => setPage(pageNum) }
                  onChangeRowsPerPage={ e => setRowsPerPage(e.target.value) }
                  page={ page }
                  rowsPerPage={ rowsPerPage }
                  count={ users.size } />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    </div>
  );
}

const styles = () => ({
  root: {
    width:        '100%',
    marginTop:    20,
    height:       'auto',
    overflowY:    'scroll',
    borderRadius: 10,
  },

  table: {
    minWidth: 500,
  },

  tableWrapper: {
    overflowX: 'visible',
  },

  spinner: {
    textAlign: 'center',
  },
});

export default withStyles(styles)(MatchTable);
