import Immutable, { Map } from 'immutable';
import { denormalize } from 'normalizr';

import { organizationSchema } from '../../schemas/organization';

export default function getOrganization(state) {
  const currentChapter = state.get('currentChapter', Map());
  const currentUser = state.get('currentUser', Map());
  const organization = state.get('organization', Map());
  const roundCategory = state.get('roundCategory', Map());
  const concernCategory = state.get('concernCategory', Map());
  const category = state.get('category', Map());

  const orgId = currentUser.getIn(['data', 'organization'], 0);
  const result = currentChapter.getIn(['data', 'organization'], orgId);

  let element = Map();
  if (result && organization.hasIn(['data', 'items', result.toString()])) {
    const entities = {
      organization:    organization.getIn(['data', 'items'], Map()).toJS(),
      roundCategory:   roundCategory.getIn(['data', 'items'], Map()).toJS(),
      category:        category.getIn(['data', 'items'], Map()).toJS(),
      concernCategory: concernCategory.getIn(['data', 'items'], Map()).toJS(),
    };

    element = denormalize(result, organizationSchema, entities);
  }
  return Immutable.fromJS(element);
}
