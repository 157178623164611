import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

// components;
import CompareChapters from './CompareChapters';

// action creators
import { chapterFetchAll } from '../../../../../actions/chapterActions';

const mapStateToProps = state => ({
  compareChaptersForm: state.getIn(['form', 'compareChaptersForm'], Map()),
  chapter:             state.get('chapter'),
});

const mapDispatchToProps = dispatch => ({
  fetchChapters: (params) => {
    dispatch(chapterFetchAll(params));
  },
});

// schemas
const schema = {
  chapterA: {
    presence: true,
  },
  chapterB: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedCompareChaptersForm = reduxForm({
  form:               'compareChaptersForm',
  validate:           validateForm,
  enableReinitialize: true,
  onChange:           handleChange,
})(CompareChapters);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedCompareChaptersForm);
