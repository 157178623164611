import { connect } from 'react-redux';

import { push } from 'react-router-redux';

// components;
import SettingsTabBar from './SettingsTabBar';
import getOrganization from '../../../../lib/selectors/getOrganization';

const mapStateToProps = state => ({
  router:         state.get('router'),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  organization:   getOrganization(state),
});

const mapDispatchToProps = dispatch => ({
  navigateToSettingsTab: (route) => {
    dispatch(push(`${route}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTabBar);
