import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import { push } from 'react-router-redux';

// api
import { get } from '../../sources/api/client';
import chapterService from '../../sources/api/chapterService';

import userService from '../../sources/api/userService';

// constants
import { CURRENT_CHAPTER_FETCH_FOR_ADMIN_INIT, CURRENT_CHAPTER_FETCH_FOR_ADMIN, CURRENT_CHAPTER_FETCH } from '../../constants/chapterTypes';
import { CURRENT_USER_FETCH } from '../../constants/userTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { chapterSchema } from '../../schemas/chapter';
import { userSchema } from '../../schemas/user';

export function* currentChapterFetchForAdmin(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  // pass through action payload:  includeRoundHasVotes boolean;
  const { chapterId, userId } = action.payload;

  const options = {
    query: {
      chapter: chapterId,
    },
  };

  try {
    yield window.localStorage.setItem('chapter', chapterId);

    yield put(push('/'));

    yield* httpSaga(CURRENT_CHAPTER_FETCH, call(get, chapterService, chapterId), {
      schema: chapterSchema,
    });

    yield* httpSaga(CURRENT_USER_FETCH, call(get, userService, userId, options), {
      schema: userSchema,
    });
  } catch (err) {
    yield put(error(CURRENT_CHAPTER_FETCH_FOR_ADMIN, err));
  } finally {
    yield put(doneIndicator(CURRENT_CHAPTER_FETCH_FOR_ADMIN));
  }
}

export function* watchCurrentChapterFetchForAdmin() {
  yield takeEvery(CURRENT_CHAPTER_FETCH_FOR_ADMIN_INIT, currentChapterFetchForAdmin);
}

export default function* root() {
  yield all([
    fork(watchCurrentChapterFetchForAdmin),
  ]);
}
