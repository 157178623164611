import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import moment from 'moment';
import Immutable, { List, Map } from 'immutable';
import 'react-vis/dist/style.css';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import DayPickerInput from '../../../../DayPickerInput';
import MultiAutosuggest from '../../../../MultiAutosuggest';

import AreaGraph from '../AreaGraph';
import LineGraph from '../LineGraph';

// Width breakpoints for graphs
const widths = {
  xs: 575, // single graph across screen
  sm: 450,
  md: 475,
  lg: 625,
  xl: 750,
};

class Graphs extends Component {
  static propTypes = {
    analytics:               PropTypes.instanceOf(Map).isRequired,
    analyticsForm:           PropTypes.instanceOf(Map).isRequired,
    chapter:                 PropTypes.instanceOf(Map).isRequired,
    classes:                 PropTypes.instanceOf(Object).isRequired,
    clearSelectedChapter:    PropTypes.func.isRequired,
    handleClearSuggestions:  PropTypes.func.isRequired,
    handleUpdateSuggestions: PropTypes.func.isRequired,
    initAnalytics:           PropTypes.func.isRequired,
    initChapters:            PropTypes.func.isRequired,
    loading:                 PropTypes.bool,
    suggestions:             PropTypes.instanceOf(Array).isRequired,
  };

  static defaultProps = {
    loading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        from:             moment().subtract(7, 'days').toDate(),
        to:               moment().toDate(),
        search:           null,
        timerId:          null,
        selectedChapters: [],

        // Component state updates and
        //  redux action creator calls won't work together in one callback,
        //  so we have to manually set state so that we can
        //  dispatch the redux action in componentWillReceiveProps
        selectionDeleted: false,
      }),
    };
  }

  componentWillMount() {
    const { analyticsForm, initChapters } = this.props;

    const dates = analyticsForm.getIn(['values', 'date'], Map());
    const from = dates.get('from') || this.state.data.get('from');
    const to = dates.get('to') || this.state.data.get('to');

    const newData = List();
    initChapters();

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('data', newData);
        map.set('from', moment(from).toDate());
        map.set('to', moment(to).toDate());
      }),
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      analyticsForm,
      initAnalytics,
      initChapters,
      loading,
    } = this.props;

    const nextAnalyticsForm = nextProps.analyticsForm;

    const dates = analyticsForm.getIn(['values', 'date'], Map());
    const nextDates = nextAnalyticsForm.getIn(['values', 'date'], Map());

    const selectedChapters = this.state.data.get('selectedChapters');
    const selectionDeleted = this.state.data.get('selectionDeleted');

    const selectedChapter = Immutable.fromJS(analyticsForm.getIn(['values', 'selectedChapter'], {}));
    const nextSelectedChapter = Immutable.fromJS(nextAnalyticsForm.getIn(['values', 'selectedChapter'], Map()));

    const from = nextDates.get('from') || this.state.data.get('from');
    const to = nextDates.get('to') || this.state.data.get('to');

    const alreadySelected = selectedChapters.includes(nextSelectedChapter);

    if (selectionDeleted) {
      // Resets state back to default, ready for any other deletion changes
      this.setState({ data: this.state.data.set('selectionDeleted', false) });
    } else if (nextSelectedChapter.get('value') && !alreadySelected) {
      this.setState({
        data: this.state.data.set('selectedChapters', selectedChapters.push(nextSelectedChapter)),
      });
    } else if (dates !== nextDates) {
      this.setState({
        data: this.state.data.withMutations((map) => {
          map.set('from', moment(from).toDate());
          map.set('to', moment(to).toDate());
        }),
      });
    }

    if (!loading && selectedChapter !== nextSelectedChapter) {
      initChapters();
      initAnalytics(selectedChapters, from, to);
    }

    if (!loading && dates !== nextDates) {
      initAnalytics(selectedChapters, from, to);
    }
  }

  handleUpdateSuggestions = ({ value }) => {
    const timerId = this.state.data.get('timerId');

    if (timerId) {
      clearTimeout(timerId);
    }

    this.setState({
      data: this.state.data.set('timerId', setTimeout(this.onSearchChange, 1000, value)),
    });
  }

  handleClearSuggestions = () => { }

  handleSelectAll = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('selectedChapters', List());
        map.set('search', null);
        map.set('selectionDeleted', true);
      }),
    });
  }

  handleClearSelected = () => {
    const { clearSelectedChapter } = this.props;
    clearSelectedChapter();

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('selectedChapters', List());
        map.set('search', null);
        map.set('selectionDeleted', true);
      }),
    });
  }

  handleDeleteChip = (index) => {
    const { clearSelectedChapter } = this.props;
    clearSelectedChapter();

    const selectedChapters = this.state.data.get('selectedChapters');
    const newSelected = selectedChapters.splice(index, 1);

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('selectedChapters', newSelected);
        map.set('selectionDeleted', true);
      }),
    });
  }

  render() {
    const {
      analytics,
      chapter,
      classes,
      handleClearSuggestions,
      handleUpdateSuggestions,
      initAnalytics,
      loading,
      suggestions,
    } = this.props;

    const from = this.state.data.get('from');
    const to = this.state.data.get('to');
    const selectedChapters = this.state.data.get('selectedChapters');

    const selectionDeleted = this.state.data.get('selectionDeleted');

    if (!loading && selectionDeleted) {
      initAnalytics(selectedChapters, from, to);
    }

    const votes = analytics.getIn(['data', 'votes'], List());
    const notes = analytics.getIn(['data', 'notes'], List());
    const tags = analytics.getIn(['data', 'tags'], List());

    return (
      <Grid container spacing={ 32 }>
        <Grid item xs={ 12 } className={ classes.titleContainer }>
          <Icon className={ classes.titleIcon } color='primary'>insert_chart</Icon>
          <Typography variant='h5' color='primary'>Graphs</Typography>
        </Grid>

        <Grid item xs={ 12 }>
          <Typography variant='subtitle1' color='textSecondary' gutterBottom>
            View, filter, and analyze data for your
            organization
            <span>&apos;</span>
            s chapters
          </Typography>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <div className={ classes.searchContainer }>
            <Field name='selectedChapter'
              label='Search Chapters (by name or school)'
              helperText='Select a chapter and search again to view data for multiple chapters'
              variant='outlined'
              displaySelectAll
              displayClear
              deleteChip={ this.handleDeleteChip }
              selected={ selectedChapters }
              component={ MultiAutosuggest }
              suggestions={ suggestions }
              handleSelectAll={ this.handleSelectAll }
              handleClearSelected={ this.handleClearSelected }
              onSuggestionsFetchRequested={ handleUpdateSuggestions }
              onSuggestionsClearRequested={ handleClearSuggestions }
              loading={ chapter.get('loading') }
              required />
          </div>
        </Grid>

        <Grid item xs={ 10 } sm={ 6 }>
          <div className={ classes.dateContainer }>
            <Field name='date'
              label='Select dates to pull data from'
              component={ DayPickerInput }
              variant='outlined'
              margin='dense'
              fullWidth
              required />
          </div>
        </Grid>

        <AreaGraph data={ votes }
          title='Votes'
          loading={ loading }
          from={ from }
          to={ to }
          widthBreakpoints={ widths }
          icon='thumbs_up_down' />

        <LineGraph data={ tags }
          title='Tags'
          color='#ff5722'
          stroke='#D84315'
          loading={ loading }
          from={ from }
          to={ to }
          widthBreakpoints={ widths }
          icon='local_offer' />

        <LineGraph data={ notes }
          title='Notes'
          loading={ loading }
          from={ from }
          to={ to }
          widthBreakpoints={ widths }
          icon='chat' />
      </Grid>
    );
  }
}

const styles = theme => ({
  tabsCard: {
    height:    'auto',
    marginTop: 40,

    [theme.breakpoints.down('sm')]: {
      paddingLeft:  10,
      paddingRight: 10,
      marginBottom: 20,
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft:  50,
      paddingRight: 30,
      marginBottom: 25,
    },
  },

  titleIcon: {
    verticalAlign: 'middle',
    marginRight:   20,
    fontSize:      35,
  },

  titleContainer: {
    marginTop:     20,
    marginLeft:    25,
    display:       'flex',
    flexDirection: 'row',
  },

  divider: {
    marginTop:    40,
    marginBottom: 10,
  },

  orContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 100,
    width:        45,
    height:       45,
    textAlign:    'center',
    marginTop:    8,
  },

  orText: {
    marginTop: 8,
  },

  grayIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  dateContainer: {
    padding:      20,
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
  },

  searchContainer: {
    padding:      25,
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
  },

  exportContainer: {
    border:       '1px solid #bfbfbf',
    borderRadius: 10,
    padding:      20,
    minHeight:    350,
  },

  exportButtonContainer: {
    marginBottom: 15,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(Graphs);
