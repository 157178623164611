import {
  FIELD_CREATE_INIT, FIELD_REMOVE_INIT, FIELD_BULK_UPDATE_INIT, FIELD_BULK_CREATE_INIT,
} from '../constants/fieldTypes';

export function fieldCreate(params) {
  return {
    type:    FIELD_CREATE_INIT,
    payload: params,
  };
}

export function fieldRemove(id, chapter) {
  return {
    type:    FIELD_REMOVE_INIT,
    payload: { id, chapter },
  };
}

export function fieldBulkUpdate(items, chapter) {
  return {
    type:    FIELD_BULK_UPDATE_INIT,
    payload: {
      items,
      chapter,
    },
  };
}

export function fieldBulkCreate(params) {
  return {
    type:    FIELD_BULK_CREATE_INIT,
    payload: params,
  };
}
