export const ANALYTICS_FETCH = 'ANALYTICS_FETCH';
export const ANALYTICS_FETCH_INIT = 'ANALYTICS_FETCH_INIT';
export const ANALYTICS_FETCH_DONE = 'ANALYTICS_FETCH_DONE';

export const ANALYTICS_FETCH_OVERVIEW_DATA = 'ANALYTICS_FETCH_OVERVIEW_DATA';
export const ANALYTICS_FETCH_OVERVIEW_DATA_INIT = 'ANALYTICS_FETCH_OVERVIEW_DATA_INIT';
export const ANALYTICS_FETCH_OVERVIEW_DATA_DONE = 'ANALYTICS_FETCH_OVERVIEW_DATA_DONE';

export const ANALYTICS_FETCH_FOR_CHAPTER = 'ANALYTICS_FETCH_FOR_CHAPTER';
export const ANALYTICS_FETCH_FOR_CHAPTER_INIT = 'ANALYTICS_FETCH_FOR_CHAPTER_INIT';
export const ANALYTICS_FETCH_FOR_CHAPTER_DONE = 'ANALYTICS_FETCH_FOR_CHAPTER_DONE';
