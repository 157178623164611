import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import Immutable from 'immutable';

// components
import ColumnMatcher from './ColumnMatcher';

const createInitialValues = ({ columns }) => {
  const initialValues = [];

  columns.forEach((column, index) => {
    if (column.get('name')) {
      initialValues.push({
        name:  column.get('name'),
        match: index,
      });
    }
  });

  return Immutable.fromJS({ columns: initialValues });
};

const mapState = (state, props) => ({
  initialValues: createInitialValues(props),
  formValues:    getFormValues('columnMatcherForm')(state),
});

export default connect(mapState)(reduxForm({
  form: 'columnMatcherForm',
})(ColumnMatcher));
