import { connect } from 'react-redux';
import {
  reduxForm, arrayMove, arraySplice, initialize,
} from 'redux-form/immutable';
import validate from 'validate.js';
import { List, Map } from 'immutable';

// action creators
import { roundBulkUpdate } from '../../../../../../actions/roundActions';

// selectors
import getLoadingFromState from '../../../../../../lib/selectors/getLoadingFromState';

// components
import RoundsForm from './RoundsForm';

const createInitialValues = (props) => {
  const PRESCORE_ROUND_TYPES = [6, 7];

  const { rounds = List(), roundsForm } = props;
  const formValues = roundsForm.getIn(['values', 'rounds'], List());
  const formValueIds = (formValues.map(item => item.get('_id', '').toString()) || List()).toJS();

  let values = rounds;

  values = rounds.map((r) => {
    if (formValueIds.includes(r.get('_id', '').toString())) {
      let newRound = formValues.find(item => item.get('_id', '').toString() === r.get('_id', '').toString());

      // Disable draggability for special round types
      newRound = newRound.set('draggable',
        PRESCORE_ROUND_TYPES.includes(newRound.get('roundType')) ? 0 : 1);

      return newRound.set('order', r.get('order'));
    }

    // Disable draggability for special round types
    return r.set('draggable', PRESCORE_ROUND_TYPES.includes(r.get('roundType')) ? 0 : 1);
  });

  // Ensures that prescore rounds precede other rounds
  values.forEach((v, index) => {
    if (v.get('roundType') === 6) {
      values = values.splice(index, 1);
      values = values.unshift(v);
    }
  });

  return values;
};

// Manually checks if the form is dirty, because adding and deleting items reinitializes the form
const getManualDirty = (state, props) => {
  const { rounds, roundsForm } = props;

  const formRounds = roundsForm.getIn(['values', 'rounds'], List());
  let dirty = false;

  if (rounds) {
    formRounds.forEach((r) => {
      const actualRound = rounds.find(round =>
        r.get('_id', '').toString() === round.get('_id', '').toString(),
      {}, // context
      Map()); // not set value

      if (
        r.get('name') !== actualRound.get('name')
        || r.get('roundType') !== actualRound.get('roundType')
        || r.get('topScores') !== actualRound.get('topScores')
        || r.get('votingLimit') !== actualRound.get('votingLimit')
        || r.get('events') !== actualRound.get('events')
        || r.get('order') !== actualRound.get('order')
      ) {
        dirty = true;
      }
    });
  }

  return dirty;
};

const mapStateToProps = (state, props) => ({
  currentChapter: state.get('currentChapter'),
  roundsForm:     (state.get('form')).get('roundsForm') || Map(),
  manualDirty:    getManualDirty(state, props),
  initialValues:  { rounds: createInitialValues(props) },
  roundLoading:   getLoadingFromState('round')(state),
});

const mapDispatchToProps = dispatch => ({
  reorderForm: (from, to) => {
    dispatch(arrayMove('roundsForm', 'rounds', from, to));
  },
  updateForm: (index, newValue) => {
    dispatch(arraySplice('roundsForm', 'rounds', index, 1, newValue));
  },
  initializeForm: (items) => {
    dispatch(initialize('roundsForm', items));
  },
  bulkUpdateRounds: (items, chapter) => {
    dispatch(roundBulkUpdate(items, chapter));
  },
});

const validateForm = function (form) {
  return validate(form.toJS());
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedRoundsForm = reduxForm({
  form:               'roundsForm',
  validate:           validateForm,
  enableReinitialize: false,
  onChange:           handleChange,
})(RoundsForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedRoundsForm);
