import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// action creators
import { fetchSlideshowPnms } from '../../../actions/slideshowActions';

// selectors
import getFields from '../../../lib/selectors/getFields';
import getChapterAttribute from '../../../lib/selectors/getChapterAttribute';

// components
import SlideshowModal from './SlideshowModal';

const mapStateToProps = state => ({
  currentChapterId: getChapterAttribute('id')(state),
  fields:           getFields(state),
});

const mapDispatchToProps = {
  fetchSlideshowPnms,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlideshowModal));
