import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// local components
import Autosuggest from '../../../../../Autosuggest';

class GroupUsers extends Component {
  static propTypes = {
    bumpGroupLoading:     PropTypes.bool.isRequired,
    classes:              PropTypes.instanceOf(Object).isRequired,
    currentChapterId:     PropTypes.number.isRequired,
    formValues:           PropTypes.instanceOf(Map).isRequired,
    selectedItem:         PropTypes.instanceOf(Map).isRequired,
    updateBumpGroup:      PropTypes.func.isRequired,
    userLoading:          PropTypes.bool.isRequired,
    userSuggestions:      PropTypes.instanceOf(Array).isRequired,
    fetchUsersForChapter: PropTypes.func.isRequired,
  };

  getRoleLabel = (user) => {
    const teamLevel = user.get('team_level');
    if (teamLevel === 0) {
      if (user.get('role') === 'CONCERN_CHAIR') {
        return 'Concern Chair';
      }
      return 'Voting Member';
    } if (teamLevel === 1) {
      return 'Recruitment Team';
    } if (teamLevel === 2) {
      return 'Technology Team';
    }
    return 'Voting Member';
  }

  handleAddMember = () => {
    const {
      formValues,
      selectedItem,
      currentChapterId,
      updateBumpGroup,
    } = this.props;

    const searchItem = formValues.get('user', {});
    const newUserId = searchItem.value;

    const oldItem = selectedItem;

    // Make payload contain only user IDs and add new user ID
    const oldUserIds = oldItem.get('users', List()).map(u => u.get('id'));

    // Prevents user from being added to group multiple times
    if (newUserId && !oldUserIds.includes(newUserId)) {
      const newItem = oldItem.set('users', oldUserIds.push(newUserId));

      updateBumpGroup({
        chapter:            currentChapterId,
        id:                 newItem.get('_id'),
        item:               newItem.toJS(),
        shouldPopulateUser: true,
      });
    }
  }

  handleDeleteMember = (userToDelete) => {
    const {
      currentChapterId,
      selectedItem,
      updateBumpGroup,
    } = this.props;

    const oldItem = selectedItem;

    // // Make payload contain only user IDs and remove selected user ID
    const oldUserIds = oldItem.get('users', List()).map(u => u.get('id'));
    const indexToDelete = oldUserIds.indexOf(userToDelete.get('id'));

    const newItem = oldItem.set('users', oldUserIds.splice(indexToDelete, 1));

    updateBumpGroup({
      chapter:            currentChapterId,
      id:                 newItem.get('_id'),
      item:               newItem.toJS(),
      shouldPopulateUser: true,
    });
  }

  handleUpdateSuggestions = ({ value }) => {
    const { currentChapterId, fetchUsersForChapter } = this.props;

    fetchUsersForChapter({
      search:  value,
      chapter: currentChapterId,
    });
  }

  handleClearSuggestions = () => { }

  render() {
    const {
      classes, bumpGroupLoading, selectedItem, userLoading, userSuggestions,
    } = this.props;

    const groupMembers = selectedItem.get('users', List());

    return (
      <Paper className={ classes.card }>
        <Grid container justify='center' alignItems='center'>
          <Grid item xs={ 12 } sm={ 5 } className={ classes.paddedContainer }>
            <Typography variant='subtitle1'>Group Members</Typography>
          </Grid>

          <Grid item xs={ 11 } sm={ 6 } align='left'>
            <Field name='user'
              label='Add New Member'
              variant='outlined'
              component={ Autosuggest }
              loading={ userLoading }
              suggestions={ userSuggestions }
              onSuggestionsFetchRequested={ this.handleUpdateSuggestions }
              onSuggestionsClearRequested={ this.handleClearSuggestions } />
          </Grid>

          <Grid item xs={ 1 }>
            <IconButton onClick={ this.handleAddMember }>
              <Icon color='primary'>add_circle</Icon>
            </IconButton>
          </Grid>

          <Grid item xs={ 12 } className={ classes.bottomBorderContainer } />

          <Grid item xs={ 12 } className={ classes.paddedContainer }>
            { bumpGroupLoading ? (
              <Grid item xs={ 12 } className={ classes.progressIndicator }>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid container spacing={ 16 } alignItems='center'>
                { groupMembers.size
                  ? groupMembers.map(groupMember => [
                    <Grid item xs={ 1 } key='avatar'>
                      <Avatar>
                        { `${groupMember.get('firstname', '').charAt(0)}${groupMember.get('lastname', '').charAt(0)}` }
                      </Avatar>
                    </Grid>,

                    <Grid item xs={ 4 } key='name'>
                      <Grid container alignItems='center'>
                        <Grid item xs={ 12 }>
                          <Typography variant='subtitle2'>
                            {`${groupMember.get('firstname')} ${groupMember.get('lastname')}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                          <Typography variant='body2' color='textSecondary'>
                            { this.getRoleLabel(groupMember) }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>,

                    <Grid item xs={ 6 } key='stats'>
                      <Grid container spacing={ 32 }>
                        <Grid item xs={ 1 }>
                          <Tooltip title='Match Recommendations'>
                            <Icon className={ classes.recIcon }
                              color='action'>
                              extension
                            </Icon>
                          </Tooltip>
                        </Grid>

                        <Grid item xs={ 1 }>
                          <Typography color='textSecondary'
                            variant='subtitle1'>
                            { groupMember.get('matchRecs') || 0 }
                          </Typography>
                        </Grid>

                        <Grid item xs={ 1 }>
                          <Tooltip title='Official Matches'>
                            <Icon className={ classes.matchIcon }
                              color='action'>
                              check_circle
                            </Icon>
                          </Tooltip>
                        </Grid>

                        <Grid item xs={ 1 }>
                          <Typography color='textSecondary'
                            variant='subtitle1'>
                            { groupMember.get('officialMatches') || 0 }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>,

                    <Grid item xs={ 1 } key='delete'>
                      <IconButton onClick={ () => this.handleDeleteMember(groupMember) }>
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>,
                  ]) : (
                    <Grid item xs={ 12 }>
                      <Typography color='secondary'>No Members Assigned</Typography>
                    </Grid>
                  )}
              </Grid>
            )}

          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const styles = theme => ({
  card: {
    borderRadius: 10,
    marginTop:    20,
    minHeight:    450,
  },

  paddedContainer: {
    padding: 16,
  },

  profileStats: {
    marginTop: 15,
  },

  profileIcon: {
    marginLeft: 15,
  },
  lightgrayIcon: {
    color: '#bfbfbf',
  },

  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatarIcon: {
    fontSize: 50,
  },

  bottomBorderContainer: {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },

  progressIndicator: {
    textAlign: 'center',
  },
});

export default withStyles(styles)(GroupUsers);
