import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

// components
import MainCardBody from '../MainCardBody';
import MainCardHeader from '../MainCardHeader';
import Card from '@material-ui/core/Card';

function MainCard(props) {
  MainCard.propTypes = {
    teamLevel:      PropTypes.number.isRequired,
    clearanceLevel: PropTypes.number.isRequired,
    votes:          PropTypes.instanceOf(List).isRequired,
  };

  const { clearanceLevel, teamLevel, votes } = props;

  return (
    <Card style={ { borderRadius: 10 } }>
      <MainCardHeader teamLevel={ teamLevel } clearanceLevel={ clearanceLevel } />
      <MainCardBody teamLevel={ teamLevel } clearanceLevel={ clearanceLevel } votes={ votes } />
    </Card>
  );
}

export default MainCard;
