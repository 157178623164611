import { connect } from 'react-redux';
import { reduxForm, isDirty, change } from 'redux-form/immutable';
import validate from 'validate.js';
import { List, Map } from 'immutable';

// components
import FiltersForm from './FiltersForm';

// action creators
import { chapterUpdate } from '../../../../actions/chapterActions';
import { roundBulkUpdate } from '../../../../actions/roundActions';
import { setOpenModalAdvancedOptions } from '../../../../actions/modalAdvancedOptions';

// helpers
import getTags from '../../../../lib/selectors/getTags';
import getFields from '../../../../lib/selectors/getFields';
import getOrganization from '../../../../lib/selectors/getOrganization';

const createInitialValues = (state, props) => {
  const { currentChapter, rounds } = props;
  const chapterData = currentChapter.get('data') || Map();
  const currentRound = chapterData.get('currentRound');
  const roundData = (rounds || List()).find(r => r.get('_id') === currentRound) || Map();
  const selectedEvent = chapterData.get('selectedEvent');

  const tags = getTags(state);
  const selectedTags = chapterData.get('selectedTags')
    ? chapterData.get('selectedTags').filter(selectedTagId =>
      (tags.map(t => t.get('_id')) || List()).includes(selectedTagId)) : List();

  const values = {
    invitations:            roundData.get('invitations'),
    flexPlus:               roundData.get('flexPlus'),
    flexMinus:              roundData.get('flexMinus'),
    selectedTags,
    resultsPnmsToInclude:   chapterData.get('resultsPnmsToInclude'),
    resultsPoolType:        chapterData.get('resultsPoolType'),
    gpaFieldOne:            chapterData.get('gpaFieldOne'),
    gpaRiskOne:             chapterData.get('gpaRiskOne'),
    gpaFieldTwo:            chapterData.get('gpaFieldTwo'),
    gpaRiskTwo:             chapterData.get('gpaRiskTwo'),
    moveLegaciesToTop:      chapterData.get('moveLegaciesToTop'),
    moveGradeRisksToBottom: chapterData.get('moveGradeRisksToBottom'),
    moveByTagId:            chapterData.get('moveByTagId'),
    moveByTagToTop:         chapterData.get('moveByTagToTop'),
    searchPnmFilter:        chapterData.get('searchPnmFilter'),
    selectedEvent:          List.isList(selectedEvent) ? selectedEvent.toJS() : selectedEvent || [],
  };

  return values;
};

const getRounds = ({ rounds }) => (rounds && List.isList(rounds) ? rounds : List());

const mapStateToProps = (state, props) => ({
  dirty:                     isDirty('filtersForm'),
  field:                     state.get('field'),
  filtersForm:               state.getIn(['form', 'filtersForm'], Map()),
  advancedDisplayOptionForm: state.getIn(['form', 'advancedDisplayOptionForm'], Map()),
  initialValues:             createInitialValues(state, props),
  fields:                    getFields(state),
  organization:              getOrganization(state),
  tags:                      getTags(state),
  rounds:                    getRounds(props),
});

const mapDispatchToProps = dispatch => ({
  updateChapter: (params) => {
    dispatch(chapterUpdate(params));
  },
  bulkUpdateRounds: (items, chapter) => {
    dispatch(roundBulkUpdate(items, chapter));
  },
  setOpenModalAdvancedOptions: (open) => {
    dispatch(setOpenModalAdvancedOptions(open));
  },
  clearSelectedFields: () => {
    dispatch(change('filtersForm', 'selectedFields', []));
  },
});

// schemas
const schema = {
  resultsPnmsToInclude: {
    presence: true,
  },
  resultsPoolType: {
    presence: true,
  },
  selectedTags: {
    presence: true,
  },
};

const validateForm = function (form) {
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedFiltersForm = reduxForm({
  form:               'filtersForm',
  validate:           validateForm,
  enableReinitialize: true,
  onChange:           handleChange,
})(FiltersForm);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedFiltersForm);
