import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

// components
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local components
import Tag from '../../../../../../../Tag';

function SpecialtyButtons(props) {
  SpecialtyButtons.propTypes = {
    input:           PropTypes.instanceOf(Object).isRequired,
    classes:         PropTypes.instanceOf(Object).isRequired,
    specialties:     PropTypes.instanceOf(List).isRequired,
    userSpecialties: PropTypes.instanceOf(List).isRequired,
  };

  const [selectedSpecialties, setSelectedSpecialties] = useState(List());

  const {
    classes,
    input,
    specialties,
    userSpecialties,
  } = props;

  const existingUserSpecialtyIds = userSpecialties.map(s => s.getIn(['specialty', '_id']));

  const handleTagSelect = (specialtyIndex, disabled = false) => {
    if (disabled) {
      return selectedSpecialties;
    }

    let newSelectedSpecialties = selectedSpecialties;
    let removed = false;

    selectedSpecialties.forEach((specialty, index) => {
      if (specialty === specialtyIndex) {
        newSelectedSpecialties = selectedSpecialties.splice(index, 1); // remove specialty from list
        setSelectedSpecialties(newSelectedSpecialties);
        removed = true;
      }
    });

    if (!removed) {
      newSelectedSpecialties = selectedSpecialties.push(specialtyIndex);
      setSelectedSpecialties(newSelectedSpecialties);
      return newSelectedSpecialties;
    }
    return newSelectedSpecialties;
  };

  const renderSubtitleDividers = (item, index) => {
    let element;
    const prevItem = index === 0
      ? item
      : specialties.get(index - 1);

    if (item.get('category') !== prevItem.get('category') && index !== 1) {
      element = (
        <Grid item xs={ 12 } key={ item.get('_id') }>
          <Typography variant='subtitle2'>
            Skills
          </Typography>
          <Divider />
        </Grid>
      );
    } else if (index === 0) {
      element = (
        <Grid item xs={ 12 } key={ item.get('_id') }>
          <Typography variant='subtitle2'>
            Shared Experiences
          </Typography>
          <Divider />
        </Grid>
      );
    }

    return element;
  };

  return (
    <Grid container spacing={ 8 }>

      <Grid item xs={ 12 }>
        <Typography variant='subtitle1' color='primary'>
          { selectedSpecialties.size }
          {' '}
          specialties selected
        </Typography>
      </Grid>

      { specialties.map((item, index) => [
        renderSubtitleDividers(item, index),

        <Grid item
          xs={ 3 }
          key={ `${item.get('_id')}_tag` }
          className={ classes.specialtyContainer }
          onClick={ () => input.onChange(
            handleTagSelect(
              item.get('_id'),
              existingUserSpecialtyIds.includes(item.get('_id').toString())
            ),
          ) }>
          <Tag key={ item.get('_id') }
            selected={ selectedSpecialties.includes(item.get('_id').toString()) }
            title={ item.get('title') }
            disabled={ existingUserSpecialtyIds.includes(item.get('_id').toString()) }
            disabledTooltipTitle='Already assigned to member'
            color={ item.get('color') } />
        </Grid>,

        <Grid item xs={ 9 } key={ `${item.get('_id')}_desc` }>
          <Typography color='textSecondary'>
            { item.get('description') }
          </Typography>
        </Grid>,
      ])}
    </Grid>
  );
}

const styles = theme => ({
  helperText: {
    color:     theme.palette.common.white,
    marginTop: 15,
    textAlign: 'center',
  },

  specialtyButtonBase: {
    borderRadius: 25,
  },

  specialtyContainer: {
    cursor: 'pointer',
  },
});

export default withStyles(styles)(SpecialtyButtons);
