import { Map, List } from 'immutable';
import { calculateVoteScore } from './calculateVoteScore';

// Gets chapter, round, and organization info to find a formula,
// then apply it, and return the final vote value
export function getVoteScore(
  organization, voteValues, categories, currentChapter, roundForVote, rounds
) {
  const orgId = (currentChapter.get('data') || Map()).get('organization') || 0;
  const currentRoundType = (rounds.find(r => r.get('_id') === roundForVote) || Map()).get('roundType');

  if (orgId > 0) {
    let formula = organization.get('voteScore');

    let maxScore;

    // checks for custom vote formulas
    organization.get('roundCategories', List()).forEach((roundCategory) => {
      if (roundCategory.get('roundType') === currentRoundType) {
        maxScore = roundCategory.get('maxScore');
        formula = roundCategory.get('voteScore') || formula;
      }
    });

    let voteScore = Math.round(
      calculateVoteScore(formula, voteValues, categories) * 1000
    ) / 1000;

    if (maxScore && voteScore > maxScore) {
      voteScore = maxScore;
    }

    return voteScore;
  }

  // no organization, meaning no categories: return single value
  return voteValues;
}
