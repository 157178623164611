import PropTypes from 'prop-types';
import Immutable, { Map, List } from 'immutable';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import validate from 'validate.js';
import deepEqual from 'fast-deep-equal';

// MUI components
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// local components
import DraggableList from '../../../../../DraggableList';
import HometownGroupsItem from '../HometownGroupsItem';
import Savebar from '../../../../../Savebar';

const HometownGroupsForm = function ({
  currentChapter,
  bulkUpdateHometownGroups,
  hometownGroupLoading,
  hometownGroups,
  hometownGroupStatus,
  hometownGroupsFormSubmitSucceeded,
  hometownGroupsFormSubmitFailed,
  removeHometownGroup,
  initialValues,
}) {
  const [savebarMessage, setSavebarMessage] = useState('Save your changes?');
  const [hideButtons, setHideButtons] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hometownGroupsSize, setHometownGroupsSize] = useState(0);

  useEffect(() => {
    if (hometownGroupsFormSubmitSucceeded) {
      setSnackbarOpen(true);
      setSnackbarMessage('Saved successfully');
    } else if (hometownGroupsFormSubmitFailed) {
      setSnackbarOpen(true);
      setSnackbarMessage('Save failed');
    } else {
      setSavebarMessage('Save your changes?');
      setHideButtons(false);
      setAutoHideDuration(null);
    }
  }, [hometownGroupsFormSubmitSucceeded, hometownGroupsFormSubmitFailed]);

  useEffect(() => {
    if (hometownGroupsSize > hometownGroups.size && hometownGroupsSize > 0) {
      if (hometownGroupStatus === 'success') {
        setSnackbarOpen(true);
        setSnackbarMessage('Removed Successfully');
      } else if (hometownGroupStatus === 'error') {
        setSnackbarOpen(true);
        setSnackbarMessage('Remove Failed');
      }
    }

    setHometownGroupsSize(hometownGroups.size);
  }, [hometownGroups.size, hometownGroupStatus]);

  const handleSave = (values) => {
    bulkUpdateHometownGroups(
      values.hometownGroups,
      currentChapter.getIn(['data', 'id']),
    );
  };

  const handleCancel = (reset) => {
    setSnackbarOpen(true);
    setSnackbarMessage('Changes reset.');
    setAutoHideDuration(2000);

    reset();
  };

  const schema = {};

  return (
    <Form id='hometownGroupsForm'
      onSubmit={ handleSave }
      mutators={ { ...arrayMutators } }
      initialValues={ initialValues.toJS() }
      initialValuesEqual={ deepEqual }
      keepDirtyOnReinitialize={ hometownGroupLoading }
      validate={ values => validate(values, schema) }
      render={ ({
        handleSubmit, invalid, values, form: { reset }, pristine,
      }) => (
        <Grid container spacing={ 8 } component='form' onSubmit={ handleSubmit }>

          <FieldArray name='hometownGroups'>
            {({ fields }) => (
              <DraggableList items={ hometownGroups }
                fields={ fields }
                listItem={ (
                  <HometownGroupsItem { ...{
                    currentChapter,
                    hometownGroups,
                    loading: hometownGroupLoading,
                    removeHometownGroup,
                    values:  Immutable.fromJS(values),
                  } } />
                  ) } />
            )}
          </FieldArray>

          <Savebar open={ !pristine }
            autoHideDuration={ autoHideDuration }
            form={ Map({ syncErrors: invalid, submitting: hometownGroupLoading }) }
            handleAccept={ handleSubmit }
            handleReject={ () => handleCancel(reset) }
            hideButtons={ hideButtons }
            message={ savebarMessage } />

          <Snackbar key='remove'
            anchorOrigin={ {
              vertical:   'bottom',
              horizontal: 'right',
            } }
            open={ snackbarOpen }
            autoHideDuration={ 3000 }
            onClose={ () => setSnackbarOpen(false) }
            message={ snackbarMessage } />
        </Grid>
      ) } />
  );
};

HometownGroupsForm.propTypes = {
  initialValues:                     PropTypes.instanceOf(Object).isRequired,
  currentChapter:                    PropTypes.instanceOf(Map).isRequired,
  hometownGroups:                    PropTypes.instanceOf(List).isRequired,
  removeHometownGroup:               PropTypes.func.isRequired,
  hometownGroupLoading:              PropTypes.bool.isRequired,
  bulkUpdateHometownGroups:          PropTypes.func.isRequired,
  hometownGroupsFormSubmitSucceeded: PropTypes.bool.isRequired,
  hometownGroupsFormSubmitFailed:    PropTypes.bool.isRequired,
  hometownGroupStatus:               PropTypes.string.isRequired,
};

export default HometownGroupsForm;
