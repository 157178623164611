import { connect } from 'react-redux';

// action creators
import { userFetchAll } from '../../../../actions/userActions';

// components
import InfoTab from './InfoTab';

// selectors
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';
import getFields from '../../../../lib/selectors/getFields';

const mapStateToProps = state => ({
  fields:        getFields(state),
  fieldsLoading: getLoadingFromState('field')(state),
  pnmsLoading:   getLoadingFromState('pnm')(state),
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: (params) => {
    dispatch(userFetchAll(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoTab);
