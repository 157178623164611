import { List, Map } from 'immutable';
import capitalize from '../lib/capitalize';

const getRoundTypeName = (roundType, organization) => {
  const roundCategories = organization.get('roundCategories', List());
  const currentRoundCategory = roundCategories.find(
    roundCategory =>
      roundCategory.get('roundType', '').toString() === roundType.toString(), {}, Map()
  );
  const scoreLabel = organization.getIn(['labels', 'score'], 'Score');

  switch (roundType) {
    case 0:
      return currentRoundCategory.get('label', 'Standard Round');
    case 1:
      return currentRoundCategory.get('label', 'Open House');
    case 2:
      return currentRoundCategory.get('label', 'Invitation');
    case 3:
      return currentRoundCategory.get('label', 'Priority');
    case 4:
      return currentRoundCategory.get('label', 'Bid Matching');
    case 5:
      return currentRoundCategory.get('label', 'Preference');
    case 6:
      return currentRoundCategory.get('label', `Pre-${capitalize(scoreLabel)}`);
    case 7:
      return currentRoundCategory.get('label', 'Special Pre-Score');
    case 8:
      return currentRoundCategory.get('label', 'Special Standard');
    default:
      return 'Standard Round';
  }
};

export default getRoundTypeName;
