import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import validate from 'validate.js';
import Immutable from 'immutable';

// action creators
import { updateBumpGroup } from '../../../../../../actions/bumpGroupActions';

import getUserSuggestions from '../../../../../../lib/selectors/getUserSuggestions';

// components
import BumpGroupsForm from './BumpGroupsForm';

const createInitialValues = (props) => {
  const { bumpGroups } = props;

  const values = bumpGroups.map(bg => Immutable.fromJS({
    _id: bg.get('_id'),
  }));

  return values;
};

const mapStateToProps = (state, props) => ({
  user:            state.get('user'),
  activeField:     state.getIn(['form', 'bumpGroupsForm', 'active'], ''),
  initialValues:   { bumpGroups: createInitialValues(props) },
  userSuggestions: getUserSuggestions(state),
});

const mapDispatch = {
  updateBumpGroup,
};

const handleChange = function (bumpGroups, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const validateForm = function (form) {
  return validate(form.toJS());
};

const wrappedBumpGroupsForm = reduxForm({
  form:               'bumpGroupsForm',
  validate:           validateForm,
  enableReinitialize: true,
  onChange:           handleChange,
})(BumpGroupsForm);

export default connect(mapStateToProps, mapDispatch)(wrappedBumpGroupsForm);
