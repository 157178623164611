import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import membershipConcernService from '../../sources/api/membershipConcernService';

// constants
import { MEMBERSHIP_CONCERN_CREATE, MEMBERSHIP_CONCERN_CREATE_INIT } from '../../constants/membershipConcernTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { membershipConcernSchema } from '../../schemas/membershipConcern';

export function* membershipConcernCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'membershipConcernForm';

  const values = action.payload;

  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(MEMBERSHIP_CONCERN_CREATE, call(create, membershipConcernService, values), {
      schema: membershipConcernSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(MEMBERSHIP_CONCERN_CREATE, err));
  } finally {
    yield put(doneIndicator(MEMBERSHIP_CONCERN_CREATE));
  }
}

export function* watchmembershipConcernCreate() {
  yield takeEvery(MEMBERSHIP_CONCERN_CREATE_INIT, membershipConcernCreate);
}

export default function* root() {
  yield all([
    fork(watchmembershipConcernCreate),
  ]);
}
