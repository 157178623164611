import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable, { Map } from 'immutable';

// MUI components
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popover from '@material-ui/core/Popover';

class CellOptionsMenu extends Component {
  static propTypes = {
    matchLoading:     PropTypes.bool.isRequired, // eslint-disable-line
    pnmLoading:       PropTypes.bool.isRequired,
    pnms:             PropTypes.instanceOf(Immutable.List).isRequired,
    round:            PropTypes.instanceOf(Map).isRequired,
    user:             PropTypes.instanceOf(Map).isRequired,
    event:            PropTypes.number.isRequired, // eslint-disable-line
    currentChapterId: PropTypes.number.isRequired,
    fetchAllPnms:     PropTypes.func.isRequired, // eslint-disable-line
    createMatch:      PropTypes.func.isRequired,
    title:            PropTypes.string,
  }

  static defaultProps = {
    title: 'Options',
  }

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.fromJS({
        menuOpen:       false,
        itemsFetched:   false,
        rowOptionsOpen: false,
        anchorEl:       null,
      }),
    };

    this.handleOpenMenu = this.handleOpenMenu.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {
      pnmLoading,
      currentChapterId, event, round, fetchAllPnms,
    } = nextProps;

    const menuOpen = this.state.data.get('menuOpen');
    const itemsFetched = this.state.data.get('itemsFetched');

    if (menuOpen && !pnmLoading && !itemsFetched) {
      fetchAllPnms({
        search:             '',
        sort:               {},
        chapter:            currentChapterId,
        event,
        round:              round.get('_id'),
        filter:             { unmatchedPnms: true },
        activeNotRecruited: true,
      });

      this.setState({ data: this.state.data.set('itemsFetched', true) });
    }
  }

  handleClick = () => {
    this.handleCloseMenu();
  }

  handleCreateMatch = (pnm) => {
    const {
      currentChapterId, createMatch, round, user,
    } = this.props;

    createMatch({
      chapter: currentChapterId,
      pnm:     pnm.get('id'),
      round:   round.get('_id'),
      user:    user.get('id'),
    });
  }

  handleOpenMenu = (event) => {
    event.preventDefault();

    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('menuOpen', true);
        map.set('anchorEl', event.currentTarget);
      }),
    });
  }

  handleCloseMenu = () => {
    this.setState({
      data: this.state.data.withMutations((map) => {
        map.set('menuOpen', false);
        map.set('anchorEl', null);
      }),
    });
  }

  renderPnmOptions = () => {
    const { pnms } = this.props;

    let element;

    if (pnms.size) {
      element = pnms.map(pnm => (
        <ListItem button key={ pnm.get('id') } onClick={ () => this.handleCreateMatch(pnm) }>
          <ListItemText primary={ `${pnm.get('firstname')} ${pnm.get('lastname')}` } />
        </ListItem>
      ));
    } else {
      return (
        <ListItem>
          <ListItemText>No Results Found</ListItemText>
        </ListItem>
      );
    }

    return element;
  }

  render() {
    const {
      pnmLoading,
      title,
    } = this.props;

    const open = this.state.data.get('menuOpen');
    const anchorEl = this.state.data.get('anchorEl');

    return (
      <div>
        <IconButton onClick={ event => this.handleOpenMenu(event) }>
          <Icon>add</Icon>
        </IconButton>

        <Popover open={ open }
          anchorEl={ anchorEl }
          anchorOrigin={ {
            vertical:   'center',
            horizontal: 'left',
          } }
          transformOrigin={ {
            vertical:   'top',
            horizontal: 'right',
          } }
          onClose={ this.handleCloseMenu }>
          <List subheader={
            <ListSubheader component='div'>{ title }</ListSubheader>
          }>
            { pnmLoading
              ? (
                <ListItem>
                  <CircularProgress />
                </ListItem>
              )
              : this.renderPnmOptions()}

          </List>
        </Popover>
      </div>
    );
  }
}

export default CellOptionsMenu;
