import { connect } from 'react-redux';

// action creators
import { push } from 'react-router-redux';

// components
import HeaderMenu from './HeaderMenu';

const mapStateToProps = state => ({
  router:         state.get('router'),
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
});

const mapDispatchToProps = dispatch => ({
  navigate: (route) => {
    dispatch(push(route));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
