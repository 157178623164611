import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { patch } from '../../sources/api/client';
import noteService from '../../sources/api/noteService';

// constants
import { NOTE_DELETE, NOTE_DELETE_INIT } from '../../constants/noteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { noteSchema } from '../../schemas/note';

export function* noteDelete(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id, chapter, modeToClear } = action.payload || {};

  const values = {
    chapter,
    removed: true,
    modeToClear,
  };

  try {
    yield* httpSaga(NOTE_DELETE, call(patch, noteService, id, { ...values }), {
      schema: noteSchema,
    });
  } catch (err) {
    yield put(error(NOTE_DELETE, err));
  } finally {
    yield put(doneIndicator(NOTE_DELETE));
  }
}

export function* watchNoteDelete() {
  yield takeEvery(NOTE_DELETE_INIT, noteDelete);
}

export default function* root() {
  yield all([
    fork(watchNoteDelete),
  ]);
}
