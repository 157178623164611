import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { remove } from '../../sources/api/client';
import userSpecialtyService from '../../sources/api/userSpecialtyService';

// constants
import { USER_SPECIALTY_REMOVE, USER_SPECIALTY_REMOVE_INIT } from '../../constants/userSpecialtyTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSpecialtySchema } from '../../schemas/userSpecialty';

export function* userSpecialtyRemove(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id, user } = action.payload || {};

  const options = {
    query: {
      user,
    },
  };

  try {
    yield* httpSaga(USER_SPECIALTY_REMOVE, call(remove, userSpecialtyService, id, options), {
      schema: userSpecialtySchema,
    });
  } catch (err) {
    yield put(error(USER_SPECIALTY_REMOVE, err));
  } finally {
    yield put(doneIndicator(USER_SPECIALTY_REMOVE));
  }
}

export function* watchInviteRemove() {
  yield takeEvery(USER_SPECIALTY_REMOVE_INIT, userSpecialtyRemove);
}

export default function* root() {
  yield all([
    fork(watchInviteRemove),
  ]);
}
