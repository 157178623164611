import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import voteService from '../../sources/api/voteService';

// constants
import { VOTE_BULK_CREATE, VOTE_BULK_CREATE_INIT } from '../../constants/voteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { voteSchema } from '../../schemas/vote';

export function* voteBulkCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'tagPnmForm';

  const values = action.payload;

  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(VOTE_BULK_CREATE, call(create, voteService, values), {
      schema: voteSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(VOTE_BULK_CREATE, err));
  } finally {
    yield put(doneIndicator(VOTE_BULK_CREATE));
  }
}

export function* watchVoteBulkCreate() {
  yield takeEvery(VOTE_BULK_CREATE_INIT, voteBulkCreate);
}

export default function* root() {
  yield all([
    fork(watchVoteBulkCreate),
  ]);
}
