import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

// components
import CommentList from '../../../CommentList';

class VotesTab extends Component {
  static propTypes = {
    classes:          PropTypes.instanceOf(Object).isRequired,
    clearVotes:       PropTypes.func.isRequired,
    currentChapter:   PropTypes.instanceOf(Map).isRequired,
    deleteVote:       PropTypes.func.isRequired,
    fetchVotesForPnm: PropTypes.func.isRequired, // eslint-disable-line
    match:            PropTypes.instanceOf(Object).isRequired,
    vote:             PropTypes.instanceOf(Map).isRequired,
    votes:            PropTypes.instanceOf(List).isRequired,
  }

  componentWillMount() {
    const { currentChapter, clearVotes } = this.props;
    if (currentChapter.get('data')) {
      clearVotes();
      this.initVotes(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: { params: { id } }, clearVotes,
    } = this.props;
    const newId = nextProps.match.params.id;
    const { currentChapter } = nextProps;

    // Checks if PNM has changed, and pulls latest info for them if so
    if (id !== newId && currentChapter.get('data')) {
      clearVotes();
      this.initVotes(nextProps);
    }
  }

  initVotes = (props) => {
    const { currentChapter, fetchVotesForPnm, match: { params: { id } } } = props;

    fetchVotesForPnm({
      pnm:     id,
      chapter: currentChapter.getIn(['data', 'id'], 0),
    });
  }

  handleDelete = (voteId) => {
    const { currentChapter, deleteVote } = this.props;

    deleteVote({
      id:      voteId,
      chapter: currentChapter.getIn(['data', 'id'], 0),
    });
  }

  render() {
    const {
      classes, vote, votes,
    } = this.props;

    // fixes issue where first element in votes array is undefined
    const filteredVotes = votes.filter(v => typeof v !== 'undefined');
    const loading = (!vote.get('data') && vote.get('loading')) || false;

    return (
      <div>
        { filteredVotes.size > 0 || loading ? (
          <CommentList type='votes'
            items={ votes }
            loading={ loading }
            onDelete={ this.handleDelete } />
        ) : (
          <div>
            <ListItem className={ classes.title }>
              <Typography color='secondary' align='center' variant='h6'>No Votes Found</Typography>
            </ListItem>
            <ListItem>
              <Avatar className={ classes.avatar }>
                <Icon className={ classes.avatarIcon }>thumbs_up_down</Icon>
              </Avatar>
            </ListItem>
            <ListItem className={ classes.title }>
              <Typography color='secondary' align='center'>Cast your first vote for this PNM!</Typography>
            </ListItem>
          </div>
        ) }
      </div>
    );
  }
}

const styles = theme => ({
  title: {
    display: 'block',
    margin:  'auto',
  },

  avatar: {
    width:           125,
    height:          125,
    margin:          'auto auto 15px auto',
    backgroundColor: theme.palette.secondary.main,
  },

  avatarIcon: {
    fontSize: 50,
  },
});

export default withStyles(styles)(VotesTab);
