import { List, Map } from 'immutable';
import { denormalize } from 'normalizr';

import { userListSchema } from '../../schemas/user';

export default function getUserSuggestions(state) {
  const chapter = state.get('chapter');
  const user = state.get('user');
  const suggestions = [];

  const userData = user.get('data') || Map();
  const result = userData.get('result', List()).toJS();

  if (userData.get('result')) {
    const users = user.getIn(['data', 'items'], Map());
    const chapters = chapter.getIn(['data', 'items'], Map());

    const entities = {
      user:    users.toJS(),
      chapter: chapters.toJS(),
    };

    const items = denormalize(result, userListSchema, entities);

    items.forEach((item) => {
      suggestions.push({
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      });
    });
  }

  return suggestions;
}
