import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

class EnhancedTableHead extends React.Component {
  static propTypes = {
    numCols:        PropTypes.number.isRequired,
    onSortChange:   PropTypes.func.isRequired,
    order:          PropTypes.number.isRequired,
    sortBy:         PropTypes.string.isRequired,
    clearanceLevel: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Immutable.fromJS({
        columns: [
          {
            id:        'name',
            align:     'right',
            label:     'Chapter',
            allowSort: true,
          },
          {
            id:        'school',
            align:     'right',
            label:     'School',
            allowSort: true,
          },
          {
            id:        'date_created',
            align:     'right',
            label:     'Date Created',
            allowSort: true,
          },
        ],

        hqAdminColumns: [
          {
            id:    'mode',
            align: 'right',
            label: 'Mode',
          },
          {
            id:        'name',
            align:     'right',
            label:     'Chapter',
            allowSort: true,
          },
          {
            id:        'school',
            align:     'right',
            label:     'School',
            allowSort: true,
          },
          {
            id:    'isCdSite',
            align: 'right',
            label: 'CD Site',
          },
          {
            id:    'userCount',
            align: 'right',
            label: 'Users',
          },
          {
            id:        'date_created',
            align:     'right',
            label:     'Date Created',
            allowSort: true,
          },
          {
            id:    'lastActive',
            align: 'right',
            label: 'Last Active',
          },
        ],

        tpAdminColumns: [
          {
            id:    'mode',
            align: 'right',
            label: 'Mode',
          },
          {
            id:    'id',
            align: 'right',
            label: 'Id',
          },
          {
            id:    'org',
            align: 'left',
            label: 'Organization',
          },
          {
            id:        'name',
            align:     'left',
            label:     'Chapter',
            allowSort: true,
          },
          {
            id:        'school',
            align:     'left',
            label:     'School',
            allowSort: true,
          },
          {
            id:    'isCdSite',
            align: 'left',
            label: 'CD Site',
          },
          {
            id:    'userCount',
            align: 'right',
            label: 'Users',
          },
          {
            id:        'date_created',
            align:     'left',
            label:     'Date Created',
            allowSort: true,
          },
          {
            id:    'lastActive',
            align: 'left',
            label: 'Last Active',
          },
          {
            id:    'lastCleared',
            align: 'left',
            label: 'Last Standard Cleared',
          },
          {
            id:    'lastCobCleared',
            align: 'left',
            label: 'Last COB Cleared',
          },
          {
            id:    'options',
            align: 'center',
            label: 'Options',
          },
        ],
      }),
    };
  }

  getPadding = (column) => {
    let padding;
    padding = column.get('align') === 'right' ? 'checkbox' : 'dense';

    if (column.get('id') === 'userCount' || column.get('id') === 'cdSite') {
      padding = 'none';
    }
    return padding;
  }

  getOrder = () => {
    const { order } = this.props;

    let label = 'asc';

    if (order === -1) {
      label = 'desc';
    }

    return label;
  }

  createSortHandler = property => () => {
    this.props.onSortChange(property);
  }

  renderColumnHeader = (column) => {
    const { sortBy, clearanceLevel } = this.props;

    let element = column.get('label');

    if (column.get('allowSort') && clearanceLevel !== 0) {
      element = (
        <Tooltip title='Sort'
          placement={ column.get('numeric') ? 'bottom-end' : 'bottom-start' }
          enterDelay={ 300 }>
          <TableSortLabel active={ sortBy === column.get('id') }
            direction={ this.getOrder() }
            onClick={ this.createSortHandler(column.get('id')) }>
            {column.get('label')}
          </TableSortLabel>
        </Tooltip>
      );
    }

    return element;
  }

  render() {
    const {
      numCols, sortBy, clearanceLevel,
    } = this.props;

    let columns;

    if (clearanceLevel === 2) {
      columns = this.state.data.get('tpAdminColumns');
    } else if (clearanceLevel === 1) {
      columns = this.state.data.get('hqAdminColumns');
    } else {
      columns = this.state.data.get('columns');
    }

    return (
      <TableHead>
        <TableRow colSpan={ numCols }>
          {columns.map(column => (
            <TableCell key={ column.get('id') }
              align={ column.get('align') }
              padding={ this.getPadding(column) }
              sortDirection={ sortBy === column.get('id') ? this.getOrder() : false }>

              { this.renderColumnHeader(column) }
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
