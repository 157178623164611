import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { get } from '../../sources/api/client';
import inviteService from '../../sources/api/inviteService';

// constants
import { INVITE_FETCH, INVITE_FETCH_INIT } from '../../constants/inviteTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { inviteSchema } from '../../schemas/invite';

export function* inviteFetch(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { id } = action.payload || {};

  try {
    yield* httpSaga(INVITE_FETCH, call(get, inviteService, id), {
      schema: inviteSchema,
    });
  } catch (err) {
    yield put(error(INVITE_FETCH, err));
  } finally {
    yield put(doneIndicator(INVITE_FETCH));
  }
}

export function* watchInviteFetch() {
  yield takeEvery(INVITE_FETCH_INIT, inviteFetch);
}

export default function* root() {
  yield all([
    fork(watchInviteFetch),
  ]);
}
