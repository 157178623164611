import { TAG_CREATE_INIT, TAG_REMOVE_INIT, TAG_BULK_UPDATE_INIT } from '../constants/tagTypes';

export function tagCreate(params) {
  return {
    type:    TAG_CREATE_INIT,
    payload: params,
  };
}

export function tagRemove(id, chapter) {
  return {
    type:    TAG_REMOVE_INIT,
    payload: { id, chapter },
  };
}

export function tagBulkUpdate(items, chapter) {
  return {
    type:    TAG_BULK_UPDATE_INIT,
    payload: {
      items,
      chapter,
    },
  };
}
