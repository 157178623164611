import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactAutoSuggest from 'react-autosuggest';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

const AutoSuggestInput = function ({
  onSelectCallback,
  classes,
  circularProgressProps,
  defaultSuggestion = {},
  formValue,
  handleClearSelected: handleClearSelectedFromProps,
  handleSelectAll,
  helperText,
  input: {
    onChange, onBlur, onFocus, value, ...restOfInput
  },
  label,
  loading,
  multiSelect,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  placeholder,
  suggestions,
  suggestionsCardType,
  variant,
  ...restOfProps
}) {
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (defaultSuggestion.label && defaultSuggestion.value) {
      setDisplayValue(defaultSuggestion.label);
      onChange(defaultSuggestion);
    }
  }, [defaultSuggestion, onChange]);

  const getSuggestionValue = () => label;

  const handleChange = (event, { newValue, method }) => {
    if (method === 'type') {
      setDisplayValue(newValue);
    }
  };

  const renderLoadingIndicator = () => (
    <CircularProgress className={ classes.loadingIndicator }
      size={ 20 }
      { ...circularProgressProps } />
  );

  const handleBlur = (event) => {
    // added this condition because the event target was coming across null at first
    if (event.target && event.target.getAttribute('type') === 'text') {
      const newEvent = event;
      newEvent.target.value = formValue;

      const newLabel = multiSelect
        ? (formValue[formValue.length] || {}).label
        : formValue.label;

      if (newLabel) {
        setDisplayValue(newLabel);
      }
    }
  };

  const handleFocus = (...params) => {
    onFocus(...params);
  };

  const handleClearSelected = () => {
    setDisplayValue('');

    if (!multiSelect) {
      onChange({});
    }
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    // Don't allow error message results to be selected
    if (suggestion.value === 'noneFound') {
      return;
    }

    if (onSelectCallback) {
      onSelectCallback(suggestion);
    }
    setDisplayValue(suggestion.label);

    if (multiSelect) {
      // Don't add item to form if it's already in there
      const alreadySelected = formValue.find(v => v.value === suggestion.value);
      if (!alreadySelected) {
        formValue.push(suggestion);
        onChange(formValue);
      }
    } else {
      onChange(suggestion);
    }
  };

  const renderSuggestion = ({ label }, { isHighlighted }) => ( // eslint-disable-line
    <MenuItem id='menuItem' selected={ isHighlighted } component='div'>
      <Typography>{label}</Typography>
    </MenuItem>
  );

  const renderInput = (inputProps) => {
    const {
      label, helperText, ref, variant, ...restOfInput // eslint-disable-line
    } = inputProps;

    restOfProps.InputProps = { // eslint-disable-line
      startAdornment: (
        <InputAdornment position='start'>
          <Icon>search</Icon>
        </InputAdornment>
      ),
      endAdornment: loading ? (
        renderLoadingIndicator()
      ) : (
        <InputAdornment position='end'>
          <Zoom in={ Boolean(value) && Boolean(displayValue) }>
            <IconButton onClick={ () => handleClearSelected() }>
              <Icon>clear</Icon>
            </IconButton>
          </Zoom>
        </InputAdornment>
      ),
      ...restOfProps.InputProps,
    };

    return (
      <FormControl className={ classes.formControl }>
        <TextField placeholder={ placeholder }
          label={ label }
          margin='normal'
          variant={ variant }
          helperText={ helperText }
          inputRef={ ref }
          InputProps={ restOfProps.InputProps }
          { ...restOfInput }
          { ...restOfProps } />
      </FormControl>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <Paper classes={ { root: classes.suggestionsCardClosed } }
      { ...containerProps }>
      {children}
    </Paper>
  );

  const theme = {
    container:            classes.container,
    suggestionsContainer:
      suggestionsCardType === 'popup'
        ? classes.suggestionsCardPopup
        : classes.suggestionsCardExpand,
    suggestionsList: classes.suggestionsList,
    suggestion:      classes.suggestion,
  };

  const inputProps = {
    helperText,
    label,
    onBlur:   handleBlur,
    onChange: handleChange,
    onFocus:  handleFocus,
    value:    displayValue,
    variant,
    ...restOfInput,
  };

  const newSuggestions = suggestions.length
    ? suggestions
    : [{ label: 'No results found.', value: 'noneFound' }];

  return (
    <div className={ classes.autoSuggestContainer }>
      <ReactAutoSuggest suggestions={ newSuggestions }
        focusInputOnSuggestionClick={ false }
        theme={ theme }
        onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
        onSuggestionsClearRequested={ onSuggestionsClearRequested }
        getSuggestionValue={ getSuggestionValue }
        renderSuggestion={ renderSuggestion }
        onSuggestionSelected={ handleSuggestionSelected }
        inputProps={ inputProps }
        renderInputComponent={ renderInput }
        renderSuggestionsContainer={ renderSuggestionsContainer } />
    </div>
  );
};

AutoSuggestInput.propTypes = {
  classes:               PropTypes.instanceOf(Object).isRequired,
  circularProgressProps: PropTypes.instanceOf(Object),
  children:              PropTypes.instanceOf(Array),
  containerProps:        PropTypes.instanceOf(Object),
  defaultSuggestion:     PropTypes.instanceOf(Object),
  formValue:             PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
  ]).isRequired,
  handleClearSelected:         PropTypes.func,
  handleSelectAll:             PropTypes.func,
  helperText:                  PropTypes.string,
  input:                       PropTypes.instanceOf(Object).isRequired,
  label:                       PropTypes.string,
  loading:                     PropTypes.bool,
  multiSelect:                 PropTypes.bool,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  onSelectCallback:            PropTypes.func.isRequired,
  placeholder:                 PropTypes.string,
  suggestions:                 PropTypes.instanceOf(Array).isRequired,
  suggestionsCardType:         PropTypes.oneOf(['expand', 'popup']),
  variant:                     PropTypes.string,
};

AutoSuggestInput.defaultProps = {
  circularProgressProps: {},
  defaultSuggestion:     null,
  helperText:            '',
  label:                 '',
  loading:               false,
  multiSelect:           false,
  placeholder:           '',
  suggestionsCardType:   'popup',
  variant:               'standard',
  handleClearSelected:   () => {},
  handleSelectAll:       () => {},
  containerProps:        {},
  children:              [],
};

const styles = theme => ({
  autoSuggestContainer: {
    display:    'flex',
    alignItems: 'center',
  },

  loadingIndicator: {
    position: 'absolute',
    right:    25,
  },

  container: {
    flexGrow: 1,
    position: 'relative',
  },

  formControl: {
    width: '100%',
  },

  suggestionsCardPopup: {
    position:     'absolute',
    marginTop:    theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 3,
    left:         0,
    right:        0,
    zIndex:       100,
  },

  suggestionsCardExpand: {
    position:     'relative',
    marginTop:    theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 3,
    left:         0,
    right:        0,
    zIndex:       100,
    transition:   'all 0.4s ease',
  },

  suggestionsCardClosed: {
    // transition: 'all 0.4s ease',
    backgroundColor: theme.palette.background.default,
  },

  suggestion: {
    display: 'block',
  },

  suggestionsList: {
    margin:        0,
    padding:       0,
    listStyleType: 'none',
  },
});

export default withStyles(styles)(AutoSuggestInput);
