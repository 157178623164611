import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import userSpecialtyService from '../../sources/api/userSpecialtyService';

// constants
import { USER_SPECIALTY_BULK_FETCH, USER_SPECIALTY_BULK_FETCH_INIT } from '../../constants/userSpecialtyTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSpecialtyListSchema } from '../../schemas/userSpecialty';

export function* userSpecialtyBulkFetch(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { userIds } = action.payload || {};

  const options = {
    query: {
      userIds: userIds.join(','),

      $sort: {
        createdAt: -1,
      },
    },
  };

  try {
    yield* httpSaga(USER_SPECIALTY_BULK_FETCH, call(find, userSpecialtyService, options), {
      schema: userSpecialtyListSchema,
    });
  } catch (err) {
    yield put(error(USER_SPECIALTY_BULK_FETCH, err));
  } finally {
    yield put(doneIndicator(USER_SPECIALTY_BULK_FETCH));
  }
}

export function* watchUserSpecialtyBulkFetch() {
  yield takeEvery(USER_SPECIALTY_BULK_FETCH_INIT, userSpecialtyBulkFetch);
}

export default function* root() {
  yield all([
    fork(watchUserSpecialtyBulkFetch),
  ]);
}
