import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';

// api
import { create } from '../../sources/api/client';
import matchService from '../../sources/api/matchService';

// constants
import { MATCH_AUTO, MATCH_AUTO_INIT } from '../../constants/matchTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { matchSchema } from '../../schemas/match';

export function* matchAuto(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'autoMatchForm';
  const {
    chapter,
    checkPreviousMatches,
    checkVotedOn,
    excludedUsers,
    matchesPerMemberEvent,
    selectedEvent,
    selectedHometown,
    selectedItems,
    selectedPnms,
    selectedRound,
    weights,
  } = action.payload;

  const options = {
    query: {
      chapter,
      checkPreviousMatches,
      checkVotedOn,
      excludedUsers,
      matchesPerMemberEvent,
      selectedEvent,
      selectedHometown,
      selectedItems,
      selectedPnms,
      selectedRound,
      shouldAutoMatch: true,
      weights,
    },
  };

  yield put(startSubmit(FORM));

  try {
    yield* httpSaga(MATCH_AUTO, call(create, matchService, {}, options), {
      schema: matchSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(MATCH_AUTO, err));
  } finally {
    yield put(doneIndicator(MATCH_AUTO));
  }
}

export function* watchMatchAuto() {
  yield takeEvery(MATCH_AUTO_INIT, matchAuto);
}

export default function* root() {
  yield all([
    fork(watchMatchAuto),
  ]);
}
