import { connect } from 'react-redux';
import { Map } from 'immutable';

import { tagCreate, tagRemove } from '../../../../actions/tagActions';
import getTags from '../../../../lib/selectors/getTags';
import getLoadingFromState from '../../../../lib/selectors/getLoadingFromState';

// components
import Tags from './Tags';

const mapStateToProps = state => ({
  currentChapter: state.get('currentChapter'),
  currentUser:    state.get('currentUser'),
  tags:           getTags(state),
  tagLoading:     getLoadingFromState('tag')(state),
  tagsForm:       state.getIn(['form', 'tagsForm'], Map()),
});

const mapDispatchToProps = dispatch => ({
  createTag: (params) => {
    dispatch(tagCreate(params));
  },
  removeTag: (id, chapter) => {
    dispatch(tagRemove(id, chapter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
