import {
  all, call, fork, put, takeEvery, select,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { get } from '../../sources/api/client';
import chapterService from '../../sources/api/chapterService';

// constants
import {
  CURRENT_CHAPTER_FETCH_FOR_VOTES_INIT,
  CURRENT_CHAPTER_FETCH_FOR_VOTES,
} from '../../constants/chapterTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { chapterSchema } from '../../schemas/chapter';

export function* currentChapterFetchVotes(action) {
  // Because fetching votes is expensive, traversing db for every vote for every round for chapter
  // we've created a distinct call to be used only in settings -> rounds to delete rounds.
  // In mobile, loading chapters happens frequently, so we don't want to always include votes.
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const chapterId =   yield select(state => state.getIn(['currentChapter', 'data', 'id']));

  const options = {
    query: {
      includeRoundHasVotes: true,
    },
  };

  if (chapterId) {
    try {
      yield* httpSaga(CURRENT_CHAPTER_FETCH_FOR_VOTES, call(
        get, chapterService, chapterId, options
      ), {
        schema: chapterSchema,
      });
    } catch (err) {
      yield put(error(CURRENT_CHAPTER_FETCH_FOR_VOTES, err));
    } finally {
      yield put(doneIndicator(CURRENT_CHAPTER_FETCH_FOR_VOTES));
    }
  }
}

export function* watchCurrentChapterFetch() {
  yield takeEvery(CURRENT_CHAPTER_FETCH_FOR_VOTES_INIT, currentChapterFetchVotes);
}
export default function* root() {
  yield all([
    fork(watchCurrentChapterFetch),
  ]);
}
