import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';

// api
import { find } from '../../sources/api/client';
import locationSchoolService from '../../sources/api/locationSchoolService';

// constants
import { LOCATION_SCHOOL_FETCH_ALL_INIT, LOCATION_SCHOOL_FETCH_ALL } from '../../constants/locationSchoolTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { locationSchoolListSchema } from '../../schemas/locationSchool';

export function* schoolFetchAll(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const { search } = action.payload || {};

  const options = {
    query: {
      $search: search,
    },
  };

  try {
    yield* httpSaga(LOCATION_SCHOOL_FETCH_ALL, call(find, locationSchoolService, options), {
      schema: locationSchoolListSchema,
    });
  } catch (err) {
    yield put(error(LOCATION_SCHOOL_FETCH_ALL, err));
  } finally {
    yield put(doneIndicator(LOCATION_SCHOOL_FETCH_ALL));
  }
}

export function* watchOrgFetchAll() {
  yield takeLatest(LOCATION_SCHOOL_FETCH_ALL_INIT, schoolFetchAll);
}

export default function* root() {
  yield all([
    fork(watchOrgFetchAll),
  ]);
}
