import PropTypes from 'prop-types';
import Immutable, { List, Map } from 'immutable';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// local components
import Button from '../../../../../Button';
import Autosuggest from '../../../../../Autosuggest';
import ActionModal from '../../../../../ActionModal';

class BumpGroupItem extends Component {
    static propTypes = {
      activeField:          PropTypes.string.isRequired,
      bumpGroup:            PropTypes.instanceOf(Map).isRequired,
      bumpGroups:           PropTypes.instanceOf(Immutable.List).isRequired,
      classes:              PropTypes.instanceOf(Object).isRequired,
      currentChapterId:     PropTypes.number.isRequired,
      field:                PropTypes.string.isRequired,
      formValues:           PropTypes.instanceOf(List).isRequired,
      handleSnackbarOpen:   PropTypes.func.isRequired,
      index:                PropTypes.number.isRequired,
      removeBumpGroup:      PropTypes.func.isRequired,
      updateBumpGroup:      PropTypes.func.isRequired,
      user:                 PropTypes.instanceOf(Map).isRequired,
      userSuggestions:      PropTypes.instanceOf(Array).isRequired,
      fetchUsersForChapter: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        data: Immutable.fromJS({
          deleteModalOpen: false,
        }),
      };
    }

    getRoleLabel = (user) => {
      const teamLevel = user.get('team_level');
      if (teamLevel === 0) {
        if (user.get('role') === 'CONCERN_CHAIR') {
          return 'Concern Chair';
        }
        return 'Voting Member';
      } if (teamLevel === 1) {
        return 'Recruitment Team';
      } if (teamLevel === 2) {
        return 'Technology Team';
      }
      return 'Voting Member';
    }

    handleUpdateSuggestions = ({ value }) => {
      const { currentChapterId, fetchUsersForChapter } = this.props;

      fetchUsersForChapter({
        search:  value,
        chapter: currentChapterId,
      });
    }

    handleClearSuggestions = () => { }

    handleOpen = () => {
      this.setState({ data: this.state.data.set('deleteModalOpen', true) });
    };

    handleClose = () => {
      this.setState({ data: this.state.data.set('deleteModalOpen', false) });
    };

    handleDelete = (index) => {
      const {
        currentChapterId, handleSnackbarOpen, bumpGroup, bumpGroups, removeBumpGroup,
      } = this.props;
      const bumpGroupId = bumpGroups.get(index).get('_id');

      removeBumpGroup({ id: bumpGroupId, chapter: currentChapterId });

      const status = bumpGroup.get('status');
      handleSnackbarOpen(status);
    }

    handleAddMember = () => {
      const {
        bumpGroups,
        index,
        formValues,
        currentChapterId,
        updateBumpGroup,
      } = this.props;

      const searchItem = formValues.getIn([index, 'search'], {});
      const newUserId = searchItem.value;

      const oldItem = bumpGroups.get(index, Map());

      // Make payload contain only user IDs and add new user ID
      const oldUserIds = oldItem.get('users', List()).map(u => u.get('id'));

      // Prevents user from being added to group multiple times
      if (newUserId && !oldUserIds.includes(newUserId)) {
        const newItem = oldItem.set('users', oldUserIds.push(newUserId));

        updateBumpGroup({
          chapter:            currentChapterId,
          id:                 newItem.get('_id'),
          item:               newItem.toJS(),
          shouldPopulateUser: true,
        });
      }
    }

    handleDeleteMember = (userToDelete) => {
      const {
        bumpGroups,
        index,
        currentChapterId,
        updateBumpGroup,
      } = this.props;

      const oldItem = bumpGroups.get(index, Map());

      // // Make payload contain only user IDs and remove selected user ID
      const oldUserIds = oldItem.get('users', List()).map(u => u.get('id'));
      const indexToDelete = oldUserIds.indexOf(userToDelete.get('id'));

      const newItem = oldItem.set('users', oldUserIds.splice(indexToDelete, 1));

      updateBumpGroup({
        chapter:            currentChapterId,
        id:                 newItem.get('_id'),
        item:               newItem.toJS(),
        shouldPopulateUser: true,
      });
    }

    renderMembersList = () => {
      const { bumpGroups, index } = this.props;

      const bumpGroupItem = bumpGroups.get(index);
      const bumpGroupUsers = bumpGroupItem.get('users', List());

      if (!bumpGroupUsers.size) {
        return (
          <Grid item xs={ 12 }>
            <Typography color='secondary' variant='subtitle2'>
              No members assigned.
            </Typography>
          </Grid>
        );
      }

      return bumpGroupUsers.map(bgUser => [
        <Grid item xs={ 1 } key='avatar'>
          <Avatar>
            { `${bgUser.get('firstname', '').charAt(0)}${bgUser.get('lastname', '').charAt(0)}` }
          </Avatar>
        </Grid>,

        <Grid item xs={ 4 } key='name'>
          <Grid container alignItems='center'>
            <Grid item xs={ 12 }>
              <Typography variant='subtitle2'>
                {`${bgUser.get('firstname')} ${bgUser.get('lastname')}`}
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography variant='body2' color='textSecondary'>
                { this.getRoleLabel(bgUser) }
              </Typography>
            </Grid>
          </Grid>
        </Grid>,

        <Grid item xs={ 6 } key='stats'>
          <Grid container spacing={ 16 }>
            <Grid item xs={ 1 }>
              <Tooltip title='Match Recommendations'>
                <Icon color='action'>
                  extension
                </Icon>
              </Tooltip>
            </Grid>

            <Grid item xs={ 1 }>
              <Typography color='textSecondary'
                variant='subtitle1'>
                { bgUser.get('matchRecs') || 0 }
              </Typography>
            </Grid>

            <Grid item xs={ 1 }>
              <Tooltip title='Official Matches'>
                <Icon color='action'>
                  check_circle
                </Icon>
              </Tooltip>
            </Grid>

            <Grid item xs={ 1 }>
              <Typography color='textSecondary'
                variant='subtitle1'>
                { bgUser.get('officialMatches') || 0 }
              </Typography>
            </Grid>
          </Grid>
        </Grid>,

        <Grid item xs={ 1 } key='delete'>
          <IconButton onClick={ () => this.handleDeleteMember(bgUser) }>
            <Icon>delete</Icon>
          </IconButton>
        </Grid>,
      ]);
    }

    render() {
      const {
        activeField,
        bumpGroup,
        bumpGroups,
        classes,
        field,
        formValues,
        index,
        user,
        userSuggestions,
      } = this.props;
      const deleteModalOpen = this.state.data.get('deleteModalOpen');

      const isActive = activeField.includes(index.toString());
      const loading = user.get('loading') && isActive;
      const disabled = !formValues.getIn([index, 'search'], false);

      const bumpGroupItem = bumpGroups.get(index);

      return (
        <Grid container
          spacing={ 40 }
          justify='flex-start'
          alignItems='center'
          className={ classes.bumpGroupItemContainer }>

          <Grid item xs={ 2 }>
            <Avatar color='primary' className={ classes.groupAvatar }>{ bumpGroupItem.get('order') }</Avatar>
          </Grid>

          <Grid item xs={ 10 } sm={ 5 } align='left'>
            <Field name={ `${field}.search` }
              label='Add New Member'
              variant='outlined'
              component={ Autosuggest }
              loading={ loading }
              suggestions={ userSuggestions }
              onSuggestionsFetchRequested={ this.handleUpdateSuggestions }
              onSuggestionsClearRequested={ this.handleClearSuggestions } />
          </Grid>

          <Grid item xs={ 6 } sm={ 3 }>
            <Button variant='outlined'
              color='primary'
              disabled={ disabled }
              loading={ bumpGroup.get('loading', false) }
              onClick={ this.handleAddMember }>
              Add To Group
            </Button>
          </Grid>

          <Grid item xs={ 6 } sm={ 2 } align='center'>
            <IconButton onClick={ this.handleOpen }>
              <Icon>delete</Icon>
            </IconButton>

            <Hidden smUp>
              <Divider className={ classes.mobileDivider } />
            </Hidden>
          </Grid>

          <Grid item xs={ 12 } className={ classes.membersContainer }>
            <Grid container alignItems='center' spacing={ 16 }>
              <Grid item xs={ 12 }>
                <Typography variant='subtitle1'>Group Members</Typography>
              </Grid>

              { this.renderMembersList() }
            </Grid>
          </Grid>

          <ActionModal open={ deleteModalOpen }
            description='This bump group will disappear and all of its members will become unassigned.'
            onAccept={ () => this.handleDelete(index) }
            onReject={ this.handleClose }
            acceptLabel='Delete'
            rejectLabel='Cancel'
            loading={ bumpGroup.get('loading', false) }
            title='Are you sure you want to delete this bump group?' />
        </Grid>
      );
    }
}
const styles = theme => ({
  bumpGroupItemContainer: {
    borderRadius: 10,
    border:       '1px solid #bfbfbf',
    margin:       15,
  },

  bumpGroupIcon: {
    height:          50,
    width:           50,
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  groupAvatar: {
    backgroundColor: theme.palette.primary.main,
    width:           60,
    height:          60,
  },

  membersContainer: {
    borderTop: '1px solid #bfbfbf',
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  mobileDivider: {
    marginTop:    10,
    marginBottom: 20,
  },
});

export default withStyles(styles)(BumpGroupItem);
