import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form/immutable';
import validate from 'validate.js';
import { Map } from 'immutable';

import { userUpdate } from '../../../../../../actions/userActions';

// components
import PasswordForm from './PasswordForm';

const mapStateToProps = state => ({
  passwordForm: (state.get('form')).get('passwordForm') || Map(),
});

const mapDispatchToProps = dispatch => ({
  resetForm: (form) => {
    dispatch(reset(form));
  },
  updatePassword: (params) => {
    dispatch(userUpdate(params));
  },
});

// schemas
const schema = {
  password: {
    presence: true,

    length: {
      maximum: 100,
    },
  },
  newPassword: {
    presence: true,

    length: {
      maximum: 100,
    },
  },
  newPasswordConfirm: {
    presence: true,

    equality: 'newPassword',

    length: {
      maximum: 100,
    },
  },
};

const validateForm = function (form) {
  validate.validators.equality.message = 'Password does not match';
  return validate(form.toJS(), schema);
};

const handleChange = function (fields, dispatch, { stopSubmit, submitFailed }) {
  if (submitFailed) { stopSubmit(); }
};

const wrappedPasswordForm = connect(mapStateToProps, mapDispatchToProps)(PasswordForm);

export default reduxForm({
  form:     'passwordForm',
  validate: validateForm,
  onChange: handleChange,
})(wrappedPasswordForm);
