import Immutable, { Map } from 'immutable';

export default function httpReducerTests(type, reducer, options = {}) {
  const { normalized } = options;

  it('should set "loading" if meta.loading is "true"', () => {
    const action = { type, meta: { loading: true } };
    const initialState = Map();

    const result = reducer(initialState, action);

    expect(result.get('loading')).toBe(true);
  });

  it('should set "error" to "payload" and "status" to "error" if meta.loading is "true"', () => {
    const action = { type, meta: { error: true }, payload: 'error' };
    const initialState = Map();

    const result = reducer(initialState, action);

    expect(result.get('error')).toBe(action.payload);
    expect(result.get('status')).toBe('error');
  });

  it('should set "status" to "success" if there is a payload and no error', () => {
    const action = { type, payload: 'payload' };
    const initialState = Map();

    const result = reducer(initialState, action);

    expect(result.get('status')).toBe('success');
  });

  it('should remove "error" if meta.error is not present', () => {
    const action = { type, payload: 'payload' };
    const initialState = Map({ error: 'payload' });

    const result = reducer(initialState, action);

    expect(result.get('error')).toBe(undefined);
  });

  it('should return the initialState if action is not supported', () => {
    const action = { type: 'abc123', payload: 'payload' };
    const initialState = Map({ data: 'data' });

    const result = reducer(initialState, action);

    expect(result.toJS()).toMatchObject(initialState.toJS());
  });

  if (!normalized) {
    it('should set "data" to "payload" if not normalized and no cb', () => {
      const action = { type, payload: { data: 'payload' } };
      const initialState = Map();

      const result = reducer(initialState, action);

      expect(result.get('data')).toBe(action.payload.data);
    });
  } else if (normalized) {
    it('should set "data" to the normalized result with merged items', () => {
      const action = {
        type,
        payload: {
          page:  1,
          count: 100,

          normalized: {
            entities: {
              randomEntity: { 1: { id: 1 } },
            },

            result: [1, 2, 3, 4, 5],
          },
        },
      };

      action.payload.normalized.entities[normalized] = {
        1: { id: 1, name: normalized },
        2: { id: 2, name: 'old item' },
      };

      const initialState = Immutable.fromJS({
        data: {
          items: {
            2: { id: 2 },
          },
        },
      });

      const expectedResult = {
        loading: false,
        status:  'success',

        data: {
          page:   action.payload.page,
          count:  action.payload.count,
          result: action.payload.normalized.result,

          items: {
            1: { id: 1, name: normalized },
            2: { id: 2, name: 'old item' },
          },
        },
      };

      const result = reducer(initialState, action);

      expect(result.toJS()).toMatchObject(expectedResult);
    });
  }
}
