import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

// action creators
import { doneIndicator, error } from '../../actions/httpActions';
import {
  destroy, startSubmit, setSubmitFailed, setSubmitSucceeded,
} from 'redux-form';
import { push } from 'react-router-redux';

// api
import { create } from '../../sources/api/client';
import userService from '../../sources/api/userService';

// constants
import { USER_CREATE_INIT, USER_CREATE } from '../../constants/userTypes';

// lib
import httpSaga from '../../lib/httpSaga';

// schemas
import { userSchema } from '../../schemas/user';

export function* userCreate(action) {
  if (action['@@redux-saga/SAGA_ACTION']) return;

  const FORM = 'signup';

  yield put(startSubmit(FORM));

  const { phone, ...restOfPayload } = action.payload || {};

  const values = { ...restOfPayload };

  if (phone) {
    values.phone_area = phone.substring(0, 3);
    values.phone_number = phone.substring(3, 10);
  }

  try {
    yield* httpSaga(USER_CREATE, call(create, userService, values), {
      schema: userSchema,
    });

    yield put(setSubmitSucceeded(FORM));
    yield put(push('/login'));
    yield put(destroy(FORM));
  } catch (err) {
    yield put(setSubmitFailed(FORM));
    yield put(error(USER_CREATE, err));
  } finally {
    yield put(doneIndicator(USER_CREATE));
  }
}

export function* watchUserCreate() {
  yield takeEvery(USER_CREATE_INIT, userCreate);
}

export default function* root() {
  yield all([
    fork(watchUserCreate),
  ]);
}
