import React from 'react';
import { Field } from 'redux-form/immutable';

// local components
import SwitchInput from '../../../SwitchInput';

function ImportForm() {
  return (
    <Field name='releasePnms'
      id='releasePnms'
      label='Release PNMs Not Found In Import And Update Statuses'
      component={ SwitchInput } />
  );
}

export default ImportForm;
